import { createIcon } from '@chakra-ui/react';

export const EmptyPlaylistIllustration = createIcon({
  displayName: 'EmptyPlaylistIllustration',
  viewBox: '0 0 270 270',
  defaultProps: {
    fill: 'none',
    width: '270px',
    height: '270px',
  },
  path: (
    <>
      <circle cx="135" cy="135" r="135" fill="#F1F8FE" />
      <g filter="url(#filter0_dd_643_2250)">
        <rect
          x="103"
          y="85.6362"
          width="85"
          height="126"
          rx="4"
          transform="rotate(-9.23162 103 85.6362)"
          fill="white"
          fillOpacity="0.9"
        />
      </g>
      <g filter="url(#filter1_dd_643_2250)">
        <rect
          x="81"
          y="76.6362"
          width="85"
          height="126"
          rx="4"
          transform="rotate(-9.23162 81 76.6362)"
          fill="white"
          fillOpacity="0.9"
        />
      </g>
      <path
        d="M162.672 165.315C166.115 164.825 169.315 167.228 169.805 170.672C170.295 174.115 167.892 177.315 164.449 177.805C161.005 178.295 157.805 175.892 157.315 172.449C156.825 169.005 159.228 165.805 162.672 165.315ZM162.494 164.066C158.355 164.655 155.477 168.488 156.066 172.627C156.655 176.765 160.488 179.643 164.627 179.054C168.765 178.465 171.643 174.632 171.054 170.494C170.465 166.355 166.632 163.477 162.494 164.066ZM162.131 174.949L161.243 168.704L167.32 171.118L162.131 174.949Z"
        fill="#0F5291"
      />
      <g filter="url(#filter2_dd_643_2250)">
        <rect
          x="45.8086"
          y="72.2379"
          width="85"
          height="126"
          rx="4"
          transform="rotate(-9.89216 45.8086 72.2379)"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip0_643_2250)">
        <path
          d="M113.466 163.396C113.284 162.305 114.022 161.271 115.113 161.088C116.205 160.906 117.239 161.643 117.421 162.735C117.604 163.828 116.866 164.861 115.775 165.043C114.683 165.226 113.649 164.489 113.466 163.396ZM125.442 162.071L123.003 167.9L119.3 165.863L115.341 174.602L133.798 171.515L125.442 162.071ZM137.302 151.955L141.272 175.685L109.632 180.979L105.662 157.248L137.302 151.955ZM137.302 151.955L105.662 157.248L109.632 180.979L141.272 175.685L137.302 151.955Z"
          fill="#0F5291"
        />
        <path
          d="M148.602 162.933C148.419 161.842 149.157 160.808 150.249 160.625C151.34 160.442 152.375 161.18 152.557 162.272C152.74 163.364 152.002 164.397 150.91 164.58C149.819 164.763 148.785 164.026 148.602 162.933ZM160.577 161.607L158.139 167.436L154.436 165.4L150.477 174.139L168.934 171.051L160.577 161.607ZM170.242 154.57L173.33 173.026L146.963 177.438L143.875 158.981L170.242 154.57ZM172.438 151.492L140.797 156.785L144.767 180.515L176.408 175.222L172.438 151.492Z"
          fill="#0F5291"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_643_2250"
          x="91.5893"
          y="70.5893"
          width="126.934"
          height="160.826"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_643_2250"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_643_2250" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_643_2250"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_643_2250"
            result="effect2_dropShadow_643_2250"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_643_2250"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_643_2250"
          x="69.5893"
          y="61.5893"
          width="126.934"
          height="160.826"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_643_2250"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_643_2250" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_643_2250"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_643_2250"
            result="effect2_dropShadow_643_2250"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_643_2250"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_643_2250"
          x="34.4356"
          y="56.2624"
          width="128.128"
          height="161.475"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_643_2250"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_643_2250" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_643_2250"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_643_2250"
            result="effect2_dropShadow_643_2250"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_643_2250"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_643_2250">
          <rect
            width="32.0799"
            height="32.0799"
            fill="white"
            transform="translate(105 153.293) rotate(-9.49758)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
