/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { TitleCell_PowerScheduleFragmentDoc } from '../Cell/__generated__/TitleCell.graphql';
import { DescriptionCell_PowerScheduleFragmentDoc } from '../Cell/__generated__/DescriptionCell.graphql';
import { CreatedAtCell_PowerScheduleFragmentDoc } from '../Cell/__generated__/CreateAtCell.graphql';
import { UsedByCell_PowerScheduleFragmentDoc } from '../Cell/__generated__/UsedBy.graphql';
import { ActionsCell_PowerScheduleFragmentDoc } from '../Cell/__generated__/ActionsCell.graphql';
export type PowerScheduleTable_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, title: string, description?: string | null, createdAt: any, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> };

export const PowerScheduleTable_PowerScheduleFragmentDoc = gql`
    fragment PowerScheduleTable_powerSchedule on PowerSchedule {
  id
  ...TitleCell_powerSchedule
  ...DescriptionCell_powerSchedule
  ...CreatedAtCell_powerSchedule
  ...UsedByCell_powerSchedule
  ...ActionsCell_powerSchedule
}
    ${TitleCell_PowerScheduleFragmentDoc}
${DescriptionCell_PowerScheduleFragmentDoc}
${CreatedAtCell_PowerScheduleFragmentDoc}
${UsedByCell_PowerScheduleFragmentDoc}
${ActionsCell_PowerScheduleFragmentDoc}`;