import { gql } from '@apollo/client';
import {
  Button,
  chakra,
  Link,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useAuth } from '~auth/useAuth';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { EditIcon, ExternalLinkIcon, TrashIcon } from '~components/ui/icons';
import { useAnalyticsReporter } from '~utils/analytics';
import { SiteItemFragment, useRemoveSiteMutation } from './__generated__/SitesActionTable.graphql';
import { EditSiteModal } from './EditSiteModal';

interface Props {
  sites: SiteItemFragment[];
}

function SitesActionTable({ sites }: Props) {
  const { verifyUserPermissions } = useAuth();
  const toast = useToast();
  const [selectedSite, setSelectedSite] = useState<SiteItemFragment | null>(null);
  const editSiteModal = useDisclosure();
  const analytics = useAnalyticsReporter();

  const hasSiteUpdatePermission = verifyUserPermissions([Permission.SiteUpdate]);
  const hasSiteDeletePermission = verifyUserPermissions([Permission.SiteDelete]);

  const [removeSite] = useRemoveSiteMutation();
  const removeAction = useDestructiveAction<SiteItemFragment>({
    title: 'Remove site',
    message: (site) => (
      <>
        Are you sure you want to delete <strong>{site?.name}</strong>?
      </>
    ),
    confirmLabel: 'Remove',
    onConfirm: async (site) => {
      if (!site) return;

      try {
        await removeSite({
          variables: {
            input: {
              siteId: site.id,
            },
          },
        });
        analytics.track('siteDeleteComplete');
      } catch (err) {
        console.error(err);
        toast({
          status: 'error',
          title: 'Unexpected error',
          description: 'An unexpected error occurred trying to remove the site',
        });
      }
    },
  });

  const handleEdit = (site: SiteItemFragment) => async () => {
    analytics.track('siteUpdateStart');
    setSelectedSite(site);
    editSiteModal.onOpen();
  };

  const handleEditSuccess = () => {
    analytics.track('siteUpdateComplete');
    editSiteModal.onClose();
    setSelectedSite(null);
  };

  const handleRemove = (site: SiteItemFragment) => async () => {
    analytics.track('siteDeleteStart');
    await removeAction.askConfirmation(site);
  };

  return (
    <>
      <Table variant="action" width="full">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Address</Th>
            <Th>
              <VisuallyHidden>Actions</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sites.map((site) => {
            return (
              <Tr key={site.id}>
                <Td
                  width="35%"
                  paddingLeft="4"
                  marginRight="20"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="gray.900"
                >
                  {site.name}
                </Td>
                <Td width="65%" fontSize="sm" color="gray.700">
                  {!isNil(site.address) && (
                    <Link
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      href={`https://www.google.com/maps?q=${site.address}`}
                      isExternal
                    >
                      <chakra.span>{site.address}</chakra.span>
                      <ExternalLinkIcon color="gray.200" ml="2" width="18px" height="18px" />
                    </Link>
                  )}
                </Td>
                <Td>
                  <Stack direction="row" spacing="2">
                    <Button
                      size="sm"
                      variant="inline"
                      colorScheme="gray"
                      onClick={handleEdit(site)}
                      isDisabled={!hasSiteUpdatePermission}
                    >
                      <EditIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
                    </Button>
                    <Button
                      size="sm"
                      variant="inline"
                      colorScheme="gray"
                      onClick={handleRemove(site)}
                      isDisabled={!hasSiteDeletePermission}
                    >
                      <TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
                    </Button>
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {selectedSite && (
        <EditSiteModal
          site={selectedSite}
          isOpen={editSiteModal.isOpen}
          onCancel={editSiteModal.onClose}
          onSuccess={handleEditSuccess}
        />
      )}
      {removeAction.confirmationNode}
    </>
  );
}

SitesActionTable.graphql = {
  fragments: {
    SiteItem: gql`
      fragment SiteItem on Site {
        id
        name
        address
        ...EditSiteModal
      }
    `,
  },
  mutations: {
    updateSite: gql`
      mutation UpdateSite($input: SiteUpdateInput!) {
        siteUpdate(input: $input) {
          id
        }
      }
    `,
    removeSite: gql`
      mutation RemoveSite($input: SiteDeleteInput!) {
        siteDelete(input: $input) {
          siteId
        }
      }
    `,
  },
};

export default SitesActionTable;
