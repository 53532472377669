import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './useAuth';

interface Props {
  element: ReactNode;
}

export function Protected({ element }: Props) {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  }

  return <>{element}</>;
}

export function protect(element: ReactNode) {
  return <Protected element={element} />;
}
