import { createIcon } from '@chakra-ui/react';

export const LandscapeIcon = createIcon({
  displayName: 'LandscapeIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M22 16H21H22ZM20 18V17V18ZM22 6H23H22ZM2 6H1H2ZM4 18V19V18ZM6 20C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22V20ZM18 22C18.5523 22 19 21.5523 19 21C19 20.4477 18.5523 20 18 20V22ZM3 16L3 6H1L1 16H3ZM4 5L20 5V3L4 3V5ZM21 6V16H23V6H21ZM20 17L4 17V19L20 19V17ZM21 16C21 16.5523 20.5523 17 20 17V19C21.6569 19 23 17.6569 23 16H21ZM20 5C20.5523 5 21 5.44772 21 6H23C23 4.34315 21.6569 3 20 3V5ZM3 6C3 5.44772 3.44772 5 4 5V3C2.34315 3 1 4.34315 1 6H3ZM1 16C1 17.6569 2.34315 19 4 19V17C3.44772 17 3 16.5523 3 16H1ZM6 22H18V20H6V22Z"
      fill="currentColor"
    />
  ),
});
