import { gql } from '@apollo/client';
import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { useCreateApiKeyMutation } from './__generated__/CreateApiKeyModal.graphql';

const FormData = z.object({
  alias: z
    .string()
    .min(1)
    .regex(/.*\S.*/, 'The alias cannot contain only white spaces'),
});
type FormData = z.infer<typeof FormData>;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (id: string) => Promise<void> | void;
}

export function CreateApiKeyModal({ isOpen, onCancel, onSuccess }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <CreateApiKeyModalContent
          onSuccess={onSuccess}
          onCancel={onCancel}
          initialFocusRef={initialFocusRef}
        />
      </ModalContent>
    </Modal>
  );
}

interface ContentProps {
  onSuccess: (id: string) => Promise<void> | void;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => void;
}

function CreateApiKeyModalContent({ onSuccess, initialFocusRef, onCancel }: ContentProps) {
  const toast = useToast();
  const [createLocation, { data }] = useCreateApiKeyMutation({ fetchPolicy: 'no-cache' });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      alias: '',
    },
    mode: 'onChange',
    resolver: zodResolver(FormData),
  });
  const { ref: aliasInputRef, ...aliasInputProps } = register('alias');

  const onSubmit = useCallback(
    async ({ alias }: FormData) => {
      try {
        const { data } = await createLocation({
          variables: {
            input: {
              alias,
            },
          },
        });

        if (!data || !data.apiKeyCreate.apiKey) {
          throw new Error('Create location failed');
        }

        await onSuccess?.(data.apiKeyCreate.apiKey.id);
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot create API key',
          description: fromError(err, 'CreateApiKey'),
        });
      }
    },
    [createLocation, onSuccess, toast],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Create your personal API key</ModalHeader>
      <ModalCloseButton onClick={onCancel} />

      <ModalBody>
        {data?.apiKeyCreate.apiKeyValue ? (
          <Stack>
            <Text fontSize="sm" fontWeight="semibold" color="gray.500" marginBottom="1">
              Your new personal API Key has been created:
            </Text>
            <chakra.span fontSize="lg" fontWeight="semibold" color="gray.900">
              {data?.apiKeyCreate.apiKeyValue}
            </chakra.span>

            <chakra.span fontSize="xs" fontWeight="semibold" color="red.300" marginTop="1">
              Please copy the key to a safe location as it will be only be visible this one time.
            </chakra.span>
          </Stack>
        ) : (
          <Stack>
            <FormControl isRequired isInvalid={Boolean(errors.alias)}>
              <FormLabel>Alias</FormLabel>
              <Input
                placeholder="Alias for your API Key"
                ref={(r) => {
                  aliasInputRef(r);
                  initialFocusRef.current = r;
                }}
                {...aliasInputProps}
              />
              <FormErrorMessage>{errors.alias?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        )}
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" colorScheme="blue" onClick={onCancel} isDisabled={isSubmitting}>
          Close
        </Button>
        {!data?.apiKeyCreate.apiKeyValue && (
          <Button
            variant="solid"
            colorScheme="blue"
            marginLeft="3"
            type="submit"
            isDisabled={!isDirty || isSubmitting || Object.keys(errors).length > 0}
            isLoading={isSubmitting}
          >
            Create
          </Button>
        )}
      </ModalFooter>
    </form>
  );
}

CreateApiKeyModalContent.graphql = {
  mutations: {
    CreateApiKey: gql`
      mutation CreateApiKey($input: ApiKeyCreateInput!) {
        apiKeyCreate(input: $input) {
          apiKey {
            id
            alias
            createdAt
          }
          apiKeyValue
        }
      }
    `,
  },
};
