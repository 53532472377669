import { chain, differenceWith, intersectionWith, isEqual, isNil } from 'lodash';

export function extractIntersectionAndDifferenceFromMultiDimensionalArray<T>(
  arrays: T[][],
): [T[], T[]] {
  const allUniqueValues = chain(arrays).flattenDeep().uniqWith(isEqual).value();

  const intersection = intersectionWith(...arrays, isEqual);

  const difference = differenceWith(allUniqueValues, intersection, isEqual);

  return [intersection, difference];
}

export function areArraysEqualInsideMultidimensionalArray<T>(arrays: T[][]): boolean {
  if (isNil(arrays)) {
    false;
  }

  const subarrayLength = arrays[0].length;
  if (!arrays.every((subarray) => subarray.length === subarrayLength)) {
    return false;
  }

  const firstSubarray = arrays[0];
  return arrays.every((subarray) => isEqual(subarray, firstSubarray));
}
