import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleList: SystemStyleFunction = () => {
  return {
    py: '0',
    borderRadius: 'base',
  };
};

const baseStyleItem: SystemStyleFunction = (props) => {
  return {
    color: 'blue.800',
    _focus: {
      bg: mode('gray.50', 'whiteAlpha.100')(props),
    },
    _active: {
      bg: mode('gray.50', 'whiteAlpha.200')(props),
    },
    _expanded: {
      bg: mode('gray.50', 'whiteAlpha.100')(props),
    },
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  list: baseStyleList(props),
  item: baseStyleItem(props),
});

export const Menu = {
  parts: parts.keys,
  baseStyle,
};
