import { StyleProps } from '~components/ui/styles/types';

function getIconColorByColorScheme(c: StyleProps['colorScheme']) {
  switch (c) {
    case 'green':
      return `${c}.300`;
    case 'red':
    case 'orange':
      return `${c}.400`;
    case 'blue':
      return `${c}.500`;
  }
}

/**
 * The Alerts from our designs cannot be replicated 100% using the following styles.
 * E.g. it's not possible to customize icons here.
 * However, these styles are used by Toasts, so we try to come as close as possible.
 * If you want to use an Alert that 100% matches the designs, use <InfoAlert />, <WarningAlert /> etc.
 */
export const Alert = {
  parts: ['container', 'title', 'description', 'icon'],
  baseStyle: (props: StyleProps) => {
    const { colorScheme: c } = props;

    return {
      container: {
        background: 'unset',
        backgroundColor: `${c}.50`,
        border: '1px solid',
        borderColor: `${c}.100`,
        borderRadius: 'base',
        fontSize: 'sm',
        color: `${c}.900`,
      },
      title: {
        color: `${c}.900`,
        fontWeight: 'semibold',
        fontFamily: 'heading',
        fontSize: 'md',
      },
      description: {
        display: 'block',
        lineHeight: 'unset',
        color: `${c}.900`,
      },
    };
  },
  variants: {
    solid: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        icon: {
          alignSelf: 'center',
          color: getIconColorByColorScheme(c),
          marginRight: 4,
        },
        container: {
          color: `${c}.900`,

          _before: {
            display: 'block',
            content: '""',
            width: 1,
            flexShrink: 0,
            alignSelf: 'stretch',
            borderRadius: 'lg',
            background: getIconColorByColorScheme(c),
            marginRight: 2,
          },
        },
      };
    },
  },
};
