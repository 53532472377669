import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { formatBytes } from '~utils/file';
import { PlaylistTableMedia } from '../PlaylistMediaTable';

export function SizeCell({ value }: PropsWithChildren<CellProps<PlaylistTableMedia, number>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {formatBytes(value)}
    </Box>
  );
}

SizeCell.graphql = {
  fragments: {
    SizeCell_media: gql`
      fragment SizeCell_media on Media {
        id
        size
      }
    `,
  },
};
