import { gql } from '@apollo/client';
import { cloneDeep, isEmpty, isNil, merge } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  isAppContentSource,
  isBookmarkContentSource,
  isInputContentSource,
  isPlaylistContentSource,
} from '~components/displays/utils';
import { SelectOption } from '~components/ui/Select';
import { isDefined } from '~utils/types';
import {
  useBulkUpdateAppContentSourceMutation,
  useBulkUpdateBookmarkContentSourceMutation,
  useBulkUpdateDefaultAppContentSourceMutation,
  useBulkUpdateDefaultBookmarkContentSourceMutation,
  useBulkUpdateDefaultInputContentSourceMutation,
  useBulkUpdateDefaultPlaylistContentSourceMutation,
  useBulkUpdateInputContentSourceMutation,
  useBulkUpdatePlaylistContentSourceMutation,
  UseContentSource_ContentSource_AppContentSource_Fragment,
  UseContentSource_ContentSource_BookmarkContentSource_Fragment,
  UseContentSource_ContentSource_InputContentSource_Fragment,
  UseContentSource_ContentSource_PlaylistContentSource_Fragment,
  UseContentSource_ContentSourceFragment,
  UseContentSource_DisplayFragment,
} from './__generated__/useContentSource.graphql';
import {
  areArraysEqualInsideMultidimensionalArray,
  extractIntersectionAndDifferenceFromMultiDimensionalArray,
} from './BulkAction/utils';

export const ContentSourceOption = {
  Input: 'Input',
  Bookmark: 'Bookmark',
  Playlist: 'Playlist',
  App: 'App',
} as const;

export function useContentSource() {
  const [updateAppContentSource, { loading: appContentSourceLoading }] =
    useBulkUpdateAppContentSourceMutation();
  const [updateBookmarkContentSource, { loading: bookmarkContentSourceLoading }] =
    useBulkUpdateBookmarkContentSourceMutation();
  const [updatePlaylistContentSource, { loading: playlistContentSourceLoading }] =
    useBulkUpdatePlaylistContentSourceMutation();
  const [updateInputContentSource, { loading: inputContentSourceLoading }] =
    useBulkUpdateInputContentSourceMutation();

  const [updateDefaultAppContentSource, { loading: appDefaultContentSourceLoading }] =
    useBulkUpdateDefaultAppContentSourceMutation();
  const [updateDefaultBookmarkContentSource, { loading: bookmarkDefaultContentSourceLoading }] =
    useBulkUpdateDefaultBookmarkContentSourceMutation();
  const [updateDefaultPlaylistContentSource, { loading: playlistDefaultContentSourceLoading }] =
    useBulkUpdateDefaultPlaylistContentSourceMutation();
  const [updateDefaultInputContentSource, { loading: inputDefaultContentSourceLoading }] =
    useBulkUpdateDefaultInputContentSourceMutation();

  const isLoadingContentSource =
    appContentSourceLoading ||
    bookmarkContentSourceLoading ||
    playlistContentSourceLoading ||
    inputContentSourceLoading;

  const isLoadingDefaultContentSource =
    appDefaultContentSourceLoading ||
    bookmarkDefaultContentSourceLoading ||
    playlistDefaultContentSourceLoading ||
    inputDefaultContentSourceLoading;

  const bulkUpdateContentSource = useCallback(
    async (
      displays: UseContentSource_DisplayFragment[],
      contentSource: UseContentSource_ContentSourceFragment,
      activity?: string,
    ) => {
      const displayIds = displays.map((display) => display.id);

      switch (contentSource.__typename) {
        case 'AppContentSource':
          await updateAppContentSource({
            variables: buildAppContentSourceInput(displayIds, contentSource, activity),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateAppContentSource: buildOptimisticResponseContentSource(
                displays,
                contentSource,
              ),
            },
          });
          return;
        case 'BookmarkContentSource':
          await updateBookmarkContentSource({
            variables: buildBookmarkContentSourceInput(displayIds, contentSource),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateBookmarkContentSource: buildOptimisticResponseContentSource(
                displays,
                contentSource,
              ),
            },
          });
          return;
        case 'InputContentSource':
          await updateInputContentSource({
            variables: buildInputContentSourceInput(displayIds, contentSource),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateInputContentSource: buildOptimisticResponseContentSource(
                displays,
                contentSource,
              ),
            },
          });
          return;
        case 'PlaylistContentSource':
          await updatePlaylistContentSource({
            variables: buildPlaylistContentSourceInput(displayIds, contentSource),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdatePlaylistContentSource: buildOptimisticResponseContentSource(
                displays,
                contentSource,
              ),
            },
          });
          return;
      }
    },
    [
      updateAppContentSource,
      updateBookmarkContentSource,
      updateInputContentSource,
      updatePlaylistContentSource,
    ],
  );

  const bulkUpdateDefaultContentSource = useCallback(
    async (
      displays: UseContentSource_DisplayFragment[],
      contentSource: UseContentSource_ContentSourceFragment,
      activity?: string,
    ) => {
      const displayIds = displays.map((display) => display.id);

      switch (contentSource.__typename) {
        case 'AppContentSource':
          return await updateDefaultAppContentSource({
            variables: buildAppContentSourceInput(displayIds, contentSource, activity),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateDefaultAppContentSource: buildOptimisticResponseDefaultContentSource(
                displays,
                contentSource,
              ),
            },
          });
        case 'BookmarkContentSource':
          return await updateDefaultBookmarkContentSource({
            variables: buildBookmarkContentSourceInput(displayIds, contentSource),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateDefaultBookmarkContentSource:
                buildOptimisticResponseDefaultContentSource(displays, contentSource),
            },
          });
        case 'InputContentSource':
          return await updateDefaultInputContentSource({
            variables: buildInputContentSourceInput(displayIds, contentSource),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateDefaultInputContentSource:
                buildOptimisticResponseDefaultContentSource(displays, contentSource),
            },
          });

        case 'PlaylistContentSource':
          return await updateDefaultPlaylistContentSource({
            variables: buildPlaylistContentSourceInput(displayIds, contentSource),
            optimisticResponse: {
              __typename: 'Mutation',
              displayBulkUpdateDefaultPlaylistContentSource:
                buildOptimisticResponseDefaultContentSource(displays, contentSource),
            },
          });
      }
    },
    [
      updateDefaultAppContentSource,
      updateDefaultBookmarkContentSource,
      updateDefaultInputContentSource,
      updateDefaultPlaylistContentSource,
    ],
  );

  return {
    isLoadingContentSource,
    isLoadingDefaultContentSource,
    bulkUpdateContentSource,
    bulkUpdateDefaultContentSource,
  };
}

function buildOptimisticResponseDefaultContentSource(
  displays: UseContentSource_DisplayFragment[],
  contentSource: UseContentSource_ContentSourceFragment,
) {
  return {
    __typename: 'DisplayBulkUpdateDefaultContentSourcePayload' as const,
    displays: displays.map((display) =>
      merge(cloneDeep(display), {
        contentSource: {
          default: {
            desired: {
              contentSource,
            },
          },
        },
      }),
    ),
  };
}

function buildOptimisticResponseContentSource(
  displays: UseContentSource_DisplayFragment[],
  contentSource: UseContentSource_ContentSourceFragment,
) {
  return {
    __typename: 'DisplayBulkUpdateContentSourcePayload' as const,
    displays: displays.map((display) =>
      merge(cloneDeep(display), {
        contentSource: {
          current: {
            desired: {
              contentSource,
            },
          },
        },
      }),
    ),
  };
}

function buildAppContentSourceInput(
  displayIds: string[],
  contentSource: UseContentSource_ContentSource_AppContentSource_Fragment,
  activity?: string,
) {
  return {
    input: {
      displayIds,
      applicationId: contentSource.applicationId,
      label: contentSource.label,
      activity: activity,
    },
  };
}

function buildPlaylistContentSourceInput(
  displayIds: string[],
  contentSource: UseContentSource_ContentSource_PlaylistContentSource_Fragment,
) {
  return {
    input: {
      displayIds,
      playlistId: contentSource.playlistId,
    },
  };
}

function buildBookmarkContentSourceInput(
  displayIds: string[],
  contentSource: UseContentSource_ContentSource_BookmarkContentSource_Fragment,
) {
  return {
    input: {
      displayIds,
      index: contentSource.index,
    },
  };
}

function buildInputContentSourceInput(
  displayIds: string[],
  contentSource: UseContentSource_ContentSource_InputContentSource_Fragment,
) {
  return {
    input: {
      displayIds,
      source: contentSource.source,
    },
  };
}

export function useContentSourceLabel(
  contentSource: UseContentSource_ContentSourceFragment | undefined,
  display: UseContentSource_DisplayFragment,
) {
  const bookmarkLabel = useMemo(() => {
    if (!isNil(contentSource) && isBookmarkContentSource(contentSource)) {
      const bookmarks = display.bookmarks.all.desired ?? display.bookmarks.all.reported;
      const bookmarkIndex = contentSource.index;

      return bookmarks && isDefined(bookmarkIndex) ? bookmarks[bookmarkIndex] : undefined;
    }

    return undefined;
  }, [display, contentSource]);

  const mediaLabel = useMemo(() => {
    if (!isNil(contentSource) && isPlaylistContentSource(contentSource)) {
      const playlist = display.playlist?.current;
      const isPlaylistActive = isDefined(contentSource.playlistId);

      return playlist && isPlaylistActive ? playlist.title : undefined;
    }

    return undefined;
  }, [display, contentSource]);

  const appLabel = useMemo(() => {
    if (!isNil(contentSource) && isAppContentSource(contentSource)) {
      return contentSource.label || contentSource.applicationId;
    }

    return undefined;
  }, [contentSource]);

  const appActivityLabel = useMemo(() => {
    if (isAppContentSource(contentSource)) {
      return contentSource.selectedActivity || contentSource.label || contentSource.applicationId;
    }
    return undefined;
  }, [contentSource]);

  const headingLabel = useMemo(() => {
    if (isDefined(bookmarkLabel)) {
      return 'Web page';
    }

    if (isDefined(mediaLabel)) {
      return mediaLabel;
    }

    if (isDefined(appLabel)) {
      return appLabel;
    }

    if (isInputContentSource(contentSource)) {
      return contentSource.source;
    }

    return '';
  }, [bookmarkLabel, mediaLabel, appLabel, contentSource]);

  return {
    bookmarkLabel,
    mediaLabel,
    appLabel,
    headingLabel,
    appActivityLabel,
  };
}

export function useContentSourceOptions(displays: UseContentSource_DisplayFragment[]) {
  const [sharedAvailableContentSources, excludedContentSources] =
    extractIntersectionAndDifferenceFromMultiDimensionalArray(
      displays.map((display) => display.contentSource?.available ?? []),
    );

  const sharedPlaylistIds = sharedAvailableContentSources
    .filter(isPlaylistContentSource)
    .map((contentSource) => contentSource.playlistId);

  const sharedPlaylists = displays
    .map((display) => {
      const playlist = display.playlist?.current;

      if (!isNil(playlist) && sharedPlaylistIds.includes(playlist.id)) {
        return playlist;
      }
    })
    .filter(
      (playlist): playlist is { __typename: 'DisplayCurrentPlaylist'; id: string; title: string } =>
        !isNil(playlist),
    );

  const allBookmarks = displays.map(
    (display) => display.bookmarks.all.desired ?? display.bookmarks.all.reported ?? [],
  );

  const bookmarkOptions = useMemo(() => {
    const sharedBookmarks = areArraysEqualInsideMultidimensionalArray(allBookmarks)
      ? allBookmarks[0]
      : [];

    const options: SelectOption[] = [];

    if (!isEmpty(sharedBookmarks)) {
      sharedBookmarks.forEach((bookmark, index) => {
        if (!isNil(bookmark) && !isEmpty(bookmark)) {
          options.push({
            label: bookmark,
            value: index.toString(),
          });
        }
      });
    }

    return options;
  }, [allBookmarks]);

  const { appOptions, sharedAvailableContentSourceOptions, inputOptions, playlistOptions } =
    useMemo(() => {
      const sharedAvailableContentSourceOptions: SelectOption[] = [];
      const appOptions: SelectOption[] = [];
      const playlistOptions: SelectOption[] = [];
      const inputOptions: SelectOption[] = [];

      sharedAvailableContentSources.forEach((contentSource) => {
        if (isPlaylistContentSource(contentSource)) {
          const playlist = sharedPlaylists.find((p) => p.id === contentSource.playlistId);

          if (!isNil(playlist)) {
            const option = { label: playlist.title, value: contentSource.playlistId };
            playlistOptions.push(option);
            sharedAvailableContentSourceOptions.push(option);
          }
        }

        if (isAppContentSource(contentSource)) {
          const option = {
            label: contentSource.label ?? contentSource.applicationId,
            value: contentSource.applicationId,
          };
          appOptions.push(option);
          sharedAvailableContentSourceOptions.push(option);
        }

        if (isInputContentSource(contentSource)) {
          if (contentSource.source !== 'MEDIA PLAYER' && contentSource.source !== 'BROWSER') {
            const option = {
              label: contentSource.source,
              value: contentSource.source,
            };

            inputOptions.push(option);
            sharedAvailableContentSourceOptions.push(option);
          }
        }
      });

      return {
        sharedAvailableContentSourceOptions,
        appOptions,
        inputOptions,
        playlistOptions,
      };
    }, [sharedAvailableContentSources, sharedPlaylists]);

  return useMemo(
    () => ({
      sharedAvailableContentSourceOptions,
      sharedContentSourcesOptionsByType: {
        appOptions,
        bookmarkOptions,
        playlistOptions,
        inputOptions,
      },
      excludedContentSources: excludedContentSources,
    }),
    [
      appOptions,
      bookmarkOptions,
      excludedContentSources,
      inputOptions,
      playlistOptions,
      sharedAvailableContentSourceOptions,
    ],
  );
}

useContentSource.graphql = {
  fragments: {
    UseContentSource_contentSource: gql`
      fragment UseContentSource_contentSource on ContentSource {
        ... on AppContentSource {
          label
          applicationId
          activityList
          selectedActivity
          isBootable
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    `,
    UseContentSource_display: gql`
      fragment UseContentSource_display on Display {
        id
        bookmarks {
          all {
            reported
            desired
          }
        }
        contentSource {
          available {
            ...UseContentSource_contentSource
          }
          current {
            reported {
              ...UseContentSource_contentSource
            }
            desired {
              ...UseContentSource_contentSource
            }
          }
          default {
            desired {
              ...UseContentSource_contentSource
            }
            reported {
              ...UseContentSource_contentSource
            }
          }
        }
        playlist {
          current {
            id
            title
          }
        }
      }
    `,
    UseContentSourceLabel_display: gql`
      fragment UseContentSourceLabel_display on Display {
        id
        ...UseContentSource_display
      }
    `,
  },
  mutations: {
    BulkUpdateAppContentSource: gql`
      mutation BulkUpdateAppContentSource($input: DisplayBulkUpdateAppContentSourceInput!) {
        displayBulkUpdateAppContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdateBookmarkContentSource: gql`
      mutation BulkUpdateBookmarkContentSource(
        $input: DisplayBulkUpdateBookmarkContentSourceInput!
      ) {
        displayBulkUpdateBookmarkContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdatePlaylistContentSource: gql`
      mutation BulkUpdatePlaylistContentSource(
        $input: DisplayBulkUpdatePlaylistContentSourceInput!
      ) {
        displayBulkUpdatePlaylistContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdateInputContentSource: gql`
      mutation BulkUpdateInputContentSource($input: DisplayBulkUpdateInputContentSourceInput!) {
        displayBulkUpdateInputContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdateDefaultAppContentSource: gql`
      mutation BulkUpdateDefaultAppContentSource(
        $input: DisplayBulkUpdateDefaultAppContentSourceInput!
      ) {
        displayBulkUpdateDefaultAppContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdateDefaultBookmarkContent: gql`
      mutation BulkUpdateDefaultBookmarkContentSource(
        $input: DisplayBulkUpdateDefaultBookmarkContentSourceInput!
      ) {
        displayBulkUpdateDefaultBookmarkContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdateDefaultPlaylistContentSource: gql`
      mutation BulkUpdateDefaultPlaylistContentSource(
        $input: DisplayBulkUpdateDefaultPlaylistContentSourceInput!
      ) {
        displayBulkUpdateDefaultPlaylistContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
    BulkUpdateDefaultInputContentSource: gql`
      mutation BulkUpdateDefaultInputContentSource(
        $input: DisplayBulkUpdateDefaultInputContentSourceInput!
      ) {
        displayBulkUpdateDefaultInputContentSource(input: $input) {
          displays {
            id
            ...UseContentSource_display
          }
        }
      }
    `,
  },
};
