import { gql } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PowerScheduleDetail } from '~components/powerSchedules/PowerScheduleDetail';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { HandleApiError } from '~graphql/HandleApiError';
import { ensure } from '~utils/types';
import { usePowerScheduleDetailsPageQuery } from './__generated__/[id].graphql';

const POLL_INTERVAL = 5000;

export function PowerScheduleDetailsPage() {
  const { customer, powerScheduleId } = useParams();
  const { data, loading, error, refetch, startPolling, stopPolling } =
    usePowerScheduleDetailsPageQuery({
      variables: {
        customerHandle: ensure(customer),
        powerScheduleId: ensure(powerScheduleId),
      },
      pollInterval: POLL_INTERVAL,
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const handlePowerScheduleUpdateSuccess = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <Page
      title={`${data?.powerSchedule?.title || 'Loading…'} | Power schedule`}
      pageName="power_schedule_detail"
    >
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          data &&
          data.powerSchedule &&
          data.customer && (
            <PowerScheduleDetail
              powerSchedule={data.powerSchedule}
              onPowerScheduleUpdateSuccess={handlePowerScheduleUpdateSuccess}
            />
          )
        )}
      </PageContent>
    </Page>
  );
}

PowerScheduleDetailsPage.graphql = {
  queries: {
    PowerScheduleDetailsPage: gql`
      query PowerScheduleDetailsPage($customerHandle: String!, $powerScheduleId: ID!) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          ...PowerScheduleDetail_customer
        }
        powerSchedule(id: $powerScheduleId) {
          id
          ...PowerScheduleDetail_powerSchedule
        }
      }
    `,
  },
};
