/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type ContentSourceCell_ContentSource_AppContentSource_Fragment = { __typename: 'AppContentSource', label?: string | null, applicationId: string };

export type ContentSourceCell_ContentSource_BookmarkContentSource_Fragment = { __typename: 'BookmarkContentSource', index: number };

export type ContentSourceCell_ContentSource_InputContentSource_Fragment = { __typename: 'InputContentSource', source: string };

export type ContentSourceCell_ContentSource_PlaylistContentSource_Fragment = { __typename: 'PlaylistContentSource', playlistId: string };

export type ContentSourceCell_ContentSourceFragment = ContentSourceCell_ContentSource_AppContentSource_Fragment | ContentSourceCell_ContentSource_BookmarkContentSource_Fragment | ContentSourceCell_ContentSource_InputContentSource_Fragment | ContentSourceCell_ContentSource_PlaylistContentSource_Fragment;

export type ContentSourceCell_ValueFragment = { __typename: 'ContentSourceSettings', current?: { __typename: 'ContentSourceState', reported: { __typename: 'AppContentSource', label?: string | null, applicationId: string } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }, desired?: { __typename: 'AppContentSource', label?: string | null, applicationId: string } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string } | null } | null, available?: Array<{ __typename: 'AppContentSource', label?: string | null, applicationId: string } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }> | null };

export type ContentSourceCell_DisplayFragment = { __typename: 'Display', id: string, contentSource?: { __typename: 'ContentSourceSettings', current?: { __typename: 'ContentSourceState', reported: { __typename: 'AppContentSource', label?: string | null, applicationId: string } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }, desired?: { __typename: 'AppContentSource', label?: string | null, applicationId: string } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string } | null } | null, available?: Array<{ __typename: 'AppContentSource', label?: string | null, applicationId: string } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }> | null } | null };

export const ContentSourceCell_ContentSourceFragmentDoc = gql`
    fragment ContentSourceCell_contentSource on ContentSource {
  ... on AppContentSource {
    label
    applicationId
  }
  ... on BookmarkContentSource {
    index
  }
  ... on InputContentSource {
    source
  }
  ... on PlaylistContentSource {
    playlistId
  }
}
    `;
export const ContentSourceCell_ValueFragmentDoc = gql`
    fragment ContentSourceCell_value on ContentSourceSettings {
  current {
    reported {
      ... on AppContentSource {
        label
        applicationId
      }
      ... on BookmarkContentSource {
        index
      }
      ... on InputContentSource {
        source
      }
      ... on PlaylistContentSource {
        playlistId
      }
    }
    desired {
      ... on AppContentSource {
        label
        applicationId
      }
      ... on BookmarkContentSource {
        index
      }
      ... on InputContentSource {
        source
      }
      ... on PlaylistContentSource {
        playlistId
      }
    }
  }
  available {
    ... on AppContentSource {
      label
      applicationId
    }
    ... on BookmarkContentSource {
      index
    }
    ... on InputContentSource {
      source
    }
    ... on PlaylistContentSource {
      playlistId
    }
  }
}
    `;
export const ContentSourceCell_DisplayFragmentDoc = gql`
    fragment ContentSourceCell_display on Display {
  id
  contentSource {
    current {
      reported {
        ... on AppContentSource {
          label
          applicationId
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
      desired {
        ... on AppContentSource {
          label
          applicationId
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    }
    available {
      ... on AppContentSource {
        label
        applicationId
      }
      ... on BookmarkContentSource {
        index
      }
      ... on InputContentSource {
        source
      }
      ... on PlaylistContentSource {
        playlistId
      }
    }
  }
}
    `;