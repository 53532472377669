import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type Props = {
  ts: string; // ISO timestamp
  format?: (ts: string) => string;
  render: (time: string) => JSX.Element | null;
};

export function RelativeTime({ ts, format = DEFAULT_FORMAT_TIME, render }: Props) {
  const [time, setTime] = useState(format(ts));

  useEffect(() => {
    setTime(format(ts));

    const interval = setInterval(() => {
      setTime(format(ts));
    }, 15000);

    return () => clearInterval(interval);
  }, [format, setTime, ts]);

  return render(time);
}

const DEFAULT_FORMAT_TIME = (timestamp: string): string => {
  const ts = DateTime.fromISO(timestamp);

  if (ts.diffNow('minutes').minutes > -1) {
    return 'less than a minute ago';
  }

  if (ts.diffNow('days').days > -1) {
    return ts.toRelative() ?? '';
  }

  return ts.toFormat("DD 'at' h:mm a");
};

export const RELATIVE_FORMAT_TIME = (timestamp: string): string => {
  const ts = DateTime.fromISO(timestamp).setLocale('en-US');

  if (ts.diffNow('minutes').minutes > -1) {
    return 'less than a minute ago';
  }

  if (ts.diffNow('hours').hours > -1) {
    return ts.toRelative({ unit: 'minutes' }) ?? '';
  }

  if (ts.diffNow('days').days > -1) {
    return ts.toRelative({ unit: 'hours' }) ?? '';
  }

  return ts.toRelative({ unit: 'days' }) ?? '';
};

export const SHORT_FORMAT_TIME = (timestamp: string): string => {
  const ts = DateTime.fromISO(timestamp).setLocale('en-US');

  return (
    ts
      .toRelative()
      ?.replace(/ days?/, 'd')
      .replace(/ hours?/, 'h')
      .replace(/ minutes?/, 'min')
      .replace(/ seconds?/, 's') ?? ''
  );
};
