import { Button } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { DateTime } from 'luxon';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Row, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Shield } from '~auth/Shield';
import { EditIcon } from '~components/ui/icons';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { SubscriptionsTableSubscriptionFragment } from './__generated__/SubscriptionsTable.graphql';
import { Columns } from './types';

const TableCell = generateTableCellComponent<SubscriptionsTableSubscriptionFragment, ReactNode>();

const TableCellBold = generateTableCellComponent<SubscriptionsTableSubscriptionFragment, ReactNode>(
  {
    isBold: true,
  },
);

const SubscriptionsActionsCell = ({
  row: { original: subscription },
}: {
  row: Row<SubscriptionsTableSubscriptionFragment>;
}) => {
  const navigate = useNavigate();

  const handleEdit = () => navigate(subscription.id, { state: { canGoBack: true } });

  return (
    // TODO add permissions
    <Shield
      requiredPermissions={[Permission.WaveSubscriptionView, Permission.WaveSubscriptionUpdate]}
    >
      <Button size="sm" variant="inline" colorScheme="gray" onClick={handleEdit}>
        <EditIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
      </Button>
    </Shield>
  );
};

export function useSubscriptionsTable(data: SubscriptionsTableSubscriptionFragment[]) {
  const columns = useMemo<Array<Column<SubscriptionsTableSubscriptionFragment>>>(
    () => [
      {
        id: Columns.Subscription,
        Header: 'Subscription',
        accessor: 'name',
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.name.toLowerCase().localeCompare(rowB.original.name.toLowerCase()),
        width: '250px',
      },
      {
        id: Columns.Usage,
        Header: 'Usage',
        accessor: (subscription) =>
          `${subscription.usage.current}/${subscription.usage.max ?? '∞'}`,
        disableSortBy: true,
        Cell: TableCell,
        width: '100px',
      },
      {
        id: Columns.PurchasedOn,
        Header: 'Purchased on',
        accessor: (subscription) => DateTime.fromISO(subscription.createdAt).toFormat('dd/MM/yyyy'),
        Cell: TableCell,
        sortType: (rowA, rowB) =>
          DateTime.fromISO(rowA.original.createdAt).toMillis() -
          DateTime.fromISO(rowB.original.createdAt).toMillis(),
        width: '140px',
      },
      {
        id: Columns.ValidUntil,
        Header: 'Valid until',
        accessor: (subscription) =>
          DateTime.fromISO(subscription.validUntil).toFormat('dd/MM/yyyy'),
        Cell: TableCell,
        sortType: (rowA, rowB) =>
          DateTime.fromISO(rowA.original.validUntil).toMillis() -
          DateTime.fromISO(rowB.original.validUntil).toMillis(),
        width: '140px',
      },
      {
        id: Columns.Customers,
        Header: 'Customers',
        accessor: (subscription) =>
          subscription.customers.length === 0
            ? '/'
            : subscription.customers.map((customer) => customer.name).join(', '),
        Cell: TableCell,
        disableSortBy: true,
        width: 'auto',
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: Columns.Actions,
        Cell: SubscriptionsActionsCell,
        width: '90px',
      },
    ],
    [],
  );

  return useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Subscription }],
        filters: [{ id: Columns.Subscription, value: [] }],
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );
}
