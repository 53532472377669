/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CustomersGridItem_CustomerFragment = { __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null, displayCount: number, displayUsage?: { __typename: 'UsageStatistics', presence: { __typename: 'PresenceStatistics', disconnected: number }, powerState: { __typename: 'PowerStateStatistics', unplannedStandBy: number }, warnings: { __typename: 'WarningStatistics', emptyShadowWarnings: number, recommendedSettingsWarnings: number, undefinedRecommendedSettingsWarnings: number, outOfSyncPowerScheduleWarnings: number, outOfSyncPlaylistWarnings: number, playlistSyncFailedWarnings: number, powerScheduleSyncFailedWarnings: number } } | null, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null };

export const CustomersGridItem_CustomerFragmentDoc = gql`
    fragment CustomersGridItem_customer on Customer {
  id
  handle
  name
  avatarUrl
  displayCount
  displayUsage {
    presence {
      disconnected
    }
    powerState {
      unplannedStandBy
    }
    warnings {
      emptyShadowWarnings
      recommendedSettingsWarnings
      undefinedRecommendedSettingsWarnings
      outOfSyncPowerScheduleWarnings
      outOfSyncPlaylistWarnings
      playlistSyncFailedWarnings
      powerScheduleSyncFailedWarnings
    }
  }
  waveSubscription {
    id
  }
}
    `;