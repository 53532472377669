/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type MediaCreateRequestMutationVariables = Types.Exact<{
  input: Types.MediaCreateRequestInput;
}>;


export type MediaCreateRequestMutation = { __typename: 'Mutation', mediaCreateRequest: { __typename: 'MediaCreateRequestPayload', uploadUrl: string, mediaId: string } };


export const MediaCreateRequestDocument = gql`
    mutation MediaCreateRequest($input: MediaCreateRequestInput!) {
  mediaCreateRequest(input: $input) {
    uploadUrl
    mediaId
  }
}
    `;
export type MediaCreateRequestMutationFn = ApolloReactCommon.MutationFunction<MediaCreateRequestMutation, MediaCreateRequestMutationVariables>;

/**
 * __useMediaCreateRequestMutation__
 *
 * To run a mutation, you first call `useMediaCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaCreateRequestMutation, { data, loading, error }] = useMediaCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaCreateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaCreateRequestMutation, MediaCreateRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MediaCreateRequestMutation, MediaCreateRequestMutationVariables>(MediaCreateRequestDocument, options);
      }
export type MediaCreateRequestMutationHookResult = ReturnType<typeof useMediaCreateRequestMutation>;
export type MediaCreateRequestMutationResult = ApolloReactCommon.MutationResult<MediaCreateRequestMutation>;
export type MediaCreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<MediaCreateRequestMutation, MediaCreateRequestMutationVariables>;