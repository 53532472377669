import { Box, Button, ButtonProps, chakra, Circle, Text } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

type Props = { selectedCount: number } & ButtonProps;
type Ref = LegacyRef<HTMLButtonElement>;

export const BulkActionButton = forwardRef(function BulkButton(
  { selectedCount, ...props }: Props,
  ref: Ref,
) {
  return (
    <Button ref={ref} variant="outline" colorScheme="gray" {...props}>
      <Text>Bulk Actions</Text>
      <Box paddingLeft="3">
        <Circle size="24px" background="white">
          <chakra.span color="blue.500" fontSize="sm" mb="0.5">
            {selectedCount}
          </chakra.span>
        </Circle>
      </Box>
    </Button>
  );
});
