import {
  Box,
  Button,
  chakra,
  Circle,
  ComponentWithAs,
  Flex,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { ResponsiveValue, ThemeTypings } from '@chakra-ui/styled-system';
import * as CSS from 'csstype';
import { ArrowRightIcon, IconProps } from './icons';

// This is not exported anymore by @chakra-ui/styled-system.
// Navigating to `@chakra-ui/styled-system/dist/index.d.ts` the same type is defined at line 77.
// By defining it here, we can continue using the already defined ui elements without incurring in costly refactoring.
type Token<CSSType, ThemeKey = unknown> = ThemeKey extends keyof ThemeTypings
  ? ResponsiveValue<CSSType | ThemeTypings[ThemeKey]>
  : ResponsiveValue<CSSType>;

interface Props {
  amount: number | string;
  backgroundColor?: Token<CSS.Property.Color, 'colors'>;
  icon: ComponentWithAs<'svg', IconProps>;
  iconColor?: Token<CSS.Property.Color, 'colors'>;
  label: string;
  onClick?: () => void;
}

export function Stat({
  onClick,
  amount,
  backgroundColor = 'blue.100',
  icon: Icon,
  iconColor = 'blue.500',
  label,
}: Props) {
  const elements = (
    <HStack spacing="4" role="group">
      <Flex alignItems="center">
        <Circle size="12" backgroundColor={backgroundColor}>
          <Icon width="6" height="6" color={iconColor} />
        </Circle>
        <VStack marginLeft="4" alignItems="flex-start" spacing="0">
          <HStack alignItems="flex-end">
            <chakra.span
              display="flex"
              fontFamily="heading"
              fontSize="3xl"
              fontWeight="semibold"
              lineHeight="6"
              color="blue.800"
            >
              {amount}
            </chakra.span>
            {onClick !== undefined && (
              <ArrowRightIcon
                width={6}
                height={6}
                opacity={0}
                style={{ transition: '0.2s opacity' }}
                _groupHover={{ opacity: 1 }}
                color="gray.400"
              />
            )}
          </HStack>
          <chakra.span
            display="flex"
            fontFamily="body"
            fontSize="sm"
            fontWeight={400}
            lineHeight="normal"
            color="blue.800"
          >
            {label}
          </chakra.span>
        </VStack>
      </Flex>
    </HStack>
  );

  return onClick === undefined ? (
    <Box
      style={{
        padding: '4px 8px',
      }}
    >
      {elements}
    </Box>
  ) : (
    <Button
      variant="unstyled"
      onClick={onClick}
      height="auto"
      style={{
        padding: '4px 8px',
      }}
      _hover={{
        borderRadius: '4px',
        boxShadow: '0px 6px 16px -1px rgba(0, 56, 95, 0.06)',
        background: 'white',
      }}
    >
      {elements}
    </Button>
  );
}
