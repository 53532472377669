import { createIcon } from '@chakra-ui/react';

export const LogoutIcon = createIcon({
  displayName: 'LogoutIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M15.2929 16.2929C14.9024 16.6834 14.9024 17.3166 15.2929 17.7071C15.6834 18.0976 16.3166 18.0976 16.7071 17.7071L15.2929 16.2929ZM21 12L21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L21 12ZM16.7071 6.29289C16.3166 5.90237 15.6834 5.90237 15.2929 6.29289C14.9024 6.68342 14.9024 7.31658 15.2929 7.70711L16.7071 6.29289ZM9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13V11ZM9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20V22ZM3 19H2H3ZM5 3V2V3ZM9 4C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2V4ZM16.7071 17.7071L21.7071 12.7071L20.2929 11.2929L15.2929 16.2929L16.7071 17.7071ZM21.7071 11.2929L16.7071 6.29289L15.2929 7.70711L20.2929 12.7071L21.7071 11.2929ZM21 11H9V13H21V11ZM9 20H5V22H9V20ZM5 20C4.73478 20 4.48043 19.8946 4.29289 19.7071L2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22V20ZM4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19H2C2 19.7957 2.31607 20.5587 2.87868 21.1213L4.29289 19.7071ZM4 19V5H2V19H4ZM4 5C4 4.73478 4.10536 4.48043 4.29289 4.29289L2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5H4ZM4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4V2C4.20435 2 3.44129 2.31607 2.87868 2.87868L4.29289 4.29289ZM5 4H9V2H5V4Z"
      fill="currentColor"
    />
  ),
});
