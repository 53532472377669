/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePlaylistsModal_PlaylistFragment = { __typename: 'Playlist', id: string, syncedDisplays: Array<{ __typename: 'Display', id: string }> };

export type DeletePlaylistsMutationVariables = Types.Exact<{
  input: Types.PlaylistsDeleteInput;
}>;


export type DeletePlaylistsMutation = { __typename: 'Mutation', playlistsDelete: { __typename: 'PlaylistsDeletePayload', playlistIds: Array<string> } };

export const DeletePlaylistsModal_PlaylistFragmentDoc = gql`
    fragment DeletePlaylistsModal_playlist on Playlist {
  id
  syncedDisplays: displays(filter: {state: SYNCED}) {
    id
  }
}
    `;
export const DeletePlaylistsDocument = gql`
    mutation DeletePlaylists($input: PlaylistsDeleteInput!) {
  playlistsDelete(input: $input) {
    playlistIds
  }
}
    `;
export type DeletePlaylistsMutationFn = ApolloReactCommon.MutationFunction<DeletePlaylistsMutation, DeletePlaylistsMutationVariables>;

/**
 * __useDeletePlaylistsMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistsMutation, { data, loading, error }] = useDeletePlaylistsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlaylistsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePlaylistsMutation, DeletePlaylistsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePlaylistsMutation, DeletePlaylistsMutationVariables>(DeletePlaylistsDocument, options);
      }
export type DeletePlaylistsMutationHookResult = ReturnType<typeof useDeletePlaylistsMutation>;
export type DeletePlaylistsMutationResult = ApolloReactCommon.MutationResult<DeletePlaylistsMutation>;
export type DeletePlaylistsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlaylistsMutation, DeletePlaylistsMutationVariables>;