import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { PowerScheduleTable_PowerScheduleFragment } from '../__generated__/PowerScheduleTable.graphql';

export function TitleCell({
  value,
}: PropsWithChildren<CellProps<PowerScheduleTable_PowerScheduleFragment, string>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontWeight="semibold">
      {value}
    </Box>
  );
}

TitleCell.graphql = {
  fragments: {
    TitleCell_powerSchedule: gql`
      fragment TitleCell_powerSchedule on PowerSchedule {
        id
        title
      }
    `,
  },
};
