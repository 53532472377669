/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UseRemovePowerScheduleFromDisplays_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, removingDisplays: Array<{ __typename: 'Display', id: string }> };

export type RemovePowerScheduleFromDisplaysMutationVariables = Types.Exact<{
  input: Types.PowerScheduleRemoveDisplaysInput;
}>;


export type RemovePowerScheduleFromDisplaysMutation = { __typename: 'Mutation', powerScheduleRemoveDisplays: { __typename: 'PowerScheduleRemoveDisplaysPayload', displays: Array<{ __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', id: string, title: string, removingDisplays: Array<{ __typename: 'Display', id: string }> } | null } | null }> } };

export const UseRemovePowerScheduleFromDisplays_PowerScheduleFragmentDoc = gql`
    fragment useRemovePowerScheduleFromDisplays_powerSchedule on PowerSchedule {
  id
  removingDisplays: displays(filter: {state: REMOVING}) {
    id
  }
}
    `;
export const RemovePowerScheduleFromDisplaysDocument = gql`
    mutation RemovePowerScheduleFromDisplays($input: PowerScheduleRemoveDisplaysInput!) {
  powerScheduleRemoveDisplays(input: $input) {
    displays {
      id
      powerSchedule {
        schedule {
          id
          title
          ...useRemovePowerScheduleFromDisplays_powerSchedule
        }
      }
    }
  }
}
    ${UseRemovePowerScheduleFromDisplays_PowerScheduleFragmentDoc}`;
export type RemovePowerScheduleFromDisplaysMutationFn = ApolloReactCommon.MutationFunction<RemovePowerScheduleFromDisplaysMutation, RemovePowerScheduleFromDisplaysMutationVariables>;

/**
 * __useRemovePowerScheduleFromDisplaysMutation__
 *
 * To run a mutation, you first call `useRemovePowerScheduleFromDisplaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePowerScheduleFromDisplaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePowerScheduleFromDisplaysMutation, { data, loading, error }] = useRemovePowerScheduleFromDisplaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePowerScheduleFromDisplaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePowerScheduleFromDisplaysMutation, RemovePowerScheduleFromDisplaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemovePowerScheduleFromDisplaysMutation, RemovePowerScheduleFromDisplaysMutationVariables>(RemovePowerScheduleFromDisplaysDocument, options);
      }
export type RemovePowerScheduleFromDisplaysMutationHookResult = ReturnType<typeof useRemovePowerScheduleFromDisplaysMutation>;
export type RemovePowerScheduleFromDisplaysMutationResult = ApolloReactCommon.MutationResult<RemovePowerScheduleFromDisplaysMutation>;
export type RemovePowerScheduleFromDisplaysMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePowerScheduleFromDisplaysMutation, RemovePowerScheduleFromDisplaysMutationVariables>;