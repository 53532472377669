/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PowerScheduleDetail_CustomerFragment = { __typename: 'Customer', id: string, name: string };

export type PowerScheduleDetail_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, title: string, description?: string | null, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }>, allDisplays: Array<{ __typename: 'Display', id: string }>, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }> };

export type PowerScheduleUpdateMutationVariables = Types.Exact<{
  input: Types.PowerScheduleUpdateInput;
}>;


export type PowerScheduleUpdateMutation = { __typename: 'Mutation', powerScheduleUpdate: { __typename: 'PowerScheduleUpdatePayload', powerSchedule: { __typename: 'PowerSchedule', id: string, title: string, description?: string | null, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }>, allDisplays: Array<{ __typename: 'Display', id: string }>, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }> } } };

export const PowerScheduleDetail_CustomerFragmentDoc = gql`
    fragment PowerScheduleDetail_customer on Customer {
  id
  name
}
    `;
export const PowerScheduleDetail_PowerScheduleFragmentDoc = gql`
    fragment PowerScheduleDetail_powerSchedule on PowerSchedule {
  id
  title
  description
  timeBlocks {
    start
    end
    day
  }
  allDisplays: displays {
    id
  }
  syncedDisplays: displays(filter: {state: SYNCED}) {
    id
  }
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
  syncingDisplays: displays(filter: {state: SYNCING}) {
    id
  }
  removingDisplays: displays(filter: {state: REMOVING}) {
    id
  }
}
    `;
export const PowerScheduleUpdateDocument = gql`
    mutation PowerScheduleUpdate($input: PowerScheduleUpdateInput!) {
  powerScheduleUpdate(input: $input) {
    powerSchedule {
      id
      ...PowerScheduleDetail_powerSchedule
    }
  }
}
    ${PowerScheduleDetail_PowerScheduleFragmentDoc}`;
export type PowerScheduleUpdateMutationFn = ApolloReactCommon.MutationFunction<PowerScheduleUpdateMutation, PowerScheduleUpdateMutationVariables>;

/**
 * __usePowerScheduleUpdateMutation__
 *
 * To run a mutation, you first call `usePowerScheduleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePowerScheduleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [powerScheduleUpdateMutation, { data, loading, error }] = usePowerScheduleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePowerScheduleUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PowerScheduleUpdateMutation, PowerScheduleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PowerScheduleUpdateMutation, PowerScheduleUpdateMutationVariables>(PowerScheduleUpdateDocument, options);
      }
export type PowerScheduleUpdateMutationHookResult = ReturnType<typeof usePowerScheduleUpdateMutation>;
export type PowerScheduleUpdateMutationResult = ApolloReactCommon.MutationResult<PowerScheduleUpdateMutation>;
export type PowerScheduleUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PowerScheduleUpdateMutation, PowerScheduleUpdateMutationVariables>;