/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayKeyboardControlToggle_DisplayFragment = { __typename: 'Display', id: string, keyboardControl?: { __typename: 'ControlState', desired?: Types.ControlLockState | null, reported: Types.ControlLockState, supportedValues: Array<Types.ControlLockState> } | null };

export type UpdateKeyboardControlMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateKeyboardControlInput;
}>;


export type UpdateKeyboardControlMutation = { __typename: 'Mutation', displayBulkUpdateKeyboardControl: { __typename: 'DisplayBulkUpdateKeyboardControlPayload', displays: Array<{ __typename: 'Display', id: string, keyboardControl?: { __typename: 'ControlState', desired?: Types.ControlLockState | null, reported: Types.ControlLockState, supportedValues: Array<Types.ControlLockState> } | null }> } };

export const DisplayKeyboardControlToggle_DisplayFragmentDoc = gql`
    fragment DisplayKeyboardControlToggle_display on Display {
  id
  keyboardControl {
    desired
    reported
    supportedValues
  }
}
    `;
export const UpdateKeyboardControlDocument = gql`
    mutation UpdateKeyboardControl($input: DisplayBulkUpdateKeyboardControlInput!) {
  displayBulkUpdateKeyboardControl(input: $input) {
    displays {
      id
      keyboardControl {
        desired
        reported
        supportedValues
      }
    }
  }
}
    `;
export type UpdateKeyboardControlMutationFn = ApolloReactCommon.MutationFunction<UpdateKeyboardControlMutation, UpdateKeyboardControlMutationVariables>;

/**
 * __useUpdateKeyboardControlMutation__
 *
 * To run a mutation, you first call `useUpdateKeyboardControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeyboardControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeyboardControlMutation, { data, loading, error }] = useUpdateKeyboardControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKeyboardControlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateKeyboardControlMutation, UpdateKeyboardControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateKeyboardControlMutation, UpdateKeyboardControlMutationVariables>(UpdateKeyboardControlDocument, options);
      }
export type UpdateKeyboardControlMutationHookResult = ReturnType<typeof useUpdateKeyboardControlMutation>;
export type UpdateKeyboardControlMutationResult = ApolloReactCommon.MutationResult<UpdateKeyboardControlMutation>;
export type UpdateKeyboardControlMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateKeyboardControlMutation, UpdateKeyboardControlMutationVariables>;