/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type SizeCell_PlaylistFragment = { __typename: 'Playlist', id: string, size: any };

export const SizeCell_PlaylistFragmentDoc = gql`
    fragment SizeCell_playlist on Playlist {
  id
  size
}
    `;