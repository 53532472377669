import { IconButton } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { forwardRef, LegacyRef, MouseEventHandler } from 'react';
import { MoreIcon } from '~components/ui/icons';

export const ActionMenuButton = forwardRef(function ActionMenuButton(
  {
    onClick,
    disabled,
    sx,
  }: {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    sx?: SystemStyleObject;
  },
  ref: LegacyRef<HTMLButtonElement>,
) {
  return (
    <IconButton
      ref={ref}
      variant="unstyled"
      height="8"
      minWidth="8"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="gray.200"
      borderRadius="full"
      aria-label="actions"
      icon={<MoreIcon color="gray.300" width="actionIconSize" height="actionIconSize" />}
      onClick={onClick}
      isDisabled={disabled}
      sx={sx}
    />
  );
});
