import { gql } from '@apollo/client';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { PropsWithChildren, useCallback } from 'react';
import { CellProps } from 'react-table';
import { useAuth } from '~auth/useAuth';
import { DeletePlaylistsModal } from '~components/playlists/DeletePlaylistsModal';
import { SyncDisplaysModal } from '~components/playlists/SyncDisplaysModal';
import { ActionMenuButton } from '~components/ui/ActionMenuButton';
import { LoadingIcon, NoDisplayIcon, SyncIcon, TrashIcon } from '~components/ui/icons';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { useRemovePlaylistFromDisplays } from '../../useRemovePlaylistFromDisplays';
import { PlaylistTable_PlaylistFragment } from '../__generated__/PlaylistTable.graphql';
import { ActionsCell_PlaylistFragment } from './__generated__/ActionsCell.graphql';

export function ActionsCell({
  value,
}: PropsWithChildren<CellProps<PlaylistTable_PlaylistFragment, ActionsCell_PlaylistFragment>>) {
  const { verifyUserPermissions } = useAuth();
  const syncDisplaysModal = useDisclosure();
  const deletePlaylistsModal = useDisclosure();

  const hasOutOfSyncDisplays = value.outOfSyncDisplays.length > 0;
  const isSyncing = value.syncingDisplays.length > 0;
  const isRemoving = value.removingDisplays.length > 0;
  const isPlaylistUpdatingOnDisplays = isSyncing || isRemoving;
  const syncOperationLabel = 'Update in progress';

  const hasDisplayContentDeletePermission = verifyUserPermissions([
    Permission.DisplayContentDelete,
  ]);
  const hasPlaylistDeletePermission = verifyUserPermissions([Permission.PlaylistDelete]);

  const handleDeletePlaylistsSuccess = useCallback(() => {
    deletePlaylistsModal.onClose();
  }, [deletePlaylistsModal]);

  const handleSyncToDisplaysSuccess = useCallback(() => {
    syncDisplaysModal.onClose();
  }, [syncDisplaysModal]);

  const { removePlaylistFromDisplays, loading: isInitiatingRemove } =
    useRemovePlaylistFromDisplays();

  const handleRemovePlaylistsFromDisplays = async () => {
    await removePlaylistFromDisplays(value.id);
  };

  return (
    <>
      <StopClickPropagation>
        <Flex justifyContent="center">
          {isPlaylistUpdatingOnDisplays || isInitiatingRemove ? (
            <Tooltip label={syncOperationLabel}>
              <Flex
                aria-label={syncOperationLabel}
                justifyContent="center"
                alignItems="center"
                height="8"
                minWidth="8"
              >
                <LoadingIcon color="gray.400" height={5} width={5} />
              </Flex>
            </Tooltip>
          ) : (
            <Menu placement="bottom-end">
              <MenuButton as={ActionMenuButton} />
              <MenuList>
                <MenuItem
                  icon={<SyncIcon />}
                  onClick={syncDisplaysModal.onOpen}
                  isDisabled={!hasOutOfSyncDisplays}
                >
                  Sync
                </MenuItem>
                <MenuItem
                  icon={<NoDisplayIcon />}
                  onClick={handleRemovePlaylistsFromDisplays}
                  isDisabled={!hasDisplayContentDeletePermission || value.allDisplays.length === 0}
                >
                  Remove from displays
                </MenuItem>
                <MenuItem
                  color={'red.500'}
                  icon={<TrashIcon color="red.500" />}
                  onClick={deletePlaylistsModal.onOpen}
                  isDisabled={!hasPlaylistDeletePermission}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </StopClickPropagation>
      <SyncDisplaysModal
        isOpen={syncDisplaysModal.isOpen}
        playlist={value}
        onCancel={syncDisplaysModal.onClose}
        onSuccess={handleSyncToDisplaysSuccess}
      />
      <DeletePlaylistsModal
        isOpen={deletePlaylistsModal.isOpen}
        playlists={[value]}
        onCancel={deletePlaylistsModal.onClose}
        onSuccess={handleDeletePlaylistsSuccess}
      />
    </>
  );
}

ActionsCell.graphql = {
  fragments: {
    ActionsCell_playlist: gql`
      fragment ActionsCell_playlist on Playlist {
        id
        syncingDisplays: displays(filter: { state: SYNCING }) {
          id
        }
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
        removingDisplays: displays(filter: { state: REMOVING }) {
          id
        }
        allDisplays: displays {
          id
        }
        ...SyncDisplaysModal_playlist
        ...DeletePlaylistsModal_playlist
        ...useRemovePlaylistFromDisplays_playlist
      }
    `,
  },
};
