import { createIcon } from '@chakra-ui/react';

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '5',
    width: '5',
  },
  path: (
    <>
      <path
        d="M11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16H11ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12H13ZM12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9V7ZM12.01 9C12.5623 9 13.01 8.55228 13.01 8C13.01 7.44772 12.5623 7 12.01 7V9ZM21 12C21 16.9706 16.9706 21 12 21V23C18.0751 23 23 18.0751 23 12H21ZM12 21C7.02944 21 3 16.9706 3 12H1C1 18.0751 5.92487 23 12 23V21ZM3 12C3 7.02944 7.02944 3 12 3V1C5.92487 1 1 5.92487 1 12H3ZM12 3C16.9706 3 21 7.02944 21 12H23C23 5.92487 18.0751 1 12 1V3ZM13 16V12H11V16H13ZM12 9H12.01V7H12V9Z"
        fill="currentColor"
      />
    </>
  ),
});
