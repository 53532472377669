/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkAddGroups_CustomerFragment = { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type BulkAddGroupsMutationVariables = Types.Exact<{
  input: Types.DisplayBulkAddGroupsInput;
}>;


export type BulkAddGroupsMutation = { __typename: 'Mutation', displayBulkAddGroups: { __typename: 'DisplayBulkAddGroupsPayload', displays: Array<{ __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> }> } };

export type GroupCreateMutationVariables = Types.Exact<{
  input: Types.GroupCreateInput;
}>;


export type GroupCreateMutation = { __typename: 'Mutation', groupCreate: { __typename: 'GroupCreatePayload', group?: { __typename: 'Group', id: string, name: string } | null, customer?: { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> } | null } };

export const BulkAddGroups_CustomerFragmentDoc = gql`
    fragment BulkAddGroups_customer on Customer {
  id
  groups {
    id
    name
  }
}
    `;
export const BulkAddGroupsDocument = gql`
    mutation BulkAddGroups($input: DisplayBulkAddGroupsInput!) {
  displayBulkAddGroups(input: $input) {
    displays {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type BulkAddGroupsMutationFn = ApolloReactCommon.MutationFunction<BulkAddGroupsMutation, BulkAddGroupsMutationVariables>;

/**
 * __useBulkAddGroupsMutation__
 *
 * To run a mutation, you first call `useBulkAddGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddGroupsMutation, { data, loading, error }] = useBulkAddGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkAddGroupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkAddGroupsMutation, BulkAddGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkAddGroupsMutation, BulkAddGroupsMutationVariables>(BulkAddGroupsDocument, options);
      }
export type BulkAddGroupsMutationHookResult = ReturnType<typeof useBulkAddGroupsMutation>;
export type BulkAddGroupsMutationResult = ApolloReactCommon.MutationResult<BulkAddGroupsMutation>;
export type BulkAddGroupsMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkAddGroupsMutation, BulkAddGroupsMutationVariables>;
export const GroupCreateDocument = gql`
    mutation GroupCreate($input: GroupCreateInput!) {
  groupCreate(input: $input) {
    group {
      id
      name
    }
    customer {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type GroupCreateMutationFn = ApolloReactCommon.MutationFunction<GroupCreateMutation, GroupCreateMutationVariables>;

/**
 * __useGroupCreateMutation__
 *
 * To run a mutation, you first call `useGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCreateMutation, { data, loading, error }] = useGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GroupCreateMutation, GroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GroupCreateMutation, GroupCreateMutationVariables>(GroupCreateDocument, options);
      }
export type GroupCreateMutationHookResult = ReturnType<typeof useGroupCreateMutation>;
export type GroupCreateMutationResult = ApolloReactCommon.MutationResult<GroupCreateMutation>;
export type GroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<GroupCreateMutation, GroupCreateMutationVariables>;