import { gql } from '@apollo/client';
import { Box, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack } from '@chakra-ui/react';
import { TableInstance } from 'react-table';
import { InfoAlert } from '~components/ui/Alert';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { defaultPageSizes } from '~components/ui/PageSizeSelector';
import { isWaveSubscription } from '~utils/subscriptions';
import { SubscriptionCustomersTableCustomerFragment as CustomerFragment } from './__generated__/SubscriptionCustomersTable.graphql';
import { SubscriptionCustomersTableFilter } from './Filtering';
import {
  SubscriptionCustomersTablePageSizeSelector,
  SubscriptionCustomersTablePagination,
} from './Pagination';

interface SubscriptionCustomersTableProps {
  table: TableInstance<CustomerFragment>;
  currentCustomerIds: string[];
  availableSeats: number;
}

export function SubscriptionCustomersTable({
  table,
  currentCustomerIds,
  availableSeats,
}: SubscriptionCustomersTableProps) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = table;

  return (
    <VStack spacing="6" alignItems="stretch">
      <Text color="gray.500">Select customers to link them to this subscription plan.</Text>{' '}
      <InfoAlert mb="6">
        A customer cannot be linked to multiple Essential Subscriptions, nor to a subscription that
        cannot accommodate the customers&apos; displays within the available seats.
      </InfoAlert>
      <SubscriptionCustomersTableFilter table={table} />
      <Table {...getTableProps()} variant="simple" width="100%" marginTop="8">
        <Thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();

            return (
              <Tr {...headerGroupProps} key={headerGroupProps.key}>
                {headerGroup.headers.map((column) => {
                  const sortProps = column.getSortByToggleProps();
                  const sortColumn = sortProps.onClick;
                  const headerProps = column.getHeaderProps(sortProps);

                  return (
                    <Th
                      {...headerProps}
                      key={headerProps.key}
                      color={column.isSorted ? 'gray.700' : 'gray.600'}
                      minWidth={`${column.minWidth}px`}
                      maxWidth={`${column.maxWidth}px`}
                      width={column.width}
                      _hover={
                        column.disableSortBy
                          ? {}
                          : {
                              textColor: 'gray.800',
                            }
                      }
                      onClick={sortColumn}
                    >
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flex="1">{column.render('Header')}</Box>
                        {column.canSort && (
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDownIcon display="inherit" />
                              ) : (
                                <ChevronUpIcon display="inherit" />
                              )
                            ) : (
                              <ChevronDownIcon visibility="hidden" />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            const hasAnotherSubscription =
              !currentCustomerIds.includes(row.original.id) &&
              isWaveSubscription(row.original.waveSubscription);
            const notEnoughAvailableSeats =
              row.original.displayCount > availableSeats && !row.cells[0].row.isSelected;

            const isDisabled = hasAnotherSubscription || notEnoughAvailableSeats;

            return (
              <Tooltip
                label={
                  hasAnotherSubscription
                    ? 'Allready linked to a different subscription'
                    : notEnoughAvailableSeats
                    ? 'Not enough available seats'
                    : ''
                }
                isDisabled={!isDisabled}
                hasArrow
                key={rowProps.key}
              >
                <Tr
                  {...rowProps}
                  {...(isDisabled ? { background: 'gray.50' } : { background: 'blue.' })}
                >
                  {row.cells.map((cell) => {
                    const cellProps = cell.getCellProps();
                    return (
                      <Td
                        {...cellProps}
                        {...(isDisabled ? { opacity: 0.5 } : {})}
                        minWidth={`${cell.column.minWidth}px`}
                        maxWidth={`${cell.column.maxWidth}px`}
                        width={cell.column.width}
                        key={cellProps.key}
                      >
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              </Tooltip>
            );
          })}
        </Tbody>
      </Table>
      <Box display="flex">
        <Box flex="1">
          <SubscriptionCustomersTablePageSizeSelector
            table={table}
            sizes={[15, ...defaultPageSizes]}
          />
        </Box>
        <SubscriptionCustomersTablePagination table={table} />
      </Box>
    </VStack>
  );
}

SubscriptionCustomersTable.graphql = {
  fragments: {
    SubscriptionCustomersTableCustomer: gql`
      fragment SubscriptionCustomersTableCustomer on Customer {
        id
        name
        displayCount
        waveSubscription {
          id
        }
      }
    `,
  },
};
