/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UserCustomersTable_CustomerFragmentDoc } from '../../../../../components/organization/UserCustomersTable/__generated__/UserCustomersTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UserCreatePageOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCreatePageOrganizationQuery = { __typename: 'Query', customersByOrganization: { __typename: 'QueryCustomersByOrganizationPayload', customers: Array<{ __typename: 'PartialCustomer', id: string, name: string, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null }> }, organization: { __typename: 'Organization', id: string }, me: { __typename: 'CurrentUser', id: string } };

export type CreateUserMutationVariables = Types.Exact<{
  input: Types.UserCreateInput;
}>;


export type CreateUserMutation = { __typename: 'Mutation', userCreate: { __typename: 'User', id: string, email: string, roles: Array<Types.Role>, givenName: string, familyName: string } };


export const UserCreatePageOrganizationDocument = gql`
    query UserCreatePageOrganization {
  customersByOrganization {
    customers {
      ...UserCustomersTable_customer
    }
  }
  organization {
    id
  }
  me {
    id
  }
}
    ${UserCustomersTable_CustomerFragmentDoc}`;

/**
 * __useUserCreatePageOrganizationQuery__
 *
 * To run a query within a React component, call `useUserCreatePageOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreatePageOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreatePageOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreatePageOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCreatePageOrganizationQuery, UserCreatePageOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserCreatePageOrganizationQuery, UserCreatePageOrganizationQueryVariables>(UserCreatePageOrganizationDocument, options);
      }
export function useUserCreatePageOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCreatePageOrganizationQuery, UserCreatePageOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserCreatePageOrganizationQuery, UserCreatePageOrganizationQueryVariables>(UserCreatePageOrganizationDocument, options);
        }
export type UserCreatePageOrganizationQueryHookResult = ReturnType<typeof useUserCreatePageOrganizationQuery>;
export type UserCreatePageOrganizationLazyQueryHookResult = ReturnType<typeof useUserCreatePageOrganizationLazyQuery>;
export type UserCreatePageOrganizationQueryResult = ApolloReactCommon.QueryResult<UserCreatePageOrganizationQuery, UserCreatePageOrganizationQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: UserCreateInput!) {
  userCreate(input: $input) {
    id
    email
    roles
    givenName
    familyName
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;