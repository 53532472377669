/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { Wizard_CustomerFragmentDoc } from './Wizard.graphql';
export type ClaimDisplayModal_CustomerFragment = { __typename: 'Customer', id: string, waveSubscription?: { __typename: 'WaveSubscription', usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } } | null, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export const ClaimDisplayModal_CustomerFragmentDoc = gql`
    fragment ClaimDisplayModal_customer on Customer {
  id
  ...Wizard_customer
}
    ${Wizard_CustomerFragmentDoc}`;