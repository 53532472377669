import { Box, Checkbox, CheckboxProps, Tag } from '@chakra-ui/react';
import _ from 'lodash';
import { ChangeEvent, forwardRef, Ref, useCallback, useRef, useState } from 'react';
import { useCombinedRefs } from '~utils/useCombinedRefs';
import { StopClickPropagation } from './StopClickPropagation';

type Props = CheckboxProps;

export const FancyRemovalCheckbox = forwardRef(
  (
    { children, colorScheme = 'blue', isChecked: originalIsChecked, onChange, ...rest }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const mergedRef = useCombinedRefs(ref, innerRef);

    const [isChecked, setIsChecked] = useState(originalIsChecked);

    const setNativeCheckbox = useCallback(() => {
      if (!mergedRef.current) return;
      const checkbox = mergedRef.current;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set.call(
        checkbox,
        !checkbox.checked,
      );

      checkbox.dispatchEvent(new Event('click', { bubbles: true }));
    }, [mergedRef]);

    const handleClick = useCallback(() => {
      setNativeCheckbox();
    }, [setNativeCheckbox]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        onChange?.(e);
      },
      [onChange],
    );

    return (
      <Box
        display="flex"
        alignItems="center"
        paddingX="6"
        paddingY="7"
        borderRadius="base"
        border="1px solid"
        borderColor="gray.200"
        userSelect="none"
        cursor="pointer"
        background={isChecked ? 'gray.50' : 'white'}
        onClick={handleClick}
      >
        <StopClickPropagation display="flex" alignItems="center">
          <Checkbox
            ref={mergedRef}
            colorScheme={colorScheme}
            {...rest}
            isChecked={isChecked}
            onClick={_.noop}
            onChange={handleChange}
          />
        </StopClickPropagation>
        <Box marginLeft="8" display="flex" alignItems="center" overflow="hidden">
          <Tag
            variant="outline"
            colorScheme={colorScheme}
            textDecoration={isChecked ? 'line-through' : 'none'}
            color={isChecked ? 'gray.200' : `${colorScheme}.500`}
            borderColor={isChecked ? 'gray.200' : `${colorScheme}.200`}
            background={isChecked ? 'white' : 'inherit'}
            display="inline-block"
            lineHeight="unset"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {children}
          </Tag>
        </Box>
      </Box>
    );
  },
);
