import { cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip = {
  baseStyle: {
    maxWidth: 'unset',
    bg: 'blue.800',
    [$arrowBg.variable]: 'colors.blue.800',
  },
};
