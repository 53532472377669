/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRecommendedSettings_DisplayFragmentDoc } from './useRecommendedSettings.graphql';
import { UsePlaylists_DisplayFragmentDoc } from './usePlaylists.graphql';
import { UsePowerSchedules_DisplayFragmentDoc } from './usePowerSchedules.graphql';
export type UseStatus_DisplayFragment = { __typename: 'Display', id: string, hasEmptyShadow: boolean, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, id: string, title: string, description?: string | null, jobId: string } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, id: string, title: string, description?: string | null, jobId: string } | null } | null, powerSchedule?: { __typename: 'DisplayPowerSchedule', isSynced?: boolean | null, schedule?: { __typename: 'PowerSchedule', id: string, title: string } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateCompleted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateInProgress', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateRejected', rejectionCode: string, id: string, scheduleId?: string | null } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export const UseStatus_DisplayFragmentDoc = gql`
    fragment UseStatus_display on Display {
  id
  hasEmptyShadow
  ...UseRecommendedSettings_display
  ...UsePlaylists_display
  ...UsePowerSchedules_display
}
    ${UseRecommendedSettings_DisplayFragmentDoc}
${UsePlaylists_DisplayFragmentDoc}
${UsePowerSchedules_DisplayFragmentDoc}`;