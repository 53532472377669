import { Button } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Row, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import { useDeleteCustomer } from '~components/customers/mutations/DeleteCustomer';
import { EditIcon, TrashIcon } from '~components/ui/icons';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { SubscriptionTag } from '~components/ui/SubscriptionTag';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { useAnalyticsReporter } from '~utils/analytics';
import { Feature, useFeatureFlag } from '~utils/features';
import { getSubscriptionType } from '~utils/subscriptions';
import { CustomersTableCustomerFragment } from './__generated__/CustomersTable.graphql';
import { Columns } from './types';

const TableCell = generateTableCellComponent<CustomersTableCustomerFragment, ReactNode>();

const TableCellBold = generateTableCellComponent<CustomersTableCustomerFragment, ReactNode>({
  isBold: true,
});

function CustomerActionsCell({
  row: { original: customer },
}: {
  row: Row<CustomersTableCustomerFragment>;
}) {
  const navigate = useNavigate();
  const analytics = useAnalyticsReporter();

  const deleteCustomer = useDeleteCustomer({
    onCompleted: () => {
      analytics.track('customerDeleteComplete');
    },
  });

  const handleDelete = async () => {
    analytics.track('customerDeleteStart');
    await deleteCustomer.action.askConfirmation(customer);
  };

  const handleEdit = () => navigate(customer.id, { state: { canGoBack: true } });

  return (
    <Shield requiredPermissions={[Permission.CustomerDelete, Permission.CustomerUpdate]}>
      <Button size="sm" variant="inline" colorScheme="gray" onClick={handleEdit}>
        <EditIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
      </Button>
      <Button size="sm" variant="inline" colorScheme="gray" onClick={handleDelete}>
        <TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
      </Button>
      {deleteCustomer.action.confirmationNode}
    </Shield>
  );
}

export function useCustomersTable(data: CustomersTableCustomerFragment[]) {
  const { verifyUserPermissions } = useAuth();

  const columns = useMemo<Array<Column<CustomersTableCustomerFragment>>>(
    () => [
      {
        id: Columns.Name,
        Header: 'Customer',
        accessor: (customer) => customer.name,
        Cell: TableCellBold,
        width: 'auto',
      },
      {
        id: Columns.Users,
        Header: 'Users',
        accessor: (customer) => customer.users.length,
        Cell: TableCell,
        width: '140px',
      },
      {
        id: Columns.Displays,
        Header: 'Displays',
        accessor: 'displayCount',
        Cell: TableCell,
        width: '140px',
      },
      {
        id: Columns.Subscription,
        Header: 'Subscription',
        Cell: ({ row }: { row: Row<CustomersTableCustomerFragment> }) => {
          const subscriptionType = getSubscriptionType(row.original.waveSubscription);

          return <SubscriptionTag variant={subscriptionType} />;
        },
        width: 'auto',
      },
      {
        id: Columns.Actions,
        Cell: CustomerActionsCell,
        width: '160px',
      },
    ],
    [],
  );

  const hiddenColumns = useHiddenColumns(verifyUserPermissions, useFeatureFlag);

  return useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Name }],
        filters: [{ id: Columns.Name, value: [] }],
        hiddenColumns,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );
}

function useHiddenColumns(
  verifyUserPermissions: (permissions: Permission[]) => boolean,
  useFeatureFlag: (feature: Feature) => { isEnabled: boolean },
): Columns[] {
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const hiddenColumns: Columns[] = [];

  if (!verifyUserPermissions([Permission.FeatureCustomerScopedAccess])) {
    hiddenColumns.push(Columns.Users);
  }

  if (!isSubscriptionsEnabled) {
    hiddenColumns.push(Columns.Subscription);
  }

  return hiddenColumns;
}
