import {
  Box,
  Button,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useTab,
  VStack,
} from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';
import UpgradeToWaveEssentialEmptyState from '~components/organization/UpgradeToWaveEssentialModal/EmptyState';
import { isWaveSubscription } from '~utils/subscriptions';
import { isDefined } from '~utils/types';
import { CustomerSubscriptionsTable } from './CustomerSubscriptionsTable';
import { CustomerSubscriptionsTableSubscriptionFragment } from './CustomerSubscriptionsTable/__generated__/CustomerSubscriptionsTable.graphql';
import { useCustomerSubscriptionsTable } from './CustomerSubscriptionsTable/useCustomerSubscriptionsTable';

interface SubscriptionsTabProps {
  title: string;
  subtitle?: string;
  showBadge?: boolean;
}

interface CustomerSubscriptionsProps {
  customerSubscriptionId?: string;
  currentSubscriptionId?: string;
  subscriptions: CustomerSubscriptionsTableSubscriptionFragment[];
  displayCount: number;
  onChange: (subscriptionId: string | undefined) => void;
}

enum TabIndex {
  Lite = 0,
  Essential = 1,
}

export const SubscriptionsTab = forwardRef<HTMLElement, SubscriptionsTabProps>(
  ({ title, subtitle, showBadge = false, ...props }, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];
    const hasSubtitle = isDefined(subtitle) && subtitle !== '';

    return (
      <Button
        variant="unstyled"
        height="auto"
        fontWeight="inherit"
        position="relative"
        padding="6 4"
        backgroundColor="white"
        borderBottom="3px solid"
        borderBottomColor={isSelected ? 'blue.500' : 'transparent'}
        display="flex"
        whiteSpace="pre-wrap"
        textAlign="left"
        {...(hasSubtitle
          ? {
              flexDirection: 'column',
              gap: '1',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }
          : {
              justifyContent: 'space-between',
            })}
        {...tabProps}
      >
        <strong>{title}</strong>
        {showBadge && (
          <Tag
            variant="solid"
            background="gray.50"
            color="blue.800"
            borderRadius="full"
            position={hasSubtitle ? 'absolute' : 'static'}
            top="2"
            right="2"
          >
            Current plan
          </Tag>
        )}
        {hasSubtitle && <span>{subtitle}</span>}
      </Button>
    );
  },
);

export function CustomerSubscriptions({
  currentSubscriptionId,
  customerSubscriptionId,
  subscriptions,
  displayCount,
  onChange,
}: CustomerSubscriptionsProps) {
  const table = useCustomerSubscriptionsTable({
    data: subscriptions,
    displayCount,
    customerSubscriptionId,
    currentSubscriptionId,
    onSelect: onChange,
  });

  const [tabIndex, setTabIndex] = useState(TabIndex.Lite);

  const customerSubscription = isDefined(customerSubscriptionId)
    ? subscriptions.find(({ id }) => id === customerSubscriptionId)
    : null;

  const hasEssentialSubscription = isWaveSubscription(customerSubscription);

  const handleTabsChange = (index: TabIndex) => {
    // When switching from the Essential to Lite tab, we also want to deselect any existing subscription
    if (index === TabIndex.Lite) {
      onChange(undefined);
    }

    if (index === TabIndex.Essential && hasEssentialSubscription) {
      onChange(customerSubscriptionId);
    }

    setTabIndex(index);
  };

  useEffect(() => {
    setTabIndex(hasEssentialSubscription ? TabIndex.Essential : TabIndex.Lite);
  }, [hasEssentialSubscription]);

  return (
    <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabList display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))" gap="1" mb="10">
        <SubscriptionsTab title="Lite Plan" showBadge={!hasEssentialSubscription} />
        <SubscriptionsTab title="Essential Plan" showBadge={hasEssentialSubscription} />
      </TabList>
      <TabPanels>
        <TabPanel></TabPanel>
        <TabPanel padding="0">
          {subscriptions.length > 0 ? (
            <VStack spacing="6" alignItems="stretch">
              <Text color="gray.500">
                Select a subscription in this table to connect{' '}
                <strong>
                  {displayCount} {displayCount === 1 ? 'display' : 'displays'}
                </strong>{' '}
                of this customer to an <strong>Essential Plan</strong>.
              </Text>
              <CustomerSubscriptionsTable table={table} />
            </VStack>
          ) : (
            <Box
              boxShadow="elevated"
              border="1px solid"
              borderRadius="md"
              borderColor="gray.100"
              overflowX="auto"
              background="white"
              paddingX="10"
            >
              <UpgradeToWaveEssentialEmptyState noSubscriptions />
            </Box>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
