import { StyleProps } from './types';

export const Radio = {
  parts: ['container', 'control', 'label'],
  baseStyle: (props: StyleProps) => {
    const { colorScheme: c } = props;

    return {
      control: {
        color: 'white',
        _checked: {
          bg: `${c}.500`,
          borderColor: `${c}.500`,
          color: 'white',
          _hover: {
            bg: `${c}.600`,
            borderColor: `${c}.600`,
            color: 'white',
          },
          _disabled: {
            bg: 'gray.200',
            borderColor: 'gray.200',
            color: 'gray.500',
          },
        },
        _invalid: {
          borderColor: 'red.500',
          color: 'white',
        },
      },
      label: {
        marginLeft: 4,
      },
    };
  },
};
