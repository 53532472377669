import {
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CloseIcon, SearchIcon } from '~components/ui/icons';
import { DisplaysQueryContext } from './useDisplaysQuery';

const SearchBar = () => {
  const { setSearch } = useContext(DisplaysQueryContext);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useRef(
    debounce((newSearch: string) => {
      const currentParams = new URLSearchParams(window.location.search);
      if (newSearch) {
        currentParams.set('search', newSearch); // Set or update the 'search' parameter
      } else {
        currentParams.delete('search'); // Remove the 'search' parameter if empty
      }
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      history.replaceState({}, window.document.title, newUrl);
      setSearch?.(newSearch);
    }, 100),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Handle search input changes
  const handleSearchClick = () => {
    debouncedSearch(searchText);
  };

  const handleInputText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClear = () => {
    setSearchText('');
    setSearch?.('');
  };

  return (
    <InputGroup width="100%">
      <Input
        type="text"
        placeholder="Type to search..."
        onChange={handleInputText}
        value={searchText}
        variant="outline"
        size="md"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearchClick();
          }
        }}
      />
      <InputRightElement
        width="4.5rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip label="Clear search" fontSize="md">
          <IconButton
            aria-label="Clear search"
            icon={<CloseIcon />}
            size="sm"
            _hover={{ backgroundColor: 'transparent' }}
            color="blue.600"
            variant="ghost"
            onClick={handleClear}
            title="Clear search"
          />
        </Tooltip>
        <Divider orientation="vertical" height="60%" borderColor="gray.300" /> {/* Divider */}
        <Tooltip label="Search" fontSize="md">
          <IconButton
            aria-label="Search"
            variant="ghost"
            _hover={{ backgroundColor: 'transparent' }}
            color="blue.600"
            icon={<SearchIcon />}
            size="sm"
            onClick={handleSearchClick}
            title="Search"
          />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchBar;
