import { createIcon } from '@chakra-ui/react';

export const EmptyPowerScheduleIllustration = createIcon({
  displayName: 'EmptyPowerScheduleIllustration',
  viewBox: '0 0 270 270',
  defaultProps: {
    fill: 'none',
    width: '270px',
    height: '270px',
  },
  path: (
    <>
      <circle cx="135" cy="135" r="135" fill="#F1F8FE" />
      <g filter="url(#filter0_dd_4906_1739)">
        <g clipPath="url(#clip0_4906_1739)">
          <g filter="url(#filter1_dd_4906_1739)">
            <rect
              x="84"
              y="90"
              width="126.409"
              height="103"
              rx="4.68182"
              fill="white"
              fillOpacity="0.9"
            />
          </g>
          <rect x="84" y="90" width="126" height="20" fill="#0F5291" />
          <rect x="99" y="123" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="99" y="163" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="119" y="163" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="139" y="143" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="139" y="163" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="159" y="143" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="159" y="163" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="179" y="143" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="179" y="163" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="119" y="123" width="16" height="16" rx="4" fill="#F0F4F8" />
          <g filter="url(#filter2_dd_4906_1739)">
            <rect x="139" y="123" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter3_dd_4906_1739)">
            <rect x="99" y="143" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter4_dd_4906_1739)">
            <rect x="159" y="123" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter5_dd_4906_1739)">
            <rect x="119" y="143" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter6_dd_4906_1739)">
            <rect x="179" y="123" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
        </g>
      </g>
      <g filter="url(#filter7_dd_4906_1739)">
        <g clipPath="url(#clip1_4906_1739)">
          <g filter="url(#filter8_dd_4906_1739)">
            <rect x="60" y="76" width="126.409" height="103" rx="4.68182" fill="white" />
          </g>
          <rect x="60" y="76" width="126" height="20" fill="#0F5291" />
          <rect x="75" y="109" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="75" y="149" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="95" y="149" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="115" y="129" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="115" y="149" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="135" y="129" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="135" y="149" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="155" y="129" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="155" y="149" width="16" height="16" rx="4" fill="#F0F4F8" />
          <rect x="95" y="109" width="16" height="16" rx="4" fill="#F0F4F8" />
          <g filter="url(#filter9_dd_4906_1739)">
            <rect x="115" y="109" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter10_dd_4906_1739)">
            <rect x="75" y="129" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter11_dd_4906_1739)">
            <rect x="135" y="109" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter12_dd_4906_1739)">
            <rect x="95" y="129" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
          <g filter="url(#filter13_dd_4906_1739)">
            <rect x="155" y="109" width="16" height="16" rx="4" fill="#D5E9FB" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_4906_1739"
          x="69.9545"
          y="87.6591"
          width="154.091"
          height="131.091"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.34091"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4.68182" />
          <feGaussianBlur stdDeviation="3.51136" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.51136"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="11.7045" />
          <feGaussianBlur stdDeviation="8.77841" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_4906_1739"
          x="69.9545"
          y="87.6591"
          width="154.5"
          height="131.091"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.34091"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4.68182" />
          <feGaussianBlur stdDeviation="3.51136" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.51136"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="11.7045" />
          <feGaussianBlur stdDeviation="8.77841" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_4906_1739"
          x="127"
          y="121"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_4906_1739"
          x="87"
          y="141"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_dd_4906_1739"
          x="147"
          y="121"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_dd_4906_1739"
          x="107"
          y="141"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_dd_4906_1739"
          x="167"
          y="121"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_dd_4906_1739"
          x="45.9545"
          y="73.6591"
          width="154.091"
          height="131.091"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.34091"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4.68182" />
          <feGaussianBlur stdDeviation="3.51136" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.51136"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="11.7045" />
          <feGaussianBlur stdDeviation="8.77841" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_dd_4906_1739"
          x="45.9545"
          y="73.6591"
          width="154.5"
          height="131.091"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.34091"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4.68182" />
          <feGaussianBlur stdDeviation="3.51136" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.51136"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="11.7045" />
          <feGaussianBlur stdDeviation="8.77841" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_dd_4906_1739"
          x="103"
          y="107"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_dd_4906_1739"
          x="63"
          y="127"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_dd_4906_1739"
          x="123"
          y="107"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter12_dd_4906_1739"
          x="83"
          y="127"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <filter
          id="filter13_dd_4906_1739"
          x="143"
          y="107"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_4906_1739"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4906_1739" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_4906_1739"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4906_1739"
            result="effect2_dropShadow_4906_1739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4906_1739"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4906_1739">
          <rect x="84" y="90" width="126" height="103" rx="4" fill="white" />
        </clipPath>
        <clipPath id="clip1_4906_1739">
          <rect x="60" y="76" width="126" height="103" rx="4" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
