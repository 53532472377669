import { useEffect } from 'react';

export type PollingOptions = {
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  pollInterval: number;
};

export const useConditionalPolling = ({
  startPolling,
  stopPolling,
  pollInterval,
}: PollingOptions) => {
  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'hidden') {
        stopPolling();
      } else {
        startPolling(pollInterval);
      }
    };

    document.addEventListener('visibilitychange', listener);

    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [pollInterval, startPolling, stopPolling]);
};
