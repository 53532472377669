import { gql } from '@apollo/client';
import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission } from '@tp-vision/roles-permissions';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { z } from 'zod';
import { useAuth } from '~auth/useAuth';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components, SelectOption, selectOptionSchema } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { isDefined } from '~utils/types';
import { ChangeDefaultContentSourceModal_DisplayFragment } from './__generated__/ChangeDefaultContentSourceModal.graphql';
import {
  UseContentSource_ContentSource_AppContentSource_Fragment,
  UseContentSource_ContentSource_InputContentSource_Fragment,
  UseContentSource_ContentSourceFragment,
} from './__generated__/useContentSource.graphql';
import { useContentSource } from './useContentSource';
import {
  isAppContentSource,
  isBookmarkContentSource,
  isInputContentSource,
  isPlaylistContentSource,
} from './utils';

interface Props {
  display: ChangeDefaultContentSourceModal_DisplayFragment;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}

export function ChangeDefaultContentSourceModal({
  display,
  isOpen,
  onCancel,
  onSuccess,
}: Props & { isOpen: boolean }) {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ChangeDefaultContentSourceModalContent
          display={display}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      </ModalContent>
    </Modal>
  );
}

const schema = z.object({
  displayId: z.string(),
  defaultContentSource: selectOptionSchema,
  contentSourceOption: selectOptionSchema.optional().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

const ContentSourceOption = {
  App: 'app',
  Bookmark: 'bookmark',
  Input: 'input',
  Playlist: 'playlist',
} as const;

function ChangeDefaultContentSourceModalContent({ display, onCancel, onSuccess }: Props) {
  const contentSourceToSelectOption = useCallback(
    (contentSource: UseContentSource_ContentSourceFragment | undefined, asLabel = true) => {
      if (isAppContentSource(contentSource)) {
        return {
          label: 'App',
          value: asLabel ? ContentSourceOption.App : contentSource.applicationId,
        };
      }

      if (isBookmarkContentSource(contentSource)) {
        return {
          label: 'Bookmark',
          value: asLabel ? ContentSourceOption.Bookmark : contentSource.index.toString(),
        };
      }

      if (isPlaylistContentSource(contentSource)) {
        return {
          label: 'Playlist',
          value: asLabel ? ContentSourceOption.Playlist : contentSource.playlistId,
        };
      }

      if (isInputContentSource(contentSource)) {
        return {
          label: 'Input',
          value: asLabel ? ContentSourceOption.Input : contentSource.source,
        };
      }

      throw new Error('Unsupported content source');
    },
    [],
  );

  const contentSourceOptions = useMemo(() => {
    if (!isDefined(display.contentSource) || !isDefined(display.contentSource.available)) {
      return [];
    }

    return (
      display.contentSource.available
        // Get unique options.
        .reduce<UseContentSource_ContentSourceFragment[]>((results, contentSource) => {
          if (!results.find((result) => result.__typename === contentSource.__typename)) {
            results.push(contentSource);
          }

          return results;
        }, [])
        // Some apps cannot be set as a default content source. Filter them out.
        .filter((contentSource) => {
          if (contentSource.__typename === 'AppContentSource') {
            return isDefined(contentSource.isBootable) ? contentSource.isBootable : false;
          } else {
            return true;
          }
        })
        // Map to label+value
        .map((contentSource) => contentSourceToSelectOption(contentSource))
        // Sort alphabetically.
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        })
    );
  }, [contentSourceToSelectOption, display.contentSource]);

  const allBookmarks = useMemo(
    () => display.bookmarks?.all.desired ?? display.bookmarks.all.reported ?? [],
    [display.bookmarks.all],
  );

  const currentDefaultContentSource = useMemo(
    () => display.contentSource?.default?.desired ?? display.contentSource?.default?.reported,
    [display],
  );

  const initialContentSourceOption = useMemo<SelectOption | undefined>(() => {
    return isDefined(currentDefaultContentSource) && !isEmpty(currentDefaultContentSource)
      ? contentSourceToSelectOption(currentDefaultContentSource)
      : contentSourceOptions.at(0);
  }, [contentSourceOptions, contentSourceToSelectOption, currentDefaultContentSource]);

  const bookmarkOptions = useMemo(
    () =>
      isDefined(allBookmarks)
        ? allBookmarks
            .map<SelectOption>((name, index) => ({
              label: name as string,
              value: index.toString(),
            }))
            .filter((element) => element.label !== '')
        : undefined,
    [allBookmarks],
  );

  const playlistOptions = useMemo<SelectOption[]>(() => {
    const id = display.playlist?.current?.id;
    const title = display.playlist?.current?.title;

    if (isDefined(title) && isDefined(id)) {
      return [
        {
          label: title,
          value: id,
        },
      ];
    }

    return [];
  }, [display.playlist]);

  const appOptions = useMemo<SelectOption[]>(() => {
    if (isDefined(display.contentSource) && Array.isArray(display.contentSource.available)) {
      return display.contentSource.available
        .filter(
          (
            contentSource: UseContentSource_ContentSourceFragment,
          ): contentSource is UseContentSource_ContentSource_AppContentSource_Fragment => {
            if (contentSource.__typename === 'AppContentSource') {
              if (isDefined(contentSource.isBootable)) {
                return contentSource.isBootable;
              }

              return true;
            }

            return false;
          },
        )
        .map((contentSource) => {
          return {
            label: contentSource.label || contentSource.applicationId,
            value: contentSource.applicationId,
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1));
    }

    return [];
  }, [display.contentSource]);

  const inputOptions = useMemo<SelectOption[]>(() => {
    if (isDefined(display.contentSource) && Array.isArray(display.contentSource.available)) {
      return display.contentSource.available
        .filter(
          (
            contentSource: UseContentSource_ContentSourceFragment,
          ): contentSource is UseContentSource_ContentSource_InputContentSource_Fragment =>
            contentSource.__typename === 'InputContentSource',
        )
        .map((contentSource) => {
          return {
            label: contentSource.source,
            value: contentSource.source,
          };
        });
    }

    return [];
  }, [display.contentSource]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      displayId: display.id,
      defaultContentSource: initialContentSourceOption,
      contentSourceOption: undefined,
    },
    resolver: zodResolver(schema),
  });

  const defaultContentSourceWatch = watch('defaultContentSource');

  const initialContentSourceValue = useMemo(() => {
    if (initialContentSourceOption?.value !== defaultContentSourceWatch?.value) {
      return undefined;
    }

    return isDefined(currentDefaultContentSource) && !isEmpty(currentDefaultContentSource)
      ? contentSourceToSelectOption(currentDefaultContentSource, false).value
      : undefined;
  }, [
    contentSourceToSelectOption,
    currentDefaultContentSource,
    defaultContentSourceWatch?.value,
    initialContentSourceOption?.value,
  ]);

  useEffect(() => {
    let value = undefined;

    if (defaultContentSourceWatch?.value === ContentSourceOption.App) {
      if (isDefined(initialContentSourceValue)) {
        value = appOptions.find((option) => option.value === initialContentSourceValue);
      } else {
        value = appOptions.at(0);
      }
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Bookmark) {
      if (isDefined(initialContentSourceValue)) {
        value = bookmarkOptions?.find((option) => option.value === initialContentSourceValue);
      } else {
        value = bookmarkOptions?.at(0);
      }
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Input) {
      if (isDefined(initialContentSourceValue)) {
        value = inputOptions.find((option) => option.value === initialContentSourceValue);
      } else {
        value = inputOptions.at(0);
      }
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Playlist) {
      if (isDefined(initialContentSourceValue)) {
        value = playlistOptions.find((option) => option.value === initialContentSourceValue);
      } else {
        value = playlistOptions.at(0);
      }
    }

    setValue('contentSourceOption', value, { shouldValidate: true });
  }, [
    appOptions,
    bookmarkOptions,
    defaultContentSourceWatch,
    initialContentSourceOption,
    initialContentSourceValue,
    inputOptions,
    playlistOptions,
    setValue,
  ]);

  const subOptions = useMemo(() => {
    if (defaultContentSourceWatch?.value === ContentSourceOption.App) {
      return appOptions;
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Bookmark) {
      return bookmarkOptions;
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Input) {
      return inputOptions;
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Playlist) {
      return playlistOptions;
    }

    return [];
  }, [defaultContentSourceWatch, appOptions, bookmarkOptions, inputOptions, playlistOptions]);

  const subOptionsLabel = useMemo(() => {
    if (defaultContentSourceWatch?.value === ContentSourceOption.App) {
      return 'Default app';
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Bookmark) {
      return 'Default bookmark';
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Input) {
      return 'Default input';
    }

    if (defaultContentSourceWatch?.value === ContentSourceOption.Playlist) {
      return 'Default playlist';
    }

    return [];
  }, [defaultContentSourceWatch]);

  const analytics = useAnalyticsReporter();

  const toast = useToast();

  const { bulkUpdateDefaultContentSource } = useContentSource();

  const buildMutationContent = useCallback((value: FormValues) => {
    if (
      !isDefined(value.defaultContentSource) ||
      !isDefined(value.defaultContentSource.value) ||
      !isDefined(value.contentSourceOption) ||
      !isDefined(value.contentSourceOption.value)
    ) {
      throw new Error('Unsupported content source');
    }

    if (value.defaultContentSource.value === ContentSourceOption.App) {
      return {
        __typename: 'AppContentSource' as const,
        applicationId: value.contentSourceOption.value,
        label: value.contentSourceOption.label,
      };
    }

    if (value.defaultContentSource.value === ContentSourceOption.Bookmark) {
      return {
        __typename: 'BookmarkContentSource' as const,
        index: parseInt(value.contentSourceOption.value, 10),
      };
    }

    if (value.defaultContentSource.value === ContentSourceOption.Input) {
      return {
        __typename: 'InputContentSource' as const,
        source: value.contentSourceOption.value,
      };
    }

    if (value.defaultContentSource.value === ContentSourceOption.Playlist) {
      return {
        __typename: 'PlaylistContentSource' as const,
        playlistId: value.contentSourceOption.value,
      };
    }

    throw new Error('Unsupported content source');
  }, []);

  const performSubmit = async (values: FormValues) => {
    try {
      const content = buildMutationContent(values);
      await bulkUpdateDefaultContentSource([display], content);

      analytics.track('displaySettingUpdate', {
        group: 'playback',
        changeItem: 'defaultContentSource',
      });
      await onSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot change the input source',
        description: fromError(err, 'UpdateDefaultContentSource'),
      });
    }
  };

  const formatOptionLabel = useCallback(({ label }: { label: string }) => {
    return (
      <chakra.span title={label}>
        {label.length > 45 ? `${label.slice(0, 45)}...` : label}
      </chakra.span>
    );
  }, []);

  const { verifyUserPermissions } = useAuth();
  const isUpdateDisabled = !verifyUserPermissions([Permission.DisplayContentUpdate]);

  return (
    <>
      <ModalHeader>Change default input source</ModalHeader>
      <ModalCloseButton tabIndex={5} />
      <form onSubmit={handleSubmit(performSubmit)}>
        <ModalBody>
          <Stack direction="column" spacing="4">
            <FormControl isInvalid={Boolean(errors.defaultContentSource)}>
              <FormLabel>Default input source</FormLabel>
              <Controller
                name="defaultContentSource"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    options={contentSourceOptions}
                    components={components}
                    onChange={field.onChange}
                    placeholder={'Not Available'}
                    isLoading={isSubmitting}
                    isMulti={false}
                    escapeClearsValue={false}
                    isClearable={false}
                    isSearchable={true}
                    menuPlacement="auto"
                    isDisabled={isUpdateDisabled}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="defaultContentSource"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormControl>

            {isDefined(defaultContentSourceWatch) && (
              <FormControl isInvalid={Boolean(errors.contentSourceOption)}>
                <FormLabel>{subOptionsLabel}</FormLabel>
                <Controller
                  name="contentSourceOption"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={subOptions}
                      components={components}
                      onChange={field.onChange}
                      placeholder={'No option selected'}
                      isLoading={isSubmitting}
                      isMulti={false}
                      escapeClearsValue={false}
                      isClearable={false}
                      isSearchable={true}
                      menuPlacement="auto"
                      formatOptionLabel={formatOptionLabel}
                      isDisabled={isUpdateDisabled}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="contentSourceOption"
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FormControl>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onCancel}
            variant="ghost"
            colorScheme="blue"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            marginLeft="3"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Confirm
          </Button>
        </ModalFooter>
      </form>
    </>
  );
}

ChangeDefaultContentSourceModal.graphql = {
  fragments: {
    ChangeDefaultContentSourceModal_contentSourceSettings: gql`
      fragment ChangeDefaultContentSourceModal_contentSourceSettings on ContentSourceSettings {
        available {
          ...UseContentSource_contentSource
        }
        current {
          desired {
            ...UseContentSource_contentSource
          }
          reported {
            ...UseContentSource_contentSource
          }
        }
        default {
          desired {
            ...UseContentSource_contentSource
          }
          reported {
            ...UseContentSource_contentSource
          }
        }
      }
    `,
    ChangeDefaultContentSourceModal_display: gql`
      fragment ChangeDefaultContentSourceModal_display on Display {
        id
        ...UseContentSource_display
        bookmarks {
          all {
            reported
            desired
          }
        }
        contentSource {
          default {
            desired {
              ... on AppContentSource {
                label
                applicationId
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
            reported {
              ... on AppContentSource {
                label
                applicationId
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
          }
          available {
            ... on AppContentSource {
              label
              applicationId
            }
            ... on BookmarkContentSource {
              index
            }
            ... on InputContentSource {
              source
            }
            ... on PlaylistContentSource {
              playlistId
            }
          }
        }
        playlist {
          current {
            id
            title
          }
        }
      }
    `,
  },
};
