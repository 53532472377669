import { Circle, Tooltip } from '@chakra-ui/react';
import { ComponentType } from 'react';
import { IconProps } from '~components/ui/icons/types';

export interface Props {
  bg?: string;
  color?: string;
  icon: ComponentType<IconProps>;
  tooltip?: string;
  variant?: 'big' | 'small';
}

export function IconBadge({ bg, color, icon: Icon, tooltip, variant = 'big' }: Props) {
  const padding = variant === 'big' ? 3 : 2;
  const size = variant === 'big' ? 5 : 4;
  return (
    <Tooltip label={tooltip} hasArrow>
      <Circle padding={padding} bg={bg} color={color}>
        <Icon width={size} height={size} />
      </Circle>
    </Tooltip>
  );
}
