/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CustomerSwitcher_OrganizationFragmentDoc } from './CustomerSwitcher.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type NavbarQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NavbarQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, handle: string, name: string, avatarUrl?: string | null, customers: Array<{ __typename: 'Customer', id: string, name: string, handle: string, avatarUrl?: string | null }> } };


export const NavbarDocument = gql`
    query Navbar {
  organization {
    id
    ...CustomerSwitcher_organization
  }
}
    ${CustomerSwitcher_OrganizationFragmentDoc}`;

/**
 * __useNavbarQuery__
 *
 * To run a query within a React component, call `useNavbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavbarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavbarQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavbarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NavbarQuery, NavbarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NavbarQuery, NavbarQueryVariables>(NavbarDocument, options);
      }
export function useNavbarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavbarQuery, NavbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NavbarQuery, NavbarQueryVariables>(NavbarDocument, options);
        }
export type NavbarQueryHookResult = ReturnType<typeof useNavbarQuery>;
export type NavbarLazyQueryHookResult = ReturnType<typeof useNavbarLazyQuery>;
export type NavbarQueryResult = ApolloReactCommon.QueryResult<NavbarQuery, NavbarQueryVariables>;