import { Checkbox } from '@chakra-ui/react';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { CustomerUsersTableUserFragment } from './__generated__/CustomerUsersTable.graphql';
import { Columns, SelectableCustomerUsersTableUserFragment } from './types';

const TableCell = generateTableCellComponent<SelectableCustomerUsersTableUserFragment, ReactNode>();

const TableCellBold = generateTableCellComponent<
  SelectableCustomerUsersTableUserFragment,
  ReactNode
>({
  isBold: true,
});

export function useCustomerUsersTable(
  data: CustomerUsersTableUserFragment[],
  currentUserIds: string[],
  hasEssentialSubscription = false,
) {
  // The react-table docs specify that this *must* be memoized
  const columns = useMemo<Array<Column<SelectableCustomerUsersTableUserFragment>>>(
    () => [
      {
        id: Columns.Selection,
        Header: ({ getToggleAllPageRowsSelectedProps }) => {
          const { checked, indeterminate, onChange, role, title } =
            getToggleAllPageRowsSelectedProps();

          return (
            <Checkbox
              isChecked={hasEssentialSubscription ? checked : true}
              isIndeterminate={indeterminate}
              onChange={onChange}
              role={role}
              title={title}
              isDisabled={!hasEssentialSubscription}
            />
          );
        },
        Cell: ({ row }: { row: Row<SelectableCustomerUsersTableUserFragment> }) => {
          const { checked, indeterminate, onChange, role, title } = row.getToggleRowSelectedProps();

          return (
            <StopClickPropagation>
              <Checkbox
                isChecked={hasEssentialSubscription ? checked : true}
                isIndeterminate={indeterminate}
                onChange={onChange}
                role={role}
                title={title}
                isDisabled={!hasEssentialSubscription}
              />
            </StopClickPropagation>
          );
        },
        width: '48px',
        sortType: (rowA, rowB) => {
          return Number(rowA.original.hasCustomerAccess) - Number(rowB.original.hasCustomerAccess);
        },
      },
      {
        id: Columns.Name,
        Header: 'User',
        accessor: (user) => `${user.givenName} ${user.familyName}`,
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.givenName
            .toLowerCase()
            .localeCompare(rowB.original.givenName.toLowerCase()),
        width: 'auto',
      },
      {
        id: Columns.Role,
        Header: 'Role',
        accessor: (user) => [...user.roles].sort().join(', '),
        disableSortBy: true,
        Cell: TableCell,
        width: 'auto',
      },
      {
        id: Columns.Email,
        Header: 'Email',
        accessor: (user) => user.email,
        disableSortBy: true,
        Cell: TableCell,
        width: 'auto',
      },
    ],
    [hasEssentialSubscription],
  );

  // The react-table docs specify that this *must* be memoized
  // Because the `useSortBy` plugin for `react-table` is executed _before_ `useRowSelect`, we don't have access to `isSelected` when sorting is applied.
  // We do want to sort on selection state, however, so down below, where we memoize the data, we will be adding that property manually
  const dataMemoized = useMemo<SelectableCustomerUsersTableUserFragment[]>(
    () => data.map((user) => ({ ...user, hasCustomerAccess: currentUserIds.includes(user.id) })),
    [data, currentUserIds],
  );

  // The react-table docs specify that this *must* be memoized
  const getRowId = useCallback((row: CustomerUsersTableUserFragment) => row.id, []);

  const selectedRowIds = useMemo(
    () =>
      currentUserIds.reduce<Record<string, boolean>>((map, current) => {
        map[current] = true;
        return map;
      }, {}),
    [currentUserIds],
  );

  return useTable(
    {
      columns,
      data: dataMemoized,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Selection, desc: true }, { id: Columns.Name }],
        selectedRowIds,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
}
