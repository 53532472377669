/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type BookmarksCell_ValueFragment = { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } };

export type BookmarksCell_DisplayFragment = { __typename: 'Display', id: string, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } } };

export const BookmarksCell_ValueFragmentDoc = gql`
    fragment BookmarksCell_value on Bookmarks {
  all {
    reported
    desired
  }
}
    `;
export const BookmarksCell_DisplayFragmentDoc = gql`
    fragment BookmarksCell_display on Display {
  id
  bookmarks {
    ...BookmarksCell_value
  }
}
    ${BookmarksCell_ValueFragmentDoc}`;