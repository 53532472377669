import { gql } from '@apollo/client';
import { AlertDescription, AlertTitle, Box, Button, useDisclosure } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import UpgradeToWaveEssentialModal from '~components/organization/UpgradeToWaveEssentialModal/UpgradeToWaveEssentialModal';
import { CreatePlaylistModal } from '~components/playlists/CreatePlaylistModal';
import { EmptyPlaylists } from '~components/playlists/EmptyPlaylists';
import { PlaylistTable, usePlaylistTable } from '~components/playlists/PlaylistTable/PlaylistTable';
import { WarningAlert } from '~components/ui/Alert';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { HandleApiError } from '~graphql/HandleApiError';
import { useAnalyticsReporter } from '~utils/analytics';
import { FeatureFlag, useFeatureFlag } from '~utils/features';
import { isWaveSubscription } from '~utils/subscriptions';
import { ensure } from '~utils/types';
import { useLimits } from '~utils/useLimits';
import { useCustomerPlaylistsPageQuery } from './__generated__/index.graphql';

const POLL_INTERVAL = 5000;

export function CustomerPlaylistsPage() {
  const { customer } = useParams();
  const navigate = useNavigate();
  const { verifyUserPermissions } = useAuth();

  const customerHandle = ensure(customer);
  const { data, loading, error, refetch, startPolling, stopPolling } =
    useCustomerPlaylistsPageQuery({
      variables: {
        customerHandle,
      },
      pollInterval: POLL_INTERVAL,
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const analytics = useAnalyticsReporter();
  const createPlaylistModal = useDisclosure();

  const handleCreate = useCallback(() => {
    analytics.track('playlistCreateStart');
    createPlaylistModal.onOpen();
  }, [analytics, createPlaylistModal]);

  const handleCreateSuccess = useCallback(() => {
    analytics.track('playlistCreateComplete');
    refetch({ customerHandle });
    createPlaylistModal.onClose();
  }, [analytics, createPlaylistModal, customerHandle, refetch]);

  const handlePlaylistClicked = useCallback(
    (id: string) => {
      navigate(id);
    },
    [navigate],
  );

  const table = usePlaylistTable(data?.customer?.playlists ?? []);

  const {
    playlists: {
      playlistsNumberPerCustomerLimit: playlistsLimit,
      isPlaylistsLimitPerCustomerReached,
    },
  } = useLimits();

  const hasEssentialSubscription = isWaveSubscription(data?.customer?.waveSubscription);

  const isPlaylistsLimitReached = isPlaylistsLimitPerCustomerReached(
    data?.customer?.playlists,
    hasEssentialSubscription,
  );

  const hasCreatePlaylistPermission = verifyUserPermissions([Permission.PlaylistCreate]);

  const canCreatePlaylist = hasCreatePlaylistPermission && !isPlaylistsLimitReached;

  const shouldRenderUpgradeToWaveEssentialModal =
    !hasEssentialSubscription && isPlaylistsLimitReached;

  const shouldRenderPlaylistsTable = data?.customer?.playlists.length ?? 0 > 0;

  const shouldRenderLimitReachedAlert = isPlaylistsLimitReached;

  const shouldRenderEmptyPlaylists = !shouldRenderPlaylistsTable;

  return (
    <Page title="Playlists" pageName="playlists_overview">
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          data &&
          data.customer && (
            <>
              <PageHeading
                actions={
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={handleCreate}
                    // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
                    isDisabled={!canCreatePlaylist}
                  >
                    Create playlist
                  </Button>
                }
                marginBottom={isPlaylistsLimitReached ? '8' : undefined}
              >
                Playlists
              </PageHeading>
              {shouldRenderLimitReachedAlert ? (
                <WarningAlert marginBottom="6">
                  <AlertTitle>Playlists limit reached!</AlertTitle>
                  <AlertDescription>
                    {isSubscriptionsEnabled && !hasEssentialSubscription
                      ? `You can only add 1 playlist per lite customer. Upgrade to Wave Essential to be able to create up to 25 playlists.`
                      : `You can only add ${playlistsLimit} playlist per customer. Remove an old playlist to create a new one.`}
                  </AlertDescription>
                </WarningAlert>
              ) : undefined}
              <Box
                boxShadow="elevated"
                border="1px solid"
                borderRadius="md"
                borderColor="gray.100"
                overflowX="auto"
                background="white"
              >
                {shouldRenderEmptyPlaylists ? (
                  <EmptyPlaylists onCreate={handleCreate} />
                ) : (
                  <PlaylistTable table={table} onGoToDetail={handlePlaylistClicked} />
                )}
              </Box>
              {
                // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
              }
              {shouldRenderUpgradeToWaveEssentialModal && (
                <FeatureFlag feature="subscriptions">
                  <Box
                    m="auto"
                    marginY="16"
                    maxW="50VW"
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="gray.100"
                    overflow="hidden"
                    bg="white"
                    boxShadow="elevated"
                  >
                    <UpgradeToWaveEssentialModal.EmptyView />
                  </Box>
                </FeatureFlag>
              )}

              <CreatePlaylistModal
                customerId={data.customer.id}
                isOpen={createPlaylistModal.isOpen}
                onCancel={createPlaylistModal.onClose}
                onSuccess={handleCreateSuccess}
              />
            </>
          )
        )}
      </PageContent>
    </Page>
  );
}

CustomerPlaylistsPage.graphql = {
  queries: {
    CustomerPlaylistsPage: gql`
      query CustomerPlaylistsPage($customerHandle: String!) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          playlists {
            ...PlaylistTable_playlist
          }
          waveSubscription {
            usage {
              current
              max
            }
          }
        }
      }
    `,
  },
};
