import {
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  useNumberInput,
  UseNumberInputProps,
} from '@chakra-ui/react';

type Props = { isDisabled?: boolean } & Required<Pick<UseNumberInputProps, 'value' | 'onChange'>> &
  Pick<UseNumberInputProps, 'max' | 'onBlur' | 'min' | 'id'> &
  Pick<InputProps, 'onKeyUp'>;

export function PercentageInput({
  isDisabled = false,
  max = 100,
  min,
  onKeyUp,
  value,
  onChange,
  ...rest
}: Props) {
  const { getInputProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    precision: 0,
    min,
    max,
    value,
    onChange,
    ...rest,
  });

  return (
    <InputGroup>
      <Input {...getInputProps({ onKeyUp })} {...rest} isDisabled={isDisabled} />
      <InputRightAddon>%</InputRightAddon>
    </InputGroup>
  );
}
