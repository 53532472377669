import { Box, Link, PopoverTrigger, Tooltip, VisuallyHidden } from '@chakra-ui/react';
import { BellIcon } from '~components/ui/icons';
import { useTheme } from '~components/ui/styles/hooks';
import { useAnalyticsReporter } from '~utils/analytics';

type Props = {
  alertCount: number;
};

export function AlertsMenuTrigger({ alertCount }: Props) {
  const hasAlerts = alertCount > 0;
  const theme = useTheme();
  const analytics = useAnalyticsReporter();

  return (
    <PopoverTrigger>
      <Link
        as="button"
        color="gray.300"
        onClick={() => {
          analytics.track('notificationOpen', { count: alertCount });
        }}
      >
        <Tooltip label="Alerts" fontWeight="semibold">
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            paddingX={{
              md: '4',
              sm: '2',
            }}
            color={'gray.300'}
            _hover={{
              color: 'gray.600',
              cursor: 'pointer',
            }}
          >
            <Box position="relative">
              <VisuallyHidden>Alerts</VisuallyHidden>
              <BellIcon height="6" width="6" />
              {hasAlerts && (
                <Box
                  position="absolute"
                  width="14px"
                  height="14px"
                  top="0"
                  right="0"
                  borderRadius="full"
                  background={`linear-gradient(${theme.colors.orange[300]}, ${theme.colors.orange[400]})`}
                  border="2px solid white"
                />
              )}
            </Box>
          </Box>
        </Tooltip>
      </Link>
    </PopoverTrigger>
  );
}
