import { Box, Tooltip } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { CustomerSubscriptionsTableSubscriptionFragment } from './__generated__/CustomerSubscriptionsTable.graphql';
import { Columns } from './types';

const TableCell = generateTableCellComponent<
  CustomerSubscriptionsTableSubscriptionFragment,
  ReactNode
>();

const TableCellBold = generateTableCellComponent<
  CustomerSubscriptionsTableSubscriptionFragment,
  ReactNode
>({
  isBold: true,
});

export function useCustomerSubscriptionsTable({
  data,
  displayCount,
  onSelect,
  customerSubscriptionId,
  currentSubscriptionId,
}: {
  data: CustomerSubscriptionsTableSubscriptionFragment[];
  displayCount: number;
  customerSubscriptionId?: string;
  currentSubscriptionId?: string;
  onSelect: (subscriptionId: string | undefined) => void;
}) {
  // The react-table docs specify that this *must* be memoized
  const columns = useMemo<Array<Column<CustomerSubscriptionsTableSubscriptionFragment>>>(
    () => [
      {
        id: Columns.Subscription,
        Header: 'Subscription',
        accessor: 'name',
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.name.toLowerCase().localeCompare(rowB.original.name.toLowerCase()),
        width: 'auto',
      },
      {
        id: Columns.Usage,
        Header: 'Usage',
        accessor: (subscription) =>
          `${subscription.usage.current}/${subscription.usage.max ?? '∞'}`,
        disableSortBy: true,
        Cell: TableCell,
        width: '150px',
      },
      {
        id: Columns.ValidUntil,
        Header: 'Valid until',
        accessor: (subscription) =>
          DateTime.fromISO(subscription.validUntil).toFormat('dd/MM/yyyy'),
        Cell: TableCell,
        sortType: (rowA, rowB) =>
          DateTime.fromISO(rowA.original.validUntil).toMillis() -
          DateTime.fromISO(rowB.original.validUntil).toMillis(),
        width: '140px',
      },
      {
        id: Columns.Customers,
        Header: 'Customers',
        accessor: (subscription) =>
          subscription.customers.length === 0 ? '/' : subscription.customers.length,
        Cell: TableCell,
        disableSortBy: true,
        width: '85px',
      },
      {
        id: Columns.Actions,
        disableSortBy: true,
        Cell: ({
          row: { original: subscription },
        }: {
          row: Row<CustomerSubscriptionsTableSubscriptionFragment>;
        }) => {
          const isCurrentSubscription = subscription.id === currentSubscriptionId;
          const isCustomerSubscription = subscription.id === customerSubscriptionId;

          const availableSeats = isNil(subscription.usage.max)
            ? null
            : subscription.usage.max - subscription.usage.current;

          const hasEnoughSeats = availableSeats === null || availableSeats >= displayCount;
          const canSelect = isCurrentSubscription || isCustomerSubscription || hasEnoughSeats;

          return (
            <Tooltip label="Not enough available seats" isDisabled={canSelect}>
              <Box
                as="button"
                color={canSelect ? 'blue.500' : 'gray.200'}
                cursor={canSelect ? 'pointer' : 'not-allowed'}
                textDecoration="underline"
                fontWeight="normal"
                fontSize="inherit"
                disabled={!canSelect}
                onClick={() => onSelect(isCurrentSubscription ? undefined : subscription.id)}
              >
                {isCurrentSubscription ? 'Deselect' : 'Select'}
              </Box>
            </Tooltip>
          );
        },
        width: '100px',
      },
    ],
    [currentSubscriptionId, displayCount, onSelect, customerSubscriptionId],
  );

  // The react-table docs specify that this *must* be memoized
  // Because the `useSortBy` plugin for `react-table` is executed _before_ `useRowSelect`, we don't have access to `isSelected` when sorting is applied.
  // We do want to sort on selection state, however, so down below, where we memoize the data, we will be adding that property manually
  const dataMemoized = useMemo<CustomerSubscriptionsTableSubscriptionFragment[]>(
    () =>
      data.map((subscription) => ({
        ...subscription,
        isSelected: currentSubscriptionId === subscription.id,
      })),
    [data, currentSubscriptionId],
  );

  // The react-table docs specify that this *must* be memoized
  const getRowId = useCallback((row: CustomerSubscriptionsTableSubscriptionFragment) => row.id, []);

  return useTable(
    {
      columns,
      data: dataMemoized,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Subscription, desc: true }],
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
}
