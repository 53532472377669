/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateBookmarksAllMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateBookmarksAllInput;
}>;


export type BulkUpdateBookmarksAllMutation = { __typename: 'Mutation', displayBulkUpdateBookmarksAll: { __typename: 'DisplayBulkUpdateBookmarksAllPayload', displays: Array<{ __typename: 'Display', id: string, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } } }> } };


export const BulkUpdateBookmarksAllDocument = gql`
    mutation BulkUpdateBookmarksAll($input: DisplayBulkUpdateBookmarksAllInput!) {
  displayBulkUpdateBookmarksAll(input: $input) {
    displays {
      id
      bookmarks {
        all {
          reported
          desired
        }
      }
    }
  }
}
    `;
export type BulkUpdateBookmarksAllMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateBookmarksAllMutation, BulkUpdateBookmarksAllMutationVariables>;

/**
 * __useBulkUpdateBookmarksAllMutation__
 *
 * To run a mutation, you first call `useBulkUpdateBookmarksAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateBookmarksAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateBookmarksAllMutation, { data, loading, error }] = useBulkUpdateBookmarksAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateBookmarksAllMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateBookmarksAllMutation, BulkUpdateBookmarksAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdateBookmarksAllMutation, BulkUpdateBookmarksAllMutationVariables>(BulkUpdateBookmarksAllDocument, options);
      }
export type BulkUpdateBookmarksAllMutationHookResult = ReturnType<typeof useBulkUpdateBookmarksAllMutation>;
export type BulkUpdateBookmarksAllMutationResult = ApolloReactCommon.MutationResult<BulkUpdateBookmarksAllMutation>;
export type BulkUpdateBookmarksAllMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateBookmarksAllMutation, BulkUpdateBookmarksAllMutationVariables>;