import gql from 'graphql-tag';
import { PowerBadge_DisplayFragment } from '~components/displays/__generated__/PowerBadge.graphql';
import { OnIcon, StandbyIcon } from '~components/ui/icons';
import { IconBadge, Props as IconBadgeProps } from '~components/ui/icons/IconBadge';
import { Power } from '~graphql/__generated__/types';

export default function PowerBadge({
  display,
  noTooltip,
  variant,
}: {
  display: PowerBadge_DisplayFragment;
  noTooltip?: boolean;
} & Pick<IconBadgeProps, 'variant'>) {
  const currentPower = display.power?.desired ?? display.power?.reported;
  const isOn = currentPower === Power.On;

  return isOn ? (
    <IconBadge
      bg="blue.50"
      icon={OnIcon}
      tooltip={noTooltip ? undefined : 'On'}
      variant={variant}
    />
  ) : (
    <IconBadge
      bg="gray.50"
      color="gray.500"
      icon={StandbyIcon}
      tooltip={noTooltip ? undefined : 'Standby'}
      variant={variant}
    />
  );
}

PowerBadge.graphql = {
  fragments: {
    PowerBadge_display: gql`
      fragment PowerBadge_display on Display {
        id
        power {
          desired
          reported
        }
      }
    `,
  },
};
