/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PowerScheduleCell_ValueFragment = { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', title: string } | null };

export type PowerScheduleCell_DisplayFragment = { __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', title: string } | null } | null };

export const PowerScheduleCell_ValueFragmentDoc = gql`
    fragment PowerScheduleCell_value on DisplayPowerSchedule {
  schedule {
    title
  }
}
    `;
export const PowerScheduleCell_DisplayFragmentDoc = gql`
    fragment PowerScheduleCell_display on Display {
  id
  powerSchedule {
    ...PowerScheduleCell_value
  }
}
    ${PowerScheduleCell_ValueFragmentDoc}`;