import { ButtonProps } from '@chakra-ui/button';
import { Button } from '@chakra-ui/react';
import { ArrowLeftIcon } from '~components/ui/icons';

export default function BackToAllActionsButton({
  onBack,
  ...rest
}: ButtonProps & { onBack: (() => void | Promise<void>) | undefined }) {
  return (
    <Button
      size="sm"
      variant="link"
      colorScheme="blue"
      color="blue.800"
      leftIcon={<ArrowLeftIcon />}
      onClick={onBack}
      {...rest}
    >
      Back to all actions
    </Button>
  );
}
