import 'react-datepicker/dist/react-datepicker.css';
import '~components/ui/styles/react-datepicker.css';
import '~components/ui/styles/google-maps.css';
import * as Sentry from '@sentry/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { isDefined } from '~utils/types';
import App from './App';
import AppProvider from './AppProvider';

if (Number(process.env.REACT_APP_ENABLE_MOCKING) !== 0) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
  const { worker } = require('./mocks/browser');
  worker.start();
}

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENVIRONMENT;

if (isDefined(SENTRY_DSN)) {
  Sentry.init({
    environment: SENTRY_ENVIRONMENT,
    dsn: SENTRY_DSN,
    allowUrls: [/wave.ppds.com/, /wave-dev.ppds.com/],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: false,
      }),
    ],
    replaysSessionSampleRate: 0, // disable session replay
    replaysOnErrorSampleRate: 1.0, // enable error replay (100%)
    tracesSampleRate: 0.1, // sample 10% of transactions
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Router>
      <AppProvider>
        <App />
      </AppProvider>
    </Router>
  </StrictMode>,
);
