import { createIcon } from '@chakra-ui/react';

export const BuildingIcon = createIcon({
  displayName: 'BuildingIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '24',
    width: '24',
  },
  path: (
    <>
      <path
        d="M14 22V9M14 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9H14M14 22H10M14 9V4C14 2.89543 13.1046 2 12 2H4C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H6M6 22V19C6 17.8954 6.89543 17 8 17V17C9.10457 17 10 17.8954 10 19V22M6 22H10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6Z"
        fill="currentColor"
      />
      <path
        d="M7 10C7 10.5523 6.55228 11 6 11C5.44772 11 5 10.5523 5 10C5 9.44772 5.44772 9 6 9C6.55228 9 7 9.44772 7 10Z"
        fill="currentColor"
      />
      <path
        d="M7 14C7 14.5523 6.55228 15 6 15C5.44772 15 5 14.5523 5 14C5 13.4477 5.44772 13 6 13C6.55228 13 7 13.4477 7 14Z"
        fill="currentColor"
      />
      <path
        d="M11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z"
        fill="currentColor"
      />
      <path
        d="M11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10Z"
        fill="currentColor"
      />
      <path
        d="M11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14Z"
        fill="currentColor"
      />
    </>
  ),
});
