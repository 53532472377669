import { gql } from '@apollo/client';
import { chakra, useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import Select, { ActionMeta } from 'react-select';
import { components } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplaySiteSelect_CustomerFragment,
  DisplaySiteSelect_DisplayFragment,
  useUpdateDisplaySiteMutation,
} from './__generated__/DisplaySiteSelect.graphql';

interface Props {
  customer: DisplaySiteSelect_CustomerFragment;
  display: DisplaySiteSelect_DisplayFragment;
  isDisabled?: boolean;
}

interface SiteSelectOption {
  label: string;
  address?: string | null;
  value: string;
}

export function DisplaySiteSelect({ customer, display, isDisabled = false }: Props) {
  const analytics = useAnalyticsReporter();
  const siteOptions = useMemo(
    () =>
      customer.sites.map<SiteSelectOption>((site) => ({
        label: site.name,
        address: site.address,
        value: site.id,
      })),
    [customer],
  );
  const currentSite = useMemo(() => {
    const site = display.site ?? null;
    return siteOptions.find((option) => option.value === site?.id);
  }, [siteOptions, display]);

  const formatOptionLabel = useCallback((data: SiteSelectOption) => {
    const label = data.label;
    const address = data.address;

    return (
      <chakra.span>
        {label}
        {address && <chakra.span color="gray.500"> - {address}</chakra.span>}
      </chakra.span>
    );
  }, []);

  const toast = useToast();
  const [updateSite, { loading }] = useUpdateDisplaySiteMutation();
  const handleChange = async (
    option: SiteSelectOption | null,
    meta: ActionMeta<SiteSelectOption>,
  ) => {
    try {
      await updateSite({
        variables: {
          input: {
            displayId: display.id,
            siteId: meta.action === 'clear' ? null : option?.value,
          },
        },
      });
      analytics.track('displaySettingUpdate', { group: 'management', changeItem: 'sites' });
    } catch (err) {
      toast({
        status: 'error',
        title: "Cannot update display's site",
        description: fromError(err, 'UpdateDisplaySite'),
      });
    }
  };

  return (
    <Select
      value={currentSite}
      options={siteOptions}
      components={{
        ...components,
      }}
      formatOptionLabel={formatOptionLabel}
      onChange={handleChange}
      placeholder={'Assign a site'}
      isLoading={loading}
      isDisabled={isDisabled || loading}
      isMulti={false}
      escapeClearsValue={true}
      isClearable={true}
      isSearchable={true}
      menuPlacement="auto"
    />
  );
}

DisplaySiteSelect.graphql = {
  fragments: {
    DisplaySiteSelect_display: gql`
      fragment DisplaySiteSelect_display on Display {
        id
        site {
          id
          name
          address
        }
      }
    `,
    DisplaySiteSelect_customer: gql`
      fragment DisplaySiteSelect_customer on Customer {
        id
        sites {
          id
          name
          address
        }
      }
    `,
  },
  mutations: {
    UpdateDisplaySite: gql`
      mutation UpdateDisplaySite($input: DisplayUpdateSiteInput!) {
        displayUpdateSite(input: $input) {
          id
          site {
            id
            name
            address
          }
        }
      }
    `,
  },
};
