/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type EditPlaylistModal_PlaylistFragment = { __typename: 'Playlist', id: string, title: string, description?: string | null };

export type PlaylistEditMutationVariables = Types.Exact<{
  input: Types.PlaylistUpdateInput;
}>;


export type PlaylistEditMutation = { __typename: 'Mutation', playlistUpdate: { __typename: 'PlaylistUpdatePayload', playlist: { __typename: 'Playlist', id: string, title: string, description?: string | null } } };

export const EditPlaylistModal_PlaylistFragmentDoc = gql`
    fragment EditPlaylistModal_playlist on Playlist {
  id
  title
  description
}
    `;
export const PlaylistEditDocument = gql`
    mutation PlaylistEdit($input: PlaylistUpdateInput!) {
  playlistUpdate(input: $input) {
    playlist {
      id
      ...EditPlaylistModal_playlist
    }
  }
}
    ${EditPlaylistModal_PlaylistFragmentDoc}`;
export type PlaylistEditMutationFn = ApolloReactCommon.MutationFunction<PlaylistEditMutation, PlaylistEditMutationVariables>;

/**
 * __usePlaylistEditMutation__
 *
 * To run a mutation, you first call `usePlaylistEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaylistEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playlistEditMutation, { data, loading, error }] = usePlaylistEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaylistEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlaylistEditMutation, PlaylistEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PlaylistEditMutation, PlaylistEditMutationVariables>(PlaylistEditDocument, options);
      }
export type PlaylistEditMutationHookResult = ReturnType<typeof usePlaylistEditMutation>;
export type PlaylistEditMutationResult = ApolloReactCommon.MutationResult<PlaylistEditMutation>;
export type PlaylistEditMutationOptions = ApolloReactCommon.BaseMutationOptions<PlaylistEditMutation, PlaylistEditMutationVariables>;