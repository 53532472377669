import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useOnWindowScroll } from 'rooks';
import { useRecentlyUsedCustomers } from '~components/customers/CustomerSwitcher';
import { Navbar } from '~components/customers/Navbar';
import { Footer } from '~components/ui/Footer';
import { Header } from '~components/ui/Header';

export function NavbarLayout({ hideNavigationLinks = false }) {
  const { customer } = useParams();
  const { addToRecentlyUsed } = useRecentlyUsedCustomers();
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();

  const useGrayHeader = location.pathname.endsWith('/displays');
  const userHasScrolled = scrollPosition > 0;

  useEffect(() => {
    if (customer) {
      addToRecentlyUsed(customer);
    }
  }, [customer, addToRecentlyUsed]);

  useOnWindowScroll(() => {
    setScrollPosition(window.pageYOffset);
  });

  return (
    <Header.Provider>
      <Box display="flex" flexDir="column" height="100%">
        <Header
          background={userHasScrolled || !useGrayHeader ? 'white' : 'gray.50'}
          color="gray.900"
          display="flex"
          justifyContent="center"
          transition="background 0.3s ease, boxShadow 0.3s ease"
          boxShadow={userHasScrolled ? '0px 2px 2px rgba(0, 0, 0, 0.05)' : 'none'}
        >
          <Navbar hideNavigationLinks={hideNavigationLinks} />
        </Header>

        <Box flex="1 0 auto">
          <Header.Spacer />
          <Outlet />
        </Box>

        <Footer />
      </Box>
    </Header.Provider>
  );
}
