/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayScreenshot_DisplayFragment = { __typename: 'Display', id: string, screenshot?: { __typename: 'ScreenshotEmpty', url?: string | null, createdAt?: any | null } | { __typename: 'ScreenshotPending', url?: string | null, createdAt?: any | null } | { __typename: 'ScreenshotRejected', rejectedAt: any, errorCode: string, url?: string | null, createdAt?: any | null } | { __typename: 'ScreenshotResolved', url?: string | null, createdAt?: any | null } | null, orientation?: { __typename: 'OrientationState', desired?: Types.Orientation | null, reported: Types.Orientation } | null };

export type CaptureScreenshotMutationVariables = Types.Exact<{
  input: Types.DisplayCaptureScreenshotInput;
}>;


export type CaptureScreenshotMutation = { __typename: 'Mutation', displayCaptureScreenshot: { __typename: 'DisplayCapturescreenshotPayload', display: { __typename: 'Display', id: string, screenshot?: { __typename: 'ScreenshotEmpty', url?: string | null } | { __typename: 'ScreenshotPending', url?: string | null } | { __typename: 'ScreenshotRejected', url?: string | null } | { __typename: 'ScreenshotResolved', url?: string | null } | null } } };

export const DisplayScreenshot_DisplayFragmentDoc = gql`
    fragment DisplayScreenshot_display on Display {
  id
  screenshot {
    __typename
    url
    createdAt
    ... on ScreenshotRejected {
      rejectedAt
      errorCode
    }
  }
  orientation {
    desired
    reported
  }
}
    `;
export const CaptureScreenshotDocument = gql`
    mutation CaptureScreenshot($input: DisplayCaptureScreenshotInput!) {
  displayCaptureScreenshot(input: $input) {
    display {
      id
      screenshot {
        __typename
        url
      }
    }
  }
}
    `;
export type CaptureScreenshotMutationFn = ApolloReactCommon.MutationFunction<CaptureScreenshotMutation, CaptureScreenshotMutationVariables>;

/**
 * __useCaptureScreenshotMutation__
 *
 * To run a mutation, you first call `useCaptureScreenshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureScreenshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureScreenshotMutation, { data, loading, error }] = useCaptureScreenshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaptureScreenshotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CaptureScreenshotMutation, CaptureScreenshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CaptureScreenshotMutation, CaptureScreenshotMutationVariables>(CaptureScreenshotDocument, options);
      }
export type CaptureScreenshotMutationHookResult = ReturnType<typeof useCaptureScreenshotMutation>;
export type CaptureScreenshotMutationResult = ApolloReactCommon.MutationResult<CaptureScreenshotMutation>;
export type CaptureScreenshotMutationOptions = ApolloReactCommon.BaseMutationOptions<CaptureScreenshotMutation, CaptureScreenshotMutationVariables>;