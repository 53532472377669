import { createIcon } from '@chakra-ui/react';

export const ImageIllustration = createIcon({
  displayName: 'ImageIllustration',
  viewBox: '0 0 21 18',
  defaultProps: {
    fill: 'none',
    height: '12',
    width: '12',
  },
  path: (
    <path
      d="M19.5 0H4.5C2.8439 0.00180847 1.50181 1.3439 1.5 3V15C1.50181 16.6561 2.8439 17.9982 4.5 18H19.5C21.1561 17.9982 22.4982 16.6561 22.5 15V3V3C22.4982 1.3439 21.1561 0.00180938 19.5 0ZM15.75 3C16.9926 3 18 4.00736 18 5.25C18 6.49264 16.9926 7.5 15.75 7.5C14.5074 7.5 13.5 6.49264 13.5 5.25V5.25001C13.5013 4.00791 14.5079 3.0013 15.75 3H15.75ZM4.5 16.5C3.67157 16.5 3 15.8284 3 15V11.8298L7.44562 7.87828V7.87828C8.33591 7.0887 9.68676 7.1277 10.53 7.96734L13.5745 11.0053L8.07984 16.5H4.5ZM21 15C21 15.8284 20.3284 16.5 19.5 16.5H10.2014L15.893 10.8084C16.7251 10.1008 17.9466 10.0976 18.7823 10.8009L21 12.6488V15Z"
      fill="currentColor"
    />
  ),
});
