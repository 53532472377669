import { gql } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { RelativeTime } from '~components/ui/RelativeTime';
import { PowerScheduleTable_PowerScheduleFragment } from '../__generated__/PowerScheduleTable.graphql';

export function CreatedAtCell({
  value,
}: PropsWithChildren<CellProps<PowerScheduleTable_PowerScheduleFragment, string>>) {
  return (
    <Box>
      <RelativeTime ts={value} render={(t) => <Text fontSize="sm">{t}</Text>} />
    </Box>
  );
}

CreatedAtCell.graphql = {
  fragments: {
    CreatedAtCell_powerSchedule: gql`
      fragment CreatedAtCell_powerSchedule on PowerSchedule {
        id
        createdAt
      }
    `,
  },
};
