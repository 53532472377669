import { Td, Tr } from '@chakra-ui/react';
import { ReactNode, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Row } from 'react-table';

const DND_ITEM_TYPE = 'draggable_row';

// See https://github.com/tannerlinsley/react-table/blob/master/examples/row-dnd/src/App.js
// for reference.
/* eslint-disable react/jsx-key */
export function DraggableRow<T extends Record<string, unknown>>({
  row,
  onMove,
}: {
  row: Row<T>;
  onMove: (dragIndex: number, hoverIndex: number) => void;
}) {
  const ref = useRef<HTMLTableRowElement | null>(null);

  const [, drop] = useDrop<{ index: number }, unknown, unknown>({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = row.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index: row.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(preview(drop(ref)));

  return (
    <Tr ref={ref} opacity={isDragging ? 0 : 1} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <Td
            minWidth={`${cell.column.minWidth}px`}
            maxWidth={`${cell.column.maxWidth}px`}
            width={cell.column.width}
            cursor="grab"
            onClick={
              cell.column.id === 'selection' ? () => cell.row.toggleRowSelected() : undefined
            }
            {...cell.getCellProps()}
          >
            {cell.render('Cell') as ReactNode}
          </Td>
        );
      })}
    </Tr>
  );
}
/* eslint-enable react/jsx-key */
