import { Button, chakra, Flex, HStack, Link, ThemeTypings } from '@chakra-ui/react';
import { createContext, ReactNode, useContext } from 'react';
import { ExternalLinkIcon } from '~components/ui/icons';
import { Link as InternalLink } from '~components/ui/Link';

const EmptyStateTheme = createContext<ThemeTypings['colorSchemes']>('blue');

export function EmptyView({
  description,
  icon,
  padding,
  title,
  children,
  theme = 'blue',
}: {
  description?: string | ReactNode;
  icon: ReactNode;
  padding?: string;
  title: string;
  children?: ReactNode;
  theme?: ThemeTypings['colorSchemes'];
}) {
  return (
    <EmptyStateTheme.Provider value={theme}>
      <Flex
        alignItems="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        {...(padding
          ? { padding }
          : {
              paddingBottom: '8',
              paddingTop: '8',
            })}
      >
        {icon}
        <chakra.h2
          fontFamily="heading"
          fontSize="3xl"
          fontWeight="semibold"
          marginTop={6}
          marginBottom={0}
          color={`${theme}.700`}
          textAlign="center"
        >
          {title}
        </chakra.h2>
        {description && (
          <chakra.p
            color="gray.700"
            opacity="0.75"
            marginTop={4}
            marginBottom={0}
            maxWidth={434}
            textAlign="center"
          >
            {description}
          </chakra.p>
        )}
        {children}
      </Flex>
    </EmptyStateTheme.Provider>
  );
}

export function EmptyViewExternalLink({ label, href }: { label: string; href?: string }) {
  const theme = useContext(EmptyStateTheme);

  if (href === undefined) {
    return null;
  }

  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
      }}
    >
      <Button variant="solid" colorScheme={theme} marginTop={6}>
        <HStack marginX="2" marginY="3">
          <chakra.span textDecoration="none">{label}</chakra.span>
          <ExternalLinkIcon />
        </HStack>
      </Button>
    </Link>
  );
}

export function EmptyViewInternalLink({ label, to }: { label: string; to: string }) {
  const theme = useContext(EmptyStateTheme);

  if (to === undefined) {
    return null;
  }

  return (
    <InternalLink
      to={to}
      style={{
        textDecoration: 'none',
      }}
    >
      <Button variant="solid" colorScheme={theme} marginTop={6}>
        <HStack marginX="2" marginY="3">
          <chakra.span textDecoration="none">{label}</chakra.span>
        </HStack>
      </Button>
    </InternalLink>
  );
}

export function EmptyViewButton({
  label,
  onClick,
  isDisabled = false,
}: {
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
}) {
  const theme = useContext(EmptyStateTheme);

  return (
    <Button
      variant="solid"
      colorScheme={theme}
      onClick={onClick}
      marginTop={6}
      isDisabled={isDisabled}
    >
      {label}
    </Button>
  );
}
