import { Box, Tooltip } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { Fragment, PropsWithChildren, ReactNode } from 'react';
import { CellProps } from 'react-table';

type GenerateTableCellOptions = {
  isBold: boolean;
  hasTooltip: boolean;
};

export function generateTableCellComponent<TData extends object, TValue extends ReactNode>({
  isBold = false,
  hasTooltip = false,
}: Partial<GenerateTableCellOptions> = {}) {
  return ({ value }: PropsWithChildren<CellProps<TData, TValue>>) => {
    if (isNil(value) || value === '') {
      return <Box />;
    }

    const Wrapper = hasTooltip
      ? ({ children }: PropsWithChildren) => <Tooltip label={value}>{children}</Tooltip>
      : Fragment;

    return (
      <Wrapper>
        <Box
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          {...(isBold ? { fontWeight: 'semibold' } : {})}
        >
          {value}
        </Box>
      </Wrapper>
    );
  };
}
