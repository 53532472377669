import { gql } from '@apollo/client';
import { Box, chakra, HStack } from '@chakra-ui/react';
import { PropsWithChildren, useMemo } from 'react';
import { CellProps } from 'react-table';
import { useStatus } from '~components/displays/useStatus';
import { ErrorIcon, WarningIcon } from '~components/ui/icons';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';

export function WarningsCell({
  value,
}: PropsWithChildren<CellProps<DisplayTable_DisplayFragment, DisplayTable_DisplayFragment>>) {
  const { getStatus } = useStatus();
  const status = useMemo(() => getStatus(value), [value, getStatus]);

  return (
    <Box>
      {status.kind === 'warnings' && (
        <HStack spacing="2" alignItems="center">
          <WarningIcon color="orange.400" height="6" width="6" />
          <Box>
            {status.warnings.length > 0 && (
              <>
                <chakra.span fontSize="sm">{status.warnings[0].message}</chakra.span>
                {status.warnings.length > 1 && <chakra.span fontSize="sm">+1</chakra.span>}
              </>
            )}
          </Box>
        </HStack>
      )}
      {status.kind === 'error' && (
        <HStack spacing="2" alignItems="center">
          <ErrorIcon color="red.400" height="6" width="6" />
          <Box>
            <chakra.span fontSize="sm">{status.errorMessage}</chakra.span>
          </Box>
        </HStack>
      )}
    </Box>
  );
}

WarningsCell.graphql = {
  fragments: {
    WarningsCell_display: gql`
      fragment WarningsCell_display on Display {
        id
        ...UseStatus_display
      }
    `,
  },
};
