import { addMocksToSchema, createMockStore, Ref } from '@graphql-tools/mock';
import faker from 'faker';
import { buildClientSchema, graphql, IntrospectionQuery } from 'graphql';
import { isNil, random } from 'lodash';
import { rest } from 'msw';
import introspectionQuery from '~graphql/__generated__/schema.json';
import {
  AndroidUpdateRejected,
  Customer,
  Display,
  DisplayAlert,
  Organization,
} from '~graphql/__generated__/types';
import { DeepPartial } from '~utils/types';

const nonExecutableSchema = buildClientSchema(introspectionQuery as unknown as IntrospectionQuery);

const MOCK_S3_URL = 'https://not-a-real-s3.com/upload';
const S3_UPLOAD_DELAY = 2000;

const knownCustomers: Array<DeepPartial<Customer>> = [
  {
    name: 'Mocked Customer 1',
    id: '278790d4-fcf9-436c-91d8-5d769e734d5a',
    handle: 'mocked-customer-1',
  },
  {
    name: 'Mocked Customer 2',
    id: '5bbc6d13-9266-4545-acc5-22287572df5a',
    handle: 'mocked-customer-2',
  },
];

const knownAlerts = [
  {
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().toISOString(),
    message: faker.random.words(3),
    display: {
      id: faker.datatype.uuid(),
    } as unknown as Display,
  },
];

const knownOrganization: DeepPartial<Organization> = {
  name: 'Mocked Organization',
  handle: 'mocked-organization',
  customers: knownCustomers,
  displayAlerts: knownAlerts,
};

const randomFirmwareVersion = ['FB01.05', 'FB01.03', 'FB01.02'];
const randomGroups = [
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
];
const randomSites = [
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
  { id: faker.datatype.uuid(), name: faker.random.word() },
];

// These are general type mocks
const typeMocks = {
  FirmwareVersion: () => faker.random.arrayElement(randomFirmwareVersion),
  DateTime: () => faker.datatype.datetime(),
  String: () => faker.random.word(),
  AndroidUpdateJob: () => {
    const latestJob: DeepPartial<AndroidUpdateRejected> = {
      __typename: 'AndroidUpdateRejected',
      targetVersion: 'FB01.06',
      createdAt: faker.date.past().toJSON(),
      plannedAt: faker.date.recent().toJSON(),
      rejectionCode: 'DISPLAY_WAS_NOT_CONNECTED_AT_PLANNED_TIME',
    };
    return latestJob;
  },
  Android: () => {
    return {
      availableUpdates: ['FB01.06'],
    };
  },
  Organization: () => {
    return {
      customers: knownCustomers,
    };
  },
  Customer: () => {
    return {
      displays: new Array(300).fill(undefined),
      playlists: new Array(4).fill(undefined),
      powerSchedules: new Array(6).fill(undefined),
    };
  },
  Playlist: () => {
    return {
      media: new Array(5).fill(undefined),
    };
  },
  PowerSchedule: () => {
    return {
      timeBlocks: [],
    };
  },
  Display: () => {
    return {
      groups: () => faker.random.arrayElements(randomGroups, random(2, 3, false)),
      site: () => faker.random.arrayElement(randomSites),
    };
  },
  DisplayAlert: () => {
    const alert: DisplayAlert = {
      id: faker.datatype.uuid(),
      createdAt: faker.date.past().toISOString(),
      message: faker.random.words(3),
      displayId: faker.datatype.uuid(),
      customerHandle: faker.random.word(),
    };

    return alert;
  },
  VolumeLevelState: () => {
    return {
      reported: faker.datatype.number({ min: 0, max: 100 }),
      desired: faker.datatype.number({ min: 0, max: 100 }),
    };
  },
  VolumeMuteState: () => {
    return {
      reported: faker.datatype.boolean(),
      desired: faker.datatype.boolean(),
    };
  },
  VolumeLimitState: () => {
    const min = faker.datatype.number({ min: 0, max: 40 });
    const max = faker.datatype.number({ min, max: 100 });

    return {
      min: {
        reported: min,
        desired: null,
      },
      max: {
        reported: max,
        desired: null,
      },
    };
  },
  TimeBlock: () => {
    const hours = faker.datatype.number({ min: 0, max: 18 });

    return {
      start: `${String(hours).padStart(2, '0')}:00`,
      end: `${String(hours + 5).padStart(2, '0')}:30`,
    };
  },
};

const store = createMockStore({ schema: nonExecutableSchema, mocks: typeMocks });

store.set({
  typeName: 'Query',
  key: 'ROOT',
  fieldName: 'organization',
  value: knownOrganization,
});

const mockedSchema = addMocksToSchema({
  schema: nonExecutableSchema,
  preserveResolvers: false,
  store,
  resolvers: (store) => {
    return {
      Query: {
        display: (_: unknown, { id }: { id: string }) => {
          return store.get('Display', id);
        },
        customer: (_: unknown, { id }: { id: string }) => {
          return store.get('Customer', id);
        },
      },
      Mutation: {
        apiKeyRevoke: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const currentKeys = store.get('Query', 'ROOT', 'apiKeys') as Ref[];
          const newKeys = currentKeys.filter(({ $ref }) => $ref.key !== input.apiKeyId);
          store.set('Query', 'ROOT', 'apiKeys', newKeys);

          return {
            apiKeyId: input.apiKeyId,
          };
        },
        apiKeyCreate: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const newApiKey = store.get('ApiKey', faker.datatype.uuid()) as Ref;
          store.set(newApiKey, 'alias', input.alias);

          const currentKeys = store.get('Query', 'ROOT', 'apiKeys') as Ref[];
          store.set('Query', 'ROOT', 'apiKeys', [...currentKeys, newApiKey]);
          return {
            apiKey: newApiKey,
            apiKeyValue: faker.random.alphaNumeric(20),
          };
        },
        siteCreate: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const newSite = store.get('Site', faker.datatype.uuid()) as Ref;
          store.set(newSite, 'name', input.name);

          const customer = store.get('Customer', input.customerId as string) as Ref;
          const currentSites = store.get(customer, 'sites') as Ref[];
          store.set(customer, 'sites', currentSites.concat(newSite));
          return {
            site: newSite,
            customer: customer,
          };
        },
        playlistCreate: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const newPlaylist = store.get('Playlist', faker.datatype.uuid()) as Ref;
          store.set(newPlaylist, 'title', input.title);
          store.set(newPlaylist, 'description', input.description ?? null);

          const customer = store.get('Customer', input.customerId as string) as Ref;
          const currentPlaylists = store.get(customer, 'playlists') as Ref[];
          store.set(customer, 'playlists', currentPlaylists.concat(newPlaylist));
          return {
            playlist: newPlaylist,
          };
        },
        playlistUpdate: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const playlist = store.get('Playlist', input.playlistId as string) as Ref;
          if (!isNil(input.title)) {
            store.set(playlist, 'title', input.title);
          }
          if (!isNil(input.description)) {
            store.set(playlist, 'description', input.description ?? null);
          }
          if (!isNil(input.mediaIds)) {
            const mediaIds = input.mediaIds as string[];
            const media = mediaIds.map((id: string) => store.get('Media', id));
            store.set(playlist, 'media', media);
          }

          return {
            playlist,
          };
        },
        displayUpdateVolumeLevel: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const displayRef = store.get('Display', input.id as string) as Ref;
          const volumeRef = store.get(displayRef, 'volume') as Ref;
          store.set(volumeRef, 'level', { desired: input.level });

          setTimeout(() => {
            store.set(volumeRef, 'level', { reported: input.level, desired: null });
          }, 2000);

          return {
            display: displayRef,
          };
        },
        displayUpdateVolumeMute: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const displayRef = store.get('Display', input.id as string) as Ref;
          const volumeRef = store.get(displayRef, 'volume') as Ref;
          store.set(volumeRef, 'isMuted', { desired: input.mute });

          setTimeout(() => {
            store.set(volumeRef, 'isMuted', { reported: input.mute, desired: null });
          }, 2000);

          return {
            display: displayRef,
          };
        },
        displayUpdateVolumeLimitMin: (
          _: unknown,
          { input }: { input: Record<string, unknown> },
        ) => {
          const displayRef = store.get('Display', input.id as string) as Ref;
          const volumeRef = store.get(displayRef, 'volume') as Ref;
          const volumeLimitRef = store.get(volumeRef, 'limits') as Ref;
          store.set(volumeLimitRef, 'min', { desired: input.min });

          setTimeout(() => {
            store.set(volumeLimitRef, 'min', { reported: input.min, desired: null });
          }, 2000);

          return {
            display: displayRef,
          };
        },
        mediaCreateRequest: (_: unknown, { input }: { input: Record<string, unknown> }) => {
          const id = faker.datatype.uuid();

          setTimeout(() => {
            store.get('Media', id, {
              id,
              createdAt: faker.date.past().toDateString(),
              title: input.title,
              size: input.size,
              type: input.type,
            });
          }, S3_UPLOAD_DELAY);

          return {
            mediaId: id,
            uploadUrl: MOCK_S3_URL,
          };
        },
        displayUpdateVolumeLimitMax: (
          _: unknown,
          { input }: { input: Record<string, unknown> },
        ) => {
          const displayRef = store.get('Display', input.id as string) as Ref;
          const volumeRef = store.get(displayRef, 'volume') as Ref;
          const volumeLimitRef = store.get(volumeRef, 'limits') as Ref;
          store.set(volumeLimitRef, 'max', { desired: input.max });

          setTimeout(() => {
            store.set(volumeLimitRef, 'max', { reported: input.max, desired: null });
          }, 2000);

          return {
            display: displayRef,
          };
        },
      },
    };
  },
});

export const handlers = [
  rest.post<{ query: string; variables: Record<string, unknown>; operationName: string }>(
    process.env.REACT_APP_API_URI,
    async (req, res, ctx) => {
      const { operationName, query, variables } = req.body;
      const result = await graphql({
        schema: mockedSchema,
        source: query,
        variableValues: variables,
        operationName,
      }).catch((e) => {
        console.log(e);
        throw e;
      });

      const hasErrors = result.errors && result.errors?.length > 0;

      if (hasErrors) {
        console.log(result.errors);
      }

      return res(
        hasErrors ? ctx.status(500) : ctx.status(200),
        ctx.json(result),
        // added small delay in order to observer real-like loading states
        ctx.delay(100),
      );
    },
  ),

  rest.put(MOCK_S3_URL, (req, res, ctx) => {
    return res(ctx.delay(S3_UPLOAD_DELAY), ctx.status(200));
  }),

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rest.get('*', () => {}),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rest.post('*', () => {}),
];
