import { gql } from '@apollo/client';
import { Box, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';

export function AliasCell({
  row,
  value,
}: PropsWithChildren<CellProps<DisplayTable_DisplayFragment, string>>) {
  const isConnected = Boolean(row.original.presence?.connected);

  return (
    <Tooltip label={value}>
      <Box
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        color={isConnected ? 'gray.900' : 'gray.500'}
        fontWeight="semibold"
      >
        {value}
      </Box>
    </Tooltip>
  );
}

AliasCell.graphql = {
  fragments: {
    AliasCell_display: gql`
      fragment AliasCell_display on Display {
        id
        alias
      }
    `,
  },
};
