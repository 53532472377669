/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { EditSiteModalFragmentDoc } from './EditSiteModal.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type SiteItemFragment = { __typename: 'Site', id: string, name: string, address?: string | null };

export type UpdateSiteMutationVariables = Types.Exact<{
  input: Types.SiteUpdateInput;
}>;


export type UpdateSiteMutation = { __typename: 'Mutation', siteUpdate: { __typename: 'Site', id: string } };

export type RemoveSiteMutationVariables = Types.Exact<{
  input: Types.SiteDeleteInput;
}>;


export type RemoveSiteMutation = { __typename: 'Mutation', siteDelete: { __typename: 'SiteDeletePayload', siteId: string } };

export const SiteItemFragmentDoc = gql`
    fragment SiteItem on Site {
  id
  name
  address
  ...EditSiteModal
}
    ${EditSiteModalFragmentDoc}`;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($input: SiteUpdateInput!) {
  siteUpdate(input: $input) {
    id
  }
}
    `;
export type UpdateSiteMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = ApolloReactCommon.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const RemoveSiteDocument = gql`
    mutation RemoveSite($input: SiteDeleteInput!) {
  siteDelete(input: $input) {
    siteId
  }
}
    `;
export type RemoveSiteMutationFn = ApolloReactCommon.MutationFunction<RemoveSiteMutation, RemoveSiteMutationVariables>;

/**
 * __useRemoveSiteMutation__
 *
 * To run a mutation, you first call `useRemoveSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSiteMutation, { data, loading, error }] = useRemoveSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSiteMutation, RemoveSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveSiteMutation, RemoveSiteMutationVariables>(RemoveSiteDocument, options);
      }
export type RemoveSiteMutationHookResult = ReturnType<typeof useRemoveSiteMutation>;
export type RemoveSiteMutationResult = ApolloReactCommon.MutationResult<RemoveSiteMutation>;
export type RemoveSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSiteMutation, RemoveSiteMutationVariables>;