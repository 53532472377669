import { createIcon } from '@chakra-ui/react';

export const ScreenshotEmptyIllustration = createIcon({
  displayName: 'ScreenshotEmptyIllustration',
  viewBox: '0 0 140 140',
  defaultProps: {
    fill: 'none',
    width: '140px',
    height: '140px',
  },
  path: (
    <>
      <circle cx="70.5" cy="70.5" r="70" fill="#0A3761" />
      <g filter="url(#filter0_dd_8379_8972)">
        <path
          d="M20.7998 42.0677C20.7998 39.2128 23.082 36.8984 25.8972 36.8984H115.102C117.918 36.8984 120.2 39.2128 120.2 42.0677V95.0523C120.2 97.9072 117.918 100.222 115.102 100.222H25.8972C23.082 100.222 20.7998 97.9072 20.7998 95.0523V42.0677Z"
          fill="white"
        />
        <path
          d="M46.287 100.222H96.6242V101.514C96.6242 102.941 95.4831 104.098 94.0754 104.098H48.8357C47.4281 104.098 46.287 102.941 46.287 101.514V100.222Z"
          fill="#0F5291"
        />
        <path
          d="M46.287 98.7215H44.787H25.8972C23.9301 98.7215 22.2998 97.0986 22.2998 95.0523V42.0677C22.2998 40.0214 23.9301 38.3984 25.8972 38.3984H115.102C117.069 38.3984 118.7 40.0214 118.7 42.0677V95.0523C118.7 97.0986 117.069 98.7215 115.102 98.7215H98.1242H96.6242H46.287ZM47.8059 101.722H95.1053C95.0103 102.234 94.5704 102.598 94.0754 102.598H48.8357C48.3408 102.598 47.9009 102.234 47.8059 101.722Z"
          stroke="#627D98"
          strokeWidth="3"
        />
      </g>
      <mask id="mask0_8379_8972" maskUnits="userSpaceOnUse" x="23" y="39" width="95" height="59">
        <path
          d="M24.2969 41.8951C24.2969 41.0665 24.9687 40.3949 25.7973 40.3951L115.141 40.4208C115.969 40.421 116.641 41.0925 116.641 41.9208V95.2198C116.641 96.0482 115.969 96.7198 115.141 96.7198L25.7969 96.7198C24.9684 96.7198 24.2969 96.0482 24.2969 95.2198V41.8951Z"
          fill="#D9D9D9"
          stroke="#627D98"
        />
      </mask>
      <g mask="url(#mask0_8379_8972)">
        <path
          d="M47.6817 61.5282L23.7993 85.6819L23.7993 97.277H118.1L118.1 97.1081L68.6674 59.984C62.2699 55.1794 53.3069 55.839 47.6817 61.5282Z"
          fill="#F0F4F8"
        />
      </g>
      <g clipPath="url(#clip0_8379_8972)">
        <path
          d="M83.3332 73.0664C83.0237 73.0664 82.727 73.1893 82.5082 73.4081C82.2894 73.6269 82.1665 73.9237 82.1665 74.2331V76.5664C82.1665 77.4947 81.7977 78.3849 81.1414 79.0413C80.485 79.6976 79.5948 80.0664 78.6665 80.0664H76.3332C76.0237 80.0664 75.727 80.1893 75.5082 80.4081C75.2894 80.6269 75.1665 80.9236 75.1665 81.2331C75.1665 81.5425 75.2894 81.8392 75.5082 82.058C75.727 82.2768 76.0237 82.3997 76.3332 82.3997H78.6665C80.213 82.3979 81.6957 81.7827 82.7892 80.6891C83.8828 79.5956 84.498 78.1129 84.4998 76.5664V74.2331C84.4998 73.9237 84.3769 73.6269 84.1581 73.4081C83.9393 73.1893 83.6426 73.0664 83.3332 73.0664Z"
          fill="#0F5291"
        />
        <path
          d="M57.6667 63.7318C57.9761 63.7318 58.2728 63.6089 58.4916 63.3901C58.7104 63.1713 58.8333 62.8745 58.8333 62.5651V60.2318C58.8333 59.3035 59.2021 58.4133 59.8585 57.7569C60.5148 57.1005 61.4051 56.7318 62.3333 56.7318H64.6667C64.9761 56.7318 65.2728 56.6089 65.4916 56.3901C65.7104 56.1713 65.8333 55.8745 65.8333 55.5651C65.8333 55.2557 65.7104 54.9589 65.4916 54.7401C65.2728 54.5214 64.9761 54.3984 64.6667 54.3984H62.3333C60.7868 54.4003 59.3042 55.0155 58.2106 56.109C57.117 57.2026 56.5019 58.6852 56.5 60.2318V62.5651C56.5 62.8745 56.6229 63.1713 56.8417 63.3901C57.0605 63.6089 57.3572 63.7318 57.6667 63.7318Z"
          fill="#0F5291"
        />
        <path
          d="M64.6667 80.0664H62.3333C61.4051 80.0664 60.5148 79.6976 59.8585 79.0413C59.2021 78.3849 58.8333 77.4947 58.8333 76.5664V74.2331C58.8333 73.9237 58.7104 73.6269 58.4916 73.4081C58.2728 73.1893 57.9761 73.0664 57.6667 73.0664C57.3572 73.0664 57.0605 73.1893 56.8417 73.4081C56.6229 73.6269 56.5 73.9237 56.5 74.2331V76.5664C56.5019 78.1129 57.117 79.5956 58.2106 80.6891C59.3042 81.7827 60.7868 82.3979 62.3333 82.3997H64.6667C64.9761 82.3997 65.2728 82.2768 65.4916 82.058C65.7104 81.8392 65.8333 81.5425 65.8333 81.2331C65.8333 80.9236 65.7104 80.6269 65.4916 80.4081C65.2728 80.1893 64.9761 80.0664 64.6667 80.0664Z"
          fill="#0F5291"
        />
        <path
          d="M78.6665 54.3984H76.3332C76.0237 54.3984 75.727 54.5214 75.5082 54.7401C75.2894 54.9589 75.1665 55.2557 75.1665 55.5651C75.1665 55.8745 75.2894 56.1713 75.5082 56.3901C75.727 56.6089 76.0237 56.7318 76.3332 56.7318H78.6665C79.5948 56.7318 80.485 57.1005 81.1414 57.7569C81.7977 58.4133 82.1665 59.3035 82.1665 60.2318V62.5651C82.1665 62.8745 82.2894 63.1713 82.5082 63.3901C82.727 63.6089 83.0237 63.7318 83.3332 63.7318C83.6426 63.7318 83.9393 63.6089 84.1581 63.3901C84.3769 63.1713 84.4998 62.8745 84.4998 62.5651V60.2318C84.498 58.6852 83.8828 57.2026 82.7892 56.109C81.6957 55.0155 80.213 54.4003 78.6665 54.3984V54.3984Z"
          fill="#0F5291"
        />
      </g>
      <path
        d="M102.7 61.3984C104.085 61.3984 105.438 60.9879 106.589 60.2187C107.74 59.4496 108.638 58.3563 109.167 57.0772C109.697 55.7981 109.836 54.3907 109.566 53.0328C109.296 51.6749 108.629 50.4277 107.65 49.4487C106.671 48.4697 105.424 47.803 104.066 47.5329C102.708 47.2628 101.3 47.4015 100.021 47.9313C98.7423 48.4611 97.6491 49.3583 96.8799 50.5094C96.1107 51.6606 95.7002 53.014 95.7002 54.3984C95.7002 56.255 96.4377 58.0354 97.7504 59.3482C99.0632 60.6609 100.844 61.3984 102.7 61.3984ZM102.7 51.3984C103.294 51.3984 103.874 51.5744 104.367 51.904C104.86 52.2337 105.245 52.7022 105.472 53.2504C105.699 53.7986 105.758 54.4018 105.643 54.9837C105.527 55.5657 105.241 56.1002 104.822 56.5198C104.402 56.9393 103.867 57.225 103.285 57.3408C102.704 57.4566 102.1 57.3971 101.552 57.1701C101.004 56.943 100.535 56.5585 100.206 56.0652C99.8761 55.5718 99.7002 54.9918 99.7002 54.3984C99.7002 53.6028 100.016 52.8397 100.579 52.2771C101.141 51.7145 101.905 51.3984 102.7 51.3984Z"
        fill="#F0F4F8"
      />
      <defs>
        <filter
          id="filter0_dd_8379_8972"
          x="7.52946"
          y="34.6867"
          width="125.941"
          height="93.7399"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8379_8972"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8379_8972" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8379_8972"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8379_8972"
            result="effect2_dropShadow_8379_8972"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8379_8972"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_8379_8972">
          <rect width="28" height="28" fill="white" transform="translate(56.5 54.3984)" />
        </clipPath>
      </defs>
    </>
  ),
});
