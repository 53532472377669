/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayRebootModal_DisplayFragment = { __typename: 'Display', id: string, reboot?: { __typename: 'RebootState', latestJob: { __typename: 'RebootJobAborted', abortedAt: any, id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobCompleted', completedAt: any, id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobInProgress', id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobPlanned', id: string, createdAt: any, plannedAt: any } } | null };

export type DisplayBulkRebootMutationVariables = Types.Exact<{
  input: Types.DisplayBulkRebootInput;
}>;


export type DisplayBulkRebootMutation = { __typename: 'Mutation', displayBulkReboot: { __typename: 'DisplayBulkRebootPayload', displays: Array<{ __typename: 'Display', id: string, reboot?: { __typename: 'RebootState', latestJob: { __typename: 'RebootJobAborted', abortedAt: any, id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobCompleted', completedAt: any, id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobInProgress', id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobPlanned', id: string, createdAt: any, plannedAt: any } } | null }> } };

export const DisplayRebootModal_DisplayFragmentDoc = gql`
    fragment DisplayRebootModal_display on Display {
  id
  reboot {
    latestJob {
      __typename
      id
      createdAt
      plannedAt
      ... on RebootJobCompleted {
        completedAt
      }
      ... on RebootJobAborted {
        abortedAt
      }
    }
  }
}
    `;
export const DisplayBulkRebootDocument = gql`
    mutation DisplayBulkReboot($input: DisplayBulkRebootInput!) {
  displayBulkReboot(input: $input) {
    displays {
      id
      ...DisplayRebootModal_display
    }
  }
}
    ${DisplayRebootModal_DisplayFragmentDoc}`;
export type DisplayBulkRebootMutationFn = ApolloReactCommon.MutationFunction<DisplayBulkRebootMutation, DisplayBulkRebootMutationVariables>;

/**
 * __useDisplayBulkRebootMutation__
 *
 * To run a mutation, you first call `useDisplayBulkRebootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayBulkRebootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayBulkRebootMutation, { data, loading, error }] = useDisplayBulkRebootMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisplayBulkRebootMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisplayBulkRebootMutation, DisplayBulkRebootMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisplayBulkRebootMutation, DisplayBulkRebootMutationVariables>(DisplayBulkRebootDocument, options);
      }
export type DisplayBulkRebootMutationHookResult = ReturnType<typeof useDisplayBulkRebootMutation>;
export type DisplayBulkRebootMutationResult = ApolloReactCommon.MutationResult<DisplayBulkRebootMutation>;
export type DisplayBulkRebootMutationOptions = ApolloReactCommon.BaseMutationOptions<DisplayBulkRebootMutation, DisplayBulkRebootMutationVariables>;