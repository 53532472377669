import { gql } from '@apollo/client';
import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Spinner,
  Square,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isEmpty, isNil } from 'lodash';
import { CSSProperties, ReactNode } from 'react';
import { useAuth } from '~auth/useAuth';
import { ErrorIcon, SyncIcon, WarningIcon } from '~components/ui/icons';
import {
  ScreenshotDisabledIllustration,
  ScreenshotEmptyIllustration,
} from '~components/ui/illustrations';
import { ScreenshotErrorIllustration } from '~components/ui/illustrations/ScreenshotErrorIllustration';
import { RelativeTime, SHORT_FORMAT_TIME } from '~components/ui/RelativeTime';
import { Orientation } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplayScreenshot_DisplayFragment,
  useCaptureScreenshotMutation,
} from './__generated__/DisplayScreenshot.graphql';

interface Props {
  display: DisplayScreenshot_DisplayFragment;
}

export function DisplayScreenshot({ display }: Props) {
  const toast = useToast();
  const [captureScreenshot] = useCaptureScreenshotMutation();
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();

  const onClick = async () => {
    try {
      await captureScreenshot({
        variables: {
          input: {
            displayId: display.id,
          },
        },
      });

      analytics.track('displayPreviewUpdate');
    } catch (error) {
      toast({
        status: 'error',
        title: 'Cannot capture screen',
        description: fromError(error, 'CaptureScreenshot', {
          DISPLAY_OFFLINE: 'Looks like the display is disconnected. Please try again later.',
        }),
      });
    }
  };

  const screenshot = display.screenshot;
  const screenshotTypeName = screenshot?.__typename;
  const syncButtonLabel =
    screenshotTypeName === 'ScreenshotEmpty'
      ? 'Capture screen'
      : screenshotTypeName === 'ScreenshotPending'
      ? 'Updating'
      : '';

  //render logic corresponding to ScreenshotTypeName
  const isScreenshotNil = isNil(screenshot);
  const isScreenshotEmpty = screenshotTypeName === 'ScreenshotEmpty';
  const isScreenshotPending = screenshotTypeName === 'ScreenshotPending';
  const isScreenshotResolved = screenshotTypeName === 'ScreenshotResolved';
  const isScreenshotRejected = screenshotTypeName === 'ScreenshotRejected';

  const hasDisplayScreenshotCreatePermission = !verifyUserPermissions([
    Permission.DisplayScreenshotCreate,
  ]);

  const screenshotDisabled = isScreenshotNil || hasDisplayScreenshotCreatePermission;
  const shouldRenderSyncButton =
    isScreenshotEmpty || isScreenshotPending || isScreenshotResolved || isScreenshotRejected;
  const shouldRenderTime = isScreenshotResolved && !isNil(screenshot?.createdAt);

  const orientation = display.orientation?.desired ?? display.orientation?.reported;
  const isPortraitOrientation = orientation === Orientation.Portrait;

  const aspectRatio = orientation === Orientation.Portrait ? 9 / 16 : 16 / 9;

  return (
    <>
      <AspectRatio
        ratio={aspectRatio}
        maxW={isPortraitOrientation ? '248px' : '443px'}
        margin="0 auto"
      >
        <Box
          borderRadius="base"
          bgGradient={
            screenshotDisabled
              ? 'linear(to-tr, gray.50, gray.50)'
              : 'linear(to-tr, blue.700, blue.950)'
          }
        >
          <ScreenshotImage
            aspectRatio={aspectRatio}
            src={screenshot?.url ?? undefined}
            renderOverlay={isScreenshotPending}
            icon={
              screenshotDisabled ? (
                <ScreenshotDisabledIllustration width="140px" height="140px" />
              ) : isScreenshotEmpty ? (
                <ScreenshotEmptyIllustration width="140px" height="140px" />
              ) : isScreenshotRejected ? (
                <ScreenshotErrorIllustration width="140px" height="140px" />
              ) : isScreenshotPending ? (
                <Spinner color="white" />
              ) : null
            }
          />
        </Box>
      </AspectRatio>
      <ScreenshotFooter>
        {hasDisplayScreenshotCreatePermission ? (
          <HStack>
            <WarningIcon color="orange.400" width="24px" height="24px" />
            <Text fontSize="sm">Insufficient permissions for screen capture</Text>
          </HStack>
        ) : screenshotDisabled ? (
          <HStack>
            <WarningIcon color="orange.400" width="24px" height="24px" />
            <Text fontSize="sm">Screen capture not available for this display</Text>
          </HStack>
        ) : isScreenshotRejected ? (
          <HStack>
            <ErrorIcon color="red.400" width="24px" height="24px" />
            <Text fontSize="sm">Cannot capture screen</Text>
          </HStack>
        ) : shouldRenderTime ? (
          <RelativeTime
            ts={screenshot.createdAt}
            format={SHORT_FORMAT_TIME}
            render={(time) => (
              <Text mr="2" fontSize="sm">
                Updated {time}
              </Text>
            )}
          />
        ) : (
          <Box />
        )}
        {!screenshotDisabled && shouldRenderSyncButton && (
          <SyncButton label={syncButtonLabel} onClick={onClick} disabled={isScreenshotPending} />
        )}
      </ScreenshotFooter>
    </>
  );
}

function ScreenshotImage({
  src,
  width = 446,
  aspectRatio = 16 / 9,
  renderOverlay,
  icon,
}: {
  src?: string;
  width?: number;
  aspectRatio?: number;
  renderOverlay?: boolean;
  icon?: ReactNode;
}) {
  const height = width / aspectRatio;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}"></svg>`;
  const sizer = (
    <img
      role="presentation"
      style={{
        display: 'block',
        width: '100%',
        borderRadius: 'base',
      }}
      src={`data:image/svg+xml;base64,${btoa(svg)}`}
    />
  );

  const absolutePositioning: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'base',
  };

  const hasSrc = !isNil(src);
  const hasIcon = !isNil(icon);

  return (
    <Box position="relative" overflow="hidden" display="block">
      {sizer}

      {hasSrc && (
        <>
          <Box style={{ ...absolutePositioning }} objectFit="cover" background="black" />
          <Image
            style={{ ...absolutePositioning }}
            objectFit="contain"
            src={src}
            alt="Display screenshot"
            backgroundColor="transparent"
            fallback={
              <Square style={{ ...absolutePositioning }}>
                <Spinner color="white" />
              </Square>
            }
          />
        </>
      )}

      {renderOverlay && (
        <Square
          style={{ ...absolutePositioning }}
          bgGradient="linear(to-b, rgb(24, 50, 88, 0.4), rgb(0, 36, 87, 0.9))"
          opacity="0.90"
        />
      )}

      {hasIcon && (
        <Square id="icon" style={{ ...absolutePositioning }}>
          {icon}
        </Square>
      )}
    </Box>
  );
}

function ScreenshotFooter({ children }: { children?: ReactNode }) {
  return (
    <HStack h="6" spacing="0" color="black" width="full" margin="12px auto">
      {children}
    </HStack>
  );
}

function SyncButton({
  label,
  disabled,
  onClick,
}: {
  label: string;
  disabled: boolean;
  onClick?: () => Promise<void>;
}) {
  const hasLabel = !isNil(label) && !isEmpty(label);
  return (
    <Button
      p="1"
      h="26px"
      colorScheme="blackAlpha"
      variant="ghost"
      isDisabled={disabled}
      onClick={onClick}
      _hover={{ backgroundColor: 'blackAlpha.500' }}
    >
      {hasLabel && (
        <Text mr="4" fontWeight="normal" color="gray.800" fontSize="sm">
          {label}
        </Text>
      )}
      <SyncIcon color="gray.800" />
    </Button>
  );
}

DisplayScreenshot.graphql = {
  fragments: {
    DisplayInfoSection_display: gql`
      fragment DisplayScreenshot_display on Display {
        id
        screenshot {
          __typename
          url
          createdAt
          ... on ScreenshotRejected {
            rejectedAt
            errorCode
          }
        }
        orientation {
          desired
          reported
        }
      }
    `,
  },
  mutations: {
    captureScreenshot: gql`
      mutation CaptureScreenshot($input: DisplayCaptureScreenshotInput!) {
        displayCaptureScreenshot(input: $input) {
          display {
            id
            screenshot {
              __typename
              url
            }
          }
        }
      }
    `,
  },
};
