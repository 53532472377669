import { isNil } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, SortingRule, TableState } from 'react-table';
import { DisplayTable_DisplayFragment } from '~components/displays/DisplayTable/__generated__/DisplayTable.graphql';
import {
  Columns,
  FilterKeys,
  FilterOption,
  FilterOptions,
} from '~components/displays/DisplayTable/constants';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';

type DisplayFilter = Partial<TableState<DisplayTable_DisplayFragment>>;

const QUERY_DELIMITER = ',';
const PAGE_SIZE_KEY = 'pageSize';
const SORT_BY_KEY = 'sortBy';
const SORT_BY_KEY_VALUE_DELIMITER = '|';

const filterQueryParams = (queryParams: URLSearchParams) => {
  const newQueryParams = new URLSearchParams();
  queryParams.forEach((value, key) => {
    if ([PAGE_SIZE_KEY, SORT_BY_KEY, ...FilterKeys].includes(key)) {
      newQueryParams.set(key, value);
    }
  });
  return newQueryParams;
};

const filterSearchParams = (queryParams: URLSearchParams): string => {
  return queryParams.get('search') || '';
};

const defaultDisplaysQuery = filterQueryParams(new URLSearchParams(window.location.search));
const defaultSearchQuery = filterSearchParams(new URLSearchParams(window.location.search));
export const DisplaysQueryContext = createContext<{
  displaysQuery: URLSearchParams;
  setDisplaysQuery?: Dispatch<SetStateAction<URLSearchParams>>;
  search: string;
  setSearch?: Dispatch<SetStateAction<string>>;
}>({ displaysQuery: defaultDisplaysQuery, search: defaultSearchQuery });

export const DisplaysQueryProvider = ({ children }: { children: ReactNode }) => {
  const [displaysQuery, setDisplaysQuery] = useState<URLSearchParams>(defaultDisplaysQuery);
  const [search, setSearch] = useState<string>(defaultSearchQuery);
  const contextValue = {
    displaysQuery,
    setDisplaysQuery,
    search,
    setSearch,
  };

  return (
    <DisplaysQueryContext.Provider value={contextValue}>{children}</DisplaysQueryContext.Provider>
  );
};

const defaultPersistOptions = {
  reset: false,
};

export const useDisplaysQuery = () => {
  const navigate = useNavigate();

  const { displaysQuery, setDisplaysQuery } = useContext(DisplaysQueryContext);
  const displaysQueryString = displaysQuery.toString();
  const [queryParams, setQueryParams] = useState<URLSearchParams | undefined>(undefined);

  useEffect(() => {
    navigate(displaysQueryString === '' ? window.location.pathname : `?${displaysQueryString}`, {
      replace: true,
    });

    if (!isNil(queryParams)) {
      setDisplaysQuery?.(queryParams);
    }
  }, [displaysQueryString, navigate, queryParams, setDisplaysQuery]);

  const persistedDisplaysState = useCallback(
    (filterOptions: FilterOptions): DisplayFilter => {
      const query = Object.fromEntries(displaysQuery);
      const pageSize = parseInt(query.pageSize);
      const sortBy = query.sortBy;

      return {
        pageSize: pageSizes.includes(pageSize) ? pageSize : pageSizes[0],
        filters: FilterKeys.map((column: FilterKeys) => ({
          id: column,
          value: query[column]
            ? query[column]
                ?.split(QUERY_DELIMITER)
                .map((value) =>
                  filterOptions[column].all.find((f: FilterOption) => f.value === value),
                )
                .filter(Boolean)
            : [],
        })),
        sortBy:
          sortBy !== undefined
            ? sortBy.split(QUERY_DELIMITER).map((sortPair) => {
                const [key, value] = sortPair.split(SORT_BY_KEY_VALUE_DELIMITER);
                return { id: key, desc: value === 'true' };
              })
            : [],
        hiddenColumns: [Columns.Firmware, Columns.Playlist, Columns.PowerSchedule],
      };
    },
    [displaysQuery],
  );

  const persistFilters = useCallback(
    (filters: Filters<DisplayTable_DisplayFragment>, options = defaultPersistOptions): void => {
      const params = new URLSearchParams(options.reset ? undefined : displaysQuery);
      filters.forEach(({ id, value }) => {
        if (value?.length > 0) {
          params.set(id, value.map(({ value = '' }) => value).join(QUERY_DELIMITER));
        } else {
          params.delete(id);
        }
      });
      setQueryParams(filterQueryParams(params));
    },
    [displaysQuery],
  );

  const persistPageSize = useCallback(
    (pageSize: number): void => {
      const params = new URLSearchParams(displaysQuery);
      if (pageSize === pageSizes[0]) {
        params.delete(PAGE_SIZE_KEY);
      } else {
        params.set(PAGE_SIZE_KEY, `${pageSize}`);
      }
      setQueryParams(filterQueryParams(params));
    },
    [displaysQuery],
  );

  const persistSorting = useCallback(
    (sortByRules: Array<SortingRule<DisplayTable_DisplayFragment>>): void => {
      const params = new URLSearchParams(displaysQuery);

      if (sortByRules.length === 0) {
        params.delete(SORT_BY_KEY);
      } else {
        params.set(
          SORT_BY_KEY,
          sortByRules
            .reduce<string[]>((acc, { id, desc }) => {
              acc.push(`${id}${SORT_BY_KEY_VALUE_DELIMITER}${desc}`);
              return acc;
            }, [])
            .join(QUERY_DELIMITER),
        );
      }
      setQueryParams(filterQueryParams(params));
    },
    [displaysQuery],
  );

  return {
    persistedDisplaysState,
    persistFilters,
    persistPageSize,
    persistSorting,
  };
};
