/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayVolumeSettings_DisplayFragment = { __typename: 'Display', id: string, volume?: { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', reported: boolean, desired?: boolean | null } | null, level?: { __typename: 'VolumeLevelState', reported: number, desired?: number | null } | null, limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null };

export type UpdateVolumeLevelMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateVolumeLevelInput;
}>;


export type UpdateVolumeLevelMutation = { __typename: 'Mutation', displayUpdateVolumeLevel: { __typename: 'DisplayUpdateVolumeLevelPayload', display: { __typename: 'Display', id: string, volume?: { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', reported: boolean, desired?: boolean | null } | null, level?: { __typename: 'VolumeLevelState', reported: number, desired?: number | null } | null, limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null } } };

export type UpdateVolumeMuteMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateVolumeMuteInput;
}>;


export type UpdateVolumeMuteMutation = { __typename: 'Mutation', displayUpdateVolumeMute: { __typename: 'DisplayUpdateVolumeMutePayload', display: { __typename: 'Display', id: string, volume?: { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', reported: boolean, desired?: boolean | null } | null, level?: { __typename: 'VolumeLevelState', reported: number, desired?: number | null } | null, limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null } } };

export const DisplayVolumeSettings_DisplayFragmentDoc = gql`
    fragment DisplayVolumeSettings_display on Display {
  id
  volume {
    isMuted {
      reported
      desired
    }
    level {
      reported
      desired
    }
    limits {
      min {
        reported
        desired
      }
      max {
        reported
        desired
      }
    }
  }
}
    `;
export const UpdateVolumeLevelDocument = gql`
    mutation UpdateVolumeLevel($input: DisplayUpdateVolumeLevelInput!) {
  displayUpdateVolumeLevel(input: $input) {
    display {
      id
      ...DisplayVolumeSettings_display
    }
  }
}
    ${DisplayVolumeSettings_DisplayFragmentDoc}`;
export type UpdateVolumeLevelMutationFn = ApolloReactCommon.MutationFunction<UpdateVolumeLevelMutation, UpdateVolumeLevelMutationVariables>;

/**
 * __useUpdateVolumeLevelMutation__
 *
 * To run a mutation, you first call `useUpdateVolumeLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVolumeLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVolumeLevelMutation, { data, loading, error }] = useUpdateVolumeLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVolumeLevelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVolumeLevelMutation, UpdateVolumeLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateVolumeLevelMutation, UpdateVolumeLevelMutationVariables>(UpdateVolumeLevelDocument, options);
      }
export type UpdateVolumeLevelMutationHookResult = ReturnType<typeof useUpdateVolumeLevelMutation>;
export type UpdateVolumeLevelMutationResult = ApolloReactCommon.MutationResult<UpdateVolumeLevelMutation>;
export type UpdateVolumeLevelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVolumeLevelMutation, UpdateVolumeLevelMutationVariables>;
export const UpdateVolumeMuteDocument = gql`
    mutation UpdateVolumeMute($input: DisplayUpdateVolumeMuteInput!) {
  displayUpdateVolumeMute(input: $input) {
    display {
      id
      ...DisplayVolumeSettings_display
    }
  }
}
    ${DisplayVolumeSettings_DisplayFragmentDoc}`;
export type UpdateVolumeMuteMutationFn = ApolloReactCommon.MutationFunction<UpdateVolumeMuteMutation, UpdateVolumeMuteMutationVariables>;

/**
 * __useUpdateVolumeMuteMutation__
 *
 * To run a mutation, you first call `useUpdateVolumeMuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVolumeMuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVolumeMuteMutation, { data, loading, error }] = useUpdateVolumeMuteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVolumeMuteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVolumeMuteMutation, UpdateVolumeMuteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateVolumeMuteMutation, UpdateVolumeMuteMutationVariables>(UpdateVolumeMuteDocument, options);
      }
export type UpdateVolumeMuteMutationHookResult = ReturnType<typeof useUpdateVolumeMuteMutation>;
export type UpdateVolumeMuteMutationResult = ApolloReactCommon.MutationResult<UpdateVolumeMuteMutation>;
export type UpdateVolumeMuteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVolumeMuteMutation, UpdateVolumeMuteMutationVariables>;