/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePowerScheduleModal_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, allDisplays: Array<{ __typename: 'Display', id: string }> };

export type DeletePowerSchedulesMutationVariables = Types.Exact<{
  input: Types.PowerSchedulesDeleteInput;
}>;


export type DeletePowerSchedulesMutation = { __typename: 'Mutation', powerSchedulesDelete: { __typename: 'PowerSchedulesDeletePayload', powerScheduleIds: Array<string> } };

export const DeletePowerScheduleModal_PowerScheduleFragmentDoc = gql`
    fragment DeletePowerScheduleModal_powerSchedule on PowerSchedule {
  id
  allDisplays: displays {
    id
  }
}
    `;
export const DeletePowerSchedulesDocument = gql`
    mutation DeletePowerSchedules($input: PowerSchedulesDeleteInput!) {
  powerSchedulesDelete(input: $input) {
    powerScheduleIds
  }
}
    `;
export type DeletePowerSchedulesMutationFn = ApolloReactCommon.MutationFunction<DeletePowerSchedulesMutation, DeletePowerSchedulesMutationVariables>;

/**
 * __useDeletePowerSchedulesMutation__
 *
 * To run a mutation, you first call `useDeletePowerSchedulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePowerSchedulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePowerSchedulesMutation, { data, loading, error }] = useDeletePowerSchedulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePowerSchedulesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePowerSchedulesMutation, DeletePowerSchedulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePowerSchedulesMutation, DeletePowerSchedulesMutationVariables>(DeletePowerSchedulesDocument, options);
      }
export type DeletePowerSchedulesMutationHookResult = ReturnType<typeof useDeletePowerSchedulesMutation>;
export type DeletePowerSchedulesMutationResult = ApolloReactCommon.MutationResult<DeletePowerSchedulesMutation>;
export type DeletePowerSchedulesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePowerSchedulesMutation, DeletePowerSchedulesMutationVariables>;