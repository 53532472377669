import { BoxProps } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { MouseEvent } from 'react';

type Props = BoxProps;

export function StopClickPropagation({ onClick, ...rest }: Props) {
  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    onClick?.(e);
  }

  return <Box onClick={handleClick} {...rest} />;
}
