import { gql } from '@apollo/client';
import { Box, Button, chakra, Heading, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  PowerScheduleCard_CustomerFragment,
  PowerScheduleCard_DisplayFragment,
} from '~components/displays/DisplayDetail/summary/__generated__/PowerScheduleCard.graphql';
import { AlertPowerScheduleSync } from '~components/displays/DisplayDetail/summary/AlertPowerScheduleSync';
import { PowerScheduleTimeline } from '~components/displays/DisplayDetail/summary/PowerScheduleTimeline';
import { SummaryCard } from '~components/displays/DisplayDetail/summary/SummaryCard';
import { ManagePowerScheduleModal } from '~components/displays/ManagePowerScheduleModal';
import { usePowerSchedules } from '~components/displays/usePowerSchedules';
import { FormLabelPendingIndicator } from '~components/ui/FormLabelPendingIndicator';
import { createNextTimeBlockActionLabel } from '~utils/timeBlocks';

interface Props {
  display: PowerScheduleCard_DisplayFragment;
  customer: PowerScheduleCard_CustomerFragment;
  isDisabled?: boolean;
}

export function PowerScheduleCard({ display, customer, isDisabled = false }: Props) {
  const timeZone = display.timeZone?.desired ?? display.timeZone?.reported;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getPowerScheduleState } = usePowerSchedules();
  const powerScheduleState = useMemo(
    () => getPowerScheduleState(display),
    [display, getPowerScheduleState],
  );

  return (
    <SummaryCard title="Power schedule">
      <AlertPowerScheduleSync display={display} />
      <VStack spacing="5" alignItems="stretch">
        <HStack justifyContent="space-between" alignContent="start">
          <Box display="flex" flexDir="column">
            <Heading size="md" color="gray.900" mb="0" lineHeight="7">
              {display.powerSchedule?.schedule?.title || 'No schedule'}{' '}
              <FormLabelPendingIndicator
                isPending={['power_schedule_syncing', 'power_schedule_sync_planned'].includes(
                  powerScheduleState.kind,
                )}
                label="Syncing power schedule"
              />
            </Heading>
            <chakra.span color="gray.500">
              {display.powerSchedule?.schedule
                ? createNextTimeBlockActionLabel(
                    display.powerSchedule.schedule.timeBlocks,
                    timeZone,
                  )
                : "You haven't synced a power schedule to this display yet."}
            </chakra.span>
          </Box>
          <Button
            variant="outline"
            colorScheme="blue"
            size="sm"
            onClick={onOpen}
            isDisabled={
              isDisabled ||
              powerScheduleState.kind === 'power_schedule_syncing' ||
              powerScheduleState.kind === 'power_schedule_sync_planned'
            }
          >
            {powerScheduleState.kind === 'empty' ? 'Choose schedule' : 'Change schedule'}
          </Button>
        </HStack>
        <PowerScheduleTimeline display={display} />
      </VStack>

      <ManagePowerScheduleModal
        customer={customer}
        display={display}
        isOpen={isOpen}
        onCancel={onClose}
        onSuccess={onClose}
      />
    </SummaryCard>
  );
}

PowerScheduleCard.graphql = {
  fragments: {
    PowerScheduleCard_customer: gql`
      fragment PowerScheduleCard_customer on Customer {
        id
        ...ManagePowerScheduleModal_customer
      }
    `,
    PowerScheduleCard_display: gql`
      fragment PowerScheduleCard_display on Display {
        id
        power {
          reportedAt
          desired
        }
        timeZone {
          reported
          desired
        }
        ...AlertPowerScheduleSync_display
        ...PowerScheduleTimeline_display
        ...ManagePowerScheduleModal_display
      }
    `,
  },
};
