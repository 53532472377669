/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayAutoRestart_DisplayFragment = { __typename: 'Display', id: string, autoRestart?: { __typename: 'AutoRestartState', reported: { __typename: 'AutoRestart', switch: string, time?: string | null }, desired?: { __typename: 'AutoRestart', switch: string, time?: string | null } | null } | null };

export type UpdateAutoRestartMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateAutoRestartInput;
}>;


export type UpdateAutoRestartMutation = { __typename: 'Mutation', displayUpdateAutoRestart: { __typename: 'DisplayUpdateAutoRestartPayload', display: { __typename: 'Display', id: string, autoRestart?: { __typename: 'AutoRestartState', reported: { __typename: 'AutoRestart', switch: string, time?: string | null }, desired?: { __typename: 'AutoRestart', switch: string, time?: string | null } | null } | null } } };

export const DisplayAutoRestart_DisplayFragmentDoc = gql`
    fragment DisplayAutoRestart_display on Display {
  id
  autoRestart {
    reported {
      switch
      time
    }
    desired {
      switch
      time
    }
  }
}
    `;
export const UpdateAutoRestartDocument = gql`
    mutation updateAutoRestart($input: DisplayUpdateAutoRestartInput!) {
  displayUpdateAutoRestart(input: $input) {
    display {
      id
      ...DisplayAutoRestart_display
    }
  }
}
    ${DisplayAutoRestart_DisplayFragmentDoc}`;
export type UpdateAutoRestartMutationFn = ApolloReactCommon.MutationFunction<UpdateAutoRestartMutation, UpdateAutoRestartMutationVariables>;

/**
 * __useUpdateAutoRestartMutation__
 *
 * To run a mutation, you first call `useUpdateAutoRestartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoRestartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoRestartMutation, { data, loading, error }] = useUpdateAutoRestartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutoRestartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAutoRestartMutation, UpdateAutoRestartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAutoRestartMutation, UpdateAutoRestartMutationVariables>(UpdateAutoRestartDocument, options);
      }
export type UpdateAutoRestartMutationHookResult = ReturnType<typeof useUpdateAutoRestartMutation>;
export type UpdateAutoRestartMutationResult = ApolloReactCommon.MutationResult<UpdateAutoRestartMutation>;
export type UpdateAutoRestartMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAutoRestartMutation, UpdateAutoRestartMutationVariables>;