import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Tag,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components, SelectOption } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  useBulkAddGroupsMutation,
  useGroupCreateMutation,
} from './__generated__/BulkAddGroups.graphql';
import { BulkActionComponent } from './BulkActionModal';

interface FormData {
  displayIds: string[];
  groups: SelectOption[];
}

export const BulkAddGroups: BulkActionComponent = ({
  customer,
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const availableGroups = useMemo(
    () =>
      (customer.groups || []).map<SelectOption>((group) => ({
        label: group.name,
        value: group.id,
      })),
    [customer.groups],
  );

  const displayIds = displays.map((d) => d.id);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    setValue,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      groups: [],
      displayIds: displayIds,
    },
  });

  const toast = useToast();
  const [createGroup] = useGroupCreateMutation();
  const [bulkAddGroups] = useBulkAddGroupsMutation();

  const handleCreate = useCallback(
    (name: string) => {
      createGroup({
        variables: {
          input: {
            customerId: customer.id,
            name,
          },
        },
      }).then(async ({ data }) => {
        if (!data?.groupCreate.group?.id) {
          throw new Error('No group id returned from CreateGroup');
        }
        const values = getValues();
        setValue('groups', [
          ...values.groups,
          {
            label: data.groupCreate.group.name,
            value: data.groupCreate.group.id,
          },
        ]);
      });
    },
    [createGroup, customer, getValues, setValue],
  );

  const analytics = useAnalyticsReporter();
  const onSubmit = useCallback(
    async ({ displayIds, groups }: FormData) => {
      try {
        await bulkAddGroups({
          variables: {
            input: {
              displayIds: displayIds,
              groupIds: groups.map((g) => g.value),
            },
          },
        });

        if (groups.length === 1) {
          analytics.track('groupCreate');
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'addGroups',
            displayCount: displayIds.length,
          });
        }

        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot add groups in bulk',
          description: fromError(err, 'bulkAddGroups'),
        });
      }
    },
    [analytics, bulkAddGroups, onSuccess, toast],
  );

  return (
    <ModalContent>
      <ModalHeader>Add Group(s)</ModalHeader>
      <ModalCloseButton onClick={onCancel} isDisabled={isSubmitting} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl marginBottom="6">
            <FormLabel>Group(s) to add</FormLabel>
            <Controller
              name="groups"
              control={control}
              render={({ field }) => {
                return (
                  <CreatableSelect
                    options={availableGroups}
                    value={field.value}
                    placeholder="Select or create a group"
                    onChange={(value) => field.onChange(value)}
                    onCreateOption={handleCreate}
                    components={components}
                    formatCreateLabel={(value) => (
                      <>
                        <chakra.span fontWeight={'bold'} fontSize="md">
                          Create
                        </chakra.span>
                        <Tag
                          colorScheme="blue"
                          variant="outline"
                          display="inline"
                          paddingY="1"
                          paddingX="2"
                          marginLeft="2"
                          verticalAlign="middle"
                          alignItems="center"
                        >
                          {value}
                        </Tag>
                      </>
                    )}
                    styles={{
                      input: (base) => ({
                        ...base,
                        margin: 0,
                        marginLeft: '0.5rem',
                      }),
                    }}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    isMulti={true}
                    isClearable={false}
                  />
                );
              }}
            ></Controller>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Stack flex="1" direction="row" alignItems="center">
            <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
            <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="ghost"
                colorScheme="blue"
                onClick={onCancel}
                isDisabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                marginLeft="3"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Apply
              </Button>
            </Box>
          </Stack>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

BulkAddGroups.graphql = {
  fragments: {
    BulkAddGroups_customer: gql`
      fragment BulkAddGroups_customer on Customer {
        id
        groups {
          id
          name
        }
      }
    `,
  },
  mutations: {
    BulkAddGroups: gql`
      mutation BulkAddGroups($input: DisplayBulkAddGroupsInput!) {
        displayBulkAddGroups(input: $input) {
          displays {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
    CreateGroup: gql`
      mutation GroupCreate($input: GroupCreateInput!) {
        groupCreate(input: $input) {
          group {
            id
            name
          }
          customer {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
  },
};
