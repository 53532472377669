import { Permission } from '@tp-vision/roles-permissions';
import { ReactNode } from 'react';
import { useAuth } from './useAuth';

interface Props {
  requiredPermissions: Permission[];
  children: ReactNode;
}

export function Shield({ requiredPermissions, children }: Props) {
  const { verifyUserPermissions } = useAuth();

  if (!verifyUserPermissions(requiredPermissions)) {
    return null;
  }

  return <>{children}</>;
}
