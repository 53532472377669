import { createIcon } from '@chakra-ui/react';

export const WarningIcon = createIcon({
  displayName: 'WarningIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M22.4445 10.6589L13.3437 1.55676C12.9876 1.20074 12.5048 1.00073 12.0012 1.0007C11.4977 1.00066 11.0148 1.20062 10.6587 1.55659L1.55676 10.6585C1.20075 11.0146 1.00074 11.4975 1.00071 12.001C1.00068 12.5045 1.20063 12.9875 1.55659 13.3436L10.6574 22.4448C11.0134 22.8008 11.4963 23.0008 11.9998 23.0008C12.5034 23.0009 12.9863 22.8009 13.3424 22.4449L22.4443 13.3449C22.6207 13.1686 22.7607 12.9592 22.8562 12.7288C22.9517 12.4984 23.0008 12.2514 23.0009 12.0019C23.0009 11.7525 22.9517 11.5055 22.8563 11.2751C22.7608 11.0446 22.6209 10.8352 22.4445 10.6589ZM11.116 6.69986C11.116 6.44805 11.2161 6.20655 11.3941 6.02851C11.5722 5.85046 11.8137 5.75044 12.0655 5.75046C12.3173 5.75047 12.5588 5.85052 12.7368 6.02859C12.9149 6.20666 13.0149 6.44817 13.0149 6.69998L13.0145 12.3968C13.0145 12.6486 12.9145 12.8901 12.7364 13.0681C12.5583 13.2462 12.3168 13.3462 12.065 13.3462C11.8132 13.3461 11.5717 13.2461 11.3937 13.068C11.2156 12.89 11.1156 12.6485 11.1156 12.3966L11.116 6.69986ZM12.1132 17.6282L12.0866 17.6282C11.7122 17.6268 11.3529 17.4808 11.0837 17.2207C10.8144 16.9606 10.656 16.6065 10.6416 16.2324C10.6347 16.0463 10.6649 15.8607 10.7305 15.6864C10.7962 15.5121 10.896 15.3526 11.024 15.2174C11.152 15.0821 11.3058 14.9737 11.4762 14.8986C11.6466 14.8235 11.8303 14.7831 12.0165 14.7798L12.0431 14.7798C12.4176 14.7808 12.7772 14.9267 13.0465 15.1869C13.3159 15.4471 13.4741 15.8014 13.4881 16.1756C13.495 16.3618 13.4648 16.5474 13.3991 16.7217C13.3335 16.896 13.2337 17.0554 13.1057 17.1907C12.9776 17.326 12.8239 17.4343 12.6535 17.5095C12.4831 17.5846 12.2994 17.625 12.1132 17.6282Z"
      fill="currentColor"
    />
  ),
});
