/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplaySignalDetectionToggle_DisplayFragmentDoc } from './DisplaySignalDetection.graphql';
import { UsePowerToggle_DisplayFragmentDoc } from './usePowerToggle.graphql';
export type PowerSettingsSection_DisplayFragment = { __typename: 'Display', id: string, deviceSdk?: { __typename: 'DeviceSdk', sdk: string, version: string } | null, power?: { __typename: 'PowerState', reportedAt: any, desired?: Types.Power | null, reported: Types.Power } | null, signalDetection?: { __typename: 'SignalDetectionState', desired?: boolean | null, reported: boolean } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null };

export const PowerSettingsSection_DisplayFragmentDoc = gql`
    fragment PowerSettingsSection_display on Display {
  id
  deviceSdk {
    sdk
    version
  }
  power {
    reportedAt
    desired
  }
  ...DisplaySignalDetectionToggle_display
  ...usePowerToggle_display
}
    ${DisplaySignalDetectionToggle_DisplayFragmentDoc}
${UsePowerToggle_DisplayFragmentDoc}`;