/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CreatedAtCell_PlaylistFragment = { __typename: 'Playlist', id: string, createdAt: any };

export const CreatedAtCell_PlaylistFragmentDoc = gql`
    fragment CreatedAtCell_playlist on Playlist {
  id
  createdAt
}
    `;