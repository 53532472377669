/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type DescriptionCell_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, description?: string | null };

export const DescriptionCell_PowerScheduleFragmentDoc = gql`
    fragment DescriptionCell_powerSchedule on PowerSchedule {
  id
  description
}
    `;