import { EmptyView, EmptyViewExternalLink } from '~components/EmptyView';
import { EmptyAppsIllustration } from '~components/ui/illustrations/EmptyAppsIllustration';
import { useFeatureFlag } from '~utils/features';

function AppsEmptyState() {
  const { isEnabled: isMarketPlaceLinkEnabled } = useFeatureFlag('appDirectMarketplaceLink');

  return (
    <EmptyView
      icon={<EmptyAppsIllustration />}
      title="You don’t have any apps yet"
      description="To start using apps on your displays, contact your sales executive. Any app you buy will be available for installation on your displays."
    >
      {isMarketPlaceLinkEnabled && (
        <EmptyViewExternalLink
          label="Visit Prostore"
          href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
        />
      )}
    </EmptyView>
  );
}

export default AppsEmptyState;
