import { ComponentType, createRef, RefObject, useEffect, useRef } from 'react';
import { RouteObject, useLocation, useNavigate } from 'react-router-dom';
import { IconProps } from '~components/ui/icons';

export type TabDefinition = RouteObject &
  Required<Pick<RouteObject, 'path'>> & {
    label: string;
    icon?: ComponentType<IconProps>;
    header?: string;
    color?: string;
    borderColor?: string;
    contentRef?: RefObject<HTMLDivElement>;
  };

export type Tab = TabDefinition & {
  ref: RefObject<HTMLAnchorElement>;
  toLink: string;
  isActive: boolean;
};

interface UseTabsOptions {
  animateVertical?: boolean;
  scrollOffset?: number;
}

export function useTabs(
  tabDefinitions: TabDefinition[],
  { animateVertical = false, scrollOffset = 0 }: UseTabsOptions = {},
) {
  const navigate = useNavigate();
  const location = useLocation();

  const fullPath = `${location.pathname}${location.hash}`;
  const isPathActive = (path: string) => fullPath.includes(`/${path}`);

  const tabRefs = useRef<
    Array<{ tabRef: RefObject<HTMLAnchorElement>; contentRef: RefObject<HTMLHeadingElement> }>
  >(tabDefinitions.map(() => ({ tabRef: createRef(), contentRef: createRef() })));

  const tabs = tabDefinitions.map<Tab>((tab, index) => {
    return {
      ...tab,
      ref: tabRefs.current[index].tabRef,
      contentRef: tabRefs.current[index].contentRef,
      isActive: isPathActive(tab.path),
      toLink: tab.path,
    };
  });

  const activeTab = tabs.find((tab) => tab.isActive);

  useEffect(() => {
    if (!tabDefinitions.some(({ path }) => isPathActive(path))) {
      navigate(`./${tabDefinitions[0].path}`, { replace: true });
    }
    // eslint-disable-next-line -- we only want this effect to run once
  }, [navigate, tabDefinitions]);

  useEffect(() => {
    if (activeTab?.path === undefined || !animateVertical || !isPathActive(activeTab.path)) {
      return;
    }

    const activeTabTopFromViewport =
      activeTab?.contentRef?.current?.getBoundingClientRect().top ?? 0;
    const activeTabTop = (window?.scrollY ?? 0) + activeTabTopFromViewport - scrollOffset - 16;
    const targetTop = activeTab === tabs[0] ? 0 : activeTabTop;

    window.scrollTo({
      behavior: 'smooth',
      top: targetTop,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding `tabs` or the entire `activeTab` causes unwanted scrolling
  }, [activeTab?.path, animateVertical, scrollOffset]);

  return { tabs, activeTab };
}
