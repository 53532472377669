import { gql } from '@apollo/client';
import { AlertTitle, Button } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { WarningAlert } from '~components/ui/Alert';
import { useDisplayPresence } from '../../useDisplayPresence';
import { usePowerSchedules } from '../../usePowerSchedules';
import { formatDownloadProgress } from '../../utils';
import { AlertPowerScheduleSync_DisplayFragment } from './__generated__/AlertPowerScheduleSync.graphql';

interface Props {
  display: AlertPowerScheduleSync_DisplayFragment;
}

export function AlertPowerScheduleSync({ display }: Props) {
  const { getPresence } = useDisplayPresence();
  const { isConnected } = useMemo(() => getPresence(display), [display, getPresence]);
  const { getPowerScheduleState, syncPowerSchedule } = usePowerSchedules();
  const powerScheduleState = useMemo(
    () => getPowerScheduleState(display),
    [display, getPowerScheduleState],
  );

  const handleSyncPowerSchedule = useCallback(async () => {
    await syncPowerSchedule(display);
  }, [display, syncPowerSchedule]);

  if (powerScheduleState.kind === 'power_schedule_out_of_sync')
    return (
      <WarningAlert
        marginBottom="4"
        actionButton={
          <Button
            variant="outline"
            size="sm"
            colorScheme="orange"
            color="orange.700"
            onClick={handleSyncPowerSchedule}
          >
            Sync {isConnected ? 'now' : 'when connected'}
          </Button>
        }
      >
        <AlertTitle>This schedule is out of sync.</AlertTitle>
      </WarningAlert>
    );

  if (powerScheduleState.kind === 'power_schedule_sync_failed')
    return (
      <WarningAlert
        marginBottom="4"
        actionButton={
          <Button
            variant="outline"
            size="sm"
            colorScheme="orange"
            color="orange.700"
            onClick={handleSyncPowerSchedule}
          >
            Try again{isConnected ? '' : ' when connected'}
          </Button>
        }
      >
        <AlertTitle>Sync failed.</AlertTitle>
      </WarningAlert>
    );

  if (powerScheduleState.kind === 'power_schedule_syncing')
    return (
      <WarningAlert marginBottom="4">
        <AlertTitle>
          Syncing power schedule&hellip;
          {powerScheduleState.progress
            ? formatDownloadProgress(powerScheduleState.progress)
            : undefined}
        </AlertTitle>
      </WarningAlert>
    );

  return null;
}

AlertPowerScheduleSync.graphql = {
  fragments: {
    AlertPowerScheduleSync_display: gql`
      fragment AlertPowerScheduleSync_display on Display {
        id
        ...UsePowerSchedules_display
      }
    `,
  },
};
