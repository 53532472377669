import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { Power, SettingWarningSeverity } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { useRecommendedSettings } from '../useRecommendedSettings';
import { mapPowerEnumToLabel } from '../utils';
import { useBulkUpdatePowerMutation } from './__generated__/BulkUpdatePower.graphql';
import { BulkActionComponent } from './BulkActionModal';

interface FormData {
  displayIds: string[];
  power: Power;
}

export const BulkUpdatePower: BulkActionComponent = ({ displays, onCancel, onBack, onSuccess }) => {
  const [bulkUpdatePower] = useBulkUpdatePowerMutation();
  const defaultPower = useMemo(
    () =>
      displays.filter(({ power }) => power && power.reported === Power.Standby) >
      displays.filter(({ power }) => power && power.reported === Power.On)
        ? Power.On
        : Power.Standby,
    [displays],
  );
  const { getSettingsState } = useRecommendedSettings();
  const analytics = useAnalyticsReporter();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      power: defaultPower,
      displayIds: displays.map((d) => d.id),
    },
  });

  const displaysWithHighSeverityWarnings = useMemo(() => {
    return displays.filter((display) => {
      const settings = getSettingsState(display);

      return (
        settings.kind === 'not_recommended' &&
        settings.highestSeverity === SettingWarningSeverity.High
      );
    });
  }, [displays, getSettingsState]);

  const powerOptions = useMemo(() => [Power.On, Power.Standby], []);

  const toast = useToast();

  const powerApplyAction = useDestructiveAction<FormData>({
    title: () => <>Warning</>,
    message: () => (
      <>
        {displaysWithHighSeverityWarnings.length > 1 ? (
          <>
            Please note that {displaysWithHighSeverityWarnings.length} displays in your selection
            are currently not using the recommended settings.{' '}
            <chakra.span fontSize="md" fontWeight="semibold">
              Switching these displays to stand-by will make them lose connection with the WAVE
              platform.
            </chakra.span>
          </>
        ) : (
          <>
            Please note that a display in your selection is currently not using the recommended
            settings.{' '}
            <chakra.span fontSize="md" fontWeight="semibold">
              Switching this display to stand-by will make it lose connection with the WAVE
              platform.
            </chakra.span>
          </>
        )}
      </>
    ),
    onConfirm: async (data) => {
      await handleBulkUpdatePower(data);
    },
    confirmLabel: 'Apply anyway',
    variant: 'warning',
    notice: undefined,
  });

  const handleBulkUpdatePower = useCallback(
    async ({ displayIds, power }: FormData) => {
      try {
        await bulkUpdatePower({
          variables: {
            input: {
              displayIds,
              power,
            },
          },
        });

        if (displayIds.length === 1) {
          analytics.track('scheduleUpdate');
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'power',
            displayCount: displayIds.length,
          });
        }
        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot update power in bulk',
          description: fromError(err, 'bulkUpdatePower'),
        });
      }
    },
    [analytics, bulkUpdatePower, onSuccess, toast],
  );

  const onSubmit = useCallback(
    async (formData: FormData) => {
      if (formData.power === Power.Standby && displaysWithHighSeverityWarnings.length > 0) {
        await powerApplyAction.askConfirmation(formData);
      } else {
        await handleBulkUpdatePower(formData);
      }
    },
    [powerApplyAction, handleBulkUpdatePower, displaysWithHighSeverityWarnings],
  );

  return (
    <>
      <ModalContent>
        <ModalHeader>On/StandBy</ModalHeader>
        <ModalCloseButton onClick={onCancel} isDisabled={isSubmitting} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Box display="flex">
              <FormLabel>Power</FormLabel>
              <Box marginLeft="14" flex="1">
                <Controller
                  name="power"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      tabIndex={2}
                      defaultValue="now"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      <Stack direction="row" spacing="6">
                        {powerOptions.map((power) => {
                          return (
                            <Radio key={power} size="md" value={power}>
                              {mapPowerEnumToLabel(power)}
                            </Radio>
                          );
                        })}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Stack flex="1" direction="row" alignItems="center">
              <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={onCancel}
                  isDisabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  marginLeft="3"
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Apply
                </Button>
              </Box>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
      {powerApplyAction.confirmationNode}
    </>
  );
};

BulkUpdatePower.graphql = {
  fragments: {
    BulkUpdatePower_display: gql`
      fragment BulkUpdatePower_display on Display {
        id
        power {
          desired
          reported
        }
        ...UseRecommendedSettings_display
      }
    `,
  },
  mutations: {
    BulkUpdatePower: gql`
      mutation BulkUpdatePower($input: DisplayBulkUpdatePowerInput!) {
        displayBulkUpdatePower(input: $input) {
          displays {
            id
            power {
              desired
              reported
            }
          }
        }
      }
    `,
  },
};
