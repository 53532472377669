/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CustomerUsersTableUserFragmentDoc } from '../../../../../components/organization/CustomerUsersTable/__generated__/CustomerUsersTable.graphql';
import { CustomerSubscriptionsTableSubscriptionFragmentDoc } from '../../../../../components/organization/CustomerSubscriptionsTable/__generated__/CustomerSubscriptionsTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomerPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateCustomerPageQuery = { __typename: 'Query', organization: { __typename: 'Organization', users: Array<{ __typename: 'User', id: string, givenName: string, familyName: string, email: string, roles: Array<Types.Role> }>, waveSubscriptions?: Array<{ __typename: 'WaveSubscription', id: string, name: string, validUntil: any, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null }, customers: Array<{ __typename: 'PartialCustomer', name: string }> }> | null }, me: { __typename: 'CurrentUser', id: string } };

export type CreateCustomerMutationVariables = Types.Exact<{
  input: Types.CustomerCreateInput;
}>;


export type CreateCustomerMutation = { __typename: 'Mutation', customerCreate: { __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null } };


export const CreateCustomerPageDocument = gql`
    query CreateCustomerPage {
  organization {
    users {
      ...CustomerUsersTableUser
    }
    waveSubscriptions(isAssignable: true) {
      ...CustomerSubscriptionsTableSubscription
    }
  }
  me {
    id
  }
}
    ${CustomerUsersTableUserFragmentDoc}
${CustomerSubscriptionsTableSubscriptionFragmentDoc}`;

/**
 * __useCreateCustomerPageQuery__
 *
 * To run a query within a React component, call `useCreateCustomerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCustomerPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateCustomerPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreateCustomerPageQuery, CreateCustomerPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CreateCustomerPageQuery, CreateCustomerPageQueryVariables>(CreateCustomerPageDocument, options);
      }
export function useCreateCustomerPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreateCustomerPageQuery, CreateCustomerPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CreateCustomerPageQuery, CreateCustomerPageQueryVariables>(CreateCustomerPageDocument, options);
        }
export type CreateCustomerPageQueryHookResult = ReturnType<typeof useCreateCustomerPageQuery>;
export type CreateCustomerPageLazyQueryHookResult = ReturnType<typeof useCreateCustomerPageLazyQuery>;
export type CreateCustomerPageQueryResult = ApolloReactCommon.QueryResult<CreateCustomerPageQuery, CreateCustomerPageQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    id
    handle
    name
    avatarUrl
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;