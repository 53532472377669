import { createIcon } from '@chakra-ui/react';

export const ConnectivityIcon = createIcon({
  displayName: 'ConnectivityIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M11.9999 19.9998H12.0099M8.52992 16.1098C9.54512 15.3885 10.7596 15.001 12.0049 15.001C13.2502 15.001 14.4647 15.3885 15.4799 16.1098M4.99992 12.5498C6.97649 10.9035 9.46753 10.0019 12.0399 10.0019C14.6123 10.0019 17.1034 10.9035 19.0799 12.5498M1.41992 8.99979C4.34234 6.42376 8.10422 5.00244 11.9999 5.00244C15.8956 5.00244 19.6575 6.42376 22.5799 8.99979"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
