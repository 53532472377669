/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplaySiteSelect_DisplayFragmentDoc } from './DisplaySiteSelect.graphql';
import { DisplayInlineGroupsSelect_DisplayFragmentDoc } from './DisplayInlineGroupsSelect.graphql';
import { DisplaySiteSelect_CustomerFragmentDoc } from './DisplaySiteSelect.graphql';
import { DisplayInlineGroupsSelect_CustomerFragmentDoc } from './DisplayInlineGroupsSelect.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayGeneralSection_DisplayFragment = { __typename: 'Display', id: string, alias?: string | null, site?: { __typename: 'Site', id: string, name: string, address?: string | null } | null, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type DisplayGeneralSection_CustomerFragment = { __typename: 'Customer', id: string, sites: Array<{ __typename: 'Site', id: string, name: string, address?: string | null }>, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type UpdateAliasMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateAliasInput;
}>;


export type UpdateAliasMutation = { __typename: 'Mutation', displayUpdateAlias: { __typename: 'Display', id: string, alias?: string | null, serialNumber: string } };

export const DisplayGeneralSection_DisplayFragmentDoc = gql`
    fragment DisplayGeneralSection_display on Display {
  id
  alias
  ...DisplaySiteSelect_display
  ...DisplayInlineGroupsSelect_display
}
    ${DisplaySiteSelect_DisplayFragmentDoc}
${DisplayInlineGroupsSelect_DisplayFragmentDoc}`;
export const DisplayGeneralSection_CustomerFragmentDoc = gql`
    fragment DisplayGeneralSection_customer on Customer {
  id
  ...DisplaySiteSelect_customer
  ...DisplayInlineGroupsSelect_customer
}
    ${DisplaySiteSelect_CustomerFragmentDoc}
${DisplayInlineGroupsSelect_CustomerFragmentDoc}`;
export const UpdateAliasDocument = gql`
    mutation UpdateAlias($input: DisplayUpdateAliasInput!) {
  displayUpdateAlias(input: $input) {
    id
    alias
    serialNumber
  }
}
    `;
export type UpdateAliasMutationFn = ApolloReactCommon.MutationFunction<UpdateAliasMutation, UpdateAliasMutationVariables>;

/**
 * __useUpdateAliasMutation__
 *
 * To run a mutation, you first call `useUpdateAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAliasMutation, { data, loading, error }] = useUpdateAliasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAliasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAliasMutation, UpdateAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAliasMutation, UpdateAliasMutationVariables>(UpdateAliasDocument, options);
      }
export type UpdateAliasMutationHookResult = ReturnType<typeof useUpdateAliasMutation>;
export type UpdateAliasMutationResult = ApolloReactCommon.MutationResult<UpdateAliasMutation>;
export type UpdateAliasMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAliasMutation, UpdateAliasMutationVariables>;