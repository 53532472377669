import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function TermsOfUsePage() {
  return (
    <PolicyPage title="Terms of use" heading="Wave terms of use">
      <p>
        THESE WAVE TERMS OF SERVICE (&quot;TERMS&quot;) SET OUT THE TERMS PURSUANT TO WHICH TP
        VISION EUROPE B.V. (&quot;WE&quot; or &quot;US&quot;) IS WILLING TO PROVIDE YOU, OR THE
        LEGAL ENTITY REPRESENTED BY YOU, (&quot;YOU&quot; or &quot;YOUR&quot;) WITH ACCESS TO WAVE.
      </p>
      <p>
        WE ARE TP VISION EUROPE B.V., REGISTERED AT PRINS BERNHARDPLEIN 200 (1097 JB) AMSTERDAM, THE
        NETHERLANDS.
      </p>
      <p>
        WE STRONGLY SUGGEST THAT YOU DOWNLOAD OR SAVE A LOCAL COPY OF THESE TERMS FOR FUTURE
        REFERENCE. YOU CAN ACCESS THE TERMS VIA{' '}
        <a href="https://www.ppds.com/downloads">https://www.ppds.com/downloads</a>.
      </p>
      <h2>1. Basic Terms</h2>
      <p>
        These Terms govern your access to and use of WAVE (the &quot;Service&quot;) online or
        through an application (the &quot;App&quot;) available on mobile devices.
      </p>
      <p>
        You may not use the Service if: (a) you are not of legal age to form a binding contract with
        us, or (b) you are a person who is barred or otherwise legally prohibited from accessing the
        Service under the laws of the country in which you are resident. Notwithstanding the
        foregoing, you must be at least 16 years of age to assume the responsibilities under these
        Terms. If you are under 16 years of age, you may use the Service ONLY if one of your parents
        or legal guardians consents. If you use the Service, we will assume that you are 16 years of
        age or older or, if you are under 16 years of age, that your parent or legal guardian has
        consented to your use of the Service.
      </p>
      <h2>2. Copyright, Trademarks and Other rights</h2>
      <p>
        All copyrights, trademarks and other rights, title and interest in and on the Service are
        and shall remain the property of TP Vision. Nothing in the Terms gives you a right to use
        any of the trademarks, logos, domain names or other distinctive brand features of the TP
        Vision. Any feedback, comments or suggestions you may provide to us are provided on an
        entirely voluntary basis, and we shall be free to use such feedback, comments or suggestions
        as we see fit and without any obligation to you.
      </p>
      <h2>3. Usage of Service, Term of Agreement</h2>
      <p>
        You agree not to exploit the Service in any unauthorised way, including, but not limited to,
        by trespass, burdening network or server capacity or using the Service for purposes other
        than those authorized. Copyright and other intellectual property laws protect the Service,
        and you agree to abide by and maintain all notices, licence information and restrictions
        contained therein. Unless expressly permitted herein or otherwise permitted in a separate
        agreement with us, you may not modify, publish, network, rent, lease, loan, transmit, sell,
        participate in the transfer or sale of, reproduce, create derivative works based on,
        redistribute, perform, display or in any way exploit the Service in whole or in part. You
        may not decompile, reverse engineer, disassemble, change, replace or attempt to root any
        software or security components part of the Service unless specifically permitted by
        applicable mandatory law. Use of the Service to violate, tamper with or circumvent the
        security of any computer network, software, passwords, encryption codes or technological
        protection measures, or to otherwise engage in any kind of illegal activity, or to enable
        others to do so, is expressly prohibited. The Agreement is effective for the duration of
        your access to and use of the Service. You may terminate it at any time by stopping your
        access to and use of the Service. We also may terminate or suspend the Agreement, at any
        time, without notice, and accordingly deny you access to the Service, if in our sole
        discretion you fail to comply with any of these Terms, or your continued access and use is
        harmful to the interests of any third party or is in violation of any applicable law.
      </p>
      <h2>4. No Warranty</h2>
      <p>
        WE, AND OUR AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS AND
        LICENSEES (COLLECTIVELY, TPV FOR THE PURPOSES OF THESE SECTIONS 4 AND 5) DO NOT REPRESENT,
        WARRANT OR PROMISE THAT THE SERVICE, OR ANY OTHER INFORMATION OR MATERIALS THAT YOU RECEIVE
        OR ARE GIVEN ACCESS TO VIA THE WEBSITE OR APP WILL BE ACCURATE, RELIABLE, TIMELY, SECURE,
        ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED. THE SERVICE IS PROVIDED
        ON AN AS-IS AND AS-AVAILABLE BASIS, AND IS SUBJECT TO CHANGE WITHOUT NOTICE. TPV CANNOT
        ENSURE THAT ANY SERVICE OR APP (INCLUDING FILES, INFORMATION OR OTHER DATA) THAT YOU ACCESS
        OR DOWNLOAD WILL BE FREE OF VIRUSES, CONTAMINATION OR DESTRUCTIVE FEATURES. TPV EXPLICITLY
        DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY,
        NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
      </p>
      <h2>5. Limitation of Liability</h2>
      <p>
        TPV DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES
        IN CONNECTION WITH OR RELATED TO YOUR ACCESS TO OR USE OF THE SERVICE OR APP. YOU ASSUME
        TOTAL RESPONSIBILITY FOR AND ALL RISKS IN RELATION TO YOUR ACCESS THERETO OR USE THEREOF,
        INCLUDING, BUT NOT LIMITED TO, ANY INFORMATION OBTAINED THEREON. YOUR SOLE REMEDY AGAINST
        TPV FOR ANY DAMAGE, COSTS OR DISSATISFACTION CAUSED BY ACCESS TO OR USE OF THE SERVICE, APP
        OR WEBSITES, IS TO STOP USING THE SERVICE OR THE RESPECTIVE APPS OR WEBSITE.
      </p>
      <h2>6. Indemnification</h2>
      <p>
        You agree to indemnify and defend us and hold us harmless against all claims, demands,
        causes of action, losses, expenses, damages and costs, including any reasonable legal fees,
        resulting or arising from or relating to your access to or use of the Service, your breach
        of these Terms or your infringement or violation of any rights of another party.
      </p>
      <h2>7. Limitation on Access and Export Control</h2>
      <p>
        We reserve the right (but will not have any obligation) to refuse access to the Service. We
        also reserve the right to access, read, preserve and disclose any usage information as we
        reasonably believe is necessary to (i) comply with any applicable law, regulation, legal
        process or governmental request, (ii) enforce the Terms, including investigation of
        potential violations hereof, (iii) detect, prevent or otherwise address fraud, security or
        technical issues, (iv) respond to user support requests or (v) protect the rights, property
        or safety of us, our users and the public. We may, at our sole discretion, add or remove
        access to the Service at any time. Furthermore, we reserve the right, at any time and at our
        sole discretion, to limit the availability and accessibility of the Service to any person,
        geographic area or jurisdiction we choose. The Service is subject to the export control laws
        of, among others, the Netherlands and the United States, and may not be accessed by (i) a
        national or resident of Cuba, Libya, North Korea, Iran, Syria or any other country to which
        the U.S. has embargoed goods; or (ii) anyone on the U.S. Treasury Department&apos;s list of
        Specially Designated Nationals or the U.S. Commerce Department&apos;s Table of Deny Orders.
        By using the Service, you represent and warrant that you are not located in, under the
        control of, or a national or resident of any such country or on any such list. Use,
        duplication or disclosure by the U.S. Government is subject to restrictions as set forth in
        these Terms and any amendments hereto, as applicable.
      </p>
      <h2>8. Changes to the Service and/or Terms</h2>
      <p>
        We may, at our discretion, change, update or upgrade features or the Service. If such
        changes have an impact on these Terms, you will receive a notice about the updating of these
        Terms. You understand and agree that if you use the Service after the date on which the
        Terms change, we will treat your use as acceptance of the updated Terms.
      </p>
      <h2>9. Account protection</h2>
      <p>
        You are responsible for safeguarding the username and password that you have selected for
        your account. We encourage you to use strong passwords (passwords that use a combination of
        upper and lower case letters, numbers and symbols) with your account. We cannot and will not
        be liable for any loss or damage arising from your failure to comply with the above
        requirements.
      </p>
      <h2>10. Privacy and cookies</h2>
      <p>
        We strongly believe in protecting your privacy and informing you about the way we collect,
        use and store your personal data. Any personal data that you provide to us, or that is
        collected by us, is subject to our Privacy Statement. Our Privacy Statement is accessible at{' '}
        <a href="https://www.ppds.com/downloads">https://www.ppds.com/downloads</a>. The Service
        makes use of &quot;cookies&quot; to collect and store some of your personal data. A
        &quot;cookie&quot; is a small text file stored on your device, which allows us to recognise
        your device the next time you visit. Our cookie use is described in our Cookie Statement,
        which can be accessed at{' '}
        <a href="https://www.ppds.com/downloads">https://www.ppds.com/downloads</a>.
      </p>
      <h2>11. Governing Law</h2>
      <p>
        Your agreement with us shall be governed by and is construed under and in accordance with
        the laws of the Netherlands, excluding its conflict of law provisions. The court of
        Amsterdam, the Netherlands, shall be the only competent court to settle any disputes between
        you and us arising out of or relating to the Agreement, unless applicable mandatory consumer
        law requires otherwise.
      </p>
      <h2>12. Miscellaneous</h2>
      <p>
        Our failure to enforce any right or provision of these Terms will not be deemed a waiver of
        such right or provision. In the event that any provision of these Terms is held to be
        invalid or unenforceable, the remaining provisions of these Terms will remain in full force
        and effect. No addition to or removal or modification of any of the provisions of these
        Terms will be binding upon us, unless made in writing and signed by our authorised
        representative. These Terms and our Privacy Statement are the entire agreement between you
        and us regarding your use of the Service. You agree that, regardless of any statute or law
        to the contrary, any claim or cause of action arising from or relating to use of the
        Service, or this Agreement, must be filed within 13 (thirteen) calendar months after such
        claim or cause of action arose, or will be considered expired. The &quot;No Warranty&quot;
        and &quot;Limitation of Liability&quot; provisions of this Agreement are for the benefit of
        us as defined therein, and each of these individuals or entities shall have the right to
        assert and enforce these provisions directly against you on its own behalf.
      </p>
      <h2>13. About Us</h2>
      <p>The Service is provided to you by:</p>
      <p>
        TP Vision Europe B.V.
        <br />
        Prins Bernhardplein 200
        <br />
        1097 JB Amsterdam
        <br />
        The Netherlands
        <br />
        Trade Register Number 53045394
        <br />
        VAT Number NL850721854B01
      </p>
      <p>
        If you have any comments or questions regarding the Service or this Agreement, or wish to
        report any violation of these Terms, please contact us at{' '}
        <a href="mailto:ppds@tpv-tech.com">ppds@tpv-tech.com</a>.
      </p>
    </PolicyPage>
  );
}
