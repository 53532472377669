/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplayFirmwareUpdate_DisplayFragmentDoc } from './DisplayFirmwareUpdate.graphql';
export type DisplayInfoSection_DisplayFragment = { __typename: 'Display', id: string, commercialTypeNumber: string, serialNumber: string, agentVersion?: string | null, platform: { __typename: 'DisplayPlatform', name: string, version?: string | null, type: string }, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string>, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateRejected', rejectionCode: string, rejectedAt: any, id: string, targetVersion: any, plannedAt: any, createdAt: any } | null } } | null };

export const DisplayInfoSection_DisplayFragmentDoc = gql`
    fragment DisplayInfoSection_display on Display {
  id
  commercialTypeNumber
  serialNumber
  platform {
    name
    version
    type
  }
  firmware {
    android {
      version
    }
  }
  agentVersion
  ...DisplayFirmwareUpdate_display
}
    ${DisplayFirmwareUpdate_DisplayFragmentDoc}`;