import { Button, Text } from '@chakra-ui/react';
import { Link } from '~components/ui/Link';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';

export function PowerScheduleNotFoundPage() {
  return (
    <Page title="404" pageName="power_schedule_404" analyticsDisableTracking={true}>
      <PageContent maxWidth="300px" textAlign="center">
        <Text fontWeight="semibold" color="gray.600">
          This power schedule does not exist, or you don&apos;t have permissions to view it.
        </Text>

        <Text marginTop="8" fontSize="sm">
          Try to select another powerSchedule on the{' '}
          <Button as={Link} variant="link" size="sm" colorScheme="blue" to="../">
            power schedules overview page.
          </Button>
        </Text>
      </PageContent>
    </Page>
  );
}
