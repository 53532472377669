import { gql } from '@apollo/client';
import { HStack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import PowerBadge from '~components/displays/PowerBadge';
import PresenceBadge from '~components/displays/PresenceBadge';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';

export function StatusCell({
  value,
}: PropsWithChildren<CellProps<DisplayTable_DisplayFragment, DisplayTable_DisplayFragment>>) {
  return (
    <HStack>
      <PresenceBadge display={value} variant="small" />
      <PowerBadge display={value} variant="small" />
    </HStack>
  );
}

StatusCell.graphql = {
  fragments: {
    StatusCell_display: gql`
      fragment StatusCell_display on Display {
        id
        ...PresenceBadge_display
      }
    `,
  },
};
