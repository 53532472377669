import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { formatBytes } from '~utils/file';
import { PlaylistTable_PlaylistFragment } from '../__generated__/PlaylistTable.graphql';

export function SizeCell({
  value,
}: PropsWithChildren<CellProps<PlaylistTable_PlaylistFragment, number>>) {
  return <Box>{formatBytes(value)}</Box>;
}

SizeCell.graphql = {
  fragments: {
    SizeCell_playlist: gql`
      fragment SizeCell_playlist on Playlist {
        id
        size
      }
    `,
  },
};
