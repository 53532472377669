import { gql } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { useAuth } from '~auth/useAuth';
import { useDisplayDetailSummaryTab } from '~components/displays/DisplayDetail/DisplayDetailView';
import { ContentCard } from '~components/displays/DisplayDetail/summary/ContentCard';
import { NowPlayingCard } from '~components/displays/DisplayDetail/summary/NowPlayingCard';
import { StatusCard } from '~components/displays/DisplayDetail/summary/StatusCard';
import { PowerScheduleCard } from './PowerScheduleCard';

export function DisplayDetailSummary() {
  const { customer, display, refetchData } = useDisplayDetailSummaryTab();
  const { verifyUserPermissions } = useAuth();

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Box width={{ base: 'full', md: '40%' }} marginRight={{ base: 0, md: 10 }}>
        <NowPlayingCard display={display} />
      </Box>
      <Box width={{ base: 'full', md: '60%' }}>
        <StatusCard display={display} />

        <PowerScheduleCard
          display={display}
          customer={customer}
          isDisabled={isDisplaySettingsUpdateDisabled}
        />

        <ContentCard display={display} customer={customer} refetchData={refetchData} />
      </Box>
    </Flex>
  );
}

DisplayDetailSummary.graphql = {
  fragments: {
    PowerScheduleSection_customer: gql`
      fragment DisplayDetailSummary_customer on Customer {
        id
        ...PowerScheduleCard_customer
        ...ContentCard_customer
      }
    `,
    DisplayDetailSummary_display: gql`
      fragment DisplayDetailSummary_display on Display {
        id
        ...StatusCard_display
        ...PowerScheduleCard_display
        ...NowPlayingCard_display
        ...ContentCard_display
      }
    `,
  },
};
