import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function ServiceSchedulePage() {
  return (
    <PolicyPage title="Service Schedule" heading="PPDS Wave Service Schedule">
      <p>
        This Service Schedule is attached to and incorporated into the Agreement between
        MMD-Monitors & Displays Nederland B.V. (hereinafter referred to as &ldquo;PPDS&rdquo;) and
        the contracting party identified on the Order Form (hereinafter referred to as
        &ldquo;Customer&rdquo;).
      </p>
      <p>
        The Parties hereby agree to the terms and conditions of this Service Schedule, including any
        specific services terms, product details and any applicable license and/or subscription
        terms. This Service Schedule shall become binding on the Parties and is incorporated into
        the Agreement upon execution of an Order Form.
      </p>
      <h2>1. DEFINITIONS</h2>
      <p>
        Unless otherwise defined, the capitalized terms shall have the same meaning as such terms in
        the Agreement.
      </p>
      <p>&ldquo;Wave Device&rdquo; means a display or a supporting device claimed by Wave.</p>
      <p>
        &ldquo;Available Seats&rdquo; means the number of devices available to be claimed according
        to the Order Form and billed annually.
      </p>
      <p>
        &ldquo;Wave Subscription Start Date&rdquo; means the date when a device is first purchased.
        &ldquo;Display Lifetime&rdquo; means the device lifetime which is defined as 5 years.
      </p>

      <h2>2. WAVE CONTROLLER SUBSCRIPTIONS</h2>
      <p>
        To accommodate different integration needs, Wave consists of different free and paid
        subscriptions. Accessing these subscriptions requires a Wave account.
      </p>
      <h3>2.1 Lite Subscription (Freemium)</h3>
      <p>
        The Lite subscription can be activated for free for the lifetime of the display. It includes
        everything that is needed for easy installation and maintenance including:
      </p>
      <ul>
        <li>Unlimited display claiming</li>
        <li>Automated setup guidance</li>
        <li> Philips SoC Displays</li>
        <li>Non SoC with Philips OPS Android ▪ Display overview</li>
        <li>Firmware updates</li>
        <li>Basic settings</li>
        <li>Reboot Scheduling</li>
        <li>One Playlist per Customer</li>
        <li>Screenshots</li>
        <li>Pro Store application management</li>
      </ul>
      <h3>2.2 Essential Subscription (Paid)</h3>
      <p>
        Essential subscriptions include more advanced services for professional fleet management. It
        includes all the features available in the Lite subscription. These include:
      </p>
      <ul>
        <li>Feature Updates</li>
        <li>Bulk actions</li>
        <li>Power scheduling</li>
        <li>Playlists</li>
        <li>Personal API keys</li>
        <li>Videowall Support (CRD-50)*</li>
        <li>Clone profile for easy install*</li>
        <li>Scoped customer access*</li>
        <li>Usage Dashboard*</li>
      </ul>
      <p>*Available by H2 2023</p>
      <p>
        Essential also includes access to new features, settings and display information via regular
        deployments. Essential subscriptions are billed annually in accordance with the Order Form
        and timing starts at display claiming or in-app upgrade.
      </p>
      <h3>2.3 Essential Add-On Subscriptions (Paid)</h3>
      <p>
        Add-on subscriptions are only available in combination with the Essential paid subscription.
        Custom app management may require additional training described in the Order Form
      </p>
      <h3>2.4 Custom Application Management Add-on</h3>
      <p>
        Custom application management requires the purchase of Essential subscription for all
        displays that require the installation of custom apps providing
      </p>
      <ul>
        <li>Custom app installation*</li>
        <li>Custom app management*</li>
        <li>Custom app dashboard*</li>
        <li>Developer role support*</li>
        <li>5 custom applications*</li>
      </ul>
      <p>*Custom applications are available by H2 2023.</p>
      <h3>2.5 Telemetry Add-On</h3>
      <p>
        The Telemetry add-on also requires the Essential subscription and will be available by end
        2023.
      </p>

      <h2>3. WAVE CREATOR SUBSCRIPTIONS</h2>
      <h3>3.1 Digital Signage</h3>
      <p>
        Digital Signage uses the Wave Creator platform and is optimized for basic content
        management.
      </p>
      <ul>
        <li>Integrated with Wave Controller</li>
        <li>Content Scheduling</li>
        <li>Default, day, week and calendar</li>
        <li>
          Widgets text, clock, image, video, YouTube, playlist, webpage, QR-code, playlist, API
          widgets
        </li>
        <li>Text editor and Templates</li>
      </ul>

      <h2>4. SUBSCRIPTION LIMITATIONS</h2>
      <p>
        Wave Controller is a dealer centric application and as a result an Organization can have
        multiple customer accounts created by the Account Owner. Within an Organization different
        subscription levels are allowed. However, each customer account shall apply the same
        subscription level. The Account Owner is responsible to claim or un-claim displays within
        their Organization.
      </p>

      <h2>5. RESULTS OF NON-PAYMENT</h2>
      <p>
        Non-payment of subscriptions will result in displays reverting to the Lite or Free version.
        Devices will not be unclaimed due to non-payment.
      </p>

      <h2>6. DOCUMENTATION</h2>
      <p>
        Documentation about getting started with Wave can be found at
        <br />
        <a href="https://docs.wave.ppds.com/guide/getting-started">
          https://docs.wave.ppds.com/guide/getting-started
        </a>
      </p>

      <h2>7. SUPPORT</h2>
      <p>
        For app/platform support and tech support contact our support team at
        <br />
        <a href="https://tpvcrmplus.force.com/TPVB2B/s/">https://tpvcrmplus.force.com/TPVB2B/s/</a>
      </p>
    </PolicyPage>
  );
}
