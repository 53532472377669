/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PresenceBadge_DisplayFragmentDoc } from '../../../__generated__/PresenceBadge.graphql';
export type StatusCell_DisplayFragment = { __typename: 'Display', id: string, presence?: { __typename: 'DisplayPresence', connected: boolean } | null };

export const StatusCell_DisplayFragmentDoc = gql`
    fragment StatusCell_display on Display {
  id
  ...PresenceBadge_display
}
    ${PresenceBadge_DisplayFragmentDoc}`;