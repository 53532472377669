import { FormControl } from '@chakra-ui/form-control';
import {
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission } from '@tp-vision/roles-permissions';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAuth } from '~auth/useAuth';
import { DisplayInlineGroupsSelect } from '~components/displays/DisplayDetail/general/DisplayInlineGroupsSelect';
import { DisplaySiteSelect } from '~components/displays/DisplayDetail/general/DisplaySiteSelect';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { MaybePromise } from '~utils/types';
import {
  DisplayGeneralSection_CustomerFragment,
  DisplayGeneralSection_DisplayFragment,
  useUpdateAliasMutation,
} from './__generated__/DisplayGeneralSection.graphql';

const schema = z.object({
  alias: z.string().transform((v) => v.trim()),
});

type FormValues = z.TypeOf<typeof schema>;

interface Props {
  customer: DisplayGeneralSection_CustomerFragment;
  display: DisplayGeneralSection_DisplayFragment;
  refetchData: () => MaybePromise<void>;
}

export function DisplayGeneralSection({ customer, display, refetchData }: Props) {
  const [updateAlias] = useUpdateAliasMutation();
  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const { verifyUserPermissions } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    defaultValues: {
      alias: display.alias ?? '',
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const isGroupUpdateDisabled = !verifyUserPermissions([
    Permission.DisplayGroupAssign,
    Permission.DisplayGroupUnassign,
  ]);
  const isSiteUpdateDisabled = !verifyUserPermissions([Permission.SiteUpdate]);
  const isAliasUpdateDisabled = !verifyUserPermissions([Permission.DisplayAliasUpdate]);

  useEffect(() => {
    reset({
      alias: display.alias ?? '',
    });
  }, [display, reset]);

  const performSubmit = async ({ alias }: FormValues) => {
    try {
      await updateAlias({
        variables: {
          input: {
            displayId: display.id,
            alias: isEmpty(alias) ? null : alias,
          },
        },
      });
      analytics.track('displaySettingUpdate', { group: 'management', changeItem: 'alias' });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot rename display',
        description: fromError(err, 'UpdateAlias'),
      });
    }
  };

  const handleOnBlur = () => {
    if (!isValid) return;
    handleSubmit(performSubmit)();
  };

  return (
    <GridItem
      colSpan={{
        base: 8,
        sm: 6,
        lg: 8,
        xl: 5,
      }}
    >
      <form onSubmit={handleSubmit(performSubmit)}>
        <FormControl mb="10">
          <FormLabel>Name</FormLabel>
          <InputGroup>
            <Input
              {...register('alias')}
              isDisabled={isAliasUpdateDisabled || isSubmitting}
              onBlur={handleOnBlur}
            />
            <InputRightElement>{isSubmitting ? <Spinner size="sm" /> : null}</InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.alias?.message}</FormErrorMessage>
        </FormControl>
        <input type="submit" hidden />
      </form>
      <FormControl mb="10">
        <FormLabel>Site</FormLabel>
        <DisplaySiteSelect
          customer={customer}
          display={display}
          isDisabled={isSiteUpdateDisabled}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Groups</FormLabel>
        <DisplayInlineGroupsSelect
          customer={customer}
          display={display}
          refetchData={refetchData}
          isDisabled={isGroupUpdateDisabled}
        />
      </FormControl>
    </GridItem>
  );
}

DisplayGeneralSection.graphql = {
  fragments: {
    DisplayGeneralSection_display: gql`
      fragment DisplayGeneralSection_display on Display {
        id
        alias
        ...DisplaySiteSelect_display
        ...DisplayInlineGroupsSelect_display
      }
    `,
    DisplayGeneralSection_customer: gql`
      fragment DisplayGeneralSection_customer on Customer {
        id
        ...DisplaySiteSelect_customer
        ...DisplayInlineGroupsSelect_customer
      }
    `,
  },
  mutations: {
    UpdateAlias: gql`
      mutation UpdateAlias($input: DisplayUpdateAliasInput!) {
        displayUpdateAlias(input: $input) {
          id
          alias
          serialNumber
        }
      }
    `,
  },
};
