/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayLedStripColorSelect_DisplayFragment = { __typename: 'Display', id: string, ledStripColor?: { __typename: 'LedStripColorState', reported: Types.LedStripColor, desired?: Types.LedStripColor | null } | null };

export type UpdateLedStripColorMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateLedStripColorInput;
}>;


export type UpdateLedStripColorMutation = { __typename: 'Mutation', displayBulkUpdateLedStripColor: { __typename: 'DisplayBulkUpdateLedStripColorPayload', displays: Array<{ __typename: 'Display', id: string, ledStripColor?: { __typename: 'LedStripColorState', reported: Types.LedStripColor, desired?: Types.LedStripColor | null } | null }> } };

export const DisplayLedStripColorSelect_DisplayFragmentDoc = gql`
    fragment DisplayLedStripColorSelect_display on Display {
  id
  ledStripColor {
    reported
    desired
  }
}
    `;
export const UpdateLedStripColorDocument = gql`
    mutation UpdateLedStripColor($input: DisplayBulkUpdateLedStripColorInput!) {
  displayBulkUpdateLedStripColor(input: $input) {
    displays {
      ...DisplayLedStripColorSelect_display
    }
  }
}
    ${DisplayLedStripColorSelect_DisplayFragmentDoc}`;
export type UpdateLedStripColorMutationFn = ApolloReactCommon.MutationFunction<UpdateLedStripColorMutation, UpdateLedStripColorMutationVariables>;

/**
 * __useUpdateLedStripColorMutation__
 *
 * To run a mutation, you first call `useUpdateLedStripColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLedStripColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLedStripColorMutation, { data, loading, error }] = useUpdateLedStripColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLedStripColorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLedStripColorMutation, UpdateLedStripColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateLedStripColorMutation, UpdateLedStripColorMutationVariables>(UpdateLedStripColorDocument, options);
      }
export type UpdateLedStripColorMutationHookResult = ReturnType<typeof useUpdateLedStripColorMutation>;
export type UpdateLedStripColorMutationResult = ApolloReactCommon.MutationResult<UpdateLedStripColorMutation>;
export type UpdateLedStripColorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLedStripColorMutation, UpdateLedStripColorMutationVariables>;