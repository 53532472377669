/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PowerScheduleTable_PowerScheduleFragmentDoc } from '../../../../../../components/powerSchedules/PowerScheduleTable/__generated__/PowerScheduleTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerPowerSchedulesPageQueryVariables = Types.Exact<{
  customerHandle: Types.Scalars['String'];
}>;


export type CustomerPowerSchedulesPageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, powerSchedules: Array<{ __typename: 'PowerSchedule', id: string, title: string, description?: string | null, createdAt: any, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> }>, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null } | null };


export const CustomerPowerSchedulesPageDocument = gql`
    query CustomerPowerSchedulesPage($customerHandle: String!) {
  customer: customerByHandle(handle: $customerHandle) {
    id
    powerSchedules {
      ...PowerScheduleTable_powerSchedule
    }
    waveSubscription {
      id
    }
  }
}
    ${PowerScheduleTable_PowerScheduleFragmentDoc}`;

/**
 * __useCustomerPowerSchedulesPageQuery__
 *
 * To run a query within a React component, call `useCustomerPowerSchedulesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPowerSchedulesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPowerSchedulesPageQuery({
 *   variables: {
 *      customerHandle: // value for 'customerHandle'
 *   },
 * });
 */
export function useCustomerPowerSchedulesPageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CustomerPowerSchedulesPageQuery, CustomerPowerSchedulesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerPowerSchedulesPageQuery, CustomerPowerSchedulesPageQueryVariables>(CustomerPowerSchedulesPageDocument, options);
      }
export function useCustomerPowerSchedulesPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerPowerSchedulesPageQuery, CustomerPowerSchedulesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerPowerSchedulesPageQuery, CustomerPowerSchedulesPageQueryVariables>(CustomerPowerSchedulesPageDocument, options);
        }
export type CustomerPowerSchedulesPageQueryHookResult = ReturnType<typeof useCustomerPowerSchedulesPageQuery>;
export type CustomerPowerSchedulesPageLazyQueryHookResult = ReturnType<typeof useCustomerPowerSchedulesPageLazyQuery>;
export type CustomerPowerSchedulesPageQueryResult = ApolloReactCommon.QueryResult<CustomerPowerSchedulesPageQuery, CustomerPowerSchedulesPageQueryVariables>;