import { chakra } from '@chakra-ui/react';
import { FunctionComponent, ReactNode } from 'react';

export const ContentModalSubHeading: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => (
  <chakra.span
    color="gray.500"
    fontSize="xs"
    fontFamily="heading"
    fontWeight="bold"
    letterSpacing="0.15em"
    textTransform="uppercase"
  >
    {children}
  </chakra.span>
);
