import { StyleProps } from './types';

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

export const Table = {
  parts: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'],
  baseStyle: {
    table: {
      border: 0,
      borderCollapse: 'collapse',
      borderSpacing: 0,
      width: 'auto',
      background: 'white',
    },
    th: {
      background: 'white',
      fontFamily: 'body',
    },
  },
  sizes: {
    md: {
      th: {
        fontSize: 'xs',
        px: '2.5',
        py: '3',
        '&:first-of-type': {
          pl: '6',
        },
        '&:last-child': {
          pr: '6',
        },
      },
      td: {
        fontSize: 'sm',
        px: '2.5',
        py: '4',
        '&:first-of-type': {
          pl: '6',
        },
        '&:last-child': {
          pr: '6',
        },
      },
    },
  },
  variants: {
    simple: () => {
      return {
        th: {
          background: 'gray.50',
          color: 'gray.400',
          fontWeight: 'semibold',
        },
        tr: {
          color: 'blue.800',

          fontWeight: 'normal',
        },
        td: {
          borderColor: 'gray.50',
        },
      };
    },
    action: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        th: {
          background: `${c}.50`,
          borderBottom: '1px',
          borderColor: `${c}.100`,
          color: 'gray.400',
          fontWeight: 'semibold',
          ...numericStyles,
        },
        tr: {
          td: {
            ...numericStyles,
          },
          '&:not(:last-child)': {
            td: {
              borderBottom: '1px',
              borderColor: `${c}.100`,
            },
          },
        },
      };
    },
    striped: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        th: {
          color: `${c}.700`,
        },
        tbody: {
          tr: {
            td: {
              color: `${c}.500`,
            },
            '&:hover': {
              background: 'blue.50',
            },
            '&:nth-of-type(odd)': {
              background: `${c}.50`,
              borderBottomWidth: '1px',
              borderColor: `${c}.100`,
              'th, td': {
                borderBottomWidth: 'inherit',
                borderColor: 'inherit',
              },
              td: {
                background: 'inherit',
              },
            },
          },
        },
      };
    },
  },
};
