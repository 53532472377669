/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkEditSite_CustomerFragment = { __typename: 'Customer', id: string, sites: Array<{ __typename: 'Site', id: string, name: string, address?: string | null }> };

export type BulkEditSiteMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateSiteInput;
}>;


export type BulkEditSiteMutation = { __typename: 'Mutation', displayBulkUpdateSite: { __typename: 'DisplayBulkUpdateSitePayload', displays: Array<{ __typename: 'Display', id: string }> } };

export const BulkEditSite_CustomerFragmentDoc = gql`
    fragment BulkEditSite_customer on Customer {
  id
  sites {
    id
    name
    address
  }
}
    `;
export const BulkEditSiteDocument = gql`
    mutation BulkEditSite($input: DisplayBulkUpdateSiteInput!) {
  displayBulkUpdateSite(input: $input) {
    displays {
      id
    }
  }
}
    `;
export type BulkEditSiteMutationFn = ApolloReactCommon.MutationFunction<BulkEditSiteMutation, BulkEditSiteMutationVariables>;

/**
 * __useBulkEditSiteMutation__
 *
 * To run a mutation, you first call `useBulkEditSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditSiteMutation, { data, loading, error }] = useBulkEditSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkEditSiteMutation, BulkEditSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkEditSiteMutation, BulkEditSiteMutationVariables>(BulkEditSiteDocument, options);
      }
export type BulkEditSiteMutationHookResult = ReturnType<typeof useBulkEditSiteMutation>;
export type BulkEditSiteMutationResult = ApolloReactCommon.MutationResult<BulkEditSiteMutation>;
export type BulkEditSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkEditSiteMutation, BulkEditSiteMutationVariables>;