/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type AuthorizationProvider_OrganizationFragment = { __typename: 'Organization', id: string, name: string, handle: string, developerAccess: boolean };

export const AuthorizationProvider_OrganizationFragmentDoc = gql`
    fragment AuthorizationProvider_organization on Organization {
  id
  name
  handle
  developerAccess
}
    `;