/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type DisplayPreview_DisplayFragment = { __typename: 'Display', id: string, commercialTypeNumber: string, serialNumber: string, platform: { __typename: 'DisplayPlatform', type: string } };

export type DisplayPreview_Claimable_DisplayFragment = { __typename: 'ClaimableDisplay', id: string, commercialTypeNumber: string, serialNumber: string, platformType: string };

export const DisplayPreview_DisplayFragmentDoc = gql`
    fragment DisplayPreview_display on Display {
  id
  commercialTypeNumber
  serialNumber
  platform {
    type
  }
}
    `;
export const DisplayPreview_Claimable_DisplayFragmentDoc = gql`
    fragment DisplayPreview_claimable_display on ClaimableDisplay {
  id
  commercialTypeNumber
  serialNumber
  platformType
}
    `;