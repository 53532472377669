/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CustomerUsersTableUserFragment = { __typename: 'User', id: string, givenName: string, familyName: string, email: string, roles: Array<Types.Role> };

export const CustomerUsersTableUserFragmentDoc = gql`
    fragment CustomerUsersTableUser on User {
  id
  givenName
  familyName
  email
  roles
}
    `;