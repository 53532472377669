import { Box, Heading, HStack, IconProps, Tooltip, VStack } from '@chakra-ui/react';
import { ComponentType } from 'react';
import { IconBadge } from '../ui/icons/IconBadge';

export type StatisticsItem = {
  name: string;
  count: number;
  color: string;
  hoverLabel: string;
  clickable?: boolean;
};

type StatisticsBarProps = {
  title: string;
  items: StatisticsItem[];
  icon: ComponentType<IconProps>;
  onItemClicked: (item: StatisticsItem) => void;
};

export default function StatisticsBar({ title, items, icon, onItemClicked }: StatisticsBarProps) {
  return (
    <Box
      boxShadow="0px 6px 16px -1px rgba(0, 56, 95, 0.06)"
      bg="white"
      paddingY="6"
      paddingX="8"
      marginBottom="6"
      borderRadius="base"
      height="24"
      width="full"
      maxWidth="xl"
    >
      <HStack alignItems="center" spacing="4" justifyItems="center">
        <IconBadge icon={icon} bg="blue.100" color="gray.500" variant="big" />
        <VStack w="full" justifyContent="center" alignItems="left">
          <Heading fontFamily="heading" size="md">
            {title}
          </Heading>
          <StackedBarGraph items={items} onItemClicked={onItemClicked} />
        </VStack>
      </HStack>
    </Box>
  );
}

type StackedBarGraphProps = {
  items: StatisticsItem[];
  onItemClicked: (item: StatisticsItem) => void;
};

function StackedBarGraph({ items, onItemClicked }: StackedBarGraphProps) {
  const total = items.reduce((sum, item) => sum + item.count, 0);

  return (
    <Box display="flex" width="auto" height="3" borderRadius="full" overflow="hidden" gap="0.5">
      {Array.from(items.entries()).map(([index, item]) => {
        const shouldShowPointerCursor = item.clickable;
        return item.count > 0 ? (
          <Tooltip key={index} label={item.hoverLabel} hasArrow>
            <Box
              key={index}
              flex={item.count / total}
              bg={item.color}
              onClick={() => onItemClicked(item)}
              cursor={shouldShowPointerCursor ? 'pointer' : 'default'}
            />
          </Tooltip>
        ) : null;
      })}
    </Box>
  );
}
