/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplaySubscriptionsFragmentDoc } from '../../__generated__/useManageAppSubscriptionsForm.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkManageApps_OrganizationAppSubscriptionFragment = { __typename: 'OrganizationAppSubscription', id: string, iconUrl?: string | null, name: string, appVersions: Array<string>, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } };

export type BulkManageApps_OrganizationFragment = { __typename: 'Organization', appSubscriptions: Array<{ __typename: 'OrganizationAppSubscription', id: string, iconUrl?: string | null, name: string, appVersions: Array<string>, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> };

export type BulkManageApps_DisplayFragment = { __typename: 'Display', appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', id: string } | { __typename: 'AppInstallationInstalling', id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', id: string } | { __typename: 'AppInstallationUpdating', id: string } | { __typename: 'AppUninstallationFailed', id: string } | null }> };

export type BulkInstallAppsMutationVariables = Types.Exact<{
  input: Types.DisplayBulkRequestAppInstallationInput;
}>;


export type BulkInstallAppsMutation = { __typename: 'Mutation', displayBulkRequestAppInstallation: { __typename: 'DisplayBulkRequestAppInstallationPayload', displays: Array<{ __typename: 'Display', appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> }> } };

export const BulkManageApps_OrganizationAppSubscriptionFragmentDoc = gql`
    fragment BulkManageApps_organizationAppSubscription on OrganizationAppSubscription {
  id
  iconUrl
  name
  usage {
    current
    max
  }
  appVersions
}
    `;
export const BulkManageApps_OrganizationFragmentDoc = gql`
    fragment BulkManageApps_organization on Organization {
  appSubscriptions {
    ...BulkManageApps_organizationAppSubscription
  }
}
    ${BulkManageApps_OrganizationAppSubscriptionFragmentDoc}`;
export const BulkManageApps_DisplayFragmentDoc = gql`
    fragment BulkManageApps_display on Display {
  appSubscriptions {
    id
    appInstallation {
      id
    }
  }
}
    `;
export const BulkInstallAppsDocument = gql`
    mutation BulkInstallApps($input: DisplayBulkRequestAppInstallationInput!) {
  displayBulkRequestAppInstallation(input: $input) {
    displays {
      appSubscriptions {
        ...DisplaySubscriptions
      }
    }
  }
}
    ${DisplaySubscriptionsFragmentDoc}`;
export type BulkInstallAppsMutationFn = ApolloReactCommon.MutationFunction<BulkInstallAppsMutation, BulkInstallAppsMutationVariables>;

/**
 * __useBulkInstallAppsMutation__
 *
 * To run a mutation, you first call `useBulkInstallAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkInstallAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkInstallAppsMutation, { data, loading, error }] = useBulkInstallAppsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkInstallAppsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkInstallAppsMutation, BulkInstallAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkInstallAppsMutation, BulkInstallAppsMutationVariables>(BulkInstallAppsDocument, options);
      }
export type BulkInstallAppsMutationHookResult = ReturnType<typeof useBulkInstallAppsMutation>;
export type BulkInstallAppsMutationResult = ApolloReactCommon.MutationResult<BulkInstallAppsMutation>;
export type BulkInstallAppsMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkInstallAppsMutation, BulkInstallAppsMutationVariables>;