import { createIcon } from '@chakra-ui/react';

export const EmptySiteIllustration = createIcon({
  displayName: 'EmptySiteIllustration',
  viewBox: '0 0 240 240',
  defaultProps: {
    fill: 'none',
    width: '240px',
    height: '240px',
  },
  path: (
    <>
      <circle cx="120.5" cy="120" r="120" fill="#F1F8FE" />
      <mask
        id="mask0_5358_2360"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="241"
        height="240"
      >
        <circle cx="120.5" cy="120" r="120" fill="#F1F8FE" />
      </mask>
      <g mask="url(#mask0_5358_2360)">
        <path
          opacity="0.1"
          d="M62.7222 12.4443V117.872M62.7222 117.872H192.164M62.7222 117.872L11.7057 168.889M192.164 117.872V230.522M192.164 117.872V82.8879H236.944M192.164 117.872L127.443 175.597M62.7222 233.321L127.443 175.597M127.443 175.597L160.056 208.209L127.443 240.821"
          stroke="#0F5291"
          strokeWidth="8.88889"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M155.25 70.8335C155.25 92.4168 127.5 110.917 127.5 110.917C127.5 110.917 99.75 92.4168 99.75 70.8335C99.75 63.4737 102.674 56.4154 107.878 51.2113C113.082 46.0071 120.14 43.0835 127.5 43.0835C134.86 43.0835 141.918 46.0071 147.122 51.2113C152.326 56.4154 155.25 63.4737 155.25 70.8335Z"
        fill="#0F5291"
        stroke="#0F5291"
        strokeWidth="6.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.5 80.0835C132.609 80.0835 136.75 75.9421 136.75 70.8335C136.75 65.7249 132.609 61.5835 127.5 61.5835C122.391 61.5835 118.25 65.7249 118.25 70.8335C118.25 75.9421 122.391 80.0835 127.5 80.0835Z"
        fill="white"
        stroke="white"
        strokeWidth="6.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
