import { z } from 'zod';

export function toHandle(name: string): string {
  return name
    .toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll(/[^a-zA-Z0-9-]/g, '');
}

const userBaseValidationSchema = {
  givenName: z
    .string()
    .nonempty('Required field')
    .min(2, 'First name needs to be at least two characters'),
  familyName: z
    .string()
    .nonempty('Required field')
    .min(2, 'Family name needs to be at least two characters'),
};

export const userCreateValidationSchema = {
  ...userBaseValidationSchema,
  email: z.string().nonempty('Required field').email({ message: 'Invalid email address' }),
};

export const userEditValidationSchema = {
  ...userBaseValidationSchema,
};

export const customerBaseValidationSchema = {
  name: z
    .string()
    .nonempty('Required field')
    .min(1, 'The name must contain at least one character')
    .regex(/.*\S.*/, 'The name cannot contain only white spaces'),
  handle: z
    .string()
    .nonempty('Required field')
    .min(1, 'The handle must contain at least one character')
    .regex(
      /^[a-z0-9_-]+$/,
      'Should only contain lowercase alphanumeric characters, underscores and hyphens',
    ),
  avatarUrl: z.string().optional(),
};

export const customerErrorMap: Record<string, string> = {
  CUSTOMER_LIMIT_REACHED:
    "Looks like you've reached the number of customers that your organization can create.",
  CUSTOMER_NAME_EMPTY:
    'Looks like you are adding an empty name for your customer. Please enter a valid name.',
  CUSTOMER_NAME_DUPLICATE:
    'Looks like this customer name already exists within your organization. Please enter a unique name.',
  CUSTOMER_HANDLE_DUPLICATE:
    'Looks like this customer handle already exists within your organization. Please enter a unique handle.',
  CUSTOMER_HANDLE_INVALID_CHARACTER:
    'Looks like this customer handle is invalid. Please only use lowercase alphanumeric characters, underscores and hyphens.',
};
