import { createIcon } from '@chakra-ui/react';

export const PlaylistUploadIllustration = createIcon({
  displayName: 'PlaylistUploadIllustration',
  viewBox: '0 0 240 240',
  defaultProps: {
    fill: 'none',
    width: '240px',
    height: '240px',
  },
  path: (
    <>
      <circle cx="120" cy="120" r="120" fill="#F1F8FE" />
      <g opacity="0.9" filter="url(#filter0_dd_347_5898)">
        <path
          d="M123.731 155.509C100.381 154.44 81.7778 135.17 81.7778 111.556C81.7778 87.2551 101.477 67.5557 125.778 67.5557C143.832 67.5557 159.347 78.4295 166.13 93.9853C170.202 92.1263 174.729 91.0905 179.499 91.0905C197.3 91.0905 211.731 105.522 211.731 123.323C211.731 141.125 197.3 155.556 179.499 155.556C179.328 155.556 179.157 155.554 178.987 155.552V155.556H123.731V155.509Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_dd_347_5898)">
        <path
          d="M83.5626 167.054C58.327 165.898 38.2222 145.073 38.2222 119.552C38.2222 93.2898 59.512 72 85.7743 72C105.286 72 122.053 83.7517 129.384 100.563C133.785 98.5542 138.678 97.4348 143.832 97.4348C163.071 97.4348 178.667 113.031 178.667 132.269C178.667 151.508 163.071 167.104 143.832 167.104C143.647 167.104 143.463 167.103 143.279 167.1V167.104H83.5626V167.054Z"
          fill="white"
        />
      </g>
      <path
        d="M108.444 139.259L108.444 118.519"
        stroke="#0F5291"
        strokeWidth="4.44444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.074 128.889L108.444 118.518L118.815 128.889"
        stroke="#0F5291"
        strokeWidth="4.44444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_dd_347_5898"
          x="69.4987"
          y="65.5092"
          width="154.512"
          height="112.558"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.04651"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_347_5898"
          />
          <feOffset dy="4.09302" />
          <feGaussianBlur stdDeviation="3.06977" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_347_5898" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.06977"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_347_5898"
          />
          <feOffset dy="10.2326" />
          <feGaussianBlur stdDeviation="7.67442" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_347_5898"
            result="effect2_dropShadow_347_5898"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_347_5898"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_347_5898"
          x="24.9519"
          y="69.7883"
          width="166.985"
          height="121.645"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_347_5898"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_347_5898" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_347_5898"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_347_5898"
            result="effect2_dropShadow_347_5898"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_347_5898"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
