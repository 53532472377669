import { Box, BoxProps, Heading, HStack, Spacer, useBreakpoint } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = BoxProps & {
  floatingButton?: ReactNode;
  labels?: ReactNode;
  actions?: ReactNode;
  description?: string;
};

export function PageHeading({
  floatingButton,
  description,
  color = 'gray.900',
  marginBottom = '6',
  actions,
  children,
  labels,
  ...rest
}: Props) {
  const breakpoint = useBreakpoint();

  const headingElement = (
    <Box>
      <Heading fontFamily="heading" size="xl" mb={description ? 4 : 0}>
        {children}
      </Heading>
      {!!description && <p>{description}</p>}
    </Box>
  );

  const labelsElement = !!labels && (
    <HStack
      flexWrap="wrap"
      spacing="6"
      alignItems="center"
      marginInlineStart={{ base: 0, lg: '64px !important' }}
      flex="1"
    >
      {labels}
    </HStack>
  );

  const actionsElement = !!actions && (
    <Box marginLeft={{ base: 0, md: 5 }} alignSelf="start">
      {actions}
    </Box>
  );

  if (['base', 'sm', 'md'].includes(breakpoint || '')) {
    return (
      <Box paddingTop="4" marginBottom={marginBottom} color={color} {...rest}>
        {floatingButton}
        <HStack mb="3">
          {headingElement}
          <Spacer />
          {actionsElement}
        </HStack>
        {labelsElement}
      </Box>
    );
  }

  const floatingButtonElement = !!floatingButton && (
    <Box position="absolute" top="4" left="-50px">
      {floatingButton}
    </Box>
  );

  return (
    <HStack paddingTop="4" marginBottom={marginBottom} color={color} position="relative" {...rest}>
      {floatingButtonElement}
      {headingElement}
      {labelsElement || <Spacer />}
      {actionsElement}
    </HStack>
  );
}
