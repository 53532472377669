/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayOrientationToggle_DisplayFragment = { __typename: 'Display', id: string, orientation?: { __typename: 'OrientationState', reported: Types.Orientation, desired?: Types.Orientation | null, supportedValues: Array<Types.Orientation> } | null };

export type UpdateOrientationMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateOrientationInput;
}>;


export type UpdateOrientationMutation = { __typename: 'Mutation', displayUpdateOrientation: { __typename: 'DisplayUpdateOrientationPayload', display: { __typename: 'Display', id: string, orientation?: { __typename: 'OrientationState', reported: Types.Orientation, desired?: Types.Orientation | null, supportedValues: Array<Types.Orientation> } | null } } };

export const DisplayOrientationToggle_DisplayFragmentDoc = gql`
    fragment DisplayOrientationToggle_display on Display {
  id
  orientation {
    reported
    desired
    supportedValues
  }
}
    `;
export const UpdateOrientationDocument = gql`
    mutation UpdateOrientation($input: DisplayUpdateOrientationInput!) {
  displayUpdateOrientation(input: $input) {
    display {
      id
      orientation {
        reported
        desired
        supportedValues
      }
    }
  }
}
    `;
export type UpdateOrientationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrientationMutation, UpdateOrientationMutationVariables>;

/**
 * __useUpdateOrientationMutation__
 *
 * To run a mutation, you first call `useUpdateOrientationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrientationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrientationMutation, { data, loading, error }] = useUpdateOrientationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrientationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrientationMutation, UpdateOrientationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateOrientationMutation, UpdateOrientationMutationVariables>(UpdateOrientationDocument, options);
      }
export type UpdateOrientationMutationHookResult = ReturnType<typeof useUpdateOrientationMutation>;
export type UpdateOrientationMutationResult = ApolloReactCommon.MutationResult<UpdateOrientationMutation>;
export type UpdateOrientationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrientationMutation, UpdateOrientationMutationVariables>;