import { gql } from '@apollo/client';
import { Box, Button, Stack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield } from '~auth/Shield';
import { UsersTable } from '~components/organization/UsersTable';
import { useUsersTable } from '~components/organization/UsersTable/useUsersTable';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { useOrganizationUsersIndexPageQuery } from './__generated__/Index.graphql';

const POLL_INTERVAL = 10000;

export function OrganizationUsersIndexPage() {
  const { data, loading, error, startPolling, stopPolling } = useOrganizationUsersIndexPageQuery({
    pollInterval: POLL_INTERVAL,
  });

  const navigate = useNavigate();

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const users = useMemo(() => data?.organization.users ?? [], [data?.organization.users]);

  const table = useUsersTable(users);

  const handleCreate = () => {
    navigate('./create', { state: { canGoBack: true } });
  };

  return (
    <VerticalTabContent title="Users" isLoading={loading} error={error}>
      <Stack direction={{ sm: 'column', md: 'row' }} spacing="8" mb="4">
        <UsersTable.Filter table={table} />
        <Shield requiredPermissions={[Permission.UserCreate]}>
          <Box textAlign="right">
            <Button variant="solid" colorScheme="blue" onClick={handleCreate}>
              Add new user
            </Button>
          </Box>
        </Shield>
      </Stack>
      <Box
        boxShadow="elevated"
        border="1px solid"
        borderRadius="md"
        borderColor="gray.100"
        overflowX="auto"
        bgColor="white"
      >
        <UsersTable table={table} />
      </Box>
      <Box display="flex" marginTop="4">
        <Box flex="1">
          <UsersTable.PageSizeSelector table={table} />
        </Box>
        <UsersTable.Pagination table={table} />
      </Box>
    </VerticalTabContent>
  );
}

OrganizationUsersIndexPage.graphql = {
  fragments: {
    OrganizationUsersIndexPage_user: gql`
      fragment OrganizationUsersIndexPage_user on User {
        id
        email
        roles
        givenName
        familyName
        customerIds
      }
    `,
  },
  queries: {
    OrganizationUsersIndexPage: gql`
      query OrganizationUsersIndexPage {
        organization {
          id
          users {
            ...OrganizationUsersIndexPage_user
          }
        }
      }
    `,
  },
};
