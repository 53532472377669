/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRemovePowerScheduleFromDisplays_PowerScheduleFragmentDoc } from '../../../__generated__/useRemovePowerScheduleFromDisplays.graphql';
export type ActionsCell_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, removingDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> };

export const ActionsCell_PowerScheduleFragmentDoc = gql`
    fragment ActionsCell_powerSchedule on PowerSchedule {
  id
  removingDisplays: displays(filter: {state: REMOVING}) {
    id
  }
  syncingDisplays: displays(filter: {state: SYNCING}) {
    id
  }
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
  allDisplays: displays {
    id
  }
  ...useRemovePowerScheduleFromDisplays_powerSchedule
}
    ${UseRemovePowerScheduleFromDisplays_PowerScheduleFragmentDoc}`;