import { AlertDescription, AlertTitle, Button } from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useCallback, useMemo } from 'react';
import { PlaylistWarningAlert_DisplayFragment } from '~components/displays/DisplayDetail/summary/__generated__/PlaylistWarningAlert.graphql';
import { ErrorAlert, WarningAlert } from '~components/ui/Alert';
import { useDisplayPresence } from '../../useDisplayPresence';
import { usePlaylists } from '../../usePlaylists';

interface Props {
  display: PlaylistWarningAlert_DisplayFragment;
}

export function PlaylistWarningAlert({ display }: Props) {
  const { getPresence } = useDisplayPresence();
  const { isConnected } = useMemo(() => getPresence(display), [display, getPresence]);
  const { getPlaylistState, syncPlaylist } = usePlaylists();
  const playlistState = useMemo(() => getPlaylistState(display), [getPlaylistState, display]);

  const handleResyncPlaylist = useCallback(async () => {
    await syncPlaylist(display);
  }, [syncPlaylist, display]);

  const playlistTitle = display?.playlist?.current?.title;
  const title = useMemo(() => {
    switch (playlistState.kind) {
      case 'playlist_out_of_sync':
        return `Playlist ${playlistTitle ? `"${playlistTitle}" ` : ''}is out of sync`;
      case 'playlist_sync_failed':
        return `Playlist ${playlistTitle ? `"${playlistTitle}" ` : ''}sync failed`;
      case 'playlist_tampered':
        return `Playlist ${playlistTitle ? `"${playlistTitle}" ` : ''}has been modified on display`;
      default:
        return null;
    }
  }, [playlistState, playlistTitle]);

  const callToAction = useMemo(() => {
    if (isConnected) {
      switch (playlistState.kind) {
        case 'playlist_sync_failed':
          return 'Try again';
        default:
          return 'Sync';
      }
    } else {
      switch (playlistState.kind) {
        case 'playlist_sync_failed':
          return 'Try again when connected';
        default:
          return 'Sync when connected';
      }
    }
  }, [isConnected, playlistState]);

  return (
    <>
      {playlistState.kind === 'playlist_sync_failed' &&
        playlistState.reason === 'insufficient_disk_space' && (
          <ErrorAlert
            actionButton={
              <Button
                variant="outline"
                size="sm"
                colorScheme="red"
                color="red.700"
                onClick={handleResyncPlaylist}
              >
                {callToAction}
              </Button>
            }
          >
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>Insufficient storage for sync</AlertDescription>
          </ErrorAlert>
        )}
      {(playlistState.kind === 'playlist_out_of_sync' ||
        (playlistState.kind === 'playlist_sync_failed' &&
          playlistState.reason !== 'insufficient_disk_space') ||
        playlistState.kind === 'playlist_tampered') && (
        <WarningAlert
          actionButton={
            <Button
              variant="outline"
              size="sm"
              colorScheme="orange"
              color="orange.700"
              onClick={handleResyncPlaylist}
            >
              {callToAction}
            </Button>
          }
        >
          <AlertTitle>{title}</AlertTitle>
        </WarningAlert>
      )}
    </>
  );
}

PlaylistWarningAlert.graphql = {
  fragments: {
    PlaylistWarningAlert_display: gql`
      fragment PlaylistWarningAlert_display on Display {
        id
        playlist {
          current {
            title
          }
          sync {
            jobId
          }
        }
        ...UsePlaylists_display
      }
    `,
  },
  mutations: {
    abortPlaylistJob: gql`
      mutation AbortPlaylistJob($input: DisplayAbortJobInput!) {
        displayAbortJob(input: $input) {
          display {
            id
            ...PlaylistWarningAlert_display
          }
        }
      }
    `,
  },
};
