/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type FirmwareCell_ValueFragment = { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string>, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateRejected', rejectedAt: any, rejectionCode: string, id: string, targetVersion: any, createdAt: any, plannedAt: any } | null } };

export type FirmwareCell_DisplayFragment = { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string>, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateRejected', rejectedAt: any, rejectionCode: string, id: string, targetVersion: any, createdAt: any, plannedAt: any } | null } } | null, platform: { __typename: 'DisplayPlatform', type: string } };

export const FirmwareCell_ValueFragmentDoc = gql`
    fragment FirmwareCell_value on Firmware {
  android {
    version
    availableUpdates
    latestJob {
      id
      targetVersion
      createdAt
      plannedAt
      ... on AndroidUpdateDownloading {
        downloadProgress
      }
      ... on AndroidUpdateRejected {
        rejectedAt
        rejectionCode
      }
    }
  }
}
    `;
export const FirmwareCell_DisplayFragmentDoc = gql`
    fragment FirmwareCell_display on Display {
  id
  firmware {
    ...FirmwareCell_value
  }
  platform {
    type
  }
}
    ${FirmwareCell_ValueFragmentDoc}`;