import { Avatar, Box, Button, Stack, useDisclosure } from '@chakra-ui/react';
import gql from 'graphql-tag';
import _ from 'lodash';
import { forwardRef, Ref, useCallback, useLayoutEffect, useMemo } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useLocation } from 'react-router-dom';
import { MenuBackdrop, MenuItem } from '~components/MenuBackdrop';
import { getInitials } from '~components/ui/styles/Avatar';
import { useAnalyticsReporter } from '~utils/analytics';
import { useDimensions } from '~utils/dimensions';
import { useLocalStorage } from '~utils/localStorage';
import { BuildingIcon, ChevronDownIcon } from '../ui/icons';
import {
  CustomerSwitcher_CustomerFragment,
  CustomerSwitcher_OrganizationFragment,
} from './__generated__/CustomerSwitcher.graphql';

interface Props {
  activeCustomerHandle?: string;
  organization: CustomerSwitcher_OrganizationFragment;
}

export function CustomerSwitcher({ activeCustomerHandle, organization }: Props) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [ref, { width }, measure] = useDimensions<HTMLDivElement>();
  const location = useLocation();

  useLayoutEffect(() => {
    measure();
  }, [isOpen, measure]);

  const activeCustomer = useMemo(
    () => organization.customers.find((c) => c.handle === activeCustomerHandle),
    [organization.customers, activeCustomerHandle],
  );

  const { recentlyUsed } = useRecentlyUsedCustomers();
  const customersByRecentlyUsedAndLimited = useMemo(() => {
    return _.chain(organization.customers)
      .sortBy((customer) =>
        recentlyUsed.indexOf(customer.handle) !== -1
          ? recentlyUsed.indexOf(customer.handle)
          : organization.customers.length,
      )
      .filter((c) => c.id !== activeCustomer?.id)
      .take(3)
      .value();
  }, [activeCustomer, organization.customers, recentlyUsed]);

  const analytics = useAnalyticsReporter();

  const handleCustomerClicked = () => {
    analytics.track('headerCustomerSelected');
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box position="relative" display="flex" alignItems="center">
        <MenuBackdrop width={width + 70} isOpen={isOpen}>
          <MenuItem to={'../'} onClick={onClose} icon={<BuildingIcon width="6" />} iconWidth="40px">
            All customers
          </MenuItem>
          <Box marginTop="5" paddingX="2" color="gray.400" fontSize="14px" fontWeight="semibold">
            Recent customers
          </Box>
          <Stack direction="column" spacing="2">
            {customersByRecentlyUsedAndLimited.map((customer) => (
              <MenuItem
                key={customer.id}
                to={`/${organization.handle}/customers/${customer.handle}`}
                onClick={handleCustomerClicked}
                iconWidth="40px"
                icon={
                  <Avatar
                    name={customer.name}
                    getInitials={getInitials}
                    src={customer.avatarUrl ?? undefined}
                    variant="square"
                    size="md"
                    width="40px"
                    height="40px"
                    _groupHover={{
                      background: 'blue.50',
                    }}
                  />
                }
              >
                {customer.name}
              </MenuItem>
            ))}
          </Stack>
        </MenuBackdrop>
        <Trigger
          activeCustomer={activeCustomer}
          avatar={activeCustomer?.avatarUrl ?? organization.avatarUrl ?? undefined}
          ref={ref}
          isOpen={isOpen}
          onToggle={onToggle}
          initialsName={activeCustomer?.name ?? organization?.name}
          label={
            activeCustomer?.name ??
            (location.pathname.includes('/settings/') ? 'Settings' : 'All customers')
          }
        />
      </Box>
    </ClickAwayListener>
  );
}

CustomerSwitcher.graphql = {
  fragments: {
    CustomerSwitcher_organization: gql`
      fragment CustomerSwitcher_organization on Organization {
        handle
        name
        customers {
          id
          ...CustomerSwitcher_customer
        }
        avatarUrl
      }
    `,
    CustomerSwitcher_customer: gql`
      fragment CustomerSwitcher_customer on Customer {
        id
        name
        handle
        avatarUrl
      }
    `,
  },
};

interface TriggerProps {
  activeCustomer: CustomerSwitcher_CustomerFragment | undefined;
  label: string;
  initialsName: string;
  avatar: string | undefined;
  isOpen: boolean;
  onToggle: () => void;
}

const Trigger = forwardRef(function Trigger(
  { avatar, activeCustomer, isOpen, onToggle, label, initialsName }: TriggerProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <Box ref={ref} zIndex="sticky">
      <Button
        aria-haspopup={true}
        aria-expanded={isOpen}
        zIndex={0}
        variant="unstyled"
        justifyContent="flex-start"
        height="100%"
        textAlign="inherit"
        paddingX="2"
        onClick={onToggle}
      >
        <Box role="group" display="flex" alignItems="center" flexWrap="nowrap">
          <Box>
            <Avatar
              variant="square"
              size="md"
              name={initialsName}
              getInitials={getInitials}
              src={avatar}
            />
          </Box>
          <Box
            flex="1"
            marginLeft="4"
            display={{
              base: 'none',
              lg: 'block',
            }}
          >
            {!!activeCustomer && (
              <Box color="gray.400" fontWeight="semibold" fontSize="xs">
                Customer
              </Box>
            )}
            <Box
              display="flex"
              flex="1"
              flexWrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                color={isOpen ? 'blue.500' : 'gray.900'}
                fontWeight="semibold"
                fontSize="md"
                paddingRight="9"
                transition="width 0.3s"
                _groupHover={{
                  color: 'blue.500',
                }}
              >
                {label}
              </Box>

              <Box marginLeft="2">
                <ChevronDownIcon
                  color={isOpen ? 'blue.500' : 'gray.900'}
                  transition="transform 0.2s"
                  transform={isOpen ? 'rotate(180deg)' : undefined}
                  transformOrigin="center"
                  _groupHover={{
                    color: 'blue.500',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Button>
    </Box>
  );
});

export function useRecentlyUsedCustomers() {
  const maxRecentlyUsed = 10;
  const [recentlyUsed, setRecentlyUsed] = useLocalStorage<string[]>('recentCustomers', []);

  const addToRecentlyUsed = useCallback(
    (handle: string) => {
      setRecentlyUsed((r) => _.take(_.uniq([handle, ...r]), maxRecentlyUsed));
    },
    [setRecentlyUsed],
  );

  return { recentlyUsed, addToRecentlyUsed };
}
