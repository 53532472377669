import { TableInstance } from 'react-table';
import { PageSizeSelector } from '~components/ui/PageSizeSelector';
import { Pagination } from '~components/ui/Pagination';
import { CustomerSubscriptionsTableSubscriptionFragment } from './__generated__/CustomerSubscriptionsTable.graphql';

export function CustomerSubscriptionsTablePagination({
  table,
}: {
  table: TableInstance<CustomerSubscriptionsTableSubscriptionFragment>;
}) {
  const {
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    state: { pageIndex },
    pageCount,
  } = table;

  return (
    <Pagination
      pageCount={pageCount}
      currentPageIndex={pageIndex}
      hasPreviousPage={canPreviousPage}
      hasNextPage={canNextPage}
      goToPage={gotoPage}
      goToPreviousPage={previousPage}
      goToNextPage={nextPage}
    />
  );
}

export function CustomerSubscriptionsTablePageSizeSelector({
  table,
  sizes,
}: {
  table: TableInstance<CustomerSubscriptionsTableSubscriptionFragment>;
  sizes?: number[];
}) {
  const {
    state: { pageSize },
    setPageSize,
  } = table;

  return (
    <PageSizeSelector
      label="subscriptions per page"
      onChange={setPageSize}
      options={sizes}
      value={pageSize}
    />
  );
}
