/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateVolume_DisplayFragment = { __typename: 'Display', id: string, volume?: { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', reported: boolean, desired?: boolean | null } | null, level?: { __typename: 'VolumeLevelState', reported: number, desired?: number | null } | null, limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null };

export type BulkUpdateVolumeMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateVolumeInput;
}>;


export type BulkUpdateVolumeMutation = { __typename: 'Mutation', displayBulkUpdateVolume: { __typename: 'DisplayBulkUpdateVolumePayload', displays: Array<{ __typename: 'Display', id: string, volume?: { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', reported: boolean, desired?: boolean | null } | null, level?: { __typename: 'VolumeLevelState', reported: number, desired?: number | null } | null, limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null }> } };

export const BulkUpdateVolume_DisplayFragmentDoc = gql`
    fragment BulkUpdateVolume_display on Display {
  id
  volume {
    isMuted {
      reported
      desired
    }
    level {
      reported
      desired
    }
    limits {
      min {
        reported
        desired
      }
      max {
        reported
        desired
      }
    }
  }
}
    `;
export const BulkUpdateVolumeDocument = gql`
    mutation BulkUpdateVolume($input: DisplayBulkUpdateVolumeInput!) {
  displayBulkUpdateVolume(input: $input) {
    displays {
      id
      ...BulkUpdateVolume_display
    }
  }
}
    ${BulkUpdateVolume_DisplayFragmentDoc}`;
export type BulkUpdateVolumeMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateVolumeMutation, BulkUpdateVolumeMutationVariables>;

/**
 * __useBulkUpdateVolumeMutation__
 *
 * To run a mutation, you first call `useBulkUpdateVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateVolumeMutation, { data, loading, error }] = useBulkUpdateVolumeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateVolumeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateVolumeMutation, BulkUpdateVolumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdateVolumeMutation, BulkUpdateVolumeMutationVariables>(BulkUpdateVolumeDocument, options);
      }
export type BulkUpdateVolumeMutationHookResult = ReturnType<typeof useBulkUpdateVolumeMutation>;
export type BulkUpdateVolumeMutationResult = ApolloReactCommon.MutationResult<BulkUpdateVolumeMutation>;
export type BulkUpdateVolumeMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateVolumeMutation, BulkUpdateVolumeMutationVariables>;