import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Radio,
  Stack,
  Text,
  useDisclosure,
  useRadioGroup,
  useToast,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useAuth } from '~auth/useAuth';
import { ChangeDefaultContentSourceModal } from '~components/displays/ChangeDefaultContentSourceModal';
import { DisplayDefaultContentSource_DisplayFragment } from '~components/displays/DisplayDetail/playback/__generated__/DisplayDefaultContentSource.graphql';
import { useContentSource, useContentSourceLabel } from '~components/displays/useContentSource';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { isDefined } from '~utils/types';

interface Props {
  display: DisplayDefaultContentSource_DisplayFragment;
}
export function DisplayDefaultContentSource({ display }: Props) {
  const changeDefaultContentSourceModal = useDisclosure();
  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const { verifyUserPermissions } = useAuth();

  const contentSource =
    display.contentSource?.default?.desired ?? display.contentSource?.default?.reported;

  const isInputContentSource = contentSource?.__typename === 'InputContentSource';

  const isInputContentSourceWithLastInputSource =
    isInputContentSource && contentSource?.source === 'LAST INPUT';

  const isInputContentSourceWithoutLastInputSource =
    isInputContentSource && contentSource?.source !== 'LAST INPUT';

  const { bulkUpdateDefaultContentSource } = useContentSource();

  const handleChange = useCallback(
    async (value: string) => {
      try {
        if (value === 'LAST INPUT') {
          await bulkUpdateDefaultContentSource([display], {
            source: value,
            __typename: 'InputContentSource',
          });
        } else if (value === 'OTHER') {
          const availableContentSource = display.contentSource?.available
            // Some apps cannot be set as a default content source. Filter them out.
            ?.filter((availableContentSource) => {
              if (
                availableContentSource.__typename === 'AppContentSource' &&
                isDefined(availableContentSource.isBootable)
              ) {
                return availableContentSource.isBootable;
              }

              return true;
            })
            .at(0);

          if (isNil(availableContentSource)) {
            throw new Error('Input source cannot be empty');
          }

          await bulkUpdateDefaultContentSource([display], availableContentSource);
        }
        analytics.track('displaySettingUpdate', {
          group: 'playback',
          changeItem: 'defaultContentSource',
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot change input source',
          description: fromError(err, 'UpdateDefaultContentSource'),
        });
      }
    },
    [analytics, bulkUpdateDefaultContentSource, display, toast],
  );

  const { bookmarkLabel, mediaLabel, appLabel, headingLabel } = useContentSourceLabel(
    contentSource,
    display,
  );

  const defaultInputSources = [
    {
      value: 'LAST INPUT',
      label: 'Switch to last input source when display turns on',
      isChecked: isInputContentSourceWithLastInputSource,
      isEnabled: true,
    },
    {
      value: 'OTHER',
      label: 'Switch to specific input source when display turns on',
      isChecked:
        contentSource?.__typename !== 'InputContentSource' ||
        isInputContentSourceWithoutLastInputSource,
      isEnabled: Boolean(
        display?.contentSource?.available && display?.contentSource?.available?.length > 0,
      ),
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'defaultInputSource',
    defaultValue: defaultInputSources.find((s) => s.isChecked)?.value,
    onChange: handleChange,
  });

  const group = getRootProps();

  const hasDisplaySettingsUpdatePermission = verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <>
      <Stack direction="row" spacing="8" alignItems="center">
        <Box flex="1" wordBreak="break-word">
          <FormLabel>
            Default input source
            <FormLabelPendingIndicator
              isPending={isPropertyPending(display.contentSource?.default)}
            />
          </FormLabel>

          <Text color="gray.500" marginBottom="3">
            The default input source controls which input the display will switch automatically
            every time it turns on.
          </Text>

          <Stack {...group}>
            {defaultInputSources.map(({ value, label, isChecked, isEnabled }) => {
              const radio = getRadioProps({ value });
              return (
                <Radio
                  key={value}
                  {...radio}
                  isChecked={isChecked}
                  isDisabled={!hasDisplaySettingsUpdatePermission || !isEnabled}
                >
                  {label}
                </Radio>
              );
            })}
          </Stack>
          {(isInputContentSourceWithoutLastInputSource ||
            contentSource?.__typename !== 'InputContentSource') && (
            <HStack spacing="5" alignItems="center" mt="2">
              <Flex
                direction="column"
                borderLeft="2px solid"
                borderColor="gray.100"
                pl="4"
                ml="8"
                minHeight="12"
                justifyContent="center"
                alignItems="left"
              >
                {isInputContentSource && !contentSource?.source ? (
                  'NOT SET'
                ) : (
                  <>
                    <Heading size="sm" color="gray.900" mb="0" lineHeight="7">
                      {headingLabel}
                    </Heading>
                    {bookmarkLabel || mediaLabel || appLabel ? (
                      <chakra.span color="gray.500" fontWeight="normal">
                        {bookmarkLabel}
                        {mediaLabel}
                        {appLabel}
                      </chakra.span>
                    ) : null}
                  </>
                )}
              </Flex>
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={changeDefaultContentSourceModal.onOpen}
                isDisabled={!hasDisplaySettingsUpdatePermission}
              >
                Change
              </Button>
            </HStack>
          )}
        </Box>
      </Stack>
      <ChangeDefaultContentSourceModal
        display={display}
        isOpen={changeDefaultContentSourceModal.isOpen}
        onCancel={changeDefaultContentSourceModal.onClose}
        onSuccess={changeDefaultContentSourceModal.onClose}
      />
    </>
  );
}

DisplayDefaultContentSource.graphql = {
  fragments: {
    DisplayDefaultContentSource_display: gql`
      fragment DisplayDefaultContentSource_display on Display {
        id
        ...ChangeDefaultContentSourceModal_display
      }
    `,
  },
};
