import { gql } from '@apollo/client';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { Column, TableInstance, useSortBy, useTable } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { useAnalyticsReporter } from '~utils/analytics';
import { compareMaybeStrings } from '~utils/compare';
import { MaybePromise } from '~utils/types';
import { PowerScheduleTable_PowerScheduleFragment } from './__generated__/PowerScheduleTable.graphql';
import { ActionsCell } from './Cell/ActionsCell';
import { CreatedAtCell } from './Cell/CreateAtCell';
import { DescriptionCell } from './Cell/DescriptionCell';
import { TitleCell } from './Cell/TitleCell';
import { UsedByCell } from './Cell/UsedBy';

export enum Columns {
  Title = 'title',
  Description = 'description',
  CreatedAt = 'createdAt',
  UsedBy = 'usedBy',
  Actions = 'actions',
}

export function usePowerScheduleTable(data: PowerScheduleTable_PowerScheduleFragment[]) {
  const columns = useMemo<Array<Column<PowerScheduleTable_PowerScheduleFragment>>>(
    () => [
      {
        id: Columns.Title,
        Header: 'Title',
        accessor: (p) => p.title,
        Cell: TitleCell,
        width: '230px',
        minWidth: 230,
        maxWidth: 230,
      },
      {
        id: Columns.Description,
        Header: 'Description',
        accessor: (p) => p.description,
        sortType: (rowA, rowB) =>
          compareMaybeStrings(rowA.original.description, rowB.original.description),
        Cell: DescriptionCell,
        width: '350px',
        minWidth: 350,
        maxWidth: 350,
      },
      {
        id: Columns.CreatedAt,
        Header: 'Date Added',
        accessor: (d) => d.createdAt,
        Cell: CreatedAtCell,
        width: 'auto',
      },
      {
        id: Columns.UsedBy,
        Header: 'Displays',
        accessor: (d) => d,
        Cell: UsedByCell,
        width: '250px',
        minWidth: 250,
        maxWidth: 250,
      },
      {
        id: Columns.Actions,
        Header: '',
        accessor: (d) => d,
        Cell: ActionsCell,
        width: '70px',
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [],
  );

  return useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
    },
    useSortBy,
  );
}

/* eslint-disable react/jsx-key */
export function PowerScheduleTable({
  table,
  onGoToDetail,
}: {
  table: TableInstance<PowerScheduleTable_PowerScheduleFragment>;
  onGoToDetail: (id: string) => MaybePromise<void>;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = table;
  const analytics = useAnalyticsReporter();

  return (
    <Table {...getTableProps()} variant="simple" width="100%">
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const headerProps = column.getHeaderProps(column.getSortByToggleProps());
              return (
                <Th
                  {...headerProps}
                  color={column.isSorted ? 'gray.700' : 'gray.600'}
                  minWidth={`${column.minWidth}px`}
                  maxWidth={`${column.maxWidth}px`}
                  width={column.width}
                  _hover={
                    column.disableSortBy
                      ? {}
                      : {
                          textColor: 'gray.800',
                        }
                  }
                  onClick={(e) => {
                    if (column.id !== 'selection') {
                      analytics.track('scheduleTableSort', { sortType: column.id });
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (headerProps as any).onClick?.(e);
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box flex="1">{column.render('Header') as ReactNode}</Box>
                    {column.canSort && (
                      <Box>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronDownIcon display="inherit" />
                          ) : (
                            <ChevronUpIcon display="inherit" />
                          )
                        ) : (
                          <ChevronDownIcon visibility="hidden" />
                        )}
                      </Box>
                    )}
                  </Box>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Tr
              {...row.getRowProps()}
              role="button"
              _hover={{
                background: 'blue.50',
                cursor: 'pointer',
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <Td
                    minWidth={`${cell.column.minWidth}px`}
                    maxWidth={`${cell.column.maxWidth}px`}
                    width={cell.column.width}
                    onClick={() => {
                      analytics.track('scheduleTableItemClick');
                      onGoToDetail(row.original.id);
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell') as ReactNode}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
/* eslint-enable react/jsx-key */

PowerScheduleTable.graphql = {
  fragments: {
    PowerScheduleTable_powerSchedule: gql`
      fragment PowerScheduleTable_powerSchedule on PowerSchedule {
        id
        ...TitleCell_powerSchedule
        ...DescriptionCell_powerSchedule
        ...CreatedAtCell_powerSchedule
        ...UsedByCell_powerSchedule
        ...ActionsCell_powerSchedule
      }
    `,
  },
};
