import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import UpgradeToWaveEssentialEmptyState from './EmptyState';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function UpgradeToWaveEssentialModal({ isOpen, onClose }: Props) {
  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="50vw">
        <ModalHeader>Upgrade to Wave Essential</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UpgradeToWaveEssentialEmptyState />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

UpgradeToWaveEssentialModal.EmptyView = UpgradeToWaveEssentialEmptyState;
