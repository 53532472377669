import { createIcon } from '@chakra-ui/react';

export const NoDisplayIcon = createIcon({
  displayName: 'NoDisplayIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM22.2929 23.7071C22.6834 24.0976 23.3166 24.0976 23.7071 23.7071C24.0976 23.3166 24.0976 22.6834 23.7071 22.2929L22.2929 23.7071ZM21 16C21 16.5523 21.4477 17 22 17C22.5523 17 23 16.5523 23 16H21ZM17.7616 19C18.3139 19 18.7616 18.5523 18.7616 18C18.7616 17.4477 18.3139 17 17.7616 17V19ZM4 5C4.55228 5 5 4.55228 5 4C5 3.44772 4.55228 3 4 3V5ZM6 20C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22V20ZM18 22C18.5523 22 19 21.5523 19 21C19 20.4477 18.5523 20 18 20V22ZM9.5 3C8.94772 3 8.5 3.44772 8.5 4C8.5 4.55228 8.94772 5 9.5 5V3ZM0.292893 1.70711L22.2929 23.7071L23.7071 22.2929L1.70711 0.292893L0.292893 1.70711ZM3 16V6H1V16H3ZM21 6V16H23V6H21ZM17.7616 17H4V19H17.7616V17ZM20 5C20.5523 5 21 5.44772 21 6H23C23 4.34315 21.6569 3 20 3V5ZM3 6C3 5.44772 3.44772 5 4 5V3C2.34315 3 1 4.34315 1 6H3ZM1 16C1 17.6569 2.34315 19 4 19V17C3.44772 17 3 16.5523 3 16H1ZM6 22H18V20H6V22ZM9.5 5H20V3H9.5V5Z"
      fill="currentColor"
    />
  ),
});
