/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkRemoveGroups_DisplayFragment = { __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type BulkRemoveGroupsMutationVariables = Types.Exact<{
  input: Types.DisplayBulkRemoveGroupsInput;
}>;


export type BulkRemoveGroupsMutation = { __typename: 'Mutation', displayBulkRemoveGroups: { __typename: 'DisplayBulkRemoveGroupsPayload', displays: Array<{ __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> }> } };

export const BulkRemoveGroups_DisplayFragmentDoc = gql`
    fragment BulkRemoveGroups_display on Display {
  id
  groups {
    id
    name
  }
}
    `;
export const BulkRemoveGroupsDocument = gql`
    mutation BulkRemoveGroups($input: DisplayBulkRemoveGroupsInput!) {
  displayBulkRemoveGroups(input: $input) {
    displays {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type BulkRemoveGroupsMutationFn = ApolloReactCommon.MutationFunction<BulkRemoveGroupsMutation, BulkRemoveGroupsMutationVariables>;

/**
 * __useBulkRemoveGroupsMutation__
 *
 * To run a mutation, you first call `useBulkRemoveGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkRemoveGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkRemoveGroupsMutation, { data, loading, error }] = useBulkRemoveGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkRemoveGroupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkRemoveGroupsMutation, BulkRemoveGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkRemoveGroupsMutation, BulkRemoveGroupsMutationVariables>(BulkRemoveGroupsDocument, options);
      }
export type BulkRemoveGroupsMutationHookResult = ReturnType<typeof useBulkRemoveGroupsMutation>;
export type BulkRemoveGroupsMutationResult = ApolloReactCommon.MutationResult<BulkRemoveGroupsMutation>;
export type BulkRemoveGroupsMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkRemoveGroupsMutation, BulkRemoveGroupsMutationVariables>;