import { ReactNode, useEffect } from 'react';
import { Helmet, HelmetProps } from 'react-helmet-async';
import { useAnalyticsReporter, usePageAnalytics } from '~utils/analytics';

type Props = HelmetProps & {
  children: ReactNode;
  pageName: string;
  analyticsDisableTracking?: boolean;
};

export function Page({
  children,
  pageName,
  analyticsDisableTracking = false,
  ...helmetProps
}: Props) {
  const { setPageAttributes } = usePageAnalytics();
  const analytics = useAnalyticsReporter();

  useEffect(() => {
    setPageAttributes({
      name: pageName,
      disableTracking: analyticsDisableTracking,
    });
    // Excluding `setPageAttributes` to prevent unneeded re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, analyticsDisableTracking]);

  useEffect(() => {
    analytics.track('pageView', { pageName });
    // Excluding `analytics` to prevent unneeded re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, analyticsDisableTracking]);

  return (
    <>
      <Helmet {...helmetProps} />
      {children}
    </>
  );
}
