import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { TableInstance } from 'react-table';
import { DisplayTable_DisplayFragment } from './__generated__/DisplayTable.graphql';

export const DisplayTableContext = createContext<{
  table: TableInstance<DisplayTable_DisplayFragment> | null;
  setTable?: Dispatch<SetStateAction<TableInstance<DisplayTable_DisplayFragment> | null>>;
} | null>(null);

export const DisplayTableProvider = ({ children }: { children: ReactNode }) => {
  const [table, setTable] = useState<TableInstance<DisplayTable_DisplayFragment> | null>(null);

  return (
    <DisplayTableContext.Provider value={{ table, setTable }}>
      {children}
    </DisplayTableContext.Provider>
  );
};
