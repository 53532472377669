import { chain } from 'lodash';
import { useMemo } from 'react';
import { Filters } from 'react-table';
import { Columns, FilterOption, FilterOptions } from '~components/displays/DisplayTable/constants';
import { useDisplayPresence } from '~components/displays/useDisplayPresence';
import { useStatus } from '~components/displays/useStatus';
import { isDefined } from '~utils/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';

export function useFilterOptions(
  filters: Filters<DisplayTable_DisplayFragment>,
  data: readonly DisplayTable_DisplayFragment[],
): FilterOptions {
  const filterMap = new Map(filters.map(({ id, value }) => [id, value]));

  const enabledGroupFilters: FilterOption[] = filterMap.get('groups') ?? [];
  const enabledSiteFilters: FilterOption[] = filterMap.get('site') ?? [];
  const enabledFirmwareFilters: FilterOption[] = filterMap.get('firmware') ?? [];
  const enabledPlaylistFilters: FilterOption[] = filterMap.get('playlist') ?? [];
  const enabledPowerScheduleFilters: FilterOption[] = filterMap.get('powerSchedule') ?? [];
  const enabledWarningsFilters: FilterOption[] = filterMap.get('warnings') ?? [];
  const enabledStatusFilters: FilterOption[] = filterMap.get('status') ?? [];

  const groupFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .map((row) => row.groups)
      .flatten()
      .uniqBy((group) => group.id)
      .sortBy((g) => g.name.toLowerCase())
      .map(
        (group): FilterOption => ({
          value: group.id,
          label: group.name,
          column: Columns.Groups,
        }),
      )
      .value();
  }, [data]);

  const siteFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .map((row) => row.site)
      .filter(isDefined)
      .uniqBy((site) => site.id)
      .sortBy((l) => l.name.toLowerCase())
      .map(
        (site): FilterOption => ({
          value: site.id,
          label: site.name,
          column: Columns.Site,
        }),
      )
      .value();
  }, [data]);

  const firmwareFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .map((row) => row.firmware?.android.version)
      .filter(isDefined)
      .uniq()
      .sortBy((f) => f.toLowerCase())
      .map(
        (firmware): FilterOption => ({
          value: firmware,
          label: firmware,
          column: Columns.Firmware,
        }),
      )
      .value();
  }, [data]);

  const playlistFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .map((row) => row.playlist?.current?.title)
      .filter(isDefined)
      .uniq()
      .sortBy((title) => title)
      .map(
        (title): FilterOption => ({
          value: title,
          label: title,
          column: Columns.Playlist,
        }),
      )
      .value();
  }, [data]);

  const powerScheduleFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .map((row) => row.powerSchedule?.schedule?.title)
      .filter(isDefined)
      .uniq()
      .sortBy((title) => title)
      .map(
        (title): FilterOption => ({
          value: title,
          label: title,
          column: Columns.PowerSchedule,
        }),
      )
      .value();
  }, [data]);

  const { getStatus } = useStatus();
  const warningsFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .flatMap((row) => {
        const status = getStatus(row);
        if (status.kind === 'warnings') {
          return status.warnings.map(({ message }) => message);
        } else if (status.kind === 'error') {
          return status.errorMessage;
        } else {
          return 'No warnings';
        }
      })
      .filter(isDefined)
      .uniq()
      .sortBy((title) => title)
      .map(
        (status): FilterOption => ({
          value: status,
          label: status,
          column: Columns.Warnings,
        }),
      )
      .value();
  }, [data, getStatus]);

  const { getPresence, getPowerState } = useDisplayPresence();
  const statusFilters = useMemo<FilterOption[]>(() => {
    return chain(data)
      .flatMap((row) => {
        const presence = getPresence(row);
        const powerState = getPowerState(row);
        return [
          presence.isConnected ? 'Connected' : 'Disconnected',
          powerState.isPoweredOn ? 'Powered on' : 'Standby',
        ];
      })
      .filter(isDefined)
      .uniq()
      .sortBy((status) => status)
      .map(
        (status): FilterOption => ({
          value: status,
          label: status,
          column: Columns.Status,
        }),
      )
      .value();
  }, [data, getPresence, getPowerState]);

  return {
    [Columns.Firmware]: {
      all: firmwareFilters,
      enabled: enabledFirmwareFilters,
    },
    [Columns.Groups]: {
      all: groupFilters,
      enabled: enabledGroupFilters,
    },
    [Columns.Site]: {
      all: siteFilters,
      enabled: enabledSiteFilters,
    },
    [Columns.Playlist]: {
      all: playlistFilters,
      enabled: enabledPlaylistFilters,
    },
    [Columns.PowerSchedule]: {
      all: powerScheduleFilters,
      enabled: enabledPowerScheduleFilters,
    },
    [Columns.Warnings]: {
      all: warningsFilters,
      enabled: enabledWarningsFilters,
    },
    [Columns.Status]: {
      all: statusFilters,
      enabled: enabledStatusFilters,
    },
  };
}
