import { createIcon } from '@chakra-ui/react';

export const VolumeLowIcon = createIcon({
  displayName: 'VolumeLowIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M16.2472 7.753C15.8567 7.36242 15.2236 7.36232 14.833 7.75279C14.4424 8.14325 14.4423 8.77642 14.8328 9.167L16.2472 7.753ZM14.8328 14.823C14.4423 15.2136 14.4424 15.8467 14.833 16.2372C15.2236 16.6277 15.8567 16.6276 16.2472 16.237L14.8328 14.823ZM11 5H12C12 4.61559 11.7797 4.26522 11.4332 4.0987C11.0867 3.93218 10.6755 3.979 10.3753 4.21913L11 5ZM6 9V10C6.22707 10 6.44738 9.92272 6.62469 9.78087L6 9ZM2 9V8C1.44772 8 1 8.44772 1 9H2ZM2 15H1C1 15.5523 1.44772 16 2 16V15ZM6 15L6.62469 14.2191C6.44738 14.0773 6.22707 14 6 14V15ZM11 19L10.3753 19.7809C10.6755 20.021 11.0867 20.0678 11.4332 19.9013C11.7797 19.7348 12 19.3844 12 19H11ZM14.8328 9.167C15.5827 9.91711 16.0039 10.9343 16.0039 11.995H18.0039C18.0039 10.404 17.372 8.87817 16.2472 7.753L14.8328 9.167ZM16.0039 11.995C16.0039 13.0557 15.5827 14.0729 14.8328 14.823L16.2472 16.237C17.372 15.1118 18.0039 13.586 18.0039 11.995H16.0039ZM10.3753 4.21913L5.3753 8.21913L6.62469 9.78087L11.6247 5.78087L10.3753 4.21913ZM6 8H2V10H6V8ZM1 9V15H3V9H1ZM2 16H6V14H2V16ZM5.3753 15.7809L10.3753 19.7809L11.6247 18.2191L6.62469 14.2191L5.3753 15.7809ZM12 19V5H10V19H12Z"
      fill="currentColor"
    />
  ),
});
