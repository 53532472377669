import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { fromError } from '~utils/errors';
import { useRemovePlaylistFromDisplaysMutation } from './__generated__/useRemovePlaylistFromDisplays.graphql';

export function useRemovePlaylistFromDisplays() {
  const toast = useToast();
  const [removePlaylistFromDisplaysMutation, { loading }] = useRemovePlaylistFromDisplaysMutation();

  const removePlaylistFromDisplays = useCallback(
    async (playlistId: string) => {
      try {
        await removePlaylistFromDisplaysMutation({
          variables: {
            input: {
              playlistId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot remove playlist from displays',
          description: fromError(err, 'UpdatePlaylist'),
        });
      }
    },
    [removePlaylistFromDisplaysMutation, toast],
  );

  return { removePlaylistFromDisplays, loading };
}

useRemovePlaylistFromDisplays.graphql = {
  fragments: {
    useRemovePlaylistFromDisplays_playlist: gql`
      fragment useRemovePlaylistFromDisplays_playlist on Playlist {
        id
        removingDisplays: displays(filter: { state: REMOVING }) {
          id
        }
      }
    `,
  },
  mutations: {
    RemovePlaylistFromDisplays: gql`
      mutation RemovePlaylistFromDisplays($input: PlaylistRemoveDisplaysInput!) {
        playlistRemoveDisplays(input: $input) {
          displays {
            id
            playlist {
              current {
                id
                title
              }
            }
          }
        }
      }
    `,
  },
};
