import { gql } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useAnalyticsReporter } from '~utils/analytics';
import { getId } from '~utils/data';
import { fromError } from '~utils/errors';
import {
  DeletePlaylistsModal_PlaylistFragment,
  useDeletePlaylistsMutation,
} from './__generated__/DeletePlaylistsModal.graphql';

interface Props {
  playlists: DeletePlaylistsModal_PlaylistFragment[];
  isOpen: boolean;
  onCancel: () => void;
  onSuccess?: () => Promise<void> | void;
}

export function DeletePlaylistsModal({ playlists, isOpen, onCancel, onSuccess }: Props) {
  const isPlaylistUsedByDisplays = playlists.some((playlist) => playlist.syncedDisplays.length > 0);
  const toast = useToast();
  const [deletePlaylists] = useDeletePlaylistsMutation();

  const handleClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const analytics = useAnalyticsReporter();
  const handleDelete = useCallback(async () => {
    try {
      await deletePlaylists({
        variables: {
          input: {
            playlistIds: playlists.map(getId),
          },
        },
      });

      if (playlists.length === 1) {
        analytics.track('playlistDelete');
      } else {
        analytics.track('displayBulkActionComplete', {
          action: 'deletePlaylists',
          displayCount: playlists.length,
        });
      }
      toast({
        status: 'success',
        title: playlists.length === 1 ? 'Playlist deleted' : `Playlists deleted`,
        description:
          playlists.length === 1
            ? 'The playlist has been successfully deleted.'
            : 'The playlists have been successfully deleted.',
      });

      await onSuccess?.();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot delete the playlists',
        description: fromError(err, 'DeletePlaylists', {
          PLAYLIST_NOT_FOUND: 'Looks like the playlist you are trying to delete cannot be found.',
          PLAYLIST_IN_USE_ERROR:
            'It looks like the playlist you are trying to delete is being used by a display.',
        }),
      });
    }
  }, [analytics, deletePlaylists, onSuccess, playlists, toast]);

  const isPlaylistUsedByManyDisplays = playlists.length > 1;

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />

      {isPlaylistUsedByDisplays ? (
        <ModalContent>
          <ModalHeader>
            {isPlaylistUsedByManyDisplays ? 'Cannot delete playlists' : 'Cannot delete playlist'}
          </ModalHeader>
          <ModalCloseButton tabIndex={5} />
          <ModalBody>
            <Text>
              {`We cannot delete ${
                isPlaylistUsedByManyDisplays
                  ? 'these playlists, since their are still used'
                  : 'this playlist, since it is still used'
              } by some displays. Please make sure to remove ${
                isPlaylistUsedByManyDisplays ? 'these playlists' : 'this playlist'
              } from all the displays before deleting ${
                isPlaylistUsedByManyDisplays ? 'them' : 'it'
              }.`}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="solid" colorScheme="blue">
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader>{playlists.length > 1 ? 'Delete playlists' : 'Delete playlist'}</ModalHeader>
          <ModalCloseButton tabIndex={5} />
          <ModalBody>
            <Text>
              {`Are you sure you want to delete ${
                playlists.length > 1 ? 'these playlists?' : 'this playlist?'
              }`}
            </Text>
            <Text color={'gray.600'} marginTop="6">
              This action cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="ghost" colorScheme="blue">
              Cancel
            </Button>
            <Button variant="solid" colorScheme="red" marginLeft="3" onClick={handleDelete}>
              {playlists.length > 1 ? 'Delete playlists' : 'Delete playlist'}
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}

DeletePlaylistsModal.graphql = {
  fragments: {
    DeletePlaylistsModal_playlist: gql`
      fragment DeletePlaylistsModal_playlist on Playlist {
        id
        syncedDisplays: displays(filter: { state: SYNCED }) {
          id
        }
      }
    `,
  },
  mutations: {
    DeletePlaylists: gql`
      mutation DeletePlaylists($input: PlaylistsDeleteInput!) {
        playlistsDelete(input: $input) {
          playlistIds
        }
      }
    `,
  },
};
