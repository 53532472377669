import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import { MaybePromise } from '~utils/types';
import { DeleteUser_UserFragment, useDeleteUserMutation } from './__generated__/DeleteUser.graphql';

gql`
  mutation DeleteUser($input: UserDeleteInput!) {
    userDelete(input: $input) {
      userId
    }
  }
`;

gql`
  fragment DeleteUser_user on User {
    id
    givenName
    familyName
  }
`;

interface UseDeleteUserProps {
  onCompleted?: () => MaybePromise<void>;
}

export function useDeleteUser({ onCompleted }: UseDeleteUserProps) {
  const toast = useToast();

  const [deleteUser] = useDeleteUserMutation();
  const deleteUserAction = useDestructiveAction<DeleteUser_UserFragment>({
    title: 'Delete User',
    message: (user) => (
      <>
        Are you sure you want to delete <strong>{`${user?.givenName} ${user.familyName}`}</strong>?
      </>
    ),
    onConfirm: async (user) => {
      try {
        await deleteUser({
          variables: {
            input: {
              userId: user.id,
            },
          },
        });
        await onCompleted?.();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot delete user',
          description: fromError(err, 'DeleteUser', {
            ORGANIZATION_CANNOT_DELETE_OWNER: 'This user is owner and cannot be deleted.',
            UNAUTHORIZED: 'You are not authorized to delete this user.',
          }),
        });
      }
    },
  });

  return {
    mutation: deleteUser,
    action: deleteUserAction,
  };
}
