import { Box, Center, Link as ChakraLink, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { ExternalLinkIcon } from '~components/ui/icons';
import { Link } from '~components/ui/Link';
import { useTheme } from '~components/ui/styles/hooks';

const ANIMATION_DURATION_IN_MS = 300;

interface MenuBackdropProps {
  isOpen: boolean;
  width: number;
  children: ReactNode;
  right?: boolean;
}

export function MenuBackdrop({ width, isOpen, children, right }: MenuBackdropProps) {
  const theme = useTheme();
  const responsiveWidth = useBreakpointValue({ base: '300px', md: width });

  return (
    <Box
      position="absolute"
      top="100%"
      zIndex="sticky"
      marginTop="4"
      {...{ [right ? 'right' : 'left']: '-3' }}
    >
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            style={{
              position: 'relative',
              overflow: 'hidden',
              display: 'block',
              borderRadius: theme.radii.base,
              width: `${responsiveWidth}px`,
              background: 'white',
              boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
            }}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                height: 'auto',
                opacity: 1,
              },
              collapsed: {
                height: 0,
                opacity: 0,
              },
            }}
            transition={{
              type: 'spring',
              bounce: 0.25,
              duration: ANIMATION_DURATION_IN_MS / 1000,
            }}
          >
            <Box role="dialog" background="white" borderRadius="base" padding="3" paddingTop="1">
              {children}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}

export function MenuItem({
  to,
  onClick,
  icon,
  children,
  isExternal,
  danger,
  iconWidth = '48px',
}: {
  to: string;
  onClick: () => void;
  icon: JSX.Element;
  children: string;
  isExternal?: boolean;
  danger?: boolean;
  iconWidth?: string;
}) {
  const LinkComponent = isExternal ? ChakraLink : Link;

  return (
    <LinkComponent
      to={to}
      role="group"
      marginTop="2"
      display="flex"
      alignItems="center"
      padding="2"
      paddingRight="4"
      height="14"
      cursor="pointer"
      _hover={{
        background: danger ? 'red.50' : 'blue.50',
      }}
      color="gray.900"
      fontWeight="semibold"
      fontSize="sm"
      borderRadius="base"
      {...(isExternal
        ? {
            href: to,
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {
            onClick,
            delayBeforeNavigating: ANIMATION_DURATION_IN_MS,
          })}
    >
      <Center width={iconWidth} height={iconWidth}>
        {icon}
      </Center>
      <Box
        marginLeft="4"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        color={danger ? 'red.400' : 'gray.900'}
        fontWeight="semibold"
        fontSize="sm"
      >
        {children}
      </Box>
      {isExternal && (
        <>
          <Spacer />
          <ExternalLinkIcon color="gray.200" ml="16" width="18px" height="18px" />
        </>
      )}
    </LinkComponent>
  );
}
