import { CloseButtonProps, ModalCloseButton as ChakraModalCloseButton } from '@chakra-ui/react';
import { CloseIcon } from './icons/CloseIcon';

type Props = CloseButtonProps;

export function ModalCloseButton(props: Props) {
  return (
    <ChakraModalCloseButton {...props} height="8" width="8">
      <CloseIcon width="100%" height="100%" />
    </ChakraModalCloseButton>
  );
}
