import { gql } from '@apollo/client';
import { Permission } from '@tp-vision/roles-permissions';
import { intersection } from 'lodash';
import { createContext, ReactNode, useState } from 'react';
import { AuthorizationProvider_OrganizationFragment } from './__generated__/AuthorizationProvider.graphql';
import { useAuthentication } from './useAuthentication';

interface AuthorizationContextProps {
  organization?: AuthorizationProvider_OrganizationFragment | null;
  setOrganization: (organization: AuthorizationProvider_OrganizationFragment) => void;
  verifyUserPermissions: (permissions: Permission[]) => boolean;
}

export const AuthorizationContext = createContext<AuthorizationContextProps | null>(null);

export function AuthorizationProvider({ children }: { children: ReactNode }) {
  const {
    user: { permissions: userPermissions = [] },
  } = useAuthentication();

  const [organization, setOrganization] =
    useState<AuthorizationProvider_OrganizationFragment | null>(null);

  const verifyUserPermissions = (permissions: Permission[]) =>
    intersection(userPermissions, permissions).length > 0;

  const ctx: AuthorizationContextProps = {
    verifyUserPermissions,
    organization,
    setOrganization,
  };

  return <AuthorizationContext.Provider value={ctx}>{children}</AuthorizationContext.Provider>;
}

AuthorizationProvider.graphql = {
  fragments: gql`
    fragment AuthorizationProvider_organization on Organization {
      id
      name
      handle
      developerAccess
    }
  `,
};
