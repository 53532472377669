/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DangerZoneSection_DisplayFragment = { __typename: 'Display', id: string, alias?: string | null, serialNumber: string, hasSensitiveData: boolean, agentReleaseChannel?: { __typename: 'AgentReleaseChannel', desired?: Types.ReleaseChannel | null, reported: Types.ReleaseChannel } | null, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', id: string } | { __typename: 'AppInstallationInstalling', id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', id: string } | { __typename: 'AppInstallationUpdating', id: string } | { __typename: 'AppUninstallationFailed', id: string } | null }> };

export type ClearDataMutationVariables = Types.Exact<{
  input: Types.DisplayBulkClearDataInput;
}>;


export type ClearDataMutation = { __typename: 'Mutation', displayBulkClearData: { __typename: 'DisplayBulkClearDataPayload', displays: Array<{ __typename: 'Display', id: string, hasSensitiveData: boolean }> } };

export type UnclaimDisplayMutationVariables = Types.Exact<{
  input: Types.DisplayUnclaimInput;
}>;


export type UnclaimDisplayMutation = { __typename: 'Mutation', displayUnclaim: { __typename: 'DisplayUnclaimPayload', customer: { __typename: 'Customer', id: string } } };

export type UpdateAgentReleaseChannelMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateAgentReleaseChannelInput;
}>;


export type UpdateAgentReleaseChannelMutation = { __typename: 'Mutation', displayBulkUpdateAgentReleaseChannel: { __typename: 'DisplayBulkUpdateAgentReleaseChannelPayload', displays: Array<{ __typename: 'Display', id: string }> } };

export const DangerZoneSection_DisplayFragmentDoc = gql`
    fragment DangerZoneSection_display on Display {
  id
  alias
  serialNumber
  hasSensitiveData
  agentReleaseChannel {
    desired
    reported
  }
  appSubscriptions {
    appInstallation {
      id
    }
  }
}
    `;
export const ClearDataDocument = gql`
    mutation ClearData($input: DisplayBulkClearDataInput!) {
  displayBulkClearData(input: $input) {
    displays {
      id
      hasSensitiveData
    }
  }
}
    `;
export type ClearDataMutationFn = ApolloReactCommon.MutationFunction<ClearDataMutation, ClearDataMutationVariables>;

/**
 * __useClearDataMutation__
 *
 * To run a mutation, you first call `useClearDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDataMutation, { data, loading, error }] = useClearDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearDataMutation, ClearDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ClearDataMutation, ClearDataMutationVariables>(ClearDataDocument, options);
      }
export type ClearDataMutationHookResult = ReturnType<typeof useClearDataMutation>;
export type ClearDataMutationResult = ApolloReactCommon.MutationResult<ClearDataMutation>;
export type ClearDataMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearDataMutation, ClearDataMutationVariables>;
export const UnclaimDisplayDocument = gql`
    mutation UnclaimDisplay($input: DisplayUnclaimInput!) {
  displayUnclaim(input: $input) {
    customer {
      id
    }
  }
}
    `;
export type UnclaimDisplayMutationFn = ApolloReactCommon.MutationFunction<UnclaimDisplayMutation, UnclaimDisplayMutationVariables>;

/**
 * __useUnclaimDisplayMutation__
 *
 * To run a mutation, you first call `useUnclaimDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnclaimDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unclaimDisplayMutation, { data, loading, error }] = useUnclaimDisplayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnclaimDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnclaimDisplayMutation, UnclaimDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnclaimDisplayMutation, UnclaimDisplayMutationVariables>(UnclaimDisplayDocument, options);
      }
export type UnclaimDisplayMutationHookResult = ReturnType<typeof useUnclaimDisplayMutation>;
export type UnclaimDisplayMutationResult = ApolloReactCommon.MutationResult<UnclaimDisplayMutation>;
export type UnclaimDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<UnclaimDisplayMutation, UnclaimDisplayMutationVariables>;
export const UpdateAgentReleaseChannelDocument = gql`
    mutation UpdateAgentReleaseChannel($input: DisplayBulkUpdateAgentReleaseChannelInput!) {
  displayBulkUpdateAgentReleaseChannel(input: $input) {
    displays {
      id
    }
  }
}
    `;
export type UpdateAgentReleaseChannelMutationFn = ApolloReactCommon.MutationFunction<UpdateAgentReleaseChannelMutation, UpdateAgentReleaseChannelMutationVariables>;

/**
 * __useUpdateAgentReleaseChannelMutation__
 *
 * To run a mutation, you first call `useUpdateAgentReleaseChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentReleaseChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentReleaseChannelMutation, { data, loading, error }] = useUpdateAgentReleaseChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgentReleaseChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAgentReleaseChannelMutation, UpdateAgentReleaseChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAgentReleaseChannelMutation, UpdateAgentReleaseChannelMutationVariables>(UpdateAgentReleaseChannelDocument, options);
      }
export type UpdateAgentReleaseChannelMutationHookResult = ReturnType<typeof useUpdateAgentReleaseChannelMutation>;
export type UpdateAgentReleaseChannelMutationResult = ApolloReactCommon.MutationResult<UpdateAgentReleaseChannelMutation>;
export type UpdateAgentReleaseChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAgentReleaseChannelMutation, UpdateAgentReleaseChannelMutationVariables>;