import { gql } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  EditPlaylistModal_PlaylistFragment,
  usePlaylistEditMutation,
} from './__generated__/EditPlaylistModal.graphql';

interface Props {
  playlist: EditPlaylistModal_PlaylistFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (update: EditPlaylistModal_PlaylistFragment) => Promise<void> | void;
}

const schema = z.object({
  playlistId: z.string(),
  title: z.string(),
  description: z.string().nullish(),
});

type FormValues = z.infer<typeof schema>;

export function EditPlaylistModal({ isOpen, onCancel, onSuccess, playlist }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <EditPlaylistModalContent
          playlist={playlist}
          initialFocusRef={initialFocusRef}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

type ContentProps = {
  playlist: EditPlaylistModal_PlaylistFragment;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => void;
  onSuccess: (update: EditPlaylistModal_PlaylistFragment) => Promise<void> | void;
};

function EditPlaylistModalContent({
  onSuccess,
  onCancel,
  initialFocusRef,
  playlist,
}: ContentProps) {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      playlistId: playlist.id,
      title: playlist.title,
      description: playlist.description,
    },
    resolver: zodResolver(schema),
  });
  const toast = useToast();
  const [editPlaylist] = usePlaylistEditMutation();
  const { ref: titleInputRef, ...titleInputProps } = register('title');
  const { ref: descriptionInputRef, ...descriptionInputProps } = register('description');
  const analytics = useAnalyticsReporter();
  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const { data } = await editPlaylist({
          variables: {
            input: {
              playlistId: values.playlistId,
              title: values.title,
              description: values.description,
            },
          },
        });

        if (!data) {
          throw new Error('no data received');
        }

        analytics.track('playlistUpdate');
        await onSuccess?.(data.playlistUpdate.playlist);
        reset();

        toast({
          status: 'success',
          title: 'Playlist edited',
          description: 'The playlist has been edited.',
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot edit the playlist',
          description: fromError(err, 'EditPlaylist'),
        });
      }
    },
    [analytics, editPlaylist, onSuccess, reset, toast],
  );

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Edit playlist</ModalHeader>
      <ModalCloseButton tabIndex={5} />

      <ModalBody>
        <VStack spacing="4">
          <FormControl isInvalid={Boolean(errors.title)} marginBottom={3}>
            <FormLabel>Playlist title</FormLabel>
            <Input
              placeholder="My playlist"
              ref={(r) => {
                titleInputRef(r);
                initialFocusRef.current = r;
              }}
              {...titleInputProps}
            />
            <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.description)}>
            <FormLabel>Description</FormLabel>
            <Input
              placeholder="Some description"
              ref={(r) => {
                descriptionInputRef(r);
                initialFocusRef.current = r;
              }}
              {...descriptionInputProps}
            />

            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleClose} variant="ghost" colorScheme="blue" isDisabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="solid"
          colorScheme="blue"
          marginLeft="3"
          type="submit"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
}

EditPlaylistModal.graphql = {
  fragments: {
    EditPlaylistModal_playlist: gql`
      fragment EditPlaylistModal_playlist on Playlist {
        id
        title
        description
      }
    `,
  },
  mutations: {
    PlaylistEdit: gql`
      mutation PlaylistEdit($input: PlaylistUpdateInput!) {
        playlistUpdate(input: $input) {
          playlist {
            id
            ...EditPlaylistModal_playlist
          }
        }
      }
    `,
  },
};
