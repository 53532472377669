import { Box, chakra, PinInput, PinInputField, Spinner, UsePinInputProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { createRef, forwardRef, Ref, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { SuccessIcon, WarningIcon } from '~components/ui/icons';
import { useTheme } from '~components/ui/styles/hooks';
import { useCombinedRefs } from '~utils/useCombinedRefs';

type Props = Pick<UsePinInputProps, 'value' | 'onChange' | 'placeholder'> & {
  error?: string;
  isComplete?: boolean;
  isLoading?: boolean;
  length: number;
};

export const DisplayClaimCodeInput = forwardRef(function DisplayClaimCodeInput(
  { length, value, placeholder, error, isLoading = false, isComplete = false, onChange }: Props,
  ref: Ref<HTMLInputElement>,
) {
  const theme = useTheme();
  const refs = useMemo(
    () =>
      Array(length)
        .fill(0)
        .map(() => createRef<HTMLInputElement>()),
    [length],
  );
  const firstInputRef = useCombinedRefs(refs[0], ref);
  const [activeField, setActiveField] = useState<number | null>(null);

  const handleFieldFocus = useCallback(
    (index: number) => () => {
      setActiveField(index);
    },
    [],
  );

  const handleFieldBlur = useCallback(() => {
    setActiveField(null);
  }, []);

  const [animate, setAnimate] = useState({ x: 0, width: '0px', opacity: 0 });
  useLayoutEffect(() => {
    if (isComplete) {
      setAnimate({ x: 0, width: '100%', opacity: 1 });
      return;
    }

    if (_.isNil(activeField)) {
      setAnimate((anim) => ({ ...anim, opacity: 0 }));
      return;
    }

    const ref = refs[activeField];
    if (!ref.current) return;

    setAnimate({
      x: ref.current.offsetLeft,
      // NOTE:
      // using ref.current.getBoundingClientRect().width is off by 2px on the first measure.
      // setting the width of 1 input manually seems more stable.
      // needs to be updated when the style of a single input changes (making it biggre or smaller)
      width: '40px',
      opacity: 1,
    });
  }, [activeField, isComplete, refs]);

  return (
    <Box display="flex" alignItems="center">
      <Box position="relative" display="flex" flexDirection="row" width="fit-content">
        <PinInput value={value} placeholder={placeholder} onChange={onChange}>
          {refs.map((ref, i) => (
            <PinInputField
              ref={i === 0 ? firstInputRef : ref}
              key={`display-code-input-${i}`}
              onFocus={handleFieldFocus(i)}
              onBlur={handleFieldBlur}
              color={isComplete ? 'blue.400' : error ? 'red.400' : 'blue.800'}
              border="none"
              borderTop="1px solid"
              borderTopColor={isComplete ? 'blue.200' : error ? 'red.200' : 'gray.200'}
              borderBottom="1px solid"
              borderBottomColor={isComplete ? 'blue.200' : error ? 'red.200' : 'gray.200'}
              borderRight="1px solid"
              borderRightColor={isComplete ? 'blue.200' : error ? 'red.200' : 'gray.200'}
              borderRadius="none"
              sx={{
                '&:first-of-type': {
                  borderLeft: '1px solid',
                  borderLeftColor: isComplete ? 'blue.200' : error ? 'red.200' : 'gray.200',
                  borderTopLeftRadius: 'base',
                  borderBottomLeftRadius: 'base',
                },
                '&:last-of-type': {
                  borderTopRightRadius: 'base',
                  borderBottomRightRadius: 'base',
                },
              }}
              _focus={{
                borderColor: 'inherit',
              }}
              _hover={{
                borderColor: isComplete ? 'blue.200' : error ? 'red.200' : 'gray.200',
              }}
            />
          ))}
        </PinInput>
        <motion.div
          style={{
            userSelect: 'none',
            pointerEvents: 'none',
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100%',
            border: '3px solid',
            borderColor: isComplete
              ? theme.colors.blue[400]
              : error
              ? theme.colors.red[400]
              : theme.colors.blue[500],
            borderRadius: theme.radii.base,
          }}
          animate={animate}
          transition={{
            type: 'spring',
            bounce: 0.2,
            duration: 0.25,
          }}
        />
      </Box>
      <Box marginLeft="4">
        {error ? (
          <Box color="red.400">
            <WarningIcon />
            <chakra.span marginLeft="3" fontSize="xs" fontWeight="semibold">
              {error}
            </chakra.span>
          </Box>
        ) : isLoading ? (
          <Spinner size="sm" color="blue.500" />
        ) : (
          isComplete && (
            <Box color="blue.400">
              <SuccessIcon />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
});
