import { createIcon } from '@chakra-ui/react';

export const DisconnectedIcon = createIcon({
  displayName: 'DisconnectedIcon',
  viewBox: '0 0 24 25',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <>
      <g clipPath="url(#clip0_6447_81545)">
        <path
          d="M17.54 14.04L20.54 11.04C21.4508 10.097 21.9548 8.83397 21.9434 7.52299C21.932 6.21201 21.4061 4.95794 20.4791 4.0309C19.5521 3.10386 18.298 2.57802 16.987 2.56663C15.676 2.55523 14.413 3.05921 13.47 3.97L11.75 5.68M8.24864 9.81021C7.57688 10.0608 6.96687 10.4529 6.45999 10.96L3.45999 13.96C2.5492 14.903 2.04522 16.166 2.05662 17.477C2.06801 18.788 2.59385 20.042 3.52089 20.9691C4.44793 21.8961 5.702 22.422 7.01298 22.4334C8.32396 22.4448 9.58697 21.9408 10.53 21.03L12.24 19.32M1 1.5L23 23.5"
          stroke="#EF4E4E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6447_81545">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});
