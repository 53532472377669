/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { SyncDisplaysModal_PlaylistFragmentDoc } from '../../../__generated__/SyncDisplaysModal.graphql';
import { DeletePlaylistsModal_PlaylistFragmentDoc } from '../../../__generated__/DeletePlaylistsModal.graphql';
import { UseRemovePlaylistFromDisplays_PlaylistFragmentDoc } from '../../../__generated__/useRemovePlaylistFromDisplays.graphql';
export type ActionsCell_PlaylistFragment = { __typename: 'Playlist', id: string, syncingDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }>, syncedDisplays: Array<{ __typename: 'Display', id: string }> };

export const ActionsCell_PlaylistFragmentDoc = gql`
    fragment ActionsCell_playlist on Playlist {
  id
  syncingDisplays: displays(filter: {state: SYNCING}) {
    id
  }
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
  removingDisplays: displays(filter: {state: REMOVING}) {
    id
  }
  allDisplays: displays {
    id
  }
  ...SyncDisplaysModal_playlist
  ...DeletePlaylistsModal_playlist
  ...useRemovePlaylistFromDisplays_playlist
}
    ${SyncDisplaysModal_PlaylistFragmentDoc}
${DeletePlaylistsModal_PlaylistFragmentDoc}
${UseRemovePlaylistFromDisplays_PlaylistFragmentDoc}`;