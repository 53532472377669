import { createIcon } from '@chakra-ui/react';

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11V13ZM5 12L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L5 12ZM11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071C13.0976 19.3166 13.0976 18.6834 12.7071 18.2929L11.2929 19.7071ZM12.7071 5.70711C13.0976 5.31658 13.0976 4.68342 12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L12.7071 5.70711ZM19 11H5V13H19V11ZM12.7071 18.2929L5.70711 11.2929L4.29289 12.7071L11.2929 19.7071L12.7071 18.2929ZM5.70711 12.7071L12.7071 5.70711L11.2929 4.29289L4.29289 11.2929L5.70711 12.7071Z"
      fill="currentColor"
    />
  ),
});
