/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayInfraRedControlToggle_DisplayFragment = { __typename: 'Display', id: string, infraRedControl?: { __typename: 'ControlState', desired?: Types.ControlLockState | null, reported: Types.ControlLockState, supportedValues: Array<Types.ControlLockState> } | null };

export type UpdateInfraRedControlMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateInfraRedControlInput;
}>;


export type UpdateInfraRedControlMutation = { __typename: 'Mutation', displayBulkUpdateInfraRedControl: { __typename: 'DisplayBulkUpdateInfraRedControlPayload', displays: Array<{ __typename: 'Display', id: string, infraRedControl?: { __typename: 'ControlState', desired?: Types.ControlLockState | null, reported: Types.ControlLockState, supportedValues: Array<Types.ControlLockState> } | null }> } };

export const DisplayInfraRedControlToggle_DisplayFragmentDoc = gql`
    fragment DisplayInfraRedControlToggle_display on Display {
  id
  infraRedControl {
    desired
    reported
    supportedValues
  }
}
    `;
export const UpdateInfraRedControlDocument = gql`
    mutation UpdateInfraRedControl($input: DisplayBulkUpdateInfraRedControlInput!) {
  displayBulkUpdateInfraRedControl(input: $input) {
    displays {
      id
      infraRedControl {
        desired
        reported
        supportedValues
      }
    }
  }
}
    `;
export type UpdateInfraRedControlMutationFn = ApolloReactCommon.MutationFunction<UpdateInfraRedControlMutation, UpdateInfraRedControlMutationVariables>;

/**
 * __useUpdateInfraRedControlMutation__
 *
 * To run a mutation, you first call `useUpdateInfraRedControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfraRedControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfraRedControlMutation, { data, loading, error }] = useUpdateInfraRedControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInfraRedControlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInfraRedControlMutation, UpdateInfraRedControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateInfraRedControlMutation, UpdateInfraRedControlMutationVariables>(UpdateInfraRedControlDocument, options);
      }
export type UpdateInfraRedControlMutationHookResult = ReturnType<typeof useUpdateInfraRedControlMutation>;
export type UpdateInfraRedControlMutationResult = ApolloReactCommon.MutationResult<UpdateInfraRedControlMutation>;
export type UpdateInfraRedControlMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInfraRedControlMutation, UpdateInfraRedControlMutationVariables>;