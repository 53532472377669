import { StyleProps } from './types';

function getBgColorByColorScheme(c: StyleProps['colorScheme']) {
  return c === 'blue'
    ? `${c}.700`
    : c === 'orange'
    ? `${c}.300`
    : c === 'red'
    ? `${c}.400`
    : `${c}.500`;
}

function getHoverBgColorByColorScheme(c: StyleProps['colorScheme']) {
  return c === 'blue' ? `${c}.600` : c === 'red' ? `${c}.300` : `${c}.400`;
}

function getActivegColorByColorScheme(c: StyleProps['colorScheme']) {
  return c === 'blue' ? `${c}.800` : `${c}.600`;
}

function getColorByColorScheme(c: StyleProps['colorScheme']) {
  return c === 'blue' ? `${c}.800` : `${c}.500`;
}

export const Button = {
  baseStyle: {
    borderRadius: 'base',
    boxShadow: 'unset',
    _focus: {
      boxShadow: 'unset',
    },
  },
  sizes: {
    sm: {
      height: 10,
      fontSize: 'md',
      paddingX: 4,
    },
    md: {
      pt: 6,
      pb: 6,
      pl: 6,
      pr: 6,
    },
  },
  variants: {
    solid: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        background: getBgColorByColorScheme(c),
        boxShadow: 'lg',
        _hover: {
          background: getHoverBgColorByColorScheme(c),
          _disabled: {
            background: getBgColorByColorScheme(c),
          },
        },
        _active: {
          background: getActivegColorByColorScheme(c),
        },
      };
    },
    ghost: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        color: getColorByColorScheme(c),
        _hover: {
          background: `${c}.50`,
          _disabled: {
            color: getColorByColorScheme(c),
          },
        },
        _active: {
          background: 'unset',
        },
      };
    },
    outline: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        color: c === 'gray' ? `${c}.700` : `${c}.500`,
        background: 'inherit',
        borderRadius: 'base',
        border: '1px solid',
        borderColor: `${c}.200`,
        fontWeight: 'semibold',
        _hover: {
          background: `${c}.50`,
          _disabled: {
            background: 'inherit',
          },
        },
        _active: {
          background: `${c}.100`,
        },
      };
    },
    inline: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        fontSize: 'xs',
        fontWeight: 'semibold',
        textTransform: 'uppercase',
        color: c === 'gray' ? `${c}.600` : `${c}.400`,
        _hover: {
          background: `${c}.50`,
          _disabled: {
            background: undefined,
          },
        },
      };
    },
    link: {
      paddingX: '0',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
