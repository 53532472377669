/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PowerScheduleDetail_CustomerFragmentDoc, PowerScheduleDetail_PowerScheduleFragmentDoc } from '../../../../../../components/powerSchedules/PowerScheduleDetail/__generated__/PowerScheduleDetail.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PowerScheduleDetailsPageQueryVariables = Types.Exact<{
  customerHandle: Types.Scalars['String'];
  powerScheduleId: Types.Scalars['ID'];
}>;


export type PowerScheduleDetailsPageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, name: string } | null, powerSchedule?: { __typename: 'PowerSchedule', id: string, title: string, description?: string | null, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }>, allDisplays: Array<{ __typename: 'Display', id: string }>, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }> } | null };


export const PowerScheduleDetailsPageDocument = gql`
    query PowerScheduleDetailsPage($customerHandle: String!, $powerScheduleId: ID!) {
  customer: customerByHandle(handle: $customerHandle) {
    id
    ...PowerScheduleDetail_customer
  }
  powerSchedule(id: $powerScheduleId) {
    id
    ...PowerScheduleDetail_powerSchedule
  }
}
    ${PowerScheduleDetail_CustomerFragmentDoc}
${PowerScheduleDetail_PowerScheduleFragmentDoc}`;

/**
 * __usePowerScheduleDetailsPageQuery__
 *
 * To run a query within a React component, call `usePowerScheduleDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerScheduleDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerScheduleDetailsPageQuery({
 *   variables: {
 *      customerHandle: // value for 'customerHandle'
 *      powerScheduleId: // value for 'powerScheduleId'
 *   },
 * });
 */
export function usePowerScheduleDetailsPageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PowerScheduleDetailsPageQuery, PowerScheduleDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PowerScheduleDetailsPageQuery, PowerScheduleDetailsPageQueryVariables>(PowerScheduleDetailsPageDocument, options);
      }
export function usePowerScheduleDetailsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PowerScheduleDetailsPageQuery, PowerScheduleDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PowerScheduleDetailsPageQuery, PowerScheduleDetailsPageQueryVariables>(PowerScheduleDetailsPageDocument, options);
        }
export type PowerScheduleDetailsPageQueryHookResult = ReturnType<typeof usePowerScheduleDetailsPageQuery>;
export type PowerScheduleDetailsPageLazyQueryHookResult = ReturnType<typeof usePowerScheduleDetailsPageLazyQuery>;
export type PowerScheduleDetailsPageQueryResult = ApolloReactCommon.QueryResult<PowerScheduleDetailsPageQuery, PowerScheduleDetailsPageQueryVariables>;