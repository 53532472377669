export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      '&::placeholder': {
        color: 'gray.400',
      },
      _readOnly: {
        border: '1px solid gray.300',
        opacity: 0.5,
        background: 'gray.50',
      },
    },
  },
  sizes: {
    md: {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
  },
  variants: {
    outline: () => {
      return {
        field: {
          background: 'white',
          border: '1px solid',
          borderColor: 'gray.200',
          borderRadius: 'base',
          color: 'blue.800',
          _hover: {
            borderColor: 'gray.200',
          },
          _focus: {
            borderWidth: '2px',
            borderColor: 'blue.500',
            boxShadow: 'none',
          },
          _disabled: {
            opacity: '1',
            background: 'gray.75',
          },
        },
        addon: {
          borderColor: 'gray.200',
        },
      };
    },
  },
};
