import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import {
  BulkActionModal_DisplayFragment,
  BulkActionModal_OrganizationFragment,
} from '~components/displays/BulkAction/__generated__/BulkActionModal.graphql';
import { OrganizationAppSubscription } from '~components/displays/BulkAction/BulkManageApps';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import { isDefined, MaybePromise } from '~utils/types';
import {
  useInstallAppMutation,
  useRetryInstallAppMutation,
  useRetryUninstallAppMutation,
  useUninstallAppMutation,
} from './__generated__/useAppSubscriptions.graphql';
import { UseManageAppSubscriptionsForm_DisplayFragment } from './__generated__/useManageAppSubscriptionsForm.graphql';
import { formatDownloadProgress } from './utils';

export type AppSubscription =
  UseManageAppSubscriptionsForm_DisplayFragment['appSubscriptions'][number];
export type InstalledAppSubscription = AppSubscription & {
  appInstallation: NonNullable<AppSubscription['appInstallation']>;
  progressLabel?: string;
  version?: string;
};
export type UnavailableAppSubscription = AppSubscription & { reason?: string };

export type UnavailableOrganizationAppSubscription = OrganizationAppSubscription & {
  reason?: string;
};

const subscriptionHasAvailableSeats = (appSubscription: AppSubscription) => {
  if (appSubscription.usage.max) {
    return appSubscription.usage.current < appSubscription.usage.max;
  }

  return true;
};

const subscriptionHasInstallableVersions = (appSubscription: AppSubscription) => {
  return (appSubscription.appVersions?.length ?? 0) > 0;
};

export interface UninstallAppMutationProps {
  displayId: string;
  appSubscription: {
    id: string;
    name: string;
  };
}

interface UseUninstallAppProps {
  onCompleted: () => MaybePromise<void>;
}

export function useCancelUninstallApp({ onCompleted }: UseUninstallAppProps) {
  const toast = useToast();
  const [uninstallApp] = useUninstallAppMutation();
  const uninstallAppAction = useDestructiveAction<UninstallAppMutationProps>({
    title: 'Cancel installation',
    message: ({ appSubscription }) => (
      <>
        Are you sure you want to cancel the installation of <strong>{appSubscription.name}</strong>?
      </>
    ),
    onConfirm: async ({ displayId, appSubscription }) => {
      try {
        await uninstallApp({
          variables: {
            input: {
              displayId,
              subscriptionId: appSubscription.id,
            },
          },
        });
        await onCompleted?.();
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot cancel the installation',
          description: fromError(error, 'UninstallApp'),
        });
      }
    },
    confirmLabel: 'Cancel installation',
    cancelLabel: 'Not now',
    variant: 'danger',
    notice: 'This action cannot be undone.',
  });

  return {
    mutation: uninstallApp,
    action: uninstallAppAction,
  };
}

export function useUninstallApp({ onCompleted }: UseUninstallAppProps) {
  const toast = useToast();
  const [uninstallApp] = useUninstallAppMutation();
  const uninstallAppAction = useDestructiveAction<UninstallAppMutationProps>({
    title: 'Uninstall application',
    message: ({ appSubscription }) => (
      <>
        Are you sure you want to delete <strong>{appSubscription.name}</strong>?
      </>
    ),
    onConfirm: async ({ displayId, appSubscription }) => {
      try {
        await uninstallApp({
          variables: {
            input: {
              displayId,
              subscriptionId: appSubscription.id,
            },
          },
        });
        await onCompleted?.();
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot uninstall the application',
          description: fromError(error, 'UninstallApp'),
        });
      }
    },
    confirmLabel: 'Uninstall',
    variant: 'danger',
    notice: 'This action cannot be undone.',
  });

  return {
    mutation: uninstallApp,
    action: uninstallAppAction,
  };
}
/**
 * Hook to help transform display subscriptions to a more UI convenient state.
 */
export function useAppSubscriptions() {
  const toast = useToast();
  const [installAppMutation] = useInstallAppMutation();
  const [uninstallAppMutation] = useUninstallAppMutation();
  const [retryInstallAppMutation] = useRetryInstallAppMutation();
  const [retryUninstallAppMutation] = useRetryUninstallAppMutation();

  const getSortedDisplayAppSubscriptions = useCallback(
    (display: UseManageAppSubscriptionsForm_DisplayFragment) => {
      const installedAppSubscriptions: InstalledAppSubscription[] = [];
      const availableAppSubscriptions: AppSubscription[] = [];
      const unavailableAppSubscriptions: UnavailableAppSubscription[] = [];
      const installFailedAppSubscriptions: AppSubscription[] = [];
      const uninstallFailedAppSubscriptions: AppSubscription[] = [];
      const updateAvailableAppSubscriptions: InstalledAppSubscription[] = [];

      for (const appSubscription of display.appSubscriptions) {
        const { appInstallation, ...restAppSubscription } = appSubscription;

        if (isDefined(appInstallation)) {
          let progressLabel: string | undefined = undefined;
          let version = '';

          switch (appInstallation.__typename) {
            case 'AppInstallationInstallPending':
              progressLabel = 'Install pending';
              break;
            case 'AppInstallationUninstallPending':
              progressLabel = 'Uninstall pending';
              break;
            case 'AppInstallationInstalling':
              progressLabel = `Installing (${formatDownloadProgress(
                appInstallation.downloadProgress,
              )})`;
              break;
            case 'AppInstallationUninstalling':
              progressLabel = 'Uninstalling';
              version = appInstallation.versionName;
              break;
            case 'AppInstallationUpdating':
              progressLabel = `Updating (${formatDownloadProgress(
                appInstallation.downloadProgress,
              )})`;
              version = appInstallation.currentVersionCode;
              break;
            case 'AppInstallationInstalled':
              version = appInstallation.versionName;
              break;
          }

          switch (appInstallation.__typename) {
            case 'AppUninstallationFailed':
              uninstallFailedAppSubscriptions.push({
                ...restAppSubscription,
                appInstallation: appInstallation,
              });
              break;
            case 'AppInstallationFailed':
              installFailedAppSubscriptions.push({
                ...restAppSubscription,
                appInstallation: appInstallation,
              });
              break;
            default:
              installedAppSubscriptions.push({
                ...restAppSubscription,
                appInstallation: appInstallation,
                progressLabel,
                version,
              });

              if ((appSubscription.appVersions?.indexOf(version) ?? 0) > 0) {
                updateAvailableAppSubscriptions.push({
                  ...restAppSubscription,
                  appInstallation: appInstallation,
                  progressLabel,
                  version,
                });
              }
              break;
          }
        } else if (!subscriptionHasInstallableVersions(appSubscription)) {
          unavailableAppSubscriptions.push({
            ...appSubscription,
            reason: 'No compatible versions',
          });
        } else if (!subscriptionHasAvailableSeats(appSubscription)) {
          unavailableAppSubscriptions.push({
            ...appSubscription,
            reason: 'No seats left',
          });
        } else {
          availableAppSubscriptions.push(appSubscription);
        }
      }

      return {
        installedAppSubscriptions,
        availableAppSubscriptions,
        unavailableAppSubscriptions,
        installFailedAppSubscriptions,
        uninstallFailedAppSubscriptions,
        updateAvailableAppSubscriptions,
      };
    },
    [],
  );

  const getSortedOrganizationAppSubscriptions = useCallback(
    (
      organization: BulkActionModal_OrganizationFragment,
      displays: BulkActionModal_DisplayFragment[],
    ) => {
      const requiredFreeSeats = displays.length;
      const existingDisplaySeatsBySubscriptionId = displays.reduce<Record<string, number>>(
        (acc, { appSubscriptions }) => {
          for (const { id, appInstallation } of appSubscriptions) {
            if (isDefined(appInstallation)) {
              acc[id] = (acc[id] ?? 0) + 1;
            }
          }

          return acc;
        },
        {},
      );

      const availableAppSubscriptions: OrganizationAppSubscription[] = [];
      const unavailableAppSubscriptions: UnavailableOrganizationAppSubscription[] = [];

      for (const appSubscription of organization.appSubscriptions) {
        const { usage, appVersions } = appSubscription;
        const existingSeatsForAppSubscription =
          existingDisplaySeatsBySubscriptionId[appSubscription.id] ?? 0;

        const hasAppVersions = appVersions.length === 0;
        const isMaxUsageReachedOrExceeded = isDefined(usage.max) && usage.current >= usage.max;
        const isMaxUsageExceededByRequiredSeats =
          isDefined(usage.max) &&
          requiredFreeSeats !== 0 &&
          usage.current + requiredFreeSeats - existingSeatsForAppSubscription > usage.max;

        if (hasAppVersions) {
          unavailableAppSubscriptions.push({
            ...appSubscription,
            reason: 'No compatible versions',
          });
        } else if (isMaxUsageReachedOrExceeded || isMaxUsageExceededByRequiredSeats) {
          unavailableAppSubscriptions.push({
            ...appSubscription,
            reason: 'Not enough seats left',
          });
        } else {
          availableAppSubscriptions.push(appSubscription);
        }
      }

      return {
        availableAppSubscriptions,
        unavailableAppSubscriptions,
      };
    },
    [],
  );

  const installApp = useCallback(
    async (display: UseManageAppSubscriptionsForm_DisplayFragment, subscriptionId: string) => {
      try {
        await installAppMutation({
          variables: {
            input: {
              displayId: display.id,
              subscriptionId,
            },
          },
        });
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot install application',
          description: fromError(error, 'InstallApp'),
        });
      }
    },
    [installAppMutation, toast],
  );

  const uninstallApp = useCallback(
    async (display: UseManageAppSubscriptionsForm_DisplayFragment, subscriptionId: string) => {
      try {
        await uninstallAppMutation({
          variables: {
            input: {
              displayId: display.id,
              subscriptionId,
            },
          },
        });
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot uninstall application',
          description: fromError(error, 'UninstallApp'),
        });
      }
    },
    [uninstallAppMutation, toast],
  );

  const retryAppInstallation = useCallback(
    async (display: UseManageAppSubscriptionsForm_DisplayFragment, subscriptionId: string) => {
      try {
        await retryInstallAppMutation({
          variables: {
            input: {
              displayId: display.id,
              subscriptionId,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            displayRetryRequestAppInstallation: {
              __typename: 'DisplayRetryRequestAppInstallationPayload',
              display: {
                __typename: 'Display',
                id: display.id,
                appSubscriptions: display.appSubscriptions.map((appSubscription) => {
                  if (
                    appSubscription.id === subscriptionId &&
                    appSubscription.appInstallation?.__typename === 'AppInstallationFailed'
                  ) {
                    return {
                      ...appSubscription,
                      appInstallation: {
                        ...appSubscription.appInstallation,
                        __typename: 'AppInstallationInstallPending',
                      },
                      progressLabel: 'App install retry pending',
                    };
                  }
                  return appSubscription;
                }),
              },
            },
          },
        });
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot install application',
          description: fromError(error, 'RetryAppInstallation', {
            displayId: display.id,
            subscriptionId,
          }),
        });
      }
    },
    [retryInstallAppMutation, toast],
  );

  const retryAppUninstallation = useCallback(
    async (display: UseManageAppSubscriptionsForm_DisplayFragment, subscriptionId: string) => {
      try {
        await retryUninstallAppMutation({
          variables: {
            input: {
              displayId: display.id,
              subscriptionId,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            displayRetryRequestAppUninstallation: {
              __typename: 'DisplayRetryRequestAppUninstallationPayload',
              display: {
                __typename: 'Display',
                id: display.id,
                appSubscriptions: display.appSubscriptions.map((appSubscription) => {
                  if (
                    appSubscription.id === subscriptionId &&
                    appSubscription.appInstallation?.__typename === 'AppUninstallationFailed'
                  ) {
                    return {
                      ...appSubscription,
                      appInstallation: {
                        ...appSubscription.appInstallation,
                        __typename: 'AppUninstallationFailed',
                      },
                      progressLabel: 'App uninstall retry pending',
                    };
                  }
                  return appSubscription;
                }),
              },
            },
          },
        });
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot uninstall application',
          description: fromError(error, 'RetryAppUninstallation', {
            displayId: display.id,
            subscriptionId,
          }),
        });
      }
    },
    [retryUninstallAppMutation, toast],
  );

  return {
    getSortedDisplayAppSubscriptions,
    getSortedOrganizationAppSubscriptions,
    installApp,
    uninstallApp,
    retryAppInstallation,
    retryAppUninstallation,
  };
}

useAppSubscriptions.graphql = {
  mutations: {
    InstallApp: gql`
      mutation InstallApp($input: DisplayRequestAppInstallationInput!) {
        displayRequestAppInstallation(input: $input) {
          display {
            id
            appSubscriptions {
              ...DisplaySubscriptions
            }
          }
        }
      }
    `,
    UninstallApp: gql`
      mutation UninstallApp($input: DisplayRequestAppUninstallationInput!) {
        displayRequestAppUninstallation(input: $input) {
          display {
            id
            appSubscriptions {
              ...DisplaySubscriptions
            }
          }
        }
      }
    `,
    RetryInstallApp: gql`
      mutation RetryInstallApp($input: DisplayRetryRequestAppInstallationInput!) {
        displayRetryRequestAppInstallation(input: $input) {
          display {
            id
            appSubscriptions {
              ...DisplaySubscriptions
            }
          }
        }
      }
    `,
    RetryUninstallApp: gql`
      mutation RetryUninstallApp($input: DisplayRetryRequestAppUninstallationInput!) {
        displayRetryRequestAppUninstallation(input: $input) {
          display {
            id
            appSubscriptions {
              ...DisplaySubscriptions
            }
          }
        }
      }
    `,
  },
};
