/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplayInfraRedControlToggle_DisplayFragmentDoc } from './DisplayInfraRedControlToggle.graphql';
import { DisplayKeyboardControlToggle_DisplayFragmentDoc } from './DisplayKeyboardControlToggle.graphql';
import { DisplayPortsControlToggle_DisplayFragmentDoc } from './DisplayPortsControlToggle.graphql';
export type DisplayLockingSection_DisplayFragment = { __typename: 'Display', id: string, infraRedControl?: { __typename: 'ControlState', desired?: Types.ControlLockState | null, reported: Types.ControlLockState, supportedValues: Array<Types.ControlLockState> } | null, keyboardControl?: { __typename: 'ControlState', desired?: Types.ControlLockState | null, reported: Types.ControlLockState, supportedValues: Array<Types.ControlLockState> } | null, portsControl?: { __typename: 'PortsControlState', desired?: Types.PortsControlLockState | null, reported: Types.PortsControlLockState, supportedValues: Array<Types.PortsControlLockState> } | null };

export const DisplayLockingSection_DisplayFragmentDoc = gql`
    fragment DisplayLockingSection_display on Display {
  id
  ...DisplayInfraRedControlToggle_display
  ...DisplayKeyboardControlToggle_display
  ...DisplayPortsControlToggle_display
}
    ${DisplayInfraRedControlToggle_DisplayFragmentDoc}
${DisplayKeyboardControlToggle_DisplayFragmentDoc}
${DisplayPortsControlToggle_DisplayFragmentDoc}`;