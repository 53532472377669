import { createIcon } from '@chakra-ui/react';

export const AlertsIcon = createIcon({
  displayName: 'AlertsIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <>
      <rect x="0.00012207" width="24.0001" height="24.0001" rx="12" fill="#FFEFE6" />
      <path
        d="M6.18867 11.5372L11.5373 6.18855C11.793 5.93293 12.2074 5.93293 12.463 6.18855L17.8117 11.5372C18.0673 11.7928 18.0673 12.2073 17.8117 12.4629L12.463 17.8115C12.2074 18.0672 11.793 18.0672 11.5373 17.8115L6.18867 12.4629C5.93306 12.2073 5.93306 11.7928 6.18867 11.5372Z"
        fill="#E67635"
        stroke="#E67635"
        strokeWidth="1.30909"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0001 9V12.3" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
      <circle cx="12.0001" cy="14.4" r="0.3" fill="white" stroke="white" strokeWidth="0.6" />
    </>
  ),
});
