import gql from 'graphql-tag';
import { PresenceBadge_DisplayFragment } from '~components/displays/__generated__/PresenceBadge.graphql';
import { ConnectedIcon, DisconnectedIcon } from '~components/ui/icons';
import { IconBadge, Props as IconBadgeProps } from '~components/ui/icons/IconBadge';

export default function PresenceBadge({
  display,
  noTooltip,
  variant,
}: {
  display: PresenceBadge_DisplayFragment;
  noTooltip?: boolean;
} & Pick<IconBadgeProps, 'variant'>) {
  const isConnected = Boolean(display?.presence?.connected);

  return isConnected ? (
    <IconBadge
      bg="green.50"
      color="green.400"
      icon={ConnectedIcon}
      tooltip={noTooltip ? undefined : 'Connected'}
      variant={variant}
    />
  ) : (
    <IconBadge
      bg="red.50"
      icon={DisconnectedIcon}
      tooltip={noTooltip ? undefined : 'Disconnected'}
      variant={variant}
    />
  );
}

PresenceBadge.graphql = {
  fragments: {
    PresenceBadge_display: gql`
      fragment PresenceBadge_display on Display {
        id
        presence {
          connected
        }
      }
    `,
  },
};
