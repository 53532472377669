import { gql } from '@apollo/client';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { forwardRef, LegacyRef, MouseEventHandler, PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { useAuth } from '~auth/useAuth';
import { DeletePowerSchedulesModal } from '~components/powerSchedules/DeletePowerSchedulesModal';
import { SyncDisplaysModal } from '~components/powerSchedules/SyncDisplaysModal';
import { useRemovePowerScheduleFromDisplays } from '~components/powerSchedules/useRemovePowerScheduleFromDisplays';
import { LoadingIcon, MoreIcon, NoDisplayIcon, SyncIcon, TrashIcon } from '~components/ui/icons';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { PowerScheduleTable_PowerScheduleFragment } from '../__generated__/PowerScheduleTable.graphql';
import { ActionsCell_PowerScheduleFragment } from './__generated__/ActionsCell.graphql';

export function ActionsCell({
  value,
}: PropsWithChildren<
  CellProps<PowerScheduleTable_PowerScheduleFragment, ActionsCell_PowerScheduleFragment>
>) {
  const deletePowerScheduleModal = useDisclosure();
  const syncDisplaysModal = useDisclosure();
  const { verifyUserPermissions } = useAuth();

  const hasPowerscheduleDeletePermission = verifyUserPermissions([Permission.PowerscheduleDelete]);
  const hasDisplayContentUpdatePermission = verifyUserPermissions([
    Permission.DisplayContentUpdate,
  ]);

  const isRemoving = value.removingDisplays.length > 0;
  const isSyncing = value.syncingDisplays.length > 0;
  const isPowerScheduleUpdatingOnDisplays = isSyncing || isRemoving;
  const syncOperationLabel = 'Update in progress';
  const hasOutOfSyncDisplays = value.outOfSyncDisplays.length > 0;

  const { removePowerScheduleFromDisplays, loading: isInitiatingRemove } =
    useRemovePowerScheduleFromDisplays();

  const handleRemovePowerScheduleFromDisplays = async () => {
    await removePowerScheduleFromDisplays(value.id);
  };

  const handleSyncToDisplaysSuccess = () => {
    syncDisplaysModal.onClose();
  };

  const handleDeletePowerSchedulesSuccess = async () => {
    deletePowerScheduleModal.onClose();
  };

  return (
    <>
      <StopClickPropagation>
        <Flex justifyContent="center">
          {isPowerScheduleUpdatingOnDisplays || isInitiatingRemove ? (
            <Tooltip label={syncOperationLabel}>
              <Flex
                aria-label={syncOperationLabel}
                justifyContent="center"
                alignItems="center"
                height="8"
                minWidth="8"
              >
                <LoadingIcon color="gray.400" height={5} width={5} />
              </Flex>
            </Tooltip>
          ) : (
            <Menu placement="bottom-end">
              <MenuButton as={ActionMenuButton} />
              <MenuList>
                <MenuItem
                  icon={<SyncIcon />}
                  onClick={syncDisplaysModal.onOpen}
                  isDisabled={!hasDisplayContentUpdatePermission || !hasOutOfSyncDisplays}
                >
                  Sync
                </MenuItem>
                <MenuItem
                  icon={<NoDisplayIcon />}
                  onClick={handleRemovePowerScheduleFromDisplays}
                  isDisabled={!hasDisplayContentUpdatePermission || value.allDisplays.length === 0}
                >
                  Remove from displays
                </MenuItem>
                <MenuItem
                  color={'red.500'}
                  icon={<TrashIcon color="red.500" />}
                  onClick={deletePowerScheduleModal.onOpen}
                  isDisabled={!hasPowerscheduleDeletePermission}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </StopClickPropagation>
      <SyncDisplaysModal
        isOpen={syncDisplaysModal.isOpen}
        powerSchedule={value}
        onCancel={syncDisplaysModal.onClose}
        onSuccess={handleSyncToDisplaysSuccess}
      />
      <DeletePowerSchedulesModal
        isOpen={deletePowerScheduleModal.isOpen}
        powerSchedules={[value]}
        onCancel={deletePowerScheduleModal.onClose}
        onSuccess={handleDeletePowerSchedulesSuccess}
      />
    </>
  );
}

const ActionMenuButton = forwardRef(function ActionMenuButton(
  { onClick }: { onClick?: MouseEventHandler<HTMLButtonElement> },
  ref: LegacyRef<HTMLButtonElement>,
) {
  return (
    <IconButton
      ref={ref}
      variant="unstyled"
      height="8"
      minWidth="8"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="gray.200"
      borderRadius="full"
      backgroundColor="white"
      aria-label="playlist actions"
      icon={<MoreIcon color="gray.300" width="actionIconSize" height="actionIconSize" />}
      onClick={onClick}
    />
  );
});

ActionsCell.graphql = {
  fragments: {
    ActionsCell_powerSchedule: gql`
      fragment ActionsCell_powerSchedule on PowerSchedule {
        id
        removingDisplays: displays(filter: { state: REMOVING }) {
          id
        }
        syncingDisplays: displays(filter: { state: SYNCING }) {
          id
        }
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
        allDisplays: displays {
          id
        }
        ...useRemovePowerScheduleFromDisplays_powerSchedule
      }
    `,
  },
};
