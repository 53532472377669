import { createIcon } from '@chakra-ui/react';

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M6 11C6 11.5523 6.44772 12 7 12C7.55228 12 8 11.5523 8 11H6ZM7 7H6H7ZM12 2V1V2ZM16 11C16 11.5523 16.4477 12 17 12C17.5523 12 18 11.5523 18 11H16ZM5 12H19V10H5V12ZM19 12C19.5523 12 20 12.4477 20 13H22C22 11.3431 20.6569 10 19 10V12ZM20 13V20H22V13H20ZM20 20C20 20.5523 19.5523 21 19 21V23C20.6569 23 22 21.6569 22 20H20ZM19 21H5V23H19V21ZM5 21C4.44772 21 4 20.5523 4 20H2C2 21.6569 3.34315 23 5 23V21ZM4 20V13H2V20H4ZM4 13C4 12.4477 4.44772 12 5 12V10C3.34315 10 2 11.3431 2 13H4ZM8 11V7H6V11H8ZM8 7C8 5.93913 8.42143 4.92172 9.17157 4.17157L7.75736 2.75736C6.63214 3.88258 6 5.4087 6 7H8ZM9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3V1C10.4087 1 8.88258 1.63214 7.75736 2.75736L9.17157 4.17157ZM12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157L16.2426 2.75736C15.1174 1.63214 13.5913 1 12 1V3ZM14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7H18C18 5.4087 17.3679 3.88258 16.2426 2.75736L14.8284 4.17157ZM16 7V11H18V7H16Z"
      fill="currentColor"
    />
  ),
});
