/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type AliasCell_DisplayFragment = { __typename: 'Display', id: string, alias?: string | null };

export const AliasCell_DisplayFragmentDoc = gql`
    fragment AliasCell_display on Display {
  id
  alias
}
    `;