import { gql } from '@apollo/client';
import { Box, useDimensions } from '@chakra-ui/react';
import { useRef } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { NAVBAR_HEIGHT } from '~components/customers/Navbar';
import {
  DisplayDetailSettings_CustomerFragment,
  DisplayDetailSettings_DisplayFragment,
} from '~components/displays/DisplayDetail/__generated__/DisplayDetailSettings.graphql';
import DisplayDetailHeader from '~components/displays/DisplayDetail/DisplayDetailHeader';
import {
  DisplayDetailSummary_CustomerFragment,
  DisplayDetailSummary_DisplayFragment,
} from '~components/displays/DisplayDetail/summary/__generated__/DisplayDetailSummary.graphql';
import {
  HorizontalTab,
  HorizontalTabList,
  HorizontalTabPanel,
  TabsUnderline,
} from '~components/ui/HorizontalTabs';
import { PAGE_CONTENT_PADDING_TOP } from '~components/ui/PageContent';
import { useTabs } from '~components/ui/Tabs';
import { MaybePromise } from '~utils/types';
import {
  DisplayDetailView_CustomerFragment,
  DisplayDetailView_DisplayFragment,
} from './__generated__/DisplayDetailView.graphql';

interface Props {
  customer: DisplayDetailView_CustomerFragment;
  display: DisplayDetailView_DisplayFragment;
  refetchData: () => MaybePromise<void>;
  title: string;
}

// Provide (wrapped) context hooks for the tabs to consume, so they can destructure nicely typed properties

export function useDisplayDetailSummaryTab() {
  return useOutletContext<{
    display: DisplayDetailSummary_DisplayFragment;
    customer: DisplayDetailSummary_CustomerFragment;
    refetchData: () => MaybePromise<void>;
  }>();
}

export function useDisplayDetailSettingsTab() {
  return useOutletContext<{
    customer: DisplayDetailSettings_CustomerFragment;
    display: DisplayDetailSettings_DisplayFragment;
    refetchData: () => MaybePromise<void>;
    scrollOffset?: number;
  }>();
}

export function DisplayDetailView({ customer, display, title, refetchData }: Props) {
  const headerRef = useRef<HTMLDivElement>(null);
  const boxModel = useDimensions(headerRef);
  const scrollOffset =
    NAVBAR_HEIGHT + (boxModel?.contentBox.height || 0) + PAGE_CONTENT_PADDING_TOP * 4;

  const { tabs } = useTabs(
    [
      {
        path: 'summary',
        label: 'Summary',
      },
      {
        path: 'settings',
        label: 'Settings',
      },
    ],
    { animateVertical: true },
  );

  return (
    <>
      <Box
        position="sticky"
        top={NAVBAR_HEIGHT}
        marginTop={-1 * PAGE_CONTENT_PADDING_TOP}
        paddingTop={PAGE_CONTENT_PADDING_TOP}
        boxShadow="0 -16px 0 16px #FAFCFF"
        left="0"
        bgColor="blue.25"
        zIndex="sticky"
        ref={headerRef}
      >
        <DisplayDetailHeader display={display} title={title} />
        <HorizontalTabList>
          {tabs.map((tab) => (
            <HorizontalTab key={tab.path} ref={tab.ref} tab={tab} />
          ))}
          <TabsUnderline tabs={tabs} />
        </HorizontalTabList>
      </Box>
      <HorizontalTabPanel>
        {/* Render the routes that are defined in `src/AppRoutes.tsx` */}
        <Outlet context={{ customer, display, refetchData, scrollOffset }} />
      </HorizontalTabPanel>
    </>
  );
}

DisplayDetailView.graphql = {
  fragments: {
    DisplayDetailView_customer: gql`
      fragment DisplayDetailView_customer on Customer {
        id
        ...DisplayDetailSettings_customer
        ...DisplayDetailSummary_customer
      }
    `,
    DisplayDetailView_display: gql`
      fragment DisplayDetailView_display on Display {
        id
        groups {
          id
          name
        }
        site {
          name
        }
        ...DisplayDetailSettings_display
        ...DisplayDetailSummary_display
        ...DisplayDetailHeader_display
      }
    `,
  },
};
