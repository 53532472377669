import { gql } from '@apollo/client';
import { Button, chakra, FormErrorMessage, useToast, VStack } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAuth } from '~auth/useAuth';
import { ContentModalAppEntry } from '~components/displays/ContentModalAppEntry';
import { ContentModalSubHeading } from '~components/displays/ContentModalSubHeading';
import { Link } from '~components/ui/Link';
import { fromError } from '~utils/errors';
import {
  useInstallAppMutation,
  useRetryInstallAppMutation,
  useRetryUninstallAppMutation,
  useUninstallAppMutation,
} from './__generated__/useAppSubscriptions.graphql';
import { UseManageAppSubscriptionsForm_DisplayFragment } from './__generated__/useManageAppSubscriptionsForm.graphql';
import { InstalledAppSubscription, useAppSubscriptions } from './useAppSubscriptions';

export type AppSubscription =
  UseManageAppSubscriptionsForm_DisplayFragment['appSubscriptions'][number];

const schema = z.object({
  displayId: z.string(),
  appSubscriptionsToInstall: z.record(
    z.string(),
    z.object({ subscriptionId: z.string(), version: z.string() }),
  ),
  appSubscriptionsToUninstallIds: z.array(z.string()),
  appSubscriptionsToRetryInstallIds: z.array(z.string()),
  appSubscriptionsToRetryUninstallIds: z.array(z.string()),
});

type FormValues = z.TypeOf<typeof schema>;

export function useManageAppSubscriptionsForm({
  display,
  onSuccess,
  onCancel,
}: {
  display: UseManageAppSubscriptionsForm_DisplayFragment;
  onSuccess: () => Promise<void> | void;
  onCancel: () => void;
}) {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      displayId: display.id,
      appSubscriptionsToInstall: {},
      appSubscriptionsToUninstallIds: [],
      appSubscriptionsToRetryInstallIds: [],
      appSubscriptionsToRetryUninstallIds: [],
    },
    resolver: zodResolver(schema),
  });

  const appSubscriptionsToUninstallIds = watch('appSubscriptionsToUninstallIds');
  const appSubscriptionsToInstall = watch('appSubscriptionsToInstall');
  const appSubscriptionsToRetryInstallIds = watch('appSubscriptionsToRetryInstallIds');
  const appSubscriptionsToRetryUninstallIds = watch('appSubscriptionsToRetryUninstallIds');

  // Get the subscriptions for this display
  const { getSortedDisplayAppSubscriptions } = useAppSubscriptions();
  const {
    installedAppSubscriptions: currentlyInstalledAppSubscriptions,
    availableAppSubscriptions: currentlyAvailableAppSubscriptions,
    unavailableAppSubscriptions,
    installFailedAppSubscriptions: currentlyInstallFailedAppSubscriptions,
    uninstallFailedAppSubscriptions: currentlyUninstallFailedAppSubscriptions,
  } = getSortedDisplayAppSubscriptions(display);

  const installFailedAppSubscriptionIds = currentlyInstallFailedAppSubscriptions.map(
    (subscription) => subscription.id,
  );
  const uninstallFailedAppSubscriptionIds = currentlyUninstallFailedAppSubscriptions.map(
    (subscription) => subscription.id,
  );
  const installedAppSubscriptionIds = currentlyInstalledAppSubscriptions.map(
    (subscription) => subscription.id,
  );
  const availableAppSubscriptionIds = currentlyAvailableAppSubscriptions.map(
    (subscription) => subscription.id,
  );

  const installAppSubscription = (subscriptionId: string, version: string) => {
    setValue(
      'appSubscriptionsToUninstallIds',
      appSubscriptionsToUninstallIds.filter((subId) => subId !== subscriptionId),
    );

    if (availableAppSubscriptionIds.includes(subscriptionId)) {
      appSubscriptionsToInstall[subscriptionId] = { subscriptionId, version };
      setValue('appSubscriptionsToInstall', appSubscriptionsToInstall);
    }

    if (installFailedAppSubscriptionIds.includes(subscriptionId)) {
      setValue('appSubscriptionsToRetryInstallIds', [
        ...appSubscriptionsToRetryInstallIds,
        subscriptionId,
      ]);
    }

    if (uninstallFailedAppSubscriptionIds.includes(subscriptionId)) {
      setValue(
        'appSubscriptionsToRetryUninstallIds',
        appSubscriptionsToRetryUninstallIds.filter((subId) => subId !== subscriptionId),
      );
    }
  };

  const uninstallAppSubscription = (subscriptionId: string) => {
    delete appSubscriptionsToInstall[subscriptionId];
    setValue('appSubscriptionsToInstall', appSubscriptionsToInstall);

    if (installedAppSubscriptionIds.includes(subscriptionId)) {
      setValue('appSubscriptionsToUninstallIds', [
        ...appSubscriptionsToUninstallIds,
        subscriptionId,
      ]);
    }

    if (installFailedAppSubscriptionIds.includes(subscriptionId)) {
      setValue(
        'appSubscriptionsToRetryInstallIds',
        appSubscriptionsToRetryInstallIds.filter((subId) => subId !== subscriptionId),
      );
    }

    if (uninstallFailedAppSubscriptionIds.includes(subscriptionId)) {
      setValue('appSubscriptionsToRetryUninstallIds', [
        ...appSubscriptionsToRetryUninstallIds,
        subscriptionId,
      ]);
    }
  };

  const resetUninstallingAppSubscription = (subscriptionId: string) => {
    setValue(
      'appSubscriptionsToUninstallIds',
      appSubscriptionsToUninstallIds.filter((subId) => subId !== subscriptionId),
    );
  };

  // Construct the final arrays by applying the local changes onto the current data
  const installedAppSubscriptions: InstalledAppSubscription[] = [];
  const availableAppSubscriptions: AppSubscription[] = [];
  const installFailedAppSubscriptions: AppSubscription[] = [];
  const uninstallFailedAppSubscriptions: AppSubscription[] = [];

  for (const appSubscription of currentlyUninstallFailedAppSubscriptions) {
    if (appSubscriptionsToUninstallIds.includes(appSubscription.id)) {
      availableAppSubscriptions.push(appSubscription);
    } else if (appSubscriptionsToInstall[appSubscription.id] !== undefined) {
      installedAppSubscriptions.push({
        ...appSubscription,
        appInstallation: {
          __typename: 'AppInstallationInstalled',
          id: '',
          versionName: '',
        },
      });
    } else {
      uninstallFailedAppSubscriptions.push(appSubscription);
    }
  }

  for (const appSubscription of currentlyInstallFailedAppSubscriptions) {
    if (appSubscriptionsToUninstallIds.includes(appSubscription.id)) {
      availableAppSubscriptions.push(appSubscription);
    } else if (appSubscriptionsToInstall[appSubscription.id] !== undefined) {
      installedAppSubscriptions.push({
        ...appSubscription,
        appInstallation: {
          __typename: 'AppInstallationInstalled',
          id: '',
          versionName: '',
        },
      });
    } else {
      installFailedAppSubscriptions.push(appSubscription);
    }
  }

  for (const appSubscription of currentlyInstalledAppSubscriptions) {
    if (appSubscriptionsToUninstallIds.includes(appSubscription.id)) {
      availableAppSubscriptions.push(appSubscription);
    } else {
      installedAppSubscriptions.push(appSubscription);
    }
  }

  for (const appSubscription of currentlyAvailableAppSubscriptions) {
    if (appSubscriptionsToInstall[appSubscription.id] !== undefined) {
      installedAppSubscriptions.push({
        ...appSubscription,
        appInstallation: {
          __typename: 'AppInstallationInstalled',
          id: '',
          versionName: '',
        },
      });
    } else {
      // `unshift` so existing available apps are at the top of the list, above the to-uninstall apps from above
      availableAppSubscriptions.unshift(appSubscription);
    }
  }

  const hasInstallFailedAppSubscriptions = installFailedAppSubscriptions.length > 0;
  const hasUninstallFailedAppSubscriptions = uninstallFailedAppSubscriptions.length > 0;
  const hasInstalledAppSubscriptions = installedAppSubscriptions.length > 0;
  const hasAvailableAppSubscriptions = availableAppSubscriptions.length > 0;
  const hasAppSubscriptionsToInstall = Object.keys(appSubscriptionsToInstall).length > 0;
  const hasAppSubscriptionsToUninstall = appSubscriptionsToUninstallIds.length > 0;

  // Applying changes is only needed if local changes were made
  const canApplyChanges = hasAppSubscriptionsToInstall || appSubscriptionsToUninstallIds.length > 0;

  const saveAppSubscriptions = useSaveAppSubscriptions({ display, onSuccess });
  const { organization } = useAuth();
  let organizationLink = '#';
  if (organization) {
    organizationLink = `/${organization.handle}/settings/apps`;
  }

  const body = (
    <VStack spacing="3" alignItems="flex-start">
      <ErrorMessage
        errors={errors}
        name="appSubscriptions"
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />

      {hasInstallFailedAppSubscriptions && (
        <>
          <ContentModalSubHeading>Install Failed</ContentModalSubHeading>
          {installFailedAppSubscriptions.map((appSubscription) => (
            <ContentModalAppEntry
              key={appSubscription.id}
              subscription={appSubscription}
              onDelete={() => uninstallAppSubscription(appSubscription.id)}
            />
          ))}
        </>
      )}
      {hasUninstallFailedAppSubscriptions && (
        <>
          <ContentModalSubHeading>Uninstall Failed</ContentModalSubHeading>
          {uninstallFailedAppSubscriptions.map((appSubscription) => (
            <ContentModalAppEntry
              key={appSubscription.id}
              subscription={appSubscription}
              onDelete={() => uninstallAppSubscription(appSubscription.id)}
            />
          ))}
        </>
      )}
      <ContentModalSubHeading>Installed</ContentModalSubHeading>
      {hasInstalledAppSubscriptions ? (
        installedAppSubscriptions.map((appSubscription) => {
          const isDisabled =
            appSubscription.appInstallation.__typename !== 'AppInstallationInstalled';
          return appSubscriptionsToUninstallIds.includes(appSubscription.id) ? null : (
            <ContentModalAppEntry
              key={appSubscription.id}
              subscription={appSubscription}
              onDelete={() => uninstallAppSubscription(appSubscription.id)}
              disabled={isDisabled}
              progressLabel={appSubscription.progressLabel}
              version={appSubscriptionsToInstall[appSubscription.id]?.version}
            />
          );
        })
      ) : (
        <chakra.p>You haven’t added any apps to this display yet.</chakra.p>
      )}
      {hasInstalledAppSubscriptions && hasAppSubscriptionsToUninstall && (
        <>
          <ContentModalSubHeading>To Uninstall</ContentModalSubHeading>
          {availableAppSubscriptions.map((appSubscription) => {
            return appSubscriptionsToUninstallIds.includes(appSubscription.id) ? (
              <ContentModalAppEntry
                key={appSubscription.id}
                subscription={appSubscription}
                onDelete={() => resetUninstallingAppSubscription(appSubscription.id)}
                version={appSubscriptionsToInstall[appSubscription.id]?.version}
              />
            ) : null;
          })}
        </>
      )}
      {(hasAvailableAppSubscriptions || !hasAppSubscriptionsToInstall) && (
        <>
          <ContentModalSubHeading>Available</ContentModalSubHeading>
          {hasAvailableAppSubscriptions ? (
            availableAppSubscriptions.map((appSubscription) =>
              appSubscriptionsToUninstallIds.includes(appSubscription.id) ? null : (
                <ContentModalAppEntry
                  key={appSubscription.id}
                  subscription={appSubscription}
                  onInstall={(version: string) =>
                    installAppSubscription(appSubscription.id, version)
                  }
                />
              ),
            )
          ) : (
            <chakra.p>
              You haven’t bought any apps yet. You can visit your{' '}
              <Link to={organizationLink} color="blue.500">
                organisation apps
              </Link>{' '}
              to start using Wave apps on your displays.
            </chakra.p>
          )}
        </>
      )}
      {unavailableAppSubscriptions.length > 0 && (
        <>
          <ContentModalSubHeading>Unavailable</ContentModalSubHeading>
          {unavailableAppSubscriptions.map((appSubscription) => (
            <ContentModalAppEntry
              key={appSubscription.id}
              subscription={appSubscription}
              unavailabilityReason={appSubscription.reason}
            />
          ))}
        </>
      )}
    </VStack>
  );

  const footer = (
    <>
      <Button variant="ghost" colorScheme="blue" onClick={onCancel} isDisabled={isSubmitting}>
        Cancel
      </Button>
      <Button
        variant="solid"
        colorScheme="blue"
        marginLeft="3"
        type="submit"
        isDisabled={isSubmitting || !canApplyChanges}
        isLoading={isSubmitting}
      >
        Apply
      </Button>
    </>
  );

  return {
    onSubmit: handleSubmit(canApplyChanges ? saveAppSubscriptions : onSuccess),
    body,
    footer,
  };
}

export function useSaveAppSubscriptions({
  display,
  onSuccess,
}: {
  display: UseManageAppSubscriptionsForm_DisplayFragment;
  onSuccess?: () => Promise<void> | void;
}) {
  const toast = useToast();
  const [installAppMutation] = useInstallAppMutation();
  const [uninstallAppMutation] = useUninstallAppMutation();
  const [retryInstallAppMutation] = useRetryInstallAppMutation();
  const [retryUninstallAppMutation] = useRetryUninstallAppMutation();

  return useCallback(
    async ({
      displayId,
      appSubscriptionsToInstall,
      appSubscriptionsToUninstallIds,
      appSubscriptionsToRetryInstallIds,
      appSubscriptionsToRetryUninstallIds,
    }: FormValues) => {
      // Remove the subscriptions that failed to install. We have to retry them.
      const appSubscriptionsToInstallWithoutRetryIds = Object.values(
        appSubscriptionsToInstall,
      ).filter(({ subscriptionId }) => !appSubscriptionsToRetryInstallIds.includes(subscriptionId));

      for (const { subscriptionId, version } of appSubscriptionsToInstallWithoutRetryIds) {
        try {
          await installAppMutation({
            variables: {
              input: {
                displayId,
                subscriptionId,
                appVersion: version,
              },
            },
          });

          // TODO: add analytics here later through other ticket

          await onSuccess?.();
        } catch (error) {
          const appSubscription = display.appSubscriptions.find(({ id }) => id === subscriptionId);
          const formattedName =
            appSubscription !== undefined ? `"${appSubscription.name}"` : 'the app';

          toast({
            status: 'error',
            title: `Installing ${formattedName} failed. Please try again later.`,
            description: fromError(error, 'installAppMutation', {
              displayId,
              subscriptionId,
            }),
          });
        }
      }

      // Remove the subscriptions that failed to install. We have to retry them.
      const appSubscriptionsToUninstallWithoutRetryIds = appSubscriptionsToUninstallIds.filter(
        (subscriptionId) => !appSubscriptionsToRetryUninstallIds.includes(subscriptionId),
      );

      for (const appSubscriptionId of appSubscriptionsToUninstallWithoutRetryIds) {
        try {
          await uninstallAppMutation({
            variables: {
              input: {
                displayId,
                subscriptionId: appSubscriptionId,
              },
            },
          });

          // TODO: add analytics here later through other ticket

          await onSuccess?.();
        } catch (error) {
          const appSubscription = display.appSubscriptions.find(
            ({ id }) => id === appSubscriptionId,
          );
          const formattedName =
            appSubscription !== undefined ? `"${appSubscription.name}"` : 'the app';

          toast({
            status: 'error',
            title: `Uninstalling ${formattedName} failed. Please try again later.`,
            description: fromError(error, 'uninstallAppMutation', {
              displayId,
              subscriptionId: appSubscriptionId,
            }),
          });
        }
      }

      for (const appSubscriptionId of appSubscriptionsToRetryInstallIds) {
        try {
          await retryInstallAppMutation({
            variables: {
              input: {
                displayId,
                subscriptionId: appSubscriptionId,
              },
            },
          });
          await onSuccess?.();
        } catch (error) {
          const appSubscription = display.appSubscriptions.find(
            ({ id }) => id === appSubscriptionId,
          );
          const formattedName =
            appSubscription !== undefined ? `"${appSubscription.name}"` : 'the app';

          toast({
            status: 'error',
            title: `Installing ${formattedName} failed. Please try again later.`,
            description: fromError(error, 'retryInstallAppMutation', {
              displayId,
              subscriptionId: appSubscriptionId,
            }),
          });
        }
      }

      for (const appSubscriptionId of appSubscriptionsToRetryUninstallIds) {
        try {
          await retryUninstallAppMutation({
            variables: {
              input: {
                displayId,
                subscriptionId: appSubscriptionId,
              },
            },
          });

          // TODO: add analytics here later through other ticket

          await onSuccess?.();
        } catch (error) {
          const appSubscription = display.appSubscriptions.find(
            ({ id }) => id === appSubscriptionId,
          );
          const formattedName =
            appSubscription !== undefined ? `"${appSubscription.name}"` : 'the app';

          toast({
            status: 'error',
            title: `Uninstalling ${formattedName} failed. Please try again later.`,
            description: fromError(error, 'retryUninstallAppMutation', {
              displayId,
              subscriptionId: appSubscriptionId,
            }),
          });
        }
      }
    },
    [
      installAppMutation,
      display.appSubscriptions,
      onSuccess,
      toast,
      uninstallAppMutation,
      retryInstallAppMutation,
      retryUninstallAppMutation,
    ],
  );
}

useManageAppSubscriptionsForm.graphql = {
  fragments: {
    DisplaySubscriptions: gql`
      fragment DisplaySubscriptions on DisplayAppSubscription {
        id
        name
        iconUrl
        appVersions
        appInstallation {
          id
          ... on AppInstallationInstalled {
            versionName
          }
          ... on AppInstallationInstalling {
            versionName
            downloadProgress
          }
          ... on AppInstallationUninstalling {
            versionName
          }
          ... on AppInstallationUpdating {
            currentVersionCode
            downloadProgress
          }
        }
        usage {
          current
          max
        }
      }
    `,
    useManageAppSubscriptionsForm_display: gql`
      fragment useManageAppSubscriptionsForm_display on Display {
        id
        appSubscriptions {
          ...DisplaySubscriptions
        }
      }
    `,
  },
};
