/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayTimeSection_DisplayFragment = { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any } } | null, timeZone?: { __typename: 'TimeZoneState', reported: string, desired?: string | null, supportedValues: Array<string> } | null };

export type DisplayBulkUpdateTimeZoneMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateTimeZoneInput;
}>;


export type DisplayBulkUpdateTimeZoneMutation = { __typename: 'Mutation', displayBulkUpdateTimeZone: { __typename: 'DisplayBulkUpdateTimeZonePayload', displays: Array<{ __typename: 'Display', id: string, timeZone?: { __typename: 'TimeZoneState', reported: string, desired?: string | null } | null }> } };

export const DisplayTimeSection_DisplayFragmentDoc = gql`
    fragment DisplayTimeSection_display on Display {
  id
  firmware {
    android {
      version
    }
  }
  timeZone {
    reported
    desired
    supportedValues
  }
}
    `;
export const DisplayBulkUpdateTimeZoneDocument = gql`
    mutation displayBulkUpdateTimeZone($input: DisplayBulkUpdateTimeZoneInput!) {
  displayBulkUpdateTimeZone(input: $input) {
    displays {
      id
      timeZone {
        reported
        desired
      }
    }
  }
}
    `;
export type DisplayBulkUpdateTimeZoneMutationFn = ApolloReactCommon.MutationFunction<DisplayBulkUpdateTimeZoneMutation, DisplayBulkUpdateTimeZoneMutationVariables>;

/**
 * __useDisplayBulkUpdateTimeZoneMutation__
 *
 * To run a mutation, you first call `useDisplayBulkUpdateTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayBulkUpdateTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayBulkUpdateTimeZoneMutation, { data, loading, error }] = useDisplayBulkUpdateTimeZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisplayBulkUpdateTimeZoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisplayBulkUpdateTimeZoneMutation, DisplayBulkUpdateTimeZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisplayBulkUpdateTimeZoneMutation, DisplayBulkUpdateTimeZoneMutationVariables>(DisplayBulkUpdateTimeZoneDocument, options);
      }
export type DisplayBulkUpdateTimeZoneMutationHookResult = ReturnType<typeof useDisplayBulkUpdateTimeZoneMutation>;
export type DisplayBulkUpdateTimeZoneMutationResult = ApolloReactCommon.MutationResult<DisplayBulkUpdateTimeZoneMutation>;
export type DisplayBulkUpdateTimeZoneMutationOptions = ApolloReactCommon.BaseMutationOptions<DisplayBulkUpdateTimeZoneMutation, DisplayBulkUpdateTimeZoneMutationVariables>;