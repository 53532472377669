import { Permission } from '@tp-vision/roles-permissions';
import { useAuth } from '~auth/useAuth';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import { EmptyPowerScheduleIllustration } from '~components/ui/illustrations';

export function EmptyPowerSchedules({ handleCreate }: { handleCreate: () => void }) {
  const { verifyUserPermissions } = useAuth();
  const hasPowerScheduleCreatePermission = verifyUserPermissions([Permission.PowerscheduleCreate]);

  return (
    <EmptyView icon={<EmptyPowerScheduleIllustration />} title="Create your first power schedule">
      <EmptyViewButton
        onClick={handleCreate}
        label="Create schedule"
        isDisabled={!hasPowerScheduleCreatePermission}
      />
    </EmptyView>
  );
}
