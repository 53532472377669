import { Radio, Stack, useRadioGroup } from '@chakra-ui/react';
import { isNil } from 'lodash';

export type ToggleOptionsConfig<T extends string> = {
  value: T;
  label: string;
};

interface Props<T extends string> {
  name: string;
  value: T;
  isLoading: boolean;
  isDisabled?: boolean;
  handleChange: (value: T) => Promise<void>;
  options: Array<ToggleOptionsConfig<T>>;
}

export function DisplayControlsToggle<T extends string>({
  name,
  value,
  isLoading,
  isDisabled = false,
  handleChange,
  options,
}: Props<T>) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: value,
    onChange: handleChange,
    isDisabled: isLoading,
  });

  const group = getRootProps();

  if (isNil(value)) {
    return null;
  }

  return (
    <Stack {...group}>
      {options.map(({ value: optionValue, label }) => {
        const radio = getRadioProps({ value: optionValue });
        return (
          <Radio
            key={optionValue}
            {...radio}
            isChecked={value === optionValue}
            isDisabled={isDisabled}
          >
            {label}
          </Radio>
        );
      })}
    </Stack>
  );
}
