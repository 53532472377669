import { gql } from '@apollo/client';
import { chakra, useToast } from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { Power, SettingWarningSeverity } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { useRecommendedSettings } from '../../useRecommendedSettings';
import { mapStringToPowerEnum } from '../../utils';
import {
  UpdatePowerMutation,
  UsePowerToggle_DisplayFragment,
  useUpdatePowerMutation,
} from './__generated__/usePowerToggle.graphql';

interface Props {
  display: UsePowerToggle_DisplayFragment;
}

export function usePowerToggle({ display }: Props) {
  const currentPower = useMemo(() => display.power?.desired ?? display.power?.reported, [display]);
  const analytics = useAnalyticsReporter();

  const { getSettingsState } = useRecommendedSettings();
  const powerApplyAction = useDestructiveAction<string>({
    title: () => <>Warning</>,
    message: () => (
      <>
        Please note that this display is currently not using the recommended settings.{' '}
        <chakra.span fontSize="md" fontWeight="semibold">
          Switching this display to stand-by will make it lose connection with the WAVE platform.
        </chakra.span>
      </>
    ),
    onConfirm: async (data) => {
      await handleUpdatePower(data);
    },
    confirmLabel: 'Apply anyway',
    variant: 'warning',
    notice: undefined,
  });

  const toast = useToast();
  const [updatePower] = useUpdatePowerMutation();

  const handleUpdatePower = useCallback(
    async (value: string) => {
      analytics.track('displaySettingUpdate', { group: 'power', changeItem: 'power' });

      try {
        const power = mapStringToPowerEnum(value);
        const optimisticResponse: UpdatePowerMutation = {
          __typename: 'Mutation',
          displayUpdatePower: {
            __typename: 'DisplayUpdatePowerPayload',
            display: _.merge(_.cloneDeep(display), {
              power: {
                desired: power,
              },
            }),
          },
        };

        await updatePower({
          variables: {
            input: {
              id: display.id,
              power,
            },
          },
          optimisticResponse,
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot update power',
          description: fromError(err, 'UpdatePower'),
        });
      }
    },
    [analytics, display, updatePower, toast],
  );

  const handleChange = useCallback(async () => {
    const value = currentPower === Power.On ? Power.Standby : Power.On;
    if (value === Power.Standby) {
      const settings = getSettingsState(display);
      const hasHighSeverityWarning =
        settings.kind === 'not_recommended' &&
        settings.highestSeverity === SettingWarningSeverity.High;

      if (hasHighSeverityWarning) {
        await powerApplyAction.askConfirmation(value);
        return;
      }
    }
    await handleUpdatePower(value);
  }, [currentPower, display, getSettingsState, handleUpdatePower, powerApplyAction]);

  return {
    currentPower,
    confirmationNode: powerApplyAction.confirmationNode,
    handleChange,
  };
}

usePowerToggle.graphql = {
  fragments: {
    usePowerToggle_display: gql`
      fragment usePowerToggle_display on Display {
        id
        power {
          reported
          reportedAt
          desired
        }
        ...UseRecommendedSettings_display
      }
    `,
  },
  mutations: {
    UpdatePower: gql`
      mutation UpdatePower($input: DisplayUpdatePowerInput!) {
        displayUpdatePower(input: $input) {
          display {
            id
            power {
              reported
              reportedAt
              desired
            }
            ...UseRecommendedSettings_display
          }
        }
      }
    `,
  },
};
