/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PresenceBadge_DisplayFragment = { __typename: 'Display', id: string, presence?: { __typename: 'DisplayPresence', connected: boolean } | null };

export const PresenceBadge_DisplayFragmentDoc = gql`
    fragment PresenceBadge_display on Display {
  id
  presence {
    connected
  }
}
    `;