import { gql } from '@apollo/client';
import { Checkbox, useToast } from '@chakra-ui/react';
import { cloneDeep, merge } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplaySignalDetectionToggle_DisplayFragment,
  useBulkUpdateSignalDetectionMutation,
} from './__generated__/DisplaySignalDetection.graphql';

interface Props {
  display: DisplaySignalDetectionToggle_DisplayFragment;
  isDisabled?: boolean;
}

export function DisplaySignalDetectionToggle({ display, isDisabled = false }: Props) {
  const currentSignalDetection = useMemo(
    () => display.signalDetection?.desired ?? display.signalDetection?.reported,
    [display],
  );
  const analytics = useAnalyticsReporter();

  const toast = useToast();
  const [updateSignalDetection] = useBulkUpdateSignalDetectionMutation();
  const handleUpdateSignalDetection = useCallback(
    async (value: boolean) => {
      analytics.track('displaySettingUpdate', { group: 'power', changeItem: 'signalDetection' });

      try {
        await updateSignalDetection({
          variables: {
            input: {
              displayIds: [display.id],
              enable: value,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            displayBulkUpdateSignalDetection: {
              __typename: 'DisplayBulkUpdateSignalDetectionPayload',
              displays: [
                merge(cloneDeep(display), {
                  signalDetection: {
                    desired: value,
                  },
                }),
              ],
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot update the signal detection',
          description: fromError(err, 'UpdateSignalDetection'),
        });
      }
    },
    [analytics, display, toast, updateSignalDetection],
  );

  return (
    <Checkbox
      isChecked={currentSignalDetection}
      onChange={(e) => handleUpdateSignalDetection(e.target.checked)}
      isDisabled={isDisabled}
    >
      Enable signal detection
    </Checkbox>
  );
}

DisplaySignalDetectionToggle.graphql = {
  fragments: {
    DisplaySignalDetectionToggle_display: gql`
      fragment DisplaySignalDetectionToggle_display on Display {
        id
        signalDetection {
          desired
          reported
        }
        ...UseRecommendedSettings_display
      }
    `,
  },
  mutations: {
    UpdatePower: gql`
      mutation BulkUpdateSignalDetection($input: DisplayBulkUpdateSignalDetectionInput!) {
        displayBulkUpdateSignalDetection(input: $input) {
          displays {
            id
            ...DisplaySignalDetectionToggle_display
            ...UseRecommendedSettings_display
          }
        }
      }
    `,
  },
};
