import { createIcon } from '@chakra-ui/react';

export const EmptyDisplayIllustration = createIcon({
  displayName: 'EmptyDisplayIllustration',
  viewBox: '0 0 240 240',
  defaultProps: {
    fill: 'none',
    width: '240px',
    height: '240px',
  },
  path: (
    <>
      <circle cx="120" cy="120" r="120" fill="#F1F8FE" />
      <g filter="url(#filter0_dd_5358_1931)">
        <path
          d="M50.6667 85.3333C50.6667 81.406 53.8504 78.2222 57.7778 78.2222H182.222C186.15 78.2222 189.333 81.406 189.333 85.3333V158.222C189.333 162.15 186.15 165.333 182.222 165.333H57.7778C53.8504 165.333 50.6667 162.15 50.6667 158.222V85.3333Z"
          fill="white"
        />
        <path
          d="M86.2222 165.333H156.444V167.111C156.444 169.075 154.853 170.667 152.889 170.667H89.7778C87.8141 170.667 86.2222 169.075 86.2222 167.111V165.333Z"
          fill="white"
        />
        <path
          d="M86.2222 163.556H84.4444H57.7778C54.8322 163.556 52.4444 161.168 52.4444 158.222V85.3333C52.4444 82.3878 54.8323 80 57.7778 80H182.222C185.168 80 187.556 82.3878 187.556 85.3333V158.222C187.556 161.168 185.168 163.556 182.222 163.556H158.222H156.444H86.2222ZM88 167.111H154.667C154.667 168.093 153.871 168.889 152.889 168.889H89.7778C88.7959 168.889 88 168.093 88 167.111Z"
          stroke="#0F5291"
          strokeWidth="3.55556"
        />
      </g>
      <path
        d="M73.4482 129.99C72.6502 129.985 71.88 129.851 71.1376 129.588C70.4002 129.326 69.7386 128.899 69.1527 128.308C68.5669 127.717 68.1022 126.934 67.7588 125.96C67.4204 124.985 67.2512 123.788 67.2512 122.369C67.2563 121.066 67.4053 119.902 67.6982 118.876C67.9962 117.846 68.4204 116.972 68.9709 116.255C69.5265 115.538 70.1906 114.992 70.9633 114.619C71.7361 114.24 72.6022 114.051 73.5618 114.051C74.5972 114.051 75.5113 114.253 76.3043 114.657C77.0972 115.056 77.7335 115.598 78.2133 116.285C78.6982 116.972 78.9911 117.742 79.0921 118.596H75.8573C75.731 118.056 75.4659 117.631 75.0618 117.323C74.6578 117.015 74.1578 116.861 73.5618 116.861C72.5517 116.861 71.7841 117.301 71.2588 118.179C70.7386 119.058 70.4734 120.253 70.4633 121.763H70.5694C70.8017 121.303 71.1149 120.912 71.5088 120.588C71.9078 120.26 72.3598 120.01 72.8649 119.838C73.375 119.662 73.9128 119.573 74.4785 119.573C75.3977 119.573 76.2159 119.79 76.933 120.225C77.6502 120.654 78.2159 121.245 78.63 121.997C79.0442 122.75 79.2512 123.611 79.2512 124.581C79.2512 125.631 79.0063 126.566 78.5164 127.384C78.0315 128.202 77.3522 128.843 76.4785 129.308C75.6098 129.768 74.5997 129.995 73.4482 129.99ZM73.433 127.414C73.9381 127.414 74.3901 127.293 74.7891 127.051C75.1881 126.808 75.5012 126.48 75.7285 126.066C75.9558 125.652 76.0694 125.187 76.0694 124.672C76.0694 124.157 75.9558 123.694 75.7285 123.285C75.5063 122.876 75.1982 122.551 74.8043 122.308C74.4103 122.066 73.9608 121.944 73.4558 121.944C73.077 121.944 72.726 122.015 72.4027 122.157C72.0846 122.298 71.8043 122.495 71.5618 122.747C71.3245 123 71.1376 123.293 71.0012 123.626C70.8649 123.955 70.7967 124.306 70.7967 124.679C70.7967 125.179 70.9103 125.636 71.1376 126.051C71.3699 126.465 71.683 126.795 72.077 127.043C72.476 127.29 72.928 127.414 73.433 127.414Z"
        fill="#0F5291"
      />
      <rect
        x="65.3333"
        y="109.778"
        width="15.1111"
        height="24"
        rx="1.33333"
        stroke="#9FB3C8"
        strokeWidth="0.888889"
      />
      <path
        d="M91.0167 129.99C89.8854 129.99 88.8778 129.795 87.994 129.407C87.1152 129.013 86.4207 128.472 85.9106 127.785C85.4056 127.093 85.1455 126.295 85.1303 125.391H88.4334C88.4536 125.77 88.5773 126.104 88.8046 126.391C89.0369 126.674 89.345 126.894 89.7288 127.051C90.1126 127.207 90.5445 127.285 91.0243 127.285C91.5243 127.285 91.9662 127.197 92.35 127.02C92.7339 126.843 93.0344 126.598 93.2515 126.285C93.4687 125.972 93.5773 125.611 93.5773 125.202C93.5773 124.788 93.4611 124.422 93.2288 124.104C93.0015 123.78 92.6733 123.528 92.244 123.346C91.8197 123.164 91.3147 123.073 90.7288 123.073H89.2818V120.664H90.7288C91.2238 120.664 91.6606 120.578 92.0394 120.407C92.4233 120.235 92.7212 119.997 92.9334 119.694C93.1455 119.386 93.2515 119.028 93.2515 118.619C93.2515 118.23 93.1581 117.889 92.9712 117.596C92.7894 117.298 92.5318 117.066 92.1985 116.899C91.8702 116.732 91.4864 116.649 91.047 116.649C90.6025 116.649 90.196 116.73 89.8273 116.891C89.4586 117.048 89.1632 117.273 88.9409 117.566C88.7187 117.859 88.6 118.202 88.5849 118.596H85.4409C85.4561 117.702 85.7111 116.914 86.2061 116.232C86.701 115.551 87.3677 115.018 88.2061 114.634C89.0495 114.245 90.0015 114.051 91.0621 114.051C92.1328 114.051 93.0697 114.245 93.8727 114.634C94.6758 115.023 95.2995 115.548 95.744 116.21C96.1935 116.866 96.4157 117.604 96.4106 118.422C96.4157 119.29 96.1455 120.015 95.6 120.596C95.0596 121.177 94.3551 121.545 93.4864 121.702V121.823C94.6278 121.97 95.4965 122.366 96.0924 123.013C96.6935 123.654 96.9914 124.457 96.9864 125.422C96.9914 126.306 96.7364 127.091 96.2212 127.778C95.7111 128.465 95.0066 129.005 94.1076 129.399C93.2086 129.793 92.1783 129.99 91.0167 129.99Z"
        fill="#0F5291"
      />
      <rect
        x="83.1111"
        y="109.778"
        width="15.1111"
        height="24"
        rx="1.33333"
        stroke="#9FB3C8"
        strokeWidth="0.888889"
      />
      <path
        d="M111.4 114.263V129.778H108.12V117.376H108.029L104.476 119.604V116.694L108.317 114.263H111.4Z"
        fill="#0F5291"
      />
      <rect
        x="100.889"
        y="109.778"
        width="15.1111"
        height="24"
        rx="1.33333"
        stroke="#9FB3C8"
        strokeWidth="0.888889"
      />
      <path
        d="M125.738 127.051V124.467L132.215 114.263H134.443V117.838H133.124L129.041 124.301V124.422H138.246V127.051H125.738ZM133.185 129.778V126.263L133.246 125.119V114.263H136.321V129.778H133.185Z"
        fill="#0F5291"
      />
      <rect
        x="124"
        y="109.778"
        width="15.1111"
        height="24"
        rx="1.33333"
        stroke="#9FB3C8"
        strokeWidth="0.888889"
      />
      <path
        d="M143.761 129.778V127.414L149.284 122.301C149.753 121.846 150.147 121.437 150.466 121.073C150.789 120.71 151.034 120.354 151.2 120.005C151.367 119.652 151.45 119.27 151.45 118.861C151.45 118.407 151.347 118.015 151.14 117.687C150.933 117.354 150.65 117.098 150.291 116.922C149.933 116.74 149.526 116.649 149.072 116.649C148.597 116.649 148.183 116.745 147.829 116.937C147.476 117.129 147.203 117.404 147.011 117.763C146.819 118.121 146.723 118.548 146.723 119.043H143.61C143.61 118.028 143.839 117.146 144.299 116.399C144.759 115.652 145.402 115.073 146.231 114.664C147.059 114.255 148.014 114.051 149.094 114.051C150.206 114.051 151.173 114.247 151.996 114.641C152.824 115.03 153.468 115.571 153.928 116.263C154.387 116.955 154.617 117.747 154.617 118.641C154.617 119.227 154.501 119.806 154.269 120.376C154.041 120.947 153.635 121.581 153.049 122.278C152.463 122.97 151.637 123.801 150.572 124.77L148.307 126.99V127.096H154.822V129.778H143.761Z"
        fill="#0F5291"
      />
      <rect
        x="141.778"
        y="109.778"
        width="15.1111"
        height="24"
        rx="1.33333"
        stroke="#9FB3C8"
        strokeWidth="0.888889"
      />
      <path
        d="M170.067 114.263V129.778H166.786V117.376H166.695L163.142 119.604V116.694L166.983 114.263H170.067Z"
        fill="#0F5291"
      />
      <rect
        x="159.556"
        y="109.778"
        width="15.1111"
        height="24"
        rx="1.33333"
        stroke="#9FB3C8"
        strokeWidth="0.888889"
      />
      <g filter="url(#filter1_dd_5358_1931)">
        <circle
          cx="121.778"
          cy="165.333"
          r="17.3333"
          fill="white"
          stroke="#D9E2EC"
          strokeWidth="2.66667"
        />
        <path
          d="M121.778 159.111V171.556M115.556 165.333H128"
          stroke="#0F5291"
          strokeWidth="1.77778"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_5358_1931"
          x="37.3963"
          y="76.0105"
          width="165.207"
          height="118.985"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_5358_1931"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5358_1931" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_5358_1931"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5358_1931"
            result="effect2_dropShadow_5358_1931"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_5358_1931"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_5358_1931"
          x="89.8408"
          y="144.455"
          width="63.874"
          height="63.874"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_5358_1931"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5358_1931" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_5358_1931"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5358_1931"
            result="effect2_dropShadow_5358_1931"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_5358_1931"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
