import { gql } from '@apollo/client';
import { chakra, Flex, HStack, Tag } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PowerBadge from '~components/displays/PowerBadge';
import PresenceBadge from '~components/displays/PresenceBadge';
import { BackButton } from '~components/ui/BackButton';
import { SiteIcon } from '~components/ui/icons';
import { PageHeading } from '~components/ui/PageHeading';
import { ReleaseChannel } from '~graphql/__generated__/types';
import { DisplayDetailHeader_DisplayFragment } from './__generated__/DisplayDetailHeader.graphql';

export default function DisplayDetailHeader({
  display,
  title,
}: {
  display: DisplayDetailHeader_DisplayFragment;
  title: string;
}) {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate('../displays');
  }, [navigate]);

  return (
    <PageHeading
      floatingButton={<BackButton onClick={handleGoBack} />}
      labels={
        <>
          {display.site && (
            <HStack spacing={2}>
              <SiteIcon width="4" height="4" />
              <chakra.span>{display.site.name}</chakra.span>
            </HStack>
          )}
          <Flex direction="row" flexWrap="wrap">
            {display.groups.map((group) => (
              <Tag key={group.id} variant="outline" size="sm" mr="2">
                {group.name}
              </Tag>
            ))}

            {display.agentReleaseChannel?.reported === ReleaseChannel.Alpha ? (
              <Tag
                size="sm"
                color="orange.400"
                borderColor="orange.400"
                colorScheme="orange"
                variant="outline"
              >
                ALPHA
              </Tag>
            ) : undefined}
          </Flex>
        </>
      }
      actions={
        <HStack>
          <PresenceBadge display={display} />
          <PowerBadge display={display} />
        </HStack>
      }
    >
      {title}
    </PageHeading>
  );
}

DisplayDetailHeader.graphql = {
  fragments: {
    DisplayDetailHeader_display: gql`
      fragment DisplayDetailHeader_display on Display {
        id
        site {
          name
        }
        groups {
          id
          name
        }
        agentReleaseChannel {
          reported
        }
        ...PresenceBadge_display
        ...PowerBadge_display
      }
    `,
  },
};
