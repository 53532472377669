import { gql } from '@apollo/client';
import {
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  chakra,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import capitalize from 'lodash/capitalize';
import { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { Columns } from '~components/displays/DisplayTable/constants';
import {
  DisplaysQueryContext,
  useDisplaysQuery,
} from '~components/displays/DisplayTable/useDisplaysQuery';
import { statusMessageMap, StatusTypes } from '~components/displays/useStatus';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import { AddTimeSlotModal } from '~components/powerSchedules/AddTimeSlotModal';
import { CopyTimeSlotsModal } from '~components/powerSchedules/CopyTimeSlotsModal';
import { ActionMenuButton } from '~components/ui/ActionMenuButton';
import { WarningAlert } from '~components/ui/Alert';
import { BackButton } from '~components/ui/BackButton';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { DisplaysDetailStats } from '~components/ui/DisplaysDetailStats';
import { CopyIcon, StandbyIcon, TrashIcon } from '~components/ui/icons';
import { EmptyPowerScheduleIllustration } from '~components/ui/illustrations';
import { PageHeading } from '~components/ui/PageHeading';
import { Day, PowerScheduleUpdateInput, TimeBlock } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { DAYS_OF_WEEK_MON_FIRST, hourToDecimal } from '~utils/timeBlocks';
import { MaybePromise, OmitStrict } from '~utils/types';
import { useLimits } from '~utils/useLimits';
import { DeletePowerSchedulesModal } from '../DeletePowerSchedulesModal';
import { SyncDisplaysModal } from '../SyncDisplaysModal';
import { useRemovePowerScheduleFromDisplays } from '../useRemovePowerScheduleFromDisplays';
import {
  PowerScheduleDetail_PowerScheduleFragment,
  usePowerScheduleUpdateMutation,
} from './__generated__/PowerScheduleDetail.graphql';
import { EditPowerScheduleModal } from './EditPowerScheduleModal';
import { PowerScheduleDetailMoreActions } from './PowerScheduleDetailMoreActions';
import { TimeBlockBox } from './TimeBlockBox';

export type FormValues = OmitStrict<PowerScheduleUpdateInput, 'timeBlocks'>;

interface Props {
  powerSchedule: PowerScheduleDetail_PowerScheduleFragment;
  onPowerScheduleUpdateSuccess: () => MaybePromise<void>;
}

export function PowerScheduleDetail({ powerSchedule, onPowerScheduleUpdateSuccess }: Props) {
  const toast = useToast();
  const navigate = useNavigate();
  const editPowerScheduleModal = useDisclosure();
  const analytics = useAnalyticsReporter();
  const { setDisplaysQuery } = useContext(DisplaysQueryContext);
  const { verifyUserPermissions } = useAuth();

  const { removePowerScheduleFromDisplays } = useRemovePowerScheduleFromDisplays();

  const [dayToCopy, setDayToCopy] = useState<Day | undefined>();
  const [updatePowerSchedule] = usePowerScheduleUpdateMutation();

  const addTimeSlotModal = useDisclosure();
  const deletePowerSchedulesModal = useDisclosure();
  const syncDisplaysModal = useDisclosure();

  const hasPowerScheduleUpdatePermission = verifyUserPermissions([Permission.PowerscheduleUpdate]);

  const { timeBlocks = [] } = powerSchedule;

  const getFirstStandbyBlockWidth = (timeBlocks: TimeBlock[]) => {
    if (timeBlocks.length === 0) return '100%';
    return `${hourToDecimal('start', timeBlocks[0].start) * 100}%`;
  };

  const handleGoBack = () => {
    navigate('../schedules');
  };

  const handleDeletePowerSchedulesSuccess = () => {
    deletePowerSchedulesModal.onClose();
    analytics.track('scheduleDelete');
    navigate('../schedules');
  };

  const { persistFilters } = useDisplaysQuery();

  const canGoToLinkedDisplays = powerSchedule.syncedDisplays.length > 0;

  const handleGoToLinkedDisplays = (
    scheduleTitle: string,
    filters: Partial<Record<Columns, string>> = {},
  ) => {
    persistFilters(
      [
        {
          id: Columns.PowerSchedule,
          value: [{ column: Columns.PowerSchedule, value: scheduleTitle, label: scheduleTitle }],
        },
        ...Object.entries(filters).reduce<Parameters<typeof persistFilters>[0]>(
          (acc, [key, value]) => {
            acc.push({
              id: key,
              value: [{ column: key, value, label: value }],
            });
            return acc;
          },
          [],
        ),
      ],
      { reset: true },
    );

    const searchParams = new URLSearchParams({ powerSchedule: scheduleTitle, ...filters });
    setDisplaysQuery?.(searchParams);

    navigate(`../displays?${searchParams.toString()}`);
  };

  const updatePowerScheduleTimeBlocks = async (
    input: Pick<PowerScheduleUpdateInput, 'timeBlocks' | 'powerScheduleId'>,
  ) => {
    const { powerScheduleId, timeBlocks } = input;
    try {
      const addDisplayTypeName = (d: { id: string }) => ({
        ...d,
        __typename: 'Display' as const,
      });
      await updatePowerSchedule({
        variables: {
          input: {
            powerScheduleId,
            timeBlocks: timeBlocks?.map(({ start, end, day }) => ({
              start,
              end,
              day,
            })),
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          powerScheduleUpdate: {
            __typename: 'PowerScheduleUpdatePayload',
            powerSchedule: {
              __typename: 'PowerSchedule',
              id: powerScheduleId,
              title: powerSchedule.title,
              description: powerSchedule.description,
              timeBlocks: timeBlocks?.map((tb) => ({ ...tb, __typename: 'TimeBlock' })) || [],
              allDisplays: powerSchedule.allDisplays.map(addDisplayTypeName) || [],
              syncedDisplays: powerSchedule.syncedDisplays.map(addDisplayTypeName) || [],
              outOfSyncDisplays: powerSchedule.outOfSyncDisplays.map(addDisplayTypeName) || [],
              syncingDisplays: powerSchedule.syncingDisplays.map(addDisplayTypeName) || [],
              removingDisplays: powerSchedule.removingDisplays.map(addDisplayTypeName) || [],
            },
          },
        },
      });
      toast({
        status: 'success',
        title: 'Power schedule saved successfully',
      });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot edit power schedule',
        description: fromError(err, 'UpdatePowerSchedule'),
      });
    }
  };

  const handlePowerScheduleEdit = async () => {
    await onPowerScheduleUpdateSuccess();
    editPowerScheduleModal.onClose();
  };

  const addTimeSlot = () => {
    analytics.track('scheduleTimeSlotCreateStart');
    addTimeSlotModal.onOpen();
  };

  const timeSlotAdded = async (blocks: TimeBlock[]) => {
    analytics.track('scheduleTimeSlotCreateComplete');
    addTimeSlotModal.onClose();

    await updatePowerScheduleTimeBlocks({
      powerScheduleId: powerSchedule.id,
      timeBlocks: blocks,
    });
    setDayToCopy(undefined);
  };

  const timeSlotEdited = async (blocks: TimeBlock[]) => {
    analytics.track('scheduleTimeSlotUpdate');

    await updatePowerScheduleTimeBlocks({
      powerScheduleId: powerSchedule.id,
      timeBlocks: blocks,
    });
  };

  const removeDisplaysAction = useDestructiveAction<undefined>({
    title: 'Remove power schedule from displays',
    message: 'Are you sure you want remove this power schedule from all displays?',
    notice: 'The displays will no longer change the power following this schedule.',
    confirmLabel: 'Remove',
    onConfirm: async () => {
      await removePowerScheduleFromDisplays(powerSchedule.id);
    },
  });

  const handleClearDay = async (day: Day) => {
    await updatePowerScheduleTimeBlocks({
      powerScheduleId: powerSchedule.id,
      timeBlocks: timeBlocks.filter((tb) => tb.day !== day),
    });
  };

  const handleCopy = (day: Day) => {
    setDayToCopy(day);
  };

  const {
    powerSchedules: {
      timeBlocksNumberPerScheduleLimit: timeBlocksLimit,
      isTimeBlocksLimitPerScheduleReached,
    },
  } = useLimits();

  const isTimeBlocksLimitReached = isTimeBlocksLimitPerScheduleReached(powerSchedule?.timeBlocks);

  return (
    <>
      <PageHeading
        floatingButton={<BackButton onClick={handleGoBack} />}
        description={powerSchedule.description || undefined}
        actions={
          <HStack>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={addTimeSlot}
              isDisabled={!hasPowerScheduleUpdatePermission}
            >
              Add time slot
            </Button>
            <PowerScheduleDetailMoreActions
              powerSchedule={powerSchedule}
              onRemoveDisplays={() => removeDisplaysAction.askConfirmation(undefined)}
              onDelete={deletePowerSchedulesModal.onOpen}
              onSync={syncDisplaysModal.onOpen}
              onRename={editPowerScheduleModal.onOpen}
            />
          </HStack>
        }
      >
        {powerSchedule.title}
      </PageHeading>

      {isTimeBlocksLimitReached ? (
        <WarningAlert marginTop="8" marginBottom="6">
          <AlertTitle>Time blocks limit reached!</AlertTitle>
          <AlertDescription>
            You can only add {timeBlocksLimit} time blocks per schedule. Remove unused time blocks
            to add new ones for this schedule.
          </AlertDescription>
        </WarningAlert>
      ) : undefined}

      <Box marginBottom={8}>
        <DisplaysDetailStats
          syncedCount={powerSchedule.syncedDisplays.length}
          outOfSyncCount={powerSchedule.outOfSyncDisplays.length}
          syncingCount={powerSchedule.syncingDisplays.length}
          removingCount={powerSchedule.removingDisplays.length}
          onClickSyncedDisplays={
            canGoToLinkedDisplays ? () => handleGoToLinkedDisplays(powerSchedule.title) : undefined
          }
          onClickOutOfSyncDisplays={() =>
            handleGoToLinkedDisplays(powerSchedule.title, {
              [Columns.Warnings]: statusMessageMap[StatusTypes.PowerScheduleOutOfSync],
            })
          }
        />
      </Box>

      <Grid
        templateColumns="[days] auto [padding-left] 56px [timeline] 1fr [padding-right] 28px [actions] auto"
        rowGap={4}
        alignItems="center"
      >
        <GridItem
          gridColumnStart="timeline"
          display="flex"
          justifyContent="space-between"
          marginBottom="-2"
        >
          <span>00:00</span>
          <span>06:00</span>
          <span>12:00</span>
          <span>18:00</span>
          <span>00:00</span>
        </GridItem>
        {timeBlocks.length === 0 && (
          <GridItem
            gridColumnStart="timeline"
            rowStart={2}
            rowEnd={DAYS_OF_WEEK_MON_FIRST.length + 2}
            textAlign="center"
            zIndex={2}
            padding={8}
          >
            <EmptyView
              icon={<EmptyPowerScheduleIllustration />}
              title="Your schedule is empty"
              description="Start creating your schedule by adding a new time slot."
              padding="24px 0 0 0"
            >
              <EmptyViewButton
                onClick={addTimeSlot}
                label="Add time slot"
                isDisabled={!hasPowerScheduleUpdatePermission}
              />
            </EmptyView>
          </GridItem>
        )}
        {DAYS_OF_WEEK_MON_FIRST.map((DAY, i) => {
          const timeBlocksForDay = timeBlocks.filter(({ day }) => day === DAY);
          const hasTimeBlocksForDay = timeBlocksForDay.length > 0;
          return (
            <Fragment key={DAY}>
              <GridItem
                fontFamily="Gilroy, sans-serif"
                fontWeight={600}
                fontSize="20px"
                gridColumnStart="days"
                rowStart={i + 2}
              >
                {capitalize(DAY)}
              </GridItem>
              <GridItem
                gridColumnStart="timeline"
                rowStart={i + 2}
                h="12"
                bg="white"
                borderRadius={4}
                borderColor="gray.100"
                borderWidth={1}
                borderStyle="solid"
                padding="3px"
                filter="auto"
                blur={timeBlocks.length > 0 ? 0 : '3px'}
              >
                <Box position="relative" height="100%">
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    lineHeight="40px"
                    height="100%"
                    width={getFirstStandbyBlockWidth(timeBlocksForDay)}
                    paddingX={4}
                    flexWrap="wrap"
                    overflow="hidden"
                  >
                    {/* Hack to flex-wrap the icon out of the overflow when parent Box is too short */}
                    <Box width="1px">&nbsp;</Box>
                    <chakra.span display="inline-flex" width={6} height="40px" alignItems="center">
                      <Tooltip label="Standby">
                        <StandbyIcon position="absolute" width={6} height={6} color="gray.200" />
                      </Tooltip>
                    </chakra.span>
                  </Box>
                  {timeBlocksForDay.map((tb) => (
                    <TimeBlockBox
                      allTimeBlocks={timeBlocks}
                      currentTimeBlock={tb}
                      key={`${tb.day}|${tb.start}|${tb.end}|`}
                      onSubmit={timeSlotEdited}
                      isDisabled={!hasPowerScheduleUpdatePermission}
                    />
                  ))}
                </Box>
              </GridItem>
              <GridItem gridColumnStart="actions" rowStart={i + 2}>
                <Menu placement="bottom-end">
                  <MenuButton
                    as={ActionMenuButton}
                    sx={{
                      filter: 'auto',
                      blur: timeBlocks.length > 0 ? 0 : '2px',
                    }}
                    disabled={timeBlocks.length === 0}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<CopyIcon />}
                      onClick={() => handleCopy(DAY)}
                      isDisabled={!hasPowerScheduleUpdatePermission || !hasTimeBlocksForDay}
                    >
                      Copy to other days
                    </MenuItem>
                    <MenuItem
                      color={'red.500'}
                      icon={<TrashIcon color="red.500" />}
                      onClick={() => handleClearDay(DAY)}
                      isDisabled={!hasPowerScheduleUpdatePermission || !hasTimeBlocksForDay}
                    >
                      Clear day
                    </MenuItem>
                  </MenuList>
                </Menu>
              </GridItem>
            </Fragment>
          );
        })}
      </Grid>

      {removeDisplaysAction.confirmationNode}
      <AddTimeSlotModal
        timeBlocks={timeBlocks}
        isOpen={addTimeSlotModal.isOpen}
        onCancel={addTimeSlotModal.onClose}
        onSubmit={timeSlotAdded}
      />
      <SyncDisplaysModal
        isOpen={syncDisplaysModal.isOpen}
        powerSchedule={powerSchedule}
        onSuccess={syncDisplaysModal.onClose}
        onCancel={syncDisplaysModal.onClose}
      />
      <DeletePowerSchedulesModal
        isOpen={deletePowerSchedulesModal.isOpen}
        powerSchedules={[powerSchedule]}
        onCancel={deletePowerSchedulesModal.onClose}
        onSuccess={handleDeletePowerSchedulesSuccess}
      />
      <EditPowerScheduleModal
        isOpen={editPowerScheduleModal.isOpen}
        powerSchedule={powerSchedule}
        onCancel={editPowerScheduleModal.onClose}
        onSuccess={handlePowerScheduleEdit}
      />
      {!!dayToCopy && (
        <CopyTimeSlotsModal
          timeBlocks={timeBlocks}
          isOpen={!!dayToCopy}
          onCancel={() => setDayToCopy(undefined)}
          onSubmit={timeSlotAdded}
          dayToCopy={dayToCopy}
        />
      )}
    </>
  );
}

PowerScheduleDetail.graphql = {
  fragments: {
    PowerScheduleDetail_customer: gql`
      fragment PowerScheduleDetail_customer on Customer {
        id
        name
      }
    `,
    PowerScheduleDetail_powerSchedule: gql`
      fragment PowerScheduleDetail_powerSchedule on PowerSchedule {
        id
        title
        description
        timeBlocks {
          start
          end
          day
        }
        allDisplays: displays {
          id
        }
        syncedDisplays: displays(filter: { state: SYNCED }) {
          id
        }
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
        syncingDisplays: displays(filter: { state: SYNCING }) {
          id
        }
        removingDisplays: displays(filter: { state: REMOVING }) {
          id
        }
      }
    `,
  },
  mutations: {
    PowerScheduleUpdate: gql`
      mutation PowerScheduleUpdate($input: PowerScheduleUpdateInput!) {
        powerScheduleUpdate(input: $input) {
          powerSchedule {
            id
            ...PowerScheduleDetail_powerSchedule
          }
        }
      }
    `,
  },
};
