import { gql } from '@apollo/client';
import {
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Shield } from '~auth/Shield';
import { CustomersGrid } from '~components/customers/CustomersGrid';
import { EmptyCustomers } from '~components/customers/EmptyCustomers';
import { WarningAlert } from '~components/ui/Alert';
import { SearchIcon } from '~components/ui/icons';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { HandleApiError } from '~graphql/HandleApiError';
import { isDefined } from '~utils/types';
import { useLimits } from '~utils/useLimits';
import { useCustomersIndexPageQuery } from './__generated__/Index.graphql';

const POLL_INTERVAL = 20000;

export function CustomersIndexPage() {
  const navigate = useNavigate();
  const { data, loading, error, refetch, startPolling, stopPolling } = useCustomersIndexPageQuery({
    pollInterval: POLL_INTERVAL,
  });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const customers = useMemo(
    () => data?.organization.customers ?? [],
    [data?.organization.customers],
  );
  const [customersFilteredList, setCustomersFilteredList] = useState(customers);

  useEffect(() => {
    setCustomersFilteredList(customers);
  }, [customers]);

  const navigateToCustomerSettings = () =>
    navigate(`../settings/customers`, { state: { canGoBack: true } });

  const handleCustomerSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toLowerCase();

      if (!isDefined(value)) {
        setCustomersFilteredList(customers);
      }

      if (isDefined(data)) {
        const list = data.organization.customers.filter((c) =>
          c.name.toLowerCase().includes(value),
        );

        setCustomersFilteredList(list);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only interested in the customers update
    [customers, data?.organization.customers],
  );

  async function handleCustomerDeleted() {
    await refetch();
  }

  const {
    customers: {
      customersNumberPerOrganizationLimit: customersLimit,
      isCustomersLimitPerOrganizationReached,
    },
  } = useLimits();

  const isCustomersLimitReached = isCustomersLimitPerOrganizationReached(
    data?.organization?.customers,
  );

  const customerManagementButton = (
    <Shield requiredPermissions={[Permission.CustomerView]}>
      <Button variant="solid" colorScheme="blue" onClick={navigateToCustomerSettings}>
        Customer management
      </Button>
    </Shield>
  );

  return (
    <Page title="Customers" pageName="customers">
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          data && (
            <Box overflowX="hidden">
              <PageHeading actions={customerManagementButton}>Customer overview</PageHeading>
              <Stack direction={{ sm: 'column', md: 'row' }} spacing="8">
                <InputGroup width="full">
                  <InputLeftElement pointerEvents="none" height="full">
                    <SearchIcon color="gray.500" />
                  </InputLeftElement>
                  <Input
                    type="search"
                    placeholder="Search"
                    onChange={handleCustomerSearch}
                    height="12"
                  />
                </InputGroup>
              </Stack>
              {isCustomersLimitReached ? (
                <WarningAlert marginTop="8" marginBottom="6">
                  <AlertTitle>Customers limit reached!</AlertTitle>
                  <AlertDescription>
                    {`You've reached the limit of ${customersLimit} customers that you can add in your organization.`}
                  </AlertDescription>
                </WarningAlert>
              ) : undefined}
              {customers.length === 0 ? (
                <EmptyCustomers handleCreate={navigateToCustomerSettings} />
              ) : (
                <CustomersGrid
                  marginTop="6"
                  customers={customersFilteredList}
                  onCustomerDeleted={handleCustomerDeleted}
                />
              )}
            </Box>
          )
        )}
      </PageContent>
    </Page>
  );
}

CustomersIndexPage.graqphl = {
  queries: {
    CustomersIndexPage: gql`
      query CustomersIndexPage {
        organization {
          id
          customers {
            id
            ...CustomersGrid_customer
          }
        }
      }
    `,
  },
};
