import { gql } from '@apollo/client';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission } from '@tp-vision/roles-permissions';
import { isEmpty, isNil } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import { CustomerSubscriptions } from '~components/organization/CustomerSubscriptions';
import { CustomerSubscriptionsTableSubscriptionFragment } from '~components/organization/CustomerSubscriptionsTable/__generated__/CustomerSubscriptionsTable.graphql';
import { CustomerUsersTable } from '~components/organization/CustomerUsersTable';
import { CustomerUsersTableUserFragment } from '~components/organization/CustomerUsersTable/__generated__/CustomerUsersTable.graphql';
import { useCustomerUsersTable } from '~components/organization/CustomerUsersTable/useCustomerUsersTable';
import {
  customerBaseValidationSchema,
  customerErrorMap,
  toHandle,
} from '~components/organization/utils';
import { AvatarInput, FileUploadProps } from '~components/ui/AvatarInput';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import useGoBack from '~components/useGoBack';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { useFeatureFlag } from '~utils/features';
import { isDefined } from '~utils/types';
import { useS3Upload } from '~utils/useS3Upload';
import {
  useCreateCustomerMutation,
  useCreateCustomerPageQuery,
} from './__generated__/Create.graphql';

const schema = z.object({
  file: z.instanceof(File).optional(),
  uploadUrl: z.string().optional(),
  userIds: z.array(z.string()),
  subscriptionId: z.string().optional(),
  ...customerBaseValidationSchema,
});

type FormValues = z.infer<typeof schema>;

export function OrganizationCustomersCreatePage() {
  const handleGoBack = useGoBack();
  const toast = useToast();
  const [createCustomer] = useCreateCustomerMutation();
  const { upload } = useS3Upload();
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();

  const { data, loading, error } = useCreateCustomerPageQuery();

  const activeUserId = data?.me.id;
  const defaultUserIds = isDefined(activeUserId) ? [activeUserId] : [];

  const createForm = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      avatarUrl: undefined,
      userIds: defaultUserIds,
    },
    resolver: zodResolver(schema),
  });

  const { isSubmitting, isDirty, isValid } = createForm.formState;
  const canSubmit = !isSubmitting && isValid && isDirty;

  const handleCreateCustomer = async ({
    name,
    handle,
    avatarUrl,
    file,
    uploadUrl,
    userIds,
    subscriptionId,
  }: FormValues) => {
    if (!isNil(uploadUrl) && !isNil(file)) {
      try {
        await upload(uploadUrl, file);
      } catch (error) {
        toast({
          status: 'error',
          title: 'Cannot upload image',
          description: fromError(error, 'UploadFile'),
        });
        return;
      }
    }

    try {
      const { data } = await createCustomer({
        variables: {
          input: {
            handle,
            name,
            subscriptionId,
            avatarUrl: avatarUrl ?? null,
            ...(verifyUserPermissions([Permission.FeatureCustomerScopedAccess]) && { userIds }),
          },
        },
      });

      if (isNil(data)) {
        throw new Error('no data received');
      }

      analytics.track('customerCreateComplete');

      toast({
        status: 'success',
        title: 'The customer has been successfully created',
      });

      handleGoBack();
    } catch (error) {
      toast({
        status: 'error',
        title: 'Cannot edit the customer',
        description: fromError(error, 'EditCustomer', customerErrorMap),
      });
    }
  };

  // This page loads data asynchronously, so we need to "reset" the form when the data is available
  // to fill out the default values
  useEffect(() => {
    if (loading === false) {
      createForm.reset({
        userIds: defaultUserIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once, when the page is loaded
  }, [loading]);

  useEffect(() => {
    analytics.track('customerCreateStart');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run on initial mount
  }, []);

  return (
    <VerticalTabContent
      title="Create customer"
      isLoading={loading}
      error={error}
      onGoBack={handleGoBack}
      data={data?.organization}
      hasStickyHeader={true}
      action={{
        label: 'Create customer',
        disabled: !canSubmit,
        isLoading: isSubmitting,
        onClick: createForm.handleSubmit(handleCreateCustomer),
      }}
    >
      {(organization) => (
        <CreateCustomerForm
          defaultUserIds={defaultUserIds}
          users={organization.users}
          subscriptions={data?.organization.waveSubscriptions ?? []}
          form={createForm}
        />
      )}
    </VerticalTabContent>
  );
}

type CreateCustomerFormProps = {
  defaultUserIds?: string[];
  form: UseFormReturn<FormValues>;
  users: CustomerUsersTableUserFragment[];
  subscriptions: CustomerSubscriptionsTableSubscriptionFragment[];
};

function CreateCustomerForm({
  users,
  form,
  subscriptions,
  defaultUserIds = [],
}: CreateCustomerFormProps) {
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');
  const [subscriptionSelected, setSubscriptionSelected] = useState(false);

  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors, touchedFields },
  } = form;

  const { ref: nameInputRef, onChange: onNameChange, ...nameInputProps } = register('name');
  const currentSubscriptionId = watch('subscriptionId');

  const table = useCustomerUsersTable(
    users,
    defaultUserIds,
    isSubscriptionsEnabled ? subscriptionSelected : true,
  );

  function handleAvatarInputChange(uploadData: FileUploadProps) {
    if (isNil(uploadData.file)) {
      setValue('file', undefined, { shouldDirty: true });
    } else {
      setValue('file', uploadData.file, { shouldDirty: true });
    }

    setValue('avatarUrl', uploadData.uploadPayload?.assetUrl);
    setValue('uploadUrl', uploadData.uploadPayload?.uploadUrl);

    clearErrors('avatarUrl');
  }

  const handleAvatarInputError = (error: Error) => {
    setError('avatarUrl', { message: error.message });
  };

  const handleCustomerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onNameChange(event);

    if (!touchedFields.handle) {
      setValue('handle', toHandle(event.target.value));
    }
  };

  const handleSubscriptionIdChange = (subscriptionId: string | undefined) => {
    setValue('subscriptionId', subscriptionId, {
      shouldTouch: true,
      shouldDirty: true,
    });

    if (subscriptionId !== undefined) {
      setSubscriptionSelected(true);
    } else {
      setSubscriptionSelected(false);
    }
  };

  // Create the handle from the name if the field hasn't been touched
  useEffect(() => {
    const handle = getValues('handle') ?? '';
    const generatedHandle = toHandle(getValues('name') ?? '');

    if (handle !== generatedHandle) {
      setValue('handle', handle, { shouldTouch: true });
    }
  }, [getValues, setValue]);

  // When user selection changes, update the form data
  useEffect(() => {
    const checkedUserIds = Object.keys(table.state.selectedRowIds).sort();
    const selectionChangeUpdatedData = checkedUserIds.length > 0;

    setValue('userIds', checkedUserIds, {
      shouldTouch: true,
      shouldDirty: selectionChangeUpdatedData,
    });
  }, [setValue, table.state.selectedRowIds]);

  return (
    <form>
      <VStack alignItems="flex-start" spacing={10}>
        <FormControl isInvalid={Boolean(errors.avatarUrl)}>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            Upload customer logo
          </Heading>

          <AvatarInput onChange={handleAvatarInputChange} onError={handleAvatarInputError} />
          <FormErrorMessage>{errors.avatarUrl?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            Customer details
          </Heading>

          <FormControl isInvalid={Boolean(errors.name)} maxWidth="420" mb="8">
            <FormLabel marginRight="0">Customer name</FormLabel>
            <Input
              placeholder="My awesome customer"
              ref={nameInputRef}
              onChange={handleCustomerNameChange}
              {...nameInputProps}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.handle)} maxWidth="420">
            <FormLabel marginRight="0">Customer handle</FormLabel>
            <Input placeholder="my-awesome-customer" {...register('handle')} />
            <FormErrorMessage>{errors.handle?.message}</FormErrorMessage>
          </FormControl>
        </FormControl>

        <Shield requiredPermissions={[Permission.FeatureCustomerScopedAccess]}>
          <FormControl>
            <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
              Users
            </Heading>
            {isNil(users) || isEmpty(users) ? (
              <>No users</>
            ) : (
              <CustomerUsersTable
                table={table}
                isDisabled={isSubscriptionsEnabled ? !subscriptionSelected : false}
              />
            )}
          </FormControl>
        </Shield>

        <Shield requiredPermissions={[Permission.WaveSubscriptionUpdate]}>
          <FormControl>
            <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
              Subscriptions
            </Heading>
            <CustomerSubscriptions
              subscriptions={subscriptions}
              currentSubscriptionId={currentSubscriptionId}
              displayCount={0}
              onChange={handleSubscriptionIdChange}
            />
          </FormControl>
        </Shield>
      </VStack>
    </form>
  );
}

OrganizationCustomersCreatePage.graphql = {
  queries: {
    CreateCustomerPage: gql`
      query CreateCustomerPage {
        organization {
          users {
            ...CustomerUsersTableUser
          }
          waveSubscriptions(isAssignable: true) {
            ...CustomerSubscriptionsTableSubscription
          }
        }
        me {
          id
        }
      }
    `,
  },
  mutations: {
    CreateCustomer: gql`
      mutation CreateCustomer($input: CustomerCreateInput!) {
        customerCreate(input: $input) {
          id
          handle
          name
          avatarUrl
        }
      }
    `,
  },
};
