import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useAnalyticsReporter } from '~utils/analytics';
import { ensure, MaybePromise } from '~utils/types';
import {
  DisplayGroupsSelect_CustomerFragment,
  DisplayGroupsSelect_DisplayFragment,
  DisplayGroupsSelect_GroupFragment,
} from '../../__generated__/DisplayGroupSelect.graphql';
import { DisplayGroupsSelect } from '../../DisplayGroupSelect';
import {
  DisplayInlineGroupsSelect_CustomerFragment,
  DisplayInlineGroupsSelect_DisplayFragment,
  useAddGroupMutation,
  useDeleteGroupMutation,
  useDisplayInlineGrouppsSelect_GroupCreateMutation,
} from '../general/__generated__/DisplayInlineGroupsSelect.graphql';

interface Props {
  customer: DisplayInlineGroupsSelect_CustomerFragment;
  display: DisplayInlineGroupsSelect_DisplayFragment;
  isDisabled?: boolean;
  refetchData: () => MaybePromise<void>;
}

export function DisplayInlineGroupsSelect({
  customer,
  display,
  isDisabled = false,
  refetchData,
}: Props) {
  const [createGroup, createGroupMeta] = useDisplayInlineGrouppsSelect_GroupCreateMutation();
  const [displayAddGroup, displayAddGroupMeta] = useAddGroupMutation();
  const [displayDeleteGroup, displayDeleteGroupMeta] = useDeleteGroupMutation();
  const analytics = useAnalyticsReporter();

  const isLoading = useMemo(
    () => createGroupMeta.loading || displayAddGroupMeta.loading || displayDeleteGroupMeta.loading,
    [createGroupMeta, displayAddGroupMeta, displayDeleteGroupMeta],
  );

  const handleAdd = useCallback(
    async (
      display: DisplayGroupsSelect_DisplayFragment,
      group: DisplayGroupsSelect_GroupFragment,
    ) => {
      await displayAddGroup({
        variables: {
          input: {
            displayId: display.id,
            groupId: group.id,
          },
        },
      });
      analytics.track('displaySettingUpdate', { group: 'management', changeItem: 'groups' });
    },
    [analytics, displayAddGroup],
  );

  const handleRemove = useCallback(
    async (
      display: DisplayGroupsSelect_DisplayFragment,
      group: DisplayGroupsSelect_GroupFragment,
    ) => {
      await displayDeleteGroup({
        variables: {
          input: {
            displayId: display.id,
            groupId: group.id,
          },
        },
      });
      analytics.track('displaySettingUpdate', { group: 'management', changeItem: 'groups' });
      await refetchData();
    },
    [analytics, displayDeleteGroup, refetchData],
  );

  const handleCreate = useCallback(
    async (customer: DisplayGroupsSelect_CustomerFragment, name: string) => {
      const { data } = await createGroup({
        variables: {
          input: {
            customerId: customer.id,
            name,
          },
        },
      });

      await displayAddGroup({
        variables: {
          input: {
            displayId: display.id,
            groupId: ensure(data?.groupCreate?.group?.id),
          },
        },
      });
      analytics.track('displaySettingUpdate', { group: 'management', changeItem: 'groups' });
    },
    [analytics, createGroup, display.id, displayAddGroup],
  );

  return (
    <DisplayGroupsSelect
      customer={customer}
      display={display}
      value={display.groups}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onCreate={handleCreate}
      onAdd={handleAdd}
      onRemove={handleRemove}
    />
  );
}

DisplayInlineGroupsSelect.graphql = {
  fragments: {
    DisplayInlineGroupsSelect_display: gql`
      fragment DisplayInlineGroupsSelect_display on Display {
        id
        ...DisplayGroupsSelect_display
        groups {
          id
          ...DisplayGroupsSelect_group
        }
      }
    `,
    DisplayInlineGroupsSelect_customer: gql`
      fragment DisplayInlineGroupsSelect_customer on Customer {
        id
        ...DisplayGroupsSelect_customer
      }
    `,
  },
  mutations: {
    CreateGroup: gql`
      mutation DisplayInlineGrouppsSelect_GroupCreate($input: GroupCreateInput!) {
        groupCreate(input: $input) {
          group {
            id
            name
          }
          customer {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
    AddGroup: gql`
      mutation AddGroup($input: DisplayAddGroupInput!) {
        displayAddGroup(input: $input) {
          id
          groups {
            id
            name
          }
        }
      }
    `,
    DeleteGroup: gql`
      mutation DeleteGroup($input: DisplayDeleteGroupInput!) {
        displayDeleteGroup(input: $input) {
          id
          groups {
            id
            name
          }
        }
      }
    `,
  },
};
