import { Box, BoxProps, chakra } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Dimensions, useDimensions } from '~utils/dimensions';
import { OmitStrict } from '~utils/types';

interface ContextProps {
  height: number;
  setHeight: (height: number) => void;
}

const [_Provider, useContext] = createContext<ContextProps>({
  name: 'HeaderContext',
  errorMessage: '<Header /> must be used inside a <Header.Provider /> component.',
});

type Props = OmitStrict<BoxProps, 'zIndex' | 'position' | 'top' | 'left' | 'right'>;

export function Header({ children, ...rest }: Props) {
  const { setHeight } = useContext();
  const [ref] = useDimensions({
    onDimensions: useCallback(
      (dimensions: Dimensions) => setHeight(dimensions.height),
      [setHeight],
    ),
  });

  return (
    <>
      <chakra.header
        ref={ref}
        zIndex="sticky"
        position="fixed"
        top="0"
        left="0"
        right="unset"
        width="100vw"
        {...rest}
      >
        <Box width="100%" height="100%" display="flex" justifyContent="center">
          {children}
        </Box>
      </chakra.header>
    </>
  );
}

Header.Provider = function Provider({ children }: { children: ReactNode }) {
  const [height, setHeight] = useState(0);
  const ctx = useMemo(() => ({ height, setHeight }), [height]);

  return <_Provider value={ctx}>{children}</_Provider>;
};

Header.Spacer = function Spacer() {
  const { height } = useContext();

  return <Box height={`${height}px`} />;
};

export function useHeaderSpacing() {
  const { height } = useContext();
  return { height };
}
