/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type DisplayGroupsSelect_CustomerFragment = { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type DisplayGroupsSelect_DisplayFragment = { __typename: 'Display', id: string };

export type DisplayGroupsSelect_GroupFragment = { __typename: 'Group', id: string, name: string };

export const DisplayGroupsSelect_CustomerFragmentDoc = gql`
    fragment DisplayGroupsSelect_customer on Customer {
  id
  groups {
    id
    name
  }
}
    `;
export const DisplayGroupsSelect_DisplayFragmentDoc = gql`
    fragment DisplayGroupsSelect_display on Display {
  id
}
    `;
export const DisplayGroupsSelect_GroupFragmentDoc = gql`
    fragment DisplayGroupsSelect_group on Group {
  id
  name
}
    `;