import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';
import { TableInstance } from 'react-table';
import { SearchIcon } from '~components/ui/icons';
import { SubscriptionCustomersTableCustomerFragment as CustomerFragment } from './__generated__/SubscriptionCustomersTable.graphql';
import { Columns } from './types';

type Props = {
  table: TableInstance<CustomerFragment>;
};

export function SubscriptionCustomersTableFilter({ table }: Props) {
  const handleCustomerSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    table.setFilter(Columns.Name, event.target.value ?? '');
  };

  return (
    <InputGroup width="full" marginTop="6">
      <InputLeftElement pointerEvents="none" height="full">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        type="search"
        placeholder="Search customers"
        onChange={handleCustomerSearch}
        height="12"
      />
    </InputGroup>
  );
}
