import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export interface Dimensions {
  width: number;
  height: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
  x: number;
  y: number;
}

interface UseDimensionsOptions {
  onDimensions?: (dimensions: Dimensions) => void;
}

export function useDimensions<T extends HTMLElement>(options?: UseDimensionsOptions) {
  const ref = useRef<T>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    x: 0,
    y: 0,
  });

  const onDimensions = useCallback(
    (d: Dimensions) => {
      if (!options?.onDimensions) return;

      options.onDimensions(d);
    },
    [options],
  );

  const measure = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();
      const d = {
        width: rect.width,
        height: rect.height,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        x: rect.x,
        y: rect.y,
      };

      setDimensions(d);
      onDimensions(d);
    });
  }, [ref, onDimensions]);

  useLayoutEffect(() => {
    if (ref.current) {
      measure();
    }
  }, [measure]);

  return [ref, dimensions, measure] as const;
}
