/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { AddContentModal_CustomerFragmentDoc } from './AddContentModal.graphql';
import { PlaylistWarningAlert_DisplayFragmentDoc } from './PlaylistWarningAlert.graphql';
import { AddContentModal_DisplayFragmentDoc } from './AddContentModal.graphql';
export type ContentCard_CustomerFragment = { __typename: 'Customer', id: string, playlists: Array<{ __typename: 'Playlist', id: string, title: string, size: any }> };

export type ContentCard_DisplayFragment = { __typename: 'Display', id: string, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } }, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', title: string, size: any, id: string, isOutOfSync: boolean, isTampered: boolean } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, title: string, size: any, jobId: string, id: string, description?: string | null } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, title: string, jobId: string, id: string, description?: string | null } | null } | null, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> };

export const ContentCard_CustomerFragmentDoc = gql`
    fragment ContentCard_customer on Customer {
  id
  ...AddContentModal_customer
}
    ${AddContentModal_CustomerFragmentDoc}`;
export const ContentCard_DisplayFragmentDoc = gql`
    fragment ContentCard_display on Display {
  id
  bookmarks {
    all {
      reported
      desired
    }
  }
  playlist {
    current {
      title
      size
    }
    sync {
      title
      size
    }
  }
  ...PlaylistWarningAlert_display
  ...AddContentModal_display
}
    ${PlaylistWarningAlert_DisplayFragmentDoc}
${AddContentModal_DisplayFragmentDoc}`;