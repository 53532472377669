import capitalize from 'lodash/capitalize';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { MINUTES_IN_A_DAY } from '~components/displays/DisplayTable/constants';
import { Day, TimeBlock } from '~graphql/__generated__/types';

export const HOUR_REGEX = /^(0\d|1\d|2[0-3]):[0-5]\d$/;

export const DAYS_OF_WEEK_MON_FIRST = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
];

/**
 * @param timeBlock
 * @param prop
 * @returns a numeric value to compare time blocks and check overlap between them.
 * @example MONDAY 01:00 becomes 00100. FRIDAY 12:30 becomes 41230.
 */
const createNumericComparator = (timeBlock: TimeBlock, prop: 'end' | 'start') => {
  const dayIndex = Object.values(DAYS_OF_WEEK_MON_FIRST).indexOf(timeBlock.day);
  let timeValue = timeBlock[prop].replace(':', '');

  // start === '00:00' means start of the day
  //   end === '00:00' means end of the day,
  //                   which we're assigning the value X9999, so it doesn't conflict with the next day's start
  if (prop === 'end' && timeBlock.end === '00:00') timeValue = '9999';

  return parseInt(`${dayIndex}${timeValue}`);
};

const addComparatorsAndSort = (timeBlocks: TimeBlock[]) =>
  timeBlocks
    .filter(Boolean)
    .map((timeBlock) => ({
      start: timeBlock.start,
      end: timeBlock.end,
      day: timeBlock.day,
      startComparator: createNumericComparator(timeBlock, 'start'),
      endComparator: createNumericComparator(timeBlock, 'end'),
    }))
    .sort(({ startComparator: c1 }, { startComparator: c2 }) => c1 - c2);

export const validateAndSortTimeBlocks = (
  timeBlocks: TimeBlock[],
  options?: {
    useShortErrorMessage?: boolean;
    throwError?: boolean;
  },
): {
  validatedTimeBlocks: TimeBlock[];
  conflictingTimeBlocks: TimeBlock[];
} => {
  const { useShortErrorMessage = false, throwError = true } = options || {};
  const sortedTimeBlocksWithComparators = addComparatorsAndSort(timeBlocks);

  sortedTimeBlocksWithComparators.forEach(({ start, end, day, startComparator, endComparator }) => {
    if (!Object.values(Day).includes(day)) {
      throw new Error(`Invalid day "${day}".`);
    }

    if (!HOUR_REGEX.test(start)) {
      throw new Error(`${start} is an invalid start hour.`);
    }

    if (!HOUR_REGEX.test(end)) {
      throw new Error(`${end} is an invalid end hour.`);
    }

    if (startComparator >= endComparator) {
      throw new Error(`Start hour ${start} is not before ${end}.`);
    }
  });

  const conflictingTimeBlocks = sortedTimeBlocksWithComparators.filter(
    ({ startComparator }, i, arr) => i !== 0 && startComparator <= arr[i - 1].endComparator,
  );
  if (throwError && conflictingTimeBlocks.length)
    throw new Error(
      useShortErrorMessage
        ? 'This slot conflicts with an existing time slot.'
        : `This slot conflicts with existing time slots on ${uniq(
            conflictingTimeBlocks.map(({ day }) => capitalize(day)),
          )
            .join(', ')
            .replace(
              /,(?!.*,)/gim,
              ' and',
            )}. Please adjust your slot to avoid overlap with existing slots.`,
    );

  return {
    validatedTimeBlocks: sortedTimeBlocksWithComparators.map(({ start, end, day }) => ({
      start,
      end,
      day,
    })),
    conflictingTimeBlocks,
  };
};

export const createNextTimeBlockActionLabel = (
  timeBlocks: TimeBlock[],
  timeZone: string | undefined,
): string => {
  const sortedTimeBlocks = addComparatorsAndSort(timeBlocks);
  const currentDate = DateTime.now().setZone(timeZone);
  const currentTime = currentDate.toFormat('HH:mm');
  const currentTimeComparator = createNumericComparator(
    { day: DAYS_OF_WEEK_MON_FIRST[currentDate.weekday - 1], start: currentTime, end: currentTime },
    'start',
  );

  const nextTimeBlock =
    sortedTimeBlocks.find(({ endComparator }) => endComparator > currentTimeComparator) ||
    sortedTimeBlocks[0];

  if (!nextTimeBlock) return 'This power schedule is empty';

  let dayIndex = DAYS_OF_WEEK_MON_FIRST.indexOf(nextTimeBlock.day);

  const dayLabel = (index: number): string => {
    if (index === currentDate.weekday - 1) return 'today';
    if (index === currentDate.weekday) return 'tomorrow';
    return `on ${capitalize(DAYS_OF_WEEK_MON_FIRST[index])}`;
  };

  if (
    nextTimeBlock.startComparator <= currentTimeComparator &&
    nextTimeBlock.endComparator >= currentTimeComparator
  ) {
    if (nextTimeBlock.end === '00:00') dayIndex = dayIndex === 6 ? 0 : dayIndex + 1;
    return `Standby ${dayLabel(dayIndex)} at ${nextTimeBlock.end}`;
  }

  return `Power ON ${dayLabel(dayIndex)} at ${nextTimeBlock.start}`;
};

export const hourToDecimal = (property: 'start' | 'end', time: string) => {
  if (property === 'end' && time === '00:00') return 1;

  const [hours, minutes] = time.split(':');
  return (parseInt(hours) * 60 + parseInt(minutes)) / MINUTES_IN_A_DAY;
};
