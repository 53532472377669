import { gql } from '@apollo/client';
import { Box, Divider } from '@chakra-ui/react';
import { DisplayContentSource } from '~components/displays/DisplayDetail/playback/DisplayContentSource';
import { DisplayVolumeSettings } from '~components/displays/DisplayDetail/playback/DisplayVolumeSettings';
import { NowPlayingCard_DisplayFragment } from '~components/displays/DisplayDetail/summary/__generated__/NowPlayingCard.graphql';
import { DisplayScreenshot } from '~components/displays/DisplayDetail/summary/DisplayScreenshot';
import { SummaryCard } from '~components/displays/DisplayDetail/summary/SummaryCard';
import { isContentSourcesSupported as isContentSourcesSupportedByDisplay } from '~components/displays/utils';

interface Props {
  display: NowPlayingCard_DisplayFragment;
}

export function NowPlayingCard({ display }: Props) {
  const isContentSourcesSupported = isContentSourcesSupportedByDisplay(display);

  return (
    <SummaryCard title="Now playing">
      <Box textAlign="center">
        <DisplayScreenshot display={display} />
      </Box>
      <Divider mb="12px" />
      <Box mb="3">{isContentSourcesSupported && <DisplayContentSource display={display} />}</Box>
      <DisplayVolumeSettings display={display} />
    </SummaryCard>
  );
}

NowPlayingCard.graphql = {
  fragments: {
    NowPlayingCard_display: gql`
      fragment NowPlayingCard_display on Display {
        id
        ...DisplayScreenshot_display
        ...DisplayContentSource_display
        ...DisplayVolumeSettings_display
      }
    `,
  },
};
