/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type ConnectivitySection_DisplayFragment = { __typename: 'Display', id: string, networkInformation: { __typename: 'DisplayNetworkInformation', ethernetMacAddress: string, wifiMacAddress?: string | null, localIPAddress?: string | null, networkType?: Types.NetworkType | null } };

export const ConnectivitySection_DisplayFragmentDoc = gql`
    fragment ConnectivitySection_display on Display {
  id
  networkInformation {
    ethernetMacAddress
    wifiMacAddress
    localIPAddress
    networkType
  }
}
    `;