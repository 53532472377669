/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CustomersTableCustomerFragment = { __typename: 'Customer', id: string, name: string, displayCount: number, users: Array<{ __typename: 'User', id: string }>, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null };

export const CustomersTableCustomerFragmentDoc = gql`
    fragment CustomersTableCustomer on Customer {
  id
  name
  users {
    id
  }
  waveSubscription {
    id
  }
  displayCount
}
    `;