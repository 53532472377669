import { DateTime } from 'luxon';
import { useMemo } from 'react';
import Select from 'react-select';
import { LiteralUnion } from 'type-fest';
import { components } from '~components/ui/Select';

type CanonicalTimeZone = string; // e.g. Europe/Brussels
export type TimeZone = LiteralUnion<'DISABLED', CanonicalTimeZone>;

interface Props {
  tabIndex?: number;
  value?: TimeZone;
  supportedValues?: string[];
  onChange: (timeZone: string) => void | Promise<void>;
  isLoading?: boolean;
  isDisabled?: boolean;
}

interface SelectOption {
  label: string;
  value: string;
}

export function DisplayTimeZoneSelect({
  tabIndex,
  value,
  supportedValues,
  onChange,
  isLoading = false,
  isDisabled = false,
}: Props) {
  const currentOption = useMemo(() => {
    if (!value) {
      return null;
    }

    return toTimeZoneOption(value);
  }, [value]);

  if (!supportedValues) {
    return null;
  }

  const selectOptions = mapTimeZonesToSelectOptions(supportedValues);

  return (
    <Select
      tabIndex={tabIndex}
      value={currentOption}
      options={selectOptions}
      components={{
        ...components,
      }}
      onChange={(option) => {
        if (!option) return;
        if (option.value === 'DISABLED') return;
        onChange(option.value);
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isSearchable={true}
      isClearable={false}
      isMulti={false}
      menuPlacement="auto"
    />
  );
}

function mapTimeZonesToSelectOptions(timeZones: string[]): SelectOption[] {
  return timeZones.map(toTimeZoneOption).sort((a, b) => a.label.localeCompare(b.label));
}

function toTimeZoneOption(timeZone: TimeZone): SelectOption {
  if (timeZone === 'DISABLED') {
    return {
      label: 'Auto-sync disabled',
      value: timeZone,
    };
  }

  const city = timeZone.split('/').pop()?.replaceAll('_', ' ') ?? 'Unknown';
  const regio = timeZone.split('/')[0];
  const regioSuffix = regio ? `, ${regio}` : '';
  const offset = DateTime.now().setZone(timeZone).setLocale('fr').offsetNameShort;
  return {
    label: `${city}${regioSuffix} (${offset})`,
    value: timeZone,
  };
}
