import { createIcon } from '@chakra-ui/react';

export const StandbyIcon = createIcon({
  displayName: 'StandbyIcon',
  viewBox: '0 0 24 25',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M20.9999 13.29C20.8426 14.9922 20.2038 16.6144 19.1581 17.9668C18.1125 19.3192 16.7034 20.3458 15.0956 20.9265C13.4878 21.5073 11.7479 21.6181 10.0794 21.2461C8.41092 20.8741 6.8829 20.0345 5.67413 18.8258C4.46536 17.617 3.62584 16.089 3.25381 14.4205C2.88178 12.752 2.99262 11.0121 3.57336 9.4043C4.15411 7.79651 5.18073 6.38737 6.53311 5.34175C7.8855 4.29614 9.5077 3.65731 11.2099 3.5C10.2133 4.84827 9.73375 6.50945 9.85843 8.18141C9.98312 9.85338 10.7038 11.4251 11.8893 12.6106C13.0748 13.7961 14.6465 14.5168 16.3185 14.6415C17.9905 14.7662 19.6516 14.2866 20.9999 13.29Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
