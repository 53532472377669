import { createIcon } from '@chakra-ui/react';

export const UnlockIcon = createIcon({
  displayName: 'UnlockIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M6 11C6 11.5523 6.44772 12 7 12C7.55228 12 8 11.5523 8 11H6ZM7 7H8L8 6.999L7 7ZM15.92 6.199C16.0299 6.74024 16.5578 7.0899 17.099 6.98C17.6402 6.8701 17.9899 6.34225 17.88 5.80101L15.92 6.199ZM5 12H19V10H5V12ZM19 12C19.5523 12 20 12.4477 20 13H22C22 11.3431 20.6569 10 19 10V12ZM20 13V20H22V13H20ZM20 20C20 20.5523 19.5523 21 19 21V23C20.6569 23 22 21.6569 22 20H20ZM19 21H5V23H19V21ZM5 21C4.44772 21 4 20.5523 4 20H2C2 21.6569 3.34315 23 5 23V21ZM4 20V13H2V20H4ZM4 13C4 12.4477 4.44772 12 5 12V10C3.34315 10 2 11.3431 2 13H4ZM8 11V7H6V11H8ZM8 6.999C7.999 6.00703 8.36662 5.05009 9.0315 4.31393L7.54725 2.97341C6.54994 4.07765 5.99851 5.51306 6 7.00101L8 6.999ZM9.0315 4.31393C9.69637 3.57777 10.6111 3.11492 11.598 3.01523L11.397 1.02535C9.9166 1.17489 8.54456 1.86917 7.54725 2.97341L9.0315 4.31393ZM11.598 3.01523C12.5849 2.91554 13.5737 3.18612 14.3724 3.77445L15.5586 2.16418C14.3606 1.28169 12.8774 0.875816 11.397 1.02535L11.598 3.01523ZM14.3724 3.77445C15.171 4.36277 15.7226 5.22687 15.92 6.199L17.88 5.80101C17.5839 4.34282 16.7566 3.04667 15.5586 2.16418L14.3724 3.77445Z"
      fill="currentColor"
    />
  ),
});
