/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UsePlaylists_DisplayFragmentDoc } from '../../../__generated__/usePlaylists.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PlaylistWarningAlert_DisplayFragment = { __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', title: string, id: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, jobId: string, id: string, title: string, description?: string | null } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, jobId: string, id: string, title: string, description?: string | null } | null } | null };

export type AbortPlaylistJobMutationVariables = Types.Exact<{
  input: Types.DisplayAbortJobInput;
}>;


export type AbortPlaylistJobMutation = { __typename: 'Mutation', displayAbortJob: { __typename: 'DisplayAbortJobPayload', display: { __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', title: string, id: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, jobId: string, id: string, title: string, description?: string | null } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, jobId: string, id: string, title: string, description?: string | null } | null } | null } } };

export const PlaylistWarningAlert_DisplayFragmentDoc = gql`
    fragment PlaylistWarningAlert_display on Display {
  id
  playlist {
    current {
      title
    }
    sync {
      jobId
    }
  }
  ...UsePlaylists_display
}
    ${UsePlaylists_DisplayFragmentDoc}`;
export const AbortPlaylistJobDocument = gql`
    mutation AbortPlaylistJob($input: DisplayAbortJobInput!) {
  displayAbortJob(input: $input) {
    display {
      id
      ...PlaylistWarningAlert_display
    }
  }
}
    ${PlaylistWarningAlert_DisplayFragmentDoc}`;
export type AbortPlaylistJobMutationFn = ApolloReactCommon.MutationFunction<AbortPlaylistJobMutation, AbortPlaylistJobMutationVariables>;

/**
 * __useAbortPlaylistJobMutation__
 *
 * To run a mutation, you first call `useAbortPlaylistJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortPlaylistJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortPlaylistJobMutation, { data, loading, error }] = useAbortPlaylistJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAbortPlaylistJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AbortPlaylistJobMutation, AbortPlaylistJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AbortPlaylistJobMutation, AbortPlaylistJobMutationVariables>(AbortPlaylistJobDocument, options);
      }
export type AbortPlaylistJobMutationHookResult = ReturnType<typeof useAbortPlaylistJobMutation>;
export type AbortPlaylistJobMutationResult = ApolloReactCommon.MutationResult<AbortPlaylistJobMutation>;
export type AbortPlaylistJobMutationOptions = ApolloReactCommon.BaseMutationOptions<AbortPlaylistJobMutation, AbortPlaylistJobMutationVariables>;