import { gql } from '@apollo/client';
import {
  Box,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { useManageWebPagesForm } from '~components/displays/useManageWebPagesForm';
import { BulkActionComponent } from './BulkActionModal';

export const BulkEditWebPages: BulkActionComponent = ({
  initialFocusRef,
  displays,
  onCancel,
  onSuccess,
  onBack,
}) => {
  const { body, footer, onSubmit } = useManageWebPagesForm({
    displayIds: displays.map((display) => display.id),
    onSuccess: onSuccess,
    onCancel: onCancel,
    initialFocusRef: initialFocusRef as MutableRefObject<HTMLInputElement>,
    defaultBookmarks: [],
  });
  return (
    <ModalContent>
      <ModalHeader>Edit Web Pages</ModalHeader>
      <ModalCloseButton onClick={onCancel} />

      <form onSubmit={onSubmit}>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <HStack flex="1" justifyContent="space-between">
            <BackToAllActionsButton onBack={onBack} />
            <Box>{footer}</Box>
          </HStack>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

BulkEditWebPages.graphql = {
  fragments: {
    BulkEditWebPages_display: gql`
      fragment BulkEditWebPages_display on Display {
        id
        bookmarks {
          all {
            reported
            desired
          }
        }
      }
    `,
  },
};
