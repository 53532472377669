/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationUsersIndexPage_UserFragment = { __typename: 'User', id: string, email: string, roles: Array<Types.Role>, givenName: string, familyName: string, customerIds?: Array<string> | null };

export type OrganizationUsersIndexPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationUsersIndexPageQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, users: Array<{ __typename: 'User', id: string, email: string, roles: Array<Types.Role>, givenName: string, familyName: string, customerIds?: Array<string> | null }> } };

export const OrganizationUsersIndexPage_UserFragmentDoc = gql`
    fragment OrganizationUsersIndexPage_user on User {
  id
  email
  roles
  givenName
  familyName
  customerIds
}
    `;
export const OrganizationUsersIndexPageDocument = gql`
    query OrganizationUsersIndexPage {
  organization {
    id
    users {
      ...OrganizationUsersIndexPage_user
    }
  }
}
    ${OrganizationUsersIndexPage_UserFragmentDoc}`;

/**
 * __useOrganizationUsersIndexPageQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationUsersIndexPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationUsersIndexPageQuery, OrganizationUsersIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrganizationUsersIndexPageQuery, OrganizationUsersIndexPageQueryVariables>(OrganizationUsersIndexPageDocument, options);
      }
export function useOrganizationUsersIndexPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationUsersIndexPageQuery, OrganizationUsersIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrganizationUsersIndexPageQuery, OrganizationUsersIndexPageQueryVariables>(OrganizationUsersIndexPageDocument, options);
        }
export type OrganizationUsersIndexPageQueryHookResult = ReturnType<typeof useOrganizationUsersIndexPageQuery>;
export type OrganizationUsersIndexPageLazyQueryHookResult = ReturnType<typeof useOrganizationUsersIndexPageLazyQuery>;
export type OrganizationUsersIndexPageQueryResult = ApolloReactCommon.QueryResult<OrganizationUsersIndexPageQuery, OrganizationUsersIndexPageQueryVariables>;