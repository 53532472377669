/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type UseDisplayPresence_DisplayFragment = { __typename: 'Display', id: string, presence?: { __typename: 'DisplayPresence', connected: boolean, timestamp: any } | null, power?: { __typename: 'PowerState', reported: Types.Power, desired?: Types.Power | null } | null };

export const UseDisplayPresence_DisplayFragmentDoc = gql`
    fragment UseDisplayPresence_display on Display {
  id
  presence {
    connected
    timestamp
  }
  power {
    reported
    desired
  }
}
    `;