import uniqueId from 'lodash/uniqueId';
import { Day } from '~graphql/__generated__/types';

/**
 * @param children a string
 * @returns the string as JSX, with days (e.g. Wednesday) and times (e.g. 10:00) wrapped in <strong> tags.
 */
export const HighlightDaysAndTimes = ({ children }: { children: string }) => (
  <>
    {children
      .split(
        new RegExp(`(${Object.keys(Day).join('|')}|(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])`, 'ig'),
      )
      .filter(Boolean)
      .map((x, i) =>
        i & 1 ? <strong key={uniqueId()}>{x}</strong> : <span key={uniqueId()}>{x}</span>,
      )}
  </>
);
