/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UserCustomersTable_CustomerFragmentDoc } from '../../../../../components/organization/UserCustomersTable/__generated__/UserCustomersTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UserEditPage_UserFragment = { __typename: 'User', id: string, email: string, roles: Array<Types.Role>, givenName: string, familyName: string, customerIds?: Array<string> | null };

export type UserEditPageOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserEditPageOrganizationQuery = { __typename: 'Query', customersByOrganization: { __typename: 'QueryCustomersByOrganizationPayload', customers: Array<{ __typename: 'PartialCustomer', id: string, name: string, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null }> }, organization: { __typename: 'Organization', id: string, users: Array<{ __typename: 'User', id: string, email: string, roles: Array<Types.Role>, givenName: string, familyName: string, customerIds?: Array<string> | null }> } };

export type EditUserMutationVariables = Types.Exact<{
  input: Types.UserUpdateInput;
}>;


export type EditUserMutation = { __typename: 'Mutation', userUpdate: { __typename: 'UserUpdatePayload', user: { __typename: 'User', id: string, email: string, roles: Array<Types.Role>, givenName: string, familyName: string, customerIds?: Array<string> | null } } };

export const UserEditPage_UserFragmentDoc = gql`
    fragment UserEditPage_user on User {
  id
  email
  roles
  givenName
  familyName
  customerIds
}
    `;
export const UserEditPageOrganizationDocument = gql`
    query UserEditPageOrganization {
  customersByOrganization {
    customers {
      ...UserCustomersTable_customer
    }
  }
  organization {
    id
    users {
      ...UserEditPage_user
    }
  }
}
    ${UserCustomersTable_CustomerFragmentDoc}
${UserEditPage_UserFragmentDoc}`;

/**
 * __useUserEditPageOrganizationQuery__
 *
 * To run a query within a React component, call `useUserEditPageOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditPageOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditPageOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEditPageOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserEditPageOrganizationQuery, UserEditPageOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserEditPageOrganizationQuery, UserEditPageOrganizationQueryVariables>(UserEditPageOrganizationDocument, options);
      }
export function useUserEditPageOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserEditPageOrganizationQuery, UserEditPageOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserEditPageOrganizationQuery, UserEditPageOrganizationQueryVariables>(UserEditPageOrganizationDocument, options);
        }
export type UserEditPageOrganizationQueryHookResult = ReturnType<typeof useUserEditPageOrganizationQuery>;
export type UserEditPageOrganizationLazyQueryHookResult = ReturnType<typeof useUserEditPageOrganizationLazyQuery>;
export type UserEditPageOrganizationQueryResult = ApolloReactCommon.QueryResult<UserEditPageOrganizationQuery, UserEditPageOrganizationQueryVariables>;
export const EditUserDocument = gql`
    mutation EditUser($input: UserUpdateInput!) {
  userUpdate(input: $input) {
    user {
      id
      ...UserEditPage_user
    }
  }
}
    ${UserEditPage_UserFragmentDoc}`;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;