/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerSitesPageQueryVariables = Types.Exact<{
  customerHandle: Types.Scalars['String'];
}>;


export type CustomerSitesPageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, sites: Array<{ __typename: 'Site', id: string, name: string, address?: string | null }> } | null };


export const CustomerSitesPageDocument = gql`
    query CustomerSitesPage($customerHandle: String!) {
  customer: customerByHandle(handle: $customerHandle) {
    id
    sites {
      id
      name
      address
    }
  }
}
    `;

/**
 * __useCustomerSitesPageQuery__
 *
 * To run a query within a React component, call `useCustomerSitesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSitesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSitesPageQuery({
 *   variables: {
 *      customerHandle: // value for 'customerHandle'
 *   },
 * });
 */
export function useCustomerSitesPageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CustomerSitesPageQuery, CustomerSitesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerSitesPageQuery, CustomerSitesPageQueryVariables>(CustomerSitesPageDocument, options);
      }
export function useCustomerSitesPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerSitesPageQuery, CustomerSitesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerSitesPageQuery, CustomerSitesPageQueryVariables>(CustomerSitesPageDocument, options);
        }
export type CustomerSitesPageQueryHookResult = ReturnType<typeof useCustomerSitesPageQuery>;
export type CustomerSitesPageLazyQueryHookResult = ReturnType<typeof useCustomerSitesPageLazyQuery>;
export type CustomerSitesPageQueryResult = ApolloReactCommon.QueryResult<CustomerSitesPageQuery, CustomerSitesPageQueryVariables>;