/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CustomerUsersTableUserFragmentDoc } from '../../../../../components/organization/CustomerUsersTable/__generated__/CustomerUsersTable.graphql';
import { CustomerSubscriptionsTableSubscriptionFragmentDoc } from '../../../../../components/organization/CustomerSubscriptionsTable/__generated__/CustomerSubscriptionsTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerPageCustomerFragment = { __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null, displayCount: number, waveSubscription?: { __typename: 'WaveSubscription', id: string, name: string } | null, users: Array<{ __typename: 'User', id: string }> };

export type CustomerPageQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
}>;


export type CustomerPageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null, displayCount: number, waveSubscription?: { __typename: 'WaveSubscription', id: string, name: string } | null, users: Array<{ __typename: 'User', id: string }> } | null, organization: { __typename: 'Organization', users: Array<{ __typename: 'User', id: string, givenName: string, familyName: string, email: string, roles: Array<Types.Role> }>, waveSubscriptions?: Array<{ __typename: 'WaveSubscription', id: string, name: string, validUntil: any, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null }, customers: Array<{ __typename: 'PartialCustomer', name: string }> }> | null } };

export type EditCustomerMutationVariables = Types.Exact<{
  input: Types.CustomerUpdateInput;
}>;


export type EditCustomerMutation = { __typename: 'Mutation', customerUpdate: { __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null, waveSubscription?: { __typename: 'WaveSubscription', id: string, name: string } | null } };

export const CustomerPageCustomerFragmentDoc = gql`
    fragment CustomerPageCustomer on Customer {
  id
  handle
  name
  avatarUrl
  displayCount
  waveSubscription {
    id
    name
  }
  users {
    id
  }
}
    `;
export const CustomerPageDocument = gql`
    query CustomerPage($customerId: ID!) {
  customer(id: $customerId) {
    ...CustomerPageCustomer
  }
  organization {
    users {
      ...CustomerUsersTableUser
    }
    waveSubscriptions(isAssignable: true) {
      ...CustomerSubscriptionsTableSubscription
    }
  }
}
    ${CustomerPageCustomerFragmentDoc}
${CustomerUsersTableUserFragmentDoc}
${CustomerSubscriptionsTableSubscriptionFragmentDoc}`;

/**
 * __useCustomerPageQuery__
 *
 * To run a query within a React component, call `useCustomerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPageQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerPageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CustomerPageQuery, CustomerPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerPageQuery, CustomerPageQueryVariables>(CustomerPageDocument, options);
      }
export function useCustomerPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerPageQuery, CustomerPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerPageQuery, CustomerPageQueryVariables>(CustomerPageDocument, options);
        }
export type CustomerPageQueryHookResult = ReturnType<typeof useCustomerPageQuery>;
export type CustomerPageLazyQueryHookResult = ReturnType<typeof useCustomerPageLazyQuery>;
export type CustomerPageQueryResult = ApolloReactCommon.QueryResult<CustomerPageQuery, CustomerPageQueryVariables>;
export const EditCustomerDocument = gql`
    mutation EditCustomer($input: CustomerUpdateInput!) {
  customerUpdate(input: $input) {
    id
    handle
    name
    avatarUrl
    waveSubscription {
      id
      name
    }
  }
}
    `;
export type EditCustomerMutationFn = ApolloReactCommon.MutationFunction<EditCustomerMutation, EditCustomerMutationVariables>;

/**
 * __useEditCustomerMutation__
 *
 * To run a mutation, you first call `useEditCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerMutation, { data, loading, error }] = useEditCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCustomerMutation, EditCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditCustomerMutation, EditCustomerMutationVariables>(EditCustomerDocument, options);
      }
export type EditCustomerMutationHookResult = ReturnType<typeof useEditCustomerMutation>;
export type EditCustomerMutationResult = ApolloReactCommon.MutationResult<EditCustomerMutation>;
export type EditCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCustomerMutation, EditCustomerMutationVariables>;