/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PlaylistCell_ValueFragment = { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', title: string } | null };

export type PlaylistCell_DisplayFragment = { __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', title: string } | null } | null };

export const PlaylistCell_ValueFragmentDoc = gql`
    fragment PlaylistCell_value on DisplayPlaylist {
  current {
    title
  }
}
    `;
export const PlaylistCell_DisplayFragmentDoc = gql`
    fragment PlaylistCell_display on Display {
  id
  playlist {
    ...PlaylistCell_value
  }
}
    ${PlaylistCell_ValueFragmentDoc}`;