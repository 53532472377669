import { Box, Button, chakra, Flex, Heading, HStack, StackProps, Tooltip } from '@chakra-ui/react';
import { ComponentType, useEffect, useMemo, useState } from 'react';
import { IconProps, LoadingIcon, TrashIcon } from '~components/ui/icons';

export type ContentItemProps = {
  disabled?: boolean;
  handleDelete?: () => void;
  icon?: ComponentType<IconProps>;
  progressLabel?: string;
  subtitle?: string;
  title: string;
};

export function ContentItem({
  disabled = false,
  handleDelete,
  icon: Icon,
  progressLabel: actualProgressLabel,
  subtitle,
  title,
  ...rest
}: ContentItemProps & StackProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  // Allows overriding the progress label depending on local deletion state
  const progressLabel = useMemo(() => {
    return isDeleting ? 'Deletion is pending' : actualProgressLabel;
  }, [isDeleting, actualProgressLabel]);

  const showIcon = Icon !== undefined;
  const showProgress = progressLabel !== undefined;
  const showSubtitle = subtitle !== undefined;
  const showDeleteButton = handleDelete !== undefined && isDeleting === false;
  const isGreyedOut = disabled || isDeleting;

  // Update the UI so we get immediate feedback on the user's click
  const onClickDelete = async () => {
    setIsDeleting(true);
    await handleDelete?.();
    setIsDeleting(false);
  };

  // Flip the override flag back when any of the props change (due to polling or a response from the delete mutation)
  useEffect(() => {
    setIsDeleting(false);
  }, [title, subtitle, actualProgressLabel]);

  return (
    <HStack spacing="3" {...rest}>
      {showIcon && (
        <Box alignSelf="start" lineHeight="7" opacity={isGreyedOut ? '0.5' : undefined}>
          <Icon width="6" height="6" />
        </Box>
      )}
      <Box flex="1" wordBreak="break-word">
        <HStack spacing={3} alignItems="center">
          <Heading
            size="md"
            color="gray.900"
            mb="0"
            lineHeight="7"
            opacity={isGreyedOut ? '0.5' : undefined}
          >
            {title}
          </Heading>
          {showProgress && (
            <Tooltip label={progressLabel}>
              <Flex
                aria-label={progressLabel}
                justifyContent="center"
                alignItems="center"
                height="7"
                minWidth="7"
              >
                <LoadingIcon color="gray.400" height={5} width={5} />
              </Flex>
            </Tooltip>
          )}
        </HStack>
        {showSubtitle &&
          (subtitle === '' ? (
            <span>&nbsp;</span>
          ) : (
            <chakra.span
              color="gray.500"
              fontWeight="normal"
              opacity={isGreyedOut ? '0.5' : undefined}
            >
              {subtitle}
            </chakra.span>
          ))}
      </Box>
      {showDeleteButton && (
        <Button
          size="sm"
          variant="inline"
          colorScheme="gray"
          onClick={onClickDelete}
          isDisabled={isGreyedOut}
        >
          <TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
        </Button>
      )}
    </HStack>
  );
}
