import { gql } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { RelativeTime } from '~components/ui/RelativeTime';
import { PlaylistTable_PlaylistFragment } from '../__generated__/PlaylistTable.graphql';

export function CreatedAtCell({
  value,
}: PropsWithChildren<CellProps<PlaylistTable_PlaylistFragment, string>>) {
  return (
    <Box>
      <RelativeTime ts={value} render={(t) => <Text fontSize="sm">{t}</Text>} />
    </Box>
  );
}

CreatedAtCell.graphql = {
  fragments: {
    CreatedAtCell_playlist: gql`
      fragment CreatedAtCell_playlist on Playlist {
        id
        createdAt
      }
    `,
  },
};
