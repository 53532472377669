import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { PlaylistTableMedia } from '../PlaylistMediaTable';

export function TitleCell({ value }: PropsWithChildren<CellProps<PlaylistTableMedia, string>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontWeight="semibold">
      {value}
    </Box>
  );
}

TitleCell.graphql = {
  fragments: {
    TitleCell_media: gql`
      fragment TitleCell_media on Media {
        id
        title
      }
    `,
  },
};
