/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CreatedAtCell_MediaFragment = { __typename: 'Media', id: string, createdAt: any };

export const CreatedAtCell_MediaFragmentDoc = gql`
    fragment CreatedAtCell_media on Media {
  id
  createdAt
}
    `;