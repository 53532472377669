import { Box, IconProps } from '@chakra-ui/react';
import Loading from 'react-loading';

/**
 * This icon is actually from "react-loading"
 * but we wrap it in an SVG-like structure, so it blends with the other icons in terms of usage.
 * Note that this icon will accept any SVG Prop but won't apply all of them.
 */
export function LoadingIcon({ height = '4', width = '4', color = 'blue.500' }: IconProps) {
  return (
    <Box height={height} width={width} color={color}>
      <Loading height="100%" width="100%" color={'currentColor'} type="bars" />
    </Box>
  );
}
