import { ApolloError } from '@apollo/client';
import { Box, Button, Heading, HStack, Stack, StackProps } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { isNil } from 'lodash';
import { forwardRef, ReactNode, Ref, useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Tab } from '~components/ui/Tabs';
import { HandleApiError } from '~graphql/HandleApiError';
import { BackButton } from './BackButton';
import { Link } from './Link';
import { PageLoader } from './PageLoader';
import { useTheme } from './styles/hooks';

export function VerticalTabs({ children, ...rest }: { children: ReactNode } & StackProps) {
  return (
    <HStack alignItems="flex-start" spacing="10" {...rest}>
      {children}
    </HStack>
  );
}

export function VerticalTabList({
  children,
  stickyOffset,
  ...rest
}: {
  children: ReactNode;
  stickyOffset?: number;
} & StackProps) {
  return (
    <Stack
      position="sticky"
      direction="column"
      top={(stickyOffset || 100) + 16}
      left="0"
      minWidth="255"
      paddingBottom="7"
      maxHeight={`calc(100vh - ${stickyOffset}px)`}
      overflow="auto"
      {...rest}
    >
      {children}
    </Stack>
  );
}

export function VerticalTabDivider() {
  return <Box borderRight="1px solid" borderColor="gray.100" alignSelf="stretch" />;
}

export const VerticalTab = forwardRef(function Tab(
  { tab: { label, icon: Icon, toLink, isActive, header }, prevTab }: { tab: Tab; prevTab?: Tab },
  ref: Ref<HTMLAnchorElement>,
) {
  return (
    <>
      {header !== prevTab?.header && (
        <Heading
          fontSize="20"
          color="gray.900"
          paddingY="2"
          marginTop="40px !important"
          _first={{ marginTop: '0 !important' }}
          marginBottom="2"
        >
          {header}
        </Heading>
      )}
      <Link
        ref={ref}
        to={toLink}
        display="flex"
        padding="4"
        marginBottom="2"
        color={isActive ? 'blue.500' : 'gray.500'}
        fontWeight="semibold"
        _hover={{
          textDecoration: 'none',
          color: 'gray.700',
        }}
        _focus={{
          textDecoration: 'none',
          color: 'blue.500',
        }}
      >
        {Icon && <Icon width="6" height="6" marginRight="5" />}
        {label}
      </Link>
    </>
  );
});

export function VerticalTabPanel({ children }: { children: ReactNode }) {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          flex: 1,
          alignSelf: 'stretch',
        }}
      >
        <Box paddingBottom="7" height="full">
          {children}
        </Box>
      </motion.div>
    </AnimatePresence>
  );
}

type VerticalTabHeaderActionProps = {
  isLoading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  label: string;
};

type VerticalTabHeaderProps = {
  title?: string;
  onGoBack?: () => void;
  isSticky?: boolean;
  action?: VerticalTabHeaderActionProps;
};

export function VerticalTabHeader({
  title,
  onGoBack,
  isSticky = false,
  action,
}: VerticalTabHeaderProps) {
  return (
    <>
      <HStack
        mb="6"
        position={isSticky ? 'sticky' : undefined}
        top="calc(var(--chakra-space-16) + var(--chakra-space-1))"
        left="0"
        paddingY={2}
        justifyContent="space-between"
        zIndex="2"
        backgroundColor="blue.25"
      >
        <HStack>
          {!isNil(onGoBack) && <BackButton onClick={onGoBack} />}
          {title && (
            <Heading fontSize="30" lineHeight="48px">
              {title}
            </Heading>
          )}
        </HStack>
        {!isNil(action) && (
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={action.onClick}
            isLoading={action.isLoading ?? false}
            isDisabled={action.disabled ?? false}
          >
            Apply changes
          </Button>
        )}
      </HStack>
      {/* The drop shadow for the header, which is sticky as well */}
      {isSticky && (
        <Box
          position="sticky"
          background="radial-gradient(ellipse at top, rgba(0, 0, 0, .05) 40%, rgba(0, 0, 0, 0) 70%)"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center top"
          top="calc(var(--chakra-space-28) + var(--chakra-space-5))"
          pointerEvents="none"
          height="2"
          zIndex={1}
        />
      )}
    </>
  );
}

/**
 * A wrapper component for the content of a tabbed interface that takes care of displaying a loader,
 * presenting an error component and performing a 404 redirect if the `data` property is used.
 *
 * This component accepts either a render function or any valid ReactNode as `children`.
 * By combining the `data` property with a render function, you can use the validated data
 * within the rest of your declarative rendering code.
 *
 * As an example of the implementation, have a look at any of the organization settings pages:
 * `src/pages/[organization]/settings`
 */
export function VerticalTabContent<TContentType extends Record<string, unknown>>({
  children,
  error,
  isLoading = false,
  hasStickyHeader = false,
  data,
  ...tabHeaderProps
}: {
  children: ReactNode | ((data: TContentType) => ReactNode);
  isLoading?: boolean;
  error?: ApolloError;
  data?: TContentType | null | undefined;
  hasStickyHeader?: boolean;
} & VerticalTabHeaderProps) {
  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : error ? (
        <HandleApiError error={error} />
      ) : (
        <>
          <VerticalTabHeader {...tabHeaderProps} isSticky={hasStickyHeader} />
          {/* The pseudo element on this wrapper hides the shadow of the header when the page is not scrolled */}
          <Box
            position="relative"
            _before={{
              content: '""',
              position: 'absolute',
              backgroundColor: 'blue.25',
              left: '0',
              top: '-2',
              width: '100%',
              height: '2',
              zIndex: '1',
            }}
          >
            {typeof children === 'function' ? (
              isNil(data) ? (
                <Navigate to="../404" />
              ) : (
                children(data)
              )
            ) : (
              children
            )}
          </Box>
        </>
      )}
    </>
  );
}

export function ActiveTabBackground({ tabs }: { tabs: Tab[] }) {
  const theme = useTheme();
  const [animate, setAnimate] = useState({ y: 0, height: '0px' });
  const updateAnimate = useCallback((tab: Tab) => {
    if (!tab.ref?.current) return;

    setAnimate({
      y: tab.ref.current.offsetTop,
      height: `${tab.ref.current.clientHeight}px`,
    });
  }, []);

  useEffect(() => {
    const activeTab = tabs.find((t) => t.isActive);

    if (activeTab) {
      updateAnimate(activeTab);
    }
  }, [tabs, updateAnimate]);

  return (
    <Box position="absolute" bottom="0" left="0" width="full" height="full" zIndex="-1">
      <motion.div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: `${tabs[0].ref.current?.clientHeight || 56}px`,
          borderRadius: theme.radii.md,
          backgroundColor: 'white',
          boxShadow: '0px 6px 16px -1px rgba(0, 56, 95, 0.06)',
        }}
        animate={animate}
        transition={{
          duration: 0.3,
          type: 'spring',
          bounce: 0.2,
        }}
      />
    </Box>
  );
}
