import { Box, BoxProps, Container } from '@chakra-ui/react';
import React from 'react';

export const CONTAINER_MAX_WIDTH = '8xl';

export const CONTAINER_PADDING = { base: 3, md: '56px' };

export const PAGE_CONTENT_PADDING_TOP = 6;

export function PageContent({
  maxWidth = CONTAINER_MAX_WIDTH,
  backgroundColor,
  ...rest
}: BoxProps) {
  return (
    <Container
      position="relative"
      zIndex="base"
      paddingTop={PAGE_CONTENT_PADDING_TOP}
      paddingBottom="10"
      maxW="full"
      backgroundColor={backgroundColor}
    >
      <Box marginX="auto" maxWidth={maxWidth} paddingX={CONTAINER_PADDING} {...rest} />
    </Container>
  );
}
