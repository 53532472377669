/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateFirmware_DisplayFragment = { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string> } } | null, platform: { __typename: 'DisplayPlatform', name: string, version?: string | null, type: string } };

export type BulkUpdateFirmwareMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateAndroidFirmwareInput;
}>;


export type BulkUpdateFirmwareMutation = { __typename: 'Mutation', displayBulkUpdateAndroidFirmware: { __typename: 'DisplayBulkUpdateAndroidFirmwarePayload', displays: Array<{ __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string>, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateRejected', rejectedAt: any, rejectionCode: string, id: string, targetVersion: any, createdAt: any, plannedAt: any } | null } } | null }> } };

export const BulkUpdateFirmware_DisplayFragmentDoc = gql`
    fragment BulkUpdateFirmware_display on Display {
  id
  firmware {
    android {
      version
      availableUpdates
    }
  }
  platform {
    name
    version
    type
  }
}
    `;
export const BulkUpdateFirmwareDocument = gql`
    mutation BulkUpdateFirmware($input: DisplayBulkUpdateAndroidFirmwareInput!) {
  displayBulkUpdateAndroidFirmware(input: $input) {
    displays {
      id
      firmware {
        android {
          version
          availableUpdates
          latestJob {
            id
            targetVersion
            createdAt
            plannedAt
            ... on AndroidUpdateDownloading {
              downloadProgress
            }
            ... on AndroidUpdateRejected {
              rejectedAt
              rejectionCode
            }
          }
        }
      }
    }
  }
}
    `;
export type BulkUpdateFirmwareMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateFirmwareMutation, BulkUpdateFirmwareMutationVariables>;

/**
 * __useBulkUpdateFirmwareMutation__
 *
 * To run a mutation, you first call `useBulkUpdateFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateFirmwareMutation, { data, loading, error }] = useBulkUpdateFirmwareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateFirmwareMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateFirmwareMutation, BulkUpdateFirmwareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdateFirmwareMutation, BulkUpdateFirmwareMutationVariables>(BulkUpdateFirmwareDocument, options);
      }
export type BulkUpdateFirmwareMutationHookResult = ReturnType<typeof useBulkUpdateFirmwareMutation>;
export type BulkUpdateFirmwareMutationResult = ApolloReactCommon.MutationResult<BulkUpdateFirmwareMutation>;
export type BulkUpdateFirmwareMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateFirmwareMutation, BulkUpdateFirmwareMutationVariables>;