export const Accordion = {
  parts: ['container', 'button', 'panel', 'icon'],
  baseStyle: {
    container: {
      color: 'gray.700',
      borderTop: 'unset',
      _last: {
        borderBottomWidth: '0',
      },
    },
  },
};
