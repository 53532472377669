import { Permission } from '@tp-vision/roles-permissions';
import { useAuth } from '~auth/useAuth';
import { EmptyView, EmptyViewExternalLink, EmptyViewInternalLink } from '~components/EmptyView';
import { EmptySubscriptionsIllustration } from '~components/ui/illustrations/EmptySubscriptionsIllustration';
import { useFeatureFlag } from '~utils/features';

type UpgradeToWaveEssentialEmptyStateProps = {
  externalLink?: boolean;
  noSubscriptions?: boolean;
};

function UpgradeToWaveEssentialEmptyState({
  externalLink = false,
  noSubscriptions = false,
}: UpgradeToWaveEssentialEmptyStateProps) {
  const { isEnabled: isAppDirectMarketplaceLinkEnabled } = useFeatureFlag(
    'appDirectMarketplaceLink',
  );

  const { verifyUserPermissions, organization } = useAuth();
  const baseURL = organization && `/${organization.handle}`;

  const canManageSubscriptions = verifyUserPermissions([Permission.WaveSubscriptionUpdate]);

  return (
    <EmptyView
      icon={<EmptySubscriptionsIllustration />}
      title={noSubscriptions ? 'You currently have no subscriptions' : 'Upgrade to Wave Essential'}
      description={
        canManageSubscriptions
          ? externalLink
            ? 'Unlock all Wave features with Wave Essential. Contact your sales executive to purchase subscriptions.'
            : 'Unlock all Wave features with Wave Essential. Find out more and manage the subscriptions from settings.'
          : 'Unlock all Wave features with Wave Essential. Contact an admin to upgrade this customer to Essential.'
      }
      theme="purple"
    >
      {externalLink ? (
        <>
          {isAppDirectMarketplaceLinkEnabled && (
            <EmptyViewExternalLink
              href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
              label="Purchase on ProStore"
            />
          )}
        </>
      ) : (
        <>
          {canManageSubscriptions && (
            <EmptyViewInternalLink
              label="Manage Subcriptions"
              to={`${baseURL}/settings/subscriptions`}
            />
          )}
        </>
      )}
    </EmptyView>
  );
}

export default UpgradeToWaveEssentialEmptyState;
