import { createIcon } from '@chakra-ui/react';

export const PowerIcon = createIcon({
  displayName: 'PowerIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M19.0672 5.93301C18.6768 5.54242 18.0436 5.54232 17.653 5.93278C17.2624 6.32324 17.2623 6.9564 17.6528 7.34699L19.0672 5.93301ZM11.995 22.0029V23.0029V22.0029ZM6.33722 7.34699C6.72768 6.9564 6.72758 6.32324 6.33699 5.93278C5.9464 5.54232 5.31324 5.54242 4.92278 5.93301L6.33722 7.34699ZM13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2H13ZM11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12H11ZM17.6528 7.34699C18.7713 8.46592 19.533 9.89138 19.8415 11.4432L21.8031 11.0532C21.4175 9.1135 20.4654 7.33166 19.0672 5.93301L17.6528 7.34699ZM19.8415 11.4432C20.1499 12.9949 19.9914 14.6033 19.3858 16.065L21.2335 16.8305C21.9905 15.0034 22.1887 12.9929 21.8031 11.0532L19.8415 11.4432ZM19.3858 16.065C18.7802 17.5266 17.7548 18.7759 16.4393 19.6549L17.5504 21.3178C19.1948 20.2192 20.4765 18.6576 21.2335 16.8305L19.3858 16.065ZM16.4393 19.6549C15.1238 20.5338 13.5771 21.0029 11.995 21.0029V23.0029C13.9727 23.0029 15.9059 22.4165 17.5504 21.3178L16.4393 19.6549ZM11.995 21.0029C10.4129 21.0029 8.86625 20.5338 7.55071 19.6549L6.43964 21.3178C8.08406 22.4165 10.0173 23.0029 11.995 23.0029V21.0029ZM7.55071 19.6549C6.23517 18.7759 5.20979 17.5266 4.60421 16.065L2.75652 16.8305C3.51349 18.6576 4.79522 20.2192 6.43964 21.3178L7.55071 19.6549ZM4.60421 16.065C3.99864 14.6033 3.84006 12.9949 4.14853 11.4432L2.18691 11.0532C1.80132 12.9929 1.99954 15.0034 2.75652 16.8305L4.60421 16.065ZM4.14853 11.4432C4.457 9.89138 5.21866 8.46592 6.33722 7.34699L4.92278 5.93301C3.52458 7.33166 2.5725 9.1135 2.18691 11.0532L4.14853 11.4432ZM11 2V12H13V2H11Z"
      fill="currentColor"
    />
  ),
});
