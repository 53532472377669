import { SubscriptionCustomersTableCustomerFragment } from './__generated__/SubscriptionCustomersTable.graphql';

export enum Columns {
  Displays = 'displays',
  Name = 'name',
  Selection = 'selection',
  Subscription = 'subscription',
}

export type SelectableSubscriptionCustomersTableCustomerFragment =
  SubscriptionCustomersTableCustomerFragment & {
    isLinkedToSubscription?: boolean;
  };
