import { Permission, Role } from '@tp-vision/roles-permissions';
import jwtDecode from 'jwt-decode';
import { isEqual } from 'lodash';
import { useContext, useState } from 'react';
import { AuthenticationContext } from './AuthenticationProvider';

export interface AuthUser {
  name?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  permissions?: Permission[];
  roles?: Role[];
  'https://keat.io/audiences'?: string[];
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);

  if (!context) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }

  const auth = useContext(context);

  if (auth.isAuthenticated && permissions.length === 0) {
    auth.getAccessTokenSilently().then((token) => {
      const decodedToken = jwtDecode<{
        permissions?: Permission[];
        'https://wave.ppds.com/user_roles'?: Role[];
      }>(token);

      const tokenRoles = decodedToken['https://wave.ppds.com/user_roles'] ?? [];
      const tokenPermissions = decodedToken.permissions ?? [];

      if (!isEqual(tokenRoles, roles)) {
        setRoles(tokenRoles);
      }

      if (!isEqual(tokenPermissions, permissions)) {
        setPermissions(tokenPermissions);
      }
    });
  }

  const user: AuthUser = {
    ...auth.user,
    name: auth.user?.name,
    givenName: auth.user?.given_name,
    familyName: auth.user?.family_name,
    email: auth.user?.email,
    'https://keat.io/audiences': auth.user?.['https://keat.io/audiences'],
    permissions,
    roles,
  };

  return {
    ...auth,
    user,
  };
}
