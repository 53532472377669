import { gql } from '@apollo/client';
import {
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEditTimeBlocks } from '~components/powerSchedules/PowerScheduleDetail/useEditTimeBlocks';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { PowerSchedule, TimeBlock } from '~graphql/__generated__/types';

interface AddTimeSlotModalProps {
  timeBlocks: PowerSchedule['timeBlocks'];
  isOpen: boolean;
  onSubmit: (timeBlocks: TimeBlock[]) => void;
  onCancel: () => void;
}

export function AddTimeSlotModal({
  timeBlocks,
  isOpen,
  onSubmit,
  onCancel,
}: AddTimeSlotModalProps) {
  const { handleSubmit, reset, FormBody, SubmitButton, CancelButton } = useEditTimeBlocks({
    timeBlocks,
    defaultValues: {
      start: '09:00',
      end: '12:00',
      days: [],
    },
    onSubmit,
    onCancel,
    allowSelectDays: true,
  });

  const onClose = () => {
    onCancel();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Add new time slot</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <chakra.p marginBottom={5}>
              Choose the times at which your displays will turn on and to standby, and on which days
              it will do so.
            </chakra.p>
            {FormBody}
          </ModalBody>

          <ModalFooter>
            <CancelButton />
            <SubmitButton>Add slot</SubmitButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

AddTimeSlotModal.graphql = {
  mutations: {
    UpdatePowerSchedule: gql`
      mutation UpdatePowerSchedule($input: PowerScheduleUpdateInput!) {
        powerScheduleUpdate(input: $input) {
          powerSchedule {
            id
            ...PowerScheduleTable_powerSchedule
            ...PowerScheduleDetail_powerSchedule
          }
        }
      }
    `,
  },
};
