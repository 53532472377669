import { createIcon } from '@chakra-ui/react';

export const VolumeMuteIcon = createIcon({
  displayName: 'VolumeMuteIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M23.7071 9.70711C24.0976 9.31658 24.0976 8.68342 23.7071 8.29289C23.3166 7.90237 22.6834 7.90237 22.2929 8.29289L23.7071 9.70711ZM16.2929 14.2929C15.9024 14.6834 15.9024 15.3166 16.2929 15.7071C16.6834 16.0976 17.3166 16.0976 17.7071 15.7071L16.2929 14.2929ZM17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289C15.9024 8.68342 15.9024 9.31658 16.2929 9.70711L17.7071 8.29289ZM22.2929 15.7071C22.6834 16.0976 23.3166 16.0976 23.7071 15.7071C24.0976 15.3166 24.0976 14.6834 23.7071 14.2929L22.2929 15.7071ZM11 5H12C12 4.61559 11.7797 4.26522 11.4332 4.0987C11.0867 3.93218 10.6755 3.979 10.3753 4.21913L11 5ZM6 9V10C6.22707 10 6.44738 9.92272 6.62469 9.78087L6 9ZM2 9V8C1.44772 8 1 8.44772 1 9H2ZM2 15H1C1 15.5523 1.44772 16 2 16V15ZM6 15L6.62469 14.2191C6.44738 14.0773 6.22707 14 6 14V15ZM11 19L10.3753 19.7809C10.6755 20.021 11.0867 20.0678 11.4332 19.9013C11.7797 19.7348 12 19.3844 12 19H11ZM22.2929 8.29289L16.2929 14.2929L17.7071 15.7071L23.7071 9.70711L22.2929 8.29289ZM16.2929 9.70711L22.2929 15.7071L23.7071 14.2929L17.7071 8.29289L16.2929 9.70711ZM10.3753 4.21913L5.3753 8.21913L6.62469 9.78087L11.6247 5.78087L10.3753 4.21913ZM6 8H2V10H6V8ZM1 9V15H3V9H1ZM2 16H6V14H2V16ZM5.3753 15.7809L10.3753 19.7809L11.6247 18.2191L6.62469 14.2191L5.3753 15.7809ZM12 19V5H10V19H12Z"
      fill="currentColor"
    />
  ),
});
