/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UsePowerSchedules_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, title: string };

export type UsePowerSchedules_DisplayFragment = { __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', isSynced?: boolean | null, schedule?: { __typename: 'PowerSchedule', id: string, title: string } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateCompleted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateInProgress', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateRejected', rejectionCode: string, id: string, scheduleId?: string | null } | null } | null };

export type SyncPowerScheduleMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdatePowerScheduleInput;
}>;


export type SyncPowerScheduleMutation = { __typename: 'Mutation', displayBulkUpdatePowerSchedule: { __typename: 'DisplayBulkUpdatePowerSchedulePayload', displays: Array<{ __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', isSynced?: boolean | null, schedule?: { __typename: 'PowerSchedule', id: string, title: string } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateCompleted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateInProgress', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateRejected', rejectionCode: string, id: string, scheduleId?: string | null } | null } | null }> } };

export const UsePowerSchedules_PowerScheduleFragmentDoc = gql`
    fragment UsePowerSchedules_powerSchedule on PowerSchedule {
  id
  title
}
    `;
export const UsePowerSchedules_DisplayFragmentDoc = gql`
    fragment UsePowerSchedules_display on Display {
  id
  powerSchedule {
    schedule {
      id
      ...UsePowerSchedules_powerSchedule
    }
    isSynced
    latestJob {
      __typename
      id
      scheduleId
      ... on PowerScheduleUpdateRejected {
        rejectionCode
      }
    }
  }
}
    ${UsePowerSchedules_PowerScheduleFragmentDoc}`;
export const SyncPowerScheduleDocument = gql`
    mutation SyncPowerSchedule($input: DisplayBulkUpdatePowerScheduleInput!) {
  displayBulkUpdatePowerSchedule(input: $input) {
    displays {
      id
      ...UsePowerSchedules_display
    }
  }
}
    ${UsePowerSchedules_DisplayFragmentDoc}`;
export type SyncPowerScheduleMutationFn = ApolloReactCommon.MutationFunction<SyncPowerScheduleMutation, SyncPowerScheduleMutationVariables>;

/**
 * __useSyncPowerScheduleMutation__
 *
 * To run a mutation, you first call `useSyncPowerScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPowerScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPowerScheduleMutation, { data, loading, error }] = useSyncPowerScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncPowerScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncPowerScheduleMutation, SyncPowerScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SyncPowerScheduleMutation, SyncPowerScheduleMutationVariables>(SyncPowerScheduleDocument, options);
      }
export type SyncPowerScheduleMutationHookResult = ReturnType<typeof useSyncPowerScheduleMutation>;
export type SyncPowerScheduleMutationResult = ApolloReactCommon.MutationResult<SyncPowerScheduleMutation>;
export type SyncPowerScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncPowerScheduleMutation, SyncPowerScheduleMutationVariables>;