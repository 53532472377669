import { gql } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PlaylistDetail } from '~components/playlists/PlaylistDetail';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { HandleApiError } from '~graphql/HandleApiError';
import { ensure } from '~utils/types';
import { usePlaylistDetailsPageQuery } from './__generated__/[id].graphql';

const POLL_INTERVAL = 5000;

export function PlaylistDetailsPage() {
  const { customer, playlistId } = useParams();
  const { data, loading, error, refetch, startPolling, stopPolling } = usePlaylistDetailsPageQuery({
    variables: {
      customerHandle: ensure(customer),
      playlistId: ensure(playlistId),
    },
    pollInterval: POLL_INTERVAL,
  });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const handlePlaylistUpdateSuccess = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <Page title={`${data?.playlist?.title || 'Loading…'} | Playlist`} pageName="playlist_detail">
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          data &&
          data.playlist &&
          data.customer && (
            <PlaylistDetail
              customer={data.customer}
              playlist={data.playlist}
              onPlaylistUpdateSuccess={handlePlaylistUpdateSuccess}
            />
          )
        )}
      </PageContent>
    </Page>
  );
}

PlaylistDetailsPage.graphql = {
  queries: {
    PlaylistDetailsPage: gql`
      query PlaylistDetailsPage($customerHandle: String!, $playlistId: ID!) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          ...PlaylistDetail_customer
        }
        playlist(id: $playlistId) {
          id
          ...PlaylistDetail_playlist
        }
      }
    `,
  },
};
