export const Popover = {
  parts: ['popper', 'content', 'header', 'body', 'footer', 'arrow'],
  baseStyle: () => {
    return {
      popper: {
        boxShadow: 'xl',
      },
      content: {
        border: 'unset',
      },
      body: {
        padding: '0',
      },
    };
  },
};
