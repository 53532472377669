import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { fromError } from '~utils/errors';
import { wait } from '~utils/promise';
import { useLimits } from '~utils/useLimits';
import { uploadToS3 } from '~utils/useS3Upload';
import { useMediaCreateRequestMutation } from './__generated__/useMediaList.graphql';
import { PlaylistTableMedia } from './PlaylistMediaTable/PlaylistMediaTable';

const MEDIA_FILE_NAME_CONFLICT_ERROR = 'mediaFileWithSimilarNameAlreadyExists';
const MEDIA_FILE_MIME_TYPE_UNSUPPORTED_ERROR = 'mediaFileWithUnsupportedMimeType';
const PLAYLISTS_MAXIMUM_MEDIA_ENTRIES_EXCEEDED_ERROR = 'playlistsMaximumMediaEntries';

const UPLOAD_DELAY_TIME_IN_MS = 5000;

const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png', 'video/mp4'];

export function useMediaList({
  customerId,
  initialMedia,
}: {
  customerId: string;
  initialMedia: PlaylistTableMedia[];
}) {
  const toast = useToast();
  const [mediaCreateRequest] = useMediaCreateRequestMutation();

  const {
    playlists: { maximumPlaylistMediaEntries, isPlaylistMaximumFileLimitExceeded },
  } = useLimits();

  const [mediaList, setMediaList] = useState<
    Array<PlaylistTableMedia & { originalIndex?: number }>
  >(initialMedia.map((m, index) => ({ ...m, originalIndex: index })));

  const commitedMediaLength = useRef(initialMedia.length);

  const hasUnsavedChanges = useMemo(
    () =>
      commitedMediaLength.current !== mediaList.length ||
      mediaList.some((m, i) => (m.originalIndex ? m.originalIndex !== i : false)),
    [mediaList],
  );

  const reinitializeMediaList = useCallback((initialMedia: PlaylistTableMedia[]) => {
    setMediaList(initialMedia.map((m, index) => ({ ...m, originalIndex: index })));
    commitedMediaLength.current = initialMedia.length;
  }, []);

  const isUploadInProgress = useMemo(() => mediaList.some((m) => m.isUploading), [mediaList]);

  const removeMedia = useCallback((id: string) => {
    setMediaList((mediaList) => mediaList.filter((m) => m.id !== id));
  }, []);

  const extractFileDataFromFileList = useCallback(
    async (fileList: FileList) => {
      const fileData = [];
      for (const file of fileList) {
        if (!ALLOWED_MIME_TYPES.includes(file.type)) {
          throw new Error(MEDIA_FILE_MIME_TYPE_UNSUPPORTED_ERROR);
        }

        if (mediaList.some((media) => media.title === file.name)) {
          throw new Error(MEDIA_FILE_NAME_CONFLICT_ERROR);
        }

        if (isPlaylistMaximumFileLimitExceeded(mediaList.length + fileData.length)) {
          throw new Error(PLAYLISTS_MAXIMUM_MEDIA_ENTRIES_EXCEEDED_ERROR);
        }

        fileData.push({ size: file.size, type: file.type, title: file.name, file });
      }
      return fileData;
    },
    [mediaList, isPlaylistMaximumFileLimitExceeded],
  );

  const extractAwsUploadDataFromFileData = useCallback(
    async (
      fileData: Array<{
        size: number;
        type: string;
        title: string;
        file: File;
      }>,
    ) => {
      const mediaList = [];
      const awsUploadData = [];

      for (const media of fileData) {
        const { data } = await mediaCreateRequest({
          variables: {
            input: { size: media.size, title: media.title, type: media.type, customerId },
          },
        });

        if (!data || !data.mediaCreateRequest) {
          throw new Error('Failed to create media');
        }

        const { uploadUrl, mediaId } = data.mediaCreateRequest;
        awsUploadData.push({ media, uploadUrl, mediaId });

        mediaList.push({
          size: media.size,
          title: media.title,
          type: media.type,
          id: mediaId,
          isUploading: true,
          createdAt: new Date().toISOString(),
        });
      }
      return { mediaList, awsUploadData };
    },

    [customerId, mediaCreateRequest],
  );

  const handleMediaUpload = useCallback(
    async (fileList: FileList) => {
      const newMediaList = [...mediaList];

      const fileData = await extractFileDataFromFileList(fileList);
      const { awsUploadData, mediaList: list } = await extractAwsUploadDataFromFileData(fileData);

      newMediaList.push(...list);
      setMediaList(newMediaList);

      for (const data of awsUploadData) {
        await uploadToS3(data.uploadUrl, data.media.file).catch((err) => {
          removeMedia(data.mediaId);
          throw err;
        });

        newMediaList.map((m) => (m.id === data.mediaId ? { ...m, isUploading: false } : m));
      }

      // Delay necessary to prevent the MEDIA_NOT_FOUND_ERROR.
      // It gives time to aws to upload the files and to the lambda to update the file status from PENDING to UPLOADED
      await wait(UPLOAD_DELAY_TIME_IN_MS);

      setMediaList(newMediaList);

      return newMediaList;
    },
    [extractAwsUploadDataFromFileData, extractFileDataFromFileList, mediaList, removeMedia],
  );

  const updateMedia = useCallback(
    async (fileList: FileList) => {
      return handleMediaUpload(fileList).catch((err) => {
        switch (err.message) {
          case MEDIA_FILE_MIME_TYPE_UNSUPPORTED_ERROR:
            toast({
              status: 'error',
              title: 'Unsupported media type',
              description: `Only ${ALLOWED_MIME_TYPES.map(
                (mimeType) => mimeType.split('/')[1] ?? mimeType,
              ).join(', ')} files are allowed.`,
            });
            break;
          case MEDIA_FILE_NAME_CONFLICT_ERROR:
            toast({
              status: 'error',
              title: 'Existing media',
              description: 'Media with the same name already exists',
            });
            break;
          case PLAYLISTS_MAXIMUM_MEDIA_ENTRIES_EXCEEDED_ERROR:
            toast({
              status: 'error',
              title: `Total file limit of ${maximumPlaylistMediaEntries} exceeded`,
              description: `Adding the selected files would exceed the amount of allowed files per playlist.`,
            });
            break;
          default:
            toast({
              status: 'error',
              title: 'Failed to upload media',
              description: fromError(err, 'UploadMedia', {
                MEDIA_LIMIT_REACHED:
                  'The file you are trying to upload exceeds the total uploads storage space that you have left.',
                INVALID_MIME_TYPE:
                  'Looks like you are trying to upload a file with a format that is not allowed.',
              }),
            });
        }
        return [];
      });
    },
    [handleMediaUpload, maximumPlaylistMediaEntries, toast],
  );

  return useMemo(
    () => ({
      mediaList,
      setMediaList,
      hasUnsavedChanges,
      isUploadInProgress,
      removeMedia,
      reinitializeMediaList,
      updateMedia,
    }),
    [
      mediaList,
      hasUnsavedChanges,
      isUploadInProgress,
      removeMedia,
      reinitializeMediaList,
      updateMedia,
    ],
  );
}

useMediaList.graphql = {
  mutations: {
    mediaCreateRequest: gql`
      mutation MediaCreateRequest($input: MediaCreateRequestInput!) {
        mediaCreateRequest(input: $input) {
          uploadUrl
          mediaId
        }
      }
    `,
  },
};
