import { gql } from '@apollo/client';
import { chakra, Tag } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { ActionMeta, MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { components, SelectOption } from '~components/ui/Select';
import { MaybePromise } from '~utils/types';
import {
  DisplayGroupsSelect_CustomerFragment,
  DisplayGroupsSelect_DisplayFragment,
  DisplayGroupsSelect_GroupFragment,
} from './__generated__/DisplayGroupSelect.graphql';

interface Props {
  tabIndex?: number;
  customer: DisplayGroupsSelect_CustomerFragment;
  display: DisplayGroupsSelect_DisplayFragment;
  value: DisplayGroupsSelect_GroupFragment[];
  isLoading: boolean;
  isDisabled?: boolean;
  onCreate: (customer: DisplayGroupsSelect_CustomerFragment, name: string) => MaybePromise<void>;
  onAdd: (
    display: DisplayGroupsSelect_DisplayFragment,
    group: DisplayGroupsSelect_GroupFragment,
  ) => MaybePromise<void>;
  onRemove: (
    display: DisplayGroupsSelect_DisplayFragment,
    group: DisplayGroupsSelect_GroupFragment,
  ) => MaybePromise<void>;
}

export function DisplayGroupsSelect({
  tabIndex,
  customer,
  display,
  value,
  isLoading,
  isDisabled = false,
  onCreate,
  onAdd,
  onRemove,
}: Props) {
  const availableGroups = useMemo(
    () =>
      customer.groups.map<SelectOption>((group) => ({
        label: group.name,
        value: group.id,
      })),
    [customer],
  );

  const currentOption = useMemo(
    () => value.map<SelectOption>((v) => ({ label: v.name, value: v.id })),
    [value],
  );

  const handleChange = useCallback(
    (_value: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
      if (actionMeta.action === 'select-option' && actionMeta.option) {
        const group = customer.groups.find((g) => g.id === actionMeta.option?.value);
        if (!group) throw new Error('Selected group not available.');

        onAdd(display, group);
      } else if (actionMeta.action === 'remove-value') {
        const group = customer.groups.find((g) => g.id === actionMeta.removedValue.value);
        if (!group) throw new Error('Selected group not available.');

        onRemove(display, group);
      }
    },
    [customer, display, onAdd, onRemove],
  );

  const handleCreate = useCallback(
    (name: string) => {
      onCreate(customer, name);
    },
    [onCreate, customer],
  );

  const shouldDisable = isDisabled || isLoading;

  return (
    <CreatableSelect
      tabIndex={tabIndex}
      isMulti={true}
      components={components}
      isDisabled={shouldDisable}
      formatCreateLabel={(value) => (
        <>
          <chakra.span fontWeight="bold" fontSize="md">
            Create
          </chakra.span>
          <Tag
            colorScheme="gray"
            variant="outline"
            display="inline"
            marginLeft="2"
            verticalAlign="middle"
            fontSize="xs"
            paddingY="1"
            paddingX="2"
          >
            {value}
          </Tag>
        </>
      )}
      styles={{
        input: (base) => ({
          ...base,
          margin: 0,
          marginLeft: '0.5rem',
        }),
      }}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={availableGroups}
      value={currentOption}
      placeholder="Select or create a group"
      isClearable={false}
      menuPlacement="auto"
    />
  );
}

DisplayGroupsSelect.graphql = {
  fragments: {
    DisplayGroupsSelect_customer: gql`
      fragment DisplayGroupsSelect_customer on Customer {
        id
        groups {
          id
          name
        }
      }
    `,
    DisplayGroupsSelect_display: gql`
      fragment DisplayGroupsSelect_display on Display {
        id
      }
    `,
    DisplayGroupsSelect_group: gql`
      fragment DisplayGroupsSelect_group on Group {
        id
        name
      }
    `,
  },
};
