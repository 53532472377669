import { gql } from '@apollo/client';
import {
  Button,
  IconProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { isNil } from 'lodash';
import { ComponentType, ReactNode, useEffect, useRef, useState } from 'react';
import { useManageAppSubscriptionsForm } from '~components/displays/useManageAppSubscriptionsForm';
import { useManagePlaylistForm } from '~components/displays/useManagePlaylistForm';
import { useManageWebPagesForm } from '~components/displays/useManageWebPagesForm';
import { GridIcon, PlaylistIcon, WebPageIcon } from '~components/ui/icons';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import {
  AddContentModal_CustomerFragment,
  AddContentModal_DisplayFragment,
} from './__generated__/AddContentModal.graphql';

type ModalTab = {
  title: string;
  icon: ComponentType<IconProps>;
  body: ReactNode;
  footer?: ReactNode;
  onSubmit: () => void;
};

export function AddContentModal({
  display,
  customer,
  onClose,
}: {
  customer: AddContentModal_CustomerFragment;
  display: AddContentModal_DisplayFragment;
  onClose: () => void;
}) {
  const [submittedForms, setSubmittedForms] = useState<number>(0);
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  const shouldRenderPlaylists = !isNil(display.playlist);
  const shouldRenderBookmarks = !isNil(display.bookmarks.all.reported);

  const onSubmitFormSuccess = () => {
    setSubmittedForms((prevState) => prevState + 1);
  };

  const webPagesForm = useManageWebPagesForm({
    displayIds: [display.id],
    initialFocusRef,
    onCancel: onClose,
    onSuccess: onSubmitFormSuccess,
    defaultBookmarks: display.bookmarks.all.desired || display.bookmarks.all.reported || [],
  });

  const appSubscriptionsForm = useManageAppSubscriptionsForm({
    display,
    onSuccess: onSubmitFormSuccess,
    onCancel: onClose,
  });

  const playlistForm = useManagePlaylistForm({
    displayIds: [display.id],
    customer,
    onCancel: onClose,
    onSuccess: onSubmitFormSuccess,
    currentPlaylistId: display.playlist?.current?.id,
  });

  const modalTabs: ModalTab[] = [
    ...(shouldRenderBookmarks
      ? [
          {
            title: 'Web pages',
            icon: WebPageIcon,
            body: webPagesForm.body,
            footer: webPagesForm.footer,
            onSubmit: webPagesForm.onSubmit,
          },
        ]
      : []),
    {
      title: 'Apps',
      icon: GridIcon,
      body: appSubscriptionsForm.body,
      footer: appSubscriptionsForm.footer,
      onSubmit: appSubscriptionsForm.onSubmit,
    },
    ...(shouldRenderPlaylists
      ? [
          {
            title: 'Playlist',
            icon: PlaylistIcon,
            body: playlistForm.body,
            footer: playlistForm.footer,
            onSubmit: playlistForm.onSubmit,
          },
        ]
      : []),
  ];

  const isSubmitting = 0 < submittedForms && submittedForms < modalTabs.length;

  const handleSubmitTabs = () => {
    for (const tab of modalTabs) {
      tab.onSubmit();
    }
  };

  useEffect(() => {
    if (submittedForms === modalTabs.length) {
      onClose();
    }
  }, [submittedForms, onClose, modalTabs.length]);

  return (
    <Modal size="2xl" initialFocusRef={initialFocusRef} isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add content</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <Tabs isLazy>
          <ModalBody paddingX="9" paddingY="6">
            <TabList>
              {modalTabs.map(({ icon: Icon, title }) => (
                <Tab key={title}>
                  <Icon mr="3" width="4" /> {title}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {modalTabs.map(({ title, body, onSubmit }) => (
                <TabPanel padding={0} paddingTop="6" key={title}>
                  <form onSubmit={onSubmit}>{body}</form>
                </TabPanel>
              ))}
            </TabPanels>
          </ModalBody>
          <ModalFooter paddingTop="9">
            <Button variant="ghost" colorScheme="blue" onClick={onClose} isDisabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              onClick={handleSubmitTabs}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Apply
            </Button>
          </ModalFooter>
        </Tabs>
      </ModalContent>
    </Modal>
  );
}

AddContentModal.graphql = {
  fragments: {
    AddContentModal_customer: gql`
      fragment AddContentModal_customer on Customer {
        id
        ...useManagePlaylistForm_customer
      }
    `,
    AddContentModal_display: gql`
      fragment AddContentModal_display on Display {
        id
        bookmarks {
          all {
            reported
            desired
          }
        }
        playlist {
          current {
            id
            title
          }
        }
        ...useManageAppSubscriptionsForm_display
      }
    `,
  },
};
