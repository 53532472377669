import { createIcon } from '@chakra-ui/react';

export const ErrorConnectionIllustration = createIcon({
  displayName: 'ErrorConnectionIllustration',
  viewBox: '0 0 240 240',
  defaultProps: {
    fill: 'none',
    width: '240px',
    height: '240px',
  },
  path: (
    <>
      <circle cx="120" cy="120" r="120" fill="#F1F8FE" />
      <mask id="mask0_1329_1185" maskUnits="userSpaceOnUse" x="0" y="0" width="240" height="240">
        <circle cx="120" cy="120" r="120" fill="#F1F8FE" />
      </mask>
      <g mask="url(#mask0_1329_1185)">
        <g filter="url(#filter0_dd_1329_1185)">
          <g filter="url(#filter1_dd_1329_1185)">
            <path
              d="M76.4999 23L73.8281 24.3644L76.4999 23ZM78.1176 47.5342C76.61 44.842 76.2568 42.7802 76.3486 41.1004C76.444 39.3557 77.0283 37.7601 77.8465 35.931C79.3622 32.5423 82.2603 27.6841 79.1717 21.6356L73.8281 24.3644C75.3606 27.3654 74.2588 29.2572 72.3694 33.4811C71.485 35.4584 70.5154 37.8847 70.3575 40.7729C70.1961 43.726 70.8902 46.908 72.8826 50.4658L78.1176 47.5342ZM79.1717 21.6356C76.7003 16.7961 71.4204 12.9307 67.26 10.4087C65.1036 9.1015 63.0897 8.06308 61.6159 7.35133C60.8772 6.9946 60.2697 6.71777 59.8422 6.52819C59.6284 6.43335 59.4592 6.3602 59.3409 6.30967C59.2818 6.2844 59.2353 6.26477 59.2022 6.2509C59.1857 6.24396 59.1725 6.23847 59.1628 6.23442C59.1579 6.2324 59.1539 6.23074 59.1508 6.22944C59.1492 6.2288 59.1479 6.22824 59.1468 6.22777C59.1462 6.22754 59.1455 6.22726 59.1452 6.22715C59.1446 6.22689 59.144 6.22665 58.0001 9C56.8562 11.7733 56.8557 11.7732 56.8553 11.773C56.8553 11.773 56.8549 11.7728 56.8548 11.7728C56.8546 11.7727 56.8545 11.7727 56.8547 11.7727C56.8551 11.7729 56.8563 11.7734 56.8583 11.7742C56.8625 11.776 56.87 11.7791 56.8808 11.7836C56.9024 11.7927 56.9371 11.8073 56.9843 11.8275C57.0786 11.8678 57.2225 11.9299 57.4098 12.013C57.7848 12.1793 58.3329 12.4289 59.0066 12.7543C60.3577 13.4067 62.1962 14.3554 64.1497 15.5396C68.209 18.0003 72.179 21.135 73.8281 24.3644L79.1717 21.6356Z"
              fill="#BCCCDC"
            />
          </g>
          <path
            d="M129.89 76.7654L74.4641 108.765L60.4326 84.4621C52.374 70.5041 61.2634 54.0329 75.1769 46C89.0904 37.967 107.8 38.5041 115.858 52.4621L129.89 76.7654Z"
            fill="#BCCCDC"
          />
          <rect
            x="66.5"
            y="102.971"
            width="72"
            height="17"
            rx="4"
            transform="rotate(-30 66.5 102.971)"
            fill="#0F5291"
          />
        </g>
        <path
          d="M65.6292 144.25L51.7727 152.25"
          stroke="#F86A6A"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M60.2272 128.894L48.2272 128.894"
          stroke="#F86A6A"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M76.2272 156.606L70.2272 166.999"
          stroke="#F86A6A"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M168.598 88.0526L182.455 80.0526"
          stroke="#F86A6A"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path d="M158 75.6962L164 65.3038" stroke="#F86A6A" strokeWidth="6" strokeLinecap="round" />
        <path d="M174 103.409L186 103.409" stroke="#F86A6A" strokeWidth="6" strokeLinecap="round" />
        <g filter="url(#filter2_dd_1329_1185)">
          <path
            d="M106.464 158.928L161.89 126.928L175.921 151.231C183.98 165.19 175.09 181.661 161.177 189.694C147.263 197.727 128.554 197.189 120.496 183.231L106.464 158.928Z"
            fill="#BCCCDC"
          />
          <rect
            x="169.854"
            y="132.722"
            width="72"
            height="17"
            rx="4"
            transform="rotate(150 169.854 132.722)"
            fill="#0F5291"
          />
          <path
            d="M104.856 132.144C103.752 130.23 104.407 127.784 106.32 126.68C108.234 125.575 110.68 126.23 111.785 128.144L119.785 142L112.856 146L104.856 132.144Z"
            fill="white"
          />
          <path
            d="M132.569 116.144C131.465 114.23 132.12 111.784 134.033 110.679C135.946 109.575 138.393 110.23 139.497 112.144L147.497 126L140.569 130L132.569 116.144Z"
            fill="white"
          />
          <g filter="url(#filter3_dd_1329_1185)">
            <path
              d="M181 199L178.478 200.624L181 199ZM158.559 190.244C160.687 193.223 162.955 195.096 165.319 196.26C167.649 197.408 169.89 197.772 171.732 197.995C175.645 198.469 177.091 198.47 178.478 200.624L183.522 197.376C180.323 192.408 175.519 192.409 172.453 192.038C170.806 191.839 169.376 191.57 167.97 190.877C166.596 190.201 165.063 189.027 163.441 186.756L158.559 190.244ZM178.478 200.624C178.993 201.425 179.043 202.029 178.919 202.61C178.769 203.316 178.299 204.23 177.325 205.385C175.316 207.767 172.126 210.118 168.674 212.848C167.025 214.153 165.343 215.53 163.907 216.948C162.491 218.347 161.145 219.946 160.33 221.739C159.474 223.62 159.181 225.779 160.031 228.009C160.839 230.129 162.532 231.913 164.8 233.472L168.2 228.528C166.468 227.337 165.846 226.419 165.637 225.871C165.47 225.433 165.457 224.958 165.791 224.224C166.165 223.402 166.919 222.407 168.123 221.217C169.309 220.046 170.77 218.84 172.396 217.554C175.499 215.1 179.398 212.234 181.911 209.254C183.198 207.727 184.348 205.924 184.787 203.861C185.254 201.673 184.864 199.459 183.522 197.376L178.478 200.624Z"
              fill="#BCCCDC"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_1329_1185"
          x="32"
          y="4.22714"
          width="117.354"
          height="135.466"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1329_1185"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1329_1185" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1329_1185"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1329_1185"
            result="effect2_dropShadow_1329_1185"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1329_1185"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_1329_1185"
          x="44.8546"
          y="4.22714"
          width="47.6846"
          height="68.2387"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1329_1185"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1329_1185" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1329_1185"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1329_1185"
            result="effect2_dropShadow_1329_1185"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1329_1185"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_1329_1185"
          x="86.9999"
          y="108.143"
          width="117.354"
          height="147.329"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1329_1185"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1329_1185" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1329_1185"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1329_1185"
            result="effect2_dropShadow_1329_1185"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1329_1185"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_1329_1185"
          x="146.559"
          y="184.756"
          width="50.417"
          height="70.7159"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1329_1185"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1329_1185" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1329_1185"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1329_1185"
            result="effect2_dropShadow_1329_1185"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1329_1185"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
