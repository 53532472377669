/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type GroupsCell_ValueFragment = { __typename: 'Group', id: string, name: string };

export type GroupsCell_DisplayFragment = { __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export const GroupsCell_ValueFragmentDoc = gql`
    fragment GroupsCell_value on Group {
  id
  name
}
    `;
export const GroupsCell_DisplayFragmentDoc = gql`
    fragment GroupsCell_display on Display {
  id
  groups {
    id
    ...GroupsCell_value
  }
}
    ${GroupsCell_ValueFragmentDoc}`;