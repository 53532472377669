import { Box, Button, chakra, Flex, Link, useToast, VStack } from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import DisplayDetailHeader from '~components/displays/DisplayDetail/DisplayDetailHeader';
import CopyButton from '~components/ui/CopyButton';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { HelpIcon } from '~components/ui/icons';
import { ErrorConnectionIllustration } from '~components/ui/illustrations';
import { DisplayPlatform } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { ensure } from '~utils/types';
import { getPlatformLabel } from '../utils';
import {
  DisplayDetailEmptyView_Display_DisplayFragment,
  useDisplayDetailEmptyView_UnclaimDisplayMutation,
} from './__generated__/DisplayDetailEmptyView.graphql';

interface Props {
  display: DisplayDetailEmptyView_Display_DisplayFragment;
  title: string;
}

export function DisplayDetailEmptyView({ display, title }: Props) {
  const auth = useAuth();
  const { customer, displayId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const analytics = useAnalyticsReporter();
  const [unclaimDisplay] = useDisplayDetailEmptyView_UnclaimDisplayMutation();

  const unclaimDisplayAction = useDestructiveAction({
    title: 'Unclaim Display',
    confirmLabel: 'Unclaim',
    message: (
      <>
        Are you sure you want to unclaim{' '}
        <chakra.span fontWeight="bold">{display.alias ?? display.serialNumber}</chakra.span>?
      </>
    ),
    onConfirm: async () => {
      try {
        await unclaimDisplay({
          variables: {
            input: {
              displayId: ensure(displayId),
            },
          },
          update: (cache) => {
            cache.evict({
              id: cache.identify({
                __typename: 'Display',
                id: displayId,
              }),
            });
          },
        });

        toast({
          status: 'info',
          title: 'Display unclaimed',
          description: `${display.alias ?? display.serialNumber} has been unclaimed`,
        });

        analytics.track('displayUnclaimComplete');
        navigate(`/${auth.organization?.handle}/customers/${customer}`, { replace: true });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot unclaim display',
          description: fromError(err, 'UnclaimDisplay'),
        });
      }
    },
  });

  const handleUnclaim = useCallback(() => {
    analytics.track('displayUnclaimStart');
    unclaimDisplayAction.askConfirmation(undefined);
  }, [analytics, unclaimDisplayAction]);

  return (
    <>
      <DisplayDetailHeader display={display} title={title} />
      <Box
        border="1px solid"
        borderColor="gray.100"
        borderRadius="base"
        boxShadow="lg"
        background="white"
        py="8"
        px="12"
      >
        <Flex>
          <Flex flex="1" marginRight="8" justifyContent="center">
            <VStack spacing="2" justifyContent="center" maxWidth="520px">
              <ErrorConnectionIllustration />
              <chakra.span
                display="inline-block"
                fontFamily="heading"
                fontSize="3xl"
                fontWeight="semibold"
              >
                Wave app incompatible
              </chakra.span>
              <chakra.span display="inline-block" fontFamily="body" textAlign="center">
                We can’t communicate with this display since it is using an older, incompatible
                version of the Wave app. Please make sure the latest version is installed to
                continue using this display.
              </chakra.span>
            </VStack>
          </Flex>
          <Box flex="1">
            <Flex>
              <VStack flex="1" spacing="6" alignItems="flex-start">
                <DisplayProperty label="Serial number" value={display.serialNumber} />
                <DisplayProperty label="Model number" value={display.commercialTypeNumber} />
                <DisplayProperty
                  label="Platform"
                  value={getPlatformLabel(display.platform as DisplayPlatform)}
                />
              </VStack>
              <VStack flex="1" spacing="6" alignItems="flex-start">
                <DisplayProperty
                  label="Firmware"
                  value={display.firmware?.android.version ?? 'Unknown'}
                />
                <DisplayProperty label="Wave app" value={display.agentVersion ?? 'Unknown'} />
              </VStack>
            </Flex>
            <Flex marginTop="12">
              <VStack flex="1" spacing="6" alignItems="flex-start">
                <DisplayProperty
                  label="Ethernet MAC"
                  value={display.networkInformation.ethernetMacAddress}
                />
                <DisplayProperty
                  label="Wifi MAC"
                  value={display.networkInformation.wifiMacAddress ?? 'Unknown'}
                />
              </VStack>
              <VStack flex="1" spacing="6" alignItems="flex-start">
                <DisplayProperty
                  label="Local IP"
                  value={display.networkInformation.localIPAddress ?? 'Unknown'}
                />
              </VStack>
            </Flex>
            <Flex marginTop="12">
              <Flex flex="1">
                <Button colorScheme="red" onClick={handleUnclaim}>
                  Unclaim display
                </Button>
              </Flex>
              <Flex flex="1" alignItems="center">
                <ContactSupport />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
      {unclaimDisplayAction.confirmationNode}
    </>
  );
}

function DisplayProperty({ label, value }: { label: string; value: string }) {
  return (
    <Box>
      <Flex direction="column">
        <chakra.span display="inline-block" color="gray.500" fontSize="md">
          {label}
        </chakra.span>
        <chakra.span display="inline-flex">
          <chakra.span
            display="inline-block"
            color="gray.900"
            fontFamily="heading"
            fontSize="xl"
            fontWeight="semibold"
          >
            {value}
          </chakra.span>
          <chakra.span marginLeft="3" display="inline-block">
            <CopyButton label={label} value={value} />
          </chakra.span>
        </chakra.span>
      </Flex>
    </Box>
  );
}

function ContactSupport() {
  return (
    <Button
      as={Link}
      href="https://tpvcrmplus.force.com/TPVB2B/s/"
      target="_blank"
      variant="link"
      colorScheme="blue"
      leftIcon={<HelpIcon width="6" height="6" />}
    >
      Contact support
    </Button>
  );
}

DisplayDetailEmptyView.graphql = {
  fragments: {
    DisplayDetailEmptyView_display: gql`
      fragment DisplayDetailEmptyView_display_display on Display {
        id
        alias
        serialNumber
        commercialTypeNumber
        agentVersion
        platform {
          name
          version
          type
        }
        firmware {
          android {
            version
          }
        }
        networkInformation {
          ethernetMacAddress
          wifiMacAddress
          localIPAddress
        }
        ...DisplayDetailHeader_display
      }
    `,
  },
  mutations: {
    DisplayDetailEmptyView_UnclaimDisplay: gql`
      mutation DisplayDetailEmptyView_UnclaimDisplay($input: DisplayUnclaimInput!) {
        displayUnclaim(input: $input) {
          customer {
            id
          }
        }
      }
    `,
  },
};
