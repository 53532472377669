import { gql } from '@apollo/client';
import { chakra, Image, Table, Tbody, Td, Th, Thead, Tr, VisuallyHidden } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { LandscapeIcon } from '~components/ui/icons';
import { Logo } from '~components/ui/illustrations';
import { OrganizationSubscriptionsFragment } from './__generated__/AppsOverviewTable.graphql';

interface Props {
  subscriptions: OrganizationSubscriptionsFragment[];
}

function AppsOverviewTable({ subscriptions }: Props) {
  /*
  
  We currently don't have the app id to allow us to link the user to the appDirect marketplace page.
  When that data is available, the 'href' will contain something similar to this example:
  href="https://marketplace.appdirect.com/apps/{appId}"

  Uncomment the following line, as well as the comment blocks down below to add the needed UI:

  const { isEnabled: isMarketPlaceLinkEnabled } = useFeatureFlag('appDirectMarketplaceLink');

  */

  return (
    <>
      <Table variant="action" width="full" color="gray.700">
        <Thead>
          <Tr>
            <Th>
              <VisuallyHidden>Logo</VisuallyHidden>
            </Th>
            <Th color="gray.600" fontWeight="semibold">
              Name
            </Th>
            <Th color="gray.600" fontWeight="semibold">
              Usage
            </Th>
            <Th color="gray.600" fontWeight="semibold">
              Seats
            </Th>
            {/*{isMarketPlaceLinkEnabled && (
              <Th>
                <VisuallyHidden>External Link</VisuallyHidden>
              </Th>
            )}*/}
          </Tr>
        </Thead>
        <Tbody>
          {subscriptions.map((subscription) => {
            // We will receive a null value when the app has infinite seats available
            const {
              usage: { max: maxSeats, current: currentSeats },
            } = subscription;
            const hasInfiniteSeats = isNil(maxSeats);

            const name = subscription.name;
            const seatsLeft = hasInfiniteSeats ? '∞' : maxSeats - currentSeats;
            const iconUrl = subscription.iconUrl;
            return (
              <Tr key={subscription.id}>
                <Td maxWidth="12">
                  {iconUrl ? (
                    <Image
                      borderRadius="base"
                      border="1px"
                      borderColor="gray.100"
                      width="12"
                      height="12"
                      src={iconUrl}
                    />
                  ) : (
                    <Logo
                      borderRadius="4px"
                      border="1px"
                      borderColor="gray.100"
                      width="12"
                      height="12"
                    />
                  )}
                </Td>
                <Td fontSize="sm" color="gray.900">
                  <chakra.strong>{name}</chakra.strong>
                </Td>
                <Td>
                  <LandscapeIcon width="6" height="6" />
                  <chakra.span marginLeft="2">{currentSeats}</chakra.span>
                </Td>
                <Td>
                  <chakra.span>
                    {hasInfiniteSeats
                      ? seatsLeft
                      : seatsLeft === 0
                      ? 'no seats left'
                      : `${seatsLeft} left`}
                  </chakra.span>
                </Td>
                {/*isMarketPlaceLinkEnabled && (
                  <Td width="80px">
                    <Link
                      href="https://marketplace.appdirect.com/apps/123"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ExternalLinkIcon width="6" height="6" color="gray.300" />
                    </Link>
                  </Td>
                )*/}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}

AppsOverviewTable.graphql = {
  fragments: {
    OrganizationSubscriptions: gql`
      fragment OrganizationSubscriptions on OrganizationAppSubscription {
        id
        name
        iconUrl
        updatedAt
        usage {
          current
          max
        }
      }
    `,
  },
};

export default AppsOverviewTable;
