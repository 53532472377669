/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type VolumeCell_ValueFragment = { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', desired?: boolean | null, reported: boolean } | null, level?: { __typename: 'VolumeLevelState', desired?: number | null, reported: number } | null };

export type VolumeCell_DisplayFragment = { __typename: 'Display', id: string, volume?: { __typename: 'Volume', isMuted?: { __typename: 'VolumeMuteState', desired?: boolean | null, reported: boolean } | null, level?: { __typename: 'VolumeLevelState', desired?: number | null, reported: number } | null } | null };

export const VolumeCell_ValueFragmentDoc = gql`
    fragment VolumeCell_value on Volume {
  isMuted {
    desired
    reported
  }
  level {
    desired
    reported
  }
}
    `;
export const VolumeCell_DisplayFragmentDoc = gql`
    fragment VolumeCell_display on Display {
  id
  volume {
    ...VolumeCell_value
  }
}
    ${VolumeCell_ValueFragmentDoc}`;