import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  FormErrorMessage,
  HStack,
  Text,
  useRadioGroup,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { OptionProps } from 'react-select';
import { z } from 'zod';
import { ContentItem } from '~components/displays/DisplayDetail/summary/ContentItem';
import { InfoAlert } from '~components/ui/Alert';
import { BoxedRadio } from '~components/ui/BoxedRadio';
import { TrashIcon } from '~components/ui/icons';
import { Link } from '~components/ui/Link';
import { selectOptionBaseSchema } from '~components/ui/Select';
import { Display, Playlist } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { formatBytes } from '~utils/file';
import {
  useBulkUpdatePlaylistMutation,
  UseManagePlaylistForm_CustomerFragment,
} from './__generated__/useManagePlaylistForm.graphql';

const playlistOption = selectOptionBaseSchema.extend({
  size: z.string(),
});

type PlaylistOption = z.TypeOf<typeof playlistOption>;

const schema = z.object({
  displayIds: z.array(z.string()),
  playlist: playlistOption.optional().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

export function useManagePlaylistForm({
  customer,
  displayIds,
  onCancel,
  onSuccess,
  currentPlaylistId,
}: {
  customer: UseManagePlaylistForm_CustomerFragment;
  displayIds: Array<Display['id']>;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
  currentPlaylistId?: Playlist['id'];
}) {
  const options = useMemo<PlaylistOption[]>(
    () =>
      customer.playlists.map<PlaylistOption>((p) => ({
        label: p.title,
        value: p.id,
        size: formatBytes(p.size),
      })),
    [customer],
  );

  const currentPlaylist = useMemo(
    () => options.find((o) => o.value === currentPlaylistId),
    [currentPlaylistId, options],
  );

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      displayIds,
      playlist: currentPlaylist,
    },
    resolver: zodResolver(schema),
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'playlist',
    defaultValue: currentPlaylist?.value,
    onChange: (v) => {
      setValue(
        'playlist',
        options.find((p) => p.value === v),
      );
    },
  });

  const group = getRootProps();

  const savePlaylist = useSavePlaylist({ onSuccess });

  const selectedPlaylist = watch('playlist');
  const defaultRadio = currentPlaylist && getRadioProps({ value: currentPlaylist.value });
  const defaultOption = currentPlaylist && (
    <BoxedRadio
      key={currentPlaylist.value}
      checkedIcon={
        <TrashIcon
          width={5}
          height={5}
          onClick={(e) => {
            e.preventDefault();
            setValue('playlist', undefined);
          }}
        />
      }
      {...defaultRadio}
      isChecked={selectedPlaylist?.value === currentPlaylist.value}
    >
      <HStack justifyContent="space-between">
        <ContentItem title={currentPlaylist.label} subtitle={currentPlaylist.size} mb="0" />
        <chakra.span color="gray.500" fontSize="sm" whiteSpace="nowrap">
          {selectedPlaylist === undefined ? 'Proceed to remove' : 'Currently installed'}
        </chakra.span>
      </HStack>
    </BoxedRadio>
  );

  const body = (
    <>
      {options.length > 0 && (
        <InfoAlert mb="6">
          You can only add 1 playlist per display. By adding a new playlist, the current playlist
          will be replaced.
        </InfoAlert>
      )}

      <ErrorMessage
        errors={errors}
        name="playlist"
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />

      {options.length === 0 && displayIds.length > 0 && (
        <Text textAlign="center">
          You haven&apos;t created any playlist for this customer yet. You can visit your{' '}
          <Link to="../../playlists" color="blue.500">
            customer playlists
          </Link>{' '}
          to create one.
        </Text>
      )}

      {options.length === 0 && displayIds.length === 1 && (
        <Text textAlign="center">You haven&apos;t added any playlists to this display yet</Text>
      )}

      {options.length > 0 && (
        <VStack {...group} spacing="2" alignItems="stretch">
          {defaultOption}
          {options
            .filter((p) => p.value !== currentPlaylist?.value)
            .map((option) => {
              const { value, size, label } = option;
              const radio = getRadioProps({ value });
              return (
                <BoxedRadio key={value} {...radio}>
                  <ContentItem title={label} subtitle={size} mb="0" />
                </BoxedRadio>
              );
            })}
        </VStack>
      )}
    </>
  );

  const footer = (
    <>
      <Button
        onClick={onCancel}
        variant="ghost"
        colorScheme="blue"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="solid"
        colorScheme="blue"
        marginLeft="3"
        type="submit"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Apply
      </Button>
    </>
  );

  const playlistHasChanged =
    selectedPlaylist?.label !== currentPlaylist?.label ||
    selectedPlaylist?.value !== currentPlaylist?.value;

  return {
    onSubmit: handleSubmit(playlistHasChanged ? savePlaylist : onSuccess),
    body,
    footer,
  };
}

export function ManagePlaylistSelectOption({
  isSelected,
  innerProps,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
OptionProps<any, any, any>) {
  const data = rest.data as PlaylistOption;

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingX="3.5"
      paddingY="2"
      cursor="pointer"
      color={isSelected ? 'white' : 'blue.800'}
      background={isSelected ? 'blue.400' : 'white'}
      fontSize="md"
      _hover={{
        background: isSelected ? 'blue.400' : 'blue.50',
      }}
      {...innerProps}
    >
      <chakra.span display="inline-block" flex="1">
        {data.label}
      </chakra.span>
      <chakra.span display="inline-block" fontSize="sm" fontWeight="semibold">
        {data.size}
      </chakra.span>
    </Box>
  );
}

export function useSavePlaylist({ onSuccess }: { onSuccess?: () => void } = {}) {
  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const [bulkUpdatePlaylist] = useBulkUpdatePlaylistMutation();
  return useCallback(
    async ({ displayIds, playlist }: FormValues) => {
      try {
        await bulkUpdatePlaylist({
          variables: {
            input: {
              displayIds,
              playlistId: playlist?.value,
            },
          },
        });

        if (displayIds.length === 1) {
          analytics.track('displaySettingUpdate', { group: 'playback', changeItem: 'playlist' });
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'updatePlaylist',
            displayCount: displayIds.length,
          });
        }

        onSuccess?.();
      } catch (error) {
        toast({
          status: 'error',
          title:
            displayIds.length === 1 ? 'Cannot update playlist' : 'Cannot update playlists in bulk',
          description: fromError(
            error,
            displayIds.length === 1 ? 'UpdatePlaylist' : 'bulkUpdatePlaylist',
            {
              INSUFFICIENT_STORAGE:
                'Display has insufficient storage. To update the playlist, first free up storage on the display.',
            },
          ),
        });
      }
    },
    [analytics, bulkUpdatePlaylist, onSuccess, toast],
  );
}

useManagePlaylistForm.graphql = {
  fragments: {
    useManagePlaylistForm_customer: gql`
      fragment useManagePlaylistForm_customer on Customer {
        id
        playlists {
          id
          title
          size
        }
      }
    `,
  },
};

useSavePlaylist.graphql = {
  mutations: {
    BulkManagePlaylist: gql`
      mutation BulkUpdatePlaylist($input: DisplayBulkUpdatePlaylistInput!) {
        displayBulkUpdatePlaylist(input: $input) {
          displays {
            id
            playlist {
              current {
                id
                title
              }
            }
          }
        }
      }
    `,
  },
};
