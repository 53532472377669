import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { OrganizationAppSubscription } from '~components/displays/BulkAction/BulkManageApps';
import { AppSubscription } from '~components/displays/useManageAppSubscriptionsForm';
import { getSubscriptionVersion } from '~components/displays/utils';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  GridIcon,
  LoadingIcon,
  TrashIcon,
} from '~components/ui/icons';

export function ContentModalAppEntry({
  subscription,
  version,
  onDelete,
  onInstall,
  progressLabel,
  unavailabilityReason,
  disabled = false,
}: {
  subscription: AppSubscription | OrganizationAppSubscription;
  version?: string;
  onDelete?: () => void;
  onInstall?: (version: string) => void;
  progressLabel?: string;
  unavailabilityReason?: string;
  disabled?: boolean;
}) {
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(
    version ?? subscription.appVersions?.[0],
  );
  const [versionToggleOpened, setVersionToggleOpened] = useState(false);

  // Note: We are using a Chakra UI `Menu` inside a `Modal`, and the default interaction will close the modal
  // automatically. To prevent this, we want to control the opened state ourselves.
  const handleVersionToggleButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setVersionToggleOpened(!versionToggleOpened);
  };

  return (
    <HStack spacing={3} justifyContent="center" width="100%">
      <Box
        borderRadius="base"
        border="1px solid"
        borderColor="gray.100"
        overflow="hidden"
        opacity={disabled ? '0.5' : undefined}
      >
        {subscription.iconUrl ? (
          <Image
            width="48px"
            height="48px"
            src={subscription.iconUrl}
            alt={`App icon for ${subscription.name}`}
          />
        ) : (
          <Box
            display="flex"
            width="48px"
            height="48px"
            justifyContent="center"
            alignItems="center"
          >
            <GridIcon color="gray.500" width={4} height={4} />
          </Box>
        )}
      </Box>
      <VStack alignItems="flex-start" spacing={0} flex={1}>
        <VStack alignItems="flex-start" spacing={0}>
          <HStack spacing={3} alignItems="center">
            <Heading
              size="md"
              color="gray.900"
              mb="0"
              lineHeight="7"
              opacity={disabled ? '0.5' : undefined}
            >
              {subscription.name}
            </Heading>
            {progressLabel !== undefined && (
              <Tooltip label={progressLabel}>
                <Flex
                  aria-label={progressLabel}
                  justifyContent="center"
                  alignItems="center"
                  height="7"
                  minWidth="7"
                >
                  <LoadingIcon color="gray.400" height={5} width={5} />
                </Flex>
              </Tooltip>
            )}
          </HStack>
          {onInstall !== undefined &&
            subscription.appVersions &&
            subscription.appVersions.length > 0 && (
              <Menu isOpen={versionToggleOpened}>
                <MenuButton onClick={handleVersionToggleButtonClick} color="gray.500">
                  {selectedVersion}
                  {versionToggleOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </MenuButton>
                <MenuList>
                  <MenuGroup
                    title="Latest version"
                    color="gray.400"
                    fontSize="sm"
                    px="3"
                    py="1.5"
                    m="0"
                  >
                    <MenuItem
                      onClick={() => {
                        setSelectedVersion(subscription.appVersions?.[0] ?? selectedVersion);
                        setVersionToggleOpened(false);
                      }}
                      fontWeight="bold"
                      color="gray.900"
                      backgroundColor={
                        selectedVersion === subscription.appVersions?.[0] ? 'blue.50' : undefined
                      }
                      _hover={{ backgroundColor: 'gray.100' }}
                    >
                      {subscription.appVersions[0]}
                    </MenuItem>
                  </MenuGroup>
                  {subscription.appVersions.length > 1 && (
                    <MenuGroup
                      title="Older versions"
                      color="gray.400"
                      fontSize="sm"
                      px="3"
                      py="1.5"
                      m="0"
                    >
                      {subscription.appVersions.slice(1).map((version) => (
                        <MenuItem
                          key={version}
                          onClick={() => {
                            setSelectedVersion(version);
                            setVersionToggleOpened(false);
                          }}
                          fontWeight="bold"
                          color="gray.900"
                          backgroundColor={selectedVersion === version ? 'blue.50' : undefined}
                          _hover={{ backgroundColor: 'gray.100' }}
                        >
                          {version}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  )}
                </MenuList>
              </Menu>
            )}
          {onDelete !== undefined && (
            <chakra.span color="gray.500" opacity={disabled ? '0.5' : undefined}>
              {version ?? getSubscriptionVersion(subscription) ?? <span>&nbsp;</span>}
            </chakra.span>
          )}
        </VStack>
      </VStack>
      <Box>
        {onInstall !== undefined && selectedVersion !== undefined && (
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            onClick={() => onInstall(selectedVersion)}
            isDisabled={disabled}
          >
            Add
          </Button>
        )}
        {onDelete !== undefined && (
          <Button
            size="sm"
            variant="inline"
            colorScheme="gray"
            onClick={onDelete}
            isDisabled={disabled}
          >
            <TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
          </Button>
        )}
        {unavailabilityReason !== undefined && unavailabilityReason !== '' && (
          <chakra.span color="gray.500">{unavailabilityReason}</chakra.span>
        )}
      </Box>
    </HStack>
  );
}
