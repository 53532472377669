/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PlatformCell_ValueFragment = { __typename: 'DisplayPlatform', name: string, version?: string | null };

export type PlatformCell_DisplayFragment = { __typename: 'Display', id: string, platform: { __typename: 'DisplayPlatform', name: string, version?: string | null } };

export const PlatformCell_ValueFragmentDoc = gql`
    fragment PlatformCell_value on DisplayPlatform {
  name
  version
}
    `;
export const PlatformCell_DisplayFragmentDoc = gql`
    fragment PlatformCell_display on Display {
  id
  platform {
    ...PlatformCell_value
  }
}
    ${PlatformCell_ValueFragmentDoc}`;