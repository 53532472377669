/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PowerBadge_DisplayFragment = { __typename: 'Display', id: string, power?: { __typename: 'PowerState', desired?: Types.Power | null, reported: Types.Power } | null };

export const PowerBadge_DisplayFragmentDoc = gql`
    fragment PowerBadge_display on Display {
  id
  power {
    desired
    reported
  }
}
    `;