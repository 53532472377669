/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type TitleCell_MediaFragment = { __typename: 'Media', id: string, title: string };

export const TitleCell_MediaFragmentDoc = gql`
    fragment TitleCell_media on Media {
  id
  title
}
    `;