/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ManagePowerScheduleModal_CustomerFragment = { __typename: 'Customer', id: string, powerSchedules: Array<{ __typename: 'PowerSchedule', id: string, title: string }> };

export type ManagePowerScheduleModal_DisplayFragment = { __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', id: string, title: string, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }> } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string } | { __typename: 'PowerScheduleUpdateCompleted', id: string } | { __typename: 'PowerScheduleUpdateInProgress', id: string } | { __typename: 'PowerScheduleUpdateRejected', id: string } | null } | null };

export type BulkUpdatePowerScheduleMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdatePowerScheduleInput;
}>;


export type BulkUpdatePowerScheduleMutation = { __typename: 'Mutation', displayBulkUpdatePowerSchedule: { __typename: 'DisplayBulkUpdatePowerSchedulePayload', displays: Array<{ __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', id: string, title: string, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }> } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string } | { __typename: 'PowerScheduleUpdateCompleted', id: string } | { __typename: 'PowerScheduleUpdateInProgress', id: string } | { __typename: 'PowerScheduleUpdateRejected', id: string } | null } | null }> } };

export const ManagePowerScheduleModal_CustomerFragmentDoc = gql`
    fragment ManagePowerScheduleModal_customer on Customer {
  id
  powerSchedules {
    id
    title
  }
}
    `;
export const ManagePowerScheduleModal_DisplayFragmentDoc = gql`
    fragment ManagePowerScheduleModal_display on Display {
  id
  powerSchedule {
    schedule {
      id
      title
      timeBlocks {
        start
        end
        day
      }
    }
    latestJob {
      id
      __typename
    }
  }
}
    `;
export const BulkUpdatePowerScheduleDocument = gql`
    mutation BulkUpdatePowerSchedule($input: DisplayBulkUpdatePowerScheduleInput!) {
  displayBulkUpdatePowerSchedule(input: $input) {
    displays {
      id
      ...ManagePowerScheduleModal_display
    }
  }
}
    ${ManagePowerScheduleModal_DisplayFragmentDoc}`;
export type BulkUpdatePowerScheduleMutationFn = ApolloReactCommon.MutationFunction<BulkUpdatePowerScheduleMutation, BulkUpdatePowerScheduleMutationVariables>;

/**
 * __useBulkUpdatePowerScheduleMutation__
 *
 * To run a mutation, you first call `useBulkUpdatePowerScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdatePowerScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdatePowerScheduleMutation, { data, loading, error }] = useBulkUpdatePowerScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdatePowerScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdatePowerScheduleMutation, BulkUpdatePowerScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdatePowerScheduleMutation, BulkUpdatePowerScheduleMutationVariables>(BulkUpdatePowerScheduleDocument, options);
      }
export type BulkUpdatePowerScheduleMutationHookResult = ReturnType<typeof useBulkUpdatePowerScheduleMutation>;
export type BulkUpdatePowerScheduleMutationResult = ApolloReactCommon.MutationResult<BulkUpdatePowerScheduleMutation>;
export type BulkUpdatePowerScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdatePowerScheduleMutation, BulkUpdatePowerScheduleMutationVariables>;