import { gql } from '@apollo/client';
import { Box, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { Maybe } from '~graphql/__generated__/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { PowerScheduleCell_ValueFragment } from './__generated__/PowerScheduleCell.graphql';

export function PowerScheduleCell({
  value,
}: PropsWithChildren<
  CellProps<DisplayTable_DisplayFragment, Maybe<PowerScheduleCell_ValueFragment> | undefined>
>) {
  if (value?.schedule == null) {
    return <Box />;
  }

  return (
    <Tooltip label={value.schedule?.title}>
      <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {value.schedule?.title}
      </Box>
    </Tooltip>
  );
}

PowerScheduleCell.graphql = {
  fragments: {
    PlaylistCell_value: gql`
      fragment PowerScheduleCell_value on DisplayPowerSchedule {
        schedule {
          title
        }
      }
    `,
    PlaylistCell_display: gql`
      fragment PowerScheduleCell_display on Display {
        id
        powerSchedule {
          ...PowerScheduleCell_value
        }
      }
    `,
  },
};
