import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DisplayDetailEmptyView } from '~components/displays/DisplayDetail/DisplayDetailEmptyView';
import { DisplayDetailView } from '~components/displays/DisplayDetail/DisplayDetailView';
import { RecommendedSettingsBanner } from '~components/displays/DisplayDetail/RecommendedSettingsBanner';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { HandleApiError } from '~graphql/HandleApiError';
import { ensure } from '~utils/types';
import { useCustomerDisplayDetailPageQuery } from './__generated__/[id].graphql';

const POLL_INTERVAL = 5000;

export function CustomerDisplayDetailPage() {
  const { customer, displayId } = useParams();

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useCustomerDisplayDetailPageQuery({
      variables: {
        customerHandle: ensure(customer),
        displayId: ensure(displayId),
      },
      pollInterval: POLL_INTERVAL,
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const customerData = useMemo(() => data?.customerByHandle, [data?.customerByHandle]);

  const refetchData = useCallback(async () => {
    await refetch({ customerHandle: customer, displayId });
  }, [refetch, customer, displayId]);

  const title = customerData?.display?.alias ?? 'Display Info';

  return (
    <Page title={`Displays - ${title}`} pageName="display_detail">
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          customerData &&
          customerData.display && (
            <>
              {customerData.display.hasEmptyShadow ? (
                <DisplayDetailEmptyView display={customerData.display} title={title} />
              ) : (
                <>
                  <DisplayDetailView
                    customer={customerData}
                    display={customerData.display}
                    refetchData={refetchData}
                    title={title}
                  />
                  <RecommendedSettingsBanner display={customerData.display} />
                </>
              )}
            </>
          )
        )}
      </PageContent>
    </Page>
  );
}

CustomerDisplayDetailPage.graphql = {
  queries: {
    CustomerDisplayDetailPage: gql`
      query CustomerDisplayDetailPage($customerHandle: String!, $displayId: ID!) {
        customerByHandle(handle: $customerHandle) {
          id
          name
          ...DisplayDetailView_customer
          display(id: $displayId) {
            id
            alias
            serialNumber
            hasEmptyShadow
            ...DisplayDetailView_display
            ...RecommendedSettingsBanner_display
            ...PowerBadge_display
            ...PresenceBadge_display
          }
        }
      }
    `,
  },
};
