import * as Sentry from '@sentry/react';
import { noop } from 'lodash';

type Logger = {
  info: typeof console.log;
  error: typeof console.error;
  fatal: (err: unknown, extra?: Record<string, unknown>) => void;
};

export const logger: Logger =
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'production'
    ? {
        info: noop,
        error: noop,
        fatal: (err, extra) => Sentry.captureException(err, { extra }),
      }
    : {
        info: console.log,
        error: console.error,
        fatal: (err, extra) => {
          if (extra) {
            console.group();
            console.error(err);
            console.info(extra);
            console.groupEnd();
          } else {
            console.error(err);
          }
        },
      };
