import {
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { slice, take } from 'lodash';
import { FilterOption } from '~components/displays/DisplayTable/constants';

type Props = {
  filters: FilterOption[];
  onDisable: (filter: FilterOption) => void;
  onReset: () => void;
};

export function FilterTagList({ filters, onDisable, onReset }: Props) {
  const numberOfVisibleFilters = useBreakpointValue<number>({ base: 2, md: 4, lg: 6 });
  const visibleFilters = take(filters, numberOfVisibleFilters);
  const hiddenFilters = slice(filters, numberOfVisibleFilters);

  return (
    <HStack>
      {visibleFilters.map((filter) => (
        <FilterTag key={filter.value} filter={filter} onClose={() => onDisable(filter)} />
      ))}
      {hiddenFilters.length !== 0 ? <OverflowFilterTag filters={hiddenFilters} /> : null}

      {visibleFilters.length > 1 ? <DisplayFilterReset onClick={onReset} /> : null}
    </HStack>
  );
}

function FilterTag({ filter, onClose }: { filter: FilterOption; onClose: () => void }) {
  return (
    <Tag maxW="36" variant="solid" colorScheme="gray" onClick={onClose} cursor="pointer">
      <TagLabel fontSize="sm">{filter.label}</TagLabel>
      <TagCloseButton />
    </Tag>
  );
}

function OverflowFilterTag({ filters }: { filters: FilterOption[] }) {
  return (
    <Tooltip label={filters.map((filter) => filter.label).join(', ')}>
      <Tag variant="solid" colorScheme="gray">
        <TagLabel>...</TagLabel>
      </Tag>
    </Tooltip>
  );
}

function DisplayFilterReset({ onClick }: { onClick: () => void }) {
  return (
    <Tag variant="ghost" cursor="pointer" onClick={onClick}>
      <TagLabel>clear filters</TagLabel>
    </Tag>
  );
}
