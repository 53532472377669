import { Avatar as ChakraAvatar, AvatarProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

// Chakra bug: https://github.com/chakra-ui/chakra-ui/issues/4304
// And while that thread mentions that a fix has been released, it still occurs here, so implementing workaround
export const Avatar = ({ src, ...props }: AvatarProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [src]);

  return (
    <ChakraAvatar
      src={src}
      onError={() => setHasError(true)}
      bg={src && !hasError ? 'transparent' : undefined}
      {...props}
    />
  );
};
