/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { ChangeContentSourceModal_DisplayFragmentDoc } from '../../../__generated__/ChangeContentSourceModal.graphql';
export type DisplayContentSource_DisplayFragment = { __typename: 'Display', id: string, contentSource?: { __typename: 'ContentSourceSettings', current?: { __typename: 'ContentSourceState', reported: { __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }, desired?: { __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string } | null } | null, available?: Array<{ __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }> | null } | null, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } }, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, id: string, title: string, description?: string | null, jobId: string } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, id: string, title: string, description?: string | null, jobId: string } | null } | null };

export const DisplayContentSource_DisplayFragmentDoc = gql`
    fragment DisplayContentSource_display on Display {
  id
  contentSource {
    current {
      reported {
        ... on AppContentSource {
          label
          applicationId
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
      desired {
        ... on AppContentSource {
          label
          applicationId
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    }
    available {
      ... on AppContentSource {
        label
        applicationId
      }
      ... on BookmarkContentSource {
        index
      }
      ... on InputContentSource {
        source
      }
      ... on PlaylistContentSource {
        playlistId
      }
    }
  }
  bookmarks {
    all {
      reported
      desired
    }
  }
  playlist {
    current {
      id
      title
    }
  }
  ...ChangeContentSourceModal_display
}
    ${ChangeContentSourceModal_DisplayFragmentDoc}`;