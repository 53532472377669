import { Box, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

export function SummaryCard({ title, children }: { title: string; children: ReactNode }) {
  return (
    <>
      <Heading fontSize="1.5rem" marginBottom="4">
        {title}
      </Heading>
      <Box
        boxShadow="0px 6px 16px -1px rgba(0, 56, 95, 0.06)"
        bg="white"
        paddingY="6"
        paddingX="8"
        marginBottom="6"
        borderRadius="base"
      >
        {children}
      </Box>
    </>
  );
}
