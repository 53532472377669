/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type TitleCell_PlaylistFragment = { __typename: 'Playlist', id: string, title: string };

export const TitleCell_PlaylistFragmentDoc = gql`
    fragment TitleCell_playlist on Playlist {
  id
  title
}
    `;