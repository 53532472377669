import { Tag } from '@chakra-ui/react';
import { WaveSubscriptionEnum } from '~utils/subscriptions';

interface Props {
  variant: WaveSubscriptionEnum;
}

export function SubscriptionTag(props: Props) {
  const { variant } = props;
  return (
    <Tag
      variant="solid"
      background={isEssentialType(variant) ? 'blue.400' : 'gray.50'}
      color={isEssentialType(variant) ? 'white' : 'blue.800'}
      borderRadius="full"
    >
      {variant}
    </Tag>
  );
}

function isEssentialType(type: WaveSubscriptionEnum) {
  return type === WaveSubscriptionEnum.Essential;
}
