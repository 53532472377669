import { Box, chakra } from '@chakra-ui/react';
import Select, { SingleValue } from 'react-select';
import { components, SelectOption } from './Select';

export const defaultPageSizes = [25, 50];

interface Props {
  label: string;
  onChange: (pageSize: number) => void;
  options?: number[];
  value: number;
}

export function PageSizeSelector({
  label,
  onChange,
  options: sizes = defaultPageSizes,
  value: currentValue,
}: Props) {
  const options = sizes.map<SelectOption>((size) => ({ label: `${size}`, value: size.toString() }));
  const selectedOption = options.find(({ value }) => value === currentValue.toString());

  const handleChange = (option: SingleValue<SelectOption>) =>
    onChange(option === null ? sizes[0] : parseInt(option.value, 10));

  return (
    <Box display="flex" alignItems="center">
      <Box width="max-content">
        <Select
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          placeholder=""
          components={components}
          options={options}
          value={selectedOption}
          onChange={handleChange}
        />
      </Box>
      <Box marginLeft="4">
        <chakra.span color="blue.800" fontSize="sm" fontWeight="normal">
          {label}
        </chakra.span>
      </Box>
    </Box>
  );
}
