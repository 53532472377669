/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CustomersTableCustomerFragmentDoc } from '../../../../../components/organization/CustomersTable/__generated__/CustomersTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CustomersPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomersPageQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, customers: Array<{ __typename: 'Customer', id: string, name: string, displayCount: number, users: Array<{ __typename: 'User', id: string }>, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null }> } };


export const CustomersPageDocument = gql`
    query CustomersPage {
  organization {
    id
    customers {
      ...CustomersTableCustomer
    }
  }
}
    ${CustomersTableCustomerFragmentDoc}`;

/**
 * __useCustomersPageQuery__
 *
 * To run a query within a React component, call `useCustomersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomersPageQuery, CustomersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomersPageQuery, CustomersPageQueryVariables>(CustomersPageDocument, options);
      }
export function useCustomersPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersPageQuery, CustomersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomersPageQuery, CustomersPageQueryVariables>(CustomersPageDocument, options);
        }
export type CustomersPageQueryHookResult = ReturnType<typeof useCustomersPageQuery>;
export type CustomersPageLazyQueryHookResult = ReturnType<typeof useCustomersPageLazyQuery>;
export type CustomersPageQueryResult = ApolloReactCommon.QueryResult<CustomersPageQuery, CustomersPageQueryVariables>;