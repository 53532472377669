import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { VolumeHighIcon, VolumeMuteIcon } from '~components/ui/icons';
import { Maybe } from '~graphql/__generated__/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { VolumeCell_ValueFragment } from './__generated__/VolumeCell.graphql';

export function VolumeCell({
  value,
}: PropsWithChildren<
  CellProps<DisplayTable_DisplayFragment, Maybe<VolumeCell_ValueFragment> | undefined>
>) {
  const isMuted = value?.isMuted?.desired ?? value?.isMuted?.reported;

  const hasDesiredLevel = value?.level?.desired !== null && value?.level?.desired !== undefined;

  const isDesiredLevelZero = value?.level?.desired === 0;
  const isReportedLevelZero = value?.level?.reported === 0;

  const isLevelZero = hasDesiredLevel ? isDesiredLevelZero : isReportedLevelZero;

  if (isMuted === undefined && isLevelZero === undefined) {
    return <Box></Box>;
  }

  if (isMuted || isLevelZero) {
    return (
      <Box>
        <VolumeMuteIcon color="gray.500" width="actionIconSize" height="actionIconSize" />
      </Box>
    );
  }
  return (
    <Box>
      <VolumeHighIcon color="blue.500" width="actionIconSize" height="actionIconSize" />
    </Box>
  );
}

VolumeCell.graphql = {
  fragments: {
    VolumeCell_value: gql`
      fragment VolumeCell_value on Volume {
        isMuted {
          desired
          reported
        }
        level {
          desired
          reported
        }
      }
    `,
    VolumeCell_display: gql`
      fragment VolumeCell_display on Display {
        id
        volume {
          ...VolumeCell_value
        }
      }
    `,
  },
};
