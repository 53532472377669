import { gql } from '@apollo/client';
import {
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { TableInstance } from 'react-table';
import { InfoAlert } from '~components/ui/Alert';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { useFeatureFlag } from '~utils/features';
import { UserCustomersTable_CustomerFragment as CustomerFragment } from './__generated__/UserCustomersTable.graphql';
import { UserCustomersTableFilter } from './Filtering';
import { UserCustomersTablePageSizeSelector, UserCustomersTablePagination } from './Pagination';

interface UserCustomersTableProps {
  table1: TableInstance<CustomerFragment>;
  table2: TableInstance<CustomerFragment>;
}

export function UserCustomersTable({ table1, table2 }: UserCustomersTableProps) {
  const {
    getTableProps: getEssentialTableProps,
    getTableBodyProps: getEssentialTableBodyProps,
    headerGroups: essentialHeaderGroups,
    prepareRow: essentialPrepareRow,
    page: essentialUsers,
  } = table1;
  const {
    getTableProps: getLiteTableProps,
    getTableBodyProps: getLiteTableBodyProps,
    headerGroups: liteHeaderGroups,
    prepareRow: litePrepareRow,
    page: liteUsers,
  } = table2;
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setCurrentTabIndex(index);
  };

  return (
    <VStack spacing="6" alignItems="stretch">
      <Text color="gray.500">Select customers in this table to add them to this user.</Text>
      {isSubscriptionsEnabled && (
        <InfoAlert mt="6" mb="10">
          <AlertTitle>
            Customer management is only available for customers connected to an Essential plan
          </AlertTitle>
          <AlertDescription>
            Connect your customer to an Essential plan via the subscriptions section on the customer
            edit page or the subscriptions settings page.
          </AlertDescription>
        </InfoAlert>
      )}
      <Tabs variant="unstyled" index={currentTabIndex} onChange={handleTabsChange}>
        <Flex alignItems="center" justifyContent="space-between">
          <TabList mt={-6}>
            <Tab
              _selected={{ color: 'gray.600', bg: '#cad4dd' }}
              color="gray.600"
              borderWidth="1px"
              borderRightWidth="0"
              borderColor="gray.300"
              borderRadius="md"
              borderRightRadius="0"
              px={4}
            >
              Essential
            </Tab>
            <Tab
              _selected={{ color: 'gray.600', bg: '#cad4dd' }}
              color="gray.600"
              borderWidth="1px"
              borderLeftWidth="0"
              borderColor="gray.300"
              borderRadius="md"
              borderLeftRadius="0"
              px={7}
            >
              Lite
            </Tab>
          </TabList>
          <Box ml={9} mb={6} mt={-6} flex="1">
            {currentTabIndex === 0 ? (
              <UserCustomersTableFilter table={table1} />
            ) : (
              <UserCustomersTableFilter table={table2} />
            )}
          </Box>
        </Flex>
        <TabPanels>
          <TabPanel>
            <Table {...getEssentialTableProps()} variant="simple" width="100%" marginTop={-4}>
              <Thead>
                {essentialHeaderGroups.map((headerGroup) => {
                  const headerGroupProps = headerGroup.getHeaderGroupProps();
                  return (
                    <Tr {...headerGroupProps} key={headerGroupProps.key}>
                      {headerGroup.headers.map((column) => {
                        const sortProps = column.getSortByToggleProps();
                        const sortColumn = sortProps.onClick;
                        const headerProps = column.getHeaderProps(sortProps);
                        return (
                          <Th
                            {...headerProps}
                            key={headerProps.key}
                            color={column.isSorted ? 'gray.700' : 'gray.600'}
                            minWidth={`${column.minWidth}px`}
                            maxWidth={`${column.maxWidth}px`}
                            width={column.width}
                            _hover={
                              column.disableSortBy
                                ? {}
                                : {
                                    textColor: 'gray.800',
                                  }
                            }
                            onClick={sortColumn}
                          >
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <Box flex="1">{column.render('Header')}</Box>
                              {column.canSort && (
                                <Box>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDownIcon display="inherit" />
                                    ) : (
                                      <ChevronUpIcon display="inherit" />
                                    )
                                  ) : (
                                    <ChevronDownIcon visibility="hidden" />
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Th>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Thead>
              <Tbody {...getEssentialTableBodyProps()}>
                {essentialUsers.map((row) => {
                  essentialPrepareRow(row);
                  const rowProps = row.getRowProps();

                  return (
                    <Tr {...rowProps} key={rowProps.key}>
                      {row.cells.map((cell) => {
                        const cellProps = cell.getCellProps();
                        return (
                          <Td
                            {...cellProps}
                            key={cellProps.key}
                            minWidth={`${cell.column.minWidth}px`}
                            maxWidth={`${cell.column.maxWidth}px`}
                            width={cell.column.width}
                          >
                            {cell.render('Cell')}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Box display="flex">
              <Box flex="1">
                <UserCustomersTablePageSizeSelector table={table1} />
              </Box>
              <UserCustomersTablePagination table={table1} />
            </Box>
          </TabPanel>
          <TabPanel>
            <InfoAlert mt="-4" mb="6">
              <AlertDescription>
                Users of lite plan can only be selected as a complete group.
              </AlertDescription>
            </InfoAlert>
            <Table {...getLiteTableProps()} variant="simple" width="100%">
              <Thead>
                {liteHeaderGroups.map((headerGroup) => {
                  const headerGroupProps = headerGroup.getHeaderGroupProps();

                  return (
                    <Tr {...headerGroupProps} key={headerGroupProps.key}>
                      {headerGroup.headers.map((column) => {
                        const sortProps = column.getSortByToggleProps();
                        const sortColumn = sortProps.onClick;
                        const headerProps = column.getHeaderProps(sortProps);

                        return (
                          <Th
                            {...headerProps}
                            key={headerProps.key}
                            color={column.isSorted ? 'gray.700' : 'gray.600'}
                            minWidth={`${column.minWidth}px`}
                            maxWidth={`${column.maxWidth}px`}
                            width={column.width}
                            _hover={
                              column.disableSortBy
                                ? {}
                                : {
                                    textColor: 'gray.800',
                                  }
                            }
                            onClick={sortColumn}
                          >
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <Box flex="1">{column.render('Header')}</Box>
                              {column.canSort && (
                                <Box>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDownIcon display="inherit" />
                                    ) : (
                                      <ChevronUpIcon display="inherit" />
                                    )
                                  ) : (
                                    <ChevronDownIcon visibility="hidden" />
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Th>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Thead>
              <Tbody {...getLiteTableBodyProps()}>
                {liteUsers.map((row) => {
                  litePrepareRow(row);
                  const rowProps = row.getRowProps();

                  return (
                    <Tr {...rowProps} key={rowProps.key}>
                      {row.cells.map((cell) => {
                        const cellProps = cell.getCellProps();
                        return (
                          <Td
                            {...cellProps}
                            key={cellProps.key}
                            minWidth={`${cell.column.minWidth}px`}
                            maxWidth={`${cell.column.maxWidth}px`}
                            width={cell.column.width}
                          >
                            {cell.render('Cell')}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Box display="flex">
              <Box flex="1">
                <UserCustomersTablePageSizeSelector table={table2} />
              </Box>
              <UserCustomersTablePagination table={table2} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}

UserCustomersTable.graphql = {
  fragments: {
    UserCustomersTableTableCustomer: gql`
      fragment UserCustomersTable_customer on PartialCustomer {
        id
        name
        waveSubscription {
          id
        }
      }
    `,
  },
};
