/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayVolumeLimits_DisplayFragment = { __typename: 'Display', id: string, volume?: { __typename: 'Volume', limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null };

export type UpdateVolumeLimitMinMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateVolumeLimitMinInput;
}>;


export type UpdateVolumeLimitMinMutation = { __typename: 'Mutation', displayUpdateVolumeLimitMin: { __typename: 'DisplayUpdateVolumeLimitMinPayload', display: { __typename: 'Display', id: string, volume?: { __typename: 'Volume', limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null } } };

export type UpdateVolumeLimitMaxMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateVolumeLimitMaxInput;
}>;


export type UpdateVolumeLimitMaxMutation = { __typename: 'Mutation', displayUpdateVolumeLimitMax: { __typename: 'DisplayUpdateVolumeLimitMaxPayload', display: { __typename: 'Display', id: string, volume?: { __typename: 'Volume', limits?: { __typename: 'VolumeLimitState', min?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null, max?: { __typename: 'VolumeLimit', reported: number, desired?: number | null } | null } | null } | null } } };

export const DisplayVolumeLimits_DisplayFragmentDoc = gql`
    fragment DisplayVolumeLimits_display on Display {
  id
  volume {
    limits {
      min {
        reported
        desired
      }
      max {
        reported
        desired
      }
    }
  }
}
    `;
export const UpdateVolumeLimitMinDocument = gql`
    mutation UpdateVolumeLimitMin($input: DisplayUpdateVolumeLimitMinInput!) {
  displayUpdateVolumeLimitMin(input: $input) {
    display {
      id
      ...DisplayVolumeLimits_display
    }
  }
}
    ${DisplayVolumeLimits_DisplayFragmentDoc}`;
export type UpdateVolumeLimitMinMutationFn = ApolloReactCommon.MutationFunction<UpdateVolumeLimitMinMutation, UpdateVolumeLimitMinMutationVariables>;

/**
 * __useUpdateVolumeLimitMinMutation__
 *
 * To run a mutation, you first call `useUpdateVolumeLimitMinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVolumeLimitMinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVolumeLimitMinMutation, { data, loading, error }] = useUpdateVolumeLimitMinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVolumeLimitMinMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVolumeLimitMinMutation, UpdateVolumeLimitMinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateVolumeLimitMinMutation, UpdateVolumeLimitMinMutationVariables>(UpdateVolumeLimitMinDocument, options);
      }
export type UpdateVolumeLimitMinMutationHookResult = ReturnType<typeof useUpdateVolumeLimitMinMutation>;
export type UpdateVolumeLimitMinMutationResult = ApolloReactCommon.MutationResult<UpdateVolumeLimitMinMutation>;
export type UpdateVolumeLimitMinMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVolumeLimitMinMutation, UpdateVolumeLimitMinMutationVariables>;
export const UpdateVolumeLimitMaxDocument = gql`
    mutation UpdateVolumeLimitMax($input: DisplayUpdateVolumeLimitMaxInput!) {
  displayUpdateVolumeLimitMax(input: $input) {
    display {
      id
      ...DisplayVolumeLimits_display
    }
  }
}
    ${DisplayVolumeLimits_DisplayFragmentDoc}`;
export type UpdateVolumeLimitMaxMutationFn = ApolloReactCommon.MutationFunction<UpdateVolumeLimitMaxMutation, UpdateVolumeLimitMaxMutationVariables>;

/**
 * __useUpdateVolumeLimitMaxMutation__
 *
 * To run a mutation, you first call `useUpdateVolumeLimitMaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVolumeLimitMaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVolumeLimitMaxMutation, { data, loading, error }] = useUpdateVolumeLimitMaxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVolumeLimitMaxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVolumeLimitMaxMutation, UpdateVolumeLimitMaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateVolumeLimitMaxMutation, UpdateVolumeLimitMaxMutationVariables>(UpdateVolumeLimitMaxDocument, options);
      }
export type UpdateVolumeLimitMaxMutationHookResult = ReturnType<typeof useUpdateVolumeLimitMaxMutation>;
export type UpdateVolumeLimitMaxMutationResult = ApolloReactCommon.MutationResult<UpdateVolumeLimitMaxMutation>;
export type UpdateVolumeLimitMaxMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVolumeLimitMaxMutation, UpdateVolumeLimitMaxMutationVariables>;