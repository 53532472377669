import { Loader } from '@googlemaps/js-api-loader';
import { isNil, isNull } from 'lodash';
import { RefObject, useCallback, useMemo, useState } from 'react';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GOOGLE_STATIC_MAPS_BASE_URL = process.env.REACT_APP_GOOGLE_MAPS_STATIC_API_BASE_URL;
const LOCAL_STORAGE_IP_LOCATION = 'ipLocation';

/**
 * Docs: https://ipapi.co/api/#complete-location
 */
type IpApiResponse = {
  country_capital: string;
  city: string;
  region: string;
  ip: string;
  country_name: string;
  latitude: number;
  longitude: number;
};

type IpLocationData = {
  capital: string | null | undefined;
};

type PlaceResult = Pick<google.maps.places.PlaceResult, 'formatted_address'>;

export function useGoogleMaps({
  inputRef,
  staticMapParams,
}: {
  inputRef: RefObject<HTMLInputElement>;
  staticMapParams?: StaticMapParams;
}) {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [place, setPlace] = useState<PlaceResult | null>(null);
  const [staticMapUrl, setStaticMapUrl] = useState<string | null>(null);

  const ipLocation = useMemo(async () => {
    const location = localStorage.getItem(LOCAL_STORAGE_IP_LOCATION);

    if (!isNil(location)) {
      return location;
    }

    const ipLocation: IpLocationData | null | undefined = await fetch('https://ipapi.co/json/')
      .then((response) => response.json() as Promise<IpApiResponse>)
      .then((data) => ({ capital: data.country_capital }));

    if (ipLocation?.capital) {
      localStorage.setItem(LOCAL_STORAGE_IP_LOCATION, ipLocation.capital);
    }

    return ipLocation?.capital;
  }, []);

  const googleLoader = useMemo(async () => {
    return await new Loader({
      apiKey: GOOGLE_MAPS_API_KEY ?? '',
      libraries: ['places'],
    }).load();
  }, []);
  const placesService = useMemo(async () => {
    const loader = await googleLoader;

    return new loader.maps.places.PlacesService(document.createElement('div'));
  }, [googleLoader]);

  const updateStaticMapUrl = useCallback(async () => {
    const defaultLocation = await ipLocation;
    const newUrl = createStaticMapUrl({ place, staticMapParams, options: { defaultLocation } });
    setStaticMapUrl(newUrl);
  }, [ipLocation, place, staticMapParams]);

  const autocomplete = useMemo(async () => {
    const loader = await googleLoader;
    const inputField = inputRef.current;

    if (isNil(inputField)) {
      return;
    }

    const autocomplete = new loader.maps.places.Autocomplete(inputField, {
      fields: ['formatted_address', 'name'],
      strictBounds: false,
    });
    autocomplete.addListener('place_changed', () => setPlace(autocomplete.getPlace()));
    updateStaticMapUrl();

    return autocomplete;
  }, [googleLoader, inputRef, updateStaticMapUrl]);

  const initMap = useCallback(
    async (address?: string | null) => {
      if (isInit) {
        return;
      }

      if (isNil(address)) {
        setIsInit(true);
        return;
      }
      const service = await placesService;
      service.findPlaceFromQuery(
        {
          query: address,
          fields: ['formatted_address'],
        },
        (results: PlaceResult[] | null) => {
          if (!isNull(results)) {
            setPlace(results[0]);
            setIsInit(true);
            return;
          }
          setPlace(null);
          setIsInit(true);
        },
      );
    },
    [isInit, placesService],
  );

  return useMemo(
    () => ({
      autocomplete,
      initMap,
      place,
      staticMapUrl,
      setPlace,
      isMapInitialized: isInit,
    }),
    [autocomplete, initMap, isInit, place, staticMapUrl],
  );
}

function createStaticMapUrl({
  place,
  staticMapParams,
  options,
}: {
  place: PlaceResult | null;
  staticMapParams?: StaticMapParams;
  options?: {
    defaultLocation?: string | null;
  };
}) {
  const params = buildStaticMapParams({ place, params: staticMapParams, options });

  const staticMapsApiUrl = `${GOOGLE_STATIC_MAPS_BASE_URL}?${params}`;
  return staticMapsApiUrl;
}

type StaticMapParams = {
  size?: string;
  scale?: 1 | 2;
  zoom?: number;
  marker?: {
    size?: 'tiny' | 'small' | 'md';
  };
  format?: 'png32' | 'png' | 'jpg';
};

function buildStaticMapParams({
  place,
  params,
  options,
}: {
  place: PlaceResult | null;
  params?: StaticMapParams;
  options?: {
    defaultLocation?: string | null;
  };
}) {
  // TPV Gent
  const defaultCenter = options?.defaultLocation ?? '51.01331792836762, 3.7094077698499164';
  const defaultSize = '600x250';
  const defaultScale = '2';
  const defaultFormat = 'png32';

  if (isNil(place) || isNil(place.formatted_address)) {
    return new URLSearchParams({
      key: GOOGLE_MAPS_API_KEY ?? '',
      center: defaultCenter,
      scale: defaultScale,
      size: defaultSize,
      format: defaultFormat,
      zoom: '10',
    }).toString();
  }

  const urlParams = new URLSearchParams({
    key: GOOGLE_MAPS_API_KEY ?? '',
    center: place.formatted_address,
    size: params?.size ?? defaultSize,
    scale: params?.scale ? String(params.scale) : defaultScale,
    zoom: params?.zoom ? String(params.zoom) : '17',
    format: params?.format ?? defaultFormat,
  }).toString();

  // Needed for "size:" parameter.
  // The semicolon should be not be converted to the encoded ASCII value. Which is automatically done by URLSearchParams
  const markersParams = `markers=size:${params?.marker?.size ?? 'small'}%7C${
    place.formatted_address
  }`;

  return `${urlParams}&${markersParams}`;
}
