import { StyleProps } from './types';

export const Tag = {
  parts: ['container', 'label', 'closeButton'],
  baseStyle: {
    container: {
      whiteSpace: 'nowrap',
    },
    label: {},
  },
  sizes: {
    md: {
      container: {
        borderRadius: 'full',
        paddingX: '2',
        fontSize: 'sm',
      },
    },
    sm: {
      container: {
        borderRadius: 'full',
        paddingX: '1',
        fontSize: 'xs',
      },
    },
    xs: {
      container: {
        borderRadius: 'full',
        paddingX: '2',
        paddingY: '1',
        fontSize: 'xs',
      },
    },
  },
  variants: {
    outline: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        container: {
          fontFamily: 'heading',
          fontWeight: 'bold',
          letterSpacing: '0.15em',
          textTransform: 'uppercase',
          borderRadius: 'base',
          border: '1px solid',
          borderColor: `${c}.100`,
          color: `${c}.700`,
          boxShadow: 'unset',
        },
        closeButton: {
          opacity: 1,

          _hover: {
            opacity: 0.8,
          },
        },
      };
    },
    solid: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        container: {
          color: 'blue.800',
          background: `${c}.200`,
          fontWeight: 'semibold',
        },
      };
    },
    ghost: (props: StyleProps) => {
      const { colorScheme: c } = props;

      return {
        container: {
          color: `${c}.500`,
          fontWeight: 'semibold',
        },
      };
    },
  },
};
