import { gql } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import {
  SyncDisplaysModal_PowerScheduleFragment,
  useSyncPowerScheduleToDisplaysMutation,
} from './__generated__/SyncDisplaysModal.graphql';

interface Props {
  powerSchedule: SyncDisplaysModal_PowerScheduleFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess?: () => Promise<void> | void;
}

const schema = z.object({
  powerScheduleId: z.string(),
});

type FormValues = z.infer<typeof schema>;

export function SyncDisplaysModal({ powerSchedule, isOpen, onCancel, onSuccess }: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      // only powerScheduleId is set as a default value in the form because it won't update through polling.
      // displayIds to sync will be supplied at submission time.
      // Alternative approach is to run an effect every time power schedule updates and reset the form.
      powerScheduleId: powerSchedule.id,
    },
    resolver: zodResolver(schema),
  });

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const [syncDisplays] = useSyncPowerScheduleToDisplaysMutation();
  const toast = useToast();
  const performSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await syncDisplays({
          variables: {
            input: {
              powerScheduleId: values.powerScheduleId,
            },
          },
        });

        toast({
          status: 'success',
          title: 'Sync to displays started',
          description:
            'The power schedule is being synced to displays, this may take a few seconds.',
        });

        reset();
        await onSuccess?.();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot start syncing to displays',
          description: fromError(err, 'SyncToDisplays'),
        });
      }
    },
    [syncDisplays, toast, reset, onSuccess],
  );

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sync displays</ModalHeader>
        <ModalCloseButton tabIndex={5} />
        <form onSubmit={handleSubmit(performSubmit)}>
          <ModalBody>
            <Text>
              Syncing the same power schedule to multiple devices on the same location may cause
              heavily increased network traffic
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleClose}
              variant="ghost"
              colorScheme="blue"
              isDisabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Sync power schedule
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

SyncDisplaysModal.graphql = {
  fragments: {
    SyncDisplaysModal_powerSchedule: gql`
      fragment SyncDisplaysModal_powerSchedule on PowerSchedule {
        id
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
      }
    `,
  },
  mutations: {
    SyncPowerScheduleToDisplays: gql`
      mutation SyncPowerScheduleToDisplays($input: PowerScheduleSyncDisplaysInput!) {
        powerScheduleSyncDisplays(input: $input) {
          displays {
            id
          }
        }
      }
    `,
  },
};
