import { AlertTitle, Box, Button, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import gql from 'graphql-tag';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { useAuth } from '~auth/useAuth';
import { AddContentModal } from '~components/displays/DisplayDetail/summary/AddContentModal';
import {
  ContentItem,
  ContentItemProps,
} from '~components/displays/DisplayDetail/summary/ContentItem';
import { PlaylistWarningAlert } from '~components/displays/DisplayDetail/summary/PlaylistWarningAlert';
import { SummaryCard } from '~components/displays/DisplayDetail/summary/SummaryCard';
import { UpdateAppModal } from '~components/displays/DisplayDetail/summary/UpdateAppModal';
import {
  InstalledAppSubscription,
  useAppSubscriptions,
  useCancelUninstallApp,
  useUninstallApp,
} from '~components/displays/useAppSubscriptions';
import { useDisplayPresence } from '~components/displays/useDisplayPresence';
import { useSavePlaylist } from '~components/displays/useManagePlaylistForm';
import { useSaveWebPages } from '~components/displays/useManageWebPagesForm';
import { usePlaylists } from '~components/displays/usePlaylists';
import { formatDownloadProgress, getSubscriptionVersion } from '~components/displays/utils';
import { ErrorAlert, InfoAlert } from '~components/ui/Alert';
import { GridIcon, PlaylistIcon, TrashIcon, WebPageIcon } from '~components/ui/icons';
import { formatBytes } from '~utils/file';
import { isDefined, MaybePromise } from '~utils/types';
import {
  ContentCard_CustomerFragment,
  ContentCard_DisplayFragment,
} from './__generated__/ContentCard.graphql';

interface Props {
  customer: ContentCard_CustomerFragment;
  display: ContentCard_DisplayFragment;
  refetchData: () => MaybePromise<void>;
}

export function ContentCard({ customer, display, refetchData }: Props) {
  const addContentModal = useDisclosure();
  const updateAppModal = useDisclosure();
  const { getPresence } = useDisplayPresence();
  const saveWebPages = useSaveWebPages({ displayIds: [display.id] });
  const savePlaylist = useSavePlaylist();
  const { getPlaylistState, abortPlaylistSync, abortConfirmationNode } = usePlaylists();
  const { verifyUserPermissions } = useAuth();

  const { isConnected } = useMemo(() => getPresence(display), [display, getPresence]);
  const playlistState = getPlaylistState(display);
  const shouldRenderPlaylists = !isNil(display.playlist);
  const shouldRenderBookmarks = !isNil(display.bookmarks.all.reported);

  const hasDisplaySettingsUpdatePermission = verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);
  const hasAppUninstallPermission = verifyUserPermissions([Permission.AppUninstall]);
  const hasAppInstallPermission = verifyUserPermissions([Permission.AppUninstall]);
  const hasDisplayContentDeletePermission = verifyUserPermissions([
    Permission.DisplayContentDelete,
  ]);

  const bookmarks = display?.bookmarks?.all?.desired || display?.bookmarks?.all?.reported || [];

  const contentList: Array<
    ContentItemProps & {
      id: string;
    }
  > = [];

  if (shouldRenderBookmarks) {
    bookmarks
      .map((bookmark, index) => {
        return {
          id: `${index}-${bookmark}`,
          icon: WebPageIcon,
          title: 'Web page',
          subtitle: bookmark ?? undefined,
          disabled: !hasDisplayContentDeletePermission,
          async handleDelete() {
            await saveWebPages({
              bookmark1: index === 0 ? '' : bookmarks[0] || '',
              bookmark2: index === 1 ? '' : bookmarks[1] || '',
              bookmark3: index === 2 ? '' : bookmarks[2] || '',
              bookmark4: index === 3 ? '' : bookmarks[3] || '',
              bookmark5: index === 4 ? '' : bookmarks[4] || '',
              bookmark6: index === 5 ? '' : bookmarks[5] || '',
              bookmark7: index === 6 ? '' : bookmarks[6] || '',
            });
          },
        };
      })
      .filter(({ subtitle }) => !!subtitle)
      .map((bookmark) => contentList.push(bookmark));
  }

  const playlist = display?.playlist?.sync ?? display?.playlist?.current;

  if (shouldRenderPlaylists && isDefined(playlist)) {
    const playlistIsSyncing = playlistState.kind === 'playlist_syncing';
    const playlistIsSyncPlanned = playlistState.kind === 'playlist_sync_planned';
    const playlistIsRemoving = playlistState.kind === 'playlist_removing';

    let progressLabel: string | undefined;
    if (playlistIsRemoving) {
      progressLabel = 'Deleting';
    } else if (playlistIsSyncPlanned) {
      if (isConnected) {
        progressLabel = 'Syncing planned';
      } else {
        progressLabel = 'Syncing planned when connected';
      }
    } else if (playlistIsSyncing) {
      progressLabel = `Syncing (${formatDownloadProgress(playlistState.progress)})`;
    }

    contentList.push({
      id: 'playlist',
      icon: PlaylistIcon,
      title: playlist.title,
      subtitle: formatBytes(
        playlistIsRemoving
          ? display?.playlist?.current?.size ?? 0
          : display?.playlist?.sync?.size ?? display?.playlist?.current?.size ?? 0,
      ),
      progressLabel,
      disabled: !hasDisplayContentDeletePermission || playlistIsRemoving,
      handleDelete: playlistIsRemoving
        ? undefined
        : playlistIsSyncing
        ? async () => abortPlaylistSync(display)
        : async () =>
            savePlaylist({
              displayIds: [display.id],
              playlist: undefined,
            }),
    });
  }

  const { getSortedDisplayAppSubscriptions, retryAppInstallation, retryAppUninstallation } =
    useAppSubscriptions();

  const { action: cancelUninstallAppAction } = useCancelUninstallApp({
    onCompleted: refetchData,
  });

  const { action: uninstallAppAction } = useUninstallApp({
    onCompleted: refetchData,
  });

  const [appSubscriptionToUpdate, setAppSubscriptionToUpdate] =
    useState<InstalledAppSubscription | null>();
  const handleStartAppUpdate = (appSubscription: InstalledAppSubscription) => {
    setAppSubscriptionToUpdate(appSubscription);
    updateAppModal.onOpen();
  };

  const {
    installedAppSubscriptions,
    installFailedAppSubscriptions,
    uninstallFailedAppSubscriptions,
    updateAvailableAppSubscriptions,
  } = getSortedDisplayAppSubscriptions(display);

  for (const appSubscription of installedAppSubscriptions) {
    const appIsInstalled =
      appSubscription.appInstallation.__typename === 'AppInstallationInstalled';

    contentList.push({
      id: appSubscription.id,
      icon: GridIcon,
      title: appSubscription.name,
      subtitle: getSubscriptionVersion(appSubscription) ?? undefined,
      progressLabel: appSubscription.progressLabel,
      disabled: !hasAppUninstallPermission || !appIsInstalled,
      handleDelete: async () =>
        await uninstallAppAction.askConfirmation({
          displayId: display.id,
          appSubscription: appSubscription,
        }),
    });
  }

  // Feature-toggled description for the empty state
  const availableContent = [];

  if (shouldRenderBookmarks) {
    availableContent.push('webpages');
  }

  availableContent.push('apps');

  if (shouldRenderPlaylists) {
    availableContent.push('a playlist');
  }

  const joinedAvailableContent = [
    ...availableContent.slice(0, -2),
    availableContent.slice(-2).join(' and '),
  ].join(', ');

  const availableContentDescription = `You haven't added any content to this display yet. You can add ${joinedAvailableContent} on this display.`;

  return (
    <SummaryCard title="Content">
      <VStack alignItems="stretch" spacing={3}>
        <PlaylistWarningAlert display={display} />
        {installFailedAppSubscriptions.map((subscription) => (
          <ErrorAlert
            key={subscription.id}
            actionButton={
              <Button
                variant="outline"
                size="sm"
                colorScheme="red"
                color="red.700"
                isDisabled={!hasAppInstallPermission}
                onClick={() => retryAppInstallation(display, subscription.id)}
              >
                Try again
              </Button>
            }
            actionIcon={
              <Button
                size="sm"
                variant="inline"
                colorScheme="gray"
                isDisabled={!hasAppUninstallPermission}
                onClick={async () =>
                  await cancelUninstallAppAction.askConfirmation({
                    displayId: display.id,
                    appSubscription: subscription,
                  })
                }
              >
                <TrashIcon color="red.700" width="actionIconSize" height="actionIconSize" />
              </Button>
            }
          >
            <AlertTitle>Installation of &ldquo;{subscription.name}&rdquo; failed</AlertTitle>
          </ErrorAlert>
        ))}
        {uninstallFailedAppSubscriptions.map((subscription) => (
          <ErrorAlert
            key={subscription.id}
            actionButton={
              <Button
                variant="outline"
                size="sm"
                colorScheme="red"
                color="red.700"
                isDisabled={!hasAppUninstallPermission}
                onClick={() => retryAppUninstallation(display, subscription.id)}
              >
                Try again
              </Button>
            }
          >
            <AlertTitle>Uninstall of &ldquo;{subscription.name}&rdquo; failed</AlertTitle>
          </ErrorAlert>
        ))}
        {updateAvailableAppSubscriptions.map((subscription) => (
          <InfoAlert
            key={subscription.id}
            actionButton={
              <Button
                variant="outline"
                size="sm"
                colorScheme="blue"
                color="blue.700"
                onClick={() => handleStartAppUpdate(subscription)}
              >
                Update
              </Button>
            }
          >
            <AlertTitle>Update available for &ldquo;{subscription.name}&rdquo;</AlertTitle>
          </InfoAlert>
        ))}
        {!contentList.length && (
          <VStack textAlign="center" mb="4">
            <HStack spacing="4" mb="4" marginTop={3}>
              {shouldRenderBookmarks && <WebPageIcon width="8" height="8" />}
              <GridIcon width="8" height="8" />
              {shouldRenderPlaylists && <PlaylistIcon width="8" height="8" />}
            </HStack>
            <Text>{availableContentDescription}</Text>
          </VStack>
        )}
        {contentList.map(({ id, icon, title, subtitle, handleDelete, progressLabel, disabled }) => (
          <ContentItem
            key={id}
            icon={icon}
            title={title}
            subtitle={subtitle}
            handleDelete={handleDelete}
            progressLabel={progressLabel}
            disabled={disabled}
          />
        ))}
        <Box textAlign={contentList.length ? 'left' : 'center'}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            onClick={addContentModal.onOpen}
            isDisabled={!hasDisplaySettingsUpdatePermission}
          >
            Add content
          </Button>
        </Box>
      </VStack>
      {addContentModal.isOpen && (
        <AddContentModal customer={customer} display={display} onClose={addContentModal.onClose} />
      )}
      {updateAppModal.isOpen && !isNil(appSubscriptionToUpdate) && (
        <UpdateAppModal
          display={display}
          appSubscription={appSubscriptionToUpdate}
          onClose={updateAppModal.onClose}
        />
      )}
      {abortConfirmationNode}
      {cancelUninstallAppAction.confirmationNode}
      {uninstallAppAction.confirmationNode}
    </SummaryCard>
  );
}

ContentCard.graphql = {
  fragments: {
    ContentCard_customer: gql`
      fragment ContentCard_customer on Customer {
        id
        ...AddContentModal_customer
      }
    `,
    ContentCard_display: gql`
      fragment ContentCard_display on Display {
        id
        bookmarks {
          all {
            reported
            desired
          }
        }
        playlist {
          current {
            title
            size
          }
          sync {
            title
            size
          }
        }
        ...PlaylistWarningAlert_display
        ...AddContentModal_display
      }
    `,
  },
};
