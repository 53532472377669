/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UpdateFirmwareModal_DisplayFragmentDoc } from '../../../__generated__/UpdateFirmwareModal.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayFirmwareUpdate_DisplayFragment = { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', availableUpdates: Array<string>, version: any, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateRejected', rejectionCode: string, rejectedAt: any, id: string, targetVersion: any, plannedAt: any, createdAt: any } | null } } | null };

export type AbortFirmwareUpdateJobMutationVariables = Types.Exact<{
  input: Types.DisplayAbortJobInput;
}>;


export type AbortFirmwareUpdateJobMutation = { __typename: 'Mutation', displayAbortJob: { __typename: 'DisplayAbortJobPayload', display: { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', availableUpdates: Array<string>, version: any, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, plannedAt: any, createdAt: any } | { __typename: 'AndroidUpdateRejected', rejectionCode: string, rejectedAt: any, id: string, targetVersion: any, plannedAt: any, createdAt: any } | null } } | null } } };

export const DisplayFirmwareUpdate_DisplayFragmentDoc = gql`
    fragment DisplayFirmwareUpdate_display on Display {
  id
  firmware {
    android {
      availableUpdates
      latestJob {
        id
        targetVersion
        plannedAt
        createdAt
        ... on AndroidUpdateDownloading {
          downloadProgress
        }
        ... on AndroidUpdateRejected {
          rejectionCode
          rejectedAt
        }
      }
    }
  }
  ...UpdateFirmwareModal_display
}
    ${UpdateFirmwareModal_DisplayFragmentDoc}`;
export const AbortFirmwareUpdateJobDocument = gql`
    mutation AbortFirmwareUpdateJob($input: DisplayAbortJobInput!) {
  displayAbortJob(input: $input) {
    display {
      id
      ...DisplayFirmwareUpdate_display
    }
  }
}
    ${DisplayFirmwareUpdate_DisplayFragmentDoc}`;
export type AbortFirmwareUpdateJobMutationFn = ApolloReactCommon.MutationFunction<AbortFirmwareUpdateJobMutation, AbortFirmwareUpdateJobMutationVariables>;

/**
 * __useAbortFirmwareUpdateJobMutation__
 *
 * To run a mutation, you first call `useAbortFirmwareUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortFirmwareUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortFirmwareUpdateJobMutation, { data, loading, error }] = useAbortFirmwareUpdateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAbortFirmwareUpdateJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AbortFirmwareUpdateJobMutation, AbortFirmwareUpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AbortFirmwareUpdateJobMutation, AbortFirmwareUpdateJobMutationVariables>(AbortFirmwareUpdateJobDocument, options);
      }
export type AbortFirmwareUpdateJobMutationHookResult = ReturnType<typeof useAbortFirmwareUpdateJobMutation>;
export type AbortFirmwareUpdateJobMutationResult = ApolloReactCommon.MutationResult<AbortFirmwareUpdateJobMutation>;
export type AbortFirmwareUpdateJobMutationOptions = ApolloReactCommon.BaseMutationOptions<AbortFirmwareUpdateJobMutation, AbortFirmwareUpdateJobMutationVariables>;