/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type TitleCell_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, title: string };

export const TitleCell_PowerScheduleFragmentDoc = gql`
    fragment TitleCell_powerSchedule on PowerSchedule {
  id
  title
}
    `;