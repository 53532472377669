/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CtnCell_DisplayFragment = { __typename: 'Display', id: string, commercialTypeNumber: string };

export const CtnCell_DisplayFragmentDoc = gql`
    fragment CTNCell_display on Display {
  id
  commercialTypeNumber
}
    `;