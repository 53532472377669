/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UsePlaylists_CurrentPlaylistFragment = { __typename: 'DisplayCurrentPlaylist', id: string, title: string };

export type UsePlaylists_SyncPlaylist_PlaylistSyncFailed_Fragment = { __typename: 'PlaylistSyncFailed' };

export type UsePlaylists_SyncPlaylist_PlaylistSyncing_Fragment = { __typename: 'PlaylistSyncing', id: string, title: string, progress: number };

export type UsePlaylists_SyncPlaylistFragment = UsePlaylists_SyncPlaylist_PlaylistSyncFailed_Fragment | UsePlaylists_SyncPlaylist_PlaylistSyncing_Fragment;

export type UsePlaylists_SyncFailedPlaylist_PlaylistSyncFailed_Fragment = { __typename: 'PlaylistSyncFailed', id: string, title: string };

export type UsePlaylists_SyncFailedPlaylist_PlaylistSyncing_Fragment = { __typename: 'PlaylistSyncing' };

export type UsePlaylists_SyncFailedPlaylistFragment = UsePlaylists_SyncFailedPlaylist_PlaylistSyncFailed_Fragment | UsePlaylists_SyncFailedPlaylist_PlaylistSyncing_Fragment;

export type UsePlaylists_DisplayFragment = { __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, id: string, title: string, description?: string | null, jobId: string } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, id: string, title: string, description?: string | null, jobId: string } | null } | null };

export type SyncPlaylistMutationVariables = Types.Exact<{
  input: Types.DisplayUpdatePlaylistInput;
}>;


export type SyncPlaylistMutation = { __typename: 'Mutation', displayUpdatePlaylist: { __typename: 'DisplayUpdatePlaylistPayload', display: { __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, id: string, title: string, description?: string | null, jobId: string } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, id: string, title: string, description?: string | null, jobId: string } | null } | null } } };

export const UsePlaylists_CurrentPlaylistFragmentDoc = gql`
    fragment UsePlaylists_currentPlaylist on DisplayCurrentPlaylist {
  id
  title
}
    `;
export const UsePlaylists_SyncPlaylistFragmentDoc = gql`
    fragment UsePlaylists_syncPlaylist on DisplayPlaylistSync {
  ... on PlaylistSyncing {
    id
    title
    progress
  }
}
    `;
export const UsePlaylists_SyncFailedPlaylistFragmentDoc = gql`
    fragment UsePlaylists_syncFailedPlaylist on DisplayPlaylistSync {
  ... on PlaylistSyncFailed {
    id
    title
  }
}
    `;
export const UsePlaylists_DisplayFragmentDoc = gql`
    fragment UsePlaylists_display on Display {
  id
  playlist {
    current {
      id
      title
      isOutOfSync
      isTampered
      size
    }
    sync {
      id
      title
      description
      jobId
      ... on PlaylistSyncing {
        progress
        isRemoving
        isPlanned
        size
      }
      ... on PlaylistSyncFailed {
        errorCode
      }
    }
  }
}
    `;
export const SyncPlaylistDocument = gql`
    mutation SyncPlaylist($input: DisplayUpdatePlaylistInput!) {
  displayUpdatePlaylist(input: $input) {
    display {
      id
      ...UsePlaylists_display
    }
  }
}
    ${UsePlaylists_DisplayFragmentDoc}`;
export type SyncPlaylistMutationFn = ApolloReactCommon.MutationFunction<SyncPlaylistMutation, SyncPlaylistMutationVariables>;

/**
 * __useSyncPlaylistMutation__
 *
 * To run a mutation, you first call `useSyncPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPlaylistMutation, { data, loading, error }] = useSyncPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncPlaylistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncPlaylistMutation, SyncPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SyncPlaylistMutation, SyncPlaylistMutationVariables>(SyncPlaylistDocument, options);
      }
export type SyncPlaylistMutationHookResult = ReturnType<typeof useSyncPlaylistMutation>;
export type SyncPlaylistMutationResult = ApolloReactCommon.MutationResult<SyncPlaylistMutation>;
export type SyncPlaylistMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncPlaylistMutation, SyncPlaylistMutationVariables>;