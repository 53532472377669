/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type FileTypeCell_MediaFragment = { __typename: 'Media', id: string, type: string };

export const FileTypeCell_MediaFragmentDoc = gql`
    fragment FileTypeCell_media on Media {
  id
  type
}
    `;