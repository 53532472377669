import { useContext } from 'react';
import { AuthorizationContext } from './AuthorizationProvider';

export function useAuthorization() {
  const ctx = useContext(AuthorizationContext);

  if (!ctx) {
    throw new Error('useAuthorization must be used inside an AuthorizationProvider');
  }

  return ctx;
}
