import { ApolloError, gql } from '@apollo/client';
import {
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, isNil } from 'lodash';
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SearchIcon } from '~components/ui/icons';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { EditSiteModalFragment, useEditSiteMutation } from './__generated__/EditSiteModal.graphql';
import { useGoogleMaps } from './useGoogleMaps';

const FormData = z.object({
  id: z.string(),
  name: z
    .string()
    .min(1, 'The name must contain at least one character')
    .regex(/.*\S.*/, 'The name cannot contain only white spaces'),
});
type FormData = z.infer<typeof FormData>;

interface Props {
  site: EditSiteModalFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}

export function EditSiteModal({ site, isOpen, onCancel, onSuccess }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <EditSiteModalContent
          site={site}
          initialFocusRef={initialFocusRef}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

type ContentProps = {
  site: EditSiteModalFragment;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
};

function EditSiteModalContent({ site, initialFocusRef, onCancel, onSuccess }: ContentProps) {
  const toast = useToast();
  const [editSite] = useEditSiteMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: site,
    resolver: zodResolver(FormData),
  });

  const { ref: nameInputRef, ...nameInputProps } = register('name');
  const [addressSearchValue, setAddressSearchValue] = useState<string | null>(null);

  const inputRefMaps = useRef<HTMLInputElement>(null);

  const { staticMapUrl, setPlace, place, initMap, isMapInitialized } = useGoogleMaps({
    inputRef: inputRefMaps,
  });

  const isAddressInvalid = useMemo(() => {
    return !isEmpty(addressSearchValue) && !isNil(addressSearchValue) && isNil(place);
  }, [addressSearchValue, place]);

  const addressErrorMessage = useMemo(() => {
    if (isAddressInvalid) {
      return 'Please select one of the suggested addresses.';
    }
    return undefined;
  }, [isAddressInvalid]);

  useEffect(() => {
    if (isMapInitialized) {
      return;
    }
    initMap(site.address);
    !isNil(inputRefMaps.current)
      ? (inputRefMaps.current.value = site.address ?? place?.formatted_address ?? '')
      : undefined;
  }, [initMap, isMapInitialized, place, setPlace, site.address]);

  const resetPlace = useCallback(() => {
    if (isEmpty(inputRefMaps.current?.value) || isNil(inputRefMaps.current?.value)) {
      setPlace(null);
    }
  }, [setPlace]);

  const handleAddressChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAddressSearchValue(e.target.value);

      resetPlace();
    },
    [resetPlace],
  );

  const removeAddress = useCallback(() => {
    if (inputRefMaps.current) {
      inputRefMaps.current.value = '';
    }

    setAddressSearchValue(null);
    setPlace(null);
  }, [setPlace]);

  const onSubmit = useCallback(
    async ({ id: siteId, name }: FormData) => {
      const addressToStore = !isEmpty(inputRefMaps.current?.value)
        ? inputRefMaps.current?.value
        : undefined;

      try {
        await editSite({
          variables: {
            input: {
              siteId,
              name,
              address: addressToStore,
            },
          },
        });

        await onSuccess?.();
      } catch (err) {
        const error: ApolloError = err as ApolloError;

        const title =
          error.message === 'SITE_NAME_DUPLICATE' ? 'Site name already exists' : 'Cannot edit site';

        toast({
          status: 'error',
          title,
          description: fromError(err, 'EditSite', {
            SITE_NAME_DUPLICATE:
              'A site with this name already exists. Please choose a different name.',
          }),
        });
      }
    },
    [editSite, onSuccess, toast],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Edit site</ModalHeader>
      <ModalCloseButton onClick={onCancel} />

      <ModalBody paddingBottom="5">
        <FormControl isRequired isInvalid={Boolean(errors.name)}>
          <FormLabel marginTop="4">Name</FormLabel>
          <Input
            placeholder="New site name"
            ref={(r) => {
              nameInputRef(r);
              initialFocusRef.current = r;
            }}
            {...nameInputProps}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={isAddressInvalid}>
          <FormLabel marginTop="8">Address</FormLabel>

          <InputGroup>
            <InputLeftElement>
              <SearchIcon color="gray.500" />
            </InputLeftElement>
            <Input
              type={'text'}
              placeholder="Search an address"
              ref={inputRefMaps}
              onChange={handleAddressChange}
              isDisabled={!isNil(place)}
            />
            <InputRightElement onClick={removeAddress}>
              <CloseButton />
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>{addressErrorMessage}</FormErrorMessage>

          {!isNil(staticMapUrl) && isMapInitialized ? (
            <Image src={staticMapUrl} marginTop="4" borderRadius={'md'} />
          ) : undefined}
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" colorScheme="blue" onClick={onCancel} isDisabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="solid"
          colorScheme="blue"
          marginLeft="3"
          type="submit"
          isDisabled={isSubmitting || isAddressInvalid}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
}

EditSiteModal.graphql = {
  fragments: {
    EditSiteModal: gql`
      fragment EditSiteModal on Site {
        id
        name
        address
      }
    `,
  },
  mutations: {
    EditSite: gql`
      mutation EditSite($input: SiteUpdateInput!) {
        siteUpdate(input: $input) {
          id
          name
          address
        }
      }
    `,
  },
};
