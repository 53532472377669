import {
  ThemingProps,
  useStyleConfig as useChakraStyleConfig,
  useTheme as useChakraTheme,
} from '@chakra-ui/react';
import { Theme } from './theme';
import { ThemeTypings } from './types';

/**
 * A better typed version of @chakra-ui/react/useTheme
 */
export function useTheme() {
  return useChakraTheme<Theme>();
}

/**
 * A better typed version of @chakra-ui/react/useStyleConfig
 */
export function useStyleConfig<TComponent extends keyof ThemeTypings['components'] & string>(
  themeKey: TComponent,
  props?: ThemingProps<TComponent>,
) {
  return useChakraStyleConfig(themeKey, props);
}
