/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRecommendedSettings_DisplayFragmentDoc } from '../../../__generated__/useRecommendedSettings.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplaySignalDetectionToggle_DisplayFragment = { __typename: 'Display', id: string, signalDetection?: { __typename: 'SignalDetectionState', desired?: boolean | null, reported: boolean } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null };

export type BulkUpdateSignalDetectionMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateSignalDetectionInput;
}>;


export type BulkUpdateSignalDetectionMutation = { __typename: 'Mutation', displayBulkUpdateSignalDetection: { __typename: 'DisplayBulkUpdateSignalDetectionPayload', displays: Array<{ __typename: 'Display', id: string, signalDetection?: { __typename: 'SignalDetectionState', desired?: boolean | null, reported: boolean } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null }> } };

export const DisplaySignalDetectionToggle_DisplayFragmentDoc = gql`
    fragment DisplaySignalDetectionToggle_display on Display {
  id
  signalDetection {
    desired
    reported
  }
  ...UseRecommendedSettings_display
}
    ${UseRecommendedSettings_DisplayFragmentDoc}`;
export const BulkUpdateSignalDetectionDocument = gql`
    mutation BulkUpdateSignalDetection($input: DisplayBulkUpdateSignalDetectionInput!) {
  displayBulkUpdateSignalDetection(input: $input) {
    displays {
      id
      ...DisplaySignalDetectionToggle_display
      ...UseRecommendedSettings_display
    }
  }
}
    ${DisplaySignalDetectionToggle_DisplayFragmentDoc}
${UseRecommendedSettings_DisplayFragmentDoc}`;
export type BulkUpdateSignalDetectionMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateSignalDetectionMutation, BulkUpdateSignalDetectionMutationVariables>;

/**
 * __useBulkUpdateSignalDetectionMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSignalDetectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSignalDetectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSignalDetectionMutation, { data, loading, error }] = useBulkUpdateSignalDetectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSignalDetectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateSignalDetectionMutation, BulkUpdateSignalDetectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdateSignalDetectionMutation, BulkUpdateSignalDetectionMutationVariables>(BulkUpdateSignalDetectionDocument, options);
      }
export type BulkUpdateSignalDetectionMutationHookResult = ReturnType<typeof useBulkUpdateSignalDetectionMutation>;
export type BulkUpdateSignalDetectionMutationResult = ApolloReactCommon.MutationResult<BulkUpdateSignalDetectionMutation>;
export type BulkUpdateSignalDetectionMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateSignalDetectionMutation, BulkUpdateSignalDetectionMutationVariables>;