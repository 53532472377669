/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PlaylistTable_PlaylistFragmentDoc } from '../PlaylistTable/__generated__/PlaylistTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePlaylistMutationVariables = Types.Exact<{
  input: Types.PlaylistCreateInput;
}>;


export type CreatePlaylistMutation = { __typename: 'Mutation', playlistCreate: { __typename: 'PlaylistCreatePayload', playlist: { __typename: 'Playlist', id: string, title: string, description?: string | null, size: any, createdAt: any, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> } } };


export const CreatePlaylistDocument = gql`
    mutation CreatePlaylist($input: PlaylistCreateInput!) {
  playlistCreate(input: $input) {
    playlist {
      id
      ...PlaylistTable_playlist
    }
  }
}
    ${PlaylistTable_PlaylistFragmentDoc}`;
export type CreatePlaylistMutationFn = ApolloReactCommon.MutationFunction<CreatePlaylistMutation, CreatePlaylistMutationVariables>;

/**
 * __useCreatePlaylistMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistMutation, { data, loading, error }] = useCreatePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaylistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlaylistMutation, CreatePlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePlaylistMutation, CreatePlaylistMutationVariables>(CreatePlaylistDocument, options);
      }
export type CreatePlaylistMutationHookResult = ReturnType<typeof useCreatePlaylistMutation>;
export type CreatePlaylistMutationResult = ApolloReactCommon.MutationResult<CreatePlaylistMutation>;
export type CreatePlaylistMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlaylistMutation, CreatePlaylistMutationVariables>;