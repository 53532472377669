import { gql } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { getId } from '~utils/data';
import { fromError } from '~utils/errors';
import {
  DeletePowerScheduleModal_PowerScheduleFragment,
  useDeletePowerSchedulesMutation,
} from './__generated__/DeletePowerSchedulesModal.graphql';

interface Props {
  powerSchedules: DeletePowerScheduleModal_PowerScheduleFragment[];
  isOpen: boolean;
  onCancel: () => void;
  onSuccess?: () => Promise<void> | void;
}

export function DeletePowerSchedulesModal({ powerSchedules, isOpen, onCancel, onSuccess }: Props) {
  const isPowerScheduleUsedByDisplays = powerSchedules.some(
    (powerSchedule) => powerSchedule.allDisplays.length > 0,
  );
  const toast = useToast();
  const [deletePowerSchedule] = useDeletePowerSchedulesMutation();

  const handleClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleDelete = useCallback(async () => {
    try {
      await deletePowerSchedule({
        variables: {
          input: {
            powerScheduleIds: powerSchedules.map(getId),
          },
        },
      });

      toast({
        status: 'success',
        title: powerSchedules.length === 1 ? 'Power schedule deleted' : `Power schedules deleted`,
        description:
          powerSchedules.length === 1
            ? 'The power schedule has been successfully deleted.'
            : 'The power schedules have been successfully deleted.',
      });

      await onSuccess?.();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot delete the power schedules',
        description: fromError(err, 'DeletePowerSchedules', {
          POWER_SCHEDULE_NOT_FOUND:
            'Looks like the power schedule you are trying to delete cannot be found.',
          POWER_SCHEDULE_IN_USE_ERROR:
            'It looks like the power schedule you are trying to delete is being used by a display.',
        }),
      });
    }
  }, [deletePowerSchedule, onSuccess, powerSchedules, toast]);

  const isPowerScheduleUsedByManyDisplays = powerSchedules.length > 1;

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />

      {isPowerScheduleUsedByDisplays ? (
        <ModalContent>
          <ModalHeader>
            {isPowerScheduleUsedByManyDisplays
              ? 'Cannot delete power schedules'
              : 'Cannot delete power schedule'}
          </ModalHeader>
          <ModalCloseButton tabIndex={5} />
          <ModalBody>
            <Text>
              {`We cannot delete ${
                isPowerScheduleUsedByManyDisplays
                  ? 'these power schedules, because they are still used'
                  : 'this power schedule, because it is still used'
              } by some displays. Please make sure to remove ${
                isPowerScheduleUsedByManyDisplays ? 'these power schedules' : 'this power schedule'
              } from all the displays before deleting ${
                isPowerScheduleUsedByManyDisplays ? 'them' : 'it'
              }.`}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="solid" colorScheme="blue">
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader>
            {powerSchedules.length > 1 ? 'Delete power schedules' : 'Delete power schedule'}
          </ModalHeader>
          <ModalCloseButton tabIndex={5} />
          <ModalBody>
            <Text>
              {`Are you sure you want to delete ${
                powerSchedules.length > 1 ? 'these power schedules?' : 'this power schedule?'
              }`}
            </Text>
            <Text color={'gray.600'} marginTop="6">
              This action cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="ghost" colorScheme="blue">
              Cancel
            </Button>
            <Button variant="solid" colorScheme="red" marginLeft="3" onClick={handleDelete}>
              {powerSchedules.length > 1 ? 'Delete power schedules' : 'Delete power schedule'}
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}

DeletePowerSchedulesModal.graphql = {
  fragments: {
    DeletePowerSchedulesModal_powerSchedule: gql`
      fragment DeletePowerScheduleModal_powerSchedule on PowerSchedule {
        id
        allDisplays: displays {
          id
        }
      }
    `,
  },
  mutations: {
    DeletePowerSchedules: gql`
      mutation DeletePowerSchedules($input: PowerSchedulesDeleteInput!) {
        powerSchedulesDelete(input: $input) {
          powerScheduleIds
        }
      }
    `,
  },
};
