import { Box, chakra } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '~components/ui/Header';
import { Page } from '~components/ui/Page';
import { CONTAINER_MAX_WIDTH, PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import Logo128 from '../ui/assets/logo-128.png';
import { Footer } from './Footer';

type Props = {
  title: string; // For metadata
  heading: string;
  children: ReactNode;
};

/**
 * Generating HTML content
 *
 * 1. Install "Copy Markdown as HTML" plugin and configure:
 *
 * ```
 * "copyMarkdownAsHtml.xhtmlOut": true,
 * "copyMarkdownAsHtml.breaks": true,
 * "copyMarkdownAsHtml.linkify": true
 * ```
 *
 * 2. Copy contents to new Markdown file (See https://git.inthepocket.org/tp-vision/product/-/issues/270)
 * 3. Add headers (##), remove header dot and add newlines where needed
 * 4. Execute Markdown: Copy as HTML
 * 5. Paste below and fix errors as instructed, i.e. replace to `&apos;`.
 */
export function PolicyPage({ title, heading, children }: Props) {
  return (
    <Header.Provider>
      <Box position="relative" zIndex="sticky" display="flex" justifyContent="center" marginTop="6">
        <Box width={CONTAINER_MAX_WIDTH} display="flex">
          <Box display="flex">
            <Link to="/">
              <chakra.img src={Logo128} width="128px" height="128px" />
            </Link>
          </Box>
        </Box>
      </Box>

      <Page title={title} pageName="privacy_policy" analyticsDisableTracking={true}>
        <PageContent maxWidth="4xl">
          <PageHeading>{heading}</PageHeading>

          <Box
            boxShadow="elevated"
            backgroundColor="white"
            border="1px solid"
            borderRadius="md"
            borderColor="gray.100"
            paddingY="8"
            paddingX="16"
            mb="145px"
            sx={{
              '& article:not(:last-child)': {
                borderBottom: '1px solid var(--chakra-colors-gray-100)',
                paddingBottom: '24px',
                marginTop: '24px',
              },
              '& h2': { fontWeight: 'semibold', marginBottom: '8px', marginTop: '24px' },
              '& h3': { fontWeight: 'semibold', marginBottom: '8px', marginTop: '18px' },
              '& h4': { fontWeight: 'semibold', marginBottom: '8px', marginTop: '12px' },
              '& p': { marginBottom: '8px' },
              '& a': { color: '#4083E1' },
              '& ul': { listStylePosition: 'inside', marginBottom: '8px' },
              '& ol': { listStylePosition: 'inside', marginBottom: '8px' },
              '& th, & td': { textAlign: 'left', verticalAlign: 'top' },
            }}
          >
            {children}
          </Box>
        </PageContent>

        <Footer />
      </Page>
    </Header.Provider>
  );
}
