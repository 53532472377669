export const Modal = {
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: {
    header: {
      fontFamily: 'body',
      fontSize: 'xl',
      fontWeight: 'semibold',
      color: 'gray.900',
      marginTop: '4',
      marginX: '9',
      paddingX: '0',
      paddingY: '4',
      borderBottom: '1px solid',
      borderColor: 'gray.100',
    },
    body: {
      fontWeight: '400',
      paddingX: '9',
      paddingTop: '4',
      paddingBottom: '12',
    },
    closeButton: {
      top: '9',
      right: '10',
      color: 'black',
      padding: '1',
      margin: '-1',
      // Also check ModalCloseButton.tsx
    },
    footer: {
      paddingX: '10',
      paddingTop: '2.5',
      paddingBottom: '5',
      borderBottomRadius: 'md',
      background: 'white',
    },
  },
  defaultProps: {
    size: 'xl',
  },
};
