import { Box, chakra, Spinner, Tag, TagCloseButton, TagLabel, Text } from '@chakra-ui/react';
import _ from 'lodash';
import {
  ControlProps,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  InputProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
import { z } from 'zod';
import { ChevronDownIcon } from './icons';

export const selectOptionBaseSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const selectOptionSchema = selectOptionBaseSchema.optional().nullable();

export type SelectOption = Exclude<z.TypeOf<typeof selectOptionSchema>, null | undefined>;

function Control(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: ControlProps<any, any>,
) {
  const {
    children,
    cx,
    getStyles,
    className,
    isDisabled,
    isFocused,
    innerRef,
    innerProps,
    menuIsOpen,
  } = props;

  const defaultStyles = getStyles('control', props);
  const styles = {
    ...defaultStyles,
    fontSize: 'md',
    color: 'blue.800',
    minHeight: '2.5rem',
    borderWidth: isFocused ? '2px' : '1px',
    backgroundColor: isDisabled ? 'gray.75' : 'white',
    borderRadius: 'base',
    borderColor: isFocused ? 'blue.500' : 'gray.100',
    boxShadow: 'none',
    '&:hover': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(defaultStyles as any)['&:hover'],
      borderColor: isFocused ? 'blue.500' : 'gray.200',
    },
  };

  return (
    <Box
      ref={innerRef}
      sx={styles}
      className={cx(
        {
          control: true,
          'control--is-disabled': isDisabled,
          'control--is-focused': isFocused,
          'control--menu-is-open': menuIsOpen,
        },
        className,
      )}
      {...innerProps}
    >
      {children}
    </Box>
  );
}

function Placeholder({
  children,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
PlaceholderProps<any, any>) {
  return (
    <Box position="absolute" whiteSpace="nowrap">
      <Text color="gray.400">{children}</Text>
    </Box>
  );
}

function ValueContainer({
  children,
  isMulti,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
ValueContainerProps<any, any>) {
  return (
    <Box
      position="relative"
      paddingY="4px"
      marginX="4"
      display="flex"
      flexDirection="row"
      flex="1"
      flexWrap={isMulti ? 'wrap' : 'nowrap'}
      alignItems="center"
    >
      {children}
    </Box>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MultiValueContainer(props: MultiValueGenericProps<any, any, any>) {
  return (
    <Tag
      colorScheme="gray"
      size="sm"
      variant="outline"
      marginRight="2"
      marginY="1"
      {...(props.data.isDisabled ? { backgroundColor: 'gray.75', opacity: 0.5 } : {})}
    >
      {props.children}
    </Tag>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MultiValueLabel(props: MultiValueGenericProps<any, any, any>) {
  return <TagLabel textTransform="uppercase">{props.children}</TagLabel>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MultiValueRemove(props: MultiValueRemoveProps<any, any, any>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, ...innerRest } = props.innerProps;

  return props.data.isDisabled ? null : (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <TagCloseButton {...(innerRest as any)} marginInlineStart="0" />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Option({ children, isSelected, innerProps }: OptionProps<any, any, any>) {
  return (
    <Box
      paddingX="3.5"
      paddingY="2"
      cursor="pointer"
      color={isSelected ? 'white' : 'blue.800'}
      background={isSelected ? 'blue.400' : 'white'}
      fontSize="md"
      _hover={{
        background: isSelected ? 'blue.400' : 'blue.50',
      }}
      {...innerProps}
    >
      {children}
    </Box>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SingleValue({ children, innerProps }: SingleValueProps<any, any>) {
  return (
    <Box fontSize="md" fontWeight="normal" {...innerProps}>
      {children}
    </Box>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function Input(props: InputProps<any, any, any>) {
  const { innerRef, isDisabled, isHidden, ...innerProps } = props;
  const inputProps = cleanCommonProps(innerProps);

  return (
    <Box data-value={(props as any).value || ''} flex="1">
      <chakra.input
        ref={innerRef as any}
        color="inherit"
        width="100%"
        minWidth="2px"
        border="0"
        padding="0"
        margin="0"
        outline="0"
        background="0"
        opacity={isHidden ? 0 : 1}
        disabled={isDisabled}
        {...inputProps}
      />
    </Box>
  );
}
/* eslint-enable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DropdownIndicator({ innerProps }: DropdownIndicatorProps<any, any, any>) {
  return (
    <Box
      marginRight="4"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="gray.700"
      {...innerProps}
    >
      <ChevronDownIcon width="4" height="4" />
    </Box>
  );
}

function LoadingIndicator() {
  return (
    <Box paddingX="2">
      <Spinner size="sm" color="blue.700" />
    </Box>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
function IndicatorSeparator(_props: IndicatorSeparatorProps<any, any>) {
  return <Box width="1px" alignSelf="stretch" marginLeft="2" />;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-unused-vars */

/**
 * Similar to how react-select cleans props internally.
 * The function is not exposed so re-creating it here.
 *
 * See: https://github.com/JedWatson/react-select/blob/cfd5d9f632b4a8b699fba1028d3243735428e428/packages/react-select/src/utils.ts#L78
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function cleanCommonProps<T extends object>(props: T) {
  return _.omit(props, [
    'className',
    'clearValue',
    'cx',
    'getClassNames',
    'getStyles',
    'getValue',
    'hasValue',
    'isMulti',
    'isRtl',
    'options',
    'selectOption',
    'selectProps',
    'setValue',
    'theme',
  ]);
}

export const components = {
  Control,
  Placeholder,
  ValueContainer,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  Option,
  DropdownIndicator,
  LoadingIndicator,
  IndicatorSeparator,
  SingleValue,
  Input,
};
