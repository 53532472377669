import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import {
  UsePlaylists_CurrentPlaylistFragment,
  UsePlaylists_DisplayFragment,
  UsePlaylists_SyncFailedPlaylistFragment,
  UsePlaylists_SyncPlaylistFragment,
  useSyncPlaylistMutation,
} from './__generated__/usePlaylists.graphql';
import { useAbortPlaylistJobMutation } from './DisplayDetail/summary/__generated__/PlaylistWarningAlert.graphql';

export type PlaylistUpdateFailedReason = 'insufficient_disk_space' | 'unexpected_error';

/** Represents the state of a displays playlist in a more convenient way for the UI.  */
export type PlaylistState =
  | {
      kind: 'empty';
    }
  | { kind: 'playlist_ready'; playlist: UsePlaylists_CurrentPlaylistFragment }
  | { kind: 'playlist_sync_planned'; playlist?: UsePlaylists_SyncPlaylistFragment; jobId: string }
  | {
      kind: 'playlist_syncing';
      playlist?: UsePlaylists_SyncPlaylistFragment;
      progress: number;
      jobId: string;
    }
  | {
      kind: 'playlist_sync_failed';
      playlist?: UsePlaylists_SyncFailedPlaylistFragment;
      reason: PlaylistUpdateFailedReason;
    }
  | {
      kind: 'playlist_removing';
      playlist?: UsePlaylists_SyncPlaylistFragment;
      progress: number;
    }
  | { kind: 'playlist_out_of_sync'; playlist: UsePlaylists_CurrentPlaylistFragment }
  | { kind: 'playlist_tampered'; playlist: UsePlaylists_CurrentPlaylistFragment };

/**
 * Hook to help transform display playlist to a more UI convenient state.
 * If you wish to use this hook, ensure the 'UsePlaylists_display' fragment is included in the query.
 */
export function usePlaylists() {
  const getPlaylistState = useCallback((display: UsePlaylists_DisplayFragment): PlaylistState => {
    if (display.playlist?.sync?.__typename === 'PlaylistSyncing') {
      // Note: A playlist sync can be removing AND planned, so checking if it's being removed comes first
      if (display.playlist.sync.isRemoving) {
        return {
          kind: 'playlist_removing',
          playlist: display.playlist.sync ?? undefined,
          progress: display.playlist.sync.progress,
        };
      }
      if (display.playlist.sync.isPlanned) {
        return {
          kind: 'playlist_sync_planned',
          playlist: display.playlist.sync ?? undefined,
          jobId: display.playlist.sync.jobId,
        };
      }
      return {
        kind: 'playlist_syncing',
        playlist: display.playlist.sync ?? undefined,
        progress: display.playlist.sync.progress,
        jobId: display.playlist.sync.jobId,
      };
    }

    if (display.playlist?.sync?.__typename === 'PlaylistSyncFailed') {
      return {
        kind: 'playlist_sync_failed',
        playlist: display.playlist.sync ?? undefined,
        reason: getPlaylistSyncFailedReason(display.playlist.sync.errorCode),
      };
    }

    if (display.playlist?.current?.isOutOfSync) {
      return {
        kind: 'playlist_out_of_sync',
        playlist: display.playlist.current,
      };
    }

    if (display.playlist?.current?.isTampered) {
      return {
        kind: 'playlist_tampered',
        playlist: display.playlist.current,
      };
    }

    if (display.playlist?.current && isNil(display.playlist.sync)) {
      return { kind: 'playlist_ready', playlist: display.playlist.current };
    }

    return { kind: 'empty' };
  }, []);

  const toast = useToast();
  const [syncPlaylistMutation] = useSyncPlaylistMutation();
  const syncPlaylist = useCallback(
    async (display: UsePlaylists_DisplayFragment) => {
      // To re-sync the playlist we'll prefer to take the playlist from the latest job.
      // if there happens to be no job (unlikely but who knows) we'll take the current source id.
      const playlistId = display.playlist?.sync?.id ?? display.playlist?.current?.id;
      if (!playlistId) {
        return;
      }

      try {
        await syncPlaylistMutation({
          variables: {
            input: {
              displayId: display.id,
              playlistId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot sync playlist',
          description: fromError(err, 'SyncPlaylist', {
            INSUFFICIENT_STORAGE:
              'Display has insufficient storage. To sync the playlist, first free up storage on the display.',
          }),
        });
      }
    },
    [syncPlaylistMutation, toast],
  );

  const [abortPlaylistJob] = useAbortPlaylistJobMutation();
  const abortPlaylistJobAction = useDestructiveAction<{ jobId: string }>({
    title: 'Cancel playlist sync',
    message: 'Are you sure you want to cancel this playlist sync?',
    confirmLabel: 'Yes',
    onConfirm: async ({ jobId }) => {
      try {
        await abortPlaylistJob({
          variables: {
            input: {
              jobId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot abort playlist sync',
          description: fromError(err, 'AbortPlaylistJob', {
            JOB_ALREADY_COMPLETED: 'The playlist sync is already completed.',
            JOB_ALREADY_REJECTED: 'The playlist sync failed.',
            JOB_ALREADY_ABORTED: 'The playlist sync is already aborted.',
            JOB_NOT_ABORTABLE: 'The playlist sync is not abortable.',
          }),
        });
      }
    },
  });

  const abortPlaylistSync = useCallback(
    async (display: UsePlaylists_DisplayFragment) => {
      if (display.playlist?.sync?.__typename === 'PlaylistSyncing') {
        await abortPlaylistJobAction.askConfirmation({ jobId: display.playlist.sync.jobId });
      } else {
        toast({
          status: 'error',
          title: 'Cannot abort playlist sync',
          description: 'There is no active playlist sync to cancel.',
        });
      }
    },
    [abortPlaylistJobAction, toast],
  );

  return {
    getPlaylistState,
    syncPlaylist,
    abortPlaylistSync,
    abortConfirmationNode: abortPlaylistJobAction.confirmationNode,
  };
}

function getPlaylistSyncFailedReason(rejectionCode: string): PlaylistUpdateFailedReason {
  switch (rejectionCode) {
    case 'DISPLAY_NOT_ENOUGH_DISK_SPACE':
      return 'insufficient_disk_space';
    default:
      return 'unexpected_error';
  }
}

usePlaylists.graphql = {
  fragments: {
    UsePlaylists_currentPlaylist: gql`
      fragment UsePlaylists_currentPlaylist on DisplayCurrentPlaylist {
        id
        title
      }
    `,
    UsePlaylists_syncPlaylist: gql`
      fragment UsePlaylists_syncPlaylist on DisplayPlaylistSync {
        ... on PlaylistSyncing {
          id
          title
          progress
        }
      }
    `,
    UsePlaylists_syncFailedPlaylist: gql`
      fragment UsePlaylists_syncFailedPlaylist on DisplayPlaylistSync {
        ... on PlaylistSyncFailed {
          id
          title
        }
      }
    `,
    UsePlaylists_display: gql`
      fragment UsePlaylists_display on Display {
        id
        playlist {
          current {
            id
            title
            isOutOfSync
            isTampered
            size
          }
          sync {
            id
            title
            description
            jobId
            ... on PlaylistSyncing {
              progress
              isRemoving
              isPlanned
              size
            }
            ... on PlaylistSyncFailed {
              errorCode
            }
          }
        }
      }
    `,
  },
  mutations: {
    SyncPlaylist: gql`
      mutation SyncPlaylist($input: DisplayUpdatePlaylistInput!) {
        displayUpdatePlaylist(input: $input) {
          display {
            id
            ...UsePlaylists_display
          }
        }
      }
    `,
  },
};
