/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplayRebootModal_DisplayFragmentDoc } from './DisplayRebootModal.graphql';
import { PowerBadge_DisplayFragmentDoc } from '../../../__generated__/PowerBadge.graphql';
import { PresenceBadge_DisplayFragmentDoc } from '../../../__generated__/PresenceBadge.graphql';
import { UsePowerToggle_DisplayFragmentDoc } from '../../power/__generated__/usePowerToggle.graphql';
export type StatusCard_DisplayFragment = { __typename: 'Display', id: string, presence?: { __typename: 'DisplayPresence', connected: boolean, timestamp: any } | null, power?: { __typename: 'PowerState', reported: Types.Power, reportedAt: any, desired?: Types.Power | null } | null, reboot?: { __typename: 'RebootState', latestJob: { __typename: 'RebootJobAborted', abortedAt: any, id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobCompleted', completedAt: any, id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobInProgress', id: string, createdAt: any, plannedAt: any } | { __typename: 'RebootJobPlanned', id: string, createdAt: any, plannedAt: any } } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export const StatusCard_DisplayFragmentDoc = gql`
    fragment StatusCard_display on Display {
  id
  presence {
    connected
    timestamp
  }
  power {
    reported
    reportedAt
    desired
  }
  ...DisplayRebootModal_display
  ...PowerBadge_display
  ...PresenceBadge_display
  ...usePowerToggle_display
}
    ${DisplayRebootModal_DisplayFragmentDoc}
${PowerBadge_DisplayFragmentDoc}
${PresenceBadge_DisplayFragmentDoc}
${UsePowerToggle_DisplayFragmentDoc}`;