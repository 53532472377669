/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CustomerSubscriptionsTableSubscriptionFragment = { __typename: 'WaveSubscription', id: string, name: string, validUntil: any, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null }, customers: Array<{ __typename: 'PartialCustomer', name: string }> };

export const CustomerSubscriptionsTableSubscriptionFragmentDoc = gql`
    fragment CustomerSubscriptionsTableSubscription on WaveSubscription {
  id
  name
  usage {
    current
    max
  }
  validUntil
  customers {
    name
  }
}
    `;