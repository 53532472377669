import { useMemo } from 'react';
import { useAuthentication } from './useAuthentication';
import { useAuthorization } from './useAuthorization';

export function useAuth() {
  const authentication = useAuthentication();
  const authorization = useAuthorization();

  const auth = useMemo(
    () => ({
      ...authentication,
      ...authorization,
    }),
    [authentication, authorization],
  );

  return auth;
}
