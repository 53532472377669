/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { ManagePowerScheduleModal_CustomerFragmentDoc } from '../../../__generated__/ManagePowerScheduleModal.graphql';
import { AlertPowerScheduleSync_DisplayFragmentDoc } from './AlertPowerScheduleSync.graphql';
import { PowerScheduleTimeline_DisplayFragmentDoc } from './PowerScheduleTimeline.graphql';
import { ManagePowerScheduleModal_DisplayFragmentDoc } from '../../../__generated__/ManagePowerScheduleModal.graphql';
export type PowerScheduleCard_CustomerFragment = { __typename: 'Customer', id: string, powerSchedules: Array<{ __typename: 'PowerSchedule', id: string, title: string }> };

export type PowerScheduleCard_DisplayFragment = { __typename: 'Display', id: string, power?: { __typename: 'PowerState', reportedAt: any, desired?: Types.Power | null } | null, timeZone?: { __typename: 'TimeZoneState', reported: string, desired?: string | null } | null, powerSchedule?: { __typename: 'DisplayPowerSchedule', isSynced?: boolean | null, schedule?: { __typename: 'PowerSchedule', id: string, title: string, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }> } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateCompleted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateInProgress', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateRejected', rejectionCode: string, id: string, scheduleId?: string | null } | null } | null };

export const PowerScheduleCard_CustomerFragmentDoc = gql`
    fragment PowerScheduleCard_customer on Customer {
  id
  ...ManagePowerScheduleModal_customer
}
    ${ManagePowerScheduleModal_CustomerFragmentDoc}`;
export const PowerScheduleCard_DisplayFragmentDoc = gql`
    fragment PowerScheduleCard_display on Display {
  id
  power {
    reportedAt
    desired
  }
  timeZone {
    reported
    desired
  }
  ...AlertPowerScheduleSync_display
  ...PowerScheduleTimeline_display
  ...ManagePowerScheduleModal_display
}
    ${AlertPowerScheduleSync_DisplayFragmentDoc}
${PowerScheduleTimeline_DisplayFragmentDoc}
${ManagePowerScheduleModal_DisplayFragmentDoc}`;