/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type DisplaySubscriptionsFragment = { __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } };

export type UseManageAppSubscriptionsForm_DisplayFragment = { __typename: 'Display', id: string, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> };

export const DisplaySubscriptionsFragmentDoc = gql`
    fragment DisplaySubscriptions on DisplayAppSubscription {
  id
  name
  iconUrl
  appVersions
  appInstallation {
    id
    ... on AppInstallationInstalled {
      versionName
    }
    ... on AppInstallationInstalling {
      versionName
      downloadProgress
    }
    ... on AppInstallationUninstalling {
      versionName
    }
    ... on AppInstallationUpdating {
      currentVersionCode
      downloadProgress
    }
  }
  usage {
    current
    max
  }
}
    `;
export const UseManageAppSubscriptionsForm_DisplayFragmentDoc = gql`
    fragment useManageAppSubscriptionsForm_display on Display {
  id
  appSubscriptions {
    ...DisplaySubscriptions
  }
}
    ${DisplaySubscriptionsFragmentDoc}`;