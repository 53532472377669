import { gql } from '@apollo/client';
import { Box, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { Maybe } from '~graphql/__generated__/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { PlaylistCell_ValueFragment } from './__generated__/PlaylistCell.graphql';

export function PlaylistCell({
  value,
}: PropsWithChildren<
  CellProps<DisplayTable_DisplayFragment, Maybe<PlaylistCell_ValueFragment> | undefined>
>) {
  if (value?.current == null) {
    return <Box />;
  }

  return (
    <Tooltip label={value.current?.title}>
      <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {value.current?.title}
      </Box>
    </Tooltip>
  );
}

PlaylistCell.graphql = {
  fragments: {
    PlaylistCell_value: gql`
      fragment PlaylistCell_value on DisplayPlaylist {
        current {
          title
        }
      }
    `,
    PlaylistCell_display: gql`
      fragment PlaylistCell_display on Display {
        id
        playlist {
          ...PlaylistCell_value
        }
      }
    `,
  },
};
