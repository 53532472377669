/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFirmwareModal_DisplayFragment = { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string> } } | null };

export type UpdateAndroidMutationVariables = Types.Exact<{
  input: Types.FirmwareUpdateAndroidInput;
}>;


export type UpdateAndroidMutation = { __typename: 'Mutation', firmwareUpdateAndroid: { __typename: 'FirmwareUpdateAndroidPayload', display: { __typename: 'Display', id: string, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any, availableUpdates: Array<string>, latestJob?: { __typename: 'AndroidUpdateAborted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateCompleted', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateDownloading', downloadProgress: number, id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateInstalling', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdatePlanned', id: string, targetVersion: any, createdAt: any, plannedAt: any } | { __typename: 'AndroidUpdateRejected', rejectedAt: any, rejectionCode: string, id: string, targetVersion: any, createdAt: any, plannedAt: any } | null } } | null } } };

export const UpdateFirmwareModal_DisplayFragmentDoc = gql`
    fragment UpdateFirmwareModal_display on Display {
  id
  firmware {
    android {
      version
      availableUpdates
    }
  }
}
    `;
export const UpdateAndroidDocument = gql`
    mutation UpdateAndroid($input: FirmwareUpdateAndroidInput!) {
  firmwareUpdateAndroid(input: $input) {
    display {
      id
      firmware {
        android {
          version
          availableUpdates
          latestJob {
            id
            targetVersion
            createdAt
            plannedAt
            ... on AndroidUpdateDownloading {
              downloadProgress
            }
            ... on AndroidUpdateRejected {
              rejectedAt
              rejectionCode
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateAndroidMutationFn = ApolloReactCommon.MutationFunction<UpdateAndroidMutation, UpdateAndroidMutationVariables>;

/**
 * __useUpdateAndroidMutation__
 *
 * To run a mutation, you first call `useUpdateAndroidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAndroidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAndroidMutation, { data, loading, error }] = useUpdateAndroidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAndroidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAndroidMutation, UpdateAndroidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAndroidMutation, UpdateAndroidMutationVariables>(UpdateAndroidDocument, options);
      }
export type UpdateAndroidMutationHookResult = ReturnType<typeof useUpdateAndroidMutation>;
export type UpdateAndroidMutationResult = ApolloReactCommon.MutationResult<UpdateAndroidMutation>;
export type UpdateAndroidMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAndroidMutation, UpdateAndroidMutationVariables>;