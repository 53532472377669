import { createIcon } from '@chakra-ui/react';

export const SubscriptionIcon = createIcon({
  displayName: 'SubscriptionIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <>
      <path
        d="M20 10.0952V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H15.2857"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17.6739 6.32609L18.5 8L19.3261 6.32609L21 5.5L19.3261 4.67391L18.5 3L17.6739 4.67391L16 5.5L17.6739 6.32609Z"
        fill="currentColor"
      />
      <path
        d="M10.7516 12.2484L12 15L13.2484 12.2484L16 11L13.2484 9.75159L12 7L10.7516 9.75159L8 11L10.7516 12.2484Z"
        fill="currentColor"
      />
    </>
  ),
});
