import { gql } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { isNil } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { BulkActionModal_DisplayFragment } from '~components/displays/BulkAction/__generated__/BulkActionModal.graphql';
import { useBulkUpdateFirmwareMutation } from '~components/displays/BulkAction/__generated__/BulkUpdateFirmware.graphql';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { BulkActionComponent } from '~components/displays/BulkAction/BulkActionModal';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components } from '~components/ui/Select';
import { DisplayPlatform } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';

interface FormData {
  displayIds: string[];
  targetVersion: string | null | undefined;
  plan: 'now' | 'plan';
  plannedAt: Date | null | undefined;
}

function checkPlatform(display: BulkActionModal_DisplayFragment, platform: DisplayPlatform) {
  return (
    display.platform.name === platform.name &&
    display.platform.version === platform.version &&
    display.platform.type === platform.type
  );
}

function getUpdates(displays: BulkActionModal_DisplayFragment[]): string[] {
  // Sort displays in descending order based on version
  displays.sort((a, b) => b.firmware?.android?.version.localeCompare(a.firmware?.android?.version));
  // Get display with the highest version
  const highestVersionDisplay = displays[0];
  const highestVersionDisplayUpdates =
    highestVersionDisplay.firmware?.android?.availableUpdates ?? [];

  // Merge version and availableUpdates into one array
  if (allDisplaysHaveSameVersion(displays)) {
    return highestVersionDisplayUpdates;
  } else {
    return [...highestVersionDisplayUpdates];
  }
}

function allDisplaysHaveSameVersion(displays: BulkActionModal_DisplayFragment[]): boolean {
  const version = displays[0].firmware?.android?.version;
  return displays.every((display) => display.firmware?.android?.version === version);
}

export const BulkUpdateFirmware: BulkActionComponent = ({
  displays,
  isOpen,
  onCancel,
  onSuccess,
  onBack,
}) => {
  const allDisplaysHaveSamePlatform = displays.every((display) =>
    checkPlatform(display, displays[0].platform),
  );
  const targetVersionOptions = useMemo(() => {
    return getUpdates(displays)
      .slice()
      .sort((a, b) => (a > b ? -1 : 1))
      .map((version) => ({
        label: version,
        value: version,
      }));
  }, [displays]);

  const defaultTargetVersionOption = useMemo(() => {
    return targetVersionOptions.length === 1 ? targetVersionOptions[0].value : undefined;
  }, [targetVersionOptions]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      displayIds: displays.map((d) => d.id),
      plan: 'now',
      targetVersion: defaultTargetVersionOption,
    },
  });

  const watchPlan = watch('plan');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const targetVersionInputRef = useRef<any | null>(null);

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const [bulkUpdateAndroid] = useBulkUpdateFirmwareMutation();
  const toast = useToast();

  const analytics = useAnalyticsReporter();

  const performSubmit = async (values: FormData) => {
    if (!values.targetVersion) return;

    try {
      await bulkUpdateAndroid({
        variables: {
          input: {
            displayIds: values.displayIds,
            targetVersion: values.targetVersion,
            plannedAt: values.plannedAt?.toJSON() ?? null,
          },
        },
      });

      if (!isNil(values.plannedAt)) {
        analytics.track('firmwareUpdateConfirmed', { start: 'later' });
      } else {
        analytics.track('firmwareUpdateConfirmed', { start: 'now' });
      }

      analytics.track('displayBulkActionComplete', {
        action: 'updateFirmware',
        displayCount: displays.length,
      });

      toast({
        status: 'success',
        title: 'Update planned',
        description: `An android firmware update has been planned`,
      });

      reset();
      await onSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot update firmware',
        description: fromError(err, 'bulkUpdateAndroid'),
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel} initialFocusRef={targetVersionInputRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Firmware</ModalHeader>
        <ModalCloseButton tabIndex={5} />
        <form onSubmit={handleSubmit(performSubmit)}>
          <ModalBody>
            <Stack direction="column" spacing="4">
              {!allDisplaysHaveSamePlatform && (
                <InfoAlert>
                  Please select displays of the same platform to bulk update the firmware.
                </InfoAlert>
              )}
              {allDisplaysHaveSamePlatform && targetVersionOptions.length === 0 && (
                <InfoAlert>
                  All selected displays are on the latest firmware, there is no update available.
                </InfoAlert>
              )}
              {allDisplaysHaveSamePlatform && targetVersionOptions.length !== 0 && (
                <Stack>
                  <InfoAlert>
                    Changing multiple displays to a new firmware version at the same time may cause
                    heavily increased network traffic.
                  </InfoAlert>
                  <FormControl isInvalid={Boolean(errors.targetVersion)}>
                    <FormLabel>Version</FormLabel>
                    <Controller
                      name="targetVersion"
                      control={control}
                      render={({ field }) => (
                        <Select
                          ref={targetVersionInputRef}
                          tabIndex={1}
                          value={targetVersionOptions.find((o) => o.value === field.value)}
                          options={targetVersionOptions}
                          onChange={(value) => field.onChange(value?.value)}
                          onBlur={field.onBlur}
                          components={components}
                          isMulti={false}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors.targetVersion?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <Controller
                      name="plan"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          tabIndex={2}
                          defaultValue="now"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        >
                          <Stack spacing="4" direction="row">
                            <Radio value="now">Now</Radio>
                            <Radio value="plan">Specify a time</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                  {watchPlan === 'plan' && (
                    <FormControl>
                      <Controller
                        name="plannedAt"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            tabIndex={3}
                            showTimeSelect
                            placeholderText="Specify a time"
                            selected={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            popperPlacement="bottom-start"
                            filterTime={(time) => {
                              const currentDate = new Date();
                              const selectedDate = new Date(time);

                              return currentDate.getTime() < selectedDate.getTime();
                            }}
                            filterDate={(time) => {
                              const currentDate = new Date();
                              const selectedDate = new Date(time);

                              return currentDate < selectedDate;
                            }}
                            showPopperArrow={false}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                </Stack>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack flex="1" direction="row" alignItems="center">
              <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  onClick={handleClose}
                  variant="ghost"
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="blue"
                  marginLeft="3"
                  type="submit"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Confirm
                </Button>
              </Box>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

BulkUpdateFirmware.graphql = {
  fragments: {
    BulkUpdateFirmware_display: gql`
      fragment BulkUpdateFirmware_display on Display {
        id
        firmware {
          android {
            version
            availableUpdates
          }
        }
        platform {
          name
          version
          type
        }
      }
    `,
  },
  mutations: {
    BulkUpdateFirmware: gql`
      mutation BulkUpdateFirmware($input: DisplayBulkUpdateAndroidFirmwareInput!) {
        displayBulkUpdateAndroidFirmware(input: $input) {
          displays {
            id
            firmware {
              android {
                version
                availableUpdates
                latestJob {
                  id
                  targetVersion
                  createdAt
                  plannedAt
                  ... on AndroidUpdateDownloading {
                    downloadProgress
                  }
                  ... on AndroidUpdateRejected {
                    rejectedAt
                    rejectionCode
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
};
