import * as CookieThough from 'cookie-though';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useAnalyticsReporter } from './index';

export const HIDE_COOKIE_ON_PATHS = [
  '/',
  '/login',
  '/logout',
  '/legal/consent',
  '/legal/privacy-policy',
  '/legal/terms-of-use',
];

export function showCookieThough() {
  CookieThough.show();
}

export function useCookies() {
  const location = useLocation();
  const analyticsReporter = useAnalyticsReporter();
  const hasRunOnceRef = useRef(false);

  useEffect(() => {
    if (hasRunOnceRef.current) {
      return;
    }
    // This avoids flashing banner before redirects.
    if (HIDE_COOKIE_ON_PATHS.includes(location.pathname)) {
      return;
    }

    CookieThough.init({
      policies: [
        {
          id: 'mandatory',
          label: 'Mandatory Cookies',
          description: 'These cookies are required to activate core functionalities of the site.',
          category: 'essential',
        },
        {
          id: 'analytical',
          label: 'Analytical cookies',
          category: 'statistics',
          description:
            'With these cookies the site usage can be analyzed, so that the performance can be assessed and improved.',
        },
      ],
      essentialLabel: 'Always on',
      permissionLabels: {
        accept: 'Accept',
        acceptAll: 'Accept all',
        decline: 'Decline',
      },
      cookiePreferenceKey: 'cookie-preferences',
      header: {
        title: 'Cookie policy',
        subTitle: 'Customize your cookie preferences',
        description:
          "Everybody wants to show his best side - and so do we. That's why we use cookies to guarantee you a better experience.",
      },
      cookiePolicy: {
        url: 'https://www.ppds.com/cookie-policy',
        label: 'Read the full cookie declaration',
      },
      customizeLabel: 'Customize',
    });

    // This __must__ be attached after init or `onPreferencesChanged` inits cookie-though with defaults.
    CookieThough.onPreferencesChanged(() => {
      analyticsReporter.initialize();
      analyticsReporter.track('preferencesChanged');
    });

    // This __must__ be invoked after init because it calls `isAnalyticalCookieEnabled` which in turn would
    // init cookie-though with defaults by calling `getPreferences`.
    analyticsReporter.initialize();

    hasRunOnceRef.current = true;
  }, [analyticsReporter, location.pathname]);
}

export function isAnalyticalCookieEnabled(): boolean {
  const analyticalCookieOption = CookieThough.getPreferences().cookieOptions.find(
    (option) => option.id === 'analytical',
  );

  return analyticalCookieOption?.isEnabled ?? false;
}

export const cookieBannerStyle = {
  '.cookie-though': {
    '--ct-slider-enabled-primary': '#1D85E8',
    '--ct-slider-enabled-secondary': 'var(--chakra-colors-blue-100)',
    '--ct-primary-200': 'var(--chakra-colors-blue-100)',
    '--ct-primary-100': 'var(--chakra-colors-blue-50)',
    '--ct-primary-font': "'Gilroy'",
    '--ct-primary-font-weight': 600,
    '--ct-secondary-font': "'Inter'",
    '--ct-primary-400': 'var(--chakra-colors-blue-700)',
    '--ct-primary-300': 'var(--chakra-colors-blue-500)',
    '--ct-text': 'var(--chakra-colors-blue-800)',
  },
};
