import { Location } from 'history';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '~auth/useAuthentication';
import { useQueryParams } from '~utils/useQueryParams';

type LoginState = {
  from?: Location;
};

export function LoginPage() {
  const location = useLocation();
  const state = location.state as LoginState | undefined;
  const query = useQueryParams();

  const { loginWithRedirect } = useAuthentication();

  useEffect(() => {
    void loginWithRedirect({
      authorizationParams: {
        login_hint: query.get('email') ?? undefined,
      },
      appState: {
        returnTo: state?.from?.pathname,
      },
    });
  }, [loginWithRedirect, query, state]);

  return null;
}
