import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { TimeSelect } from '~components/powerSchedules/TimeSelect';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { SwitchType } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { HOUR_REGEX } from '~utils/timeBlocks';
import { useBulkUpdateAutoRestartMutation } from './__generated__/BulkUpdateAutoRestart.graphql';
import BackToAllActionsButton from './BackToAllActionsButton';
import { BulkActionComponent } from './BulkActionModal';

const schema = z.object({
  displayIds: z.array(z.string()),
  isEnabled: z.boolean(),
  time: z.string(),
});
type FormValues = z.TypeOf<typeof schema>;

export const BulkUpdateAutoRestart: BulkActionComponent = ({
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const [bulkUpdateAutoRestart] = useBulkUpdateAutoRestartMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      displayIds: displays
        .filter(({ autoRestart }) => !_.isNil(autoRestart?.desired ?? autoRestart?.reported))
        .map((display) => display.id),
      isEnabled: true,
      time: '00:00',
    },
    resolver: zodResolver(schema),
  });

  const toast = useToast();
  const analytics = useAnalyticsReporter();

  const handleBulkUpdateAutoRestart = async ({ displayIds, isEnabled, time }: FormValues) => {
    try {
      const switchValue = isEnabled ? SwitchType.On : SwitchType.Off;

      if (displayIds.length > 0 && time.length === 5) {
        await bulkUpdateAutoRestart({
          variables: {
            input: {
              displayIds,
              switch: switchValue,
              time,
            },
          },
        });

        if (displayIds.length === 1) {
          analytics.track('autoRestartUpdate');
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'updateAutoRestart',
            displayCount: displayIds.length,
          });
        }
      }

      await onSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot update auto restart in bulk',
        description: fromError(err, 'bulkUpdateAutoRestart'),
      });
    }
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Auto restart</ModalHeader>
        <ModalCloseButton onClick={onCancel} isDisabled={isSubmitting} />
        <form onSubmit={handleSubmit(handleBulkUpdateAutoRestart)}>
          <ModalBody>
            <Stack spacing="4">
              <InfoAlert>
                Displays that do not support this functionality will be skipped.
              </InfoAlert>
              <Text color="gray.500" mb="2">
                {
                  'When an auto restart time is set to the display, it automatically restart everyday at the specified time'
                }
              </Text>
              <Controller
                name="isEnabled"
                control={control}
                render={({ field }) => (
                  <Checkbox isChecked={field.value} onChange={field.onChange}>
                    Enable auto restart
                  </Checkbox>
                )}
              />
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <TimeSelect
                    property="start"
                    value={field.value}
                    onChange={(property, value) => {
                      if (!HOUR_REGEX.test(value)) return;
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack flex="1" alignItems="center">
              <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={onCancel}
                  isDisabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  marginLeft="3"
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Apply
                </Button>
              </Box>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </>
  );
};

BulkUpdateAutoRestart.graphql = {
  fragments: {
    BulkUpdateAutoRestart_display: gql`
      fragment BulkUpdateAutoRestart_display on Display {
        autoRestart {
          desired {
            switch
            time
          }
          reported {
            switch
            time
          }
        }
      }
    `,
  },
  mutations: {
    BulkUpdateAutoRestart: gql`
      mutation BulkUpdateAutoRestart($input: DisplayBulkUpdateAutoRestartInput!) {
        displayBulkUpdateAutoRestart(input: $input) {
          displays {
            id
            autoRestart {
              desired {
                switch
                time
              }
              reported {
                switch
                time
              }
            }
          }
        }
      }
    `,
  },
};
