import { WaveSubscription } from '~graphql/__generated__/types';
import { DeepPartial, isDefined } from './types';

export enum WaveSubscriptionEnum {
  Essential = 'Essential',
  Lite = 'Lite',
}

/**
 * Checks if a subscription is a valid Wave subscription.
 * @param {WaveSubscriptionDocument | null} subscription - The subscription to check.
 * @returns {boolean} - Returns true if the subscription is defined
 */
export function isWaveSubscription(
  subscription: DeepPartial<WaveSubscription> | null | undefined,
): boolean {
  return isDefined(subscription);
}

/**
 * Determines the subscription type based on the provided subscription object.
 *
 * @param {DeepPartial<WaveSubscription> | null | undefined} subscription - The subscription object to check.
 * @returns {WaveSubscriptionEnum} - Returns `WaveSubscriptionEnum.Essential` if the subscription is a WaveSubscription, otherwise returns `WaveSubscriptionEnum.Lite`.
 */
export function getSubscriptionType(
  subscription: DeepPartial<WaveSubscription> | null | undefined,
): WaveSubscriptionEnum {
  return isWaveSubscription(subscription)
    ? WaveSubscriptionEnum.Essential
    : WaveSubscriptionEnum.Lite;
}
