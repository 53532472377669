import { gql } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  UpdateFirmwareModal_DisplayFragment,
  useUpdateAndroidMutation,
} from './__generated__/UpdateFirmwareModal.graphql';

interface Props {
  display: UpdateFirmwareModal_DisplayFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}

const schema = z.object({
  displayId: z.string(),
  targetVersion: z.string().optional().nullable(),
  plan: z.enum(['now', 'plan']),
  plannedAt: z.date().optional().nullable(),
});

type FormValues = z.infer<typeof schema>;

export function UpdateFirmwareModal({ display, isOpen, onCancel, onSuccess }: Props) {
  const targetVersionOptions = useMemo(() => {
    return (display.firmware?.android.availableUpdates ?? [])
      .slice()
      .sort((a, b) => (a > b ? -1 : 1))
      .map((version) => ({
        label: version,
        value: version,
      }));
  }, [display]);
  const defaultTargetVersionOption = useMemo(() => {
    return targetVersionOptions.length === 1 ? targetVersionOptions[0].value : undefined;
  }, [targetVersionOptions]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      displayId: display.id,
      plan: 'now',
      targetVersion: defaultTargetVersionOption,
    },
    resolver: zodResolver(schema),
  });

  const watchPlan = watch('plan');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const targetVersionInputRef = useRef<any | null>(null);

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const [updateAndroid] = useUpdateAndroidMutation();
  const toast = useToast();

  const analytics = useAnalyticsReporter();

  const performSubmit = async (values: FormValues) => {
    if (!values.targetVersion) return;

    try {
      await updateAndroid({
        variables: {
          input: {
            displayId: values.displayId,
            targetVersion: values.targetVersion,
            plannedAt: values.plannedAt?.toJSON() ?? null,
          },
        },
      });

      if (!isNil(values.plannedAt)) {
        analytics.track('firmwareUpdateConfirmed', { start: 'later' });
      } else {
        analytics.track('firmwareUpdateConfirmed', { start: 'now' });
      }

      toast({
        status: 'success',
        title: 'Update planned',
        description: `An android firmware update has been planned`,
      });

      reset();
      await onSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot update firmware',
        description: fromError(err, 'UpdateAndroid'),
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel} initialFocusRef={targetVersionInputRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Firmware</ModalHeader>
        <ModalCloseButton tabIndex={5} />
        <form onSubmit={handleSubmit(performSubmit)}>
          <ModalBody>
            <Stack direction="column" spacing="4">
              <FormControl isInvalid={Boolean(errors.targetVersion)}>
                <FormLabel>Version</FormLabel>
                <Controller
                  name="targetVersion"
                  control={control}
                  render={({ field }) => (
                    <Select
                      ref={targetVersionInputRef}
                      tabIndex={1}
                      value={targetVersionOptions.find((o) => o.value === field.value)}
                      options={targetVersionOptions}
                      onChange={(value) => field.onChange(value?.value)}
                      onBlur={field.onBlur}
                      components={components}
                      isMulti={false}
                    />
                  )}
                />
                <FormErrorMessage>{errors.targetVersion?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <Controller
                  name="plan"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      tabIndex={2}
                      defaultValue="now"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      <Stack spacing="4" direction="row">
                        <Radio value="now">Now</Radio>
                        <Radio value="plan">Specify a time</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
              {watchPlan === 'plan' && (
                <FormControl>
                  <Controller
                    name="plannedAt"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        tabIndex={3}
                        showTimeSelect
                        placeholderText="Specify a time"
                        selected={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        popperPlacement="bottom-start"
                        filterTime={(time) => {
                          const currentDate = new Date();
                          const selectedDate = new Date(time);

                          return currentDate.getTime() < selectedDate.getTime();
                        }}
                        filterDate={(time) => {
                          const currentDate = new Date();
                          const selectedDate = new Date(time);

                          return currentDate < selectedDate;
                        }}
                        showPopperArrow={false}
                      />
                    )}
                  />
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleClose}
              variant="ghost"
              colorScheme="blue"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Confirm
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

UpdateFirmwareModal.graphql = {
  fragments: {
    UpdateFirmwareModal_display: gql`
      fragment UpdateFirmwareModal_display on Display {
        id
        firmware {
          android {
            version
            availableUpdates
          }
        }
      }
    `,
  },
  mutations: {
    UpdateAndroid: gql`
      mutation UpdateAndroid($input: FirmwareUpdateAndroidInput!) {
        firmwareUpdateAndroid(input: $input) {
          display {
            id
            firmware {
              android {
                version
                availableUpdates
                latestJob {
                  id
                  targetVersion
                  createdAt
                  plannedAt
                  ... on AndroidUpdateDownloading {
                    downloadProgress
                  }
                  ... on AndroidUpdateRejected {
                    rejectedAt
                    rejectionCode
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
};
