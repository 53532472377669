import { createIcon } from '@chakra-ui/react';

export const DisplaysIcon = createIcon({
  displayName: 'DisplaysIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <>
      <path
        d="M3.57911 13.7332H10.316M12.5616 11.0665V5.73317C12.5616 5.14407 12.0589 4.6665 11.4388 4.6665L2.4563 4.6665C1.83619 4.6665 1.3335 5.14407 1.3335 5.73317L1.3335 11.0665C1.3335 11.6556 1.83619 12.1332 2.4563 12.1332L11.4388 12.1332C12.0589 12.1332 12.5616 11.6556 12.5616 11.0665Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M3.43896 2.6665H13.6004C14.1895 2.6665 14.667 3.14407 14.667 3.73317V9.99984"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
