/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRecommendedSettings_DisplayFragmentDoc } from '../../../__generated__/useRecommendedSettings.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UsePowerToggle_DisplayFragment = { __typename: 'Display', id: string, power?: { __typename: 'PowerState', reported: Types.Power, reportedAt: any, desired?: Types.Power | null } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export type UpdatePowerMutationVariables = Types.Exact<{
  input: Types.DisplayUpdatePowerInput;
}>;


export type UpdatePowerMutation = { __typename: 'Mutation', displayUpdatePower: { __typename: 'DisplayUpdatePowerPayload', display: { __typename: 'Display', id: string, power?: { __typename: 'PowerState', reported: Types.Power, reportedAt: any, desired?: Types.Power | null } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null } } };

export const UsePowerToggle_DisplayFragmentDoc = gql`
    fragment usePowerToggle_display on Display {
  id
  power {
    reported
    reportedAt
    desired
  }
  ...UseRecommendedSettings_display
}
    ${UseRecommendedSettings_DisplayFragmentDoc}`;
export const UpdatePowerDocument = gql`
    mutation UpdatePower($input: DisplayUpdatePowerInput!) {
  displayUpdatePower(input: $input) {
    display {
      id
      power {
        reported
        reportedAt
        desired
      }
      ...UseRecommendedSettings_display
    }
  }
}
    ${UseRecommendedSettings_DisplayFragmentDoc}`;
export type UpdatePowerMutationFn = ApolloReactCommon.MutationFunction<UpdatePowerMutation, UpdatePowerMutationVariables>;

/**
 * __useUpdatePowerMutation__
 *
 * To run a mutation, you first call `useUpdatePowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePowerMutation, { data, loading, error }] = useUpdatePowerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePowerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePowerMutation, UpdatePowerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePowerMutation, UpdatePowerMutationVariables>(UpdatePowerDocument, options);
      }
export type UpdatePowerMutationHookResult = ReturnType<typeof useUpdatePowerMutation>;
export type UpdatePowerMutationResult = ApolloReactCommon.MutationResult<UpdatePowerMutation>;
export type UpdatePowerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePowerMutation, UpdatePowerMutationVariables>;