import { Box, chakra, IconProps, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { ComponentType, createRef, forwardRef, Ref, useCallback, useEffect, useState } from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { OmitStrict } from '~utils/types';
import { Link } from './Link';
import { useTheme } from './styles/hooks';

interface NavLinkProps {
  to: string;
  label: string;
  icon: ComponentType<IconProps>;
  onActive: () => void;
}

interface Props {
  links: Array<OmitStrict<NavLinkProps, 'onActive'>>;
}

export function HeaderNavLinks({ links }: Props) {
  const theme = useTheme();
  const refs = links.map(() => createRef<HTMLAnchorElement>());
  const [activeEl, setActiveEl] = useState<HTMLAnchorElement | null>(null);
  const [animate, setAnimate] = useState({ x: 0, width: '0px' });

  // react-router v6 doesn't have a good way to figure out which of these links is active from this level.
  // the methods we can use are resolvePath and matchPath. Sadly the latter requires input from the router context
  // which is private to the router.
  // Instead of figuring it out at this level we'll send in a callback that is called upon rendering
  // and will tell this component which link is active.
  const handleActive = useCallback(
    (index: number) => () => {
      const el = refs[index].current;

      if (el) {
        setActiveEl(el);
      }
    },
    [refs],
  );

  const updateAnimate = useCallback(() => {
    if (!activeEl) return;

    setAnimate({
      x: activeEl.offsetLeft,
      width: `${activeEl.clientWidth}px`,
    });
  }, [activeEl]);

  useEffect(() => {
    updateAnimate();
  }, [updateAnimate]);

  useEffect(() => {
    const recalculateAnimate = _.debounce(() => {
      updateAnimate();
    }, 100);

    window.addEventListener('resize', recalculateAnimate);
    return () => {
      window.removeEventListener('resize', recalculateAnimate);
    };
  }, [updateAnimate]);

  return (
    <Box
      flex="1"
      display="flex"
      position="relative"
      marginLeft={{
        base: '6',
        md: '16',
      }}
    >
      <Stack display="flex" flex="1" direction="row" spacing="6">
        {links.map((link, index) => (
          <NavLink key={link.to} ref={refs[index]} {...link} onActive={handleActive(index)} />
        ))}
      </Stack>
      <motion.div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          height: '3px',
          background: theme.colors.blue[500],
        }}
        animate={animate}
        transition={{
          type: 'spring',
          bounce: 0.2,
          duration: 0.25,
        }}
      />
    </Box>
  );
}

const NavLink = forwardRef(function NavLink(
  { label, to, icon: Icon, onActive }: NavLinkProps,
  ref: Ref<HTMLAnchorElement>,
) {
  const path = useResolvedPath(to);
  const match = useMatch({ path: path.pathname, end: false });

  useEffect(() => {
    if (match) {
      onActive();
    }
  }, [to, match, onActive]);

  return (
    <Link
      ref={ref}
      key={to}
      display="flex"
      to={to}
      color={match ? 'blue.500' : 'blue.800'}
      fontSize="md"
      fontWeight="semibold"
      _hover={{ textDecoration: 'none' }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        paddingX={{
          base: '2',
          lg: '4',
        }}
        _hover={{
          color: 'blue.500',
        }}
      >
        <chakra.span
          display={{
            base: 'none',
            lg: 'inline',
          }}
        >
          {label}
        </chakra.span>
        <Box
          display={{
            base: 'block',
            lg: 'none',
          }}
        >
          <Icon height="6" width="6" />
        </Box>
      </Box>
    </Link>
  );
});
