import { useRadio } from '@chakra-ui/radio';
import { Box, Circle, HStack, IconProps, RadioProps } from '@chakra-ui/react';
import { ComponentType, ReactNode } from 'react';

export function BoxedRadio(
  props: RadioProps & {
    icon?: ComponentType<IconProps>;
    checkedIcon?: ReactNode;
  },
) {
  const { getCheckboxProps, getInputProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const Icon = props.icon;

  const checkedProps = {
    ...props._checked,
    backgroundColor: props._checked?.backgroundColor ?? 'blue.50',
    borderColor: props._checked?.borderColor ?? 'blue.500',
  };

  return (
    <Box as="label">
      <input {...input} />
      <HStack
        {...checkbox}
        cursor="pointer"
        spacing="4"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.100"
        paddingX="4"
        paddingY="2"
        _hover={{
          ...props._hover,
          backgroundColor: props._hover?.backgroundColor ?? 'blue.25',
          borderColor: props._checked?.borderColor ?? 'blue.100',
        }}
        _checked={{ ...checkedProps, _hover: checkedProps }}
        transition="all 200ms ease"
      >
        {Icon && <Icon width="5" height="5" />}
        <Box flex="1">{props.children}</Box>
        {props.checkedIcon && props.isChecked ? (
          props.checkedIcon
        ) : (
          <Circle
            backgroundColor={props.isChecked ? 'white' : 'transparent'}
            border={props.isChecked ? '6px solid' : '1px solid'}
            borderColor={props.isChecked ? 'blue.500' : 'gray.200'}
            size="20px"
            transition="all 200ms ease"
          >
            {props.isChecked ? <Circle backgroundColor="white" /> : null}
          </Circle>
        )}
      </HStack>
    </Box>
  );
}
