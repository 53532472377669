import { createIcon } from '@chakra-ui/react';

export const EmptySubscriptionsIllustration = createIcon({
  displayName: 'EmptySubscriptionsIllustration',
  viewBox: '0 0 240 240',
  defaultProps: {
    fill: 'none',
    width: '144px',
    height: '144px',
  },
  path: (
    <>
      <circle cx="120.5" cy="120" r="120" fill="#F9F6FF" />
      <path
        d="M164.502 109.523V167C164.502 171.418 160.92 175 156.502 175H84.502C80.0837 175 76.502 171.418 76.502 167V72.9995C76.502 68.5812 80.0837 64.9995 84.502 64.9995H138.573"
        stroke="#4C21A4"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M151.708 88.7935L156.252 98L160.795 88.7935L170.002 84.25L160.795 79.7065L156.252 70.5L151.708 79.7065L142.502 84.25L151.708 88.7935Z"
        fill="#4C21A4"
      />
      <path
        d="M113.632 121.367L120.498 136.5L127.364 121.367L142.498 114.5L127.364 107.634L120.498 92.5005L113.632 107.634L98.498 114.5L113.632 121.367Z"
        fill="#4C21A4"
      />
    </>
  ),
});
