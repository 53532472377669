import { createContext, ReactNode, useCallback, useContext, useMemo, useReducer } from 'react';
import { wait } from '~utils/promise';
import { RecommendedSettingsWarning, useRecommendedSettings } from '../useRecommendedSettings';
import { StepClaim_DisplayFragment } from './__generated__/StepClaim.graphql';

export type WizardStep =
  | { name: 'claim' }
  | {
      name: 'apply_settings';
      display: StepClaim_DisplayFragment;
      warnings: RecommendedSettingsWarning[];
      isApplying: boolean;
      isFinished: boolean;
      error?: unknown;
    }
  | { name: 'add_metadata'; display: StepClaim_DisplayFragment };

export interface WizardState {
  step: WizardStep;
}

export type WizardAction =
  | { type: 'claiming_completed'; display: StepClaim_DisplayFragment }
  | { type: 'apply_settings_request_started' }
  | { type: 'apply_settings_request_success' }
  | { type: 'apply_settings_request_failed'; error: unknown }
  | { type: 'apply_settings_step_completed' }
  | { type: 'apply_settings_skipped' };

interface WizardContextProps {
  state: WizardState;
  completeClaiming: (display: StepClaim_DisplayFragment) => void;
  applySettings: () => void;
  completeSettings: () => void;
  skipApplySettings: () => void;
}

const WizardContext = createContext<WizardContextProps | null>(null);

interface Props {
  children: ReactNode;
}

export function WizardProvider({ children }: Props) {
  const { getSettingsState, applyRecommended } = useRecommendedSettings();
  const [state, dispatch] = useReducer(
    useCallback(
      (state: WizardState, action: WizardAction): WizardState => {
        if (action.type === 'claiming_completed' && state.step.name === 'claim') {
          const settings = getSettingsState(action.display);
          if (settings.kind === 'not_recommended') {
            return {
              ...state,
              step: {
                name: 'apply_settings',
                display: action.display,
                warnings: settings.warnings,
                isApplying: false,
                isFinished: false,
              },
            };
          } else {
            return {
              ...state,
              step: {
                name: 'add_metadata',
                display: action.display,
              },
            };
          }
        } else if (
          action.type === 'apply_settings_request_started' &&
          state.step.name === 'apply_settings'
        ) {
          return {
            ...state,
            step: {
              ...state.step,
              isApplying: true,
              isFinished: false,
              error: undefined,
            },
          };
        } else if (
          action.type === 'apply_settings_request_success' &&
          state.step.name === 'apply_settings'
        ) {
          return {
            ...state,
            step: {
              ...state.step,
              isApplying: false,
              isFinished: true,
            },
          };
        } else if (
          action.type === 'apply_settings_request_failed' &&
          state.step.name === 'apply_settings'
        ) {
          return {
            ...state,
            step: {
              ...state.step,
              isApplying: false,
              isFinished: true,
              error: action.error,
            },
          };
        } else if (
          action.type === 'apply_settings_step_completed' &&
          state.step.name === 'apply_settings'
        ) {
          return {
            ...state,
            step: {
              name: 'add_metadata',
              display: state.step.display,
            },
          };
        } else if (
          action.type === 'apply_settings_skipped' &&
          state.step.name === 'apply_settings'
        ) {
          return {
            ...state,
            step: {
              name: 'add_metadata',
              display: state.step.display,
            },
          };
        } else {
          return state;
        }
      },
      [getSettingsState],
    ),
    { step: { name: 'claim' } },
  );

  const completeClaiming = useCallback(
    (display: StepClaim_DisplayFragment) => {
      dispatch({ type: 'claiming_completed', display });
    },
    [dispatch],
  );

  const applySettings = useCallback(async () => {
    if (state.step.name !== 'apply_settings') return;

    try {
      dispatch({ type: 'apply_settings_request_started' });
      await Promise.all([
        applyRecommended(state.step.display),
        // This delay serves two purposes:
        //  - it makes the UX feel more 'real' delaying the animations makes them better
        //  - it applies the 'Fake it until you make it' pattern. We don't know for sure
        //    whether applying recommended settings will eventually work.
        //    By waiting a few seconds we assume that in the majority of cases it will just work.
        //    So by the time you are redirected to the details the banner will be absent.
        wait(5000),
      ]);
      dispatch({ type: 'apply_settings_request_success' });
    } catch (err) {
      dispatch({ type: 'apply_settings_request_failed', error: err });
    }
  }, [state, applyRecommended]);

  const skipApplySettings = useCallback(() => {
    if (state.step.name !== 'apply_settings') return;

    dispatch({ type: 'apply_settings_skipped' });
  }, [state]);

  const completeSettings = useCallback(async () => {
    if (state.step.name !== 'apply_settings') return;
    dispatch({ type: 'apply_settings_step_completed' });
  }, [state]);

  const ctx = useMemo<WizardContextProps>(
    () => ({
      state,
      completeClaiming,
      applySettings,
      completeSettings,
      skipApplySettings,
    }),
    [state, completeClaiming, applySettings, completeSettings, skipApplySettings],
  );

  return <WizardContext.Provider value={ctx}>{children}</WizardContext.Provider>;
}

export function useWizard() {
  const ctx = useContext(WizardContext);

  if (!ctx) {
    throw new Error('useWizard can only be used inside a WizardProvider');
  }

  return ctx;
}
