import { gql } from '@apollo/client';
import {
  Box,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
} from '@chakra-ui/react';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { useManagePlaylistForm } from '~components/displays/useManagePlaylistForm';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { BulkActionComponent } from './BulkActionModal';

export const BulkManagePlaylist: BulkActionComponent = ({
  displays,
  customer,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const { body, footer, onSubmit } = useManagePlaylistForm({
    displayIds: displays.map((d) => d.id),
    customer,
    onCancel: onCancel,
    onSuccess: onSuccess,
  });

  return (
    <>
      <ModalContent>
        <ModalHeader>Manage playlist</ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <Stack spacing="4">
              <InfoAlert>
                Syncing the same playlist to multiple devices on the same location may cause heavily
                increased network traffic.
              </InfoAlert>
              {body}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack flex="1" justifyContent="space-between">
              <BackToAllActionsButton onBack={onBack} />
              <Box>{footer}</Box>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </>
  );
};

BulkManagePlaylist.graphql = {
  fragments: {
    BulkManagePlaylist_customer: gql`
      fragment BulkManagePlaylist_customer on Customer {
        id
        ...useManagePlaylistForm_customer
      }
    `,
  },
};
