/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PowerScheduleTable_PowerScheduleFragmentDoc } from '../PowerScheduleTable/__generated__/PowerScheduleTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePowerScheduleMutationVariables = Types.Exact<{
  input: Types.PowerScheduleCreateInput;
}>;


export type CreatePowerScheduleMutation = { __typename: 'Mutation', powerScheduleCreate: { __typename: 'PowerScheduleCreatePayload', powerSchedule: { __typename: 'PowerSchedule', id: string, title: string, description?: string | null, createdAt: any, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> } } };


export const CreatePowerScheduleDocument = gql`
    mutation CreatePowerSchedule($input: PowerScheduleCreateInput!) {
  powerScheduleCreate(input: $input) {
    powerSchedule {
      id
      ...PowerScheduleTable_powerSchedule
    }
  }
}
    ${PowerScheduleTable_PowerScheduleFragmentDoc}`;
export type CreatePowerScheduleMutationFn = ApolloReactCommon.MutationFunction<CreatePowerScheduleMutation, CreatePowerScheduleMutationVariables>;

/**
 * __useCreatePowerScheduleMutation__
 *
 * To run a mutation, you first call `useCreatePowerScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePowerScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPowerScheduleMutation, { data, loading, error }] = useCreatePowerScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePowerScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePowerScheduleMutation, CreatePowerScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePowerScheduleMutation, CreatePowerScheduleMutationVariables>(CreatePowerScheduleDocument, options);
      }
export type CreatePowerScheduleMutationHookResult = ReturnType<typeof useCreatePowerScheduleMutation>;
export type CreatePowerScheduleMutationResult = ApolloReactCommon.MutationResult<CreatePowerScheduleMutation>;
export type CreatePowerScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePowerScheduleMutation, CreatePowerScheduleMutationVariables>;