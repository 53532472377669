/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type AlertRebootScheduled_DisplayFragment = { __typename: 'Display', id: string, reboot?: { __typename: 'RebootState', latestJob: { __typename: 'RebootJobAborted', id: string, plannedAt: any } | { __typename: 'RebootJobCompleted', id: string, plannedAt: any } | { __typename: 'RebootJobInProgress', id: string, plannedAt: any } | { __typename: 'RebootJobPlanned', id: string, plannedAt: any } } | null };

export type AbortRebootJobMutationVariables = Types.Exact<{
  input: Types.DisplayAbortJobInput;
}>;


export type AbortRebootJobMutation = { __typename: 'Mutation', displayAbortJob: { __typename: 'DisplayAbortJobPayload', display: { __typename: 'Display', id: string, reboot?: { __typename: 'RebootState', latestJob: { __typename: 'RebootJobAborted', id: string, plannedAt: any } | { __typename: 'RebootJobCompleted', id: string, plannedAt: any } | { __typename: 'RebootJobInProgress', id: string, plannedAt: any } | { __typename: 'RebootJobPlanned', id: string, plannedAt: any } } | null } } };

export const AlertRebootScheduled_DisplayFragmentDoc = gql`
    fragment AlertRebootScheduled_display on Display {
  id
  reboot {
    latestJob {
      __typename
      id
      plannedAt
    }
  }
}
    `;
export const AbortRebootJobDocument = gql`
    mutation AbortRebootJob($input: DisplayAbortJobInput!) {
  displayAbortJob(input: $input) {
    display {
      id
      ...AlertRebootScheduled_display
    }
  }
}
    ${AlertRebootScheduled_DisplayFragmentDoc}`;
export type AbortRebootJobMutationFn = ApolloReactCommon.MutationFunction<AbortRebootJobMutation, AbortRebootJobMutationVariables>;

/**
 * __useAbortRebootJobMutation__
 *
 * To run a mutation, you first call `useAbortRebootJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortRebootJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortRebootJobMutation, { data, loading, error }] = useAbortRebootJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAbortRebootJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AbortRebootJobMutation, AbortRebootJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AbortRebootJobMutation, AbortRebootJobMutationVariables>(AbortRebootJobDocument, options);
      }
export type AbortRebootJobMutationHookResult = ReturnType<typeof useAbortRebootJobMutation>;
export type AbortRebootJobMutationResult = ApolloReactCommon.MutationResult<AbortRebootJobMutation>;
export type AbortRebootJobMutationOptions = ApolloReactCommon.BaseMutationOptions<AbortRebootJobMutation, AbortRebootJobMutationVariables>;