import { createIcon } from '@chakra-ui/react';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13V11ZM19 12L19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L19 12ZM12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289C10.9024 4.68342 10.9024 5.31658 11.2929 5.70711L12.7071 4.29289ZM11.2929 18.2929C10.9024 18.6834 10.9024 19.3166 11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L11.2929 18.2929ZM5 13H19V11H5V13ZM11.2929 5.70711L18.2929 12.7071L19.7071 11.2929L12.7071 4.29289L11.2929 5.70711ZM18.2929 11.2929L11.2929 18.2929L12.7071 19.7071L19.7071 12.7071L18.2929 11.2929Z"
      fill="currentColor"
    />
  ),
});
