import { orient } from '@chakra-ui/theme-tools';
import { StyleProps } from './types';

export const Slider = {
  parts: ['container', 'track', 'thumb', 'filledTrack'],
  baseStyle: (props: StyleProps) => {
    return {
      container: {
        _disabled: {
          opacity: 1,
        },
      },
      track: {
        bg: 'gray.100',
        _disabled: {
          bg: 'gray.100',
        },
      },
      filledTrack: {
        bg: 'blue.500',
      },
      thumb: {
        borderRadius: 'full',
        bg: 'white',
        border: '2px solid',
        zIndex: 0,
        borderColor: 'blue.500',
        _focus: { boxShadow: 'outline' },
        _disabled: { bg: 'white', borderColor: 'gray.200' },
        ...orient({
          orientation: props.orientation,
          vertical: {
            left: '50%',
            transform: `translateX(-50%)`,
            _active: {
              transform: `translateX(-50%) scale(1.5)`,
            },
          },
          horizontal: {
            top: '50%',
            transform: `translateY(-50%)`,
            _active: {
              transform: `translateY(-50%) scale(1.5)`,
            },
          },
        }),
      },
    };
  },
};
