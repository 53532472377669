import { createIcon } from '@chakra-ui/react';

export const EmptyAppsIllustration = createIcon({
  displayName: 'EmptyAppsIllustration',
  viewBox: '0 0 240 240',
  defaultProps: {
    fill: 'none',
    width: '240px',
    height: '240px',
  },
  path: (
    <>
      <circle cx="120" cy="120" r="120" fill="#F1F8FE" />
      <g filter="url(#filter0_dd_7806_4166)">
        <rect x="67.5557" y="66.6667" width="49.7778" height="49.7778" rx="7.11111" fill="white" />
      </g>
      <g filter="url(#filter1_dd_7806_4166)">
        <rect x="123.556" y="66.6667" width="49.7778" height="49.7778" rx="7.11111" fill="white" />
      </g>
      <g filter="url(#filter2_dd_7806_4166)">
        <rect x="66.667" y="123.556" width="49.7778" height="49.7778" rx="7.11111" fill="white" />
      </g>
      <g filter="url(#filter3_dd_7806_4166)">
        <rect
          x="123.556"
          y="123.556"
          width="49.7778"
          height="49.7778"
          rx="7.11111"
          fill="#0F5291"
        />
      </g>
      <path
        d="M148.445 141.926V158.519M140.148 150.222H156.741"
        stroke="white"
        strokeWidth="3.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_dd_7806_4166"
          x="54.2853"
          y="64.455"
          width="76.318"
          height="76.3185"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_7806_4166"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7806_4166" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_7806_4166"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7806_4166"
            result="effect2_dropShadow_7806_4166"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7806_4166"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_7806_4166"
          x="110.285"
          y="64.455"
          width="76.318"
          height="76.3185"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_7806_4166"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7806_4166" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_7806_4166"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7806_4166"
            result="effect2_dropShadow_7806_4166"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7806_4166"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_7806_4166"
          x="53.3967"
          y="121.344"
          width="76.318"
          height="76.3185"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_7806_4166"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7806_4166" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_7806_4166"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7806_4166"
            result="effect2_dropShadow_7806_4166"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7806_4166"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_7806_4166"
          x="101.333"
          y="110.613"
          width="94.2218"
          height="94.2222"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.77778"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_7806_4166"
          />
          <feOffset dy="2.64889" />
          <feGaussianBlur stdDeviation="5.53778" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7806_4166" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4.44444"
            operator="dilate"
            in="SourceAlpha"
            result="effect2_dropShadow_7806_4166"
          />
          <feOffset dy="9.28" />
          <feGaussianBlur stdDeviation="8.88889" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7806_4166"
            result="effect2_dropShadow_7806_4166"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7806_4166"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
