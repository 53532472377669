import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { PlaylistTableMedia } from '../PlaylistMediaTable';

export function FileTypeCell({ value }: PropsWithChildren<CellProps<PlaylistTableMedia, string>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {value}
    </Box>
  );
}

FileTypeCell.graphql = {
  fragments: {
    FileTypeCell_media: gql`
      fragment FileTypeCell_media on Media {
        id
        type
      }
    `,
  },
};
