import { createIcon } from '@chakra-ui/react';

export const NoSignalIcon = createIcon({
  displayName: 'NoSignalIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <g>
      <path
        d="M12.0004 13.2C12.6631 13.2 13.2004 12.6627 13.2004 12C13.2004 11.3372 12.6631 10.8 12.0004 10.8C11.3376 10.8 10.8004 11.3372 10.8004 12C10.8004 12.6627 11.3376 13.2 12.0004 13.2Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5444 9.456C14.8791 9.79034 15.1446 10.1874 15.3258 10.6244C15.507 11.0614 15.6002 11.5299 15.6002 12.003C15.6002 12.4761 15.507 12.9445 15.3258 13.3816C15.1446 13.8186 14.8791 14.2157 14.5444 14.55M9.45638 14.544C9.12167 14.2097 8.85613 13.8126 8.67497 13.3756C8.4938 12.9385 8.40055 12.4701 8.40055 11.997C8.40055 11.5239 8.4938 11.0554 8.67497 10.6184C8.85613 10.1814 9.12167 9.78434 9.45638 9.45M16.2424 7.758C17.3672 8.88316 17.9991 10.409 17.9991 12C17.9991 13.591 17.3672 15.1168 16.2424 16.242M7.75838 16.242C6.63356 15.1168 6.00166 13.591 6.00166 12C6.00166 10.409 6.63356 8.88316 7.75838 7.758"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.80038 4.79999L19.2004 19.2"
        stroke="#EF4E4E"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});
