import { HStack, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  left?: ReactNode;
  right?: ReactNode;
  searchbar?: ReactNode;
}

export function PageActions({ left, right, searchbar }: Props) {
  return (
    <VStack spacing="5" alignItems="stretch" marginY="5">
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" justifyContent="flex-end">
          {left}
        </HStack>
        <HStack alignItems="center" justifyContent="flex-end">
          {right}
        </HStack>
      </HStack>
      <HStack alignItems="center" justifyContent="flex-start">
        {searchbar}
      </HStack>
    </VStack>
  );
}
