/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseManagePlaylistForm_CustomerFragmentDoc } from '../../__generated__/useManagePlaylistForm.graphql';
export type BulkManagePlaylist_CustomerFragment = { __typename: 'Customer', id: string, playlists: Array<{ __typename: 'Playlist', id: string, title: string, size: any }> };

export const BulkManagePlaylist_CustomerFragmentDoc = gql`
    fragment BulkManagePlaylist_customer on Customer {
  id
  ...useManagePlaylistForm_customer
}
    ${UseManagePlaylistForm_CustomerFragmentDoc}`;