export function compareMaybeStrings(
  maybeA: string | null | undefined,
  maybeB: string | null | undefined,
) {
  const a = maybeA ?? undefined;
  const b = maybeB ?? undefined;

  return a === undefined && b === undefined
    ? 0
    : a && b
    ? a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
    : a
    ? -1
    : 1;
}
