import { gql } from '@apollo/client';
import {
  AlertDescription,
  AlertTitle,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { TableInstance } from 'react-table';
import { InfoAlert } from '~components/ui/Alert';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { CustomerUsersTableUserFragment } from './__generated__/CustomerUsersTable.graphql';
import { CustomerUserTableFilter } from './Filtering';
import { CustomerUsersTablePageSizeSelector, CustomerUsersTablePagination } from './Pagination';

interface Props {
  table: TableInstance<CustomerUsersTableUserFragment>;
  isDisabled?: boolean;
}

export function CustomerUsersTable({ table, isDisabled = true }: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = table;

  return (
    <VStack spacing="6" alignItems="stretch">
      <Text color="gray.500">Select users in this table to provide access to this customer.</Text>
      {isDisabled && (
        <InfoAlert mt="6" mb="10">
          <AlertTitle>
            User management is only available for customers connected to an Essential plan
          </AlertTitle>
          <AlertDescription>
            Connect your customer to an Essential plan via the subscriptions section below or the
            subscriptions settings page.
          </AlertDescription>
        </InfoAlert>
      )}
      <CustomerUserTableFilter table={table} />
      <Table {...getTableProps()} variant="simple" width="100%">
        <Thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();

            return (
              <Tr {...headerGroupProps} key={headerGroupProps.key}>
                {headerGroup.headers.map((column) => {
                  const sortProps = column.getSortByToggleProps();
                  const sortColumn = sortProps.onClick;
                  const headerProps = column.getHeaderProps(sortProps);

                  return (
                    <Th
                      {...headerProps}
                      {...(isDisabled ? { opacity: 0.5 } : {})}
                      key={headerProps.key}
                      color={column.isSorted ? 'gray.700' : 'gray.600'}
                      minWidth={`${column.minWidth}px`}
                      maxWidth={`${column.maxWidth}px`}
                      width={column.width}
                      _hover={
                        column.disableSortBy
                          ? {}
                          : {
                              textColor: 'gray.800',
                            }
                      }
                      onClick={sortColumn}
                    >
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flex="1">{column.render('Header')}</Box>
                        {column.canSort && (
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDownIcon display="inherit" />
                              ) : (
                                <ChevronUpIcon display="inherit" />
                              )
                            ) : (
                              <ChevronDownIcon visibility="hidden" />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();

            return (
              <Tr
                {...rowProps}
                key={rowProps.key}
                {...(isDisabled ? { background: 'gray.50' } : { background: 'blue.' })}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  return (
                    <Td
                      {...cellProps}
                      {...(isDisabled ? { opacity: 0.5 } : {})}
                      key={cellProps.key}
                      minWidth={`${cell.column.minWidth}px`}
                      maxWidth={`${cell.column.maxWidth}px`}
                      width={cell.column.width}
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Box display="flex">
        <Box flex="1">
          <CustomerUsersTablePageSizeSelector table={table} />
        </Box>
        <CustomerUsersTablePagination table={table} />
      </Box>
    </VStack>
  );
}

CustomerUsersTable.graphql = {
  fragments: {
    CustomerUsersTable: gql`
      fragment CustomerUsersTableUser on User {
        id
        givenName
        familyName
        email
        roles
      }
    `,
  },
};
