/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type EditSiteModalFragment = { __typename: 'Site', id: string, name: string, address?: string | null };

export type EditSiteMutationVariables = Types.Exact<{
  input: Types.SiteUpdateInput;
}>;


export type EditSiteMutation = { __typename: 'Mutation', siteUpdate: { __typename: 'Site', id: string, name: string, address?: string | null } };

export const EditSiteModalFragmentDoc = gql`
    fragment EditSiteModal on Site {
  id
  name
  address
}
    `;
export const EditSiteDocument = gql`
    mutation EditSite($input: SiteUpdateInput!) {
  siteUpdate(input: $input) {
    id
    name
    address
  }
}
    `;
export type EditSiteMutationFn = ApolloReactCommon.MutationFunction<EditSiteMutation, EditSiteMutationVariables>;

/**
 * __useEditSiteMutation__
 *
 * To run a mutation, you first call `useEditSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSiteMutation, { data, loading, error }] = useEditSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSiteMutation, EditSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditSiteMutation, EditSiteMutationVariables>(EditSiteDocument, options);
      }
export type EditSiteMutationHookResult = ReturnType<typeof useEditSiteMutation>;
export type EditSiteMutationResult = ApolloReactCommon.MutationResult<EditSiteMutation>;
export type EditSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSiteMutation, EditSiteMutationVariables>;