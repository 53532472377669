/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type EditPowerScheduleModal_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, title: string, description?: string | null };

export type PowerScheduleEditMutationVariables = Types.Exact<{
  input: Types.PowerScheduleUpdateInput;
}>;


export type PowerScheduleEditMutation = { __typename: 'Mutation', powerScheduleUpdate: { __typename: 'PowerScheduleUpdatePayload', powerSchedule: { __typename: 'PowerSchedule', id: string, title: string, description?: string | null } } };

export const EditPowerScheduleModal_PowerScheduleFragmentDoc = gql`
    fragment EditPowerScheduleModal_powerSchedule on PowerSchedule {
  id
  title
  description
}
    `;
export const PowerScheduleEditDocument = gql`
    mutation PowerScheduleEdit($input: PowerScheduleUpdateInput!) {
  powerScheduleUpdate(input: $input) {
    powerSchedule {
      id
      ...EditPowerScheduleModal_powerSchedule
    }
  }
}
    ${EditPowerScheduleModal_PowerScheduleFragmentDoc}`;
export type PowerScheduleEditMutationFn = ApolloReactCommon.MutationFunction<PowerScheduleEditMutation, PowerScheduleEditMutationVariables>;

/**
 * __usePowerScheduleEditMutation__
 *
 * To run a mutation, you first call `usePowerScheduleEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePowerScheduleEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [powerScheduleEditMutation, { data, loading, error }] = usePowerScheduleEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePowerScheduleEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PowerScheduleEditMutation, PowerScheduleEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PowerScheduleEditMutation, PowerScheduleEditMutationVariables>(PowerScheduleEditDocument, options);
      }
export type PowerScheduleEditMutationHookResult = ReturnType<typeof usePowerScheduleEditMutation>;
export type PowerScheduleEditMutationResult = ApolloReactCommon.MutationResult<PowerScheduleEditMutation>;
export type PowerScheduleEditMutationOptions = ApolloReactCommon.BaseMutationOptions<PowerScheduleEditMutation, PowerScheduleEditMutationVariables>;