/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRecommendedSettings_DisplayFragmentDoc } from '../../__generated__/useRecommendedSettings.graphql';
import { UseDisplayPresence_DisplayFragmentDoc } from '../../__generated__/useDisplayPresence.graphql';
export type RecommendedSettingsBanner_DisplayFragment = { __typename: 'Display', id: string, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, presence?: { __typename: 'DisplayPresence', connected: boolean, timestamp: any } | null, power?: { __typename: 'PowerState', reported: Types.Power, desired?: Types.Power | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export const RecommendedSettingsBanner_DisplayFragmentDoc = gql`
    fragment RecommendedSettingsBanner_display on Display {
  id
  ...UseRecommendedSettings_display
  ...UseDisplayPresence_display
}
    ${UseRecommendedSettings_DisplayFragmentDoc}
${UseDisplayPresence_DisplayFragmentDoc}`;