/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ResolveAlertsMutationVariables = Types.Exact<{
  input: Types.DisplayAlertBulkResolveInput;
}>;


export type ResolveAlertsMutation = { __typename: 'Mutation', displayAlertBulkResolve: { __typename: 'DisplayAlertBulkResolvePayload', displayAlertIds: Array<string> } };

export type AlertsMenuQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AlertsMenuQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, displayAlerts: Array<{ __typename: 'DisplayAlert', id: string, createdAt: any, message: string, displayId: string, customerHandle: string }> } };

export type AlertsMenu_AlertFragment = { __typename: 'DisplayAlert', id: string, createdAt: any, message: string, displayId: string, customerHandle: string };

export const AlertsMenu_AlertFragmentDoc = gql`
    fragment AlertsMenu_alert on DisplayAlert {
  id
  createdAt
  message
  displayId
  customerHandle
}
    `;
export const ResolveAlertsDocument = gql`
    mutation ResolveAlerts($input: DisplayAlertBulkResolveInput!) {
  displayAlertBulkResolve(input: $input) {
    displayAlertIds
  }
}
    `;
export type ResolveAlertsMutationFn = ApolloReactCommon.MutationFunction<ResolveAlertsMutation, ResolveAlertsMutationVariables>;

/**
 * __useResolveAlertsMutation__
 *
 * To run a mutation, you first call `useResolveAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveAlertsMutation, { data, loading, error }] = useResolveAlertsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveAlertsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResolveAlertsMutation, ResolveAlertsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResolveAlertsMutation, ResolveAlertsMutationVariables>(ResolveAlertsDocument, options);
      }
export type ResolveAlertsMutationHookResult = ReturnType<typeof useResolveAlertsMutation>;
export type ResolveAlertsMutationResult = ApolloReactCommon.MutationResult<ResolveAlertsMutation>;
export type ResolveAlertsMutationOptions = ApolloReactCommon.BaseMutationOptions<ResolveAlertsMutation, ResolveAlertsMutationVariables>;
export const AlertsMenuDocument = gql`
    query AlertsMenu {
  organization {
    id
    displayAlerts {
      id
      ...AlertsMenu_alert
    }
  }
}
    ${AlertsMenu_AlertFragmentDoc}`;

/**
 * __useAlertsMenuQuery__
 *
 * To run a query within a React component, call `useAlertsMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertsMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlertsMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AlertsMenuQuery, AlertsMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AlertsMenuQuery, AlertsMenuQueryVariables>(AlertsMenuDocument, options);
      }
export function useAlertsMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlertsMenuQuery, AlertsMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AlertsMenuQuery, AlertsMenuQueryVariables>(AlertsMenuDocument, options);
        }
export type AlertsMenuQueryHookResult = ReturnType<typeof useAlertsMenuQuery>;
export type AlertsMenuLazyQueryHookResult = ReturnType<typeof useAlertsMenuLazyQuery>;
export type AlertsMenuQueryResult = ApolloReactCommon.QueryResult<AlertsMenuQuery, AlertsMenuQueryVariables>;