/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UsePowerSchedules_DisplayFragmentDoc } from '../../../__generated__/usePowerSchedules.graphql';
export type AlertPowerScheduleSync_DisplayFragment = { __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', isSynced?: boolean | null, schedule?: { __typename: 'PowerSchedule', id: string, title: string } | null, latestJob?: { __typename: 'PowerScheduleUpdateAborted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateCompleted', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateInProgress', id: string, scheduleId?: string | null } | { __typename: 'PowerScheduleUpdateRejected', rejectionCode: string, id: string, scheduleId?: string | null } | null } | null };

export const AlertPowerScheduleSync_DisplayFragmentDoc = gql`
    fragment AlertPowerScheduleSync_display on Display {
  id
  ...UsePowerSchedules_display
}
    ${UsePowerSchedules_DisplayFragmentDoc}`;