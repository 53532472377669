import { createIcon } from '@chakra-ui/react';

export const Logo = createIcon({
  displayName: 'Logo',
  viewBox: '0 0 60 32',
  defaultProps: {
    fill: 'none',
    height: '32',
    width: '60',
  },
  path: (
    <>
      <path
        d="M36.8506 27.4939L37.2629 27.192L37.4653 27.0423C37.7353 25.5563 39.4495 15.8881 37.8335 11.9419C37.588 11.3467 37.0751 10.1012 35.945 9.72698C35.1327 9.45457 34.1682 9.63618 33.1693 10.3123C32.3546 10.8657 31.7177 11.6007 31.0759 12.3345C30.5299 12.9579 29.6182 14.1751 29.6182 14.1751L29.8084 16.5373C30.1949 15.9139 33.3301 10.9393 35.2492 11.5799C35.3987 11.6294 35.543 11.6935 35.6799 11.7713C36.8457 12.445 37.2322 14.0095 37.388 15.828C37.7488 20.021 36.9659 26.5539 36.8506 27.4939Z"
        fill="#47A6FF"
      />
      <path
        d="M55.5905 21.3028C55.2374 21.2447 54.8788 21.227 54.5217 21.25C54.1974 21.2724 53.876 21.3262 53.5621 21.4108C50.4036 22.2538 46.9359 24.1521 46.9359 24.1521C45.4634 24.9546 44.5897 25.4356 43.44 26.286C42.8645 26.7118 42.3896 27.0296 40.7907 28.3487C39.852 29.1242 38.6237 30.4347 37.7794 30.1218C37.0849 29.7795 37.2125 28.9524 37.3291 28.1843C37.3365 28.1315 37.35 28.0615 37.35 28.0615L36.8505 28.3659C36.837 29.8874 36.9647 30.2457 37.5586 30.5022C38.1525 30.7587 38.836 30.3034 38.836 30.3034H38.9096H38.836C43.7259 27.3351 49.5533 22.7851 55.5242 22.9692C56.3918 23.0305 57.3649 23.3937 57.2041 23.984C57.312 23.4552 57.2695 22.9068 57.0814 22.401C56.6998 21.4427 55.6531 21.3114 55.5905 21.3028Z"
        fill="#47A6FF"
      />
      <path
        d="M23.7004 24.4639C23.7004 24.4639 21.1923 28.5697 19.9284 27.9291C19.9284 27.9291 19.8302 27.8727 19.7799 27.9083C19.7715 27.9122 19.7648 27.9192 19.7615 27.9279C19.7407 27.9844 19.9124 28.0972 19.9407 28.1157C20.012 28.1473 20.0865 28.1715 20.1628 28.188C20.37 28.2271 20.5835 28.217 20.7861 28.1586C21.0953 28.0662 21.3813 27.9094 21.6255 27.6984C21.7616 27.5932 21.8919 27.4805 22.0157 27.361C23.2096 26.2345 24.0796 24.859 24.9987 23.5215C26.1215 21.8858 27.1927 20.0341 28.3842 18.4438C28.3265 17.6744 28.3265 17.6744 28.2468 16.6191C26.573 19.6611 25.0281 22.2883 23.7004 24.4639Z"
        fill="#47A6FF"
      />
      <path
        d="M50.4244 19.5535C45.6302 21.0125 41.6078 24.0152 37.6174 26.932C37.5867 26.9528 37.3069 27.1627 36.9155 27.4486C33.4576 29.9665 31.7298 31.2255 31.1261 31.0488C29.3702 30.5347 30.5727 23.8949 29.6659 14.1961C29.1824 9.04238 28.1369 3.08 24.8643 2.07011C23.8348 1.75352 22.5709 1.93513 21.0125 2.84072C15.8895 6.68025 13.2046 12.7702 9.63135 17.9178C7.67538 20.5487 4.24201 27.2093 0.254006 25.4877C0.232385 25.4736 0.207843 25.4646 0.182229 25.4613C0.156616 25.4581 0.130599 25.4607 0.106141 25.469C0.0816833 25.4772 0.0594225 25.4909 0.0410364 25.5091C0.0226503 25.5272 0.00861863 25.5493 0 25.5736C4.09476 28.764 10.0363 19.1609 12.623 15.6698C18.0761 8.30736 21.4702 3.91932 24.1121 4.46905C25.6521 4.78932 27.4694 7.11831 28.2842 16.6343C28.33 17.2544 28.3762 17.8737 28.4228 18.4921C28.6265 20.9843 28.8241 23.4777 29.0364 25.9699C29.2904 28.9591 29.7395 31.0623 30.893 31.4341C31.7777 31.72 32.9066 30.7224 35.854 28.9567C36.6602 28.4744 36.994 28.2683 36.994 28.2683C39.6518 26.6485 42.7134 24.8521 43.4067 24.4569C47.7641 21.9758 55.2677 19.8358 56.8604 21.9795C57.1905 22.4237 57.3279 23.0434 57.2077 23.8814C57.0236 25.1674 56.01 26.0632 55.0137 26.8583C54.7155 27.0964 54.4946 27.3492 54.7597 27.7271C55.5769 28.6634 58.2728 25.7932 58.6765 25.0275C61.6865 19.3106 54.502 17.8381 50.4244 19.5535Z"
        fill="#0A3761"
      />
    </>
  ),
});
