/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRecommendedSettings_DisplayFragmentDoc } from '../../__generated__/useRecommendedSettings.graphql';
import { StepMetadata_DisplayFragmentDoc } from './StepMetadata.graphql';
import { DisplayPreview_Claimable_DisplayFragmentDoc } from './DisplayPreview.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type StepClaim_CustomerFragment = { __typename: 'Customer', id: string };

export type StepClaim_DisplayFragment = { __typename: 'Display', id: string, serialNumber: string, commercialTypeNumber: string, platform: { __typename: 'DisplayPlatform', type: string }, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, timeZone?: { __typename: 'TimeZoneState', reported: string, supportedValues: Array<string> } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export type StepClaim_Claimable_DisplayFragment = { __typename: 'ClaimableDisplay', id: string, commercialTypeNumber: string, serialNumber: string, platformType: string };

export type GetDisplayByCodeQueryVariables = Types.Exact<{
  displayCode: Types.Scalars['String'];
}>;


export type GetDisplayByCodeQuery = { __typename: 'Query', displayByCode?: { __typename: 'ClaimableDisplay', id: string, commercialTypeNumber: string, serialNumber: string, platformType: string } | null };

export type ClaimDisplayMutationVariables = Types.Exact<{
  input: Types.DisplayClaimInput;
}>;


export type ClaimDisplayMutation = { __typename: 'Mutation', displayClaim: { __typename: 'DisplayClaimPayload', customer: { __typename: 'Customer', id: string }, display: { __typename: 'Display', id: string, serialNumber: string, commercialTypeNumber: string, platform: { __typename: 'DisplayPlatform', type: string }, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, timeZone?: { __typename: 'TimeZoneState', reported: string, supportedValues: Array<string> } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null } } };

export const StepClaim_CustomerFragmentDoc = gql`
    fragment StepClaim_customer on Customer {
  id
}
    `;
export const StepClaim_DisplayFragmentDoc = gql`
    fragment StepClaim_display on Display {
  id
  serialNumber
  commercialTypeNumber
  ...UseRecommendedSettings_display
  ...StepMetadata_display
  platform {
    type
  }
}
    ${UseRecommendedSettings_DisplayFragmentDoc}
${StepMetadata_DisplayFragmentDoc}`;
export const StepClaim_Claimable_DisplayFragmentDoc = gql`
    fragment StepClaim_claimable_display on ClaimableDisplay {
  id
  ...DisplayPreview_claimable_display
}
    ${DisplayPreview_Claimable_DisplayFragmentDoc}`;
export const GetDisplayByCodeDocument = gql`
    query GetDisplayByCode($displayCode: String!) {
  displayByCode(displayCode: $displayCode) {
    id
    ...StepClaim_claimable_display
  }
}
    ${StepClaim_Claimable_DisplayFragmentDoc}`;

/**
 * __useGetDisplayByCodeQuery__
 *
 * To run a query within a React component, call `useGetDisplayByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayByCodeQuery({
 *   variables: {
 *      displayCode: // value for 'displayCode'
 *   },
 * });
 */
export function useGetDisplayByCodeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDisplayByCodeQuery, GetDisplayByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayByCodeQuery, GetDisplayByCodeQueryVariables>(GetDisplayByCodeDocument, options);
      }
export function useGetDisplayByCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayByCodeQuery, GetDisplayByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayByCodeQuery, GetDisplayByCodeQueryVariables>(GetDisplayByCodeDocument, options);
        }
export type GetDisplayByCodeQueryHookResult = ReturnType<typeof useGetDisplayByCodeQuery>;
export type GetDisplayByCodeLazyQueryHookResult = ReturnType<typeof useGetDisplayByCodeLazyQuery>;
export type GetDisplayByCodeQueryResult = ApolloReactCommon.QueryResult<GetDisplayByCodeQuery, GetDisplayByCodeQueryVariables>;
export const ClaimDisplayDocument = gql`
    mutation ClaimDisplay($input: DisplayClaimInput!) {
  displayClaim(input: $input) {
    customer {
      id
    }
    display {
      id
      ...StepClaim_display
    }
  }
}
    ${StepClaim_DisplayFragmentDoc}`;
export type ClaimDisplayMutationFn = ApolloReactCommon.MutationFunction<ClaimDisplayMutation, ClaimDisplayMutationVariables>;

/**
 * __useClaimDisplayMutation__
 *
 * To run a mutation, you first call `useClaimDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimDisplayMutation, { data, loading, error }] = useClaimDisplayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimDisplayMutation, ClaimDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ClaimDisplayMutation, ClaimDisplayMutationVariables>(ClaimDisplayDocument, options);
      }
export type ClaimDisplayMutationHookResult = ReturnType<typeof useClaimDisplayMutation>;
export type ClaimDisplayMutationResult = ApolloReactCommon.MutationResult<ClaimDisplayMutation>;
export type ClaimDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimDisplayMutation, ClaimDisplayMutationVariables>;