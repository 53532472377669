import { Box, Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuthentication } from '~auth/useAuthentication';

export function LogoutPage() {
  const { logout } = useAuthentication();

  useEffect(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return (
    <Box display="flex" flexDirection="column">
      <Button variant="solid" colorScheme="blue">
        Click me
      </Button>
    </Box>
  );
}
