import { createIcon } from '@chakra-ui/react';

export const ScreenshotDisabledIllustration = createIcon({
  displayName: 'ScreenshotDisabledIllustration',
  viewBox: '0 0 140 140',
  defaultProps: {
    fill: 'none',
    width: '140px',
    height: '140px',
  },
  path: (
    <>
      <circle cx="70" cy="70" r="70" fill="#F1F8FE" />
      <g filter="url(#filter0_dd_8379_14301)">
        <path
          d="M20.2998 41.5677C20.2998 38.7128 22.582 36.3984 25.3972 36.3984H114.602C117.418 36.3984 119.7 38.7128 119.7 41.5677V94.5523C119.7 97.4072 117.418 99.7215 114.602 99.7215H25.3972C22.582 99.7215 20.2998 97.4072 20.2998 94.5523V41.5677Z"
          fill="white"
        />
        <path
          d="M45.787 99.7215H96.1242V101.014C96.1242 102.441 94.9831 103.598 93.5755 103.598H48.3357C46.9281 103.598 45.787 102.441 45.787 101.014V99.7215Z"
          fill="#0F5291"
        />
        <path
          d="M45.787 98.2215H44.287H25.3972C23.4301 98.2215 21.7998 96.5986 21.7998 94.5523V41.5677C21.7998 39.5214 23.4301 37.8984 25.3972 37.8984H114.602C116.569 37.8984 118.2 39.5214 118.2 41.5677V94.5523C118.2 96.5986 116.569 98.2215 114.602 98.2215H97.6242H96.1242H45.787ZM47.3059 101.222H94.6053C94.5103 101.734 94.0704 102.098 93.5755 102.098H48.3357C47.8408 102.098 47.4009 101.734 47.3059 101.222Z"
          stroke="#627D98"
          strokeWidth="3"
        />
      </g>
      <mask id="mask0_8379_14301" maskUnits="userSpaceOnUse" x="23" y="39" width="94" height="58">
        <path
          d="M23.7969 41.3951C23.7969 40.5665 24.4687 39.8949 25.2973 39.8951L114.641 39.9208C115.469 39.921 116.141 40.5925 116.141 41.4208V94.7198C116.141 95.5482 115.469 96.2198 114.641 96.2198L25.2969 96.2198C24.4684 96.2198 23.7969 95.5482 23.7969 94.7198V41.3951Z"
          fill="#D9D9D9"
          stroke="#627D98"
        />
      </mask>
      <g mask="url(#mask0_8379_14301)">
        <path
          d="M47.1817 61.0321L23.2993 85.1858L23.2993 96.7809H117.6L117.6 96.612L68.1673 59.4879C61.7699 54.6833 52.8069 55.3429 47.1817 61.0321Z"
          fill="#F0F4F8"
        />
        <path
          d="M82.8332 72.5664C82.5237 72.5664 82.227 72.6893 82.0082 72.9081C81.7894 73.1269 81.6665 73.4237 81.6665 73.7331V76.0664C81.6665 76.9947 81.2977 77.8849 80.6414 78.5413C79.985 79.1976 79.0948 79.5664 78.1665 79.5664H75.8332C75.5237 79.5664 75.227 79.6893 75.0082 79.9081C74.7894 80.1269 74.6665 80.4236 74.6665 80.7331C74.6665 81.0425 74.7894 81.3392 75.0082 81.558C75.227 81.7768 75.5237 81.8997 75.8332 81.8997H78.1665C79.713 81.8979 81.1957 81.2827 82.2892 80.1891C83.3828 79.0956 83.998 77.6129 83.9998 76.0664V73.7331C83.9998 73.4237 83.8769 73.1269 83.6581 72.9081C83.4393 72.6893 83.1426 72.5664 82.8332 72.5664Z"
          fill="#0F5291"
        />
        <path
          d="M57.1667 63.2318C57.4761 63.2318 57.7728 63.1089 57.9916 62.8901C58.2104 62.6713 58.3333 62.3745 58.3333 62.0651V59.7318C58.3333 58.8035 58.7021 57.9133 59.3585 57.2569C60.0148 56.6005 60.9051 56.2318 61.8333 56.2318H64.1667C64.4761 56.2318 64.7728 56.1089 64.9916 55.8901C65.2104 55.6713 65.3333 55.3745 65.3333 55.0651C65.3333 54.7557 65.2104 54.4589 64.9916 54.2401C64.7728 54.0214 64.4761 53.8984 64.1667 53.8984H61.8333C60.2868 53.9003 58.8042 54.5155 57.7106 55.609C56.617 56.7026 56.0019 58.1852 56 59.7318V62.0651C56 62.3745 56.1229 62.6713 56.3417 62.8901C56.5605 63.1089 56.8572 63.2318 57.1667 63.2318Z"
          fill="#0F5291"
        />
        <path
          d="M64.1667 79.5664H61.8333C60.9051 79.5664 60.0148 79.1976 59.3585 78.5413C58.7021 77.8849 58.3333 76.9947 58.3333 76.0664V73.7331C58.3333 73.4237 58.2104 73.1269 57.9916 72.9081C57.7728 72.6893 57.4761 72.5664 57.1667 72.5664C56.8572 72.5664 56.5605 72.6893 56.3417 72.9081C56.1229 73.1269 56 73.4237 56 73.7331V76.0664C56.0019 77.6129 56.617 79.0956 57.7106 80.1891C58.8042 81.2827 60.2868 81.8979 61.8333 81.8997H64.1667C64.4761 81.8997 64.7728 81.7768 64.9916 81.558C65.2104 81.3392 65.3333 81.0425 65.3333 80.7331C65.3333 80.4236 65.2104 80.1269 64.9916 79.9081C64.7728 79.6893 64.4761 79.5664 64.1667 79.5664Z"
          fill="#0F5291"
        />
        <path
          d="M78.1665 53.8984H75.8332C75.5237 53.8984 75.227 54.0214 75.0082 54.2401C74.7894 54.4589 74.6665 54.7557 74.6665 55.0651C74.6665 55.3745 74.7894 55.6713 75.0082 55.8901C75.227 56.1089 75.5237 56.2318 75.8332 56.2318H78.1665C79.0948 56.2318 79.985 56.6005 80.6414 57.2569C81.2977 57.9133 81.6665 58.8035 81.6665 59.7318V62.0651C81.6665 62.3745 81.7894 62.6713 82.0082 62.8901C82.227 63.1089 82.5237 63.2318 82.8332 63.2318C83.1426 63.2318 83.4393 63.1089 83.6581 62.8901C83.8769 62.6713 83.9998 62.3745 83.9998 62.0651V59.7318C83.998 58.1852 83.3828 56.7026 82.2892 55.609C81.1957 54.5155 79.713 53.9003 78.1665 53.8984V53.8984Z"
          fill="#0F5291"
        />
        <path
          d="M102.2 60.8984C103.585 60.8984 104.938 60.4879 106.089 59.7187C107.24 58.9496 108.138 57.8563 108.667 56.5772C109.197 55.2981 109.336 53.8907 109.066 52.5328C108.796 51.1749 108.129 49.9277 107.15 48.9487C106.171 47.9697 104.924 47.303 103.566 47.0329C102.208 46.7628 100.8 46.9015 99.5214 47.4313C98.2423 47.9611 97.1491 48.8583 96.3799 50.0094C95.6107 51.1606 95.2002 52.514 95.2002 53.8984C95.2002 55.755 95.9377 57.5354 97.2504 58.8482C98.5632 60.1609 100.344 60.8984 102.2 60.8984ZM102.2 50.8984C102.794 50.8984 103.374 51.0744 103.867 51.404C104.36 51.7337 104.745 52.2022 104.972 52.7504C105.199 53.2986 105.258 53.9018 105.143 54.4837C105.027 55.0657 104.741 55.6002 104.322 56.0198C103.902 56.4393 103.367 56.725 102.785 56.8408C102.204 56.9566 101.6 56.8971 101.052 56.6701C100.504 56.443 100.035 56.0585 99.7058 55.5652C99.3761 55.0718 99.2002 54.4918 99.2002 53.8984C99.2002 53.1028 99.5163 52.3397 100.079 51.7771C100.641 51.2145 101.405 50.8984 102.2 50.8984Z"
          fill="#F0F4F8"
        />
        <path
          d="M74.8492 62.9497L64.9497 72.8492M64.9497 62.9497L74.8492 72.8492"
          stroke="#0F5291"
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_8379_14301"
          x="7.02946"
          y="34.1867"
          width="125.941"
          height="93.7399"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8379_14301"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8379_14301" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8379_14301"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8379_14301"
            result="effect2_dropShadow_8379_14301"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8379_14301"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
