import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function MasterServicesAgreementPage() {
  return (
    <PolicyPage title="Master Services Agreement" heading="PPDS Wave Master Services Agreement">
      <article>
        <p>
          This Master Services Agreement (&ldquo;MSA&rdquo;) is made between MMD-Monitors &amp;
          Displays Nederland B.V., a limited liability company registered in the Netherlands
          (hereinafter referred to as &ldquo;PPDS&rdquo;), and the contracting party identified on
          the Order Form (hereinafter referred to as &ldquo;Customer&rdquo;), together referred to
          as the &ldquo;Parties&rdquo; and each individually as a &ldquo;Party&rdquo;, as of the
          date of last signature of the Order Form (the &ldquo;MSA Effective Date&rdquo;). The
          Parties hereby agree to the terms and conditions of this MSA, including any specific
          services terms, product details and any applicable license and/or subscription terms will
          be set forth in applicable Service Schedule(s) and Order Form(s), each of which become
          binding on the Parties and are incorporated into this MSA upon execution of an Order Form.
          Each Order Form is governed by and incorporates the following documents in effect as of
          the effective date of the applicable Order Form, collectively referred to as the
          &ldquo;Agreement&rdquo;, that consists of:
        </p>
        <ol>
          <li>the Order Form;</li>
          <li>Service Schedule(s); and</li>
          <li>this MSA.</li>
        </ol>
        <p>
          The applicable Service Schedule(s) is determined by the PPDS Service(s) purchased on the
          Order Form. In the event of a conflict, the order of precedence is as set out above in
          descending order of control.
        </p>

        <h2>1. DEFINITIONS</h2>
        <p>
          &ldquo;Organization&rdquo; means a unique account established by Customer to enable its
          Authorized Users to access and use the PPDS Service. Creation of an Organization requires
          a registered PPDS account.
        </p>
        <p>
          &ldquo;Account Owner&rdquo; is an Authorized User who is assigned and expressly authorized
          by Customer as its agent to manage Customer’s Organization, including, without limitation,
          to configure administration settings, assign access and use authorizations, request and
          purchase different or additional services, provide usage and performance reports, assist
          in third-party product integrations, and to receive privacy disclosures. At least one (1)
          Account Owner is required for each Organization. Customer may appoint an employee or a
          third-party business partner or contractor to act as its Account Owner. Changes to or
          removal of the Account Owner shall be requested in writing to PPDS.
        </p>
        <p>
          &ldquo;Affiliate&rdquo; means any entity controlling, controlled by, or under common
          control with one of the Parties. Control shall mean the direct or indirect possession of
          more than fifty percent (50%) of the voting interests of the subject entity. Any legal
          entity will be considered a Party’s Affiliate as long as that interest is maintained.
        </p>
        <p>
          &ldquo;Authorized User&rdquo; means one individual natural person, whether an employee,
          business partner, contractor, agent or customer of Customer or its Affiliates who is
          registered by Customer in Customer’s Organization to use the PPDS Services. An Authorized
          User must be identified by a unique email address and user first and last name, and two or
          more persons may not use the PPDS Services as the same Authorized User. Different levels
          of permissions and authorization are available for Authorized Users. An actual list of
          user roles and their permissions can be found on{' '}
          <a href="https://docs.wave.ppds.com">https://docs.wave.ppds.com</a>. The PPDS Service is a
          multi-tenant solution and Customer shall be solely responsible for managing the Authorized
          Users in its Organization.
        </p>
        <p>
          &ldquo;Confidential Information&rdquo; means: (a) for PPDS and its Affiliates, the PPDS
          Services, Documentation and other related technical information, security policies and
          processes, product roadmaps, and pricing; (b) for Customer and its Affiliates, Customer
          Data; (c) any other information of a Party or its Affiliates that is disclosed in writing
          or orally and is designated as confidential or proprietary at the time of disclosure to
          the Party, including its Affiliates, receiving Confidential Information
          (&ldquo;Recipient&rdquo;) (and, in the case of oral disclosures, summarized in writing and
          delivered to the Recipient within thirty (30) days of the initial disclosure), or that due
          to the nature of the information the Recipient should reasonably understand it to be
          confidential information of the disclosing Party; and (d) the terms and conditions of the
          Agreement between the Parties. Confidential Information does not include any information
          that: (i) was or becomes generally known to the public through no fault or breach of the
          Agreement by the Recipient; (ii) was rightfully in the Recipient’s possession at the time
          of disclosure without restriction on use or disclosure; (iii) was independently developed
          by the Recipient without use of or reference to the disclosing Party&rsquo;s Confidential
          Information; or (iv) was rightfully obtained by the Recipient from a third party not under
          a duty of confidentiality and without restriction on use or disclosure.
        </p>
        <p>
          &ldquo;Customer Data&rdquo; means any content, materials, data and information that
          Customer or its Authorized Users enter into the PPDS Services, including, but not limited
          to, any Customer personal data and information contained in materials. Customer Data does
          not include any component of the PPDS Services or material provided by or on behalf of
          PPDS.
        </p>
        <p>
          &ldquo;Documentation&rdquo; means the then-current technical and functional documentation
          for the PPDS Services as made generally available by PPDS.
        </p>
        <p>
          &ldquo;PPDS Service(s)&rdquo; means the services provided by PPDS under an Order Form, and
          may include software, source code, or other technology licensed to PPDS from third parties
          and embedded into the services that PPDS provides to Customer. Notwithstanding the
          foregoing, PPDS Services do not include Third-Party Services (defined below).
        </p>
        <p>
          &ldquo;Indemnified Party(ies)&rdquo; means the Party (whether PPDS or Customer) being
          indemnified under Section 9 (Third-Party Claims), including its employees, directors,
          agents, and representatives.
        </p>
        <p>
          &ldquo;Indemnifying Party(ies)&rdquo; means the Party (whether PPDS or Customer) that is
          providing indemnification under Section 9 (Third-Party Claims).
        </p>
        <p>
          &ldquo;Order End Date&rdquo; means the end date for provision of a respective PPDS Service
          specified in a corresponding Order Form.
        </p>
        <p>
          &ldquo;Order Form&rdquo; means the order form provided by PPDS that sets forth the pricing
          and the PPDS Services selected by Customer.
        </p>
        <p>
          &ldquo;Order Start Date&rdquo; means the start date for provision of a respective PPDS
          Service specified in a corresponding Order Form.
        </p>
        <p>
          &ldquo;Professional Services&rdquo; means any integration, consulting, architecture,
          training, transition, configuration, administration, and similar ancillary PPDS Services
          that are set forth in an Order Form.
        </p>
        <p>
          &ldquo;Service Schedule&rdquo; means the service-specific terms and conditions applicable
          to the PPDS Service(s).
        </p>
        <p>
          &ldquo;System&rdquo; means the software systems and programs, the communication and
          network facilities, and the hardware and equipment used by PPDS or its agents to make
          available the PPDS Services via the Internet.
        </p>
        <p>
          &quot;Third-Party Services&quot; means services, software, products, applications,
          integrations, and other features or offerings that are provided by Customer or obtained by
          Customer from a third party.
        </p>

        <h2>2. USAGE AND ACCESS RIGHTS</h2>
        <h3>2.1 Right to Use</h3>
        <p>
          PPDS will provide the PPDS Services to Customer as set forth in the Order Form. Subject to
          the terms and conditions of the Agreement, PPDS grants to Customer a worldwide, limited,
          non-exclusive, non-transferable right and license during the Term, solely for its and its
          Affiliates’ internal business purposes, and in accordance with the Documentation, to: (a)
          access and use the PPDS Services; (b) implement, configure, and through its Account Owner,
          permit its Authorized Users to access and use the PPDS Services; and (c) access and use
          the Documentation. Customer will ensure that its Affiliates and all Authorized Users using
          the PPDS Services under its Organization comply with all of Customer’s obligations under
          the Agreement, and Customer is responsible for their acts and omissions relating to the
          Agreement as though they were those of Customer. A Customer Affiliate may enter into an
          Order Form directly with PPDS under this MSA by a mutually executed Order Form that
          references this MSA. In such event: (i) the Customer Affiliate will be bound by this MSA
          and will be fully responsible for its liabilities and obligations under the applicable
          Order Form; and (ii) all references to &ldquo;Customer&rdquo; in the Agreement will be
          deemed references to the Customer Affiliate set forth on the Order Form for purposes of
          defining the rights and obligations of the Parties hereunder.
        </p>
        <h3>2.2 Restrictions</h3>
        <p>
          Customer shall not, and shall not permit its Authorized Users or others under its control
          to, do the following with respect to the PPDS Services:
        </p>
        <p>
          (a) use the PPDS Services, or allow access to it, in a manner that circumvents contractual
          usage restrictions or that exceeds Customer’s authorized use set forth in the Agreement,
          including the applicable Order Form;
        </p>
        <p>
          (b) license, sub-license, sell, re-sell, rent, lease, transfer, distribute, time share or
          otherwise make any portion of the PPDS Services or Documentation available for access by
          third parties unless access is provided to customers of Customer in its ordinary course of
          business and as otherwise expressly provided in the Agreement;
        </p>
        <p>
          (c) access or use the PPDS Services or Documentation for the purpose of: (i) developing or
          operating products or services intended to be offered to third parties in competition with
          the PPDS Services, or (ii) allowing access to its Organization by a direct competitor of
          PPDS;
        </p>
        <p>
          (d) reverse engineer, decompile, disassemble, or copy any of the PPDS Services or
          technologies, or otherwise attempt to derive source code or other trade secrets or create
          any derivative works from or about any of the PPDS Services or technologies, or use the
          machine-learning algorithm output generated from the PPDS Services to train, calibrate, or
          validate, in whole or in part, any other systems, programs or platforms, or for
          benchmarking, software-development, or other competitive purposes, except pursuant to
          Customer’s non-waivable rights under applicable law, without PPDS’s written consent;
        </p>
        <p>
          (e) use the PPDS Services or Documentation in a way that: (i) violates or infringes upon
          the rights of a third party, including those pertaining to: contract, intellectual
          property, privacy, or publicity; or (ii) effects or facilitates the storage or
          transmission of libelous, tortious, or otherwise unlawful material including, but not
          limited to, material that is harassing, threatening, or obscene;
        </p>
        <p>
          (f) use the PPDS Services to create, use, send, store, or run viruses or other harmful
          computer code, files, scripts, agents, or other programs, or circumvent or disclose the
          user authentication or security of the PPDS Services or any host, network, or account
          related thereto or use any aspect of the PPDS Services components other than those
          specifically identified in an Order Form, even if technically possible; or
        </p>
        <p>
          (g) use, or allow the use of, the PPDS Services in violation of Section 13.5 (Trade
          Restrictions).
        </p>
        <h3>2.3 Suspension of Access</h3>
        <p>
          PPDS may suspend any use of the PPDS Services or remove or disable any Organization or
          content that PPDS reasonably and in good faith believes violates Section 2.2 above. PPDS
          will use commercially reasonable efforts to notify Customer prior to any such suspension
          or disablement, unless PPDS reasonably believes that: (a) it is prohibited from doing so
          under applicable law or under legal process (such as court or government administrative
          agency processes, orders, mandates, and the like); or (b) it is necessary to delay notice
          in order to prevent imminent harm to the PPDS Services or a third party. Under
          circumstances where notice is delayed, PPDS will provide notice if and when the related
          restrictions in the previous sentence no longer apply.
        </p>
        <h3>2.4 Third-Party Services</h3>
        <p>
          Customer may choose to obtain Third-Party Services from third parties and/or PPDS (for
          example, through a reseller arrangement or otherwise). Any acquisition by Customer of
          Third-Party Services is solely between Customer and the applicable Third-Party Service
          provider and PPDS does not warrant, support, or assume any liability or other obligation
          with respect to such Third-Party Services, unless expressly provided otherwise in the
          Order Form or the Agreement. In the event Customer chooses to integrate or interoperate
          Third-Party Services with PPDS Services in a manner that requires PPDS or the PPDS
          Services to exchange Customer Data with such Third-Party Service or Third-Party Service
          provider, Customer: (a) grants PPDS permission to allow the Third-Party Service and
          Third-Party Service provider to access Customer Data and information about Customer’s
          usage of the Third-Party Services as appropriate and necessary to enable the
          interoperation of that Third-Party Service with the PPDS Services; (b) acknowledges that
          any exchange of data between Customer and any Third-Party Service is solely between
          Customer and the Third-Party Service provider and is subject to the Third-Party Service
          provider’s terms and conditions governing the use and provision of such Third-Party
          Service (the presentation and manner of acceptance of which is controlled solely by the
          Third-Party Service provider); and (c) agrees that PPDS is not responsible for any
          disclosure, modification or deletion of Customer Data resulting from access to such data
          by Third-Party Services and Third-Party Service providers.
        </p>

        <h2>3. OWNERSHIP</h2>
        <h3>3.1 Customer Data</h3>
        <p>
          Customer Data processed using the PPDS Services is and will remain, as between Customer
          and PPDS, owned by Customer. Customer hereby grants PPDS the right to process, transmit,
          store and disclose Customer Data in order to provide the PPDS Services to Customer, solely
          in accordance with the terms of the Agreement and subject to the terms of Section 11.2
          (Required Disclosure) below.
        </p>
        <h3>3.2 PPDS Services</h3>
        <p>
          PPDS, its Affiliates, or its licensors own all right, title, and interest in and to any
          and all copyrights, trademark rights, patent rights, database rights, and other
          intellectual property or other rights in and to the PPDS Services and Documentation, any
          improvements, design contributions, or derivative works thereto, and any knowledge or
          processes related thereto (including any machine learning algorithms output from the PPDS
          Services) and/or provided hereunder.
        </p>
        <h3>3.3 Professional Services</h3>
        <p>
          Unless otherwise specified in the applicable Order Form, all deliverables provided by or
          for PPDS in the performance of Professional Services, excluding Customer Data and Customer
          Confidential Information, are owned by PPDS and constitute part of the Professional
          Service(s) under the Agreement. Effective only as of final payment by Customer to PPDS of
          all amounts required by an Order Form, and subject to the terms and conditions of the
          Agreement, PPDS grants to Customer a non-exclusive, for the duration of legal protection,
          worldwide, royalty- free license to use deliverables created for Customer during
          performance of Professional Services (&ldquo;Work Product&rdquo;) provided to Customer
          under the Order Form, solely for purposes of Customer’s internal business operations only.
          This license includes permission to make copies of the provided Work Product for such
          internal use but not permission to distribute the Work Product or any copies of them.
        </p>
        <h3>3.4 Feedback</h3>
        <p>
          PPDS encourages Customer to provide suggestions, proposals, ideas, recommendations, or
          other feedback regarding improvements to PPDS Services and related resources
          (&ldquo;Feedback&rdquo;). To the extent Customer provides Feedback, Customer grants to
          PPDS and its Affiliates a royalty-free, fully paid, sub-licensable, transferable
          (notwithstanding Section 13.2 (Assignability)), non-exclusive, irrevocable, perpetual,
          worldwide right and license to make, use, sell, offer for sale, import, and otherwise
          exploit Feedback (including by incorporation of such feedback into the PPDS Services)
          without restriction. Customer shall ensure that: (a) Feedback does not identify Customer,
          its Affiliates, or Authorized Users, or include any Customer Data; and (b) Customer has
          obtained requisite authorization from any Authorized User or other third party to grant
          the license described herein. For the avoidance of doubt, Feedback does not constitute
          Customer Confidential Information.
        </p>

        <h2>4. SECURITY AND CUSTOMER DATA</h2>
        <h3>4.1 Security</h3>
        <p>
          PPDS will use commercially reasonable industry standard security technologies in providing
          the PPDS Services. PPDS has implemented and will maintain appropriate technical and
          organizational measures, including information security policies and safeguards, designed
          to preserve the security, integrity, and confidentiality of Customer Data and Customer
          personal data and to protect against unauthorized or unlawful disclosure or corruption of
          or access to such data in accordance with the Security Schedule for PPDS Services attached
          to the MSA as Schedule 1.
        </p>
        <p>
          Additional or differing security obligations, if any, will be expressly set forth in the
          applicable Service Schedule, Order Form, or separate written agreement between the
          Parties.
        </p>
        <h3>4.2 Customer Data</h3>
        <p>
          Customer is responsible for Customer Data (including Customer personal data) as entered
          into, supplied or used by Customer and its Authorized Users in the PPDS Services. Further,
          Customer is solely responsible for determining the suitability of the PPDS Services for
          Customer&rsquo;s business and complying with any applicable data privacy and protection
          regulations, laws or conventions applicable to Customer Data and Customer’s use of the
          PPDS Services. Customer grants to PPDS the non-exclusive right to process Customer Data
          (including personal data) in accordance with the Data Protection Schedule for PPDS
          Services attached to the MSA as Schedule 2, for the sole purpose of and only to the extent
          necessary for PPDS: (a) to provide the PPDS Services; (b) to verify Customer’s compliance
          with the restrictions set forth in Section 2.2 (Restrictions) if PPDS has a reasonable
          belief of Customer’s non-compliance; and (c) as otherwise set forth in the Agreement.
        </p>
        <h3>4.3 Usage Data</h3>
        <p>
          PPDS may collect and use data, information, or insights generated or derived from the use
          of the PPDS Services (&ldquo;Usage Data&rdquo;) for its business purposes, including
          industry analysis, benchmarking, analytics, marketing, and developing, training and
          improving its products and services. Before doing so, PPDS will deidentify and anonymize
          all Usage Data in such manner that does not allow for the identification of Customer Data,
          or Customer&rsquo;s Confidential Information, and will disclose such Usage Data in
          aggregate form only.
        </p>

        <h2>5. PAYMENT OF FEES</h2>
        <h3>5.1 Fees</h3>
        <p>
          Except as expressly set forth in the applicable Order Form, Customer will pay all fees set
          forth in the Order Form in accordance with the following: (a) PPDS Services fees are
          invoiced annually in advance; (b) the first invoice will coincide with the Order Start
          Date of an Order Form; (c) payment will be due within fourteen (14) days from the date of
          the invoice; and (d) all amounts will be denominated and payable in the currency specified
          in the Order Form. Unless otherwise agreed to by the Parties and expressly noted in the
          Order Form, invoices will be sent to Customer via email. Upon execution by Customer and
          PPDS, each Order Form is non-cancellable and non-refundable except as provided in the
          Agreement, and the Term as set forth in the Order Form for PPDS Services is a continuous
          and non-divisible commitment for the full duration of the Term regardless of any invoice
          schedule. Customer may withhold from payment any charge or amount disputed by Customer in
          good faith pending resolution of such dispute, provided that Customer: (i) notifies PPDS
          of the dispute by e-mail to{' '}
          <a href="mailto:Receivable.EMEA@tpv-tech.com">Receivable.EMEA@tpv-tech.com</a> prior to
          the date such payment is due, specifying in such notice (A) the amount in dispute, and (B)
          the reason for the dispute set out in sufficient detail to facilitate investigation by
          PPDS and resolution by the Parties; (ii) makes timely payment of all undisputed charges
          and amounts; (iii) works diligently with PPDS to resolve the dispute promptly; and (iv)
          pays all amounts that are determined to be payable by resolution of the dispute (by
          adversarial proceedings, agreement or otherwise) within thirty (30) days following such
          resolution.
        </p>
        <h3>5.2 Purchase Orders</h3>
        <p>
          If Customer issues a purchase order, then it shall be for the full amount set forth in the
          applicable Order Form, and PPDS hereby rejects any additional or conflicting terms
          appearing in a purchase order or any other ordering materials submitted by Customer, and
          conditions assent solely based on the terms and conditions of the Agreement as offered by
          PPDS. Upon request, PPDS shall reference the purchase order number on its invoices,
          provided, however, that Customer acknowledges that it is Customer’s responsibility to
          provide the corresponding purchase order information (including a purchase order number)
          to PPDS upon the signing of any Order Form. Customer agrees that a failure to provide PPDS
          with the corresponding purchase order shall not relieve Customer of its obligations to
          provide payment to PPDS pursuant to Section 5.1 (Fees) above.
        </p>
        <h3>5.3 Offsets; Late Charges; Attorneys’ Fees</h3>
        <p>
          If PPDS owes any amounts to Customer that are not derived from the Agreement, such amounts
          will not be withheld or offset against any invoice issued under the Agreement. PPDS may
          assess late charges equal to the lesser of one and one-half percent (1.5%) of the unpaid
          balance per month or the highest rate permitted by applicable law. Customer will be
          responsible for any reasonable attorneys’ fees, costs, and expenses incurred by PPDS to
          collect any amounts that are not paid when due. If Customer fails to timely pay any
          amounts due under the Agreement, then without limitation of any of its other rights or
          remedies, PPDS may, upon prior written notice to Customer, suspend performance of those
          PPDS Services until PPDS receives all past due amounts from Customer.
        </p>

        <h2>6. TAXES</h2>
        <h3>6.1 Tax Responsibility</h3>
        <p>
          All payments required by the Agreement are stated exclusive of all taxes, duties, levies,
          imposts, fines or similar governmental assessments, including sales and use taxes,
          value-added taxes (&ldquo;VAT&rdquo;), goods and services taxes (&ldquo;GST&rdquo;),
          excise, business, service, and similar transactional taxes imposed by any jurisdiction and
          the interest and penalties thereon (collectively, &ldquo;Taxes&rdquo;). Without limiting
          the foregoing, Customer shall be responsible for and bear Taxes associated with its
          purchase of, payment for, access to or use of the PPDS Services. Taxes shall not be
          deducted from the payments to PPDS, except as required by law, in which case Customer
          shall increase the amount payable as necessary so that after making all required
          deductions and withholdings, PPDS receives and retains (free from any Tax liability) an
          amount equal to the amount it would have received had no such deductions or withholdings
          been made. If Customer claims tax exempt status for amounts due under the Agreement, it
          shall provide PPDS with a valid tax exemption certificate (authorized by the applicable
          governmental authority) to avoid application of Taxes to Customer’s invoice. Each Party is
          responsible for and shall bear Taxes imposed on its net income. Customer hereby confirms
          that PPDS can rely on the name and address set forth in the Order Form(s) Customer places
          directly with PPDS as being the place of supply for Tax purposes. The Parties’ obligations
          under this Section 6.1 (Tax Responsibility) shall survive the termination or expiration of
          the Agreement.
        </p>
        <h3>6.2 Invoicing Taxes</h3>
        <p>
          If PPDS is required to invoice or collect Taxes associated with Customer’s purchase of,
          payment for, access to or use of the PPDS Services, PPDS will issue an invoice to Customer
          including the amount of those Taxes, itemized where required by law. If applicable,
          Customer shall provide to PPDS its VAT, GST or similar tax identification number(s) on the
          Order Form. Customer shall use the ordered PPDS Services for Customer&rsquo;s business use
          in the locations set forth on the Order Form in accordance with the provided VAT or GST
          identification number(s).
        </p>

        <h2>7. TERM AND TERMINATION</h2>
        <h3>7.1 Term</h3>
        <p>
          The term of an Order Form and any associated Service Schedule(s) is the period of time
          that begins on the Order Start Date and, unless terminated sooner as provided herein, will
          continue until the Order End Date, both dates as specified on the Order Form (the
          &ldquo;Term&rdquo;). The term of this MSA and the Agreement shall continue as long as an
          Order Form referencing or incorporated into this MSA remains valid and in effect.
          Termination or expiration of any Order Form shall leave other Order Forms unaffected.
        </p>
        <h3>7.2 Termination for Breach; Termination for Insolvency</h3>
        <p>
          If either Party commits a material breach or default in the performance of any of its
          obligations under the Agreement, then the other Party may terminate the Agreement in its
          entirety by giving the defaulting Party written notice of termination, unless the material
          breach or default in performance is cured within thirty (30) days after the defaulting
          Party receives notice thereof. Either Party may terminate the Agreement in its entirety
          upon written notice if the other Party becomes the subject of a petition in bankruptcy or
          any proceeding related to its insolvency, receivership or liquidation, in any
          jurisdiction, that is not dismissed within sixty (60) days of its commencement, or an
          assignment for the benefit of creditors. If Customer terminates the Agreement for
          PPDS&rsquo;s breach, pursuant to this Section 7.2, that is incapable of cure, PPDS will
          provide a prorated refund to Customer for any prepaid fees received by PPDS under the
          Agreement that correspond to the unused portion of the Term.
        </p>
        <h3>7.3 Post-Termination Obligations</h3>
        <p>
          If the Agreement expires or is terminated for any reason: (a) Customer will pay to PPDS
          any amounts that have accrued before, and remain unpaid as of, the effective date of the
          expiration or termination; (b) any and all liabilities of either Party to the other Party
          that have accrued before the effective date of the expiration or termination will survive;
          (c) licenses and use rights granted to Customer with respect to the PPDS Services and
          related intellectual property will immediately terminate; (d) PPDS’s obligation to provide
          any further PPDS Services to Customer under the Agreement will immediately terminate,
          except any such PPDS Services that are expressly to be provided following the expiration
          or termination of the Agreement; and (e) the Parties’ rights and obligations under
          Sections 4.3, 6.1, 7.3, 7.4, 8.3, and 10 through 13 will survive.
        </p>
        <h3>7.4 Retrieval of Customer Data and Transition Services</h3>
        <p>
          During the Term, Customer may extract Customer Data from the PPDS Services as described in
          the Documentation and the applicable Service Schedule. If, upon termination or expiration
          of the Agreement, Customer has failed to retrieve its Customer Data and/or if Customer
          otherwise requires further support from PPDS in relation to such termination or
          expiration, Customer may request and PPDS will provide: (a) assistance in retrieving
          Customer Data still remaining in the PPDS Services, and/or (b) other reasonable transition
          assistance, the details of which will be set forth in a mutually agreed upon Statement of
          Work between the Parties at PPDS&rsquo;s then-current rates for such services.
        </p>

        <h2>8. WARRANTIES AND DISCLAIMERS</h2>
        <h3>8.1 PPDS Service Warranties</h3>
        <p>
          PPDS warrants that: (a) during the applicable Term, the PPDS Services, when used as
          authorized under the Agreement, will perform substantially in conformance with the
          Documentation associated with the applicable PPDS Services; (b) PPDS will use commercially
          reasonable efforts to ensure that the PPDS Services do not introduce files, scripts,
          agents, or programs intended to do harm, including, for example, viruses, worms, time
          bombs, and Trojan horses into Customer&rsquo;s system; and (c) the operation of its
          business as it relates to the PPDS Services will comply with all applicable laws and
          regulations. Customer’s sole and exclusive remedy for any breach of the warranties in
          8.1(a) and (b) above by PPDS is for PPDS to repair or replace the affected PPDS Services
          to make them conform, or, if PPDS determines that the foregoing remedy is not commercially
          reasonable, then either Party may terminate the Agreement, and, in such event, PPDS will
          provide a prorated refund to Customer for any prepaid fees received by PPDS under the
          Agreement that correspond to nonconforming PPDS Services and the unused portion of the
          Term.
        </p>
        <h3>8.2 PPDS Professional Services Warranties</h3>
        <p>
          If Customer has purchased Professional Services in an Order Form, PPDS warrants to
          Customer that the Professional Services will be performed in a competent and workmanlike
          manner. Customer’s exclusive remedy for breach of this warranty is to notify PPDS in
          writing within thirty (30) days of the non-conforming Professional Services. Upon receipt
          of such notice, PPDS will use commercially reasonable efforts to re-perform the
          Professional Services in conformance with these warranty requirements. This Section 8.2
          (PPDS Professional Services Warranties) sets forth Customer’s exclusive rights and
          remedies and PPDS’s sole liability in connection with the warranty related to the
          performance of the Professional Services.
        </p>
        <h3>8.3 Mutual Warranties</h3>
        <p>
          Each Party represents and warrants that: (a) the Agreement has been duly executed and
          delivered and constitutes a valid and binding agreement enforceable against it in
          accordance with the terms of the Agreement; (b) no authorization or approval from any
          third party is required in connection with its execution of the Agreement; and (c) it is
          duly organized and validly existing under the laws of the state of its incorporation or
          formation and has full power and authority to enter into the Agreement and to carry out
          the provisions hereto.
        </p>
        <h3>8.4 DISCLAIMER</h3>
        <p>
          EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES STATED IN THE AGREEMENT, NEITHER
          PARTY: (A) MAKES ANY REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED IN
          FACT OR BY OPERATION OF LAW, OR STATUTORY, AS TO ANY MATTER WHATSOEVER; AND (B) DISCLAIMS
          ALL IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND TITLE.
        </p>

        <h2>9. THIRD-PARTY CLAIMS</h2>
        <h3>9.1 By PPDS</h3>
        <p>
          PPDS will defend and, in accordance with Section 9.3 (Procedures), indemnify Customer’s
          Indemnified Parties from and against, any: (a) third-party claim; (b) third-party legal
          action; or (c) administrative agency action or proceeding (each, a &ldquo;Claim&rdquo;) to
          the extent arising from: (i) any actual breach by PPDS of its confidentiality obligations
          in the Agreement; and (ii) any alleged infringement of any third-party intellectual
          property right occurring from Customer’s use of the PPDS Services as authorized under the
          Agreement. Notwithstanding the foregoing, PPDS will not be responsible for any Claim due
          to Customer’s or its Authorized User’s combination of PPDS Services with goods or services
          provided by third parties, including any Third-Party Services; adherence to
          specifications, designs, or instructions furnished by Customer; or Customer’s modification
          of the PPDS Services not described in the Documentation or otherwise expressly authorized
          by PPDS in writing.
        </p>
        <h3>9.2 By Customer</h3>
        <p>
          Customer will defend and, in accordance with Section 9.3 (Procedures), indemnify PPDS’s
          Indemnified Parties from and against, any Claim to the extent arising from any breach by
          Customer of its obligations under Section 2.2 (e) through (g) (Restrictions).
        </p>
        <h3>9.3 Procedures</h3>
        <p>
          The Parties’ respective obligations in this Section 9 (Third-Party Claims) are conditioned
          on: (a) the Indemnified Parties giving the Indemnifying Party prompt written notice of the
          Claim, except that the failure to provide prompt notice will only limit the
          indemnification obligations to the extent the Indemnifying Party is prejudiced by the
          delay or failure; (b) the Indemnifying Party being given full and complete control over
          the defense and settlement of the Claim; and (c) the relevant Indemnified Parties
          providing assistance in connection with the defense and settlement of the Claim, as the
          Indemnifying Party may reasonably request. The Indemnifying Party will indemnify the
          Indemnified Parties against: (i) all damages, costs, and attorneys’ fees finally awarded
          against any of them with respect to any Claim; (ii) all out-of-pocket costs (including
          reasonable attorneys’ fees) reasonably incurred by any of them in connection with the
          defense of the Claim (other than attorneys’ fees and costs incurred without the
          Indemnifying Party’s consent after it has accepted defense of such Claim); and (iii) all
          amounts that the Indemnifying Party agreed to pay to any third party in settlement of any
          Claims arising under this Section 9 (Third-Party Claims) and settled by the Indemnifying
          Party or with its approval. The Indemnifying Party shall not, without the relevant
          applicable Indemnified Parties’ prior written consent, agree to any settlement on behalf
          of such Indemnified Parties which includes either the obligation to pay any amounts, or
          any admissions of liability, whether civil or criminal, on the part of any of the
          Indemnified Parties.
        </p>
        <h3>9.4 Infringement Remedy</h3>
        <p>
          If Customer is enjoined or otherwise prohibited from using any of the PPDS Services or a
          portion thereof based on a Claim covered by PPDS’s indemnification obligations under
          Section 9.1 (By PPDS) above, then PPDS will, at its sole expense and option, either: (a)
          obtain for Customer the right to use the affected portions of the PPDS Services; (b)
          modify the allegedly infringing portions of the PPDS Services so as to avoid the Claim
          without substantially diminishing or impairing their functionality; or (c) replace the
          allegedly infringing portions of the PPDS Services with items of substantially similar
          functionality so as to avoid the Claim. If PPDS determines that the foregoing remedies are
          not commercially reasonable and notifies Customer of such determination, then either Party
          may terminate the Agreement, and in such case, PPDS will provide a prorated refund to
          Customer for any prepaid fees for the infringing PPDS Services received by PPDS under the
          Agreement that correspond to the unused portion of the Term. The remedies set out in this
          Section 9 (Third-Party Claims) are Customer’s sole and exclusive remedies for any actual
          or alleged infringement by the PPDS Services of any third-party intellectual property
          right.
        </p>

        <h2>10. LIMITATION OF LIABILITY</h2>
        <h3>10.1 Exclusion of Damages</h3>
        <p>
          EXCEPT FOR THE PARTIES’ EXPRESS OBLIGATIONS UNDER SECTION 9 (THIRD-PARTY CLAIMS), UNDER NO
          CIRCUMSTANCES, AND REGARDLESS OF THE NATURE OF THE CLAIM, SHALL EITHER PARTY (OR THEIR
          RESPECTIVE AFFILIATES) BE LIABLE TO THE OTHER PARTY FOR ANY CONSEQUENTIAL, INCIDENTAL,
          INDIRECT, SPECIAL, COVER, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE
          AGREEMENT, EVEN IF APPRISED OF THE LIKELIHOOD OF SUCH LOSSES.
        </p>
        <h3>10.2 Limitation of Liability</h3>
        <p>
          EXCEPT FOR: (A) THE PARTIES’ EXPRESS OBLIGATIONS UNDER SECTION 9 (THIRD-PARTY CLAIMS); (B)
          EITHER PARTY’S BREACH OF ITS CONFIDENTIALITY OBLIGATIONS UNDER THE AGREEMENT; (C) DAMAGES
          RESULTING FROM DEATH OR BODILY INJURY, OR PHYSICAL DAMAGE TO TANGIBLE REAL OR PERSONAL
          PROPERTY, CAUSED BY EITHER PARTY’S NEGLIGENCE; (D) DAMAGES RESULTING FROM EITHER PARTY’S
          GROSS NEGLIGENCE OR WILLFUL MISCONDUCT; AND (E) PPDS’S RIGHT TO COLLECT UNPAID FEES DUE
          HEREUNDER, TO THE EXTENT PERMITTED BY LAW, THE TOTAL, CUMULATIVE LIABILITY OF PPDS (AND
          ITS RESPECTIVE AFFILIATES) ARISING OUT OF OR RELATED TO THE AGREEMENT WILL BE LIMITED TO
          THE AMOUNTS PAID BY CUSTOMER FOR THE PPDS SERVICE(S) DURING THE TWELVE (12) MONTH PERIOD
          PRECEDING THE FIRST EVENT GIVING RISE TO LIABILITY. THE FOREGOING LIMITATION WILL APPLY
          WHETHER AN ACTION IS IN CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF STATUTORY DUTY,
          OR ANY OTHER LEGAL OR EQUITABLE THEORY.
        </p>
        <h3>10.3 Independent Allocations of Risk</h3>
        <p>
          Each provision of the Agreement that provides for a limitation of liability, disclaimer of
          warranties, or exclusion of damages represents an agreed allocation of the risks of the
          Agreement between the Parties. This allocation is reflected in the pricing offered by PPDS
          to Customer and is an essential element of the basis of the bargain between the Parties.
          Each of these provisions is severable and independent of all other provisions of the
          Agreement, and each of these provisions will apply even if the warranties in the Agreement
          have failed of their essential purpose.
        </p>

        <h2>11. CONFIDENTIALITY</h2>
        <h3>11.1 Restricted Use and Nondisclosure</h3>
        <p>
          During and after the Term, Recipient will: (a) use the Confidential Information of the
          disclosing Party solely for the purpose for which it is provided;
          <br />
          (b) not disclose such Confidential Information to a third party, except on a need-to-know
          basis to its Affiliates, attorneys, auditors, consultants, and service providers who are
          under confidentiality obligations at least as restrictive as those contained herein; and
          (c) protect such Confidential Information from unauthorized use and disclosure to the same
          extent (but using no less than a reasonable degree of care) that it protects its own
          Confidential Information of a similar nature.
        </p>
        <h3>11.2 Required Disclosure</h3>
        <p>
          If Recipient is required by law to disclose Confidential Information of the disclosing
          Party, Recipient will give prompt written notice to the disclosing Party before making the
          disclosure, unless prohibited from doing so by legal or administrative process, and
          cooperate with the disclosing Party to obtain where reasonably available an order
          protecting the Confidential Information from public disclosure.
        </p>
        <h3>11.3 Ownership</h3>
        <p>
          Recipient acknowledges that, as between the Parties, all Confidential Information it
          receives from the disclosing Party, including all copies thereof in Recipient’s possession
          or control, in any media, is proprietary to and exclusively owned by the disclosing Party.
          Nothing in the Agreement grants Recipient any right, title or interest in or to any of the
          disclosing Party’s Confidential Information. Recipient’s incorporation of the disclosing
          Party’s Confidential Information into any of its own materials will not render
          Confidential Information non-confidential.
        </p>
        <h3>11.4 Remedies.</h3>
        <p>
          Recipient acknowledges that any actual or threatened breach of this Section 11
          (Confidentiality) may cause irreparable, non-monetary injury to the disclosing Party, the
          extent of which may be difficult to ascertain. Accordingly, the disclosing Party is
          entitled to (but not required to) seek injunctive relief in addition to all remedies
          available to the disclosing Party at law and/or in equity, to prevent or mitigate any
          breaches of the Agreement or damages that may otherwise result from those breaches. Absent
          written consent of the disclosing Party to the disclosure, the Recipient, in the case of a
          breach of this Section 11 (Confidentiality), has the burden of proving that the disclosing
          Party’s Confidential Information is not, or is no longer, confidential or a trade secret
          and that the disclosure does not otherwise violate this Section 11 (Confidentiality).
        </p>

        <h2>12. GOVERNING LAW AND VENUE</h2>
        <h3>12.1</h3>
        <p>
          The Agreement and any disputes or claims arising out of or in connection with it or its
          subject matter or formation (including, without limitation, non-contractual disputes or
          claims) are governed by and construed in accordance with the law of the Netherlands. Each
          Party irrevocably agrees that the courts of Amsterdam, the Netherlands shall have
          exclusive jurisdiction to settle any dispute or claim arising out of or in connection with
          the Agreement or its subject matter or formation (including non-contractual disputes or
          claims). The provisions of the 1980 U.N. Convention on Contracts for the International
          Sale of Goods are expressly excluded and do not apply to the Agreement.
        </p>

        <h2>13. GENERAL</h2>
        <h3>13.1 Relationship</h3>
        <p>
          The Parties are independent contractors. The Agreement does not create a partnership,
          franchise, joint venture, agency, fiduciary or employment relationship between the
          Parties. Except as set forth in the Agreement, nothing in the Agreement, expressed or
          implied is intended to give rise to any third-party beneficiary.
        </p>
        <h3>13.2 Assignability</h3>
        <p>
          Neither Party may assign its rights or obligations under the Agreement without the other
          Party’s prior written consent, such consent not to be unreasonably withheld.
          Notwithstanding the foregoing, either Party may assign its rights and obligations under
          the Agreement to an Affiliate as part of a reorganization, or to a purchaser of its
          business entity or substantially all of its assets or business to which rights and
          obligations pertain without the other Party’s consent, provided that: (a) the purchaser is
          not insolvent or otherwise unable to pay its debts as they become due; (b) the purchaser
          is not a competitor of the other Party; and (c) any assignee is bound hereby. Other than
          the foregoing, any attempt by either Party to transfer its rights or obligations under the
          Agreement will be void.
        </p>
        <h3>13.3 Notices</h3>
        <p>
          Any notice required or permitted to be given in accordance with the Agreement will be
          effective only if it is in writing and sent using: (a) certified or registered mail; or
          (b) a nationally recognized overnight courier, to the appropriate Party at the address set
          forth on the Order Form, with a copy, in the case of PPDS, to{' '}
          <a href="mailto:notices@ppds.com">notices@ppds.com</a>. Either Party may change its
          address for receipt of notice by notice to the other Party through a notice provided in
          accordance with this Section 13.3 (Notices). Notices are deemed given two (2) business
          days following the date of mailing, or one (1) business day following delivery to a
          courier.
        </p>
        <h3>13.4 Force Majeure</h3>
        <p>
          In the event that either Party is prevented from performing, or is unable to perform, any
          of its obligations under the Agreement due to any cause beyond the reasonable control of
          the Party invoking this provision (including, without limitation, for causes due to war,
          fire, earthquake, flood, hurricane, riots, acts of God, telecommunications outage not
          caused by the obligated Party, or other similar causes) (&ldquo;Force Majeure
          Event&rdquo;), the affected Party’s performance will be excused and the time for
          performance will be extended for the period of delay or inability to perform due to such
          occurrence; provided that the affected Party: (a) provides the other Party with prompt
          notice of the nature and expected duration of the Force Majeure Event; (b) uses
          commercially reasonable efforts to address and mitigate the cause and effect of such Force
          Majeure Event; (c) provides periodic notice of relevant developments; and (d) provides
          prompt notice of the end of such Force Majeure Event. Delays in fulfilling the obligations
          to pay hereunder are excused only to the extent that payments are entirely prevented by
          the Force Majeure Event. If PPDS Services are not restored within thirty (30) days of the
          Force Majeure Event, Customer may terminate the Agreement upon providing written notice to
          PPDS, and in such case, PPDS will provide a prorated refund to Customer for any prepaid
          fees received by PPDS under the Agreement that correspond to the unused portion of the
          Term.
        </p>
        <h3>13.5 Trade Restrictions</h3>
        <p>
          The PPDS Services, Documentation, and the provision and any derivatives thereof are
          subject to the export control and sanctions laws and regulations of the United States and
          other countries that may prohibit or restrict access by certain persons or from certain
          countries or territories (&ldquo;Trade Restrictions&rdquo;).
        </p>
        <p>
          (a) Each Party shall comply with all applicable Trade Restrictions in performance of the
          Agreement. For the avoidance of doubt, nothing in the Agreement is intended to induce or
          require either Party to act in any manner which is penalized or prohibited under any
          applicable laws, rules, regulations or decrees.
        </p>
        <p>
          (b) Each Party represents that it is not a Restricted Party. &ldquo;Restricted
          Party&rdquo; means any person or entity that is: (i) located or organized in a country or
          territory subject to comprehensive EU and U.S. sanctions (currently including Cuba,
          Crimea, Iran, North Korea, Russia and Syria) (&quot;Sanctioned Territory&quot;); (ii)
          owned or controlled by or acting on behalf of the government of a Sanctioned Territory;
          (iii) an entity organized in or a resident of a Sanctioned Territory; (iv) identified on
          any list of restricted parties targeted under U.S., EU or multilateral sanctions,
          including, but not limited to, the U.S. Department of the Treasury, Office of Foreign
          Assets Control’s (&ldquo;OFAC&rdquo; ) List of Specially Designated Nationals and Other
          Blocked Persons, the OFAC Sectoral Sanctions List, the U.S. State Department&rsquo;s
          Nonproliferation Sanctions and other lists, the U.S. Commerce Department’s Entity List or
          Denied Persons List located at{' '}
          <a href="https://www.export.gov/article?id=Consolidated-Screening-List">
            https://www.export.gov/article?id=Consolidated-Screening-List
          </a>
          , the consolidated list of persons, groups and entities subject to EU financial sanctions
          from time to time; or (v) owned or controlled by, or acting on behalf of, any of the
          foregoing.
        </p>
        <p>
          (c) Customer acknowledges and agrees that it is solely responsible for complying with, and
          shall comply with, Trade Restrictions applicable to any of its own or its
          Affiliates&rsquo; or Authorized Users’ content or Customer Data transmitted through the
          PPDS Services. Customer shall not and shall not permit any Authorized User to access, use,
          or make the PPDS Services available to or by any Restricted Party or to or from within any
          Sanctioned Territory.
        </p>
        <h3>13.6 Anti-Corruption</h3>
        <p>
          In connection with the PPDS Services performed under the Agreement and Customer’s use of
          the PPDS Services, the Parties agree to comply with all applicable anti-corruption and
          anti-bribery related laws, statutes, and regulations.
        </p>
        <h3>13.7 Publicity</h3>
        <p>
          Except as specified on an Order Form or as otherwise expressly agreed to by the Parties in
          writing, neither Party shall refer to the identity of the other Party in promotional
          material, publications, or press releases or other forms of publicity relating to the PPDS
          Services unless the prior written consent of the other Party has been obtained.
        </p>
        <h3>13.8 Waiver</h3>
        <p>
          The waiver by either Party of any breach of any provision of the Agreement does not waive
          any other breach. The failure of any Party to insist on strict performance of any covenant
          or obligation in accordance with the Agreement will not be a waiver of such Party’s right
          to demand strict compliance in the future, nor will the same be construed as a novation of
          the Agreement.
        </p>
        <h3>13.9 Severability</h3>
        <p>
          If any part of the Agreement is found to be illegal, unenforceable, or invalid, the
          remaining portions of the Agreement will remain in full force and effect.
        </p>
        <h3>13.10 Insurance</h3>
        <p>
          Each Party will maintain, at its sole expense, insurance in such amounts and with such
          types of coverage as is usual and customary with coverage limits that are appropriate for
          the PPDS Services supplied under the Agreement. At a minimum, each Party shall maintain
          commercial general liability insurance.
        </p>
        <h3>13.11 Entire Agreement</h3>
        <p>
          The Agreement is the final, complete, and exclusive expression of the agreement between
          the Parties regarding the PPDS Services provided under the Agreement. The Agreement
          supersedes and replaces, and the Parties disclaim any reliance on, all previous oral and
          written communications (including any confidentiality agreements pertaining to the PPDS
          Services under the Agreement), representations, proposals, understandings, undertakings,
          and negotiations with respect to the subject matter hereof and apply to the exclusion of
          any other terms that Customer seeks to impose or incorporate, or which are implied by
          trade, custom, practice, or course of dealing. The Agreement may be changed only by a
          written agreement signed by an authorized agent of both Parties. The Agreement will
          prevail over terms and conditions of any Customer-issued purchase order or other ordering
          documents, which will have no force and effect, even if PPDS accepts or does not otherwise
          reject the purchase order or other ordering document.
        </p>
      </article>
      <article>
        <h2>SCHEDULE 1 - SECURITY SCHEDULE FOR PPDS SERVICES</h2>
        <p>
          This Schedule describes the technical and organizational measures that PPDS has adopted to
          preserve the security, integrity, and confidentiality of Customer Data and Customer
          personal data and to protect against unauthorized or unlawful disclosure or corruption of
          or access to such data.
        </p>
        <p>
          Information security techniques, and the threats to security are continually evolving and
          assessed in the light of the specific circumstances at hand to determine the appropriate
          level of protection.
        </p>

        <h2>1. ORGANIZATIONAL MEASURES</h2>
        <h3>1.1 Security Policies and Standard Operating Procedures</h3>
        <p>
          PPDS has implemented Security Policies and Standard Operating Procedures to preserve the
          security, integrity, and confidentiality of Customer Data and Customer personal data.
        </p>
        <h3>1.2 Functions and Obligations of Staff</h3>
        <p>
          Only employees with demonstrated honesty, integrity and discretion have access to premises
          where information systems or media containing Customer Data and Customer personal data are
          located. Staff is bound by duty of confidentiality in respect of any access to Customer
          Data and Customer personal data.
        </p>

        <h2>2. TECHNICAL MEASURES</h2>
        <h3>2.1 Authorization</h3>
        <p>
          Only employees who have a legitimate operational need to access the
          <br />
          Information Systems or carry out any Processing of Personal Data are authorized to do so.
        </p>
        <h3>2.2 Identification</h3>
        <p>
          Every employee is issued a personal and unique identification code (User ID) for that
          purpose.
        </p>
        <h3>2.3 Authentication</h3>
        <p>
          Employees are only allowed to process Customer Data and Customer Personal Data if they are
          provided with authentication credentials such as to successfully complete an
          authentication procedure. Passwords abide to industry’s best practices and guidelines.
          Passwords are modified by employees to a secret value known only to the employee when it
          is first used and after.
        </p>
        <h3>2.4 Access Controls</h3>
        <p>
          Only employees with access rights based on roles and need to know have access to Customer
          Data and Customer Personal Data. Access is always recorded in an access record.
        </p>
        <h3>2.5 Preservation, Back-up copies and Recovery</h3>
        <p>
          Tools are in place to prevent the unintended deterioration or destruction of personal
          Data.
        </p>
        <h3>2.6 Software Updates</h3>
        <p>
          Software, firmware and hardware used to provide the Services are reviewed regularly in
          order to detect vulnerabilities and flaws and resolve such vulnerabilities and flaws. This
          review is carried out at least annually.
        </p>
      </article>
      <article>
        <h2>SCHEDULE 2 - DATA PROTECTION SCHEDULE FOR PPDS SERVICES</h2>

        <h2>1. GENERAL PROVISIONS</h2>
        <p>
          1.1. This Data Protection Schedule, forms part of the MSA between PPDS and Customer for
          the purchase of PPDS Services from PPDS. In the course of providing the Services to
          Customer pursuant to the MSA, PPDS may process Customer Personal Data on behalf of
          Customer and the Parties agree to comply with the following provisions with respect to any
          Personal Data, each acting reasonably and in good faith.
        </p>
        <p>
          1.2. For the purpose of this Schedule, &quot;Controller&quot;, &quot;Processor&quot;,
          &quot;Data Subject&quot;, &quot;Personal Data&quot; and &quot;Processing&quot; shall have
          the meanings ascribed to them in the GDPR.
        </p>

        <h2>2. PROCESSING OF CUSTOMER PERSONAL DATA</h2>
        <h3>2.1 Roles of the Parties</h3>
        <p>
          The Parties acknowledge and agree that with regard to the Processing of Customer Personal
          Data, Customer is the Controller, PPDS is the Processor and that PPDS or its Affiliates
          will engage Sub-processors pursuant to the requirements set forth in Section 5 &quot;Sub-
          processors&quot; below.
        </p>
        <h3>2.2 Customer&rsquo;s Processing of Personal Data</h3>
        <p>
          Customer shall, in its use of the Services, Process Personal Data in accordance with the
          requirements of Data Protection Laws and Regulations. For the avoidance of doubt,
          Customer&rsquo;s instructions for the Processing of Personal Data shall comply with Data
          Protection Laws and Regulations. Customer shall have sole responsibility for the accuracy,
          quality, and legality of Personal Data and the means by which Customer acquired Personal
          Data.
        </p>
        <h3>2.3 PPDS&rsquo;s Processing of Personal Data</h3>
        <p>
          PPDS shall treat Personal Data as Confidential Information and shall only process Personal
          Data on behalf of and in accordance with Customer&rsquo;s documented instructions for the
          following purposes: (i) Processing in accordance with the MSA and applicable Order
          Form(s); (ii) Processing initiated by Authorized Users in their use of the Services; and
          (iii) Processing to comply with other documented reasonable instructions provided by
          Customer (e.g., via email) where such instructions are consistent with the terms of the
          MSA.
        </p>
        <h3>2.4 Details of the Processing</h3>
        <p>
          The subject-matter of Processing of Customer Personal Data by PPDS is the performance of
          the Services pursuant to the MSA. The duration of the processing, the nature and purpose
          of the processing, the types of Customer Personal Data and categories of Data Subjects
          Processed under this DPA are further specified in Schedule 4 (Details of the Processing)
          to this DPA.
        </p>

        <h2>3. RIGHTS OF DATA SUBJECTS</h2>
        <h3>3.1 Data Subject Request</h3>
        <p>
          PPDS shall, to the extent legally permitted, promptly notify Customer if PPDS receives a
          request from a Data Subject to exercise the Data Subject&rsquo;s right of access, right to
          rectification, restriction of processing, erasure (&quot;right to be forgotten&quot;),
          data portability, object to the processing, or its right not to be subject to an automated
          individual decision making, each such request being a &quot;Data Subject Request&quot;.
          Considering the nature of the processing, PPDS shall assist Customer by appropriate
          technical and organizational measures, insofar as this is possible, for the fulfilment of
          Customer&rsquo;s obligation to respond to a Data Subject Request under Data Protection
          Laws and Regulations. In addition, to the extent Customer, in its use of the Services,
          does not have the ability to address a Data Subject Request, PPDS shall upon
          Customer&rsquo;s request provide commercially reasonable efforts to assist Customer in
          responding to such Data Subject Request, to the extent PPDS is legally permitted to do so
          and the response to such Data Subject Request is required under Data Protection Laws and
          Regulations. To the extent legally permitted, Customer shall be responsible for any costs
          arising from PPDS&rsquo;s provision of such assistance.
        </p>

        <h2>4. PPDS PERSONNEL</h2>
        <h3>4.1 Confidentiality</h3>
        <p>
          PPDS shall ensure that its personnel engaged in the processing of Customer Personal Data
          are informed of the confidential nature of the Customer Personal Data, have received
          appropriate training on their responsibilities and have executed written confidentiality
          agreements. PPDS shall ensure that such confidentiality obligations survive the
          termination of the personnel engagement.
        </p>
        <h3>4.2 Reliability</h3>
        <p>
          PPDS shall take commercially reasonable steps to ensure the reliability of any PPDS
          personnel engaged in the processing of Customer Personal Data.
        </p>
        <h3>4.3 Limitation of Access</h3>
        <p>
          PPDS shall ensure that PPDS&rsquo;s access to Customer Personal Data is limited to those
          personnel performing Services in accordance with the MSA.
        </p>
        <h3>4.4 Data Protection Officer</h3>
        <p>
          PPDS has appointed a data protection officer. The appointed person may be reached at{' '}
          <a href="mailto:privacy@tpv-tech.com">privacy@tpv-tech.com</a>.
        </p>

        <h2>5. SUB-PROCESSORS</h2>
        <h3>5.1 Appointment of Sub-processors</h3>
        <p>
          Customer acknowledges and agrees that PPDS and PPDS&rsquo;s Affiliates respectively may
          engage third-party Sub-processors in connection with the provision of the Services. PPDS
          or its Affiliates have entered into a written agreement with each Sub-processor containing
          data protection obligations not less protective than those in this Agreement with respect
          to the protection of Customer Personal Data to the extent applicable to the nature of the
          Services provided by such Sub-processor.
        </p>
        <h3>5.2 List of Current Sub-processors and Notification of new Sub-processors</h3>
        <p>
          PPDS shall make available to Customer the current list of Sub-processors in Annex 1
          attached hereto. The Sub- processor lists includes the identities of those Sub-processors
          and their country of location. PPDS may add and/or remove Sub-processors to the list of
          Sub-processors in Annex 1. PPDS will inform Customer by written notice of its intention to
          use a new Sub-processor.
        </p>
        <h3>5.3 Objection Right for New Sub-processors</h3>
        <p>
          Customer may object to PPDS&rsquo;s use of a new Sub- processor by notifying PPDS promptly
          in writing within ten (10) business days after receipt of PPDS&rsquo;s notice in
          accordance with the mechanism set out in Section 5.2. In the event Customer objects to a
          new Sub-processor, as permitted in the preceding sentence, PPDS will use reasonable
          efforts to make available to Customer a change in the Services or recommend a commercially
          reasonable change to Customer&rsquo;s configuration or use of the Services to avoid
          Processing of Personal Data by the objected-to new Sub-processor without unreasonably
          burdening the Customer. If PPDS is unable to make available such change within a
          reasonable period of time Customer may terminate the applicable Order Form(s) with respect
          only to those Services which cannot be provided by PPDS without the use of the objected-to
          new Sub-processor by providing written notice to PPDS.
        </p>
        <h3>5.4 Liability</h3>
        <p>
          PPDS shall be liable for the acts and omissions of its Sub-processors to the same extent
          PPDS would be liable if performing the services of each Sub-processor directly under the
          terms of this Schedule, except as otherwise set forth in the MSA.
        </p>

        <h2>6. SECURITY</h2>
        <h3>6.1 Controls for the Protection of Customer Personal Data</h3>
        <p>
          PPDS shall maintain appropriate technical and organizational measures for protection of
          the security (including protection against unauthorized or unlawful processing and against
          accidental or unlawful destruction, loss or alteration or damage, unauthorized disclosure
          of or access to, Customer Personal Data), confidentiality and integrity of Customer
          Personal Data, as set forth in the Security Schedule for PPDS Services. PPDS regularly
          monitors compliance with these measures. PPDS will not materially decrease the overall
          security of the Services during a subscription term.
        </p>
        <h3>6.2 Third-Party Certifications and Audits</h3>
        <p>
          PPDS has obtained the third-party certifications and audits as set forth in Schedule 1 –
          Security Schedule for PPDS Services.
        </p>

        <h2>7. CUSTOMER DATA INCIDENT MANAGEMENT AND NOTIFICATION</h2>
        <p>
          PPDS maintains security incident management policies and procedures and shall notify
          Customer without undue delay after becoming aware of the accidental or unlawful
          destruction, loss, alteration, unauthorized disclosure of, or access to Customer Personal
          Data, including Personal Data, transmitted, stored or otherwise Processed by PPDS or its
          Sub-processors of which PPDS becomes aware (a &quot;Customer Data Incident&quot;). PPDS
          shall make reasonable efforts to identify the cause of such Customer Data Incident and
          take those steps as PPDS deems necessary and reasonable in order to remediate the cause of
          such a Customer Data Incident to the extent the remediation is within PPDS&rsquo;s
          reasonable control. The obligations herein shall not apply to incidents that are caused by
          Customer or Customer&rsquo;s Users.
        </p>

        <h2>8. RETURN AND DELETION OF CUSTOMER DATA</h2>
        <p>
          PPDS shall return Customer Personal Data to Customer and, to the extent allowed by
          applicable law, delete Customer Data in accordance with the procedures and timeframes
          specified in the MSA or otherwise agreed in writing with Customer.
        </p>

        <h2>9. LIMITATION OF LIABILITY</h2>
        <p>
          PPDS’s and all of its Affiliates&rsquo; liability, taken together in the aggregate,
          arising out of or related to this Schedule, whether in contract, tort or under any other
          theory of liability, is subject to the &lsquo;Limitation of Liability&rsquo; section of
          the MSA, and any reference in such section to the liability of a Party means the aggregate
          liability of that party and all of its Affiliates under the MSA and all Schedules
          together.
        </p>
      </article>
      <article>
        <h2>Annex 1 – Sub-processor List Legal Name</h2>
        <table>
          <thead>
            <tr>
              <th>Legal Name</th>
              <th>Role in delivery of the services</th>
              <th>Corporate Headquarters</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Amazon Web Services</td>
              <td>Hosting Services</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>Atlassian Inc.</td>
              <td>Support Services</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>Sales Force</td>
              <td>Support Services</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>MMD-Monitors &amp; Displays Nederland B.V.</td>
              <td>Support Services</td>
              <td>Netherlands</td>
            </tr>
            <tr>
              <td>TP Vision Belgium N.V.</td>
              <td>Support Services</td>
              <td>Belgim</td>
            </tr>
            <tr>
              <td>ITP Agency N.V.</td>
              <td>Support Services</td>
              <td>Belgium</td>
            </tr>
            <tr>
              <td>Otrum AS</td>
              <td>Support Services</td>
              <td>Norway</td>
            </tr>
            <tr>
              <td>AppDirect</td>
              <td>Support Services</td>
              <td>Canada</td>
            </tr>
            <tr>
              <td>MongoDB Inc.</td>
              <td>Support Services</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>Metabase</td>
              <td>Support Services</td>
              <td>USA</td>
            </tr>
          </tbody>
        </table>
      </article>
    </PolicyPage>
  );
}
