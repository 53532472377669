import { Box, Checkbox, CheckboxGroup, Flex } from '@chakra-ui/react';
import { InputHTMLAttributes } from 'react';

type MultiSelectPillsProps = {
  onChange: (values: string[]) => void;
  options: Array<{ value: string; label: string; disabled?: boolean }>;
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  value: Array<string | number>;
};

const MultiSelectPills = ({ onChange, options, inputProps, value }: MultiSelectPillsProps) => {
  return (
    <Box
      sx={{
        '.chakra-checkbox:not([data-checked])': {
          _hover: {
            background: 'blue.50',
          },
        },
        '.chakra-checkbox__control': {
          display: 'none',
        },
        '.chakra-checkbox__label': {
          margin: 0,
        },
      }}
    >
      <CheckboxGroup onChange={onChange} value={value}>
        <Flex gap={3}>
          {options.map(({ value, label, disabled }) => (
            <Checkbox
              key={value}
              value={value}
              flex={1}
              justifyContent="center"
              cursor="pointer"
              fontWeight="semibold"
              borderWidth="1px"
              borderRadius="100px"
              color="gray.900"
              _disabled={{
                color: 'gray.400',
                bg: 'gray.50',
                pointerEvents: 'none',
              }}
              _checked={{
                bg: 'blue.700',
                color: 'white',
                borderColor: 'blue.700',
              }}
              px={4}
              py={2}
              inputProps={inputProps}
              disabled={disabled}
            >
              {label}
            </Checkbox>
          ))}
        </Flex>
      </CheckboxGroup>
    </Box>
  );
};

export default MultiSelectPills;
