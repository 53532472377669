import { createIcon } from '@chakra-ui/react';

export const PortraitIcon = createIcon({
  displayName: 'PortraitIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: <rect x="5" y="2" width="14" height="20" rx="2" stroke="currentColor" strokeWidth="2" />,
});
