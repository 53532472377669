/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { StepMetadata_CustomerFragmentDoc } from './StepMetadata.graphql';
export type Wizard_CustomerFragment = { __typename: 'Customer', id: string, waveSubscription?: { __typename: 'WaveSubscription', usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } } | null, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export const Wizard_CustomerFragmentDoc = gql`
    fragment Wizard_customer on Customer {
  id
  ...StepMetadata_customer
  waveSubscription {
    usage {
      current
      max
    }
  }
}
    ${StepMetadata_CustomerFragmentDoc}`;