import { createIcon } from '@chakra-ui/react';

export const ScreenshotErrorIllustration = createIcon({
  displayName: 'ScreenshotErrorIllustration',
  viewBox: '0 0 140 140',
  defaultProps: {
    fill: 'none',
    width: '140px',
    height: '140px',
  },
  path: (
    <>
      <circle cx="70.5" cy="70.5" r="70" fill="#0A3761" />
      <g filter="url(#filter0_dd_8537_3443)">
        <path
          d="M20.8 42.0696C20.8 39.2147 23.0822 36.9004 25.8975 36.9004H115.103C117.918 36.9004 120.2 39.2147 120.2 42.0696V95.0542C120.2 97.9091 117.918 100.223 115.103 100.223H25.8975C23.0822 100.223 20.8 97.9091 20.8 95.0542V42.0696Z"
          fill="white"
        />
        <path
          d="M46.2872 100.223H96.6244V101.516C96.6244 102.943 95.4833 104.1 94.0757 104.1H48.8359C47.4283 104.1 46.2872 102.943 46.2872 101.516V100.223Z"
          fill="#0F5291"
        />
        <path
          d="M46.2872 98.7235H44.7872H25.8975C23.9304 98.7235 22.3 97.1006 22.3 95.0542V42.0696C22.3 40.0233 23.9304 38.4004 25.8975 38.4004H115.103C117.07 38.4004 118.7 40.0233 118.7 42.0696V95.0542C118.7 97.1005 117.07 98.7235 115.103 98.7235H98.1244H96.6244H46.2872ZM47.8061 101.723H95.1055C95.0105 102.236 94.5706 102.6 94.0757 102.6H48.8359C48.341 102.6 47.9011 102.236 47.8061 101.723Z"
          stroke="#627D98"
          strokeWidth="3"
        />
      </g>
      <g filter="url(#filter1_dd_8537_3443)">
        <path
          d="M77.1499 39.9043V47.5747C77.1499 49.4111 78.6386 50.8997 80.4749 50.8997V50.8997C82.3112 50.8997 83.7999 49.4111 83.7999 47.5747V47.3997V47.3997C83.7999 45.4667 85.3669 43.8997 87.2999 43.8997H117.199"
          stroke="#F0F4F8"
          strokeWidth="2"
        />
      </g>
      <path d="M34.5002 49.5L34.5002 40.5" stroke="#F0F4F8" strokeWidth="2" />
      <path
        d="M79.6001 60.7H90.5001C94.9184 60.7 98.5001 57.1183 98.5001 52.7V39.8984"
        stroke="#F0F4F8"
        strokeWidth="2"
      />
      <circle cx="98.4999" cy="43.8998" r="2.1" fill="#D9E2EC" />
      <g filter="url(#filter2_dd_8537_3443)">
        <path
          d="M23.8018 88H26.7999C31.2181 88 34.7999 91.5817 34.7999 96V97.2227"
          stroke="#F0F4F8"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter3_dd_8537_3443)">
        <path
          d="M23.8008 68.4004L26.7999 68.4004C31.2182 68.4004 34.7999 64.8187 34.7999 60.4004L34.7999 50.2004"
          stroke="#F0F4F8"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter4_dd_8537_3443)">
        <path
          d="M107.6 78.9004L107.6 70.1004C107.6 65.6821 111.182 62.1004 115.6 62.1004L117.199 62.1004"
          stroke="#F0F4F8"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter5_dd_8537_3443)">
        <path
          d="M117.199 80.4954H100.502C98.2366 80.4954 96.3999 82.332 96.3999 84.5977V84.5977C96.3999 86.8633 94.5632 88.7 92.2976 88.7H51.9499V88.7C49.0504 88.7 46.6999 86.3495 46.6999 83.45V83.1163V77.5326V63.5163V57.5C46.6999 53.0817 43.1182 49.5 38.6999 49.5H34.7999H23.8008"
          stroke="#F0F4F8"
          strokeWidth="2"
        />
      </g>
      <path
        d="M64.2395 56.9783C64.8021 56.4157 65.5652 56.0996 66.3609 56.0996H74.6391C75.4348 56.0996 76.1979 56.4157 76.7605 56.9783L82.6213 62.8391C83.1839 63.4018 83.5 64.1648 83.5 64.9605V73.2388C83.5 74.0344 83.1839 74.7975 82.6213 75.3601L76.7605 81.2209C76.1979 81.7835 75.4348 82.0996 74.6391 82.0996H66.3609C65.5652 82.0996 64.8021 81.7835 64.2395 81.2209L58.3787 75.3601C57.8161 74.7975 57.5 74.0344 57.5 73.2388V64.9605C57.5 64.1648 57.8161 63.4018 58.3787 62.8391L64.2395 56.9783Z"
        fill="#EF4E4E"
        stroke="#EF4E4E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.5494 64.2496L65.6499 74.149M65.6499 64.2496L75.5494 74.149"
        stroke="#FAFCFF"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="34.7999" cy="49.5004" r="2.1" fill="#D9E2EC" />
      <path d="M87.5 87.5L87.5 96.5" stroke="#F0F4F8" strokeWidth="2" />
      <circle
        cx="87.2999"
        cy="88.6998"
        r="2.1"
        transform="rotate(-180 87.2999 88.6998)"
        fill="#D9E2EC"
      />
      <path
        d="M116.5 90.5L115.5 90.5C111.082 90.5 107.5 86.9183 107.5 82.5L107.5 78.5"
        stroke="#F0F4F8"
        strokeWidth="2"
      />
      <circle cx="107.6" cy="80.3002" r="2.1" fill="#D9E2EC" />
      <defs>
        <filter
          id="filter0_dd_8537_3443"
          x="7.52971"
          y="34.6887"
          width="125.941"
          height="93.7409"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.21172"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8537_3443"
          />
          <feOffset dy="4.42345" />
          <feGaussianBlur stdDeviation="3.31759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8537_3443" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.31759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8537_3443"
          />
          <feOffset dy="11.0586" />
          <feGaussianBlur stdDeviation="8.29396" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8537_3443"
            result="effect2_dropShadow_8537_3443"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8537_3443"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_8537_3443"
          x="64.1499"
          y="37.9043"
          width="65.0491"
          height="35.9951"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8537_3443"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8537_3443" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8537_3443"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8537_3443"
            result="effect2_dropShadow_8537_3443"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8537_3443"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_8537_3443"
          x="11.8018"
          y="85"
          width="35.9982"
          height="34.2227"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8537_3443"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8537_3443" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8537_3443"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8537_3443"
            result="effect2_dropShadow_8537_3443"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8537_3443"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_8537_3443"
          x="11.8008"
          y="48.2002"
          width="35.9991"
          height="43.2002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8537_3443"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8537_3443" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8537_3443"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8537_3443"
            result="effect2_dropShadow_8537_3443"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8537_3443"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_dd_8537_3443"
          x="94.6001"
          y="59.1006"
          width="34.5991"
          height="41.7998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8537_3443"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8537_3443" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8537_3443"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8537_3443"
            result="effect2_dropShadow_8537_3443"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8537_3443"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_dd_8537_3443"
          x="11.8008"
          y="46.5"
          width="117.398"
          height="65.2002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8537_3443"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8537_3443" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8537_3443"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8537_3443"
            result="effect2_dropShadow_8537_3443"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8537_3443"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
