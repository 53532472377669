import { Permission } from '@tp-vision/roles-permissions';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { LogoSettings_OrganizationFragment } from '~components/settings/__generated__/LogoSettings.graphql';
import {
  BuildingIcon,
  ImageIcon,
  KeyIcon,
  SubscriptionIcon,
  UsersIcon,
} from '~components/ui/icons';
import { AppsIcon } from '~components/ui/icons/AppsIcon';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { useTabs } from '~components/ui/Tabs';
import {
  ActiveTabBackground,
  VerticalTab,
  VerticalTabDivider,
  VerticalTabList,
  VerticalTabPanel,
  VerticalTabs,
} from '~components/ui/VerticalTabs';
import { isDefined } from '~utils/types';

export function useLogoSettingsTab() {
  return useOutletContext<{
    organization: LogoSettings_OrganizationFragment;
  }>();
}

export function OrganizationSettingsIndexPage() {
  const { organization, verifyUserPermissions } = useAuth();

  const { tabs, activeTab } = useTabs(
    [
      verifyUserPermissions([Permission.OrganizationUpdate])
        ? {
            header: 'Organization',
            path: 'logo',
            label: 'Logo',
            icon: ImageIcon,
          }
        : null,
      verifyUserPermissions([Permission.UserUpdate])
        ? {
            header: 'Organization',
            path: 'users',
            label: 'Users',
            icon: UsersIcon,
          }
        : null,
      verifyUserPermissions([Permission.CustomerUpdate])
        ? {
            header: 'Organization',
            path: 'customers',
            label: 'Customers',
            icon: BuildingIcon,
          }
        : null,
      verifyUserPermissions([Permission.WaveSubscriptionUpdate])
        ? {
            header: 'Organization',
            path: 'subscriptions',
            label: 'Subscriptions',
            icon: SubscriptionIcon,
          }
        : null,
      {
        header: 'Organization',
        path: 'apps',
        label: 'Apps',
        icon: AppsIcon,
      },
      verifyUserPermissions([Permission.ApiKeyCreate, Permission.ApiKeyDelete])
        ? {
            header: 'Personal',
            path: 'api-keys',
            label: 'API Keys',
            icon: KeyIcon,
          }
        : null,
    ].filter(isDefined),
  );

  return (
    <Page
      title={activeTab?.label ? `Settings | ${activeTab.label}` : 'Settings'}
      pageName="settings"
    >
      <PageContent paddingY="14">
        <VerticalTabs>
          <VerticalTabList>
            {tabs.map((tab, i) => (
              <VerticalTab key={tab.path} ref={tab.ref} tab={tab} prevTab={tabs[i - 1]} />
            ))}
            <ActiveTabBackground tabs={tabs} />
          </VerticalTabList>
          <VerticalTabDivider />
          <VerticalTabPanel>
            <Outlet context={{ organization }} />
          </VerticalTabPanel>
        </VerticalTabs>
      </PageContent>
    </Page>
  );
}
