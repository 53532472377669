/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type SyncDisplaysModal_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }> };

export type SyncPowerScheduleToDisplaysMutationVariables = Types.Exact<{
  input: Types.PowerScheduleSyncDisplaysInput;
}>;


export type SyncPowerScheduleToDisplaysMutation = { __typename: 'Mutation', powerScheduleSyncDisplays: { __typename: 'PowerScheduleSyncDisplaysPayload', displays: Array<{ __typename: 'Display', id: string }> } };

export const SyncDisplaysModal_PowerScheduleFragmentDoc = gql`
    fragment SyncDisplaysModal_powerSchedule on PowerSchedule {
  id
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
}
    `;
export const SyncPowerScheduleToDisplaysDocument = gql`
    mutation SyncPowerScheduleToDisplays($input: PowerScheduleSyncDisplaysInput!) {
  powerScheduleSyncDisplays(input: $input) {
    displays {
      id
    }
  }
}
    `;
export type SyncPowerScheduleToDisplaysMutationFn = ApolloReactCommon.MutationFunction<SyncPowerScheduleToDisplaysMutation, SyncPowerScheduleToDisplaysMutationVariables>;

/**
 * __useSyncPowerScheduleToDisplaysMutation__
 *
 * To run a mutation, you first call `useSyncPowerScheduleToDisplaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPowerScheduleToDisplaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPowerScheduleToDisplaysMutation, { data, loading, error }] = useSyncPowerScheduleToDisplaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncPowerScheduleToDisplaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncPowerScheduleToDisplaysMutation, SyncPowerScheduleToDisplaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SyncPowerScheduleToDisplaysMutation, SyncPowerScheduleToDisplaysMutationVariables>(SyncPowerScheduleToDisplaysDocument, options);
      }
export type SyncPowerScheduleToDisplaysMutationHookResult = ReturnType<typeof useSyncPowerScheduleToDisplaysMutation>;
export type SyncPowerScheduleToDisplaysMutationResult = ApolloReactCommon.MutationResult<SyncPowerScheduleToDisplaysMutation>;
export type SyncPowerScheduleToDisplaysMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncPowerScheduleToDisplaysMutation, SyncPowerScheduleToDisplaysMutationVariables>;