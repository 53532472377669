import 'react-datepicker/dist/react-datepicker.css';
import { gql } from '@apollo/client';
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplayRebootModal_DisplayFragment,
  useDisplayBulkRebootMutation,
} from '../summary/__generated__/DisplayRebootModal.graphql';

interface Props {
  display: DisplayRebootModal_DisplayFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}

const schema = z.object({
  displayId: z.string(),
  plan: z.enum(['now', 'plan']),
  plannedAt: z.date().optional().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

export function DisplayRebootModal({ display, isOpen, onCancel, onSuccess }: Props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      displayId: display.id,
      plan: 'now',
    },
    resolver: zodResolver(schema),
  });

  const watchPlan = watch('plan');

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const [reboot] = useDisplayBulkRebootMutation();
  const toast = useToast();

  const analytics = useAnalyticsReporter();

  const performSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await reboot({
          variables: {
            input: {
              displayIds: [values.displayId],
              plannedAt: values.plannedAt?.toJSON() ?? null,
            },
          },
        });

        toast({
          status: 'success',
          title: 'Reboot planned',
          description: `The reboot has been planned`,
        });

        if (!isNil(values.plannedAt)) {
          analytics.track('displayPlanAction', { action: 'reboot' });
        }

        reset();
        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot reboot the display',
          description: fromError(err, 'Reboot'),
        });
      }
    },
    [analytics, onSuccess, reboot, reset, toast],
  );

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reboot Display</ModalHeader>
        <ModalCloseButton tabIndex={5} />
        <form onSubmit={handleSubmit(performSubmit)}>
          <ModalBody>
            <Stack direction={'column'} spacing={8}>
              <InfoAlert>When rebooting, the display will be briefly disconnected.</InfoAlert>
              <FormControl>
                <Controller
                  name="plan"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      tabIndex={2}
                      defaultValue={'now'}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      <Stack spacing={8} direction={'row'}>
                        <Radio value="now">Reboot now</Radio>
                        <Radio value="plan">Reboot later</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
              {watchPlan === 'plan' && (
                <FormControl>
                  <Controller
                    name="plannedAt"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        tabIndex={3}
                        showTimeSelect
                        placeholderText="Specify a time"
                        selected={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        popperPlacement="bottom-start"
                        filterTime={(time) => {
                          const currentDate = new Date();
                          const selectedDate = new Date(time);

                          return currentDate.getTime() < selectedDate.getTime();
                        }}
                        filterDate={(time) => {
                          const currentDate = new Date();
                          const selectedDate = new Date(time);

                          return currentDate < selectedDate;
                        }}
                        showPopperArrow={false}
                      />
                    )}
                  />
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleClose}
              variant="ghost"
              colorScheme="blue"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Confirm
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

DisplayRebootModal.graphql = {
  fragments: {
    DisplayRebootModal_display: gql`
      fragment DisplayRebootModal_display on Display {
        id
        reboot {
          latestJob {
            __typename
            id
            createdAt
            plannedAt
            ... on RebootJobCompleted {
              completedAt
            }
            ... on RebootJobAborted {
              abortedAt
            }
          }
        }
      }
    `,
  },
  mutations: {
    DisplayBulkReboot: gql`
      mutation DisplayBulkReboot($input: DisplayBulkRebootInput!) {
        displayBulkReboot(input: $input) {
          displays {
            id
            ...DisplayRebootModal_display
          }
        }
      }
    `,
  },
};
