/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CustomersGrid_CustomerFragmentDoc } from '../../../../components/customers/__generated__/CustomersGrid.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CustomersIndexPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomersIndexPageQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, customers: Array<{ __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null, displayCount: number, displayUsage?: { __typename: 'UsageStatistics', presence: { __typename: 'PresenceStatistics', disconnected: number }, powerState: { __typename: 'PowerStateStatistics', unplannedStandBy: number }, warnings: { __typename: 'WarningStatistics', emptyShadowWarnings: number, recommendedSettingsWarnings: number, undefinedRecommendedSettingsWarnings: number, outOfSyncPowerScheduleWarnings: number, outOfSyncPlaylistWarnings: number, playlistSyncFailedWarnings: number, powerScheduleSyncFailedWarnings: number } } | null, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null }> } };


export const CustomersIndexPageDocument = gql`
    query CustomersIndexPage {
  organization {
    id
    customers {
      id
      ...CustomersGrid_customer
    }
  }
}
    ${CustomersGrid_CustomerFragmentDoc}`;

/**
 * __useCustomersIndexPageQuery__
 *
 * To run a query within a React component, call `useCustomersIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersIndexPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomersIndexPageQuery, CustomersIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomersIndexPageQuery, CustomersIndexPageQueryVariables>(CustomersIndexPageDocument, options);
      }
export function useCustomersIndexPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersIndexPageQuery, CustomersIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomersIndexPageQuery, CustomersIndexPageQueryVariables>(CustomersIndexPageDocument, options);
        }
export type CustomersIndexPageQueryHookResult = ReturnType<typeof useCustomersIndexPageQuery>;
export type CustomersIndexPageLazyQueryHookResult = ReturnType<typeof useCustomersIndexPageLazyQuery>;
export type CustomersIndexPageQueryResult = ApolloReactCommon.QueryResult<CustomersIndexPageQuery, CustomersIndexPageQueryVariables>;