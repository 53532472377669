/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type SubscriptionsTableSubscriptionFragment = { __typename: 'WaveSubscription', id: string, name: string, createdAt: any, validUntil: any, customers: Array<{ __typename: 'PartialCustomer', name: string }>, usage: { __typename: 'SubscriptionUsage', max?: number | null, current: number } };

export const SubscriptionsTableSubscriptionFragmentDoc = gql`
    fragment SubscriptionsTableSubscription on WaveSubscription {
  id
  name
  customers {
    name
  }
  createdAt
  validUntil
  usage {
    max
    current
  }
}
    `;