import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { PlaylistTable_PlaylistFragment } from '../__generated__/PlaylistTable.graphql';

export function DescriptionCell({
  value,
}: PropsWithChildren<CellProps<PlaylistTable_PlaylistFragment, string>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {value}
    </Box>
  );
}

DescriptionCell.graphql = {
  fragments: {
    DescriptionCell_playlist: gql`
      fragment DescriptionCell_playlist on Playlist {
        id
        description
      }
    `,
  },
};
