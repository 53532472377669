import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';
import { TableInstance } from 'react-table';
import { SearchIcon } from '~components/ui/icons';
import { CustomerUsersTableUserFragment } from './__generated__/CustomerUsersTable.graphql';

type Props = {
  table: TableInstance<CustomerUsersTableUserFragment>;
};

export function CustomerUserTableFilter({ table }: Props) {
  const handleCustomerUserSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    // We are filtering on both the name _and_ roles of a user, so we use a global filter instead of a column filter
    table.setGlobalFilter(event.target.value ?? '');
  };

  return (
    <InputGroup width="full">
      <InputLeftElement pointerEvents="none" height="full">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        type="search"
        placeholder="Search users"
        onChange={handleCustomerUserSearch}
        height="12"
      />
    </InputGroup>
  );
}
