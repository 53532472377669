import {
  Box,
  Button,
  chakra,
  List,
  ListItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { LoadingIcon, SuccessIcon, WarningIcon } from '~components/ui/icons';
import { RecommendedSettingsWarning } from '../useRecommendedSettings';
import { useWizard } from './WizardContext';

export function StepApplySettings() {
  const { state, applySettings, completeSettings, skipApplySettings } = useWizard();

  useEffect(() => {
    applySettings();
    // only run on initial render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.step.name !== 'apply_settings') {
    return null;
  }

  return (
    <>
      <ModalHeader>Preparing</ModalHeader>
      <ModalBody paddingBottom="8">
        <Stack direction="column" spacing="4">
          <Text fontSize="md" color="gray.600">
            We&apos;ve noticed your display isn&apos;t in the recommended settings to retain an
            optimal connection to the WAVE Platform.
          </Text>
        </Stack>
        <Box marginTop="6">
          <ApplySettingsWarnings warnings={state.step.warnings} />
        </Box>
      </ModalBody>
      <ModalFooter display="flex" alignItems="center" paddingBottom="6" overflowX="hidden">
        <AnimatePresence initial={false} mode="wait">
          {state.step.isApplying && (
            <motion.div
              key="apply_settings_spinner"
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, x: 100 }}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '48px', // prevent layout shift when contine button comes in.
              }}
            >
              <chakra.span fontSize="sm" fontWeight="semibold" color="gray.600">
                Please wait while we prepare the display.
              </chakra.span>
            </motion.div>
          )}
          {state.step.isFinished ? (
            state.step.error ? (
              <motion.div
                key="apply_settings_try_again"
                initial={{ opacity: 0, scale: 0.4 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    delay: state.step.warnings.length * 0.1,
                    duration: 0.3,
                  },
                }}
                exit={{ opacity: 0, scale: 0, transition: { duration: 0.2 } }}
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  marginRight="3"
                  onClick={skipApplySettings}
                >
                  Skip for now
                </Button>
                <Button variant="solid" colorScheme="orange" onClick={applySettings}>
                  Try again
                </Button>
              </motion.div>
            ) : (
              <motion.div
                key="apply_settings_next"
                initial={{ opacity: 0, scale: 0.4 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    delay: state.step.warnings.length * 0.1,
                    duration: 0.3,
                  },
                }}
                exit={{ opacity: 0, scale: 0.4 }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Button variant="solid" colorScheme="blue" onClick={completeSettings}>
                  Next
                </Button>
              </motion.div>
            )
          ) : null}
        </AnimatePresence>
      </ModalFooter>
    </>
  );
}

const MotionList = motion(List);
const MotionListItem = motion(ListItem);

const listVariants = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const itemVariants = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -20 },
};

function ApplySettingsWarnings({ warnings }: { warnings: RecommendedSettingsWarning[] }) {
  const { state } = useWizard();

  const getWarningMessage = useCallback((warning: RecommendedSettingsWarning) => {
    switch (warning.code) {
      case 'POWER_MODE_1_NOT_RECOMMENDED':
      case 'POWER_MODE_2_NOT_RECOMMENDED':
        return 'Adjusting PowerMode';
      case 'APM_MODE_1_NOT_RECOMMENDED':
      case 'APM_OFF_NOT_RECOMMENDED':
        return 'Adjusting APM';
      case 'STAND_BY_MODE_GREEN_NOT_RECOMMENDED':
        return 'Adjusting stand-by-mode';
      default:
        return 'Adjusting power settings';
    }
  }, []);

  return (
    <MotionList
      color="gray.600"
      spacing="3"
      paddingX="2"
      initial="hidden"
      animate="visible"
      variants={listVariants}
    >
      {warnings.map((warning, i) => (
        <MotionListItem key={warning.code} display="flex" variants={itemVariants}>
          <Box marginRight="4" display="flex" justifyContent="center" alignItems="center">
            <AnimatePresence mode="wait">
              <>
                {state.step.name === 'apply_settings' &&
                  state.step.isFinished &&
                  !state.step.error && (
                    <motion.div
                      key={`warning-${i}-success`}
                      initial={{ opacity: 0, scale: 0.4 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                      transition={{ delay: i * 0.3 }}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <SuccessIcon height="6" width="6" color="green.500" />
                    </motion.div>
                  )}
                {state.step.name === 'apply_settings' &&
                  state.step.isFinished &&
                  state.step.error && (
                    <motion.div
                      key={`warning-${i}-success`}
                      initial={{ opacity: 0, scale: 0.4 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                      transition={{ delay: i * 0.3 }}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <WarningIcon height="6" width="6" color="red.300" />
                    </motion.div>
                  )}
                {state.step.name === 'apply_settings' && state.step.isApplying && (
                  <motion.div
                    key={`warning-${i}-applying`}
                    initial={{ opacity: 0, scale: 0.4 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{ delay: i * 0.3 }}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <LoadingIcon height="6" width="6" color="gray.400" />
                  </motion.div>
                )}
              </>
            </AnimatePresence>
          </Box>
          {getWarningMessage(warning)}
        </MotionListItem>
      ))}
    </MotionList>
  );
}
