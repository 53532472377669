/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkManagePowerSchedule_CustomerFragment = { __typename: 'Customer', id: string, powerSchedules: Array<{ __typename: 'PowerSchedule', id: string, title: string }> };

export type BulkManageUpdatePowerScheduleMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdatePowerScheduleInput;
}>;


export type BulkManageUpdatePowerScheduleMutation = { __typename: 'Mutation', displayBulkUpdatePowerSchedule: { __typename: 'DisplayBulkUpdatePowerSchedulePayload', displays: Array<{ __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', id: string, title: string } | null } | null }> } };

export const BulkManagePowerSchedule_CustomerFragmentDoc = gql`
    fragment BulkManagePowerSchedule_customer on Customer {
  id
  powerSchedules {
    id
    title
  }
}
    `;
export const BulkManageUpdatePowerScheduleDocument = gql`
    mutation BulkManageUpdatePowerSchedule($input: DisplayBulkUpdatePowerScheduleInput!) {
  displayBulkUpdatePowerSchedule(input: $input) {
    displays {
      id
      powerSchedule {
        schedule {
          id
          title
        }
      }
    }
  }
}
    `;
export type BulkManageUpdatePowerScheduleMutationFn = ApolloReactCommon.MutationFunction<BulkManageUpdatePowerScheduleMutation, BulkManageUpdatePowerScheduleMutationVariables>;

/**
 * __useBulkManageUpdatePowerScheduleMutation__
 *
 * To run a mutation, you first call `useBulkManageUpdatePowerScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkManageUpdatePowerScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkManageUpdatePowerScheduleMutation, { data, loading, error }] = useBulkManageUpdatePowerScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkManageUpdatePowerScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkManageUpdatePowerScheduleMutation, BulkManageUpdatePowerScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkManageUpdatePowerScheduleMutation, BulkManageUpdatePowerScheduleMutationVariables>(BulkManageUpdatePowerScheduleDocument, options);
      }
export type BulkManageUpdatePowerScheduleMutationHookResult = ReturnType<typeof useBulkManageUpdatePowerScheduleMutation>;
export type BulkManageUpdatePowerScheduleMutationResult = ApolloReactCommon.MutationResult<BulkManageUpdatePowerScheduleMutation>;
export type BulkManageUpdatePowerScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkManageUpdatePowerScheduleMutation, BulkManageUpdatePowerScheduleMutationVariables>;