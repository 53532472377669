/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type SyncDisplaysModal_PlaylistFragment = { __typename: 'Playlist', id: string, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }> };

export type SyncPlaylistToDisplaysMutationVariables = Types.Exact<{
  input: Types.PlaylistSyncDisplaysInput;
}>;


export type SyncPlaylistToDisplaysMutation = { __typename: 'Mutation', playlistSyncDisplays: { __typename: 'PlaylistSyncDisplaysPayload', displays: Array<{ __typename: 'Display', id: string }> } };

export const SyncDisplaysModal_PlaylistFragmentDoc = gql`
    fragment SyncDisplaysModal_playlist on Playlist {
  id
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
}
    `;
export const SyncPlaylistToDisplaysDocument = gql`
    mutation SyncPlaylistToDisplays($input: PlaylistSyncDisplaysInput!) {
  playlistSyncDisplays(input: $input) {
    displays {
      id
    }
  }
}
    `;
export type SyncPlaylistToDisplaysMutationFn = ApolloReactCommon.MutationFunction<SyncPlaylistToDisplaysMutation, SyncPlaylistToDisplaysMutationVariables>;

/**
 * __useSyncPlaylistToDisplaysMutation__
 *
 * To run a mutation, you first call `useSyncPlaylistToDisplaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPlaylistToDisplaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPlaylistToDisplaysMutation, { data, loading, error }] = useSyncPlaylistToDisplaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncPlaylistToDisplaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncPlaylistToDisplaysMutation, SyncPlaylistToDisplaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SyncPlaylistToDisplaysMutation, SyncPlaylistToDisplaysMutationVariables>(SyncPlaylistToDisplaysDocument, options);
      }
export type SyncPlaylistToDisplaysMutationHookResult = ReturnType<typeof useSyncPlaylistToDisplaysMutation>;
export type SyncPlaylistToDisplaysMutationResult = ApolloReactCommon.MutationResult<SyncPlaylistToDisplaysMutation>;
export type SyncPlaylistToDisplaysMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncPlaylistToDisplaysMutation, SyncPlaylistToDisplaysMutationVariables>;