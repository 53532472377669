import { Box, Center, Portal, Spinner } from '@chakra-ui/react';

export function PageLoader() {
  /**
   * The page spinner is used for full page loading experiences.
   * Because we have multiple levels of nesting we portal the spinner out of the body
   * this allows it to stay vertically centered without layout shifting.
   */
  return (
    <Portal>
      <Box position="absolute" top="0" bottom="0" left="0" right="0" height="100vh">
        <Center position="relative" height="100vh">
          <Spinner size="lg" />
        </Center>
      </Box>
    </Portal>
  );
}
