import { useCallback, useState } from 'react';

export async function uploadToS3(signedUrl: string, file: File) {
  const request = await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });

  if (!request.ok) {
    throw new Error('upload failed');
  }
}

export function useS3Upload() {
  const [isUploading, setIsUploading] = useState(false);

  const upload = useCallback(async (signedUrl: string, file: File) => {
    setIsUploading(true);

    await uploadToS3(signedUrl, file).finally(() => setIsUploading(false));
  }, []);

  return {
    upload,
    isUploading,
  };
}
