/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { CustomersGridItem_CustomerFragmentDoc } from './CustomersGridItem.graphql';
import { DeleteCustomer_CustomerFragmentDoc } from '../mutations/__generated__/DeleteCustomer.graphql';
export type CustomersGrid_CustomerFragment = { __typename: 'Customer', id: string, handle: string, name: string, avatarUrl?: string | null, displayCount: number, displayUsage?: { __typename: 'UsageStatistics', presence: { __typename: 'PresenceStatistics', disconnected: number }, powerState: { __typename: 'PowerStateStatistics', unplannedStandBy: number }, warnings: { __typename: 'WarningStatistics', emptyShadowWarnings: number, recommendedSettingsWarnings: number, undefinedRecommendedSettingsWarnings: number, outOfSyncPowerScheduleWarnings: number, outOfSyncPlaylistWarnings: number, playlistSyncFailedWarnings: number, powerScheduleSyncFailedWarnings: number } } | null, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null };

export const CustomersGrid_CustomerFragmentDoc = gql`
    fragment CustomersGrid_customer on Customer {
  id
  ...CustomersGridItem_customer
  ...DeleteCustomer_customer
}
    ${CustomersGridItem_CustomerFragmentDoc}
${DeleteCustomer_CustomerFragmentDoc}`;