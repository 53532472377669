import { useCallback, useState } from 'react';

export function useLocalStorage<TValue>(key: string, initialValue: TValue) {
  const [storedValue, setStoredValue] = useState<TValue>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = useCallback(
    (value: TValue | ((current: TValue) => TValue)) => {
      try {
        setStoredValue((prevValue) => {
          const valueToStore = value instanceof Function ? value(prevValue) : value;
          window.localStorage.setItem(key, JSON.stringify(valueToStore));

          return valueToStore;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );
  return [storedValue, setValue] as const;
}
