import { Box, Button, chakra, Checkbox, Heading, Link as ChakraLink, Text } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Header } from '~components/ui/Header';
import { LogoWideIllustration } from '~components/ui/illustrations';
import BackgroundImage from '../../components/ui/assets/wave_background.jpg';

export function ConsentPage() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const renew = searchParams.get('renew');
  const title = renew ? "We've updated our terms and conditions" : 'Welcome to Wave';
  const terms = (
    <ChakraLink
      color="blue.500"
      target="_blank"
      rel="noopener noreferrer"
      href="https://wave.ppds.com/legal/terms-of-use"
    >
      terms and conditions
    </ChakraLink>
  );
  const privacyPolicy = (
    <ChakraLink
      color="blue.500"
      target="_blank"
      rel="noopener noreferrer"
      href="https://wave.ppds.com/legal/privacy-policy"
    >
      privacy policy
    </ChakraLink>
  );

  const [consented, setConsented] = useState(false);

  if (!state) {
    return <Navigate to={`/`} replace />;
  }

  return (
    <Header.Provider>
      <Layout>
        <Card>
          <form method="GET" action="https://auth.wave.ppds.com/continue">
            <input type="hidden" id="state" name="state" value={state} />

            <Box textAlign="center">
              <Heading color="blue.800" fontSize="20px">
                {title}
              </Heading>

              <Text pt="4">
                Before you start using Wave, please carefully read our {terms} and {privacyPolicy}.
              </Text>

              <Checkbox
                mt="16"
                id="consented"
                name="consented"
                value="yes"
                isChecked={consented}
                onChange={(e) => setConsented(e.target.checked)}
              >
                <chakra.span fontSize="md">I have read and accept these conditions.</chakra.span>
              </Checkbox>

              <Button mt="8" width="100%" isDisabled={!consented} type="submit" colorScheme="blue">
                Continue
              </Button>
            </Box>
          </form>
        </Card>
      </Layout>
    </Header.Provider>
  );
}

function Layout({ children }: { children: ReactNode }) {
  return (
    <Box
      backgroundImage={`url(${BackgroundImage})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      height="100%"
      overflow="hidden"
    >
      {children}
    </Box>
  );
}

function Card({ children }: { children: ReactNode }) {
  return (
    <Box
      position="absolute"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      maxWidth="490px"
      width="100%"
      background="white"
      boxShadow="0px 10px 15px -3px rgba(0, 56, 95, 0.1),
    0px 4px 6px -2px rgba(0, 56, 95, 0.05)"
      borderRadius="base"
    >
      <Box paddingX="10" paddingY="12">
        <Box marginBottom={10} textAlign="center">
          <LogoWideIllustration
            width="94px"
            height="94px"
            style={{ margin: 'auto', display: 'block' }}
          />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
