import { createIcon } from '@chakra-ui/react';

export const ExternalLinkIcon = createIcon({
  displayName: 'ExternalLinkIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M19 13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13H19ZM3 19H2H3ZM5 6V5V6ZM11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5V7ZM15 2C14.4477 2 14 2.44772 14 3C14 3.55228 14.4477 4 15 4V2ZM21 3H22C22 2.44772 21.5523 2 21 2V3ZM20 9C20 9.55228 20.4477 10 21 10C21.5523 10 22 9.55228 22 9H20ZM9.29289 13.2929C8.90237 13.6834 8.90237 14.3166 9.29289 14.7071C9.68342 15.0976 10.3166 15.0976 10.7071 14.7071L9.29289 13.2929ZM17 13V19H19V13H17ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071L18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19H17ZM16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20V22C16.7957 22 17.5587 21.6839 18.1213 21.1213L16.7071 19.7071ZM16 20H5V22H16V20ZM5 20C4.73478 20 4.48043 19.8946 4.29289 19.7071L2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22V20ZM4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19H2C2 19.7957 2.31607 20.5587 2.87868 21.1213L4.29289 19.7071ZM4 19V8H2V19H4ZM4 8C4 7.73478 4.10536 7.48043 4.29289 7.29289L2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8H4ZM4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7V5C4.20435 5 3.44129 5.31607 2.87868 5.87868L4.29289 7.29289ZM5 7H11V5H5V7ZM15 4H21V2H15V4ZM20 3V9H22V3H20ZM10.7071 14.7071L21.7071 3.70711L20.2929 2.29289L9.29289 13.2929L10.7071 14.7071Z"
      fill="currentColor"
    />
  ),
});
