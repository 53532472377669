import { Box, Button, Portal, Stack, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import gql from 'graphql-tag';
import { useCallback, useMemo } from 'react';
import { CONTAINER_MAX_WIDTH } from '~components/ui/PageContent';
import { useDisplayPresence } from '../useDisplayPresence';
import { useRecommendedSettings } from '../useRecommendedSettings';
import { RecommendedSettingsBanner_DisplayFragment } from './__generated__/RecommendedSettingsBanner.graphql';

interface Props {
  display: RecommendedSettingsBanner_DisplayFragment;
}

export function RecommendedSettingsBanner({ display }: Props) {
  const { getPresence } = useDisplayPresence();
  const { isConnected } = useMemo(() => getPresence(display), [display, getPresence]);
  const { getSettingsState, applyRecommended } = useRecommendedSettings();
  const settings = useMemo(() => getSettingsState(display), [display, getSettingsState]);
  const onApplyRecommended = useCallback(async () => {
    return await applyRecommended(display);
  }, [applyRecommended, display]);

  const Icon = settings.styles.Icon;

  const Warning = useCallback(() => {
    if (settings.kind === 'recommended') {
      return null;
    }

    if (settings.kind === 'not_reported') {
      return (
        <Text fontSize="sm">
          Without the correct settings the display might be disconnected when put into stand-by.
        </Text>
      );
    }

    return (
      <>
        {settings.warnings.map((warning) => (
          <Text key={warning.code} fontSize="sm">
            {warning.message}
          </Text>
        ))}
      </>
    );
  }, [settings]);

  return (
    <Portal>
      <AnimatePresence>
        {settings.kind !== 'recommended' && (
          <motion.div
            style={{
              position: 'fixed',
              bottom: '0',
              width: '100%',
            }}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.2 }}
          >
            <Box
              position="relative"
              background={`${settings.styles.colorScheme}.50`}
              color={`${settings.styles.colorScheme}.600`}
              borderTop="1px solid"
              borderColor={`${settings.styles.colorScheme}.400`}
            >
              <Box marginX="auto" maxWidth={CONTAINER_MAX_WIDTH} padding="3">
                <Stack direction="row" spacing="4">
                  <Box>
                    <Icon height="6" width="6" />
                  </Box>
                  <Box flex="1">
                    <Text fontSize="md" fontWeight="semibold">
                      {settings.kind === 'not_reported' &&
                        'Display has not reported any settings information.'}
                      {settings.kind === 'not_recommended' &&
                        'Display is not using the recommended settings.'}
                    </Text>
                    <Warning />
                  </Box>
                  {settings.kind === 'not_recommended' && (
                    <Box maxW="64" display="flex" alignItems="center">
                      <AnimatePresence initial={false} mode="wait">
                        {!isConnected && settings.isApplyingRecommended ? (
                          <motion.div
                            key="apply_recommended_disconnected"
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                          >
                            <Text fontSize="xs">
                              Settings will be applied when the display reconnects.
                            </Text>
                          </motion.div>
                        ) : (
                          <motion.div
                            key="apply_recommended_connected"
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                          >
                            <Button
                              variant="outline"
                              size="sm"
                              colorScheme={settings.styles.colorScheme}
                              isDisabled={settings.isApplyingRecommended}
                              isLoading={settings.isApplyingRecommended}
                              onClick={onApplyRecommended}
                            >
                              {isConnected ? 'Apply' : 'Apply when connected'}
                            </Button>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
}

RecommendedSettingsBanner.graphql = {
  fragments: {
    RecommendedSettingsBanner_display: gql`
      fragment RecommendedSettingsBanner_display on Display {
        id
        ...UseRecommendedSettings_display
        ...UseDisplayPresence_display
      }
    `,
  },
};
