/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayPortsControlToggle_DisplayFragment = { __typename: 'Display', id: string, portsControl?: { __typename: 'PortsControlState', desired?: Types.PortsControlLockState | null, reported: Types.PortsControlLockState, supportedValues: Array<Types.PortsControlLockState> } | null };

export type UpdatePortsControlMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdatePortsControlInput;
}>;


export type UpdatePortsControlMutation = { __typename: 'Mutation', displayBulkUpdatePortsControl: { __typename: 'DisplayBulkUpdatePortsControlPayload', displays: Array<{ __typename: 'Display', id: string, portsControl?: { __typename: 'PortsControlState', desired?: Types.PortsControlLockState | null, reported: Types.PortsControlLockState, supportedValues: Array<Types.PortsControlLockState> } | null }> } };

export const DisplayPortsControlToggle_DisplayFragmentDoc = gql`
    fragment DisplayPortsControlToggle_display on Display {
  id
  portsControl {
    desired
    reported
    supportedValues
  }
}
    `;
export const UpdatePortsControlDocument = gql`
    mutation UpdatePortsControl($input: DisplayBulkUpdatePortsControlInput!) {
  displayBulkUpdatePortsControl(input: $input) {
    displays {
      id
      portsControl {
        desired
        reported
        supportedValues
      }
    }
  }
}
    `;
export type UpdatePortsControlMutationFn = ApolloReactCommon.MutationFunction<UpdatePortsControlMutation, UpdatePortsControlMutationVariables>;

/**
 * __useUpdatePortsControlMutation__
 *
 * To run a mutation, you first call `useUpdatePortsControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortsControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortsControlMutation, { data, loading, error }] = useUpdatePortsControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePortsControlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePortsControlMutation, UpdatePortsControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePortsControlMutation, UpdatePortsControlMutationVariables>(UpdatePortsControlDocument, options);
      }
export type UpdatePortsControlMutationHookResult = ReturnType<typeof useUpdatePortsControlMutation>;
export type UpdatePortsControlMutationResult = ApolloReactCommon.MutationResult<UpdatePortsControlMutation>;
export type UpdatePortsControlMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePortsControlMutation, UpdatePortsControlMutationVariables>;