import { useMemo } from 'react';
import { useFeatureFlag } from './features';

interface Playlist {
  __typename: 'Playlist';
}

interface TimeBlock {
  __typename: 'TimeBlock';
}

interface PowerSchedule {
  __typename: 'PowerSchedule';
}

interface Customer {
  __typename: 'Customer';
}

export function useLimits() {
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const playlistsNumberPerCustomerLimit = Number(
    process.env.REACT_APP_PLAYLISTS_NUMBER_PER_CUSTOMER_LIMIT ?? 0,
  );

  const playlistsNumberPerLiteCustomerLimit = Number(
    process.env.REACT_APP_PLAYLISTS_NUMBER_PER_LITE_CUSTOMER_LIMIT ?? 0,
  );

  const customersNumberPerOrganizationLimit = Number(
    process.env.REACT_APP_CUSTOMERS_PER_ORGANIZATION_LIMIT ?? 0,
  );

  const powerSchedulesNumberPerCustomerLimit = Number(
    process.env.REACT_APP_POWER_SCHEDULES_PER_CUSTOMER_LIMIT ?? 0,
  );

  const powerSchedulesNumberPerLiteCustomerLimit = Number(
    process.env.REACT_APP_POWER_SCHEDULES_PER_LITE_CUSTOMER_LIMIT ?? 0,
  );

  const timeBlocksNumberPerScheduleLimit = Number(
    process.env.REACT_APP_POWER_SCHEDULES_TIME_BLOCKS_LIMIT ?? 0,
  );

  const maximumPlaylistMediaEntries = Number(
    process.env.REACT_APP_PLAYLISTS_MAXIMUM_MEDIA_ENTRIES ?? 0,
  );

  return useMemo(
    () => ({
      playlists: {
        playlistsNumberPerCustomerLimit,
        maximumPlaylistMediaEntries,
        isPlaylistsLimitPerCustomerReached: (
          playlists: Playlist[] | null | undefined,
          hasEssentialSubscription: boolean,
        ) => {
          if (isSubscriptionsEnabled) {
            return hasEssentialSubscription
              ? Boolean(
                  playlists &&
                    playlistsNumberPerCustomerLimit > 0 &&
                    playlists.length >= playlistsNumberPerCustomerLimit,
                )
              : Boolean(
                  playlists &&
                    playlistsNumberPerLiteCustomerLimit > 0 &&
                    playlists.length >= playlistsNumberPerLiteCustomerLimit,
                );
          } else {
            return Boolean(
              playlists &&
                playlistsNumberPerCustomerLimit > 0 &&
                playlists.length >= playlistsNumberPerCustomerLimit,
            );
          }
        },
        isPlaylistMaximumFileLimitExceeded: (value: number) => value > maximumPlaylistMediaEntries,
        isPlaylistMaximumFileLimitReached: (value: number) => value >= maximumPlaylistMediaEntries,
      },
      customers: {
        customersNumberPerOrganizationLimit,
        isCustomersLimitPerOrganizationReached: (customers: Customer[] | null | undefined) =>
          Boolean(
            customers &&
              customersNumberPerOrganizationLimit > 0 &&
              customers.length >= customersNumberPerOrganizationLimit,
          ),
      },
      powerSchedules: {
        powerSchedulesNumberPerCustomerLimit,
        isPowerSchedulesLimitPerCustomerReached: (
          powerSchedules: PowerSchedule[] | null | undefined,
          hasEssentialSubscription: boolean,
        ) => {
          if (isSubscriptionsEnabled) {
            return hasEssentialSubscription
              ? Boolean(
                  powerSchedules &&
                    powerSchedulesNumberPerCustomerLimit > 0 &&
                    powerSchedules.length >= powerSchedulesNumberPerCustomerLimit,
                )
              : Boolean(
                  powerSchedules &&
                    powerSchedulesNumberPerLiteCustomerLimit > 0 &&
                    powerSchedules.length >= powerSchedulesNumberPerLiteCustomerLimit,
                );
          } else {
            return Boolean(
              powerSchedules &&
                powerSchedulesNumberPerCustomerLimit > 0 &&
                powerSchedules.length >= powerSchedulesNumberPerCustomerLimit,
            );
          }
        },
        timeBlocksNumberPerScheduleLimit,
        isTimeBlocksLimitPerScheduleReached: (timeBlocks: TimeBlock[] | null | undefined) =>
          Boolean(
            timeBlocks &&
              timeBlocksNumberPerScheduleLimit > 0 &&
              timeBlocks.length >= timeBlocksNumberPerScheduleLimit,
          ),
      },
    }),
    [
      isSubscriptionsEnabled,
      customersNumberPerOrganizationLimit,
      playlistsNumberPerCustomerLimit,
      playlistsNumberPerLiteCustomerLimit,
      maximumPlaylistMediaEntries,
      powerSchedulesNumberPerCustomerLimit,
      powerSchedulesNumberPerLiteCustomerLimit,
      timeBlocksNumberPerScheduleLimit,
    ],
  );
}
