/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkSyncDisplays_DisplayFragment = { __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, isOutOfSync: boolean } | null } | null };

export type BulkSyncDisplaysMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdatePlaylistInput;
}>;


export type BulkSyncDisplaysMutation = { __typename: 'Mutation', displayBulkUpdatePlaylist: { __typename: 'DisplayBulkUpdatePlaylistPayload', displays: Array<{ __typename: 'Display', id: string }> } };

export const BulkSyncDisplays_DisplayFragmentDoc = gql`
    fragment BulkSyncDisplays_display on Display {
  id
  playlist {
    current {
      id
      isOutOfSync
    }
  }
}
    `;
export const BulkSyncDisplaysDocument = gql`
    mutation BulkSyncDisplays($input: DisplayBulkUpdatePlaylistInput!) {
  displayBulkUpdatePlaylist(input: $input) {
    displays {
      id
    }
  }
}
    `;
export type BulkSyncDisplaysMutationFn = ApolloReactCommon.MutationFunction<BulkSyncDisplaysMutation, BulkSyncDisplaysMutationVariables>;

/**
 * __useBulkSyncDisplaysMutation__
 *
 * To run a mutation, you first call `useBulkSyncDisplaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkSyncDisplaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkSyncDisplaysMutation, { data, loading, error }] = useBulkSyncDisplaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkSyncDisplaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkSyncDisplaysMutation, BulkSyncDisplaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkSyncDisplaysMutation, BulkSyncDisplaysMutationVariables>(BulkSyncDisplaysDocument, options);
      }
export type BulkSyncDisplaysMutationHookResult = ReturnType<typeof useBulkSyncDisplaysMutation>;
export type BulkSyncDisplaysMutationResult = ApolloReactCommon.MutationResult<BulkSyncDisplaysMutation>;
export type BulkSyncDisplaysMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkSyncDisplaysMutation, BulkSyncDisplaysMutationVariables>;