/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { AuthorizationProvider_OrganizationFragmentDoc } from '../auth/__generated__/AuthorizationProvider.graphql';
import { LogoSettings_OrganizationFragmentDoc } from '../components/settings/__generated__/LogoSettings.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type App_GetOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type App_GetOrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, handle: string, name: string, developerAccess: boolean, avatarUrl?: string | null } };


export const App_GetOrganizationDocument = gql`
    query App_getOrganization {
  organization {
    id
    handle
    ...AuthorizationProvider_organization
    ...LogoSettings_organization
  }
}
    ${AuthorizationProvider_OrganizationFragmentDoc}
${LogoSettings_OrganizationFragmentDoc}`;

/**
 * __useApp_GetOrganizationQuery__
 *
 * To run a query within a React component, call `useApp_GetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApp_GetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApp_GetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useApp_GetOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<App_GetOrganizationQuery, App_GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<App_GetOrganizationQuery, App_GetOrganizationQueryVariables>(App_GetOrganizationDocument, options);
      }
export function useApp_GetOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<App_GetOrganizationQuery, App_GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<App_GetOrganizationQuery, App_GetOrganizationQueryVariables>(App_GetOrganizationDocument, options);
        }
export type App_GetOrganizationQueryHookResult = ReturnType<typeof useApp_GetOrganizationQuery>;
export type App_GetOrganizationLazyQueryHookResult = ReturnType<typeof useApp_GetOrganizationLazyQuery>;
export type App_GetOrganizationQueryResult = ApolloReactCommon.QueryResult<App_GetOrganizationQuery, App_GetOrganizationQueryVariables>;