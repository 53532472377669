import { useLocation, useNavigate } from 'react-router-dom';

export default function useGoBack() {
  const navigate = useNavigate();
  const location = useLocation();

  return () => {
    if (location.state?.canGoBack === true) {
      navigate(-1);
    } else {
      navigate('..', { relative: 'path' });
    }
  };
}
