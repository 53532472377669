import { Box, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { forwardRef, ReactNode, Ref, useCallback, useEffect, useState } from 'react';
import { Tab } from '~components/ui/Tabs';
import { Link } from './Link';
import { useTheme } from './styles/hooks';

export function HorizontalTabList({ children }: { children: ReactNode }) {
  return (
    <Stack position="relative" direction="row" spacing="0" marginBottom="10">
      {children}
    </Stack>
  );
}

export const HorizontalTab = forwardRef(function Tab(
  { tab: { label, toLink, isActive } }: { tab: Tab },
  ref: Ref<HTMLAnchorElement>,
) {
  return (
    <Link
      ref={ref}
      to={toLink}
      display="flex"
      justifyContent="center"
      paddingY="3"
      marginRight="6"
      color={isActive ? 'blue.500' : 'gray.500'}
      fontWeight="semibold"
      _hover={{
        textDecoration: 'none',
        color: 'blue.500',
      }}
      _focus={{
        textDecoration: 'none',
        color: 'blue.500',
      }}
    >
      {label}
    </Link>
  );
});

export function HorizontalTabPanel({ children }: { children: ReactNode }) {
  return <Box>{children}</Box>;
}

export function TabsUnderline({ tabs }: { tabs: Tab[] }) {
  const theme = useTheme();
  const [animate, setAnimate] = useState({ x: 0, width: '0px' });
  const updateAnimate = useCallback((tab: Tab) => {
    if (!tab.ref?.current) return;

    setAnimate({
      x: tab.ref.current.offsetLeft,
      width: `${tab.ref.current.clientWidth}px`,
    });
  }, []);

  useEffect(() => {
    const activeTab = tabs.find((t) => t.isActive);

    if (activeTab) {
      updateAnimate(activeTab);
    }
  }, [tabs, updateAnimate]);

  return (
    <Box position="absolute" bottom="0" left="0" width="100%" height="2px" background="gray.50">
      <motion.div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          height: '2px',
          background: theme.colors.blue[500],
        }}
        animate={animate}
        transition={{
          duration: 0.3,
          type: 'spring',
          bounce: 0.2,
        }}
      />
    </Box>
  );
}
