/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type LogoSettings_OrganizationFragment = { __typename: 'Organization', id: string, name: string, avatarUrl?: string | null };

export type EditOrganizationLogoMutationVariables = Types.Exact<{
  input: Types.OrganizationUpdateInput;
}>;


export type EditOrganizationLogoMutation = { __typename: 'Mutation', organizationUpdate: { __typename: 'Organization', id: string, name: string, avatarUrl?: string | null } };

export const LogoSettings_OrganizationFragmentDoc = gql`
    fragment LogoSettings_organization on Organization {
  id
  name
  avatarUrl
}
    `;
export const EditOrganizationLogoDocument = gql`
    mutation EditOrganizationLogo($input: OrganizationUpdateInput!) {
  organizationUpdate(input: $input) {
    id
    ...LogoSettings_organization
  }
}
    ${LogoSettings_OrganizationFragmentDoc}`;
export type EditOrganizationLogoMutationFn = ApolloReactCommon.MutationFunction<EditOrganizationLogoMutation, EditOrganizationLogoMutationVariables>;

/**
 * __useEditOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useEditOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationLogoMutation, { data, loading, error }] = useEditOrganizationLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrganizationLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrganizationLogoMutation, EditOrganizationLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditOrganizationLogoMutation, EditOrganizationLogoMutationVariables>(EditOrganizationLogoDocument, options);
      }
export type EditOrganizationLogoMutationHookResult = ReturnType<typeof useEditOrganizationLogoMutation>;
export type EditOrganizationLogoMutationResult = ApolloReactCommon.MutationResult<EditOrganizationLogoMutation>;
export type EditOrganizationLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrganizationLogoMutation, EditOrganizationLogoMutationVariables>;