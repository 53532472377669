/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CreatedAtCell_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, createdAt: any };

export const CreatedAtCell_PowerScheduleFragmentDoc = gql`
    fragment CreatedAtCell_powerSchedule on PowerSchedule {
  id
  createdAt
}
    `;