/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { SubscriptionsTableSubscriptionFragmentDoc } from '../../../../../components/organization/SubscriptionsTable/__generated__/SubscriptionsTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationSubscriptionsIndexPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationSubscriptionsIndexPageQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, waveSubscriptions?: Array<{ __typename: 'WaveSubscription', id: string, name: string, createdAt: any, validUntil: any, customers: Array<{ __typename: 'PartialCustomer', name: string }>, usage: { __typename: 'SubscriptionUsage', max?: number | null, current: number } }> | null } };


export const OrganizationSubscriptionsIndexPageDocument = gql`
    query OrganizationSubscriptionsIndexPage {
  organization {
    id
    waveSubscriptions {
      ...SubscriptionsTableSubscription
    }
  }
}
    ${SubscriptionsTableSubscriptionFragmentDoc}`;

/**
 * __useOrganizationSubscriptionsIndexPageQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionsIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSubscriptionsIndexPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionsIndexPageQuery, OrganizationSubscriptionsIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrganizationSubscriptionsIndexPageQuery, OrganizationSubscriptionsIndexPageQueryVariables>(OrganizationSubscriptionsIndexPageDocument, options);
      }
export function useOrganizationSubscriptionsIndexPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionsIndexPageQuery, OrganizationSubscriptionsIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionsIndexPageQuery, OrganizationSubscriptionsIndexPageQueryVariables>(OrganizationSubscriptionsIndexPageDocument, options);
        }
export type OrganizationSubscriptionsIndexPageQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsIndexPageQuery>;
export type OrganizationSubscriptionsIndexPageLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsIndexPageLazyQuery>;
export type OrganizationSubscriptionsIndexPageQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionsIndexPageQuery, OrganizationSubscriptionsIndexPageQueryVariables>;