/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type DescriptionCell_PlaylistFragment = { __typename: 'Playlist', id: string, description?: string | null };

export const DescriptionCell_PlaylistFragmentDoc = gql`
    fragment DescriptionCell_playlist on Playlist {
  id
  description
}
    `;