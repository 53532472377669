import { gql } from '@apollo/client';
import {
  Box,
  chakra,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { EmptyViewExternalLink } from '~components/EmptyView';
import { SubscriptionCustomersTable } from '~components/organization/SubscriptionCustomersTable';
import { SubscriptionCustomersTableCustomerFragment as CustomerFragment } from '~components/organization/SubscriptionCustomersTable/__generated__/SubscriptionCustomersTable.graphql';
import { useSubscriptionCustomersTable } from '~components/organization/SubscriptionCustomersTable/useSubscriptionCustomersTable';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import useGoBack from '~components/useGoBack';
import { SubscriptionUsage } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { useFeatureFlag } from '~utils/features';
import { isDefined } from '~utils/types';
import {
  SubscriptionEditPage_SubscriptionFragment as SubscriptionFragment,
  useEditSubscriptionMutation,
  useWaveSubscriptionEditPageOrganizationQuery,
} from './__generated__/[id].graphql';

const schema = z.object({
  id: z.string().uuid(),
  customerIds: z.array(z.string()),
});

type FormValues = z.infer<typeof schema>;

export function OrganizationSubscriptionEditPage() {
  const { subscriptionId } = useParams();
  const toast = useToast();
  const handleGoBack = useGoBack();
  const [updateSubscription] = useEditSubscriptionMutation();

  const { data, loading, error, refetch } = useWaveSubscriptionEditPageOrganizationQuery();

  const subscription = data?.organization.waveSubscriptions?.find(
    (subscription) => subscription.id === subscriptionId,
  );
  const customers = data?.organization.customers;

  const updateForm = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      customerIds: subscription?.customers.map(({ id }) => id) ?? [],
    },
  });

  const { isSubmitting, isDirty, isValid } = updateForm.formState;
  const canSubmit = !isSubmitting && isValid && isDirty;

  const handleUpdateSubscription = async ({ id, customerIds }: FormValues) => {
    try {
      const { data: updateData } = await updateSubscription({
        variables: {
          input: {
            subscriptionId: id,
            customerIds,
          },
        },
      });

      if (!isDefined(updateData) || !isDefined(updateData.updateSubscription.id)) {
        throw new Error('Update subscription failed');
      }

      toast({
        status: 'success',
        title: 'Subscription settings were updated successfully',
      });

      await refetch();
    } catch (error) {
      toast({
        status: 'error',
        title: 'Cannot update subscription',
        description: fromError(error, 'UpdateSubscription', {
          NOT_ALLOWED_TO_ASSIGN_ROLE: 'You are not allowed to assign this role.',
          MUST_HAVE_ADMIN: 'You must have at least one admin.',
          ROLES_EMPTY: 'You must select at least one role.',
          UNAUTHORIZED: 'You are not authorized to perform this action.',
          NOT_ALLOWED_ROLE_REMOVAL: 'You are not allowed to remove the selected roles.',
        }),
      });
      updateForm.reset();
    }
  };

  // This page loads data asynchronously, so we need to "reset" the form when the data is available
  // to fill out the default values
  useEffect(() => {
    if (loading === false) {
      updateForm.reset({
        id: subscription?.id,
        customerIds: subscription?.customers.map(({ id }) => id) ?? [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once, when the page is loaded
  }, [loading]);

  return (
    <VerticalTabContent
      title="Edit subscription"
      error={error}
      isLoading={loading}
      data={subscription}
      onGoBack={handleGoBack}
      hasStickyHeader={true}
      action={{
        label: 'Apply changes',
        disabled: !canSubmit,
        isLoading: isSubmitting,
        onClick: updateForm.handleSubmit(handleUpdateSubscription),
      }}
    >
      {(subscription) => (
        <VStack gap="10" alignItems="stretch">
          <SubscriptionDetailGrid
            name={subscription.name}
            usage={subscription.usage}
            customerCount={subscription.customers.length}
            validUntil={subscription.validUntil}
          />
          {subscription.assignable && (
            <EditSubscriptionForm
              subscription={subscription}
              customers={customers ?? []}
              form={updateForm}
            />
          )}
          <SubscriptionDetailManage subscription={subscription} />
        </VStack>
      )}
    </VerticalTabContent>
  );
}

type SubscriptionDetailGridProps = {
  name: string;
  usage: SubscriptionUsage;
  validUntil: string;
  customerCount: number;
};

export function SubscriptionDetailGrid({
  name,
  usage,
  validUntil,
  customerCount,
}: SubscriptionDetailGridProps) {
  return (
    <Box border="1px solid" borderColor="gray.100" borderRadius="base" paddingX="4" paddingY="4">
      <Grid templateColumns="repeat(3, 1fr)" gap={1}>
        <GridItem
          colSpan={3}
          borderBottom="1px solid"
          borderColor="gray.100"
          paddingBottom="1"
          marginBottom="1"
        >
          <chakra.span fontWeight="bold" fontSize="md">
            {name}
          </chakra.span>
        </GridItem>
        <GridItem>
          <chakra.span fontSize="md" color="gray.600">
            Usage
          </chakra.span>
        </GridItem>
        <GridItem>
          <chakra.span fontSize="md" color="gray.600">
            Valid until
          </chakra.span>
        </GridItem>
        <GridItem>
          <chakra.span fontSize="md" color="gray.600">
            Customers
          </chakra.span>
        </GridItem>
        <GridItem>
          <chakra.span fontSize="md">
            {usage.current}/{usage.max}
          </chakra.span>
        </GridItem>
        <GridItem>
          <chakra.span fontSize="md">
            {DateTime.fromISO(validUntil).toFormat('dd/MM/yyyy')}
          </chakra.span>
        </GridItem>
        <GridItem>
          <chakra.span fontSize="md">{customerCount}</chakra.span>
        </GridItem>
      </Grid>
    </Box>
  );
}

type SubscriptionDetailManageProps = {
  subscription: SubscriptionFragment;
};

export function SubscriptionDetailManage({ subscription }: SubscriptionDetailManageProps) {
  const { isEnabled: isAppDirectMarketplaceLinkEnabled } = useFeatureFlag(
    'appDirectMarketplaceLink',
  );

  return (
    <Box>
      <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
        Manage subscription
      </Heading>
      <Text color="gray.500">
        Contact your sales executive to upgrade this subscription.
        <br />
        Please mention this reference <strong>&quot;{subscription.productId}&quot;</strong>.
      </Text>
      {isAppDirectMarketplaceLinkEnabled && (
        <EmptyViewExternalLink
          href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
          label="Manage subscription"
        />
      )}
    </Box>
  );
}

type EditSubscriptionFormProps = {
  subscription: SubscriptionFragment;
  customers: CustomerFragment[];
  form: UseFormReturn<FormValues>;
};

export function EditSubscriptionForm({ subscription, form, customers }: EditSubscriptionFormProps) {
  const [availableSeats, setAvailableSeats] = useState<number>(
    (subscription.usage.max ?? 0) - subscription.usage.current,
  );

  const subscriptionCustomerIds = useMemo(
    () => subscription.customers.map(({ id }) => id),
    [subscription.customers],
  );
  const table = useSubscriptionCustomersTable(
    customers,
    subscriptionCustomerIds,
    availableSeats,
    setAvailableSeats,
  );

  const {
    formState: { isSubmitSuccessful },
    setValue,
    reset,
  } = form;

  // When subscription customer selection changes, update the form data
  useEffect(() => {
    const checkedCustomerIds = Object.keys(table.state.selectedRowIds).sort();
    const sortedSubscriptionCustomerIds = [...subscriptionCustomerIds].sort();

    const hasNumberOfCustomersChanged =
      checkedCustomerIds.length !== sortedSubscriptionCustomerIds.length;
    const isEverySubscriptionCustomersStillChecked = sortedSubscriptionCustomerIds.every((id) =>
      checkedCustomerIds.includes(id),
    );

    const selectionChangeUpdatedData =
      hasNumberOfCustomersChanged || !isEverySubscriptionCustomersStillChecked;

    setValue('customerIds', checkedCustomerIds, {
      shouldTouch: true,
      shouldDirty: selectionChangeUpdatedData,
    });

    // Un-dirty the form to disable the submit button again when there are no changes compared to the starting data
    if (selectionChangeUpdatedData === false) {
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    }
  }, [setValue, subscriptionCustomerIds, table.state.selectedRowIds, reset]);

  // Reset the form after as successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      // Un-dirtying needed to disable the submit button again
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `table` changes are of no interest
  }, [isSubmitSuccessful, reset]);

  return (
    <form>
      <VStack alignItems="flex-start" spacing={10}>
        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            Customers
          </Heading>
          {isNil(customers) || isEmpty(customers) ? (
            <>No customers</>
          ) : (
            <SubscriptionCustomersTable
              table={table}
              currentCustomerIds={subscriptionCustomerIds}
              availableSeats={availableSeats}
            />
          )}
        </FormControl>
      </VStack>
    </form>
  );
}

OrganizationSubscriptionEditPage.graphql = {
  fragments: {
    SubscriptionEditPage_subscription: gql`
      fragment SubscriptionEditPage_subscription on WaveSubscription {
        id
        name
        usage {
          current
          max
        }
        validUntil
        productId
        assignable
        customers {
          id
        }
      }
    `,
  },
  queries: {
    SubscriptionEditPageOrganization: gql`
      query WaveSubscriptionEditPageOrganization {
        organization {
          customers {
            id
            name
            waveSubscription {
              id
            }
            displayCount
          }
          waveSubscriptions {
            ...SubscriptionEditPage_subscription
          }
        }
      }
    `,
  },
  mutations: {
    EditSubscription: gql`
      mutation EditSubscription($input: SubscriptionUpdateInput!) {
        updateSubscription(input: $input) {
          id
          ...SubscriptionEditPage_subscription
        }
      }
    `,
  },
};
