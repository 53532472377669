import { MutableRefObject, Ref, useEffect, useRef } from 'react';

export function useCombinedRefs<T>(...refs: Array<Ref<T>>) {
  const targetRef = useRef<T | null>(null);
  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        (ref as MutableRefObject<T | null>).current = targetRef.current;
      }
    });
  }, [refs]);
  return targetRef;
}
