/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSiteMutationVariables = Types.Exact<{
  input: Types.SiteCreateInput;
}>;


export type CreateSiteMutation = { __typename: 'Mutation', siteCreate: { __typename: 'SiteCreatePayload', site?: { __typename: 'Site', id: string } | null, customer?: { __typename: 'Customer', id: string, name: string, sites: Array<{ __typename: 'Site', id: string, name: string, address?: string | null }> } | null } };


export const CreateSiteDocument = gql`
    mutation CreateSite($input: SiteCreateInput!) {
  siteCreate(input: $input) {
    site {
      id
    }
    customer {
      id
      name
      sites {
        id
        name
        address
      }
    }
  }
}
    `;
export type CreateSiteMutationFn = ApolloReactCommon.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = ApolloReactCommon.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;