import { gql } from '@apollo/client';
import { Box, chakra, HStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { PowerScheduleTimeline_DisplayFragment } from '~components/displays/DisplayDetail/summary/__generated__/PowerScheduleTimeline.graphql';
import { MINUTES_IN_A_DAY } from '~components/displays/DisplayTable/constants';
import { OnIcon, StandbyIcon } from '~components/ui/icons';
import { TimeBlock } from '~graphql/__generated__/types';
import { DAYS_OF_WEEK_MON_FIRST, hourToDecimal } from '~utils/timeBlocks';

interface Props {
  display: PowerScheduleTimeline_DisplayFragment;
}

export function PowerScheduleTimeline({ display }: Props) {
  const timeZone = display.timeZone?.desired ?? display.timeZone?.reported;
  const blocksToday =
    display?.powerSchedule?.schedule?.timeBlocks.filter((block) => block.day === today(timeZone)) ??
    [];
  const withStandbyBlocks = fillGapsWithBlocks(blocksToday, timeZone);
  const [hourPosition, setHourPosition] = useState(getHourPosition(timeZone));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHourPosition(getHourPosition(timeZone));
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [timeZone]);

  if (!display.powerSchedule?.schedule) return null;

  return (
    <Box position="relative">
      <Box
        position="absolute"
        top="50%"
        left={hourPosition}
        marginTop="-17px"
        borderRadius="10px"
        width="3px"
        height="35px"
        backgroundColor="gray.200"
      />
      <HStack spacing={2}>
        {withStandbyBlocks.map(({ start, end, isStandby }) => {
          const width = (hourToDecimal('end', end) - hourToDecimal('start', start)) * 100;
          return (
            <Box
              key={start + end}
              background={isStandby ? 'gray.100' : 'blue.100'}
              height={6}
              width={`${width}%`}
              borderRadius="4px"
              display="flex"
              justifyContent="left"
              alignItems="center"
              flexWrap="wrap"
              overflow="hidden"
              paddingX="2"
            >
              {/* Hack to flex-wrap the icon out of the overflow when box is too short */}
              <chakra.div width="1px">&nbsp;</chakra.div>
              {isStandby ? <StandbyIcon color="gray.500" /> : <OnIcon color="blue.500" />}
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
}

function today(timeZone: string | undefined) {
  return DAYS_OF_WEEK_MON_FIRST[DateTime.now().setZone(timeZone).weekday - 1];
}

function getHourPosition(timeZone: string | undefined) {
  const now = DateTime.now().setZone(timeZone);
  return ((now.hour * 60 + now.minute) / MINUTES_IN_A_DAY) * 100 + '%';
}

function fillGapsWithBlocks(blocks: TimeBlock[], timeZone: string | undefined) {
  const newBlocks: Array<TimeBlock & { isStandby?: boolean }> = [
    {
      day: today(timeZone),
      start: '00:00',
      end: '00:00',
      isStandby: true,
    },
  ];

  blocks.forEach((block) => {
    if (block.start === '00:00') {
      // No need to fill the gap at the beginning in this case
      newBlocks.pop();
    } else {
      newBlocks[newBlocks.length - 1].end = block.start;
    }

    newBlocks.push(block);

    if (block.end !== '00:00') {
      // Otherwise, no need to fill the gap at the end
      newBlocks.push({
        day: today(timeZone),
        start: block.end,
        end: '00:00',
        isStandby: true,
      });
    }
  });

  return newBlocks;
}

PowerScheduleTimeline.graphql = {
  fragments: {
    PowerScheduleTimeline_display: gql`
      fragment PowerScheduleTimeline_display on Display {
        id
        powerSchedule {
          schedule {
            id
            timeBlocks {
              start
              end
              day
            }
          }
        }
        timeZone {
          reported
          desired
        }
      }
    `,
  },
};
