/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ApiKeysActionTable_ApiKeyFragment = { __typename: 'ApiKey', id: string, alias: string, createdAt: any };

export type RevokeApiKeysMutationVariables = Types.Exact<{
  input: Types.ApiKeyRevokeInput;
}>;


export type RevokeApiKeysMutation = { __typename: 'Mutation', apiKeyRevoke: { __typename: 'ApiKeyRevokePayload', apiKeyId: string } };

export const ApiKeysActionTable_ApiKeyFragmentDoc = gql`
    fragment ApiKeysActionTable_apiKey on ApiKey {
  id
  alias
  createdAt
}
    `;
export const RevokeApiKeysDocument = gql`
    mutation RevokeApiKeys($input: ApiKeyRevokeInput!) {
  apiKeyRevoke(input: $input) {
    apiKeyId
  }
}
    `;
export type RevokeApiKeysMutationFn = ApolloReactCommon.MutationFunction<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>;

/**
 * __useRevokeApiKeysMutation__
 *
 * To run a mutation, you first call `useRevokeApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeApiKeysMutation, { data, loading, error }] = useRevokeApiKeysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeApiKeysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>(RevokeApiKeysDocument, options);
      }
export type RevokeApiKeysMutationHookResult = ReturnType<typeof useRevokeApiKeysMutation>;
export type RevokeApiKeysMutationResult = ApolloReactCommon.MutationResult<RevokeApiKeysMutation>;
export type RevokeApiKeysMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>;