import { AlertStatus } from '@chakra-ui/alert';
import {
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  Box,
  ComponentWithAs,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';
import {
  ErrorIcon,
  IconProps,
  InfoFilledIcon,
  SuccessIcon,
  WarningIcon,
} from '~components/ui/icons';

interface BaseAlertProps extends ChakraAlertProps {
  children?: ReactNode;
  actionButton?: ReactElement;
  actionIcon?: ReactElement;
  status: AlertStatus;
  icon: ComponentWithAs<'svg', IconProps>;
  iconColor: string;
}

// See src/components/ui/styles/Alert.ts for most of the styling
const BaseAlert = ({
  children,
  actionButton,
  actionIcon,
  status,
  icon: Icon,
  iconColor,
  ...rest
}: BaseAlertProps) => {
  return (
    <ChakraAlert status={status} variant="solid" {...rest}>
      <Icon width={5} height={5} color={iconColor} marginRight={4} />
      <Box flex="1">{children}</Box>
      {actionButton}
      {actionIcon}
    </ChakraAlert>
  );
};

interface AlertProps extends ChakraAlertProps {
  children?: ReactNode;
  actionButton?: ReactElement;
  actionIcon?: ReactElement;
}

export const InfoAlert = ({ children, actionButton, ...rest }: AlertProps) => (
  <BaseAlert
    actionButton={actionButton}
    status="info"
    icon={InfoFilledIcon}
    iconColor="blue.500"
    colorScheme="blue"
    {...rest}
  >
    {children}
  </BaseAlert>
);

export const WarningAlert = ({ children, actionButton, ...rest }: AlertProps) => (
  <BaseAlert
    actionButton={actionButton}
    status="warning"
    icon={WarningIcon}
    iconColor="orange.400"
    colorScheme="orange"
    {...rest}
  >
    {children}
  </BaseAlert>
);

export const SuccessAlert = ({ children, actionButton, ...rest }: AlertProps) => (
  <BaseAlert
    actionButton={actionButton}
    status="success"
    icon={SuccessIcon}
    iconColor="green.300"
    colorScheme="green"
    {...rest}
  >
    {children}
  </BaseAlert>
);

export const ErrorAlert = ({ children, actionButton, actionIcon, ...rest }: AlertProps) => (
  <BaseAlert
    actionButton={actionButton}
    actionIcon={actionIcon}
    status="error"
    icon={ErrorIcon}
    iconColor="red.400"
    colorScheme="red"
    {...rest}
  >
    {children}
  </BaseAlert>
);
