/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplayDetailHeader_DisplayFragmentDoc } from './DisplayDetailHeader.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayDetailEmptyView_Display_DisplayFragment = { __typename: 'Display', id: string, alias?: string | null, serialNumber: string, commercialTypeNumber: string, agentVersion?: string | null, platform: { __typename: 'DisplayPlatform', name: string, version?: string | null, type: string }, firmware?: { __typename: 'Firmware', android: { __typename: 'Android', version: any } } | null, networkInformation: { __typename: 'DisplayNetworkInformation', ethernetMacAddress: string, wifiMacAddress?: string | null, localIPAddress?: string | null }, site?: { __typename: 'Site', name: string } | null, groups: Array<{ __typename: 'Group', id: string, name: string }>, agentReleaseChannel?: { __typename: 'AgentReleaseChannel', reported: Types.ReleaseChannel } | null, presence?: { __typename: 'DisplayPresence', connected: boolean } | null, power?: { __typename: 'PowerState', desired?: Types.Power | null, reported: Types.Power } | null };

export type DisplayDetailEmptyView_UnclaimDisplayMutationVariables = Types.Exact<{
  input: Types.DisplayUnclaimInput;
}>;


export type DisplayDetailEmptyView_UnclaimDisplayMutation = { __typename: 'Mutation', displayUnclaim: { __typename: 'DisplayUnclaimPayload', customer: { __typename: 'Customer', id: string } } };

export const DisplayDetailEmptyView_Display_DisplayFragmentDoc = gql`
    fragment DisplayDetailEmptyView_display_display on Display {
  id
  alias
  serialNumber
  commercialTypeNumber
  agentVersion
  platform {
    name
    version
    type
  }
  firmware {
    android {
      version
    }
  }
  networkInformation {
    ethernetMacAddress
    wifiMacAddress
    localIPAddress
  }
  ...DisplayDetailHeader_display
}
    ${DisplayDetailHeader_DisplayFragmentDoc}`;
export const DisplayDetailEmptyView_UnclaimDisplayDocument = gql`
    mutation DisplayDetailEmptyView_UnclaimDisplay($input: DisplayUnclaimInput!) {
  displayUnclaim(input: $input) {
    customer {
      id
    }
  }
}
    `;
export type DisplayDetailEmptyView_UnclaimDisplayMutationFn = ApolloReactCommon.MutationFunction<DisplayDetailEmptyView_UnclaimDisplayMutation, DisplayDetailEmptyView_UnclaimDisplayMutationVariables>;

/**
 * __useDisplayDetailEmptyView_UnclaimDisplayMutation__
 *
 * To run a mutation, you first call `useDisplayDetailEmptyView_UnclaimDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayDetailEmptyView_UnclaimDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayDetailEmptyViewUnclaimDisplayMutation, { data, loading, error }] = useDisplayDetailEmptyView_UnclaimDisplayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisplayDetailEmptyView_UnclaimDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisplayDetailEmptyView_UnclaimDisplayMutation, DisplayDetailEmptyView_UnclaimDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisplayDetailEmptyView_UnclaimDisplayMutation, DisplayDetailEmptyView_UnclaimDisplayMutationVariables>(DisplayDetailEmptyView_UnclaimDisplayDocument, options);
      }
export type DisplayDetailEmptyView_UnclaimDisplayMutationHookResult = ReturnType<typeof useDisplayDetailEmptyView_UnclaimDisplayMutation>;
export type DisplayDetailEmptyView_UnclaimDisplayMutationResult = ApolloReactCommon.MutationResult<DisplayDetailEmptyView_UnclaimDisplayMutation>;
export type DisplayDetailEmptyView_UnclaimDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<DisplayDetailEmptyView_UnclaimDisplayMutation, DisplayDetailEmptyView_UnclaimDisplayMutationVariables>;