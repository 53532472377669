import { gql } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import SiteCreatableSelect from '~components/customers/SiteCreatableSelect';
import { DisplayTimeZoneSelect } from '~components/ui/TimeZoneSelect';
import { fromError } from '~utils/errors';
import { ensure, MaybePromise } from '~utils/types';
import {
  DisplayGroupsSelect_CustomerFragment,
  DisplayGroupsSelect_DisplayFragment,
  DisplayGroupsSelect_GroupFragment,
} from '../__generated__/DisplayGroupSelect.graphql';
import { DisplayGroupsSelect } from '../DisplayGroupSelect';
import { StepClaim_DisplayFragment } from './__generated__/StepClaim.graphql';
import {
  StepMetadata_CustomerFragment,
  useAssignAliasMutation,
  useAssignGroupsMutation,
  useAssignSiteMutation,
  useAssignTimeZoneMutation,
  useStepMetadata_GroupCreateMutation,
} from './__generated__/StepMetadata.graphql';
import { DisplayPreview } from './DisplayPreview';
import { useWizard } from './WizardContext';

const schema = z.object({
  customerId: z.string(),
  alias: z.string().optional().nullable(),
  site: z.string().optional().nullable(),
  groups: z.array(z.any()),
  timeZone: z.string().optional().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

interface Props {
  customer: StepMetadata_CustomerFragment;
  onSuccess: (display: StepClaim_DisplayFragment) => MaybePromise<void>;
}

export function StepMetadata({ customer, onSuccess }: Props) {
  const { state } = useWizard();
  const timeZone = state.step.name === 'add_metadata' ? state.step.display.timeZone : undefined;
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      customerId: customer.id,
      groups: [],
      timeZone: timeZone?.reported,
    },
    resolver: zodResolver(schema),
  });
  const displayAliasInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: displayAliasRef, ...displayAliasInputProps } = register('alias');
  const timeZoneValues = timeZone?.supportedValues;

  const handleSkip = useCallback(async () => {
    if (state.step.name !== 'add_metadata') return;

    reset();
    await onSuccess(state.step.display);
  }, [reset, onSuccess, state]);

  const addGroup = useCallback(
    (group: DisplayGroupsSelect_GroupFragment) => {
      // You might think:
      // "Let's use 'useFieldArray' from react-hook-form"
      // But let me already tell you, don't.
      // That method injects a hidden 'id' property into the object and then removes it again
      // leaving our group entity without id.
      // So we'll do it the old fashion way.
      const values = getValues();
      setValue('groups', [...values.groups, group]);
    },
    [getValues, setValue],
  );

  const removeGroup = useCallback(
    (group: DisplayGroupsSelect_GroupFragment) => {
      // You might think:
      // "Let's use 'useFieldArray' from react-hook-form"
      // But let me already tell you, don't.
      // That method injects a hidden 'id' property into the object and then removes it again
      // leaving our group entity without id.
      // So we'll do it the old fashion way.
      const values = getValues();
      setValue(
        'groups',
        values.groups.filter((g) => g.id !== group.id),
      );
    },
    [getValues, setValue],
  );

  const handleGroupAdd = useCallback(
    (_display: DisplayGroupsSelect_DisplayFragment, group: DisplayGroupsSelect_GroupFragment) => {
      addGroup(group);
    },
    [addGroup],
  );

  const handleGroupRemove = useCallback(
    (_display: DisplayGroupsSelect_DisplayFragment, group: DisplayGroupsSelect_GroupFragment) => {
      removeGroup(group);
    },
    [removeGroup],
  );

  const [createGroup, createGroupMeta] = useStepMetadata_GroupCreateMutation();
  const handleGroupCreate = useCallback(
    async (customer: DisplayGroupsSelect_CustomerFragment, name: string) => {
      const { data } = await createGroup({
        variables: {
          input: {
            customerId: customer.id,
            name,
          },
        },
      });
      addGroup(ensure(data?.groupCreate.group));
    },
    [createGroup, addGroup],
  );

  const toast = useToast();
  const [assignTimeZone] = useAssignTimeZoneMutation();
  const [assignAlias] = useAssignAliasMutation();
  const [assignSite] = useAssignSiteMutation();
  const [assignGroups] = useAssignGroupsMutation();
  const performSubmit = useCallback(
    async (values: FormValues) => {
      if (state.step.name !== 'add_metadata') return;

      try {
        if (state.step.display.timeZone && values.timeZone) {
          await assignTimeZone({
            variables: {
              input: {
                displayIds: [state.step.display.id],
                timeZone: values.timeZone,
              },
            },
          });
        }
        await assignAlias({
          variables: {
            input: {
              displayId: state.step.display.id,
              alias: isEmpty(values.alias?.trim()) ? null : values.alias?.trim(),
            },
          },
        });
        await assignSite({
          variables: {
            input: {
              displayId: state.step.display.id,
              siteId: values.site,
            },
          },
        });
        await assignGroups({
          variables: {
            input: {
              displayIds: [state.step.display.id],
              groupIds: values.groups.map((g) => g.id),
            },
          },
        });

        await onSuccess(state.step.display);
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot update metadata',
          description: fromError(err, 'ClaimDisplay', {
            TIME_ZONE_INVALID: 'The selected time zone is not supported',
          }),
        });
      }
    },
    [state, assignTimeZone, assignAlias, assignSite, assignGroups, onSuccess, toast],
  );

  useEffect(() => {
    if (displayAliasInputRef.current) {
      displayAliasInputRef.current.focus();
    }
  }, []);

  if (state.step.name !== 'add_metadata') {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(performSubmit)}>
      <ModalHeader>Finish setting up</ModalHeader>
      <ModalBody>
        <Stack direction="column" spacing="4">
          <DisplayPreview display={state.step.display} />
          <Text fontSize="md" color="gray.600">
            Assign an alias, site and more to the display. This will help identify it in the future.
          </Text>
          <Stack direction="column" spacing="4">
            <FormControl isInvalid={Boolean(errors.alias)}>
              <FormLabel>Alias</FormLabel>
              <Input
                ref={(r) => {
                  displayAliasRef(r);
                  displayAliasInputRef.current = r;
                }}
                tabIndex={2}
                placeholder="eg. Room 501"
                bg="white"
                {...displayAliasInputProps}
              />
              <FormErrorMessage>{errors.alias?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.site)}>
              <FormLabel>Site</FormLabel>
              <Controller
                name="site"
                control={control}
                render={({ field }) => (
                  <SiteCreatableSelect
                    tabIndex={3}
                    customerId={customer.id}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <FormErrorMessage>{errors.site?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.groups)}>
              <FormLabel>Groups</FormLabel>
              <Controller
                name="groups"
                control={control}
                render={({ field }) => {
                  if (state.step.name !== 'add_metadata') return <></>;
                  return (
                    <DisplayGroupsSelect
                      tabIndex={4}
                      customer={customer}
                      display={state.step.display}
                      value={field.value}
                      isLoading={createGroupMeta.loading}
                      onAdd={handleGroupAdd}
                      onRemove={handleGroupRemove}
                      onCreate={handleGroupCreate}
                    />
                  );
                }}
              />
              <FormErrorMessage>{errors.site?.message}</FormErrorMessage>
            </FormControl>
            {state.step.display.timeZone && (
              <FormControl isInvalid={Boolean(errors.timeZone)}>
                <FormLabel>Time zone</FormLabel>
                <Controller
                  name="timeZone"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DisplayTimeZoneSelect
                        tabIndex={5}
                        onChange={field.onChange}
                        value={field.value ?? undefined}
                        supportedValues={timeZoneValues}
                      />
                    );
                  }}
                />
                <FormErrorMessage>{errors.site?.message}</FormErrorMessage>
              </FormControl>
            )}
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack direction="row" spacing="4">
          <Button
            variant="ghost"
            colorScheme="blue"
            tabIndex={6}
            onClick={handleSkip}
            isDisabled={isSubmitting}
          >
            Do this later
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            tabIndex={7}
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </Stack>
      </ModalFooter>
    </form>
  );
}

StepMetadata.graphql = {
  fragments: {
    StepMetadata_customer: gql`
      fragment StepMetadata_customer on Customer {
        id
        ...DisplayGroupsSelect_customer
      }
    `,
    StepMetadata_display: gql`
      fragment StepMetadata_display on Display {
        id
        ...DisplayGroupsSelect_display
        timeZone {
          reported
          supportedValues
        }
      }
    `,
  },
  mutations: {
    CreateGroup: gql`
      mutation StepMetadata_GroupCreate($input: GroupCreateInput!) {
        groupCreate(input: $input) {
          group {
            id
            name
          }
          customer {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
    AssignGroups: gql`
      mutation AssignGroups($input: DisplayBulkAddGroupsInput!) {
        displayBulkAddGroups(input: $input) {
          displays {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
    AssignSite: gql`
      mutation AssignSite($input: DisplayUpdateSiteInput!) {
        displayUpdateSite(input: $input) {
          id
          site {
            id
            name
            address
          }
        }
      }
    `,
    AssignAlias: gql`
      mutation AssignAlias($input: DisplayUpdateAliasInput!) {
        displayUpdateAlias(input: $input) {
          id
          alias
          serialNumber
        }
      }
    `,
    AssignTimeZone: gql`
      mutation AssignTimeZone($input: DisplayBulkUpdateTimeZoneInput!) {
        displayBulkUpdateTimeZone(input: $input) {
          displays {
            id
            timeZone {
              reported
              desired
            }
          }
        }
      }
    `,
  },
};
