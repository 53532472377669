/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UsePlaylists_DisplayFragmentDoc } from './usePlaylists.graphql';
export type ChangeContentSourceModal_ContentSource_AppContentSource_Fragment = { __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null };

export type ChangeContentSourceModal_ContentSource_BookmarkContentSource_Fragment = { __typename: 'BookmarkContentSource', index: number };

export type ChangeContentSourceModal_ContentSource_InputContentSource_Fragment = { __typename: 'InputContentSource', source: string };

export type ChangeContentSourceModal_ContentSource_PlaylistContentSource_Fragment = { __typename: 'PlaylistContentSource', playlistId: string };

export type ChangeContentSourceModal_ContentSourceFragment = ChangeContentSourceModal_ContentSource_AppContentSource_Fragment | ChangeContentSourceModal_ContentSource_BookmarkContentSource_Fragment | ChangeContentSourceModal_ContentSource_InputContentSource_Fragment | ChangeContentSourceModal_ContentSource_PlaylistContentSource_Fragment;

export type ChangeContentSourceModal_BookmarksFragment = { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } };

export type ChangeContentSourceModal_DisplayFragment = { __typename: 'Display', id: string, contentSource?: { __typename: 'ContentSourceSettings', available?: Array<{ __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }> | null, current?: { __typename: 'ContentSourceState', reported: { __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string }, desired?: { __typename: 'AppContentSource', label?: string | null, applicationId: string, selectedActivity?: string | null } | { __typename: 'BookmarkContentSource', index: number } | { __typename: 'InputContentSource', source: string } | { __typename: 'PlaylistContentSource', playlistId: string } | null } | null } | null, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } }, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string, isOutOfSync: boolean, isTampered: boolean, size: any } | null, sync?: { __typename: 'PlaylistSyncFailed', errorCode: string, id: string, title: string, description?: string | null, jobId: string } | { __typename: 'PlaylistSyncing', progress: number, isRemoving: boolean, isPlanned: boolean, size: any, id: string, title: string, description?: string | null, jobId: string } | null } | null };

export const ChangeContentSourceModal_ContentSourceFragmentDoc = gql`
    fragment ChangeContentSourceModal_contentSource on ContentSource {
  ... on AppContentSource {
    label
    applicationId
    selectedActivity
  }
  ... on BookmarkContentSource {
    index
  }
  ... on InputContentSource {
    source
  }
  ... on PlaylistContentSource {
    playlistId
  }
}
    `;
export const ChangeContentSourceModal_BookmarksFragmentDoc = gql`
    fragment ChangeContentSourceModal_bookmarks on Bookmarks {
  all {
    reported
    desired
  }
}
    `;
export const ChangeContentSourceModal_DisplayFragmentDoc = gql`
    fragment ChangeContentSourceModal_display on Display {
  id
  contentSource {
    available {
      ... on AppContentSource {
        label
        applicationId
        selectedActivity
      }
      ... on BookmarkContentSource {
        index
      }
      ... on InputContentSource {
        source
      }
      ... on PlaylistContentSource {
        playlistId
      }
    }
    current {
      reported {
        ... on AppContentSource {
          label
          applicationId
          selectedActivity
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
      desired {
        ... on AppContentSource {
          label
          applicationId
          selectedActivity
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    }
  }
  bookmarks {
    ...ChangeContentSourceModal_bookmarks
  }
  ...UsePlaylists_display
  playlist {
    current {
      id
      title
    }
  }
}
    ${ChangeContentSourceModal_BookmarksFragmentDoc}
${UsePlaylists_DisplayFragmentDoc}`;