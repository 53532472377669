import { UserCustomersTable_CustomerFragment } from './__generated__/UserCustomersTable.graphql';

export enum Columns {
  Selection = 'selection',
  Name = 'name',
  Subscription = 'subscription',
}

export type SelectableUserCustomersTableCustomerFragment = UserCustomersTable_CustomerFragment & {
  hasCustomerAccess?: boolean;
};
