import { gql } from '@apollo/client';
import { Box, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { Maybe } from '~graphql/__generated__/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { FirmwareCell_ValueFragment } from './__generated__/FirmwareCell.graphql';

export function FirmwareCell({
  value,
}: PropsWithChildren<
  CellProps<DisplayTable_DisplayFragment, Maybe<FirmwareCell_ValueFragment> | undefined>
>) {
  if (!value) {
    return <Box />;
  }

  return (
    <Tooltip label={value.android.version}>
      <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {value.android.version}
      </Box>
    </Tooltip>
  );
}

FirmwareCell.graphql = {
  fragments: {
    FirmwareCell_value: gql`
      fragment FirmwareCell_value on Firmware {
        android {
          version
          availableUpdates
          latestJob {
            id
            targetVersion
            createdAt
            plannedAt
            ... on AndroidUpdateDownloading {
              downloadProgress
            }
            ... on AndroidUpdateRejected {
              rejectedAt
              rejectionCode
            }
          }
        }
      }
    `,
    FirmwareCell_display: gql`
      fragment FirmwareCell_display on Display {
        id
        firmware {
          ...FirmwareCell_value
        }
        platform {
          type
        }
      }
    `,
  },
};
