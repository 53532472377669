export const Tabs = {
  parts: ['tab', 'tablist'],
  variants: {
    line: {
      tab: {
        fontWeight: 'bold',
        paddingX: 0,
        marginRight: 7,
        _selected: {
          color: 'blue.500',
          borderColor: 'blue.500',
        },
        _active: {
          background: 'transparent',
        },
      },
      tablist: {
        borderColor: 'gray.50',
      },
    },
  },
};
