import { gql } from '@apollo/client';
import { Button, FormControl, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { cloneDeep, isNil, merge } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '~auth/useAuth';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { VolumeHighIcon, VolumeLowIcon, VolumeMuteIcon } from '~components/ui/icons';
import { Slider } from '~components/ui/Slider';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplayVolumeSettings_DisplayFragment,
  useUpdateVolumeLevelMutation,
  useUpdateVolumeMuteMutation,
} from './__generated__/DisplayVolumeSettings.graphql';

interface Props {
  display: DisplayVolumeSettings_DisplayFragment;
}

export function DisplayVolumeSettings({ display }: Props) {
  const toast = useToast();
  const { verifyUserPermissions } = useAuth();
  const [volumeLevel, setVolumeLevel] = useState(0);
  const isMuteButtonDisabled = useMemo(() => isNil(display.volume?.isMuted), [display]);
  const isMuted = useMemo(
    () => Boolean(display.volume?.isMuted?.desired ?? display.volume?.isMuted?.reported),
    [display],
  );
  const analytics = useAnalyticsReporter();
  const isVolumeSliderDisabled = useMemo(
    () => isNil(display.volume?.level) || isMuted,
    [display, isMuted],
  );
  const volumeLevelMin = useMemo(
    () => display.volume?.limits?.min?.desired ?? display.volume?.limits?.min?.reported ?? 0,
    [display],
  );
  const volumeLevelMax = useMemo(
    () => display.volume?.limits?.max?.desired ?? display.volume?.limits?.max?.reported ?? 100,
    [display],
  );

  const hasDisplaySettingsUpdatePermission = verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  useEffect(() => {
    // If a new value comes in from the server, the slider should update.
    // But only if it's an actual value.
    const newVolume = display.volume?.level?.desired ?? display.volume?.level?.reported;

    if (!isNil(newVolume)) {
      setVolumeLevel(newVolume);
    }
  }, [display]);

  const [updateVolumeLevel] = useUpdateVolumeLevelMutation();
  const handleSliderChangeEnd = async (value: number) => {
    try {
      await updateVolumeLevel({
        variables: {
          input: {
            id: display.id,
            level: value,
          },
        },
        // Technically this optimistic response isn't needed.
        // But let's already have Apollo update its cache in case we read the value somewhere else.
        optimisticResponse: {
          __typename: 'Mutation',
          displayUpdateVolumeLevel: {
            __typename: 'DisplayUpdateVolumeLevelPayload',
            display: {
              __typename: 'Display',
              id: display.id,
              volume: merge(cloneDeep(display.volume), { level: { desired: value } }),
            },
          },
        },
      });

      analytics.track('displaySettingUpdate', { group: 'playback', changeItem: 'volume' });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot update volume level',
        description: fromError(err, 'UpdateVolumeLevel', {
          VOLUME_LEVEL_OUT_OF_RANGE: 'Volume level must be within the range of 0 and 100',
        }),
      });
    }
  };

  const [updateVolumeMute] = useUpdateVolumeMuteMutation();
  const handleMuteButtonClick = useCallback(async () => {
    try {
      await updateVolumeMute({
        variables: {
          input: {
            id: display.id,
            mute: !isMuted,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayUpdateVolumeMute: {
            __typename: 'DisplayUpdateVolumeMutePayload',
            display: {
              __typename: 'Display',
              id: display.id,
              volume: merge(cloneDeep(display.volume), { isMuted: { desired: !isMuted } }),
            },
          },
        },
      });

      analytics.track('displaySettingUpdate', { group: 'playback', changeItem: 'mute' });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot change mute state',
        description: fromError(err, 'UpdateMute'),
      });
    }
  }, [analytics, display.id, display.volume, isMuted, toast, updateVolumeMute]);

  const SpeakerIcon = () => {
    const Icon =
      volumeLevel === 0 || isMuted
        ? VolumeMuteIcon
        : volumeLevel <= 50
        ? VolumeLowIcon
        : VolumeHighIcon;

    return (
      <Button
        variant="unstyled"
        onClick={handleMuteButtonClick}
        isDisabled={!hasDisplaySettingsUpdatePermission || isMuteButtonDisabled}
        aria-label={volumeLevel === 0 || isMuted ? 'Unmute' : 'Mute'}
        minWidth="unset"
      >
        <Icon width="6" height="6" />
      </Button>
    );
  };

  const PendingIndicator =
    isPropertyPending(display.volume?.isMuted) || isPropertyPending(display.volume?.level)
      ? () => <FormLabelPendingIndicator isPending />
      : undefined;

  return (
    <FormControl>
      <Slider
        value={volumeLevel}
        onChange={setVolumeLevel}
        onChangeEnd={handleSliderChangeEnd}
        leftIcon={SpeakerIcon}
        rightIcon={PendingIndicator}
        isDisabled={!hasDisplaySettingsUpdatePermission || isVolumeSliderDisabled}
        minScale={0}
        maxScale={100}
        min={volumeLevelMin}
        max={volumeLevelMax}
      />
    </FormControl>
  );
}

DisplayVolumeSettings.graphql = {
  fragments: {
    DisplayVolumeSettings_display: gql`
      fragment DisplayVolumeSettings_display on Display {
        id
        volume {
          isMuted {
            reported
            desired
          }
          level {
            reported
            desired
          }
          limits {
            min {
              reported
              desired
            }
            max {
              reported
              desired
            }
          }
        }
      }
    `,
  },
  mutations: {
    UpdateVolumeLevel: gql`
      mutation UpdateVolumeLevel($input: DisplayUpdateVolumeLevelInput!) {
        displayUpdateVolumeLevel(input: $input) {
          display {
            id
            ...DisplayVolumeSettings_display
          }
        }
      }
    `,
    UpdateVolumeMute: gql`
      mutation UpdateVolumeMute($input: DisplayUpdateVolumeMuteInput!) {
        displayUpdateVolumeMute(input: $input) {
          display {
            id
            ...DisplayVolumeSettings_display
          }
        }
      }
    `,
  },
};
