import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  chakra,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import _, { isNil } from 'lodash';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { MaybePromise } from '~utils/types';
import { WarningIcon } from './icons';
import { ModalCloseButton } from './ModalCloseButton';

interface DestructiveAlertProps {
  isOpen: boolean;
  isLoading: boolean;
  title: ReactNode;
  message: ReactNode;
  notice?: ReactNode | false;
  cancelLabel?: string;
  confirmLabel?: string;
  onConfirm: () => MaybePromise<void>;
  onCancel: () => MaybePromise<void>;
  variant?: 'danger' | 'warning';
}

export function DestructiveAlert({
  isOpen,
  isLoading,
  title,
  message,
  notice,
  cancelLabel = 'Cancel',
  confirmLabel = 'Delete',
  onConfirm,
  onCancel,
  variant = 'danger',
}: DestructiveAlertProps) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onClose={onCancel} isOpen={isOpen}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Box display="flex" alignItems="center">
            <WarningIcon color={variant === 'danger' ? 'red.400' : 'orange.300'} marginRight="4" />
            <chakra.span>{title}</chakra.span>
          </Box>
        </AlertDialogHeader>
        <ModalCloseButton onClick={onCancel} />
        <AlertDialogBody>
          <Text>{message}</Text>
          {!isNil(notice) && variant === 'danger' && (
            <Text color="gray.500" fontSize="sm" marginTop="6">
              {notice}
            </Text>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            variant="ghost"
            colorScheme="gray"
            ref={cancelRef}
            onClick={onCancel}
            isDisabled={isLoading}
          >
            {cancelLabel}
          </Button>
          <Button
            colorScheme={variant === 'danger' ? 'red' : 'orange'}
            marginLeft="3"
            onClick={onConfirm}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            {confirmLabel}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

interface UseDestructiveActionProps<TValue> {
  title: ReactNode | ((value: TValue) => ReactNode);
  message: ReactNode | ((value: TValue) => ReactNode);
  notice?: ReactNode | ((value: TValue) => ReactNode) | false;
  cancelLabel?: string;
  confirmLabel?: string;
  onConfirm: (value: TValue) => MaybePromise<void>;
  onCancel?: (value: TValue) => MaybePromise<void>;
  variant?: 'danger' | 'warning';
}

export function useDestructiveAction<TValue>({
  title,
  message,
  notice,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
  variant = 'danger',
}: UseDestructiveActionProps<TValue>) {
  const [isLoading, setIsLoading] = useState(false);
  const disclosure = useDisclosure();
  const confirmHandler = useRef<DestructiveAlertProps['onConfirm'] | null>(null);
  const cancelHandler = useRef<DestructiveAlertProps['onCancel'] | null>(null);
  const messageElement = useRef<ReactNode | null>(null);
  const noticeElement = useRef<ReactNode | null>(null);
  const titleElement = useRef<ReactNode | null>(null);

  const confirmationNode = useMemo(() => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return (
      <DestructiveAlert
        isOpen={disclosure.isOpen}
        isLoading={isLoading}
        title={titleElement.current}
        message={messageElement.current!}
        notice={noticeElement.current ?? undefined}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        onCancel={cancelHandler.current!}
        onConfirm={confirmHandler.current!}
        variant={variant}
      />
    );
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }, [disclosure.isOpen, isLoading, cancelLabel, confirmLabel, variant]);

  function askConfirmation(value: TValue) {
    return new Promise<boolean>((resolve, reject) => {
      async function cancel() {
        try {
          await onCancel?.(value);
          resolve(false);
        } catch (err) {
          reject(err);
        } finally {
          disclosure.onClose();
        }
      }

      async function confirm() {
        try {
          setIsLoading(true);
          await onConfirm(value);
          resolve(true);
        } catch (err) {
          reject(err);
        } finally {
          disclosure.onClose();
          setIsLoading(false);
        }
      }

      messageElement.current = _.isFunction(message) ? message(value) : message;
      noticeElement.current = _.isFunction(notice) ? notice(value) : notice || null;
      titleElement.current = _.isFunction(title) ? title(value) : title || null;
      cancelHandler.current = cancel;
      confirmHandler.current = confirm;

      setIsLoading(false);
      disclosure.onOpen();
    });
  }

  return {
    confirmationNode,
    askConfirmation,
  };
}
