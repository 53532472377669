import { createContext, ReactNode, useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { logger } from '~utils/logger';
import { isAnalyticalCookieEnabled } from './cookies';
import { AnalyticsEvents, EventType } from './events';
import { UserTagManager } from './user';

declare global {
  interface Window {
    TPV_ANALYTICS_DEBUG?: boolean;
  }
}

const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export interface PageAnalyticsAttributes {
  name?: string;
  disableTracking?: boolean;
}

export const AnalyticsContext = createContext<{
  pageAttributes: PageAnalyticsAttributes | null;
  setPageAttributes: (attributes: PageAnalyticsAttributes) => void;
} | null>(null);

export function AnalyticsProvider({ children }: { children: ReactNode }) {
  const [pageAttributes, setPageAttributes] = useState<PageAnalyticsAttributes | null>(null);

  return (
    <AnalyticsContext.Provider value={{ pageAttributes, setPageAttributes }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function usePageAnalytics() {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error('usePageAnalytics must be used inside a AnalyticsProvider.');
  }

  return ctx;
}

export const useAnalyticsReporter = () => {
  const { organization } = useAuth();
  const organizationName = organization?.name;
  const { customer: customerName } = useParams();
  const testTraffic = isTestTraffic({ customerName, organizationName });
  const {
    user: { roles = [] },
  } = useAuth();

  const { pageAttributes } = usePageAnalytics();

  const user: UserTagManager = {
    userRole: roles.join(', '),
    organizationName,
    customerName,
    testTraffic,
  };

  const initialize = () => {
    // The user doesn't want to be tracked.
    if (!isAnalyticalCookieEnabled()) {
      return;
    }

    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID });
    }

    // Note: TagManager actually doesn't have an "uninitialize" method so
    // even if the user changes their settings, the TagManager is still kinda enabled.

    if (window.TPV_ANALYTICS_DEBUG ?? false) {
      logger.info('analytics initialized');
    }
  };

  const track = <T extends EventType>(
    event: T,
    ...eventDetails: AnalyticsEvents[T] extends undefined ? [undefined?] : [AnalyticsEvents[T]]
  ) => {
    // The page is not worthy of being tracked.
    if (pageAttributes?.disableTracking) {
      return;
    }

    // The user doesn't want to be tracked.
    // Let's be nice and send nothing.
    if (!isAnalyticalCookieEnabled()) {
      return;
    }

    // Use the pageName from the details if one is present
    let pageName = pageAttributes?.name;
    if (event === 'pageView') {
      pageName = (eventDetails[0] as AnalyticsEvents['pageView']).pageName;
    }

    const details = Object.assign({}, eventDetails, { pageName });

    if (GTM_ID) {
      TagManager.dataLayer({
        dataLayer: { user, event, details: details },
      });
    }

    if (window.TPV_ANALYTICS_DEBUG ?? false) {
      logger.info('analytics event reported', { user, event, details });
    }
  };

  return {
    track,
    initialize,
  };
};

function isTestTraffic({
  customerName,
  organizationName,
}: {
  customerName?: string;
  organizationName?: string;
}) {
  return (
    TEST_TRAFFIC.fromCustomers.includes(customerName ?? 'unknown') ||
    TEST_TRAFFIC.fromOrganizations.includes(organizationName ?? 'unknown')
  );
}

/**
 * The customers and organizations which actions will be flagged as test traffic in GTM
 */
const TEST_TRAFFIC = {
  fromCustomers: [
    'in-the-pocket',
    'gio-falafel',
    'william',
    'empty-customer',
    'sanders-home-office',
    'stefs-lab',
  ] as readonly string[],
  fromOrganizations: [] as readonly string[],
} as const;
