import { createIcon } from '@chakra-ui/react';

export const EmptyCustomerIllustration = createIcon({
  displayName: 'EmptyCustomerIllustration',
  viewBox: '0 0 280 280',
  defaultProps: {
    fill: 'none',
    width: '280px',
    height: '280px',
  },
  path: (
    <>
      <g filter="url(#filter0_dd_5358_2172)">
        <circle cx="140" cy="120" r="120" fill="#F1F8FE" />
      </g>
      <g filter="url(#filter1_dd_5358_2172)">
        <path
          d="M152 162H172.081H199.975C202.184 162 203.974 160.21 203.975 158.002L203.998 106.002C203.999 103.792 202.208 102 199.998 102H172.055H148V158C148 160.209 149.791 162 152 162Z"
          fill="white"
        />
        <path
          d="M84 65.1231C84 63.2876 85.2423 61.6944 87.0379 61.3136C92.4289 60.1703 104.214 58 116 58C127.786 58 139.571 60.1703 144.962 61.3136C146.758 61.6944 148 63.2876 148 65.1231V158C148 160.209 146.209 162 144 162H88C85.7909 162 84 160.209 84 158V65.1231Z"
          fill="#D9E2EC"
        />
        <path
          d="M84 158H204C204 160.209 202.209 162 200 162H88C85.7909 162 84 160.209 84 158Z"
          fill="#0F5291"
        />
      </g>
      <rect x="156" y="114" width="40" height="16" rx="4" fill="#D9E2EC" />
      <rect x="156" y="134" width="40" height="16" rx="4" fill="#D9E2EC" />
      <path
        d="M106 138C106 135.791 107.791 134 110 134H122C124.209 134 126 135.791 126 138V158C126 160.209 124.209 162 122 162H110C107.791 162 106 160.209 106 158V138Z"
        fill="#0F5291"
      />
      <rect x="98" y="74" width="16" height="16" rx="4" fill="#0F5291" />
      <rect x="98" y="94" width="16" height="16" rx="4" fill="#0F5291" />
      <rect x="98" y="114" width="16" height="16" rx="4" fill="#0F5291" />
      <rect x="118" y="74" width="16" height="16" rx="4" fill="#0F5291" />
      <rect x="118" y="94" width="16" height="16" rx="4" fill="#0F5291" />
      <rect x="118" y="114" width="16" height="16" rx="4" fill="#0F5291" />
      <defs>
        <filter
          id="filter0_dd_5358_2172"
          x="0"
          y="0"
          width="280"
          height="280"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="5"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_5358_2172"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.04 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5358_2172" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="5"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_5358_2172"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5358_2172"
            result="effect2_dropShadow_5358_2172"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_5358_2172"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_5358_2172"
          x="69.0709"
          y="55.5118"
          width="149.858"
          height="133.858"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2.48819"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_5358_2172"
          />
          <feOffset dy="4.97638" />
          <feGaussianBlur stdDeviation="3.73228" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5358_2172" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.73228"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_5358_2172"
          />
          <feOffset dy="12.4409" />
          <feGaussianBlur stdDeviation="9.33071" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.219608 0 0 0 0 0.372549 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5358_2172"
            result="effect2_dropShadow_5358_2172"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_5358_2172"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
});
