/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplayGroupsSelect_DisplayFragmentDoc, DisplayGroupsSelect_GroupFragmentDoc } from '../../../__generated__/DisplayGroupSelect.graphql';
import { DisplayGroupsSelect_CustomerFragmentDoc } from '../../../__generated__/DisplayGroupSelect.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayInlineGroupsSelect_DisplayFragment = { __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type DisplayInlineGroupsSelect_CustomerFragment = { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type DisplayInlineGrouppsSelect_GroupCreateMutationVariables = Types.Exact<{
  input: Types.GroupCreateInput;
}>;


export type DisplayInlineGrouppsSelect_GroupCreateMutation = { __typename: 'Mutation', groupCreate: { __typename: 'GroupCreatePayload', group?: { __typename: 'Group', id: string, name: string } | null, customer?: { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> } | null } };

export type AddGroupMutationVariables = Types.Exact<{
  input: Types.DisplayAddGroupInput;
}>;


export type AddGroupMutation = { __typename: 'Mutation', displayAddGroup: { __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> } };

export type DeleteGroupMutationVariables = Types.Exact<{
  input: Types.DisplayDeleteGroupInput;
}>;


export type DeleteGroupMutation = { __typename: 'Mutation', displayDeleteGroup: { __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> } };

export const DisplayInlineGroupsSelect_DisplayFragmentDoc = gql`
    fragment DisplayInlineGroupsSelect_display on Display {
  id
  ...DisplayGroupsSelect_display
  groups {
    id
    ...DisplayGroupsSelect_group
  }
}
    ${DisplayGroupsSelect_DisplayFragmentDoc}
${DisplayGroupsSelect_GroupFragmentDoc}`;
export const DisplayInlineGroupsSelect_CustomerFragmentDoc = gql`
    fragment DisplayInlineGroupsSelect_customer on Customer {
  id
  ...DisplayGroupsSelect_customer
}
    ${DisplayGroupsSelect_CustomerFragmentDoc}`;
export const DisplayInlineGrouppsSelect_GroupCreateDocument = gql`
    mutation DisplayInlineGrouppsSelect_GroupCreate($input: GroupCreateInput!) {
  groupCreate(input: $input) {
    group {
      id
      name
    }
    customer {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type DisplayInlineGrouppsSelect_GroupCreateMutationFn = ApolloReactCommon.MutationFunction<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>;

/**
 * __useDisplayInlineGrouppsSelect_GroupCreateMutation__
 *
 * To run a mutation, you first call `useDisplayInlineGrouppsSelect_GroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayInlineGrouppsSelect_GroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayInlineGrouppsSelectGroupCreateMutation, { data, loading, error }] = useDisplayInlineGrouppsSelect_GroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisplayInlineGrouppsSelect_GroupCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>(DisplayInlineGrouppsSelect_GroupCreateDocument, options);
      }
export type DisplayInlineGrouppsSelect_GroupCreateMutationHookResult = ReturnType<typeof useDisplayInlineGrouppsSelect_GroupCreateMutation>;
export type DisplayInlineGrouppsSelect_GroupCreateMutationResult = ApolloReactCommon.MutationResult<DisplayInlineGrouppsSelect_GroupCreateMutation>;
export type DisplayInlineGrouppsSelect_GroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<DisplayInlineGrouppsSelect_GroupCreateMutation, DisplayInlineGrouppsSelect_GroupCreateMutationVariables>;
export const AddGroupDocument = gql`
    mutation AddGroup($input: DisplayAddGroupInput!) {
  displayAddGroup(input: $input) {
    id
    groups {
      id
      name
    }
  }
}
    `;
export type AddGroupMutationFn = ApolloReactCommon.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, options);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = ApolloReactCommon.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($input: DisplayDeleteGroupInput!) {
  displayDeleteGroup(input: $input) {
    id
    groups {
      id
      name
    }
  }
}
    `;
export type DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = ApolloReactCommon.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;