/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type BulkEditWebPages_DisplayFragment = { __typename: 'Display', id: string, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } } };

export const BulkEditWebPages_DisplayFragmentDoc = gql`
    fragment BulkEditWebPages_display on Display {
  id
  bookmarks {
    all {
      reported
      desired
    }
  }
}
    `;