/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PowerScheduleTimeline_DisplayFragment = { __typename: 'Display', id: string, powerSchedule?: { __typename: 'DisplayPowerSchedule', schedule?: { __typename: 'PowerSchedule', id: string, timeBlocks: Array<{ __typename: 'TimeBlock', start: string, end: string, day: Types.Day }> } | null } | null, timeZone?: { __typename: 'TimeZoneState', reported: string, desired?: string | null } | null };

export const PowerScheduleTimeline_DisplayFragmentDoc = gql`
    fragment PowerScheduleTimeline_display on Display {
  id
  powerSchedule {
    schedule {
      id
      timeBlocks {
        start
        end
        day
      }
    }
  }
  timeZone {
    reported
    desired
  }
}
    `;