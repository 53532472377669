/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UseRecommendedSettings_WarningFragment = { __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity };

export type UseRecommendedSettings_RecommendedSettingsFragment = { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> };

export type UseRecommendedSettings_SignalDetectionFragment = { __typename: 'Display', signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export type UseRecommendedSettings_DisplayFragment = { __typename: 'Display', id: string, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export type ApplyRecommendedMutationVariables = Types.Exact<{
  input: Types.DisplayBulkApplyRecommendedSettingsInput;
}>;


export type ApplyRecommendedMutation = { __typename: 'Mutation', displayBulkApplyRecommendedSettings: { __typename: 'DisplayBulkApplyRecommendedSettingsPayload', displays: Array<{ __typename: 'Display', id: string, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null }> } };

export const UseRecommendedSettings_SignalDetectionFragmentDoc = gql`
    fragment UseRecommendedSettings_signalDetection on Display {
  signalDetection {
    reported
    desired
  }
}
    `;
export const UseRecommendedSettings_WarningFragmentDoc = gql`
    fragment UseRecommendedSettings_warning on SettingWarning {
  code
  severity
}
    `;
export const UseRecommendedSettings_RecommendedSettingsFragmentDoc = gql`
    fragment UseRecommendedSettings_recommendedSettings on RecommendedSettings {
  recommended
  warnings {
    ...UseRecommendedSettings_warning
  }
}
    ${UseRecommendedSettings_WarningFragmentDoc}`;
export const UseRecommendedSettings_DisplayFragmentDoc = gql`
    fragment UseRecommendedSettings_display on Display {
  id
  ...UseRecommendedSettings_signalDetection
  recommendedSettings {
    reported {
      ...UseRecommendedSettings_recommendedSettings
    }
    desired {
      ...UseRecommendedSettings_recommendedSettings
    }
  }
}
    ${UseRecommendedSettings_SignalDetectionFragmentDoc}
${UseRecommendedSettings_RecommendedSettingsFragmentDoc}`;
export const ApplyRecommendedDocument = gql`
    mutation ApplyRecommended($input: DisplayBulkApplyRecommendedSettingsInput!) {
  displayBulkApplyRecommendedSettings(input: $input) {
    displays {
      id
      ...UseRecommendedSettings_display
    }
  }
}
    ${UseRecommendedSettings_DisplayFragmentDoc}`;
export type ApplyRecommendedMutationFn = ApolloReactCommon.MutationFunction<ApplyRecommendedMutation, ApplyRecommendedMutationVariables>;

/**
 * __useApplyRecommendedMutation__
 *
 * To run a mutation, you first call `useApplyRecommendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyRecommendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyRecommendedMutation, { data, loading, error }] = useApplyRecommendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyRecommendedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyRecommendedMutation, ApplyRecommendedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ApplyRecommendedMutation, ApplyRecommendedMutationVariables>(ApplyRecommendedDocument, options);
      }
export type ApplyRecommendedMutationHookResult = ReturnType<typeof useApplyRecommendedMutation>;
export type ApplyRecommendedMutationResult = ApolloReactCommon.MutationResult<ApplyRecommendedMutation>;
export type ApplyRecommendedMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyRecommendedMutation, ApplyRecommendedMutationVariables>;