import { Box, Button, ButtonProps, chakra, Circle } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';
import { ChevronDownIcon } from '~components/ui/icons';

type Props = { enabledFilterCount: number } & ButtonProps;
type Ref = LegacyRef<HTMLButtonElement>;

export const FilterButton = forwardRef(function FilterButton(
  { enabledFilterCount, ...props }: Props,
  ref: Ref,
) {
  const hasActiveFilters = enabledFilterCount > 0;

  return (
    <Button ref={ref} variant="outline" colorScheme="gray" {...props}>
      <chakra.span>Filters</chakra.span>
      <Box paddingLeft="3">
        {hasActiveFilters ? (
          <Circle size="24px" background="white" display="flex">
            <chakra.span color="blue.500" fontSize="sm" mb="0.5">
              {enabledFilterCount}
            </chakra.span>
          </Circle>
        ) : (
          <Box width="24px" height="24px" paddingLeft="2" display="flex" alignItems="center">
            <ChevronDownIcon />
          </Box>
        )}
      </Box>
    </Button>
  );
});
