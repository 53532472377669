import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { Maybe } from '~graphql/__generated__/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { SiteCell_ValueFragment } from './__generated__/SiteCell.graphql';

export function SiteCell({
  value,
}: PropsWithChildren<
  CellProps<DisplayTable_DisplayFragment, Maybe<SiteCell_ValueFragment> | undefined>
>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {value?.name}
    </Box>
  );
}

SiteCell.graphql = {
  fragments: {
    SiteCell_value: gql`
      fragment SiteCell_value on Site {
        id
        name
        address
      }
    `,
    SiteCell_display: gql`
      fragment SiteCell_display on Display {
        id
        site {
          id
          ...SiteCell_value
        }
      }
    `,
  },
};
