import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';

export interface OopsProps {
  error: Error;
}

export function HandleApiError({ error }: OopsProps) {
  const { customer } = useParams();
  const auth = useAuth();

  switch (error.message) {
    case 'CUSTOMER_NOT_FOUND':
    case 'CUSTOMER_SCOPED_ACCESS':
      return <Navigate to={`/${auth.organization?.handle}/customers/404`} replace />;
    case 'DISPLAY_NOT_FOUND':
      return (
        <Navigate to={`/${auth.organization?.handle}/customers/${customer}/displays/404`} replace />
      );
    case 'PLAYLIST_NOT_FOUND':
      return (
        <Navigate
          to={`/${auth.organization?.handle}/customers/${customer}/playlists/404`}
          replace
        />
      );
    case 'POWER_SCHEDULE_NOT_FOUND':
      return (
        <Navigate
          to={`/${auth.organization?.handle}/customers/${customer}/schedules/404`}
          replace
        />
      );
    default:
      throw error;
  }
}
