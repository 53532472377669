import { createIcon } from '@chakra-ui/react';

export const SuccessIcon = createIcon({
  displayName: 'SuccessIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <>
      <circle cx="12" cy="12" r="11" fill="currentColor" />
      <path
        d="M17 9L11 15.01L8 12.01"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
