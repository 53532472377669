/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseManagePlaylistForm_CustomerFragmentDoc } from '../../../__generated__/useManagePlaylistForm.graphql';
import { UseManageAppSubscriptionsForm_DisplayFragmentDoc } from '../../../__generated__/useManageAppSubscriptionsForm.graphql';
export type AddContentModal_CustomerFragment = { __typename: 'Customer', id: string, playlists: Array<{ __typename: 'Playlist', id: string, title: string, size: any }> };

export type AddContentModal_DisplayFragment = { __typename: 'Display', id: string, bookmarks: { __typename: 'Bookmarks', all: { __typename: 'AllBookmarksState', reported?: Array<string> | null, desired?: Array<string | null> | null } }, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string } | null } | null, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> };

export const AddContentModal_CustomerFragmentDoc = gql`
    fragment AddContentModal_customer on Customer {
  id
  ...useManagePlaylistForm_customer
}
    ${UseManagePlaylistForm_CustomerFragmentDoc}`;
export const AddContentModal_DisplayFragmentDoc = gql`
    fragment AddContentModal_display on Display {
  id
  bookmarks {
    all {
      reported
      desired
    }
  }
  playlist {
    current {
      id
      title
    }
  }
  ...useManageAppSubscriptionsForm_display
}
    ${UseManageAppSubscriptionsForm_DisplayFragmentDoc}`;