import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { Power } from '~graphql/__generated__/types';
import { UseDisplayPresence_DisplayFragment } from './__generated__/useDisplayPresence.graphql';

export interface DisplayPresence {
  isConnected: boolean;
  label: string;
  lastUpdated?: DateTime;
}

export interface DisplayPowerState {
  isPoweredOn: boolean;
  label: string;
}

// Note: we want to sort disconnected displays first, because it is more likely that the user will be trying to see all
// problematic displays when sorting
const IS_CONNECTED_ORDER_DESC = [false, true];

// Note: for power, we want the reverse of the above setting (devices in standby are sorted up top when disconnected
// devices are being sorted on, and visa versa)
const POWER_ON_ORDER_ASC = [true, false];

/**
 * Hook to help transform presence from remote to UI building blocks.
 * If you wish to use this hook, ensure the 'UseDisplayPresence_display' fragment is included in the query.
 */
export function useDisplayPresence() {
  const getPresence = useCallback(
    (display: UseDisplayPresence_DisplayFragment): DisplayPresence => {
      const isConnected = Boolean(display.presence?.connected);
      const lastUpdated = display.presence?.timestamp
        ? DateTime.fromISO(display.presence?.timestamp)
        : undefined;
      const label = isConnected ? 'Connected' : 'Disconnected';

      return {
        label,
        isConnected,
        lastUpdated,
      };
    },
    [],
  );

  const getPowerState = useCallback(
    (display: UseDisplayPresence_DisplayFragment): DisplayPowerState => {
      const isPoweredOn = (display.power?.desired ?? display.power?.reported) === Power.On;
      const label = isPoweredOn ? 'Powered on' : 'Standby';

      return {
        label,
        isPoweredOn,
      };
    },
    [],
  );

  const sortByPresenceAndPower = useCallback(
    (a: UseDisplayPresence_DisplayFragment, b: UseDisplayPresence_DisplayFragment) => {
      const presenceA = getPresence(a);
      const presenceB = getPresence(b);

      const isConnectedSort =
        IS_CONNECTED_ORDER_DESC.indexOf(presenceA.isConnected) -
        IS_CONNECTED_ORDER_DESC.indexOf(presenceB.isConnected);

      if (isConnectedSort !== 0) {
        return isConnectedSort;
      }

      // As a second sort, we first check the power status
      const isPoweredOnSort =
        POWER_ON_ORDER_ASC.indexOf((a.power?.desired ?? a.power?.reported) === Power.Standby) -
        POWER_ON_ORDER_ASC.indexOf((b.power?.desired ?? b.power?.reported) === Power.Standby);

      if (isPoweredOnSort !== 0) {
        return isPoweredOnSort;
      }

      // As a tertiary sort, compare when the displays were last updated
      const aLastUpdatedMs = presenceA.lastUpdated?.toMillis() ?? Number.POSITIVE_INFINITY;
      const bLastUpdatedMs = presenceB.lastUpdated?.toMillis() ?? Number.POSITIVE_INFINITY;

      return aLastUpdatedMs - bLastUpdatedMs;
    },
    [getPresence],
  );

  return useMemo(
    () => ({
      getPresence,
      getPowerState,
      sortByPresenceAndPower,
    }),
    [getPresence, getPowerState, sortByPresenceAndPower],
  );
}

useDisplayPresence.graphql = {
  fragments: {
    UseDisplayPresence_display: gql`
      fragment UseDisplayPresence_display on Display {
        id
        presence {
          connected
          timestamp
        }
        power {
          reported
          desired
        }
      }
    `,
  },
};
