import { createIcon } from '@chakra-ui/react';

export const AppsIcon = createIcon({
  displayName: 'AppsIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M5 19L5 19L3 19C3 20.1046 3.89543 21 5 21L5 19ZM5 19L5 19L5 17C3.89543 17 3 17.8954 3 19L5 19ZM5 19L5 19L7 19C7 17.8954 6.10457 17 5 17L5 19ZM5 19L5 19L5 21C6.10457 21 7 20.1046 7 19L5 19ZM12 19L12 19L10 19C10 20.1046 10.8954 21 12 21L12 19ZM12 19L12 19L12 17C10.8954 17 10 17.8954 10 19L12 19ZM12 19L12 19L14 19C14 17.8954 13.1046 17 12 17L12 19ZM12 19L12 19L12 21C13.1046 21 14 20.1046 14 19L12 19ZM19 19L19 19L17 19C17 20.1046 17.8954 21 19 21L19 19ZM19 19L19 19L19 17C17.8954 17 17 17.8954 17 19L19 19ZM19 19L19 19L21 19C21 17.8954 20.1046 17 19 17L19 19ZM19 19L19 19L19 21C20.1046 21 21 20.1046 21 19L19 19ZM5 12L5 12L3 12C3 13.1046 3.89543 14 5 14L5 12ZM5 12L5 12L5 9.99999C3.89543 9.99999 3 10.8954 3 12L5 12ZM5 12L5 12L7 12C7 10.8954 6.10457 9.99999 5 9.99999L5 12ZM5 12L5 12L5 14C6.10457 14 7 13.1046 7 12L5 12ZM12 12L12 12L10 12C10 13.1046 10.8954 14 12 14L12 12ZM12 12L12 12L12 9.99999C10.8954 9.99999 10 10.8954 10 12L12 12ZM12 12L12 12L14 12C14 10.8954 13.1046 9.99999 12 9.99999L12 12ZM12 12L12 12L12 14C13.1046 14 14 13.1046 14 12L12 12ZM19 12L19 12L17 12C17 13.1046 17.8954 14 19 14L19 12ZM19 12L19 12L19 9.99999C17.8954 9.99999 17 10.8954 17 12L19 12ZM19 12L19 12L21 12C21 10.8954 20.1046 9.99999 19 9.99999L19 12ZM19 12L19 12L19 14C20.1046 14 21 13.1046 21 12L19 12ZM5 5L3 5C3 6.10457 3.89543 7 5 7L5 5ZM5 5L5 3C3.89543 3 3 3.89543 3 5L5 5ZM5 5L7 5C7 3.89543 6.10457 3 5 3L5 5ZM5 5L5 7C6.10457 7 7 6.10457 7 5L5 5ZM12 5L10 5C10 6.10457 10.8954 7 12 7L12 5ZM12 5L12 3C10.8954 3 10 3.89543 10 5L12 5ZM12 5L14 5C14 3.89543 13.1046 3 12 3L12 5ZM12 5L12 7C13.1046 7 14 6.10457 14 5L12 5ZM19 5L17 5C17 6.10457 17.8954 7 19 7L19 5ZM19 5L19 3C17.8954 3 17 3.89543 17 5L19 5ZM19 5L19 5L21 5C21 3.89543 20.1046 3 19 3L19 5ZM19 5L19 5L19 7C20.1046 7 21 6.10457 21 5L19 5Z"
      fill="currentColor"
    />
  ),
});
