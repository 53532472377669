import { TableInstance } from 'react-table';
import { PageSizeSelector } from '~components/ui/PageSizeSelector';
import { Pagination } from '~components/ui/Pagination';
import { SubscriptionCustomersTableCustomerFragment as CustomerFragment } from './__generated__/SubscriptionCustomersTable.graphql';

export function SubscriptionCustomersTablePagination({
  table,
}: {
  table: TableInstance<CustomerFragment>;
}) {
  const {
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    state: { pageIndex },
    pageCount,
  } = table;

  return (
    <Pagination
      pageCount={pageCount}
      currentPageIndex={pageIndex}
      hasPreviousPage={canPreviousPage}
      hasNextPage={canNextPage}
      goToPage={gotoPage}
      goToPreviousPage={previousPage}
      goToNextPage={nextPage}
    />
  );
}

export function SubscriptionCustomersTablePageSizeSelector({
  table,
  sizes,
}: {
  table: TableInstance<CustomerFragment>;
  sizes?: number[];
}) {
  const {
    state: { pageSize },
    setPageSize,
  } = table;

  return (
    <PageSizeSelector
      label="Customers per page"
      onChange={setPageSize}
      options={sizes}
      value={pageSize}
    />
  );
}
