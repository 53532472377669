import { createIcon } from '@chakra-ui/react';

export const ConnectedIcon = createIcon({
  displayName: 'ConnectedIcon',
  viewBox: '0 0 24 25',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M9.99997 13.5C10.4294 14.0741 10.9773 14.5491 11.6065 14.8929C12.2357 15.2367 12.9315 15.4411 13.6466 15.4923C14.3618 15.5435 15.0796 15.4403 15.7513 15.1897C16.4231 14.9392 17.0331 14.547 17.54 14.04L20.54 11.04C21.4508 10.097 21.9547 8.83394 21.9433 7.52296C21.9319 6.21198 21.4061 4.95791 20.4791 4.03087C19.552 3.10383 18.298 2.57799 16.987 2.5666C15.676 2.5552 14.413 3.05918 13.47 3.96997L11.75 5.67997M14 11.5C13.5705 10.9259 13.0226 10.4508 12.3934 10.107C11.7642 9.76328 11.0684 9.55886 10.3533 9.50765C9.63816 9.45643 8.92037 9.55961 8.24861 9.8102C7.57685 10.0608 6.96684 10.4529 6.45996 10.96L3.45996 13.96C2.54917 14.903 2.04519 16.166 2.05659 17.477C2.06798 18.788 2.59382 20.042 3.52086 20.9691C4.4479 21.8961 5.70197 22.422 7.01295 22.4334C8.32393 22.4447 9.58694 21.9408 10.53 21.03L12.24 19.32"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
