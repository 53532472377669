/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UseRemovePlaylistFromDisplays_PlaylistFragment = { __typename: 'Playlist', id: string, removingDisplays: Array<{ __typename: 'Display', id: string }> };

export type RemovePlaylistFromDisplaysMutationVariables = Types.Exact<{
  input: Types.PlaylistRemoveDisplaysInput;
}>;


export type RemovePlaylistFromDisplaysMutation = { __typename: 'Mutation', playlistRemoveDisplays: { __typename: 'PlaylistRemoveDisplaysPayload', displays: Array<{ __typename: 'Display', id: string, playlist?: { __typename: 'DisplayPlaylist', current?: { __typename: 'DisplayCurrentPlaylist', id: string, title: string } | null } | null }> } };

export const UseRemovePlaylistFromDisplays_PlaylistFragmentDoc = gql`
    fragment useRemovePlaylistFromDisplays_playlist on Playlist {
  id
  removingDisplays: displays(filter: {state: REMOVING}) {
    id
  }
}
    `;
export const RemovePlaylistFromDisplaysDocument = gql`
    mutation RemovePlaylistFromDisplays($input: PlaylistRemoveDisplaysInput!) {
  playlistRemoveDisplays(input: $input) {
    displays {
      id
      playlist {
        current {
          id
          title
        }
      }
    }
  }
}
    `;
export type RemovePlaylistFromDisplaysMutationFn = ApolloReactCommon.MutationFunction<RemovePlaylistFromDisplaysMutation, RemovePlaylistFromDisplaysMutationVariables>;

/**
 * __useRemovePlaylistFromDisplaysMutation__
 *
 * To run a mutation, you first call `useRemovePlaylistFromDisplaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlaylistFromDisplaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlaylistFromDisplaysMutation, { data, loading, error }] = useRemovePlaylistFromDisplaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePlaylistFromDisplaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePlaylistFromDisplaysMutation, RemovePlaylistFromDisplaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemovePlaylistFromDisplaysMutation, RemovePlaylistFromDisplaysMutationVariables>(RemovePlaylistFromDisplaysDocument, options);
      }
export type RemovePlaylistFromDisplaysMutationHookResult = ReturnType<typeof useRemovePlaylistFromDisplaysMutation>;
export type RemovePlaylistFromDisplaysMutationResult = ApolloReactCommon.MutationResult<RemovePlaylistFromDisplaysMutation>;
export type RemovePlaylistFromDisplaysMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePlaylistFromDisplaysMutation, RemovePlaylistFromDisplaysMutationVariables>;