/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplaySubscriptionsFragmentDoc } from './useManageAppSubscriptionsForm.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type InstallAppMutationVariables = Types.Exact<{
  input: Types.DisplayRequestAppInstallationInput;
}>;


export type InstallAppMutation = { __typename: 'Mutation', displayRequestAppInstallation: { __typename: 'DisplayRequestAppInstallationPayload', display: { __typename: 'Display', id: string, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> } } };

export type UninstallAppMutationVariables = Types.Exact<{
  input: Types.DisplayRequestAppUninstallationInput;
}>;


export type UninstallAppMutation = { __typename: 'Mutation', displayRequestAppUninstallation: { __typename: 'DisplayRequestAppUninstallationPayload', display: { __typename: 'Display', id: string, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> } } };

export type RetryInstallAppMutationVariables = Types.Exact<{
  input: Types.DisplayRetryRequestAppInstallationInput;
}>;


export type RetryInstallAppMutation = { __typename: 'Mutation', displayRetryRequestAppInstallation: { __typename: 'DisplayRetryRequestAppInstallationPayload', display: { __typename: 'Display', id: string, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> } } };

export type RetryUninstallAppMutationVariables = Types.Exact<{
  input: Types.DisplayRetryRequestAppUninstallationInput;
}>;


export type RetryUninstallAppMutation = { __typename: 'Mutation', displayRetryRequestAppUninstallation: { __typename: 'DisplayRetryRequestAppUninstallationPayload', display: { __typename: 'Display', id: string, appSubscriptions: Array<{ __typename: 'DisplayAppSubscription', id: string, name: string, iconUrl?: string | null, appVersions?: Array<string> | null, appInstallation?: { __typename: 'AppInstallationFailed', id: string } | { __typename: 'AppInstallationInstallPending', id: string } | { __typename: 'AppInstallationInstalled', versionName: string, id: string } | { __typename: 'AppInstallationInstalling', versionName: string, downloadProgress: number, id: string } | { __typename: 'AppInstallationUninstallPending', id: string } | { __typename: 'AppInstallationUninstalling', versionName: string, id: string } | { __typename: 'AppInstallationUpdating', currentVersionCode: string, downloadProgress: number, id: string } | { __typename: 'AppUninstallationFailed', id: string } | null, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } }> } } };


export const InstallAppDocument = gql`
    mutation InstallApp($input: DisplayRequestAppInstallationInput!) {
  displayRequestAppInstallation(input: $input) {
    display {
      id
      appSubscriptions {
        ...DisplaySubscriptions
      }
    }
  }
}
    ${DisplaySubscriptionsFragmentDoc}`;
export type InstallAppMutationFn = ApolloReactCommon.MutationFunction<InstallAppMutation, InstallAppMutationVariables>;

/**
 * __useInstallAppMutation__
 *
 * To run a mutation, you first call `useInstallAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installAppMutation, { data, loading, error }] = useInstallAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstallAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InstallAppMutation, InstallAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InstallAppMutation, InstallAppMutationVariables>(InstallAppDocument, options);
      }
export type InstallAppMutationHookResult = ReturnType<typeof useInstallAppMutation>;
export type InstallAppMutationResult = ApolloReactCommon.MutationResult<InstallAppMutation>;
export type InstallAppMutationOptions = ApolloReactCommon.BaseMutationOptions<InstallAppMutation, InstallAppMutationVariables>;
export const UninstallAppDocument = gql`
    mutation UninstallApp($input: DisplayRequestAppUninstallationInput!) {
  displayRequestAppUninstallation(input: $input) {
    display {
      id
      appSubscriptions {
        ...DisplaySubscriptions
      }
    }
  }
}
    ${DisplaySubscriptionsFragmentDoc}`;
export type UninstallAppMutationFn = ApolloReactCommon.MutationFunction<UninstallAppMutation, UninstallAppMutationVariables>;

/**
 * __useUninstallAppMutation__
 *
 * To run a mutation, you first call `useUninstallAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallAppMutation, { data, loading, error }] = useUninstallAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninstallAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UninstallAppMutation, UninstallAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UninstallAppMutation, UninstallAppMutationVariables>(UninstallAppDocument, options);
      }
export type UninstallAppMutationHookResult = ReturnType<typeof useUninstallAppMutation>;
export type UninstallAppMutationResult = ApolloReactCommon.MutationResult<UninstallAppMutation>;
export type UninstallAppMutationOptions = ApolloReactCommon.BaseMutationOptions<UninstallAppMutation, UninstallAppMutationVariables>;
export const RetryInstallAppDocument = gql`
    mutation RetryInstallApp($input: DisplayRetryRequestAppInstallationInput!) {
  displayRetryRequestAppInstallation(input: $input) {
    display {
      id
      appSubscriptions {
        ...DisplaySubscriptions
      }
    }
  }
}
    ${DisplaySubscriptionsFragmentDoc}`;
export type RetryInstallAppMutationFn = ApolloReactCommon.MutationFunction<RetryInstallAppMutation, RetryInstallAppMutationVariables>;

/**
 * __useRetryInstallAppMutation__
 *
 * To run a mutation, you first call `useRetryInstallAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryInstallAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryInstallAppMutation, { data, loading, error }] = useRetryInstallAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryInstallAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RetryInstallAppMutation, RetryInstallAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RetryInstallAppMutation, RetryInstallAppMutationVariables>(RetryInstallAppDocument, options);
      }
export type RetryInstallAppMutationHookResult = ReturnType<typeof useRetryInstallAppMutation>;
export type RetryInstallAppMutationResult = ApolloReactCommon.MutationResult<RetryInstallAppMutation>;
export type RetryInstallAppMutationOptions = ApolloReactCommon.BaseMutationOptions<RetryInstallAppMutation, RetryInstallAppMutationVariables>;
export const RetryUninstallAppDocument = gql`
    mutation RetryUninstallApp($input: DisplayRetryRequestAppUninstallationInput!) {
  displayRetryRequestAppUninstallation(input: $input) {
    display {
      id
      appSubscriptions {
        ...DisplaySubscriptions
      }
    }
  }
}
    ${DisplaySubscriptionsFragmentDoc}`;
export type RetryUninstallAppMutationFn = ApolloReactCommon.MutationFunction<RetryUninstallAppMutation, RetryUninstallAppMutationVariables>;

/**
 * __useRetryUninstallAppMutation__
 *
 * To run a mutation, you first call `useRetryUninstallAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryUninstallAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryUninstallAppMutation, { data, loading, error }] = useRetryUninstallAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryUninstallAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RetryUninstallAppMutation, RetryUninstallAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RetryUninstallAppMutation, RetryUninstallAppMutationVariables>(RetryUninstallAppDocument, options);
      }
export type RetryUninstallAppMutationHookResult = ReturnType<typeof useRetryUninstallAppMutation>;
export type RetryUninstallAppMutationResult = ApolloReactCommon.MutationResult<RetryUninstallAppMutation>;
export type RetryUninstallAppMutationOptions = ApolloReactCommon.BaseMutationOptions<RetryUninstallAppMutation, RetryUninstallAppMutationVariables>;