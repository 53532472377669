import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { ReactNode, useCallback } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import AuthenticationProvider from '~auth/AuthenticationProvider';
import { AuthorizationProvider } from '~auth/AuthorizationProvider';
import { AppError } from '~components/ui/AppError';
import { theme } from '~components/ui/styles/theme';
import ApolloProvider from '~graphql/ApolloProvider';
import { AnalyticsProvider } from '~utils/analytics';

interface Props {
  children: ReactNode;
}

function AppProvider({ children }: Props) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: Record<string, string>) => {
      if (!appState?.returnTo) return;
      navigate(appState.returnTo, { replace: true });
    },
    [navigate],
  );

  return (
    <ChakraProvider resetCSS theme={theme}>
      <HelmetProvider>
        <AuthenticationProvider
          provider={Auth0Provider}
          providerProps={{
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              redirect_uri: window.location.origin,
              scope: process.env.REACT_APP_AUTH0_SCOPE,
              useRefreshTokens: true,
              cacheLocation: 'memory',
            },
            onRedirectCallback,
          }}
          context={Auth0Context}
        >
          <ApolloProvider uri={process.env.REACT_APP_API_URI}>
            <AuthorizationProvider>
              <AnalyticsProvider>
                <Sentry.ErrorBoundary
                  fallback={({ resetError }) => <AppError resetError={resetError} />}
                >
                  {children}
                </Sentry.ErrorBoundary>
              </AnalyticsProvider>
            </AuthorizationProvider>
          </ApolloProvider>
        </AuthenticationProvider>
      </HelmetProvider>
    </ChakraProvider>
  );
}

export default AppProvider;
