/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type UsedByCell_PowerScheduleFragment = { __typename: 'PowerSchedule', id: string, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }> };

export const UsedByCell_PowerScheduleFragmentDoc = gql`
    fragment UsedByCell_powerSchedule on PowerSchedule {
  id
  syncedDisplays: displays(filter: {state: SYNCED}) {
    id
  }
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
  syncingDisplays: displays(filter: {state: SYNCING}) {
    id
  }
}
    `;