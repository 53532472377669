import { gql } from '@apollo/client';
import {
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { ApiKeysActionTable } from '~components/organization/ApiKeysActionTable';
import { CreateApiKeyModal } from '~components/organization/CreateApiKeyModal';
import { InfoAlert } from '~components/ui/Alert';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useAnalyticsReporter } from '~utils/analytics';
import { useFeatureFlag } from '~utils/features';
import { useGetApiKeysQuery } from './__generated__/ApiKeysSettings.graphql';

export function ApiKeysSettings() {
  const createApiKeyModal = useDisclosure();
  const { data, loading, error, refetch } = useGetApiKeysQuery();
  const analytics = useAnalyticsReporter();

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const handleCreateApiKey = useCallback(() => {
    analytics.track('apiKeyCreateStart');

    createApiKeyModal.onOpen();
  }, [analytics, createApiKeyModal]);

  const handleCreateApiKeySuccess = useCallback(() => {
    analytics.track('apiKeyCreateComplete');

    refetch();
  }, [analytics, refetch]);

  const documentationLink = (
    <Link
      color="blue.500"
      href="https://docs.wave.ppds.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Documentation
    </Link>
  );

  return (
    <VerticalTabContent title="API Keys" isLoading={loading} error={error} data={data}>
      {({ me: { apiKeys } }) => (
        <>
          <Stack direction="row" spacing="20" mb="6">
            <Box flex="1">
              <Text fontSize="md" color="gray.500" maxWidth="700">
                Create and manage your own personal API keys. These keys are linked to your own user
                profile. For more information around this topic, please consult our{' '}
                {documentationLink}.
              </Text>
            </Box>
            <Box>
              <Button variant="solid" colorScheme="blue" onClick={handleCreateApiKey}>
                Generate new key
              </Button>
            </Box>
          </Stack>
          {
            // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
          }
          {isSubscriptionsEnabled && (
            <InfoAlert mt="6" mb="10">
              <AlertTitle>
                API key can only work for customers connected to an Essential plan
              </AlertTitle>
              <AlertDescription>
                To use API keys, connect your customer to an Essential plan via the subscriptions
                settings page or customer settings page.
              </AlertDescription>
            </InfoAlert>
          )}
          {!isEmpty(apiKeys) ? (
            <ApiKeysActionTable apiKeys={apiKeys} />
          ) : (
            <Text color="gray.500">No current API Keys found</Text>
          )}
          <CreateApiKeyModal
            isOpen={createApiKeyModal.isOpen}
            onCancel={createApiKeyModal.onClose}
            onSuccess={handleCreateApiKeySuccess}
          />
        </>
      )}
    </VerticalTabContent>
  );
}

ApiKeysSettings.graphql = {
  queries: {
    ApiKeys: gql`
      query GetApiKeys {
        me {
          apiKeys {
            ...ApiKeysActionTable_apiKey
          }
        }
      }
    `,
  },
};
