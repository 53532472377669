import { gql } from '@apollo/client';
import {
  Box,
  Button,
  CheckboxGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { FancyRemovalCheckbox } from '~components/ui/FancyRemovalCheckbox';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { SelectOption } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { useBulkRemoveGroupsMutation } from './__generated__/BulkRemoveGroups.graphql';
import { BulkActionComponent } from './BulkActionModal';

interface FormData {
  displayIds: string[];
  groups: SelectOption[];
}

export const BulkRemoveGroups: BulkActionComponent = ({
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const groupsIntersection = useMemo(
    () =>
      _.intersectionBy(...displays.map((d) => d.groups), (g) => g.id).map((g) => ({
        value: g.id,
        label: g.name,
      })),
    [displays],
  );

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      groups: [],
      displayIds: displays.map((d) => d.id),
    },
  });

  const handleCheckboxGroupOnChange = useCallback(
    (values: string[]) => {
      const groups = groupsIntersection.filter((g) => values.includes(g.value));
      setValue('groups', groups);
    },
    [groupsIntersection, setValue],
  );

  const toast = useToast();
  const [bulkRemoveGroups] = useBulkRemoveGroupsMutation();
  const analytics = useAnalyticsReporter();

  const onSubmit = useCallback(
    async ({ displayIds, groups }: FormData) => {
      try {
        await bulkRemoveGroups({
          variables: {
            input: {
              displayIds: displayIds,
              groupIds: groups.map((g) => g.value),
            },
          },
        });

        if (groups.length === 1) {
          analytics.track('groupDelete');
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'removeGroups',
            displayCount: displayIds.length,
          });
        }

        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot remove groups in bulk',
          description: fromError(err, 'bulkRemoveGroups'),
        });
      }
    },
    [analytics, bulkRemoveGroups, onSuccess, toast],
  );

  return (
    <ModalContent>
      <ModalHeader>Remove Groups</ModalHeader>
      <ModalCloseButton onClick={onCancel} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Box marginBottom="3">
            <Text color="blue.800" fontWeight="normal" fontSize="md">
              Select groups to be removed
            </Text>
          </Box>
          <Box marginBottom="6">
            {groupsIntersection.length === 0 ? (
              <Box height="20" display="flex" justifyContent="center" alignItems="center">
                <Text color="blue.800" opacity="0.5" fontWeight="normal" fontSize="sm">
                  Selection does not contain any shared groups.
                </Text>
              </Box>
            ) : (
              <CheckboxGroup defaultValue={[]} onChange={handleCheckboxGroupOnChange}>
                <SimpleGrid columns={2} spacing="2">
                  {groupsIntersection.map(({ label, value }) => {
                    return (
                      <FancyRemovalCheckbox key={value} colorScheme="blue" value={value}>
                        {label}
                      </FancyRemovalCheckbox>
                    );
                  })}
                </SimpleGrid>
              </CheckboxGroup>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Stack flex="1" direction="row" alignItems="center">
            <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
            <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="ghost"
                colorScheme="blue"
                onClick={onCancel}
                isDisabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                marginLeft="3"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Apply
              </Button>
            </Box>
          </Stack>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

BulkRemoveGroups.graphql = {
  fragments: {
    BulkRemoveGroups_display: gql`
      fragment BulkRemoveGroups_display on Display {
        id
        groups {
          id
          name
        }
      }
    `,
  },
  mutations: {
    BulkRemoveGroups: gql`
      mutation BulkRemoveGroups($input: DisplayBulkRemoveGroupsInput!) {
        displayBulkRemoveGroups(input: $input) {
          displays {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
  },
};
