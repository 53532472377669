/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { DisplayGroupsSelect_CustomerFragmentDoc } from '../../__generated__/DisplayGroupSelect.graphql';
import { DisplayGroupsSelect_DisplayFragmentDoc, DisplayGroupsSelect_GroupFragmentDoc } from '../../__generated__/DisplayGroupSelect.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type StepMetadata_CustomerFragment = { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> };

export type StepMetadata_DisplayFragment = { __typename: 'Display', id: string, timeZone?: { __typename: 'TimeZoneState', reported: string, supportedValues: Array<string> } | null };

export type StepMetadata_GroupCreateMutationVariables = Types.Exact<{
  input: Types.GroupCreateInput;
}>;


export type StepMetadata_GroupCreateMutation = { __typename: 'Mutation', groupCreate: { __typename: 'GroupCreatePayload', group?: { __typename: 'Group', id: string, name: string } | null, customer?: { __typename: 'Customer', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> } | null } };

export type AssignGroupsMutationVariables = Types.Exact<{
  input: Types.DisplayBulkAddGroupsInput;
}>;


export type AssignGroupsMutation = { __typename: 'Mutation', displayBulkAddGroups: { __typename: 'DisplayBulkAddGroupsPayload', displays: Array<{ __typename: 'Display', id: string, groups: Array<{ __typename: 'Group', id: string, name: string }> }> } };

export type AssignSiteMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateSiteInput;
}>;


export type AssignSiteMutation = { __typename: 'Mutation', displayUpdateSite: { __typename: 'Display', id: string, site?: { __typename: 'Site', id: string, name: string, address?: string | null } | null } };

export type AssignAliasMutationVariables = Types.Exact<{
  input: Types.DisplayUpdateAliasInput;
}>;


export type AssignAliasMutation = { __typename: 'Mutation', displayUpdateAlias: { __typename: 'Display', id: string, alias?: string | null, serialNumber: string } };

export type AssignTimeZoneMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateTimeZoneInput;
}>;


export type AssignTimeZoneMutation = { __typename: 'Mutation', displayBulkUpdateTimeZone: { __typename: 'DisplayBulkUpdateTimeZonePayload', displays: Array<{ __typename: 'Display', id: string, timeZone?: { __typename: 'TimeZoneState', reported: string, desired?: string | null } | null }> } };

export const StepMetadata_CustomerFragmentDoc = gql`
    fragment StepMetadata_customer on Customer {
  id
  ...DisplayGroupsSelect_customer
}
    ${DisplayGroupsSelect_CustomerFragmentDoc}`;
export const StepMetadata_DisplayFragmentDoc = gql`
    fragment StepMetadata_display on Display {
  id
  ...DisplayGroupsSelect_display
  timeZone {
    reported
    supportedValues
  }
}
    ${DisplayGroupsSelect_DisplayFragmentDoc}`;
export const StepMetadata_GroupCreateDocument = gql`
    mutation StepMetadata_GroupCreate($input: GroupCreateInput!) {
  groupCreate(input: $input) {
    group {
      id
      name
    }
    customer {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type StepMetadata_GroupCreateMutationFn = ApolloReactCommon.MutationFunction<StepMetadata_GroupCreateMutation, StepMetadata_GroupCreateMutationVariables>;

/**
 * __useStepMetadata_GroupCreateMutation__
 *
 * To run a mutation, you first call `useStepMetadata_GroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStepMetadata_GroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stepMetadataGroupCreateMutation, { data, loading, error }] = useStepMetadata_GroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStepMetadata_GroupCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StepMetadata_GroupCreateMutation, StepMetadata_GroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StepMetadata_GroupCreateMutation, StepMetadata_GroupCreateMutationVariables>(StepMetadata_GroupCreateDocument, options);
      }
export type StepMetadata_GroupCreateMutationHookResult = ReturnType<typeof useStepMetadata_GroupCreateMutation>;
export type StepMetadata_GroupCreateMutationResult = ApolloReactCommon.MutationResult<StepMetadata_GroupCreateMutation>;
export type StepMetadata_GroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StepMetadata_GroupCreateMutation, StepMetadata_GroupCreateMutationVariables>;
export const AssignGroupsDocument = gql`
    mutation AssignGroups($input: DisplayBulkAddGroupsInput!) {
  displayBulkAddGroups(input: $input) {
    displays {
      id
      groups {
        id
        name
      }
    }
  }
}
    `;
export type AssignGroupsMutationFn = ApolloReactCommon.MutationFunction<AssignGroupsMutation, AssignGroupsMutationVariables>;

/**
 * __useAssignGroupsMutation__
 *
 * To run a mutation, you first call `useAssignGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignGroupsMutation, { data, loading, error }] = useAssignGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignGroupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignGroupsMutation, AssignGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignGroupsMutation, AssignGroupsMutationVariables>(AssignGroupsDocument, options);
      }
export type AssignGroupsMutationHookResult = ReturnType<typeof useAssignGroupsMutation>;
export type AssignGroupsMutationResult = ApolloReactCommon.MutationResult<AssignGroupsMutation>;
export type AssignGroupsMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignGroupsMutation, AssignGroupsMutationVariables>;
export const AssignSiteDocument = gql`
    mutation AssignSite($input: DisplayUpdateSiteInput!) {
  displayUpdateSite(input: $input) {
    id
    site {
      id
      name
      address
    }
  }
}
    `;
export type AssignSiteMutationFn = ApolloReactCommon.MutationFunction<AssignSiteMutation, AssignSiteMutationVariables>;

/**
 * __useAssignSiteMutation__
 *
 * To run a mutation, you first call `useAssignSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSiteMutation, { data, loading, error }] = useAssignSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignSiteMutation, AssignSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignSiteMutation, AssignSiteMutationVariables>(AssignSiteDocument, options);
      }
export type AssignSiteMutationHookResult = ReturnType<typeof useAssignSiteMutation>;
export type AssignSiteMutationResult = ApolloReactCommon.MutationResult<AssignSiteMutation>;
export type AssignSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignSiteMutation, AssignSiteMutationVariables>;
export const AssignAliasDocument = gql`
    mutation AssignAlias($input: DisplayUpdateAliasInput!) {
  displayUpdateAlias(input: $input) {
    id
    alias
    serialNumber
  }
}
    `;
export type AssignAliasMutationFn = ApolloReactCommon.MutationFunction<AssignAliasMutation, AssignAliasMutationVariables>;

/**
 * __useAssignAliasMutation__
 *
 * To run a mutation, you first call `useAssignAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAliasMutation, { data, loading, error }] = useAssignAliasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignAliasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignAliasMutation, AssignAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignAliasMutation, AssignAliasMutationVariables>(AssignAliasDocument, options);
      }
export type AssignAliasMutationHookResult = ReturnType<typeof useAssignAliasMutation>;
export type AssignAliasMutationResult = ApolloReactCommon.MutationResult<AssignAliasMutation>;
export type AssignAliasMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignAliasMutation, AssignAliasMutationVariables>;
export const AssignTimeZoneDocument = gql`
    mutation AssignTimeZone($input: DisplayBulkUpdateTimeZoneInput!) {
  displayBulkUpdateTimeZone(input: $input) {
    displays {
      id
      timeZone {
        reported
        desired
      }
    }
  }
}
    `;
export type AssignTimeZoneMutationFn = ApolloReactCommon.MutationFunction<AssignTimeZoneMutation, AssignTimeZoneMutationVariables>;

/**
 * __useAssignTimeZoneMutation__
 *
 * To run a mutation, you first call `useAssignTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTimeZoneMutation, { data, loading, error }] = useAssignTimeZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTimeZoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignTimeZoneMutation, AssignTimeZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignTimeZoneMutation, AssignTimeZoneMutationVariables>(AssignTimeZoneDocument, options);
      }
export type AssignTimeZoneMutationHookResult = ReturnType<typeof useAssignTimeZoneMutation>;
export type AssignTimeZoneMutationResult = ApolloReactCommon.MutationResult<AssignTimeZoneMutation>;
export type AssignTimeZoneMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignTimeZoneMutation, AssignTimeZoneMutationVariables>;