import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import { MaybePromise } from '~utils/types';
import {
  DeleteCustomer_CustomerFragment,
  useDeleteCustomerMutation,
} from './__generated__/DeleteCustomer.graphql';

gql`
  mutation DeleteCustomer($input: CustomerDeleteInput!) {
    customerDelete(input: $input) {
      customerId
    }
  }
`;

gql`
  fragment DeleteCustomer_customer on Customer {
    id
    name
  }
`;

interface UseDeleteCustomerProps {
  onCompleted?: () => MaybePromise<void>;
}

export function useDeleteCustomer({ onCompleted }: UseDeleteCustomerProps) {
  const toast = useToast();

  const [deleteCustomer] = useDeleteCustomerMutation();
  const deleteCustomerAction = useDestructiveAction<DeleteCustomer_CustomerFragment>({
    title: 'Delete Customer',
    message: (customer) => (
      <>
        Are you sure you want to delete <strong>{customer?.name}</strong>?
      </>
    ),
    onConfirm: async (customer) => {
      try {
        await deleteCustomer({
          variables: {
            input: {
              customerId: customer.id,
            },
          },
        });
        await onCompleted?.();
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot delete customer',
          description: fromError(err, 'DeleteCustomer', {
            HAS_CLAIMED_DISPLAYS: 'This customer has claimed displays.',
          }),
        });
      }
    },
  });

  return {
    mutation: deleteCustomer,
    action: deleteCustomerAction,
  };
}
