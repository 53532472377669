import { Box, Link as ChakraLink, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CONTAINER_MAX_WIDTH, CONTAINER_PADDING } from '~components/ui/PageContent';
import { showCookieThough } from '~utils/analytics/cookies';
import { LogoWideGrayscaleIllustration } from './illustrations';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Box
        marginTop="auto"
        marginX="auto"
        paddingX={CONTAINER_PADDING}
        maxWidth={CONTAINER_MAX_WIDTH}
      >
        <Box
          borderTop="1px solid"
          display="flex"
          justifyContent="space-between"
          paddingTop="8"
          paddingBottom="6"
          borderColor="gray.100"
          color="gray.400"
          fontSize="sm"
          alignItems="baseline"
        >
          <div className="left">
            <LogoWideGrayscaleIllustration width="136px" height="32px" />

            <ChakraLink
              as={Link}
              to="/legal/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
              ml="6"
              mr="1.5"
            >
              Terms
            </ChakraLink>

            <span>•</span>
            <ChakraLink
              as={Link}
              to="/legal/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              mx="1.5"
            >
              Privacy
            </ChakraLink>
            <span>•</span>
            <ChakraLink
              href="https://www.ppds.com/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              mx="1.5"
            >
              Cookies
            </ChakraLink>
            <span>•</span>
            <ChakraLink target="_blank" mx="1.5" onClick={showCookieThough}>
              Manage Cookies
            </ChakraLink>
          </div>

          <div className="right">
            <Text fontWeight="600">© PPDS Wave {currentYear}</Text>
          </div>
        </Box>
      </Box>
    </footer>
  );
}
