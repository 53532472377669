import { gql } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { RelativeTime } from '~components/ui/RelativeTime';
import { PlaylistTableMedia } from '../PlaylistMediaTable';

export function CreatedAtCell({ value }: PropsWithChildren<CellProps<PlaylistTableMedia, string>>) {
  return (
    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      <RelativeTime ts={value} render={(t) => <Text fontSize="sm">{t}</Text>} />
    </Box>
  );
}

CreatedAtCell.graphql = {
  fragments: {
    CreatedAtCell_media: gql`
      fragment CreatedAtCell_media on Media {
        id
        createdAt
      }
    `,
  },
};
