import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { UseStatus_DisplayFragment } from './__generated__/useStatus.graphql';
import { usePlaylists } from './usePlaylists';
import { usePowerSchedules } from './usePowerSchedules';
import { useRecommendedSettings } from './useRecommendedSettings';

interface StatusWarning {
  message: string;
}

export type Status =
  | { kind: 'ok' }
  | { kind: 'warnings'; warnings: StatusWarning[] }
  | { kind: 'error'; errorMessage: string };

export enum StatusTypes {
  PlaylistOutOfSync = 'playlist_out_of_sync',
  PlaylistSyncFailed = 'playlist_sync_failed',
  PlaylistTampered = 'playlist_tampered',
  PowerScheduleOutOfSync = 'power_schedule_out_of_sync',
  PowerScheduleSyncFailed = 'power_schedule_sync_failed',
  SettingsNotRecommended = 'not_recommended',
  SettingsNotReported = 'not_reported',
}

export const statusMessageMap = {
  [StatusTypes.PlaylistOutOfSync]: 'Playlist is out of sync',
  [StatusTypes.PlaylistSyncFailed]: 'Playlist sync has failed',
  [StatusTypes.PlaylistTampered]: 'Playlist has been modified on display',
  [StatusTypes.PowerScheduleOutOfSync]: 'Power schedule is out of sync',
  [StatusTypes.PowerScheduleSyncFailed]: 'Power schedule sync has failed',
  [StatusTypes.SettingsNotRecommended]: 'Settings are not recommended',
  [StatusTypes.SettingsNotReported]: 'Settings are not reported',
};

/**
 * Hook to help transform recommended settings and playlist state into a single status.
 * If you wish to use this hook, ensure the 'UseStatus_display' fragment is included in the query.
 */
export function useStatus() {
  const { getSettingsState } = useRecommendedSettings();
  const { getPlaylistState } = usePlaylists();
  const { getPowerScheduleState } = usePowerSchedules();

  const getStatus = useCallback(
    (display: UseStatus_DisplayFragment): Status => {
      if (display.hasEmptyShadow) {
        return { kind: 'error', errorMessage: 'Wave app incompatible' };
      }

      const settings = getSettingsState(display);
      const playlistState = getPlaylistState(display);
      const powerScheduleState = getPowerScheduleState(display);

      const warnings: StatusWarning[] = [];

      switch (playlistState.kind) {
        case StatusTypes.PlaylistOutOfSync:
          warnings.push({ message: statusMessageMap[StatusTypes.PlaylistOutOfSync] });
          break;
        case StatusTypes.PlaylistSyncFailed:
          warnings.push({ message: statusMessageMap[StatusTypes.PlaylistSyncFailed] });
          break;
        case StatusTypes.PlaylistTampered:
          warnings.push({ message: statusMessageMap[StatusTypes.PlaylistTampered] });
          break;
      }

      switch (powerScheduleState.kind) {
        case StatusTypes.PowerScheduleOutOfSync:
          warnings.push({ message: statusMessageMap[StatusTypes.PowerScheduleOutOfSync] });
          break;
        case StatusTypes.PowerScheduleSyncFailed:
          warnings.push({ message: statusMessageMap[StatusTypes.PowerScheduleSyncFailed] });
          break;
      }

      switch (settings.kind) {
        case StatusTypes.SettingsNotRecommended:
          warnings.push({ message: statusMessageMap[StatusTypes.SettingsNotRecommended] });
          break;
        case StatusTypes.SettingsNotReported:
          warnings.push({ message: statusMessageMap[StatusTypes.SettingsNotReported] });
          break;
      }

      if (warnings.length > 0) {
        return { kind: 'warnings', warnings };
      } else {
        return { kind: 'ok' };
      }
    },
    [getSettingsState, getPlaylistState, getPowerScheduleState],
  );

  // TODO: implement sorting by actual severity levels when made available through the API (feedback epic)
  const sortByWarningSeverity = useCallback(
    (a: UseStatus_DisplayFragment, b: UseStatus_DisplayFragment) => {
      const statusA = getStatus(a);
      const statusB = getStatus(b);

      const kindOrder =
        STATUS_KIND_ORDER_DESC.indexOf(statusA.kind) - STATUS_KIND_ORDER_DESC.indexOf(statusB.kind);

      if (kindOrder !== 0) return kindOrder;

      const warningCountA = statusA.kind === 'warnings' ? statusA.warnings.length : 0;
      const warningCountB = statusB.kind === 'warnings' ? statusB.warnings.length : 0;

      return warningCountB - warningCountA;
    },
    [getStatus],
  );

  return useMemo(
    () => ({
      getStatus,
      sortByWarningSeverity,
    }),
    [getStatus, sortByWarningSeverity],
  );
}

const STATUS_KIND_ORDER_DESC: Array<Status['kind']> = ['warnings', 'ok'];

useStatus.graphql = {
  fragments: {
    UseStatus_display: gql`
      fragment UseStatus_display on Display {
        id
        hasEmptyShadow
        ...UseRecommendedSettings_display
        ...UsePlaylists_display
        ...UsePowerSchedules_display
      }
    `,
  },
};
