/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type SizeCell_MediaFragment = { __typename: 'Media', id: string, size: any };

export const SizeCell_MediaFragmentDoc = gql`
    fragment SizeCell_media on Media {
  id
  size
}
    `;