import { createIcon } from '@chakra-ui/react';

export const VolumeHighIcon = createIcon({
  displayName: 'VolumeHighIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M11 4.99999H12C12 4.61559 11.7797 4.26521 11.4332 4.09869C11.0867 3.93217 10.6755 3.97899 10.3753 4.21912L11 4.99999ZM6 8.99999V9.99999C6.22707 9.99999 6.44738 9.92271 6.62469 9.78086L6 8.99999ZM2 8.99999V7.99999C1.44772 7.99999 1 8.44771 1 8.99999H2ZM2 15H1C1 15.5523 1.44772 16 2 16V15ZM6 15L6.62469 14.2191C6.44738 14.0773 6.22707 14 6 14V15ZM11 19L10.3753 19.7809C10.6755 20.021 11.0867 20.0678 11.4332 19.9013C11.7797 19.7348 12 19.3844 12 19H11ZM19.7772 4.22299C19.3867 3.83241 18.7536 3.83231 18.363 4.22278C17.9724 4.61324 17.9723 5.24641 18.3628 5.63699L19.7772 4.22299ZM18.3628 18.363C17.9723 18.7536 17.9724 19.3867 18.363 19.7772C18.7536 20.1677 19.3867 20.1676 19.7772 19.777L18.3628 18.363ZM16.2472 7.75299C15.8567 7.36241 15.2236 7.36231 14.833 7.75278C14.4424 8.14324 14.4423 8.77641 14.8328 9.16699L16.2472 7.75299ZM14.8328 14.823C14.4423 15.2136 14.4424 15.8467 14.833 16.2372C15.2236 16.6277 15.8567 16.6276 16.2472 16.237L14.8328 14.823ZM10.3753 4.21912L5.3753 8.21912L6.62469 9.78086L11.6247 5.78086L10.3753 4.21912ZM6 7.99999H2V9.99999H6V7.99999ZM1 8.99999V15H3V8.99999H1ZM2 16H6V14H2V16ZM5.3753 15.7809L10.3753 19.7809L11.6247 18.2191L6.62469 14.2191L5.3753 15.7809ZM12 19V4.99999H10V19H12ZM18.3628 5.63699C20.05 7.32474 20.9979 9.61352 20.9979 12H22.9979C22.9979 9.08319 21.8394 6.2858 19.7772 4.22299L18.3628 5.63699ZM20.9979 12C20.9979 14.3865 20.05 16.6752 18.3628 18.363L19.7772 19.777C21.8394 17.7142 22.9979 14.9168 22.9979 12H20.9979ZM14.8328 9.16699C15.5827 9.9171 16.0039 10.9343 16.0039 11.995H18.0039C18.0039 10.404 17.372 8.87816 16.2472 7.75299L14.8328 9.16699ZM16.0039 11.995C16.0039 13.0556 15.5827 14.0729 14.8328 14.823L16.2472 16.237C17.372 15.1118 18.0039 13.586 18.0039 11.995H16.0039Z"
      fill="currentColor"
    />
  ),
});
