import { CustomerUsersTableUserFragment } from './__generated__/CustomerUsersTable.graphql';

export enum Columns {
  Selection = 'selection',
  Name = 'name',
  Role = 'role',
  Email = 'email',
}

export type SelectableCustomerUsersTableUserFragment = CustomerUsersTableUserFragment & {
  hasCustomerAccess?: boolean;
};
