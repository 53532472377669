import { IconButtonProps } from '@chakra-ui/button';
import { IconButton, useClipboard, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { CopyIcon } from '~components/ui/icons';

export default function CopyButton({
  value,
  label,
  ...rest
}: { value: string; label?: string } & Omit<IconButtonProps, 'aria-label'>) {
  const toast = useToast();
  const { onCopy } = useClipboard(value);

  const handleCopyClick = useCallback(() => {
    onCopy();
    toast({
      status: 'info',
      description: `${label} has been copied to your clipboard!`,
    });
  }, [onCopy, toast, label]);

  return (
    <IconButton
      variant="inline"
      colorScheme="gray"
      size="sm"
      icon={<CopyIcon color="gray.200" />}
      onClick={handleCopyClick}
      aria-label={`Copy ${label} to clipboard`}
      {...rest}
    />
  );
}
