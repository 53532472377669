import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function PrivacyPolicyPage() {
  return (
    <PolicyPage title="Privacy policy" heading="Wave privacy statement">
      <p>
        This Privacy Statement regarding WAVE is presented to you by TP Vision Europe B.V., located
        at Prins Bernhardplein 200, 1097 JB Amsterdam, the Netherlands, which is part of TPV
        Technology Limited in Taiwan and the exclusive brand-licensee of “Philips” for televisions
        in (among others) Europe (hereafter: “TPV”). This Privacy Statement applies to processing
        carried out by TP Vision Europe B.V. as a data controller.
      </p>
      <p>
        To enable you to use WAVE and for us to be able to maintain and improve this feature we need
        to collect and process data which is regarded as Personal Data. For the processing of
        Personal Data related to the use of WAVE, TPV is the data controller. In this Privacy
        Statement we will further explain to you:
      </p>
      <ul>
        <li>what kind of Personal Data we collect;</li>
        <li>for which purposes we use it;</li>
        <li>for how long we keep it and how we keep it secure;</li>
        <li>which third party companies have access to your Personal Data;</li>
        <li>your rights and how to contact us.</li>
      </ul>
      <h2>How to contact us</h2>
      <p>
        You can contact us with any questions you may have about the processing of your Personal
        Data or the use of cookies by us, or to exercise your rights as mentioned in this Privacy
        Statement, at:
      </p>
      <p>
        TP Vision Europe B.V.
        <br />
        Attn. Data Protection Officer
        <br />
        Prins Bernhardplein 200
        <br />
        1097 JB Amsterdam
        <br />
        The Netherlands
      </p>
      <p>
        or by mail at: <a href="mailto:privacy@tpv-tech.com">privacy@tpv-tech.com</a>
      </p>
      <h2>WAVE functionality</h2>
      <p>
        WAVE is a cloud-based platform supporting both PPDS and 3rd party applications and enables
        integrators to manage a display fleet for their customers. WAVE is provided to you by TP
        Vision Europe B.V.
      </p>
      <h2>Collection and use of Personal Data when you make use of WAVE</h2>
      <p>
        If you decide to make use of WAVE, you will first register to the platform and accept this
        Privacy Statement and the Terms of Use. Subsequently you will be able to connect to the WAVE
        cloud server and use to functionalities of WAVE. The Personal Data TPV collects are: your
        name, surname and e-mail address.
      </p>
      <p>
        The legitimate ground for processing this Personal Data is your consent to allow TPV to use
        your Personal Data to offer WAVE.
      </p>
      <h2>Retention</h2>
      <p>
        We generally store Personal Data as long as it is needed for providing the services to you,
        unless we said elsewhere in this Privacy Statement that we store it for a shorter or longer
        period. We may store (aggregated) data longer if needed for tax or auditing reasons.
      </p>
      <h2>Information security</h2>
      <p>
        We have implemented appropriate technical and organizational measures to protect your
        Personal Data against accidental loss, alteration, unauthorized disclosure or access. The
        data that we collect is sent to our servers via an encrypted Internet connection and stored
        on a secure server. Access to this data is password protected and role based, i.e. limited
        to selected employees and those of our outsourcing partners who need to access it as part of
        their job description.
      </p>
      <h2>Sharing with others</h2>
      <p>
        We only share your Personal Data with third parties if either one of the following
        circumstances apply:
      </p>
      <ol>
        <li>
          In the context of outsourced activities: to enable us to provide you with the WAVE service
          we have contracted with certain affiliates of ours and outsource partners. These third
          parties are only allowed to process your Personal Data based on our instructions and in
          compliance with the agreements that we have executed with them.
        </li>
        <li>
          For legal reasons: we will also release Personal Data to the authorities, including law
          enforcement, if compelled by law.
        </li>
      </ol>
      <h2>Transfer of your Personal Data</h2>
      <p>
        You should also know that your Personal Data may be transferred from your home country to
        other companies that belong to the TPV Technology Group or to our service providers around
        the world. If you are an EU resident, this could imply that we also transfer data to
        countries that do not offer an adequate level of protection for your Personal Data. When we
        transfer this data, we will do so only when we have put in place appropriate contracts,
        transfer mechanisms and safeguards to protect your Personal Data.
      </p>
      <h2>Your rights</h2>
      <p>
        You have the right to request access to your Personal Data that we process. You also have
        the right to:
      </p>
      <ul>
        <li>rectify incorrect Personal Data or erase it in certain circumstances;</li>
        <li>restrict or object to the processing of your Personal Data;</li>
        <li>erase and to be forgotten;</li>
        <li>receive your data so that you can use it elsewhere (data portability).</li>
      </ul>
      <p>
        If you do not want to make use of WAVE, you can choose to withdraw your consent at any time
        but we would like to point out that this does not affect the lawfulness of the processing
        before the date that you withdrew your consent.
      </p>
      <p>
        To withdraw your consent please send an e-mail to:{' '}
        <a href="mailto:ppds@tpv-tech.com">ppds@tpv-tech.com</a>.
      </p>
      <p>
        Finally, you have the right to lodge a complaint with a Supervisory Authority. If you do not
        know who your supervisory authority is, please contact us and we will tell you. The
        Supervisory Authority in the Netherlands is: Autoriteit Persoonsgegevens, Bezuidenhoutseweg
        30, 2594 AV, Den Haag, the Netherlands, Tel. 0900 - 2001 201.
      </p>
      <h2>If you are under 16</h2>
      <p>
        We do not knowingly collect data from children under the age of 16. If a parent or guardian
        becomes aware that his or her child or guard has provided us with information without their
        consent, he or she should contact us at{' '}
        <a href="mailto:privacy@tpv-tech.com">privacy@tpv-tech.com</a>. We will delete such
        information from our files within a reasonable time.
      </p>
      <h2>Version information</h2>
      <p>
        TPV reserves the right to make changes to this Privacy Statement, for example due to
        legislative changes. The most recent version can always be found on this page. This policy
        was last updated on 15 October 2021. It can be viewed on:{' '}
        <a href="https://www.ppds.com/downloads">https://www.ppds.com/downloads</a>.
      </p>
    </PolicyPage>
  );
}
