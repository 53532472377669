/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type CustomerSwitcher_OrganizationFragment = { __typename: 'Organization', handle: string, name: string, avatarUrl?: string | null, customers: Array<{ __typename: 'Customer', id: string, name: string, handle: string, avatarUrl?: string | null }> };

export type CustomerSwitcher_CustomerFragment = { __typename: 'Customer', id: string, name: string, handle: string, avatarUrl?: string | null };

export const CustomerSwitcher_CustomerFragmentDoc = gql`
    fragment CustomerSwitcher_customer on Customer {
  id
  name
  handle
  avatarUrl
}
    `;
export const CustomerSwitcher_OrganizationFragmentDoc = gql`
    fragment CustomerSwitcher_organization on Organization {
  handle
  name
  customers {
    id
    ...CustomerSwitcher_customer
  }
  avatarUrl
}
    ${CustomerSwitcher_CustomerFragmentDoc}`;