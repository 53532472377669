import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { KeyIcon, TrashIcon } from '~components/ui/icons';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  ApiKeysActionTable_ApiKeyFragment,
  useRevokeApiKeysMutation,
} from './__generated__/ApiKeysActionTable.graphql';

interface Props {
  apiKeys: ApiKeysActionTable_ApiKeyFragment[];
}

export function ApiKeysActionTable({ apiKeys }: Props) {
  const toast = useToast();
  const analytics = useAnalyticsReporter();

  const [revokeApiKeys] = useRevokeApiKeysMutation();
  const removeAction = useDestructiveAction<ApiKeysActionTable_ApiKeyFragment>({
    title: 'Revoke API Key',
    confirmLabel: 'Revoke',
    message: (apiKey) => (
      <>
        Are you sure you want to revoke <strong>{apiKey?.alias}</strong>?
      </>
    ),
    onConfirm: async (apiKey) => {
      if (!apiKey) return;

      try {
        await revokeApiKeys({
          variables: {
            input: {
              apiKeyId: apiKey.id,
            },
          },
        });
        analytics.track('apiKeyRevokeComplete');
      } catch (err) {
        toast({
          status: 'error',
          title: 'Unexpected error',
          description: fromError(err, 'RevokeApiKey'),
        });
      }
    },
  });

  const handleRemove = (apiKeys: ApiKeysActionTable_ApiKeyFragment) => async () => {
    analytics.track('apiKeyRevokeStart');
    await removeAction.askConfirmation(apiKeys);
  };

  return (
    <>
      <Box boxShadow="elevated" border="1px solid" borderRadius="md" borderColor="gray.100">
        <Table variant="action" width="full">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>
                <VisuallyHidden>Actions</VisuallyHidden>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {apiKeys.map((apiKey) => {
              return (
                <Tr key={apiKey.id}>
                  <Td width="auto">
                    <Box display="flex" alignItems="center">
                      <Box display="flex" justifyContent="center" color="gray.200">
                        <KeyIcon height="6" width="6" />
                      </Box>
                      <Box flex="1" marginLeft="4">
                        <chakra.span fontSize="sm" fontWeight="semibold" color="gray.900">
                          {apiKey.alias}
                        </chakra.span>
                      </Box>
                    </Box>
                  </Td>
                  <Td width="160px" textAlign="right">
                    <Button
                      marginLeft="-3"
                      size="sm"
                      variant="inline"
                      colorScheme="gray"
                      onClick={handleRemove(apiKey)}
                    >
                      <TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      {removeAction.confirmationNode}
    </>
  );
}

ApiKeysActionTable.graphql = {
  fragments: {
    ApiKeysActionTable_apiKey: gql`
      fragment ApiKeysActionTable_apiKey on ApiKey {
        id
        alias
        createdAt
      }
    `,
  },
  mutations: {
    RevokeApiKeys: gql`
      mutation RevokeApiKeys($input: ApiKeyRevokeInput!) {
        apiKeyRevoke(input: $input) {
          apiKeyId
        }
      }
    `,
  },
};
