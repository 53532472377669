import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { Maybe } from '~graphql/__generated__/types';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import {
  ContentSourceCell_ContentSourceFragment,
  ContentSourceCell_ValueFragment,
} from './__generated__/ContentSourceCell.graphql';

export function ContentSourceCell({
  value,
}: PropsWithChildren<
  CellProps<DisplayTable_DisplayFragment, Maybe<ContentSourceCell_ValueFragment> | undefined>
>) {
  const source = value?.current?.desired ?? value?.current?.reported;
  const sourceName = extractSource(source);

  return <Box>{sourceName}</Box>;
}

export function extractSource(content?: ContentSourceCell_ContentSourceFragment | null): string {
  if (isNil(content)) {
    return '';
  }

  switch (content.__typename) {
    case 'AppContentSource':
      return content.label ?? 'APP';
    case 'BookmarkContentSource':
      return 'BROWSER';
    case 'InputContentSource':
      return content.source;
    case 'PlaylistContentSource':
      return 'MEDIA PLAYER';
  }
}

ContentSourceCell.graphql = {
  fragments: {
    ContentSourceCell_contentSource: gql`
      fragment ContentSourceCell_contentSource on ContentSource {
        ... on AppContentSource {
          label
          applicationId
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    `,
    ContentSourceCell_value: gql`
      fragment ContentSourceCell_value on ContentSourceSettings {
        current {
          reported {
            ... on AppContentSource {
              label
              applicationId
            }
            ... on BookmarkContentSource {
              index
            }
            ... on InputContentSource {
              source
            }
            ... on PlaylistContentSource {
              playlistId
            }
          }
          desired {
            ... on AppContentSource {
              label
              applicationId
            }
            ... on BookmarkContentSource {
              index
            }
            ... on InputContentSource {
              source
            }
            ... on PlaylistContentSource {
              playlistId
            }
          }
        }
        available {
          ... on AppContentSource {
            label
            applicationId
          }
          ... on BookmarkContentSource {
            index
          }
          ... on InputContentSource {
            source
          }
          ... on PlaylistContentSource {
            playlistId
          }
        }
      }
    `,
    ContentSourceCell_display: gql`
      fragment ContentSourceCell_display on Display {
        id
        contentSource {
          current {
            reported {
              ... on AppContentSource {
                label
                applicationId
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
            desired {
              ... on AppContentSource {
                label
                applicationId
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
          }
          available {
            ... on AppContentSource {
              label
              applicationId
            }
            ... on BookmarkContentSource {
              index
            }
            ... on InputContentSource {
              source
            }
            ... on PlaylistContentSource {
              playlistId
            }
          }
        }
      }
    `,
  },
};
