import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useAuth } from '~auth/useAuth';
import { DisplaysQueryProvider } from '~components/displays/DisplayTable/useDisplaysQuery';
import { PageLoader } from '~components/ui/PageLoader';
import { HandleApiError } from '~graphql/HandleApiError';
import { useCookies } from '~utils/analytics/cookies';
import { useApp_GetOrganizationQuery } from './__generated__/App.graphql';
import { AppRoutes } from './AppRoutes';

function App() {
  const { isLoading, isAuthenticated, setOrganization } = useAuth();
  const { data, loading, error } = useApp_GetOrganizationQuery({
    skip: !isAuthenticated,
  });

  const appIsLoading = isLoading || loading;

  useCookies();

  useEffect(() => {
    if (data?.organization) {
      setOrganization(data.organization);
    }
  }, [data, setOrganization]);

  return (
    <>
      {appIsLoading ? (
        <PageLoader />
      ) : error ? (
        <HandleApiError error={error} />
      ) : (
        <DisplaysQueryProvider>
          <AppRoutes />
        </DisplaysQueryProvider>
      )}
    </>
  );
}

App.graphql = {
  queries: {
    App_query: gql`
      query App_getOrganization {
        organization {
          id
          handle
          ...AuthorizationProvider_organization
          ...LogoSettings_organization
        }
      }
    `,
  },
};

export default Sentry.withProfiler(App);
