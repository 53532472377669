import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { forwardRef, MouseEvent, Ref, useCallback } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { OmitStrict } from '~utils/types';

type Props = OmitStrict<ChakraLinkProps, 'as'> &
  RouterLinkProps & {
    delayBeforeNavigating?: number;
  };

export const Link = forwardRef(function Link(
  { delayBeforeNavigating = 0, onClick, replace, state, to, ...rest }: Props,
  ref: Ref<HTMLAnchorElement>,
) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      onClick?.(e);
      if (location.pathname === to) return;
      if (e.isDefaultPrevented()) return;

      e.preventDefault();

      setTimeout(() => {
        navigate(to, { replace, state });
      }, delayBeforeNavigating);
    },
    [location, onClick, delayBeforeNavigating, navigate, to, replace, state],
  );

  return (
    <ChakraLink
      ref={ref}
      {...rest}
      as={RouterLink}
      to={to}
      replace={replace}
      onClick={handleClick}
    />
  );
});
