import { Auth0ContextInterface, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ComponentType, Context, createContext, ReactNode, useMemo } from 'react';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provider: ComponentType<any>;
  providerProps: Omit<Auth0ProviderOptions, 'children'>;
  context: Context<Auth0ContextInterface>;
  children: ReactNode;
}

/**
 * Before you read further and gasp at this wizardry:
 *
 * The Auth0Provider from @auth0/auth0-react is not testable.
 * The Auth0 client being used is created inside the provider itself and there isn't a good way to swap it out.
 *
 * Since authentication is a global concern in the app we need a way to mock it in storybook and/or tests.
 * The way we achieve this is by wrapping the original auth0 context into a custom context, one that we can swap.
 *
 * usage example:
 *
 *  <AuthenticationProvider
 *    provider={Auth0Provider}
 *    providerProps={{
 *      domain: process.env.REACT_APP_AUTH0_DOMAIN,
 *      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
 *      redirectUri: window.location.origin,
 *      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
 *      scope: process.env.REACT_APP_AUTH0_SCOPE,
 *    }}
 *    context={Auth0Context}
 *  >
 *    {children}
 *  </AuthenticationProvider>
 *
 * The above code snippet is an example of how to use the real Auth0 provider under the covers.
 *
 * You can also inject a mocked provider for storybook or tests:
 *
 *  <AuthenticationProvider
 *    provider={Auth0MockedProvider}
 *    providerProps={{}}
 *    context={MockedAuth0Context}
 *  >
 *    {children}
 *  </AuthenticationProvider>
 */
export const AuthenticationContext = createContext<Context<Auth0ContextInterface> | null>(null);

function AuthenticationProvider({ provider: Provider, providerProps, context, children }: Props) {
  const ctx = useMemo(() => context, [context]);

  return (
    <AuthenticationContext.Provider value={ctx}>
      <Provider {...providerProps}>{children}</Provider>
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationProvider;
