/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateAutoRestart_DisplayFragment = { __typename: 'Display', autoRestart?: { __typename: 'AutoRestartState', desired?: { __typename: 'AutoRestart', switch: string, time?: string | null } | null, reported: { __typename: 'AutoRestart', switch: string, time?: string | null } } | null };

export type BulkUpdateAutoRestartMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdateAutoRestartInput;
}>;


export type BulkUpdateAutoRestartMutation = { __typename: 'Mutation', displayBulkUpdateAutoRestart: { __typename: 'DisplayBulkUpdateAutoRestartPayload', displays: Array<{ __typename: 'Display', id: string, autoRestart?: { __typename: 'AutoRestartState', desired?: { __typename: 'AutoRestart', switch: string, time?: string | null } | null, reported: { __typename: 'AutoRestart', switch: string, time?: string | null } } | null }> } };

export const BulkUpdateAutoRestart_DisplayFragmentDoc = gql`
    fragment BulkUpdateAutoRestart_display on Display {
  autoRestart {
    desired {
      switch
      time
    }
    reported {
      switch
      time
    }
  }
}
    `;
export const BulkUpdateAutoRestartDocument = gql`
    mutation BulkUpdateAutoRestart($input: DisplayBulkUpdateAutoRestartInput!) {
  displayBulkUpdateAutoRestart(input: $input) {
    displays {
      id
      autoRestart {
        desired {
          switch
          time
        }
        reported {
          switch
          time
        }
      }
    }
  }
}
    `;
export type BulkUpdateAutoRestartMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateAutoRestartMutation, BulkUpdateAutoRestartMutationVariables>;

/**
 * __useBulkUpdateAutoRestartMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAutoRestartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAutoRestartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAutoRestartMutation, { data, loading, error }] = useBulkUpdateAutoRestartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateAutoRestartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateAutoRestartMutation, BulkUpdateAutoRestartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdateAutoRestartMutation, BulkUpdateAutoRestartMutationVariables>(BulkUpdateAutoRestartDocument, options);
      }
export type BulkUpdateAutoRestartMutationHookResult = ReturnType<typeof useBulkUpdateAutoRestartMutation>;
export type BulkUpdateAutoRestartMutationResult = ApolloReactCommon.MutationResult<BulkUpdateAutoRestartMutation>;
export type BulkUpdateAutoRestartMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateAutoRestartMutation, BulkUpdateAutoRestartMutationVariables>;