/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type OrganizationSubscriptionsFragment = { __typename: 'OrganizationAppSubscription', id: string, name: string, iconUrl?: string | null, updatedAt: any, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } };

export const OrganizationSubscriptionsFragmentDoc = gql`
    fragment OrganizationSubscriptions on OrganizationAppSubscription {
  id
  name
  iconUrl
  updatedAt
  usage {
    current
    max
  }
}
    `;