/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type SubscriptionEditPage_SubscriptionFragment = { __typename: 'WaveSubscription', id: string, name: string, validUntil: any, productId: string, assignable: boolean, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null }, customers: Array<{ __typename: 'PartialCustomer', id: string }> };

export type WaveSubscriptionEditPageOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WaveSubscriptionEditPageOrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', customers: Array<{ __typename: 'Customer', id: string, name: string, displayCount: number, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null }>, waveSubscriptions?: Array<{ __typename: 'WaveSubscription', id: string, name: string, validUntil: any, productId: string, assignable: boolean, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null }, customers: Array<{ __typename: 'PartialCustomer', id: string }> }> | null } };

export type EditSubscriptionMutationVariables = Types.Exact<{
  input: Types.SubscriptionUpdateInput;
}>;


export type EditSubscriptionMutation = { __typename: 'Mutation', updateSubscription: { __typename: 'WaveSubscription', id: string, name: string, validUntil: any, productId: string, assignable: boolean, usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null }, customers: Array<{ __typename: 'PartialCustomer', id: string }> } };

export const SubscriptionEditPage_SubscriptionFragmentDoc = gql`
    fragment SubscriptionEditPage_subscription on WaveSubscription {
  id
  name
  usage {
    current
    max
  }
  validUntil
  productId
  assignable
  customers {
    id
  }
}
    `;
export const WaveSubscriptionEditPageOrganizationDocument = gql`
    query WaveSubscriptionEditPageOrganization {
  organization {
    customers {
      id
      name
      waveSubscription {
        id
      }
      displayCount
    }
    waveSubscriptions {
      ...SubscriptionEditPage_subscription
    }
  }
}
    ${SubscriptionEditPage_SubscriptionFragmentDoc}`;

/**
 * __useWaveSubscriptionEditPageOrganizationQuery__
 *
 * To run a query within a React component, call `useWaveSubscriptionEditPageOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaveSubscriptionEditPageOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaveSubscriptionEditPageOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useWaveSubscriptionEditPageOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WaveSubscriptionEditPageOrganizationQuery, WaveSubscriptionEditPageOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WaveSubscriptionEditPageOrganizationQuery, WaveSubscriptionEditPageOrganizationQueryVariables>(WaveSubscriptionEditPageOrganizationDocument, options);
      }
export function useWaveSubscriptionEditPageOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WaveSubscriptionEditPageOrganizationQuery, WaveSubscriptionEditPageOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WaveSubscriptionEditPageOrganizationQuery, WaveSubscriptionEditPageOrganizationQueryVariables>(WaveSubscriptionEditPageOrganizationDocument, options);
        }
export type WaveSubscriptionEditPageOrganizationQueryHookResult = ReturnType<typeof useWaveSubscriptionEditPageOrganizationQuery>;
export type WaveSubscriptionEditPageOrganizationLazyQueryHookResult = ReturnType<typeof useWaveSubscriptionEditPageOrganizationLazyQuery>;
export type WaveSubscriptionEditPageOrganizationQueryResult = ApolloReactCommon.QueryResult<WaveSubscriptionEditPageOrganizationQuery, WaveSubscriptionEditPageOrganizationQueryVariables>;
export const EditSubscriptionDocument = gql`
    mutation EditSubscription($input: SubscriptionUpdateInput!) {
  updateSubscription(input: $input) {
    id
    ...SubscriptionEditPage_subscription
  }
}
    ${SubscriptionEditPage_SubscriptionFragmentDoc}`;
export type EditSubscriptionMutationFn = ApolloReactCommon.MutationFunction<EditSubscriptionMutation, EditSubscriptionMutationVariables>;

/**
 * __useEditSubscriptionMutation__
 *
 * To run a mutation, you first call `useEditSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSubscriptionMutation, { data, loading, error }] = useEditSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSubscriptionMutation, EditSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditSubscriptionMutation, EditSubscriptionMutationVariables>(EditSubscriptionDocument, options);
      }
export type EditSubscriptionMutationHookResult = ReturnType<typeof useEditSubscriptionMutation>;
export type EditSubscriptionMutationResult = ApolloReactCommon.MutationResult<EditSubscriptionMutation>;
export type EditSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSubscriptionMutation, EditSubscriptionMutationVariables>;