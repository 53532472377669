import { gql } from '@apollo/client';
import { Box, Button, chakra, Heading, Stack, useDisclosure } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { useMemo } from 'react';
import { useAuth } from '~auth/useAuth';
import { ChangeContentSourceModal } from '~components/displays/ChangeContentSourceModal';
import { useContentSourceLabel } from '~components/displays/useContentSource';
import { PlaylistIcon, WebPageIcon } from '~components/ui/icons';
import { AppsIcon } from '~components/ui/icons/AppsIcon';
import { DisplayContentSource_DisplayFragment } from './__generated__/DisplayContentSource.graphql';

interface Props {
  display: DisplayContentSource_DisplayFragment;
}

export function DisplayContentSource({ display }: Props) {
  const changeContentSourceModal = useDisclosure();
  const { verifyUserPermissions } = useAuth();

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  const contentSource = useMemo(
    () => display.contentSource?.current?.desired ?? display.contentSource?.current?.reported,
    [display],
  );

  const { bookmarkLabel, mediaLabel, appLabel, headingLabel, appActivityLabel } =
    useContentSourceLabel(contentSource, display);

  return (
    <>
      <Stack direction="row" spacing="3" alignItems="center">
        {(bookmarkLabel || mediaLabel || appLabel) && (
          <Box alignSelf="start" color="gray.900">
            {bookmarkLabel && <WebPageIcon width="6" height="6" />}
            {mediaLabel && <PlaylistIcon width="6" height="6" />}
            {appLabel && <AppsIcon width="6" height="6" />}
          </Box>
        )}
        <Box flex="1" wordBreak="break-word">
          <Heading size="md" color="gray.900" mb="0" lineHeight="7">
            {headingLabel}
          </Heading>
          <chakra.span color="gray.500" fontWeight="normal">
            {bookmarkLabel}
            {mediaLabel}
            {appActivityLabel}
          </chakra.span>
        </Box>
        <div>
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            onClick={changeContentSourceModal.onOpen}
            isDisabled={isDisplaySettingsUpdateDisabled}
          >
            Change input
          </Button>
        </div>
      </Stack>
      <ChangeContentSourceModal
        display={display}
        isOpen={changeContentSourceModal.isOpen}
        onCancel={changeContentSourceModal.onClose}
        onSuccess={changeContentSourceModal.onClose}
      />
    </>
  );
}

DisplayContentSource.graphql = {
  fragments: {
    DisplayContentSource_display: gql`
      fragment DisplayContentSource_display on Display {
        id
        contentSource {
          current {
            reported {
              ... on AppContentSource {
                label
                applicationId
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
            desired {
              ... on AppContentSource {
                label
                applicationId
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
          }
          available {
            ... on AppContentSource {
              label
              applicationId
            }
            ... on BookmarkContentSource {
              index
            }
            ... on InputContentSource {
              source
            }
            ... on PlaylistContentSource {
              playlistId
            }
          }
        }
        bookmarks {
          all {
            reported
            desired
          }
        }
        playlist {
          current {
            id
            title
          }
        }
        ...ChangeContentSourceModal_display
      }
    `,
  },
};
