import { getColor, randomColor } from '@chakra-ui/theme-tools';
import { snakeCase } from 'lodash';
import { StyleProps } from './types';

const ALLOWED_AVATAR_COLOR_SCHEMES = ['green', 'orange', 'purple', 'pink'];

export const Avatar = {
  parts: ['container', 'excessLabel', 'badge', 'label'],
  baseStyle: (props: StyleProps) => {
    const { name, theme } = props;
    const background = randomColor({
      string: name,
      colors: ALLOWED_AVATAR_COLOR_SCHEMES.map((c) => getColor(theme, `${c}.400`)),
    });
    const color = 'white';

    return {
      container: {
        color,
        background,
      },
    };
  },
  sizes: {
    md: {
      container: {
        fontSize: 'sm',
        fontWeight: 'semibold',
      },
      label: {
        fontSize: 'sm',
        fontWeight: 'semibold',
      },
    },
  },
  variants: {
    square: () => {
      return {
        container: {
          borderRadius: 'base',
          color: 'gray.600',
          background: 'gray.50',
          fontSize: 'sm',
          borderWidth: '1px',
          borderColor: 'gray.100',
          '& img': {
            borderRadius: 'base',
          },
        },
      };
    },
    bordered: () => {
      return {
        container: {
          border: '2px solid',
          borderColor: 'white',
        },
      };
    },
  },
};

export function getInitials(name: string) {
  return snakeCase(name)
    .split('_')
    .slice(0, 3)
    .map((v) => v[0])
    .join('');
}
