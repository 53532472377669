/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type UserCustomersTable_CustomerFragment = { __typename: 'PartialCustomer', id: string, name: string, waveSubscription?: { __typename: 'WaveSubscription', id: string } | null };

export const UserCustomersTable_CustomerFragmentDoc = gql`
    fragment UserCustomersTable_customer on PartialCustomer {
  id
  name
  waveSubscription {
    id
  }
}
    `;