import { ButtonProps } from '@chakra-ui/button';
import { IconButton } from '@chakra-ui/react';
import { forwardRef, Ref } from 'react';
import { OmitStrict } from '~utils/types';
import { ArrowLeftIcon } from './icons';

type Props = OmitStrict<ButtonProps, 'variant' | 'leftIcon' | 'rightIcon'>;

export const BackButton = forwardRef(
  ({ colorScheme = 'gray', ...rest }: Props, ref: Ref<HTMLButtonElement>) => {
    return (
      <IconButton
        ref={ref}
        {...rest}
        colorScheme={colorScheme}
        variant="ghost"
        color="blue.500"
        size="lg"
        paddingX="0"
        paddingY="0"
        aria-label="Back to overview"
        borderRadius="100%"
      >
        <ArrowLeftIcon width="6" height="6" />
      </IconButton>
    );
  },
);
