import { isNil } from 'lodash';

export type MaybePromise<T> = T | Promise<T>;

export function isDefined<T>(value: T | null | undefined): value is T {
  return !isNil(value);
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

/**
 * Same as Omit but then the strict version.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OmitStrict<T, K extends keyof T> = T extends any ? Pick<T, Exclude<keyof T, K>> : never;

export function ensure<T>(value: T | null | undefined) {
  if (isNil(value)) {
    throw new Error('Value cannot be nil');
  }

  return value;
}

export type SingleOrArray<T> = T | T[];
