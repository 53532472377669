import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import { EmptyCustomerIllustration } from '~components/ui/illustrations/EmptyCustomerIllustration';

export function EmptyCustomers({ handleCreate }: { handleCreate: () => void }) {
  return (
    <EmptyView
      icon={<EmptyCustomerIllustration marginBottom={'-40px'} />}
      title="Create your first customer"
      description="After you create a customer, you can start claiming displays."
    >
      <EmptyViewButton onClick={handleCreate} label="Add customer" />
    </EmptyView>
  );
}
