import { createIcon } from '@chakra-ui/react';

export const PlaylistIcon = createIcon({
  displayName: 'PlaylistIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M8 2C8 1.44772 7.55228 1 7 1C6.44772 1 6 1.44772 6 2H8ZM6 22C6 22.5523 6.44772 23 7 23C7.55228 23 8 22.5523 8 22H6ZM18 2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2H18ZM16 22C16 22.5523 16.4477 23 17 23C17.5523 23 18 22.5523 18 22H16ZM2 11C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13V11ZM22 13C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11V13ZM2 6C1.44772 6 1 6.44772 1 7C1 7.55228 1.44772 8 2 8V6ZM7 8C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6V8ZM2 16C1.44772 16 1 16.4477 1 17C1 17.5523 1.44772 18 2 18V16ZM7 18C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16V18ZM17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18V16ZM22 18C22.5523 18 23 17.5523 23 17C23 16.4477 22.5523 16 22 16V18ZM17 6C16.4477 6 16 6.44772 16 7C16 7.55228 16.4477 8 17 8V6ZM22 8C22.5523 8 23 7.55228 23 7C23 6.44772 22.5523 6 22 6V8ZM4.18 3H19.82V1H4.18V3ZM19.82 3C20.4717 3 21 3.5283 21 4.18H23C23 2.42373 21.5763 1 19.82 1V3ZM21 4.18V19.82H23V4.18H21ZM21 19.82C21 20.4717 20.4717 21 19.82 21V23C21.5763 23 23 21.5763 23 19.82H21ZM19.82 21H4.18V23H19.82V21ZM4.18 21C3.5283 21 3 20.4717 3 19.82H1C1 21.5763 2.42373 23 4.18 23V21ZM3 19.82V4.18H1V19.82H3ZM3 4.18C3 3.5283 3.5283 3 4.18 3V1C2.42373 1 1 2.42373 1 4.18H3ZM6 2V22H8V2H6ZM16 2V22H18V2H16ZM2 13H22V11H2V13ZM2 8H7V6H2V8ZM2 18H7V16H2V18ZM17 18H22V16H17V18ZM17 8H22V6H17V8Z"
      fill="currentColor"
    />
  ),
});
