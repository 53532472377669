import { createIcon } from '@chakra-ui/react';

export const GripIcon = createIcon({
  displayName: 'GripIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M15 20V22C16.1046 22 17 21.1046 17 20H15ZM15 20H13C13 21.1046 13.8954 22 15 22V20ZM15 20V18C13.8954 18 13 18.8954 13 20H15ZM15 20H17C17 18.8954 16.1046 18 15 18V20ZM15 13V15C16.1046 15 17 14.1046 17 13H15ZM15 13H13C13 14.1046 13.8954 15 15 15V13ZM15 13V11C13.8954 11 13 11.8954 13 13H15ZM15 13H17C17 11.8954 16.1046 11 15 11V13ZM15 6V8C16.1046 8 17 7.10457 17 6H15ZM15 6H13C13 7.10457 13.8954 8 15 8V6ZM15 6V4C13.8954 4 13 4.89543 13 6H15ZM15 6H17C17 4.89543 16.1046 4 15 4V6ZM9 20V22C10.1046 22 11 21.1046 11 20H9ZM9 20H7C7 21.1046 7.89543 22 9 22V20ZM9 20V18C7.89543 18 7 18.8954 7 20H9ZM9 20H11C11 18.8954 10.1046 18 9 18V20ZM9 13V15C10.1046 15 11 14.1046 11 13H9ZM9 13H7C7 14.1046 7.89543 15 9 15V13ZM9 13V11C7.89543 11 7 11.8954 7 13H9ZM9 13H11C11 11.8954 10.1046 11 9 11V13ZM9 6V8C10.1046 8 11 7.10457 11 6H9ZM9 6H7C7 7.10457 7.89543 8 9 8V6ZM9 6V4C7.89543 4 7 4.89543 7 6H9ZM9 6H11C11 4.89543 10.1046 4 9 4V6Z"
      fill="currentColor"
    />
  ),
});
