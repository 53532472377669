import { ApolloError, gql } from '@apollo/client';
import {
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, isNil } from 'lodash';
import { ChangeEvent, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SearchIcon } from '~components/ui/icons';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { useCreateSiteMutation } from './__generated__/CreateSiteModal.graphql';
import { useGoogleMaps } from './useGoogleMaps';

const FormData = z.object({
  customerId: z.string(),
  name: z
    .string()
    .min(1, 'The name must contain at least one character')
    .regex(/.*\S.*/, 'The name cannot contain only white spaces'),
});
type FormData = z.infer<typeof FormData>;

interface Props {
  customerId: string;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (id: string) => Promise<void> | void;
}

export function CreateSiteModal({ customerId, isOpen, onCancel, onSuccess }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <CreateSiteModalContent
          customerId={customerId}
          initialFocusRef={initialFocusRef}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

type ContentProps = {
  customerId: string;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => void;
  onSuccess: (id: string) => Promise<void> | void;
};

function CreateSiteModalContent({
  customerId,
  initialFocusRef,
  onCancel,
  onSuccess,
}: ContentProps) {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      customerId,
      name: '',
    },
    mode: 'onChange',
    resolver: zodResolver(FormData),
  });
  const { ref: nameInputRef, ...nameInputProps } = register('name');
  const [createSite] = useCreateSiteMutation();
  const [addressSearchValue, setAddressSearchValue] = useState<string | null>(null);

  const inputRefMaps = useRef<HTMLInputElement>(null);

  const { staticMapUrl, setPlace, place } = useGoogleMaps({
    inputRef: inputRefMaps,
  });

  const isAddressInvalid = useMemo(() => {
    return (!isEmpty(addressSearchValue) || !isNil(addressSearchValue)) && isNil(place);
  }, [addressSearchValue, place]);

  const addressErrorMessage = useMemo(() => {
    if (isAddressInvalid) {
      return 'Please select on of the suggested addresses.';
    }
    return undefined;
  }, [isAddressInvalid]);

  const resetPlace = useCallback(() => {
    if (isEmpty(inputRefMaps.current?.value) || isNil(inputRefMaps.current?.value)) {
      setPlace(null);
    }
  }, [setPlace]);

  const handleAddressChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAddressSearchValue(e.target.value);

      resetPlace();
    },
    [resetPlace],
  );

  const removeAddress = useCallback(() => {
    if (inputRefMaps.current) {
      inputRefMaps.current.value = '';
    }

    setAddressSearchValue(null);
    setPlace(null);
  }, [setPlace]);

  const onSubmit = useCallback(
    async ({ customerId, name }: FormData) => {
      const addressToStore = !isEmpty(inputRefMaps.current?.value)
        ? inputRefMaps.current?.value
        : undefined;

      try {
        const { data } = await createSite({
          variables: {
            input: {
              customerId,
              name,
              address: addressToStore,
            },
          },
        });
        if (!data || !data.siteCreate.site) {
          throw new Error('Create site failed');
        }
        await onSuccess?.(data.siteCreate.site.id);
      } catch (err) {
        const error: ApolloError = err as ApolloError;

        const title =
          error.message === 'SITE_NAME_DUPLICATE'
            ? 'Site name already exists'
            : 'Cannot create site';

        toast({
          status: 'error',
          title,
          description: fromError(err, 'CreateSite', {
            SITE_NAME_DUPLICATE:
              'A site with this name already exists. Please choose a different name.',
          }),
        });
      }
    },
    [createSite, onSuccess, toast],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>New site</ModalHeader>
      <ModalCloseButton onClick={onCancel} />
      <ModalBody paddingBottom="5">
        <VStack>
          <FormControl isRequired isInvalid={Boolean(errors.name)}>
            <FormLabel marginTop="4">Name</FormLabel>
            <Input
              ref={(r) => {
                nameInputRef(r);
                initialFocusRef.current = r;
              }}
              placeholder="New site name"
              {...nameInputProps}
            />

            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={isAddressInvalid}>
            <FormLabel marginTop="8">Address</FormLabel>

            <InputGroup>
              <InputLeftElement>
                <SearchIcon color="gray.500" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search an address"
                ref={inputRefMaps}
                onChange={handleAddressChange}
                isDisabled={!isNil(place)}
              />
              <InputRightElement onClick={removeAddress}>
                <CloseButton />
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>{addressErrorMessage}</FormErrorMessage>

            {!isNil(staticMapUrl) ? (
              <Image src={staticMapUrl} marginTop="4" borderRadius={'md'} />
            ) : undefined}
          </FormControl>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" colorScheme="blue" onClick={onCancel} isDisabled={isSubmitting}>
          Close
        </Button>
        <Button
          variant="solid"
          colorScheme="blue"
          marginLeft="3"
          type="submit"
          isDisabled={
            !isDirty || isSubmitting || isAddressInvalid || Object.keys(errors).length > 0
          }
          isLoading={isSubmitting}
        >
          Create
        </Button>
      </ModalFooter>
    </form>
  );
}

CreateSiteModal.graphql = {
  mutations: {
    CreateSite: gql`
      mutation CreateSite($input: SiteCreateInput!) {
        siteCreate(input: $input) {
          site {
            id
          }
          customer {
            id
            name
            sites {
              id
              name
              address
            }
          }
        }
      }
    `,
  },
};
