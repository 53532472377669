import { gql } from '@apollo/client';
import { Flex, useToast } from '@chakra-ui/react';
import { ReactNode, useCallback } from 'react';
import { ImageInput } from '~components/ui/ImageInput';
import { FileUploadRequestPayload } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { useFileUploadRequestMutation } from './__generated__/AvatarInput.graphql';

export interface FileUploadProps {
  uploadPayload?: FileUploadRequestPayload;
  file: File | null;
}

type Props = {
  onChange: (fileUploadData: FileUploadProps) => void;
  onError: (error: Error) => void;
  isInvalid?: boolean;
  avatar?: string | undefined;
  borderRadius?: string | number;
  previewComponent?: (imageUrl?: string) => ReactNode;
  title?: string;
  clearLabel?: string;
  confirmToDelete?: boolean;
};

export function AvatarInput({
  onChange,
  onError,
  avatar,
  borderRadius,
  title,
  previewComponent,
  clearLabel,
  confirmToDelete,
}: Props) {
  const toast = useToast();
  const [fileUploadRequest] = useFileUploadRequestMutation();

  const handleChange = useCallback(
    async (image?: File) => {
      try {
        if (!image) {
          return onChange({
            file: null,
          });
        }

        const { data } = await fileUploadRequest({
          variables: {
            input: {
              size: image.size,
              type: image.type,
            },
          },
        });

        if (!data) {
          onError(new Error('Something went wrong when uploading the file.'));
          return;
        }

        onChange({
          file: image,
          uploadPayload: {
            assetUrl: data.fileUploadRequest.assetUrl,
            uploadUrl: data.fileUploadRequest.uploadUrl,
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: 'Cannot request picture upload',
          description: fromError(err, 'FileUploadRequest'),
        });
      }
    },
    [fileUploadRequest, onChange, onError, toast],
  );

  return (
    <Flex flexDirection={'column'}>
      <ImageInput
        imageUrl={avatar}
        onChange={handleChange}
        onError={onError}
        borderRadius={borderRadius}
        title={title}
        previewComponent={previewComponent}
        clearLabel={clearLabel}
        confirmToDelete={confirmToDelete}
      />
    </Flex>
  );
}

AvatarInput.graphql = {
  mutations: {
    FileUploadRequest: gql`
      mutation FileUploadRequest($input: FileUploadRequestInput!) {
        fileUploadRequest(input: $input) {
          assetUrl
          uploadUrl
        }
      }
    `,
  },
};
