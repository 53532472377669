/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { UseRecommendedSettings_DisplayFragmentDoc } from '../../__generated__/useRecommendedSettings.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdatePower_DisplayFragment = { __typename: 'Display', id: string, power?: { __typename: 'PowerState', desired?: Types.Power | null, reported: Types.Power } | null, recommendedSettings?: { __typename: 'RecommendedSettingsState', reported: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> }, desired?: { __typename: 'RecommendedSettings', recommended: boolean, warnings: Array<{ __typename: 'SettingWarning', code: string, severity: Types.SettingWarningSeverity }> } | null } | null, signalDetection?: { __typename: 'SignalDetectionState', reported: boolean, desired?: boolean | null } | null };

export type BulkUpdatePowerMutationVariables = Types.Exact<{
  input: Types.DisplayBulkUpdatePowerInput;
}>;


export type BulkUpdatePowerMutation = { __typename: 'Mutation', displayBulkUpdatePower: { __typename: 'DisplayBulkUpdatePowerPayload', displays: Array<{ __typename: 'Display', id: string, power?: { __typename: 'PowerState', desired?: Types.Power | null, reported: Types.Power } | null }> } };

export const BulkUpdatePower_DisplayFragmentDoc = gql`
    fragment BulkUpdatePower_display on Display {
  id
  power {
    desired
    reported
  }
  ...UseRecommendedSettings_display
}
    ${UseRecommendedSettings_DisplayFragmentDoc}`;
export const BulkUpdatePowerDocument = gql`
    mutation BulkUpdatePower($input: DisplayBulkUpdatePowerInput!) {
  displayBulkUpdatePower(input: $input) {
    displays {
      id
      power {
        desired
        reported
      }
    }
  }
}
    `;
export type BulkUpdatePowerMutationFn = ApolloReactCommon.MutationFunction<BulkUpdatePowerMutation, BulkUpdatePowerMutationVariables>;

/**
 * __useBulkUpdatePowerMutation__
 *
 * To run a mutation, you first call `useBulkUpdatePowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdatePowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdatePowerMutation, { data, loading, error }] = useBulkUpdatePowerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdatePowerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdatePowerMutation, BulkUpdatePowerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BulkUpdatePowerMutation, BulkUpdatePowerMutationVariables>(BulkUpdatePowerDocument, options);
      }
export type BulkUpdatePowerMutationHookResult = ReturnType<typeof useBulkUpdatePowerMutation>;
export type BulkUpdatePowerMutationResult = ApolloReactCommon.MutationResult<BulkUpdatePowerMutation>;
export type BulkUpdatePowerMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdatePowerMutation, BulkUpdatePowerMutationVariables>;