import { Role } from '@tp-vision/roles-permissions';
import { AuthorizationProvider_OrganizationFragment } from '~auth/__generated__/AuthorizationProvider.graphql';
import { AuthUser } from '~auth/useAuthentication';

export type RoleOption = { label: string; value: Role; isDisabled: boolean };

export const getRoleOptionsForRoles = (value: Role[]) =>
  value.map<RoleOption>((role) => ({
    // Regex: split on any capital letter preceded by a lowercase letter
    label: role.split(/(?<=[a-z])(?=[A-Z])/).join(' '),
    value: role,
    isDisabled: role === Role.Owner ? true : false,
  }));

// Note: these roles are manually sorted in order of presumed importance, but this might one day receive some
// helper to sort by a predefined weight.
export function getRoleOptions(
  organization: AuthorizationProvider_OrganizationFragment | null | undefined,
  authUser: AuthUser,
) {
  return getRoleOptionsForRoles([
    Role.Guest,
    Role.Installer,
    Role.Operator,
    // Can only assign Developer if the Organization is configured to use this role
    ...(organization?.developerAccess === true ? [Role.Developer] : []),
    Role.Admin,
    // Can only assign WaveAdmin if user is WaveAdmin
    ...(authUser.roles?.includes(Role.WaveAdmin) ? [Role.WaveAdmin] : []),
  ]);
}
