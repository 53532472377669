/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectableSitesQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
}>;


export type GetSelectableSitesQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, sites: Array<{ __typename: 'Site', id: string, name: string, address?: string | null }> } | null };

export type CreateSelectableSiteMutationVariables = Types.Exact<{
  input: Types.SiteCreateInput;
}>;


export type CreateSelectableSiteMutation = { __typename: 'Mutation', siteCreate: { __typename: 'SiteCreatePayload', site?: { __typename: 'Site', id: string, name: string, address?: string | null } | null, customer?: { __typename: 'Customer', id: string, sites: Array<{ __typename: 'Site', id: string, name: string, address?: string | null }> } | null } };


export const GetSelectableSitesDocument = gql`
    query GetSelectableSites($customerId: ID!) {
  customer(id: $customerId) {
    id
    sites {
      id
      name
      address
    }
  }
}
    `;

/**
 * __useGetSelectableSitesQuery__
 *
 * To run a query within a React component, call `useGetSelectableSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectableSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectableSitesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetSelectableSitesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSelectableSitesQuery, GetSelectableSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSelectableSitesQuery, GetSelectableSitesQueryVariables>(GetSelectableSitesDocument, options);
      }
export function useGetSelectableSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelectableSitesQuery, GetSelectableSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSelectableSitesQuery, GetSelectableSitesQueryVariables>(GetSelectableSitesDocument, options);
        }
export type GetSelectableSitesQueryHookResult = ReturnType<typeof useGetSelectableSitesQuery>;
export type GetSelectableSitesLazyQueryHookResult = ReturnType<typeof useGetSelectableSitesLazyQuery>;
export type GetSelectableSitesQueryResult = ApolloReactCommon.QueryResult<GetSelectableSitesQuery, GetSelectableSitesQueryVariables>;
export const CreateSelectableSiteDocument = gql`
    mutation CreateSelectableSite($input: SiteCreateInput!) {
  siteCreate(input: $input) {
    site {
      id
      name
      address
    }
    customer {
      id
      sites {
        id
        name
        address
      }
    }
  }
}
    `;
export type CreateSelectableSiteMutationFn = ApolloReactCommon.MutationFunction<CreateSelectableSiteMutation, CreateSelectableSiteMutationVariables>;

/**
 * __useCreateSelectableSiteMutation__
 *
 * To run a mutation, you first call `useCreateSelectableSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelectableSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelectableSiteMutation, { data, loading, error }] = useCreateSelectableSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSelectableSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSelectableSiteMutation, CreateSelectableSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSelectableSiteMutation, CreateSelectableSiteMutationVariables>(CreateSelectableSiteDocument, options);
      }
export type CreateSelectableSiteMutationHookResult = ReturnType<typeof useCreateSelectableSiteMutation>;
export type CreateSelectableSiteMutationResult = ApolloReactCommon.MutationResult<CreateSelectableSiteMutation>;
export type CreateSelectableSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSelectableSiteMutation, CreateSelectableSiteMutationVariables>;