import { createIcon } from '@chakra-ui/react';

export const TrashIcon = createIcon({
  displayName: 'TrashIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
    height: '4',
    width: '4',
  },
  path: (
    <path
      d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7V5ZM21 7C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5V7ZM19 6H20C20 5.44772 19.5523 5 19 5V6ZM5 20H4H5ZM7 6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6H7ZM10 2V1V2ZM14 2V1V2ZM15 6C15 6.55228 15.4477 7 16 7C16.5523 7 17 6.55228 17 6H15ZM3 7H5V5H3V7ZM5 7H21V5H5V7ZM18 6V20H20V6H18ZM18 20C18 20.2652 17.8946 20.5196 17.7071 20.7071L19.1213 22.1213C19.6839 21.5587 20 20.7957 20 20H18ZM17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21V23C17.7957 23 18.5587 22.6839 19.1213 22.1213L17.7071 20.7071ZM17 21H7V23H17V21ZM7 21C6.73478 21 6.48043 20.8946 6.29289 20.7071L4.87868 22.1213C5.44129 22.6839 6.20435 23 7 23V21ZM6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20H4C4 20.7957 4.31607 21.5587 4.87868 22.1213L6.29289 20.7071ZM6 20V6H4V20H6ZM5 7H19V5H5V7ZM9 6V4H7V6H9ZM9 4C9 3.73478 9.10536 3.48043 9.29289 3.29289L7.87868 1.87868C7.31607 2.44129 7 3.20435 7 4H9ZM9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3V1C9.20435 1 8.44129 1.31607 7.87868 1.87868L9.29289 3.29289ZM10 3H14V1H10V3ZM14 3C14.2652 3 14.5196 3.10536 14.7071 3.29289L16.1213 1.87868C15.5587 1.31607 14.7956 1 14 1V3ZM14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4H17C17 3.20435 16.6839 2.44129 16.1213 1.87868L14.7071 3.29289ZM15 4V6H17V4H15Z"
      fill="currentColor"
    />
  ),
});
