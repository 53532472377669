/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type SiteCell_ValueFragment = { __typename: 'Site', id: string, name: string, address?: string | null };

export type SiteCell_DisplayFragment = { __typename: 'Display', id: string, site?: { __typename: 'Site', id: string, name: string, address?: string | null } | null };

export const SiteCell_ValueFragmentDoc = gql`
    fragment SiteCell_value on Site {
  id
  name
  address
}
    `;
export const SiteCell_DisplayFragmentDoc = gql`
    fragment SiteCell_display on Display {
  id
  site {
    id
    ...SiteCell_value
  }
}
    ${SiteCell_ValueFragmentDoc}`;