/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PresenceBadge_DisplayFragmentDoc } from '../../__generated__/PresenceBadge.graphql';
import { PowerBadge_DisplayFragmentDoc } from '../../__generated__/PowerBadge.graphql';
export type DisplayDetailHeader_DisplayFragment = { __typename: 'Display', id: string, site?: { __typename: 'Site', name: string } | null, groups: Array<{ __typename: 'Group', id: string, name: string }>, agentReleaseChannel?: { __typename: 'AgentReleaseChannel', reported: Types.ReleaseChannel } | null, presence?: { __typename: 'DisplayPresence', connected: boolean } | null, power?: { __typename: 'PowerState', desired?: Types.Power | null, reported: Types.Power } | null };

export const DisplayDetailHeader_DisplayFragmentDoc = gql`
    fragment DisplayDetailHeader_display on Display {
  id
  site {
    name
  }
  groups {
    id
    name
  }
  agentReleaseChannel {
    reported
  }
  ...PresenceBadge_display
  ...PowerBadge_display
}
    ${PresenceBadge_DisplayFragmentDoc}
${PowerBadge_DisplayFragmentDoc}`;