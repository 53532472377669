import { gql } from '@apollo/client';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { TableInstance } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { CustomerSubscriptionsTableSubscriptionFragment } from './__generated__/CustomerSubscriptionsTable.graphql';
import {
  CustomerSubscriptionsTablePageSizeSelector,
  CustomerSubscriptionsTablePagination,
} from './Pagination';

interface Props {
  table: TableInstance<CustomerSubscriptionsTableSubscriptionFragment>;
}

export function CustomerSubscriptionsTable({ table }: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, pageCount } = table;

  return (
    <>
      <Table {...getTableProps()} variant="simple" width="100%">
        <Thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();

            return (
              <Tr {...headerGroupProps} key={headerGroupProps.key}>
                {headerGroup.headers.map((column) => {
                  const sortProps = column.getSortByToggleProps();
                  const sortColumn = sortProps.onClick;
                  const headerProps = column.getHeaderProps(sortProps);

                  return (
                    <Th
                      {...headerProps}
                      key={headerProps.key}
                      color={column.isSorted ? 'gray.700' : 'gray.600'}
                      minWidth={`${column.minWidth}px`}
                      maxWidth={`${column.maxWidth}px`}
                      width={column.width}
                      _hover={
                        column.disableSortBy
                          ? {}
                          : {
                              textColor: 'gray.800',
                            }
                      }
                      onClick={sortColumn}
                    >
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flex="1">{column.render('Header')}</Box>
                        {column.canSort && (
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDownIcon display="inherit" />
                              ) : (
                                <ChevronUpIcon display="inherit" />
                              )
                            ) : (
                              <ChevronDownIcon visibility="hidden" />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();

            return (
              <Tr {...rowProps} key={rowProps.key}>
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  return (
                    <Td
                      {...cellProps}
                      key={cellProps.key}
                      minWidth={`${cell.column.minWidth}px`}
                      maxWidth={`${cell.column.maxWidth}px`}
                      width={cell.column.width}
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {pageCount > 1 && (
        <Box display="flex" justifyContent="flex-end">
          <CustomerSubscriptionsTablePageSizeSelector table={table} />
          <CustomerSubscriptionsTablePagination table={table} />
        </Box>
      )}
    </>
  );
}

CustomerSubscriptionsTable.graphql = {
  fragments: {
    CustomerSubscriptionsTable: gql`
      fragment CustomerSubscriptionsTableSubscription on WaveSubscription {
        id
        name
        usage {
          current
          max
        }
        validUntil
        customers {
          name
        }
      }
    `,
  },
};
